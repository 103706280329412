import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { IClassStoreItem } from "ts/interfaces/Class"

import {
  IDeleteClassesStoreItemsArgs,
  IDeleteClassesStoreItemsInput,
  IPatchClassesStoreItemsArgs,
  IPatchClassesStoreItemsInput,
  IPostClassesStoreItemsArgs,
  IPostClassesStoreItemsInput,
} from "./classesStoreItems.types"

export const useMutationPostClassesStoreItems = (
  args?: IPostClassesStoreItemsArgs
) =>
  useMutation<
    AxiosResponse<IClassStoreItem[]>,
    AxiosError,
    IPostClassesStoreItemsInput
  >(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_STORE_ITEMS.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )

export const useMutationPatchClassesStoreItems = (
  args?: IPatchClassesStoreItemsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPatchClassesStoreItemsInput>(
    async ({ classId, data }) =>
      await axiosInstance.patch(
        Endpoint.CLASSES_STORE_ITEMS.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )

export const useMutationDeleteClassesStoreItems = (
  args?: IDeleteClassesStoreItemsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IDeleteClassesStoreItemsInput>(
    async ({ classId, data }) =>
      await axiosInstance.delete(
        Endpoint.CLASSES_STORE_ITEMS.replace("ID", `${classId}`),
        {
          data,
        }
      ),
    args?.options
  )

export const useMutationPostClassesStoreClerkItems = (
  args?: IPostClassesStoreItemsArgs
) =>
  useMutation<
    AxiosResponse<IClassStoreItem[]>,
    AxiosError,
    IPostClassesStoreItemsInput
  >(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_ID_HELPER_STORE_CLERK_STORE_ITEMS.replace(
          "ID",
          `${classId}`
        ),
        data
      ),
    args?.options
  )

export const useMutationPatchClassesStoreClerkItems = (
  args?: IPatchClassesStoreItemsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPatchClassesStoreItemsInput>(
    async ({ classId, data }) =>
      await axiosInstance.patch(
        Endpoint.CLASSES_ID_HELPER_STORE_CLERK_STORE_ITEMS.replace(
          "ID",
          `${classId}`
        ),
        data
      ),
    args?.options
  )

export const useMutationDeleteClassesStoreClerkItems = (
  args?: IDeleteClassesStoreItemsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IDeleteClassesStoreItemsInput>(
    async ({ classId, data }) =>
      await axiosInstance.delete(
        Endpoint.CLASSES_ID_HELPER_STORE_CLERK_STORE_ITEMS.replace(
          "ID",
          `${classId}`
        ),
        {
          data,
        }
      ),
    args?.options
  )
