import { FC } from "react"

import { Box, Stack, Typography } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { useClassId } from "hooks/navigation"
import { useGetQueryData } from "hooks/reactQuery"
import { useTranslation } from "react-i18next"
import { IClass } from "ts/interfaces/Class"

const STUDENT_LOGIN_PAGE = process.env.REACT_APP_STUDENT_LOGIN

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const DialogClassCode: FC<IProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation()
  const classId = useClassId()
  const classData = useGetQueryData<IClass>(["classes", Number(classId)])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      tabletMaxWidth="867px"
      desktopMaxWidth="867px"
      customActionButtonsSlot
    >
      <Stack
        pb="24px"
        borderBottom={(theme) => `1px solid ${theme.palette.mockup.primary95}`}
      >
        <Typography
          fontSize="28px"
          color="mockup.darkBlue"
          lineHeight="36px"
          textAlign="center"
        >
          {t("students.goTo")}
          <br />
          <Box
            component="span"
            sx={{
              color: "inherit",
              wordWrap: "break-word",
              textDecoration: "underline",
            }}
          >
            {STUDENT_LOGIN_PAGE}
          </Box>
          <br />
          {t("students.toEnterYourClassCode").toLowerCase()}!
        </Typography>
      </Stack>
      <Typography
        color="mockup.primary10"
        fontSize={{ mobile: "60px", tablet: "172px" }}
        lineHeight={{ mobile: "80px", tablet: "214px" }}
        textAlign="center"
        mb="24px"
        fontFamily="Source Serif Pro"
      >
        {classData?.data.invitation_code}
      </Typography>
    </Dialog>
  )
}

export default DialogClassCode
