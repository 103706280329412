import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { IPaymentsArgs } from "./payments.types"

export const useMutationPayments = ({ options }: IPaymentsArgs) =>
  useMutation<AxiosResponse<string>, AxiosError, null>(
    async () => await axiosInstance.post(Endpoint.PAYMENTS_CHECKOUT_SESSIONS),
    options
  )
