import { useState } from "react"

import { CSSTransition, TransitionGroup } from "react-transition-group"

import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import Table from "./table"
import TableRow from "./tableRow"

const TableView = () => {
  const [count, setCount] = useState(10)
  const next10Row = () => {
    setCount(count + 10)
  }

  return (
    <ViewCommonElementsContainer
      renderDataView={({
        apiData,
        selectingProps: {
          selectedItems,
          setSelectedItems,
          toggleAll,
          toggleItem,
          getIsChecked,
        },
        sortingProps: {
          sortedData,
          handleSortingClick,
          getIsSortingActive,
          getSortingDirection,
        },
        loading,
      }) => (
        <div style={{ pointerEvents: loading ? "none" : "all" }}>
          <Table
            count={count}
            sortedData={sortedData}
            next10Row={next10Row}
            checkboxProps={{
              checked:
                sortedData.length === selectedItems.length &&
                !!apiData?.data.length,
              onChange: () => {
                if (apiData?.data && apiData?.data.length > 500) {
                  if (selectedItems.length === 500) {
                    setSelectedItems([])
                    return
                  }
                  setSelectedItems(apiData?.data.slice(0, 500))
                } else {
                  toggleAll()
                }
              },
              indeterminate:
                sortedData.length !== selectedItems.length &&
                !!selectedItems.length,
            }}
            getTableSortLabelProps={(property) => ({
              active: getIsSortingActive(property),
              direction: getSortingDirection(property),
              onClick: () => {
                handleSortingClick({
                  property,
                  comparator:
                    property === "created_at" ? "dateStrings" : undefined,
                })
              },
            })}
          >
            <TransitionGroup component={null}>
              {sortedData.slice(0, count).map((transaction) => (
                <CSSTransition
                  key={transaction.id}
                  classNames="animation-fade"
                  timeout={700}
                  unmountOnExit
                >
                  <TableRow
                    setSelectedItems={setSelectedItems}
                    getCheckboxProps={(item) => ({
                      checked: getIsChecked(item),
                      onChange: () => {
                        toggleItem(item)
                      },
                    })}
                    transaction={transaction}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Table>
        </div>
      )}
    />
  )
}

export default TableView
