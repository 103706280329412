export const surveyProposition = [
  {
    label: "Another teacher",
    value: 1,
  },
  {
    label: "Stash101",
    value: 2,
  },
  {
    label: "Google",
    value: 3,
  },
  {
    label: "Instagram",
    value: 4,
  },
  {
    label: "Facebook",
    value: 5,
  },
  {
    label: "Tiktok",
    value: 6,
  },
  {
    label: "Next Gen Personal Finance (NGPF)",
    value: 7,
  },
  {
    label: "New EdTech Classroom",
    value: 8,
  },
  {
    label: "A student",
    value: 9,
  },
  {
    label: "Other",
    value: 10,
  },
]
