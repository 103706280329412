import { Typography } from "@mui/material"
import { IResponseInvitationsProPlan } from "api/reactQuery/queries/invitations.types"
import { IResponseTeachersManagement } from "api/reactQuery/queries/teachersManagement.types"
import Dialog from "components/common/dialog/dialog"
import { useTranslation } from "react-i18next"

interface IProps {
  isOpen: boolean
  handleClose: () => void
  teacher: IResponseInvitationsProPlan | IResponseTeachersManagement
  invitations: number
  mutate: ({ invitation_id }: { invitation_id: number }) => void
}

const DialogRemoveTeacher = ({
  isOpen,
  teacher,
  mutate,
  handleClose,
  invitations,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      actionAcceptText={t("coteachers.remove")}
      titleText={t("coteachers.areYouSureYouWantToRemoveInvitationTo", {
        email: teacher.email,
      })}
      onActionButtonClick={() => {
        mutate({
          invitation_id: Number(invitations),
        })
      }}
    >
      <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
        {t("coteachers.thisCantBeRestored")}
      </Typography>
    </Dialog>
  )
}

export default DialogRemoveTeacher
