import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { IStudentSavingsArgs, StudentSavingsTypes } from "./savings.types"

export const useQueryStudentSavings = (args?: IStudentSavingsArgs) =>
  useQuery<AxiosResponse<StudentSavingsTypes[]>, AxiosError>(
    ["studentSavings"],
    async () => {
      return axiosInstance(Endpoint.STUDENT_SAVINGS)
    },
    args?.options
  )
