import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StudentSavingsTypes } from "api/reactQuery/queries/savings.types"

import { initialStateDefault } from "./savings.utility"

const initialState: initialStateDefault = {
  data: null,
}

export const savingsSlice = createSlice({
  name: "savings",
  initialState,
  reducers: {
    setAccount: (
      state,
      { payload }: PayloadAction<StudentSavingsTypes | null>
    ) => {
      state.data = payload
    },
  },
})

export const { setAccount } = savingsSlice.actions

export default savingsSlice.reducer
