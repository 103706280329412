import theme from "themes"
import { passwordSchema, requiredEmailSchema, termsSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  email: "",
  password: "",
  terms: false,
}

export const validationSchema = yup.object({
  email: requiredEmailSchema,
  password: passwordSchema,
  terms: termsSchema,
})

export const TERMS = (
  <a
    style={{ color: theme.palette.primary.main, textDecoration: "underline" }}
    href="https://help.classbank.com/en/articles/6203282-terms-of-service"
    target="_blank"
    rel="noreferrer"
  ></a>
)
export const POLICY = (
  <a
    style={{ color: theme.palette.primary.main, textDecoration: "underline" }}
    href="https://help.classbank.com/en/articles/6203095-privacy-policy"
    target="_blank"
    rel="noreferrer"
  ></a>
)
