import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import {
  Avatar,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useMutationRemoveInvitations } from "api/reactQuery/mutations/teacherManagement"
import { IResponseInvitationsProPlan } from "api/reactQuery/queries/invitations.types"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { BoxIconTrash } from "styles/common/box"
import { getErrorMessageFromTab } from "utils/api"

import DialogRemoveTeacher from "../dialogRemoveTeacher"

export interface IProps {
  teacher: IResponseInvitationsProPlan
}

const GridViewInviteProPlan = ({ teacher }: IProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const isOneGrid = useMediaQuery(theme.breakpoints.down("desktop"))
  const isTablet = useMediaQuery(theme.breakpoints.only("tablet"))
  const queryClient = useQueryClient()

  const { mutate: removeInvitations } = useMutationRemoveInvitations({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("coteachers.invitationHasBeenRemoved"),
        })
        queryClient.invalidateQueries("invitationsProPlan")
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Grid
      item
      textAlign="center"
      px="10px"
      py="24px"
      bgcolor="mockup.neutral100"
      height={isTablet ? "100%" : "283px"}
      width="222px"
      maxWidth={isOneGrid ? "100%" : "257px"}
      flex="1 0 auto"
      flexWrap="wrap"
      borderRadius="16px"
      position="relative"
      sx={{
        overflowY: "auto",
        border: `2px solid ${theme.palette.mockup.primary95}`,
      }}
    >
      <BoxIconTrash>
        <IconTrash
          style={{ margin: "auto" }}
          onClick={() => {
            handleOpen()
          }}
        />
      </BoxIconTrash>
      <Avatar
        alt="avatar"
        sx={{
          width: isTablet ? "48px" : "100px",
          height: isTablet ? "48px" : "100px",
          margin: "0 auto",
        }}
      />
      <Typography
        variant="subtitle1"
        mt={2}
        sx={{ wordBreak: "break-word" }}
        color="mockup.grey70"
      >
        {teacher.email}
      </Typography>
      <DialogRemoveTeacher
        invitations={teacher.id}
        isOpen={isOpen}
        handleClose={handleClose}
        teacher={teacher}
        mutate={removeInvitations}
      />
    </Grid>
  )
}

export default GridViewInviteProPlan
