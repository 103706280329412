import { FC } from "react"

import { Box } from "@mui/material"
import ContentTabs from "components/common/navigation/contentTabs"
import { useClassId } from "hooks/navigation"
import { Outlet } from "react-router"
import { transformRoutes } from "utils/tab"

import { setupRoutes } from "./TeacherSetupLayout.config"

const TeacherSetupLayout: FC = () => {
  const classId = useClassId()
  const tabs = transformRoutes(setupRoutes, ":classId", classId)

  return (
    <Box position="relative">
      <Box position="absolute" width="100%" top="0" left="0">
        <ContentTabs tabs={tabs} />
      </Box>
      <Box height="43px" width="100%" />
      <Outlet />
    </Box>
  )
}

export default TeacherSetupLayout
