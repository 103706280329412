export const coteachers = {
  coteachers: "Co-teachers",
  youDontHaveAnyCoteachersYet: "You don’t have any co-teachers yet.",
  shareThisClassWithAnotherTeacherByAddingThemAsCoTeacher:
    "Share this class with another teacher by adding them as a co-teacher! All co-teachers will have full access to this class to send transactions, edit students and settings, and approve store purchases.",
  addCoTeacher: "Add Co-teacher",
  searchForTeacher: "Search for teacher",
  areYouSureYouWantToRemovePersonFromTheClass:
    "Are you sure you want to remove {{email}} from the class?",
  areYouSureYouWantToRemoveCoTeacherFromTheClass:
    "Are you sure you want to remove {{firstname}} {{lastname}} from the class?",
  areYouSureYouWantToRemoveInvitationTo:
    "Are you sure you want to remove invitation to {{email}}",
  invitationHasBeenRemoved: "Invitation has been removed",
  invitationHasBeenSent: "Invitation has been sent",
  invitationsHaveBeenSent: "{{count}} Invitations has been sent",
  areYouSureYouWantDeleteTheTeacher:
    "Are you sure you want to delete {{firstname}} {{lastname}} from the class?",
  youWillOnlyRemoveATeacherFromThisClassAndYouCanStillAddThemToItLater:
    "You will only remove a teacher from this class and you can still add them to it later",
  thisCantBeRestored: "This can't be restored",
  inviteCoteacher: "Invite co-teacher",
  sendInvite: "Send invite",
  descriptionUpgradeTeacher:
    "Co-teachers is a feature included in the 'Pro Subscription' and 'School Subscription'. Learn more about our <Link>paid plans here</Link>. Upgrade to pro or school to unlock this feature.",
  upgrade: "Upgrade",
  remove: "Remove",
  userHasBeenSuccessfullyAddedToClass:
    "{{firstname}} {{lastname}} has been successfully added to class",
  usersHasBeenSuccessfullyAddedToClass:
    "Teachers have been successfully added to class",
  userHasBeenSuccessfullyRemovedFromClass:
    "{{firstname}} {{lastname}} has been successfully removed from class",
  noMatchesForUser: "No matches for <strong>{{fullname}}</strong>",
  noOptions: "No options",
  enterTheAddressesOfTheTeachersYouWantToInviteAsCoteachers:
    "Enter the addresses of the teachers you want to invite as a co-teachers, separating them with commas. They will have access to <strong>{{classname}}</strong> and all of students in this class.",
  emailAddresses: "Email addresses",
  separateAddressesWithCommas: "Separate addresses with commas",
  reject: "Reject",
  accept: "Accept",
  teachers: "Teachers",
  inviteTeachers: "Invite teachers",
  enterTheAddressesOfTheTeachersYouWantToInviteToYourSchool:
    "Enter the addresses of the teachers you want to invite to your school, separating them with commas. They will have access to all students and classes in the school.",
  userHasBeenSuccessfullyAccepted:
    "{{firstname}} {{lastname}} has been succesfully accepted",
  userHasBeenSuccessfullyRejected:
    "{{firstname}} {{lastname}} has been succesfully rejected",
  active: "Active",
  pending: "Pending",
  youHaveToSelectTeacher: "You have to select teacher",
  notSeeingTheTeacherYouWantToAdd: "Not seeing the teacher you want to add?",
  inviteThemToJoinClassBank: "Click here to invite them to ClassBank!",
  thisTeacherHasntYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass:
    "This teacher hasn't yet upgraded to PRO! Invite them to sign up for PRO and join your class!",
  theseTeacherHaventYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass:
    "These teachers haven't yet upgraded to PRO! Invite them to sign up for PRO and join your class!",
  thoseTeachersHaveDifferentPlansSendAndAddThemSeparately:
    "Those Teachers have different plans. Send and add them separately",
  listOfTeacherYouCanAddToTheClass: "List of teacher you can add to the class:",
}
