import { FC } from "react"

import { CheckboxProps, Stack } from "@mui/material"
import ActionButtons from "components/common/button/actionButtons"
import SecondaryCheckableItem from "components/common/listing/secondaryCheckableItem"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { IClassStoreItem } from "ts/interfaces/Class"

interface IProps {
  items: IClassStoreItem[]
  getListItemCheckboxProps: (el: IClassStoreItem) => CheckboxProps
}

const Listing: FC<IProps> = ({ items, getListItemCheckboxProps }) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <TransitionGroup component={null}>
        {items.map((item) => (
          <CSSTransition
            key={item.id}
            classNames="animation-fade"
            timeout={700}
            unmountOnExit
          >
            <SecondaryCheckableItem
              textTitle={t("store.item")}
              quantity_in_stock={item.quantity_in_stock}
              text={item.name}
              value={item.price}
              actionButtonsSlot={<ActionButtons item={item} />}
              active={!item.out_of_stock}
              checkboxProps={getListItemCheckboxProps(item)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Stack>
  )
}

export default Listing
