import React from "react"

import { ReactComponent as Expenses } from "@brand_assets/svg/expenses.svg"
import { ReactComponent as Fines } from "@common_assets/svg/finest.svg"
import { ReactComponent as GreenDollar } from "@common_assets/svg/greenDollar.svg"
import { ReactComponent as IconInterest } from "@common_assets/svg/interest.svg"
import { ReactComponent as Purchase } from "@common_assets/svg/purchase.svg"
import { ReactComponent as Refund } from "@common_assets/svg/refund.svg"
import { ReactComponent as Reset } from "@common_assets/svg/reset.svg"
import { ReactComponent as IconSettings } from "@common_assets/svg/settings.svg"
import { ReactComponent as BriefCase } from "@common_assets/svg/suitcase.svg"
import { ReactComponent as IconTransfer } from "@common_assets/svg/transferToChecking.svg"
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material"
import { IAccountTransactions } from "api/reactQuery/queries/transaction.types"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { AccountTransactionType } from "ts/enums/Transaction"

import Coin from "../../../../common/icon/coin"

interface TransactionsListingProps {
  data: IAccountTransactions
}

const TransactionsListing = ({ data }: TransactionsListingProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const TransactionType = ({ type }: { type: AccountTransactionType }) => {
    if (type === AccountTransactionType.TRANSFER) return <IconTransfer />
    if (type === AccountTransactionType.INTEREST) return <IconInterest />
    if (type === AccountTransactionType.BONUS) return <GreenDollar />
    if (type === AccountTransactionType.FINE) return <Fines />
    if (type === AccountTransactionType.EXPENSE) return <Expenses />
    if (type === AccountTransactionType.SALARY) return <BriefCase />
    if (type === AccountTransactionType.PURCHASE) return <Purchase />
    if (type === AccountTransactionType.REFUND) return <Refund />
    if (type === AccountTransactionType.RESET) return <Reset />

    return <IconSettings />
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        mx: "-16px",
        p: "12px 16px",
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.mockup.primary99,
        },
      }}
    >
      <Box width="44px" height="44px" flex="1 1 50px">
        <TransactionType type={data.type} />
      </Box>
      <Box height="48px" px={2}>
        <Divider orientation="vertical" />
      </Box>
      <Box flex="1 1 100%">
        <Typography variant="body2">{data.title}</Typography>
        <Typography variant="body2" fontWeight="500">
          {format(new Date(data.created_at), "MMM dd, yyyy")}
        </Typography>
      </Box>
      <Stack flex="1 1 0" justifyContent="end" alignItems="end">
        <Coin
          amount={Math.abs(data.amount)}
          sign={data.amount > 0 ? "+" : data.amount < 0 ? "-" : undefined}
        />
        {data.is_booked ? (
          <Stack direction="row" alignItems="center" columnGap={1}>
            <Typography variant="body2">{`${t(
              "myActivity.balance"
            )}:`}</Typography>
            <Coin amount={data.balance} />
          </Stack>
        ) : (
          <Box display="flex" alignItems="center" columnGap="4px">
            <span
              style={{
                display: "block",
                height: "6px",
                width: "6px",
                backgroundColor: theme.palette.mockup.secondary60,
                borderRadius: "50%",
              }}
            />
            <Typography variant="body3" color="mockup.secondary60">
              {t("myActivity.pending")}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
export default TransactionsListing
