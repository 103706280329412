import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentOverview } from "ts/interfaces/Student"

import { IGetDashboardOverviewArgs } from "./dashboardOverview.types"

export const useQueryDashboardOverview = (args?: IGetDashboardOverviewArgs) =>
  useQuery<AxiosResponse<IStudentOverview[]>, AxiosError>(
    ["studentOverview", JSON.stringify(args?.params)],
    async () =>
      axiosInstance(Endpoint.DASHBOARD_OVERVIEW, {
        params: args?.params,
      }),
    {
      ...args?.options,
    }
  )
