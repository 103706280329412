import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  ICoteacherArgs,
  ICoteacherRemoveArgs,
  IPostCoteacherAdd,
  IPostCoteachersRemove,
  IResponseCoteacherAdd,
} from "./coteachers.types"

export const useMutationCoteachersAdd = (args: ICoteacherArgs) =>
  useMutation<
    AxiosResponse<IResponseCoteacherAdd>,
    AxiosError,
    IPostCoteacherAdd
  >(
    async (data) =>
      axiosInstance.post(
        Endpoint.SCHOOL_CLASSES_ID_COTEACHERS.replace("ID", `${args.classId}`),
        data
      ),
    { ...args?.options }
  )

export const useMutationCoteachersRemove = (args: ICoteacherRemoveArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostCoteachersRemove>(
    async ({ classId, teacherId }) => {
      return axiosInstance.delete(
        Endpoint.SCHOOL_CLASSES_CLASSID_COTEACHERS_TEACHERID.replace(
          "CLASSID",
          `${classId}`
        ).replace("TEACHERID", `${teacherId}`)
      )
    },
    args?.options
  )
