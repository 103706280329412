import { ReactNode } from "react"

import { Button, Stack, Tooltip, Typography } from "@mui/material"
import { IStudentSideTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll.type"
import TransactionIcon from "components/common/icon/transactionIcon"
import BasicTable from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"

interface IProps {
  topSlot: ReactNode
  bottomSlot?: ReactNode
  handleTransactionClick?: (transaction: IStudentSideTransactionsAll) => void
  data: IStudentSideTransactionsAll[] | []
}

const TransactionsTable = ({
  topSlot,
  bottomSlot,
  handleTransactionClick,
  data,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <BasicTable
      topSlot={topSlot}
      head={
        <BasicTable.Row>
          <BasicTable.Cell width="80px">
            <BasicTable.HeadText textTransform="uppercase">
              {t("dashboardStudent.type")}
            </BasicTable.HeadText>
          </BasicTable.Cell>
          <BasicTable.Cell width="120px">
            <BasicTable.HeadText textTransform="uppercase">
              {t("dashboardStudent.class")}
            </BasicTable.HeadText>
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "100%" }}>
            <BasicTable.HeadText textTransform="uppercase">
              {t("dashboardStudent.title")}
            </BasicTable.HeadText>
          </BasicTable.Cell>
          <BasicTable.Cell width="158px" />
        </BasicTable.Row>
      }
      body={
        <TransitionGroup component={null}>
          {data.map((transaction) => (
            <CSSTransition
              key={transaction.id}
              classNames="animation-fade"
              timeout={700}
              unmountOnExit
            >
              <BasicTable.Row>
                <BasicTable.Cell py="2px" style={{ position: "relative" }}>
                  <TransactionIcon variant={transaction.type} />
                </BasicTable.Cell>
                <BasicTable.Cell sx={{ wordBreak: "break-all" }}>
                  <Stack>
                    <Typography variant="body2" color="mockup.neutral10">
                      {transaction.class.name}
                    </Typography>
                  </Stack>
                </BasicTable.Cell>
                <BasicTable.Cell position="relative">
                  <Tooltip title={transaction.title}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="mockup.neutral20"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      position="absolute"
                      width="calc(100% - 32px)"
                    >
                      {transaction.title}
                    </Typography>
                  </Tooltip>
                </BasicTable.Cell>
                <BasicTable.Cell>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      handleTransactionClick &&
                        handleTransactionClick(transaction)
                    }}
                  >
                    {transaction.type === "bonus" ||
                    transaction.type === "salary"
                      ? t("dashboardStudent.deposit")
                      : t("dashboardStudent.withdraw")}
                  </Button>
                </BasicTable.Cell>
              </BasicTable.Row>
            </CSSTransition>
          ))}
        </TransitionGroup>
      }
      bottomSlot={bottomSlot}
    />
  )
}

export default TransactionsTable
