import { FC, useEffect, useState } from "react"

import { IPostClassesTransactionsInput } from "api/reactQuery/mutations/classesTransactions.types"
import { useQueryHelpUpdateClassesStudents } from "api/reactQuery/queries/classesStudents"
import { useDialog } from "hooks/dialog"
import { userUpdateBalanceClasslist } from "hooks/filter"
import { useClassId } from "hooks/navigation"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useNavigate } from "react-router"
import { RoutePath } from "routes/Route.types"
import { selectClassList } from "store/utility/utility.selectors"
import { setClassList } from "store/utility/utility.slice"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

import TransactionsDialogForm from "../transactionsDialogForm"
import { ListItemActions } from "../ViewCommonElementsContainer.types"

interface IProps {
  action: {
    actionName: ListItemActions
    chosenItem: IObjectStudentDetailed
  } | null
  resetActionState: () => void
}

const ListItemActionsHandler: FC<IProps> = ({ action, resetActionState }) => {
  const { isOpen, handleClose, handleOpen } = useDialog()
  const navigate = useNavigate()
  const classId = useClassId()
  const [isUpdate, setIsUpdate] = useState(false)
  const [variables, setVariables] =
    useState<IPostClassesTransactionsInput | null>(null)
  const dispatch = useAppDispatch()
  const [isClickOnButton, setIsClickOnButton] = useState(false)

  const classList = useAppSelector(selectClassList)

  const { data } = useQueryHelpUpdateClassesStudents({
    classId: Number(classId),
    page: 0,
    limit: 200,
    options: {
      enabled: isUpdate,
      cacheTime: 0,
      staleTime: 0,
    },
  })

  useEffect(() => {
    if (data && isClickOnButton) {
      const updatedClassList = userUpdateBalanceClasslist({
        classlist: classList,
        variable: variables,
        updatedClasslist: data?.data.objects,
      })

      dispatch(setClassList(updatedClassList))
      setIsUpdate(false)
      setVariables(null)
      setIsClickOnButton(false)
    }
  }, [data])

  useEffect(() => {
    if (!action) return
    if (action.actionName === "sendTransaction") {
      setIsClickOnButton(true)
      handleOpen()
    }
    if (action.actionName === "visitProfile") {
      navigate(
        `${RoutePath.STUDENT.replace(":studentId", `${action.chosenItem.id}`)}`
      )
    }
  }, [action])

  if (!action) return null

  return (
    <TransactionsDialogForm
      setVariables={setVariables}
      isOpen={isOpen}
      action={action.actionName}
      handleClose={() => {
        resetActionState()
        handleClose()
      }}
      studentsIds={[action.chosenItem.id]}
      onSuccess={() => {
        handleClose()
        resetActionState()
        setIsUpdate(true)
      }}
    />
  )
}

export default ListItemActionsHandler
