import { FC } from "react"

import { Typography } from "@mui/material"

interface IProps {
  text: string
  onClick: () => void
}

const ListItem: FC<IProps> = ({ text, onClick }) => {
  return (
    <Typography
      onClick={onClick}
      variant="body2"
      minWidth="190px"
      fontWeight={500}
      color="mockup.neutral10"
      px="16px"
      py="12px"
      sx={{
        cursor: "pointer",
        "&:hover": {
          background: (theme) => theme.palette.mockup.primary99,
        },
      }}
    >
      {text}
    </Typography>
  )
}

export default ListItem
