import axiosInstance from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { classTransactionItemsEndpoints } from "utils/reactQuery"

import {
  IDeleteClassesTransactionItemsArgs,
  IDeleteClassesTransactionItemsInput,
  IPatchClassesTransactionItemsArgs,
  IPatchClassesTransactionItemsInput,
  IPostClassesTransactionItemsArgs,
  IPostClassesTransactionItemsInput,
} from "./classesTransactionItems.types"

export const useMutationPostClassesTransactionItems = (
  args?: IPostClassesTransactionItemsArgs
) =>
  useMutation<
    AxiosResponse<ITransactionItem[]>,
    AxiosError,
    IPostClassesTransactionItemsInput
  >(
    async ({ classId, variant, data }) =>
      await axiosInstance.post(
        classTransactionItemsEndpoints[variant].replace("ID", `${classId}`),
        data
      ),
    args?.options
  )

export const useMutationPatchClassesTransactionItems = (
  args?: IPatchClassesTransactionItemsArgs
) =>
  useMutation<
    AxiosResponse<ITransactionItem>,
    AxiosError,
    IPatchClassesTransactionItemsInput
  >(
    async ({ classId, itemId, variant, data }) =>
      await axiosInstance.patch(
        `${classTransactionItemsEndpoints[variant].replace(
          "ID",
          `${classId}`
        )}/${itemId}`,
        data
      ),
    args?.options
  )

export const useMutationDeleteClassesTransactionItems = (
  args?: IDeleteClassesTransactionItemsArgs
) =>
  useMutation<
    AxiosResponse<null>,
    AxiosError,
    IDeleteClassesTransactionItemsInput
  >(
    async ({ classId, itemId, variant }) =>
      await axiosInstance.delete(
        `${classTransactionItemsEndpoints[variant].replace(
          "ID",
          `${classId}`
        )}/${itemId}`
      ),
    args?.options
  )
