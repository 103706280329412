import { FC, useEffect } from "react"

import { Accordion, Grid, Typography } from "@mui/material"
import { useQueryClassesTransactionItems } from "api/reactQuery/queries/classesTransactionItems"
import TextField from "components/form/common/field/textField"
import mixpanel from "mixpanel-browser"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { getFieldName } from "utils/field"

import DetailsTransaction from "../accordion/detailsTransaction"
import Summary from "../accordion/summary"
import { ISectionProps } from "../Transactions.types"

const Bonuses: FC<ISectionProps> = ({
  formRoot,
  classId,
  defaultExpanded,
  studentId,
}) => {
  const { t } = useTranslation()

  const { getValues, setValue } = useFormContext()

  const bonusesFieldName = getFieldName(formRoot, "bonuses")

  const bonusesWatcher: ITransactionItem[] = useWatch({
    name: bonusesFieldName,
    exact: true,
  })

  const { data, isLoading, isError } = useQueryClassesTransactionItems({
    classId: Number(classId),
    variant: "bonus",
    options: {
      enabled: !!classId,
    },
  })

  useEffect(() => {
    if (classId) {
      setValue(bonusesFieldName, [])
    }
  }, [classId])

  const onTileClick = (item: ITransactionItem) => {
    setValue(bonusesFieldName, [...bonusesWatcher, item])
  }

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <Summary
        title={t("transaction.bonuses")}
        selectedItemsAmount={bonusesWatcher.length}
      />
      <DetailsTransaction
        selectedItems={bonusesWatcher}
        noElementsMessage={t("transaction.classDontHaveAnyBonusesYet")}
        data={data?.data}
        isLoading={isLoading}
        isError={isError}
        onTileClick={(item) => {
          onTileClick(item)
          if (formRoot === "transactions") {
            if (getValues("transactions.bonuses").length === 1) {
              mixpanel.track("send_transaction", {
                event_name: "pre-set_bonuses",
              })
              return
            }
          }
          if (getValues("bonuses").length === 1) {
            mixpanel.track("send_transaction", {
              event_name: "pre-set_bonuses",
            })
          }
        }}
        variant="bonus"
        studentId={studentId}
        fieldName={bonusesFieldName}
      >
        {(!!studentId || !!classId) && (
          <>
            <Typography variant="body3" color="mockup.neutral40">
              {t("transaction.other")}
            </Typography>
            <PrimaryTile p="24px 16px 32px" sx={{ borderRadius: "16px" }}>
              <Grid container columnSpacing="16px" rowSpacing="40px">
                <Grid item desktop={8} tablet={6} mobile={12}>
                  <TextField
                    onFocus={(e) => {
                      if (!e.target.value.length) {
                        mixpanel.track("send_transaction", {
                          event_name: "form_bonuses",
                        })
                      }
                    }}
                    label={t("transaction.bonusTitle")}
                    size="small"
                    name={getFieldName(formRoot, "bonusTitle")}
                  />
                </Grid>
                <Grid item desktop={4} tablet={6} mobile={12}>
                  <TextField
                    label={t("transaction.value")}
                    size="small"
                    name={getFieldName(formRoot, "bonusValue")}
                  />
                </Grid>
              </Grid>
            </PrimaryTile>
          </>
        )}
      </DetailsTransaction>
    </Accordion>
  )
}

export default Bonuses
