import { LiteralUnion } from "react-hook-form"

export const iconNames = [
  "bell",
  "bill",
  "bolt",
  "bone",
  "crown",
  "dna",
  "document",
  "flag",
  "gift",
  "grid",
  "heart",
  "home",
  "hourglass",
  "ice",
  "image",
  "key",
  "medkit",
  "moon",
  "shirt",
  "star",
  "stopwatch",
  "suitcase",
  "sun",
  "tooth",
  "trophy",
  "umbrella",
  "wallet",
  "wand",
  "waterdrop",
  "basket",
  "calendarclock",
  "camera",
  "clip",
  "compass",
  "draw",
  "explore",
  "global",
  "multiple",
  "note",
  "offer",
  "phone",
  "pin",
  "questionmark",
  "roundplus",
  "share",
  "shieldcross",
  "shoottarget",
  "textline",
  "monitor",
  "beaker",
  "email",
  "meds",
  "tube",
] as const

export type IconName = typeof iconNames[number]

export type IconType = LiteralUnion<IconName, string>

export type IconVariant = "outlined" | "solid"
