import React from "react"

import { Stack, Typography, useTheme } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"

import TableView from "./tableView"

const Banker = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.common.white, mb: 2, p: 2 }}
      >
        <Typography fontSize="28px" flex="0 0 300px">
          {t("layout.sendTransactions")}
        </Typography>
        {isTablet && (
          <Typography variant="subtitle2" fontWeight="500" flex="1 1 0">
            You’ve been assigned as a Student Banker. This means you now have
            the ability to send transactions to students. Remember, all
            transactions you make will have your name on them, so stay
            trustworthy!
          </Typography>
        )}
      </Stack>
      {/*{displayMode === "grid"} && <GridView />*/}
      <TableView />
    </Stack>
  )
}
export default Banker
