import React from "react"

import { Divider, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

interface IProps {
  isToggleAll: boolean | undefined
  excludedItems: IObjectStudentDetailed[]
  selectedItems: IObjectStudentDetailed[]
  isCaution?: boolean
  actionText: string
}

const DialogContent = ({
  isToggleAll,
  excludedItems,
  selectedItems,
  isCaution,
  actionText,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <Stack>
      {isToggleAll ? (
        <Typography
          variant="subtitle1"
          fontWeight="500"
          color="mockup.neutral10"
        >
          {!!excludedItems.length
            ? t(`students.youWill${actionText}AllOfStudentsInThisClassExcept`)
            : t(`students.youWill${actionText}AllOfStudentsFromClass`)}
        </Typography>
      ) : (
        <Typography
          variant="subtitle1"
          fontWeight="500"
          color="mockup.neutral10"
        >
          {t(`students.youWill${actionText}TheFollowingStudents`)}
        </Typography>
      )}
      {!!excludedItems.length && <Divider sx={{ mb: 2 }} />}
      {isToggleAll &&
        excludedItems.map((student) => (
          <Typography key={student.id} color="mockup.neutral10" variant="body2">
            {student.first_name} {student.last_name}
          </Typography>
        ))}
      {!isToggleAll &&
        selectedItems.map((student) => (
          <Typography key={student.id} color="mockup.neutral10" variant="body2">
            {student.first_name} {student.last_name}
          </Typography>
        ))}
      {isCaution && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight="700" color="mockup.error50">
            {t("students.thisActionCannotBeUndone").toUpperCase()}
          </Typography>
        </>
      )}
    </Stack>
  )
}

export default DialogContent
