import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { IGetCountriesArgs, IResponseGetCountries } from "./getCountries.types"

export const useQueryGetCountries = (args?: IGetCountriesArgs) =>
  useQuery<AxiosResponse<IResponseGetCountries[]>, AxiosError>(
    ["getCountries"],
    async () => {
      return await axiosInstance(Endpoint.COUNTRIES)
    },
    {
      ...args?.options,
    }
  )
