import { FC } from "react"

import { isIconName } from "utils/icon"

import { icons } from "./DynamicIcon.config"
import { IProps } from "./DynamicIcon.types"

const DynamicIcon: FC<IProps> = ({ name, variant }) => {
  if (!isIconName(name)) return <icons.home.outlined />

  const Icon = icons[name][variant]

  return <Icon />
}

export default DynamicIcon
