import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { selectDisplayMode } from "store/displayMode/displayMode.selectors"

import GridView from "./gridView"
import ListingView from "./listingView"
import TableView from "./tableView"

const ClassStudentsTeacher = () => {
  const isTablet = useIsBreakpointUp("tablet")
  const displayMode = useAppSelector(selectDisplayMode)

  if (displayMode === "grid") return <GridView />
  if (isTablet) return <TableView />
  return <ListingView />
}

export default ClassStudentsTeacher
