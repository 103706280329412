import React, { useEffect, useState } from "react"

import { ReactComponent as IconTransferToSaving } from "@common_assets/svg/transferToChecking.svg"
import { Box, MenuItem, TextField, Typography } from "@mui/material"
import { useMutationAccountTransfer } from "api/reactQuery/mutations/accountTransfer"
import { StudentSavingsTypes } from "api/reactQuery/queries/savings.types"
import { useQueryStudentBalance } from "api/reactQuery/queries/studentBalance"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"

import Dialog from "../dialog/dialog"
import Coin from "../icon/coin"

interface AccountTransferDialogProps {
  isOpen: boolean
  handleClose: () => void
  data?: StudentSavingsTypes[]
}

type TInputFields = {
  selectedFrom: string | number
  selectedTo: string | number
  valueAmount: string
}

const AccountTransferDialog = ({
  isOpen,
  handleClose,
  data,
}: AccountTransferDialogProps) => {
  const [selectedFrom, setSelectedFrom] = useState<string | number>("")
  const [selectedTo, setSelectedTo] = useState<string | number>("")
  const [isFromBalance, setIsFromBalance] = useState(false)
  const [isToBalance, setIsToBalance] = useState(false)
  const [valueAmount, setValueAmount] = useState("")
  const [error, setError] = useState({
    selectedFrom: "",
    selectedTo: "",
    valueAmount: "",
  })

  const {
    isOpen: isOpenConfirmTransfer,
    handleOpen: handleOpenConfirmTransfer,
    handleClose: handleCloseConfirmTransfer,
  } = useDialog()

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  useEffect(() => {
    setIsToBalance(selectedTo === 0)
    setIsFromBalance(selectedFrom === 0)
  }, [selectedFrom, selectedTo])

  const cleanerVariables = () => {
    setSelectedFrom("")
    setSelectedTo("")
    setValueAmount("")
    setError({
      selectedFrom: "",
      selectedTo: "",
      valueAmount: "",
    })
  }

  const handleConfirmTransfer = () => {
    mutate({
      amount: Number(valueAmount),
      is_from_balance: isFromBalance,
      is_to_balance: isToBalance,
      account_from_id: !isFromBalance ? Number(selectedFrom) : undefined,
      account_to_id: !isToBalance ? Number(selectedTo) : undefined,
    })
  }

  const { data: balance } = useQueryStudentBalance()

  const getInterestPercent = (
    id: number | string,
    data?: StudentSavingsTypes[]
  ) => {
    if (data && typeof id === "number") {
      return data.find((item) => item.id === id)?.saving_interest_rate
    }
  }

  const { mutate, isLoading } = useMutationAccountTransfer({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "account transfer has been successfully",
        })
        queryClient.invalidateQueries("studentSavings")
        queryClient.invalidateQueries("studentChart")
        queryClient.invalidateQueries("account_transactions")
        queryClient.invalidateQueries("studentBalance")
        handleClose()
        handleCloseConfirmTransfer()
        cleanerVariables()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const handleActionButtonClick = () => {
    const inputFields: TInputFields = {
      selectedFrom,
      selectedTo,
      valueAmount,
    }

    let isFormValid = true

    Object.keys(inputFields).forEach((key) => {
      const inputFieldsKey = key as keyof TInputFields

      if (inputFields[inputFieldsKey] === "") {
        setError((prev) => ({
          ...prev,
          [key]: "Required Field",
        }))

        isFormValid = false
      } else {
        setError((prev) => ({
          ...prev,
          [key]: "",
        }))
      }
    })

    if (!isFormValid) return
    handleOpenConfirmTransfer()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose()
          cleanerVariables()
        }}
        onIconClose
        titleText="Account Transfer"
        titleBeforeSlot={<IconTransferToSaving />}
        onActionButtonClick={handleActionButtonClick}
      >
        <TextField
          value={selectedFrom}
          onChange={(e) => setSelectedFrom(e.target.value)}
          select
          label="From"
          helperText={error.selectedFrom}
          error={!!error.selectedFrom}
        >
          {selectedTo !== 0 && balance && (
            <MenuItem value={0}>
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography>Checking account </Typography>
                <Coin amount={balance?.data.balance} />
              </Box>
            </MenuItem>
          )}
          {data
            ?.filter((item) => item.id !== Number(selectedTo))
            .map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  columnGap={1}
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <Typography> {option.name}</Typography>
                    <Coin amount={option.available_amount_to_transfer} />
                  </Box>
                  <Typography
                    variant="body2"
                    fontSize="500"
                  >{`(${option.saving_interest_rate}% interest)`}</Typography>
                </Box>
              </MenuItem>
            ))}
        </TextField>
        <TextField
          select
          label="To"
          value={selectedTo}
          onChange={(e) => setSelectedTo(e.target.value)}
          helperText={error.selectedTo}
          error={!!error.selectedTo}
        >
          {selectedFrom !== 0 && balance && (
            <MenuItem value={0}>
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography>Checking account </Typography>
                <Coin amount={balance?.data.balance} />
              </Box>
            </MenuItem>
          )}
          {data
            ?.filter((item) => item.id !== Number(selectedFrom))
            .map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  columnGap={1}
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <Typography> {option.name}</Typography>
                    <Coin amount={option.available_amount_to_transfer} />
                  </Box>
                  <Typography
                    variant="body2"
                    fontSize="500"
                  >{`(${option.saving_interest_rate}% interest)`}</Typography>
                </Box>
              </MenuItem>
            ))}
        </TextField>
        <TextField
          onKeyDown={(e) =>
            handleKeyDown(e, handleActionButtonClick, isLoading)
          }
          label="Transfer amount"
          helperText={error.valueAmount}
          value={valueAmount}
          onChange={(e) => setValueAmount(e.target.value)}
          error={!!error.valueAmount}
        />
      </Dialog>
      <Dialog
        actionAcceptButtonProps={{ disabled: isLoading }}
        open={isOpenConfirmTransfer}
        onKeyDown={(e) => handleKeyDown(e, handleConfirmTransfer, isLoading)}
        onClose={handleCloseConfirmTransfer}
        titleText={`Are you sure you want to transfer this money out of ${
          selectedFrom !== 0 ? "savings" : "checking"
        }?`}
        actionAcceptText="yes, transfer"
        onActionButtonClick={handleConfirmTransfer}
      >
        {selectedFrom !== 0 && (
          <Typography>
            This money could earn {getInterestPercent(selectedFrom, data)}%{" "}
            interest if kept in savings.
          </Typography>
        )}
      </Dialog>
    </>
  )
}
export default AccountTransferDialog
