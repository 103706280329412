import React from "react"

import { Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

const Second = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Typography
        variant="h2"
        color="mockup.neutral50"
        sx={{ textAlign: "left", mb: 4 }}
      >
        {t("noAuthFlow.yourPasswordHasBeenSuccessfullyChanged")}
      </Typography>
      <Button
        fullWidth
        onClick={() => {
          navigate(RoutePath.LOGIN)
        }}
      >
        {t("noAuthFlow.goBackToLoginPage")}
      </Button>
    </>
  )
}

export default Second
