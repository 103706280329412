import { FC } from "react"

import { Box, Button, Stack } from "@mui/material"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { TransactionTypeShort } from "ts/types/Transaction"

import { getSetupClassTranslation } from "../../../SetupClassTeacher.utils"

interface IProps {
  variant: TransactionTypeShort
  onAddSuggestedClick: () => void
  onAddClick: () => void
}

const NoItemsYet: FC<IProps> = ({
  onAddSuggestedClick,
  onAddClick,
  variant,
}) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  return (
    <Box p={{ mobile: "16px", desktop: "62px" }}>
      <NoElementsMessage
        boxStyle={{ py: { desktop: 0, tablet: "46px" } }}
        title={t(tKey.youDontHaveAnyItemsYet)}
        description={t(tKey.youCanChooseFromOurSuggestedItems)}
        bottomSlot={
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            gap="16px"
            mt="24px"
          >
            <Button
              onClick={() => {
                onAddClick()
                mixpanel.track("add_class_settings", {
                  action: `add_${variant}`,
                })
              }}
            >
              {t(tKey.addItem)}
            </Button>
            <Button
              onClick={() => {
                onAddSuggestedClick()
                mixpanel.track("add_class_settings", {
                  action: `add_suggested_${variant}`,
                })
              }}
              variant="outlined"
            >
              {t(tKey.addSuggestedItems)}
            </Button>
          </Stack>
        }
      />
    </Box>
  )
}

export default NoItemsYet
