import { FC } from "react"

import { Skeleton } from "@mui/material"

interface IProps {
  height: string
  width?: string
  style?: React.CSSProperties
}

const StudentSkeleton: FC<IProps> = ({
  children,
  width = "100%",
  height,
  style,
}) => {
  return (
    <Skeleton width={width} height={height} style={style}>
      {children}
    </Skeleton>
  )
}

export default StudentSkeleton
