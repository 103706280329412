import React from "react"

import { ReactComponent as IconCoin } from "@brand_assets/svg/coin.svg"
import { Stack, Grid, Typography, Box } from "@mui/material"
import { useQueryStudentSavingAccounts } from "api/reactQuery/queries/studentSavingAccounts"
import { useQueryStudentsBalanceCharts } from "api/reactQuery/queries/studentsBalanceCharts"
import DynamicIcon from "components/common/icon/dynamicIcon"
import IconWrapper from "components/common/icon/iconWrapper"
import StudentCharts from "components/module/studentProfile/studentCharts"
import { format, subDays } from "date-fns"
import { useAppSelector } from "hooks/store"
import { useParams } from "react-router"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"

import StudentSkeleton from "../../../common/skeleton/studentProfile"

const Accounts = () => {
  const params = useParams()
  const switchState = useAppSelector(selectDisplaySavings)

  const {
    data: studentSavingAccountsData,
    isLoading: studentSavingAccountLoading,
  } = useQueryStudentSavingAccounts({
    student_id: Number(params.studentId),
  })

  const {
    data: studentsBalanceChartsData,
    isLoading: studentBalanceChartDataLoading,
  } = useQueryStudentsBalanceCharts({
    student_id: Number(params.studentId),
    start_date: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  })

  if (studentSavingAccountLoading || studentBalanceChartDataLoading) {
    return (
      <Box mt={2}>
        <StudentSkeleton height="100px" />
      </Box>
    )
  }

  if (!studentSavingAccountsData?.data.length) {
    return (
      <Stack my={3}>
        <Typography variant="subtitle1">
          The student&apos;s class does not have a savings account enabled.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack py={2}>
      <Grid container spacing={2}>
        <Grid item mobile={12}>
          <Box width="100%">
            <Stack
              direction={{ mobile: "column", desktop: "row" }}
              columnGap={1}
              width="100%"
            >
              {!!studentsBalanceChartsData && !switchState && (
                <StudentCharts
                  data={studentsBalanceChartsData?.data.student_balance_data}
                  title="Checking"
                  totalTitle="Total Checking"
                />
              )}
              {!!studentsBalanceChartsData && switchState && (
                <>
                  <StudentCharts
                    data={
                      studentsBalanceChartsData?.data.student_total_balance_data
                    }
                    title="Balance"
                    totalTitle="Total Balance"
                  />
                  <StudentCharts
                    data={studentsBalanceChartsData?.data.student_balance_data}
                    title="Checking"
                    totalTitle="Total Checking"
                  />
                  <StudentCharts
                    data={
                      studentsBalanceChartsData?.data.student_savings_total_data
                    }
                    title="Savings"
                    totalTitle="Total Savings"
                  />
                </>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item tablet={12} mobile={12} desktop={6}>
          {studentSavingAccountsData?.data && (
            <Stack
              direction="row"
              justifyContent="space-between"
              minHeight="168px"
              bgcolor="mockup.primary60"
              borderRadius="16px"
              sx={{
                padding: "32px",
                border: "1px solid",
                borderColor: "mockup.primary95",
              }}
            >
              <Stack direction="column" justifyContent="space-between">
                <Typography
                  color="mockup.primary99"
                  variant="subtitle1"
                  fontWeight="500"
                >
                  Checking Account
                </Typography>
                <Stack
                  direction="row"
                  columnGap="4px"
                  alignItems="center"
                  pb="14px"
                >
                  <IconWrapper height="20px" width="20px">
                    <IconCoin height="20px" width="20px" />
                  </IconWrapper>
                  <Typography variant="h2" color="mockup.primary99">
                    {studentSavingAccountsData.data[0]?.checking_balance}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                bgcolor="mockup.primary95"
                color="mockup.primary60"
                width="48px"
                height="48px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
              >
                <DynamicIcon name="wallet" variant="solid" />
              </Stack>
            </Stack>
          )}
        </Grid>
        {studentSavingAccountsData?.data.map((student) => {
          return (
            <Grid item key={student.id} mobile={12} tablet={12} desktop={6}>
              <Stack
                justifyContent="space-between"
                minHeight="168px"
                bgcolor="mockup.primary99"
                borderRadius="16px"
                sx={{
                  padding: "32px",
                  paddingBottom: "28px",
                  border: "1px solid",
                  borderColor: "mockup.primary95",
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1" fontWeight="500">
                    {student.name}
                  </Typography>
                  <Stack
                    bgcolor="mockup.neutral100"
                    color="mockup.primary60"
                    width="48px"
                    height="48px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DynamicIcon name={student.icon} variant="solid" />
                  </Stack>
                </Stack>
                <Stack>
                  <Stack direction="row" columnGap="4px" alignItems="center">
                    <IconWrapper height="20px" width="20px">
                      <IconCoin height="20px" width="20px" />
                    </IconWrapper>
                    <Typography variant="h2">{student.balance}</Typography>
                  </Stack>
                  <Stack direction="row" columnGap={1}>
                    <Typography variant="body2" fontWeight="500">
                      Interest:
                    </Typography>
                    <Typography variant="body2">
                      {student.saving_interest_rate}%/
                      {student.interest_granting_interval}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

export default Accounts
