import { FC } from "react"

import { ReactComponent as SvgBasket } from "@common_assets/svg/basket.svg"
import { Box, Stack, Typography } from "@mui/material"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"

import { Cart } from "../../StoreStudent.types"

interface IProps {
  cart: Cart
  isDialogView?: boolean
}

const StoreCart: FC<IProps> = ({ cart, isDialogView, children }) => {
  const { t } = useTranslation()

  return (
    <PrimaryTile
      p={isDialogView ? "0px" : "24px"}
      sx={{
        borderColor: isDialogView
          ? "transparent"
          : (theme) => theme.palette.mockup.neutral90,
        overflowY: "auto",
        maxHeight: "calc(100vh - 68px - 80px)",
      }}
      className="smooth-scrollbar"
    >
      <Stack gap="24px">
        {!isDialogView && (
          <Typography
            fontSize="24px"
            lineHeight="26px"
            color="mockup.netural10"
          >
            {t("storeStudent.cart")}
          </Typography>
        )}
        {Object.keys(cart).length === 0 ? (
          <Box pb="72px">
            <NoElementMessageWithoutBtn
              title={t("storeStudent.yourCartIsEmpty")}
              description={t("storeStudent.chooseSomethingFromTheStore")}
              Icon={<SvgBasket />}
            />
          </Box>
        ) : (
          children
        )}
      </Stack>
    </PrimaryTile>
  )
}

export default StoreCart
