import { styled, TextField } from "@mui/material"

export const TextFieldStyle = styled(TextField)<{ invalid: boolean }>(
  ({ theme, invalid }) => ({
    label: {
      color: theme.palette.mockup.neutral60,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: invalid && `2px solid ${theme.palette.mockup.error50}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: invalid && `2px solid ${theme.palette.mockup.error50}`,
    },
  })
)
