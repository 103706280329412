import { FC } from "react"

import {
  MenuItem,
  TextField as TextFieldMui,
  TextFieldProps,
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IInputOption } from "ts/interfaces/Input"

import { getFieldError } from "../field.utils"

type Props = Omit<TextFieldProps, "select"> & {
  name: string
  options: IInputOption[]
  emptyValue?: string
}

const SelectField: FC<Props> = ({
  name,
  error,
  helperText,
  options,
  emptyValue,
  ...props
}) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState: { error: fieldError },
      }) => {
        const { isError, errorMessage } = getFieldError(fieldError, t)

        return (
          <TextFieldMui
            {...props}
            select
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputRef={ref}
            error={error || isError}
            helperText={helperText || errorMessage}
          >
            {emptyValue && (
              <MenuItem key={emptyValue} value="">
                {emptyValue}
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldMui>
        )
      }}
    />
  )
}

export default SelectField
