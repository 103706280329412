import { requiredNumberSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  title: "",
  description: "",
  total_amount: 0,
  icon_url: "",
}

export const validationSchema = yup.object({
  title: requiredStringSchema,
  total_amount: requiredNumberSchema.positive(),
})
