import { createContext, useContext } from "react"

import { IResponseSavingsCharts } from "api/reactQuery/queries/chart.types"

type IDataState = IResponseSavingsCharts
interface TransactionContextProps {
  data: IDataState | undefined
  isLoading: boolean
}
export const TransactionContext = createContext<TransactionContextProps | null>(
  null
)

export const useTransaction = () => useContext(TransactionContext)
