import { FC } from "react"

import { Typography } from "@mui/material"
import { useMutationDeleteClassesTransactionItems } from "api/reactQuery/mutations/classesTransactionItems"
import Dialog from "components/common/dialog/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"
import { getErrorMessage } from "utils/api"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"

interface IProps {
  item: ITransactionItem | null
  variant: TransactionTypeShort
  isOpen: boolean
  handleClose: (reason?: "closed" | "itemDeleted") => void
}

const DialogDeleteItem: FC<IProps> = ({
  item,
  variant,
  isOpen,
  handleClose,
}) => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  const classId = useClassId()

  const { isLoading: isLoadingDelete, mutate: deleteTransactionItem } =
    useMutationDeleteClassesTransactionItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(`classes${variant}`)
          showSnackbar({
            title: t(tKey.itemHasBeenDeleted, { name: item?.title }),
          })
          handleClose("itemDeleted")
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  if (!item) return null

  return (
    <Dialog
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          deleteTransactionItem({
            classId: Number(classId),
            itemId: item.id,
            variant,
          })
        }
      }}
      open={isOpen}
      onClose={() => handleClose("closed")}
      titleText={t(tKey.areYouSureYouWantToDeleteItem, {
        name: item.title,
      })}
      actionAcceptText={t("delete")}
      isLoading={isLoadingDelete}
      onActionButtonClick={() => {
        deleteTransactionItem({
          classId: Number(classId),
          itemId: item.id,
          variant,
        })
      }}
    >
      <Typography variant="subtitle2" fontWeight={500}>
        {t("setup.thisActionCannotBeUndone")}.
      </Typography>
    </Dialog>
  )
}

export default DialogDeleteItem
