import React from "react"

import TextField from "components/form/common/field/textField"
import { useTranslation } from "react-i18next"
import { InputName, InputType } from "ts/types/RegisterInput"

import PasswordTextField from "./passwordTextField"

interface IProps {
  name: InputName
  type: InputType
  isSignUp?: boolean
}

const RegisterInput = ({ name, type, isSignUp }: IProps) => {
  const { t } = useTranslation()
  return (
    <>
      <TextField
        name={name}
        type={type}
        fullWidth
        label={t("noAuthFlow.email")}
        sx={{ mb: 5 }}
      />
      <PasswordTextField isSignUp={isSignUp} />
    </>
  )
}

export default RegisterInput
