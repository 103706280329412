import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IImportStudentsPost,
  IImportStudentsReponse,
  ImportStudentsArgs,
} from "./importClassStudent.types"

export const useMutationImportStudents = (args?: ImportStudentsArgs) =>
  useMutation<
    AxiosResponse<IImportStudentsReponse>,
    AxiosError,
    IImportStudentsPost
  >(async ({ file, classId }) => {
    const formData = new FormData()
    formData.append("file", file)
    return await axiosInstance.post(
      Endpoint.CLASSES_ID_STUDENTS_IMPORT.replace("ID", `${classId}`),
      formData
    )
  }, args?.options)
