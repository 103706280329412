import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { IGetSchoolsArgs, IResponseGetSchools } from "./getSchools.types"

export const useQueryGetSchools = (args?: IGetSchoolsArgs) =>
  useQuery<AxiosResponse<IResponseGetSchools>, AxiosError>(
    ["getSchools", args?.params],
    async () => {
      return axiosInstance(Endpoint.SCHOOLS, {
        params: {
          skip: args?.params.skip,
          limit: args?.params.limit,
          filter: args?.params.filter,
        },
      })
    },
    { ...args?.options }
  )
