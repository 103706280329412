import { ThemeOptions } from "@mui/material"

const muiButton: ThemeOptions["components"] = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: "contained",
    },
    styleOverrides: {
      root: {
        borderRadius: 16,
        padding: "16px 32px",
        lineHeight: "16px",
        boxShadow: "none",
      },
    },
    variants: [
      {
        props: { variant: "contained", color: "primary" },
        style: ({ theme }) => ({
          ":hover": {
            backgroundColor: theme.palette.mockup.primary80,
            boxShadow: "none",
          },
          ":focus": {
            backgroundColor: theme.palette.mockup.primary20,
          },
          ":disabled": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
          },
        }),
      },
      {
        props: { variant: "outlined", color: "primary" },
        style: ({ theme }) => ({
          padding: "14px 30px",
          border: `2px solid ${theme.palette.primary.light}`,
          ":hover": {
            border: `2px solid ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.mockup.primary95,
          },
          ":focus": {
            border: `2px solid ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.light,
          },
          ":disabled": {
            border: `2px solid ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.mockup.primary80,
          },
        }),
      },
      {
        props: { variant: "text", color: "primary" },
        style: ({ theme }) => ({
          padding: "16px",
          backgroundColor: "transparent",
          color: theme.palette.primary.main,
          ":hover": {
            color: theme.palette.mockup.primary80,
            backgroundColor: "transparent",
          },
          ":focus": {
            color: theme.palette.primary.dark,
          },
          ":disabled": {
            color: theme.palette.primary.light,
          },
        }),
      },
      {
        props: { size: "small" },
        style: {
          minHeight: "48px",
          fontSize: "16px",
        },
      },
    ],
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
}

export default muiButton
