import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostClassesStudentsArgs,
  IPostClassesStudentsInput,
} from "./classesStudents.types"

export const useMutationPostClassesStudents = (
  args?: IPostClassesStudentsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostClassesStudentsInput>(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_ID_STUDENTS.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )
