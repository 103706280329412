import React, { Dispatch } from "react"

import { Box, Button, Typography } from "@mui/material"
import { IStudentTransactionTeacherSide } from "api/reactQuery/queries/transaction.types"
import { useTranslation } from "react-i18next"
import { Type } from "ts/enums/Filtration"

import MobileTable from "../MobileTable"

interface IProps {
  copyArrayFiltersName: Type[]
  data: IStudentTransactionTeacherSide[]
  isLoading: boolean
  isFetching: boolean
  setNextTransactions: Dispatch<React.SetStateAction<number>>
  limitCounter: number
  isNextTransactions?: boolean
  setDataTransactions: Dispatch<
    React.SetStateAction<IStudentTransactionTeacherSide[]>
  >
}
const MobileTableContainer = ({
  data,
  isLoading,
  setNextTransactions,
  limitCounter,
  isNextTransactions,
  isFetching,
  setDataTransactions,
  copyArrayFiltersName,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <>
      <MobileTable
        transactions={data}
        setDataTransactions={setDataTransactions}
      />
      <Box display="flex" justifyContent="center">
        <>
          {isNextTransactions && !isLoading && !isFetching && (
            <Button
              variant="text"
              onClick={() => {
                setNextTransactions((prev) => prev + limitCounter)
              }}
            >
              {t("studentProfile.loadMore")}
            </Button>
          )}
          {!data.length &&
            !isFetching &&
            !isLoading &&
            !!copyArrayFiltersName.length && (
              <Typography
                variant="body1"
                color="mockup.neutral10"
                sx={{ py: 1 }}
              >
                No results for: {copyArrayFiltersName.join(", ")}{" "}
              </Typography>
            )}
          {!data.length &&
            !copyArrayFiltersName.length &&
            !isFetching &&
            !isLoading && (
              <Typography
                variant="body1"
                color="mockup.neutral10"
                sx={{ py: 2 }}
              >
                {t("studentProfile.nothingToDisplay")}
              </Typography>
            )}
        </>
      </Box>
    </>
  )
}

export default MobileTableContainer
