import { useCallback, useState } from "react"

export const useDialog = (initialIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return { isOpen, handleOpen, handleClose }
}
