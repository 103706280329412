import React from "react"

import "utils/yup/setLocale"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from "App"
import AppProviders from "AppProviders"
import ReactDOM from "react-dom"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  environment: process.env.REACT_APP_SENTRY_ENV as string,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_SENTRY_TRACE as string],
    }),
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_RATE),
})

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
)
