import React from "react"

import { Typography } from "@mui/material"

export const fillTextOnInut = (text: string, noticeText: string) => {
  if (!text.trim().length) {
    return (
      <Typography
        sx={{ cursor: "pointer", p: "5px", borderRadius: "8px" }}
        textAlign="center"
        bgcolor="mockup.neutral95"
        color="mockup.neutral60"
      >
        {noticeText}
      </Typography>
    )
  } else {
    return text
  }
}
