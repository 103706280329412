import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  ITeacherTransactionsArgs,
  IPostTeacherTransactions,
  IResponseTeacherTransactions,
} from "./transactions.types"

export const useMutationTransactions = (args?: ITeacherTransactionsArgs) =>
  useMutation<
    AxiosResponse<IResponseTeacherTransactions>,
    AxiosError,
    IPostTeacherTransactions
  >(
    async (data) =>
      await axiosInstance.delete(Endpoint.TEACHER_TRANSACTIONS, { data }),
    args?.options
  )
