import { ReactComponent as IconFilters } from "@common_assets/svg/filters.svg"
import { Fab, Popover, Stack, Typography } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import RadioField from "components/form/common/field/radioField"
import RadioFieldGroup from "components/form/common/field/radioFieldGroup"
import { usePopover } from "hooks/popover"
import { useTranslation } from "react-i18next"

const FabFiltersPopover = () => {
  const { t } = useTranslation()

  const { popoverAnchorEl, handleClick, handleClose } =
    usePopover<HTMLButtonElement>()

  return (
    <>
      <Fab sx={{ flexShrink: 0 }} onClick={handleClick}>
        <IconWrapper height="32px" width="32px" color="primary.main">
          <IconFilters />
        </IconWrapper>
      </Fab>
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack p="32px" rowGap="24px">
          <Stack rowGap="16px">
            <Typography variant="subtitle2">
              {t("dashboard.filterByTimeRange")}
            </Typography>
            <RadioFieldGroup name="timeRange">
              <Stack rowGap="16px">
                <RadioField
                  value="this_month"
                  label={t("dashboard.thisMonth")}
                />
                <RadioField
                  value="last_month"
                  label={t("dashboard.lastMonth")}
                />
                <RadioField value="this_year" label={t("dashboard.year")} />
              </Stack>
            </RadioFieldGroup>
          </Stack>
          <Stack rowGap="16px">
            <Typography variant="subtitle2">{t("dashboard.sortBy")}</Typography>
            <RadioFieldGroup name="sort">
              <Stack rowGap="16px">
                <RadioField
                  value="most_bonuses"
                  label={t("dashboard.mostBonuses")}
                />
                <RadioField
                  value="least_bonuses"
                  label={t("dashboard.fewestBonuses")}
                />
              </Stack>
            </RadioFieldGroup>
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

export default FabFiltersPopover
