import { Box, BoxProps, styled } from "@mui/material"

type FilterType = "isbunch" | "isdragging"

type ForwardedProps = Exclude<keyof BoxProps, FilterType>

export const DraggingBoxStyle = styled(Box, {
  shouldForwardProp(propName: PropertyKey): propName is ForwardedProps {
    return !["isbunch", "isdragging"].includes(propName as string)
  },
})<{
  isdragging: boolean
  isbunch?: boolean
}>(({ theme, isdragging, isbunch }) => ({
  display: "flex",
  margin: "8px 0",
  alignItems: "center",
  borderRadius: "4px",
  position: "sticky",
  zIndex: 10,
  top: 0,
  cursor: "move",
  background: isdragging
    ? theme.palette.mockup.primary80
    : isbunch
    ? theme.palette.mockup.primary95
    : theme.palette.common.white,
  color: isdragging
    ? theme.palette.mockup.primary80
    : theme.palette.mockup.neutral10,
  transition: "all 0.2s ease",
  "&::after": {
    // eslint-disable-next-line quotes
    content: '""',
    position: "absolute",
    borderRadius: "4px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.mockup.primary80,
    opacity: isdragging ? 1 : 0,
    transition: "opacity 0.2s ease",
    pointerEvents: "none",
  },
}))
