import { CSSTransition, TransitionGroup } from "react-transition-group"

import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import Table from "./table"
import TableRow from "./tableRow"

const TableView = () => {
  return (
    <ViewCommonElementsContainer
      renderDataView={({
        apiData,
        sortingProps: {
          sortedData,
          handleSortingClick,
          getIsSortingActive,
          getSortingDirection,
        },
        selectingProps: { selectedItems, getIsChecked, toggleItem, toggleAll },
      }) => (
        <Table
          checkboxProps={{
            checked:
              sortedData.length === selectedItems.length &&
              !!apiData?.data.length,
            onChange: toggleAll,
            indeterminate:
              sortedData.length !== selectedItems.length &&
              !!selectedItems.length,
          }}
          getTableSortLabelProps={(property) => ({
            active: getIsSortingActive(property),
            direction: getSortingDirection(property),
            onClick: () => {
              handleSortingClick({ property })
            },
          })}
        >
          <TransitionGroup component={null}>
            {sortedData.map((item) => (
              <CSSTransition
                key={item.id}
                classNames="animation-fade"
                timeout={700}
                unmountOnExit
              >
                <TableRow
                  item={item}
                  getCheckboxProps={(item) => ({
                    checked: getIsChecked(item),
                    onChange: () => {
                      toggleItem(item)
                    },
                  })}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Table>
      )}
    />
  )
}

export default TableView
