import { FC, useState } from "react"

import { Grid } from "@mui/material"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import StudentSkeleton from "components/common/skeleton/studentProfile"

import JobsOfferAside from "./jobsOfferAside"
import JobsOfferSpecify from "./jobsOfferSpecify"
import { JOBS_PANEL_VIEW_BREAKPOINT } from "./JobsPanel.config"

const JobsPanel: FC<{
  jobsState: IGetStudentPossibleJobsInClass[]
  isLoading: boolean
}> = ({ jobsState, isLoading }) => {
  const [jobId, setJobId] = useState<number | null>(null)
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Grid
      container
      spacing={3}
      flexWrap={{ mobile: "wrap-reverse", desktop: "nowrap" }}
      sx={(theme) => ({
        flexWrap: "wrap-reverse",
        [theme.breakpoints.up(JOBS_PANEL_VIEW_BREAKPOINT)]: {
          flexWrap: "nowrap",
        },
      })}
    >
      <Grid
        item
        sx={(theme) => ({
          flexBasis: "100%",
          maxWidth: "100%",
          [theme.breakpoints.up(JOBS_PANEL_VIEW_BREAKPOINT)]: {
            flexBasis: "30%",
            maxWidth: "30%",
          },
        })}
      >
        {isLoading ? (
          <StudentSkeleton height="300px" />
        ) : (
          <JobsOfferAside
            jobsState={jobsState}
            setJobId={setJobId}
            handleExpandToggle={() => {
              setIsExpanded((prev) => !prev)
            }}
          />
        )}
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          flexBasis: "100%",
          maxWidth: "100%",
          [theme.breakpoints.up(JOBS_PANEL_VIEW_BREAKPOINT)]: {
            flexBasis: "70%",
            maxWidth: "70%",
          },
        })}
      >
        {isLoading ? (
          <StudentSkeleton height="300px" />
        ) : (
          <JobsOfferSpecify jobId={jobId} isExpanded={isExpanded} />
        )}
      </Grid>
    </Grid>
  )
}

export default JobsPanel
