import { FC, useState } from "react"

import { TableSortLabel, Typography } from "@mui/material"
import { useQueryClassesTransactionItems } from "api/reactQuery/queries/classesTransactionItems"
import Coin from "components/common/icon/coin"
import BasicTable from "components/common/table/basicTable"
import { useDialog } from "hooks/dialog"
import { useSorting } from "hooks/listing"
import { useFilterItems } from "hooks/listing/filtering"
import { useClassId } from "hooks/navigation"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"

import ActionButtons from "../common/actionButtons"
import DataFetchingStatus from "../common/dataFetchingStatus"
import DialogAddItem from "../common/dialogAddItem"
import DialogAddSuggested from "../common/dialogAddSuggested"
import DialogDeleteItem from "../common/dialogDeleteItem"
import DialogEditItem from "../common/dialogEditItem"
import SearchbarAndAddButton from "../common/searchbarAndAddButton"
import { getSetupClassTranslation } from "../SetupClassTeacher.utils"

interface IProps {
  variant: TransactionTypeShort
}

const Table: FC<IProps> = ({ variant }) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)
  const classId = useClassId()

  const { data, isLoading, isError } = useQueryClassesTransactionItems({
    classId: Number(classId),
    variant,
    options: {
      enabled: typeof Number(classId) === "number",
    },
  })

  const { filteredData, searchFilter, setSearchFilter } = useFilterItems({
    data: data ? data.data : [],
    getFilterBy: (el) => el.title,
  })

  const {
    sortedData,
    handleSortingClick,
    getIsSortingActive,
    getSortingDirection,
  } = useSorting({
    data: filteredData,
  })

  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete,
  } = useDialog()

  const {
    isOpen: isOpenEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit,
  } = useDialog()

  const {
    isOpen: isOpenAdd,
    handleOpen: handleOpenAdd,
    handleClose: handleCloseAdd,
  } = useDialog()

  const {
    isOpen: isOpenAddSuggested,
    handleOpen: handleOpenAddSuggested,
    handleClose: handleCloseAddSuggested,
  } = useDialog()

  const [selectedItem, setSelectedItem] = useState<ITransactionItem | null>(
    null
  )

  return (
    <>
      <BasicTable
        wrapperProps={{
          borderRadius: "0 8px 8px 8px",
        }}
        topSlot={
          <SearchbarAndAddButton
            variant={variant}
            onAddItemClick={() => {
              handleOpenAdd()
              mixpanel.track("add_class_settings", {
                action: `add_${variant}_searchbar`,
              })
            }}
            onSearchFormSubmit={(data) => {
              setSearchFilter(data.search)
            }}
          />
        }
        head={
          <BasicTable.Row>
            <BasicTable.Cell
              minWidth="100px"
              tableCellProps={{ width: "100%" }}
            >
              <TableSortLabel
                active={getIsSortingActive("title")}
                direction={getSortingDirection("title")}
                onClick={() => {
                  handleSortingClick({ property: "title" })
                }}
              >
                <BasicTable.HeadText>{t(tKey.items)}</BasicTable.HeadText>
              </TableSortLabel>
            </BasicTable.Cell>
            <BasicTable.Cell width="80px">
              <TableSortLabel
                active={getIsSortingActive("amount")}
                direction={getSortingDirection("amount")}
                onClick={() => {
                  handleSortingClick({ property: "amount" })
                }}
              >
                <BasicTable.HeadText width="100%" textAlign="center">
                  {t("setup.value")}
                </BasicTable.HeadText>
              </TableSortLabel>
            </BasicTable.Cell>
            <BasicTable.Cell minWidth="109px" />
          </BasicTable.Row>
        }
        body={
          <TransitionGroup component={null}>
            {data &&
              !isLoading && [
                ...sortedData.map((el) => (
                  <CSSTransition
                    key={el.id}
                    classNames="animation-fade"
                    timeout={700}
                    unmountOnExit
                  >
                    <BasicTable.Row>
                      <BasicTable.Cell py="6px">
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color="mockup.neutral10"
                        >
                          {el.title}
                        </Typography>
                      </BasicTable.Cell>
                      <BasicTable.Cell py="6px" justifyContent="center">
                        <Coin amount={`${el.amount}`} />
                      </BasicTable.Cell>
                      <BasicTable.Cell py="6px">
                        <ActionButtons
                          variant={variant}
                          handleOpenDelete={() => {
                            setSelectedItem(el)
                            handleOpenDelete()
                          }}
                          handleOpenEdit={() => {
                            setSelectedItem(el)
                            handleOpenEdit()
                          }}
                        />
                      </BasicTable.Cell>
                    </BasicTable.Row>
                  </CSSTransition>
                )),
              ]}
          </TransitionGroup>
        }
        bottomSlot={
          <DataFetchingStatus
            variant={variant}
            isLoading={isLoading}
            isError={isError}
            data={data?.data}
            sortedAndFilteredData={sortedData}
            searchFilter={searchFilter}
            onAddItemButtonClick={handleOpenAdd}
            onAddSuggestedClick={handleOpenAddSuggested}
          />
        }
      />
      <DialogDeleteItem
        item={selectedItem}
        variant={variant}
        isOpen={isOpenDelete}
        handleClose={(reason) => {
          handleCloseDelete()
          if (!isOpenEdit || reason === "itemDeleted") setSelectedItem(null)
        }}
      />
      <DialogEditItem
        item={selectedItem}
        variant={variant}
        isOpen={isOpenEdit}
        handleClose={() => {
          handleCloseEdit()
          setSelectedItem(null)
        }}
        handleOpenDelete={handleOpenDelete}
      />
      <DialogAddItem
        variant={variant}
        isOpened={isOpenAdd}
        onClose={() => {
          handleCloseAdd()
        }}
      />
      <DialogAddSuggested
        variant={variant}
        isOpened={isOpenAddSuggested}
        onClose={() => {
          handleCloseAddSuggested()
        }}
      />
    </>
  )
}

export default Table
