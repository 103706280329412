export const studentHelpers = {
  student: "Student",
  storeClerk: "Store Clerk",
  manageClassStore: "Manage class store",
  hrRepresentative: "HR Representative",
  manageJobBoard: "Manage job board",
  banker: "Banker",
  sendTransactions: "Send transactions",
  descriptionUpgradeTeacher:
    "Student Helpers is a feature included in the 'Pro Subscription' and 'School Subscription'. Learn more about our <Link>paid plans here</Link>. Upgrade to pro or school to unlock this feature.",
  studentHelper: "Student Helper",
  manageSchoolStore:
    "Manage School store: Allow students to approve store purchases and edit store items",
  manageHrRepresentative:
    "Manage job board: Allow students to manage the job board and edit jobs",
  manageSendTransactions:
    "Send transactions: Allow students to send transactions",
}
