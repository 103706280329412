import React, { Dispatch } from "react"

import { ReactComponent as IconX } from "@common_assets/svg/cross.svg"
import { Box, Typography, Stack, Divider } from "@mui/material"
import { ConnectDragSource } from "react-dnd"
import { useTranslation } from "react-i18next"

import { ICheckedPerson, IStudentsToChecked } from "../../Jobs"

interface IProps {
  drag: ConnectDragSource
  checkedPerson: ICheckedPerson[]
  isDragging: boolean
  setStudentsToChecked: Dispatch<React.SetStateAction<IStudentsToChecked[]>>
  setCheckedPerson: Dispatch<React.SetStateAction<ICheckedPerson[]>>
  setIsCheckAll: Dispatch<React.SetStateAction<boolean>>
}

const MultipleBoardHire = ({
  drag,
  checkedPerson,
  isDragging,
  setCheckedPerson,
  setStudentsToChecked,
  setIsCheckAll,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <Box
      ref={drag}
      sx={(theme) => ({
        padding: !!checkedPerson.length ? "10px" : "0",
        borderRadius: 1,
        position: "sticky",
        zIndex: "2",
        top: 0,
        cursor: "move",
        background: isDragging
          ? theme.palette.mockup.primary80
          : theme.palette.mockup.primary95,
        color: isDragging
          ? theme.palette.mockup.primary80
          : theme.palette.mockup.neutral10,
      })}
    >
      <Stack>
        <Typography
          textAlign="start"
          fontWeight="500"
          variant="body2"
          component="h3"
          pr={2}
        >
          {t("jobs.hireMultipleStudentsAtOnce")}:
          <Divider sx={{ width: "100%", my: "10px" }} />
        </Typography>
        <Typography sx={{ textAlignLast: "left" }}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              pl: "10px",
            }}
          >
            {t("jobs.selected")} : {checkedPerson.length}
          </Typography>
        </Typography>
        <Divider sx={{ width: "100%", my: "10px" }} />
        <Typography fontWeight="500" variant="body2">
          {t("jobs.dragFromHereToHire")}
        </Typography>
      </Stack>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: "3",
          cursor: "pointer",
          ":hover": {
            transition: "color 0.3s",
            color: theme.palette.mockup.error50,
          },
        })}
      >
        <IconX
          onClick={() => {
            setStudentsToChecked((prev) =>
              prev.map((person) => ({ ...person, checked: false }))
            )
            setCheckedPerson([])
            setIsCheckAll(false)
          }}
        />
      </Box>
    </Box>
  )
}

export default MultipleBoardHire
