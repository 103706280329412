import { useEffect } from "react"

import { Box } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useLocation } from "react-router"
import { selectIsMenuOpened } from "store/menu/menu.selectors"
import { setIsMenuOpened, toggleIsMenuOpened } from "store/menu/menu.slice"

const Overlay = () => {
  const isDesktop = useIsBreakpointUp("desktop")
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const isMenuOpened = useAppSelector(selectIsMenuOpened)

  const toggleMenu = () => {
    dispatch(toggleIsMenuOpened())
  }

  useEffect(() => {
    dispatch(setIsMenuOpened(false))
  }, [pathname])

  return (
    <>
      {!isDesktop && isMenuOpened && (
        <Box
          onClick={toggleMenu}
          width="100vw"
          height="100%"
          position="fixed"
          bgcolor="mockup.neutral10Opacity"
          zIndex="1150"
          top={0}
        />
      )}
    </>
  )
}

export default Overlay
