import { ReactComponent as IconChevronDown } from "@common_assets/svg/chevron-down.svg"
import { ThemeOptions } from "@mui/material"

const muiInput: ThemeOptions["components"] = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        fontWeight: "500",
        position: "absolute",
        bottom: 0,
        transform: "translateY(100%)",
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <IconChevronDown />,
    },
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          padding: "6px 10px",

          "&.MuiInputBase-adornedStart": {
            paddingLeft: "16px",
          },

          "& .MuiAutocomplete-input": {
            padding: "6px 4px 6px 6px",
          },

          "&.MuiInputBase-adornedStart .MuiAutocomplete-input": {
            paddingLeft: "0px",
          },
        },
        "& .MuiOutlinedInput-root": {
          padding: "10px",

          "&.MuiInputBase-adornedStart": {
            paddingLeft: "16px",
          },

          "& .MuiAutocomplete-input": {
            padding: "8px 4px 8px 6px",
          },

          "&.MuiInputBase-adornedStart .MuiAutocomplete-input": {
            paddingLeft: "0px",
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: IconChevronDown,
    },
    styleOverrides: {
      select: {
        textAlign: "start",
      },
      icon: ({ theme }) => ({
        top: "50%",
        transform: "translateY(-50%)",
        color: theme.palette.mockup.brightBlue,
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        lineHeight: "16px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: ({ theme }) => ({
        color: theme.palette.mockup.neutral0,
        height: "22px",
        lineHeight: "22px",
        padding: "18px 16px",
        fontWeight: "500",

        "&.MuiInputBase-inputSizeSmall": {
          padding: "12px 16px",
          height: "24px",
          lineHeight: "24px",
        },

        "&::placeholder": {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "18px",
          color: theme.palette.mockup.neutral60,
        },
      }),
      adornedStart: {
        paddingLeft: "16px",

        "&.MuiInputBase-inputSizeSmall": {
          paddingLeft: "16px",
        },
      },
      inputAdornedStart: {
        paddingLeft: 0,

        "&.MuiInputBase-inputSizeSmall": {
          paddingLeft: 0,
        },
      },
      adornedEnd: {
        paddingRight: "16px",
      },
      inputAdornedEnd: {
        paddingRight: 0,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        "&:disabled": {},
        input: {
          borderRadius: "16px",
          color: theme.palette.mockup.neutral0,
          WebkitBoxShadow: "0 0 0 1000px white inset",
          "&::placeholder": {
            fontSize: 14,
            fontWeight: "500",
          },
        },
        label: {
          color: `${theme.palette.mockup.neutral40}`,
          opacity: 0.5,
          fontSize: 12,
          top: 6,
          paddingLeft: 8,
          fontWeight: "700",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          paddingLeft: 14,
          borderRadius: 16,
          border: `2px solid ${theme.palette.mockup.primary95}`,
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            transitionDuration: "300ms",
            border: `2px solid ${theme.palette.mockup.primary80}`,
            boxShadow: "0 0 0 4px #D2DAF9",
          },
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: `${theme.palette.mockup.neutral40}`,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            border: `2px solid ${theme.palette.mockup.primary95}`,
            boxShadow: "none",
            // backgroundColor: theme.palette.mockup.primary99,
          },
      }),
    },
  },
}

export default muiInput
