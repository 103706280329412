import { ReactComponent as IconJobsClosed } from "@common_assets/svg/store-closed.svg"
import { Stack } from "@mui/material"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const JobsClosed = () => {
  const { t } = useTranslation()
  return (
    <Stack height="100%" direction="row" alignItems="center">
      <NoElementMessageWithoutBtn
        title={t("studentJobs.jobsAreClosed")}
        description={t("studentJobs.checkBackLaterOrAskYourTeacher")}
        Icon={<IconJobsClosed />}
      />
    </Stack>
  )
}

export default JobsClosed
