import React, { useEffect, useState } from "react"

import { Button, CircularProgress, Typography } from "@mui/material"
import { useMutationConfirmEmail } from "api/reactQuery/mutations/teacher"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { getErrorMessage } from "utils/api"
import { clearAuthDataLocalStorage } from "utils/authData"

import Layout from "../password/layout"

const EmailConfirmation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [renderText, setRenderText] = useState({
    success: false,
    fail: {
      message: "",
      type: false,
    },
  })

  const { mutate, isLoading } = useMutationConfirmEmail({
    options: {
      onSuccess: () => {
        setRenderText((prevState) => ({ ...prevState, success: true }))
      },
      onError: (err) => {
        setRenderText((prevState) => ({
          ...prevState,
          fail: { message: getErrorMessage(err), type: true },
        }))
      },
    },
  })

  useEffect(() => {
    const token = location.search
    const queryParams = new URLSearchParams(token)
    const queryName = queryParams.get("token")

    if (queryName) {
      mutate({ token: queryName })
    }
  }, [])

  return (
    <Layout>
      <>
        {isLoading ? (
          <span>
            <CircularProgress />
          </span>
        ) : (
          <>
            {renderText.success && !isLoading && (
              <>
                <Typography
                  variant="h2"
                  color="mockup.neutral50"
                  sx={{ textAlign: "left" }}
                >
                  Congratulations!
                  <span style={{ display: "block" }}>
                    Your Email has been confirmed.
                  </span>
                </Typography>
                <Typography sx={{ textAlign: "left" }}>
                  Please go back to login to proceed registration to finalize
                  process
                </Typography>
              </>
            )}

            {renderText.fail.type && !isLoading && (
              <Typography
                variant="h2"
                color="mockup.neutral50"
                sx={{ textAlign: "left" }}
              >
                Something went wrong!
                <span style={{ display: "block" }}>Please try again</span>
              </Typography>
            )}

            <Button
              disabled={isLoading}
              fullWidth
              onClick={() => {
                navigate(RoutePath.LOGIN)
                clearAuthDataLocalStorage()
              }}
              sx={{ mt: 2 }}
            >
              Go Back to Login
            </Button>
            <Typography variant="body3" color="mockup.error50">
              {renderText.fail.message}
            </Typography>
          </>
        )}
      </>
    </Layout>
  )
}
export default EmailConfirmation
