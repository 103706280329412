import { ReactComponent as IconPlus } from "@common_assets/svg/plus.svg"
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { useQueryStudentClasses } from "api/reactQuery/queries/studentClasses"
import ErrorText from "components/common/error/errorText"
import DynamicIcon from "components/common/icon/dynamicIcon"
import IconWrapper from "components/common/icon/iconWrapper"
import { useDialog } from "hooks/dialog"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"

import NavItem from "../navItem"
import AddClassDialog from "./addClassDialog"

const NavigationStudent = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useQueryStudentClasses()

  const { data: studentClass } = useQueryStudentClasses()

  const { isOpen, handleOpen, handleClose } = useDialog()
  const user = useAppSelector(selectUser)

  return (
    <>
      <Stack flex="1 1 auto" component="nav">
        <NavItem
          text={t("layout.dashboard")}
          to={RoutePath.HOMEPAGE}
          Icon={<DynamicIcon name="home" variant="outlined" />}
          IconActive={<DynamicIcon name="home" variant="solid" />}
        />
        <NavItem
          text={t("layout.accounts")}
          to={RoutePath.ACCOUNTS}
          Icon={<DynamicIcon name="document" variant="outlined" />}
          IconActive={<DynamicIcon name="document" variant="solid" />}
        />
        <Stack
          mt="16px"
          mb="8px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="mockup.neutral100" variant="subtitle2">
            {t("layout.classes")}
          </Typography>
          {user?.user.school_plan === UserSchoolPlan.FREE &&
            studentClass?.data.length === 0 && (
              <IconWrapper
                color="mockup.neutral100"
                sx={{ cursor: "pointer", mr: 1 }}
              >
                <IconPlus onClick={handleOpen} />
              </IconWrapper>
            )}
          {user?.user.school_plan !== UserSchoolPlan.FREE && (
            <IconWrapper
              color="mockup.neutral100"
              sx={{ cursor: "pointer", mr: 1 }}
            >
              <IconPlus onClick={handleOpen} />
            </IconWrapper>
          )}
        </Stack>
        <Stack flex="1 1 auto" position="relative">
          <Stack
            position="absolute"
            top="0"
            width="100%"
            rowGap="8px"
            sx={{ overflowY: "auto" }}
            height="100%"
            className="smooth-scrollbar"
          >
            {isLoading && (
              <Box width="100%" display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {isError && <ErrorText />}
            {data?.data.map((classObj) => (
              <NavItem
                key={classObj.id}
                text={classObj.name}
                to={RoutePath.CLASS.replace(":classId", `${classObj.id}`)}
                Icon={<DynamicIcon name={classObj.icon} variant="outlined" />}
                IconActive={
                  <DynamicIcon name={classObj.icon} variant="solid" />
                }
                linkActiveRoutes={[
                  `${RoutePath.CLASS.replace(":classId", `${classObj.id}`)}/*`,
                ]}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <AddClassDialog isOpen={isOpen} onClose={handleClose} />
    </>
  )
}

export default NavigationStudent
