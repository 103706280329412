import { TransactionType } from "ts/types/Transaction"
import { removeCommasNumber } from "utils/decimalNumber"
import { isUndefinedOrNull } from "utils/valueCheck"
import { requiredNumberSchemaCommasAndDots, stringSchema } from "utils/yup"
import * as yup from "yup"

import { IFormContext } from "./TransactionDialog.types"

export const dialogTitleKeyMap: Record<TransactionType, string> = {
  bonus: "dashboardStudent.bonusDeposit",
  expense: "dashboardStudent.expenseWithdraw",
  salary: "dashboardStudent.paycheckDeposit",
  fine: "dashboardStudent.fineWithdraw",
}

export const defaultValues = {
  newBalance: "",
}

export const validationSchema = yup.object({
  newBalance: requiredNumberSchemaCommasAndDots.test(
    "is a new balance correctly calculated",
    "validations.doubleCheckYourMath",
    (val, { options: { context } }) => {
      if (!context) return false
      const ctx = context as IFormContext
      if (
        isUndefinedOrNull(ctx.studentBalance) ||
        isUndefinedOrNull(ctx.tranactionAmount)
      )
        return false

      const studentBalance = ctx.studentBalance as number
      const transactionAmount = ctx.tranactionAmount as number

      return (
        Math.round((studentBalance + transactionAmount) * 100) / 100 ===
        Number(removeCommasNumber(val))
      )
    }
  ),
})

export const validationSchemaMath = yup.object({
  newBalance: stringSchema,
})
