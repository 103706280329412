import { AxiosError } from "axios"
import { get } from "react-hook-form"
import { Subscription_Status, UserSchoolPlan } from "ts/enums/User"
import { IUserMe, IUserTeacher } from "ts/interfaces/User"

export const getErrorMessage = (error: AxiosError) => {
  const errorDetail = get(error, "response.data.detail", null)
  return typeof errorDetail === "string"
    ? errorDetail
    : "validations.anErrorOccured"
}

export const getErrorMessageFromTab = (error: AxiosError) => {
  const errorDetails = get(error, "response.data.detail[0].msg", null)
  return typeof errorDetails === "string"
    ? errorDetails
    : "validations.anErrorOccured"
}

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export const checkSubscription = (user: IUserMe | null) => {
  let userT: IUserTeacher | null = null
  let userS: IUserMe | null = null
  if (user !== null) {
    userT = user.user as IUserTeacher
    userS = user
  }
  if (userS?.type === "student") return true

  if (userT?.school_plan === UserSchoolPlan.FREE) return true
  if (userT?.school_plan === UserSchoolPlan.DEMO) return true
  if (userT?.school_plan === UserSchoolPlan.SCHOOL) return true

  if (
    userT?.school_plan === UserSchoolPlan.PRO &&
    userT?.subscription_status === Subscription_Status.ACTIVE
  )
    return true

  if (
    userT?.school_plan === UserSchoolPlan.PRO &&
    userT?.subscription_status === Subscription_Status.PAST_DUE
  )
    return true

  return false
}
