import { useState } from "react"

import { IResponseDashboardStoreUpdates } from "api/reactQuery/queries/dashboardTodo.types"
import { AxiosResponse } from "axios"
import ErrorText from "components/common/error/errorText"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { ITodo } from "ts/interfaces/Todo"

import NoStudentUpdates from "../../noStoreUpdates"
import NoTodo from "../../noTodo"
import { StoreUpdatesBody, TodoBody } from "../tableBlody"
import { StoreUpdatesHeader, TodoHeader } from "../tableHeader"
import Top from "./top"

interface IProps {
  isLoading: boolean
  isLoadingDataStore: boolean
  isError: boolean
  isErrorDataStore: boolean
  data: AxiosResponse<ITodo[], unknown> | undefined
  dataStore:
    | AxiosResponse<IResponseDashboardStoreUpdates[], unknown>
    | undefined
}

const TableForProSchool = ({
  isLoading,
  isLoadingDataStore,
  isError,
  isErrorDataStore,
  data,
  dataStore,
}: IProps) => {
  const [tabNumber, setTabNumber] = useState(0)

  return (
    <BasicTable
      topSlot={<Top {...{ tabNumber, dataStore, setTabNumber }} />}
      head={!!tabNumber ? <StoreUpdatesHeader /> : <TodoHeader />}
      body={
        !!tabNumber ? (
          <StoreUpdatesBody data={dataStore?.data ?? []} />
        ) : (
          <TodoBody data={data?.data ?? []} />
        )
      }
      bottomSlot={
        <>
          {!tabNumber && isLoading && <TableSkeleton />}
          {!tabNumber && isError && <ErrorText />}
          {!tabNumber && !isLoading && !data?.data.length && <NoTodo />}
          {!!tabNumber && isLoadingDataStore && <TableSkeleton />}
          {!!tabNumber && isErrorDataStore && <ErrorText />}
          {!!tabNumber && !isLoadingDataStore && !dataStore?.data.length && (
            <NoStudentUpdates />
          )}
        </>
      }
    />
  )
}

export default TableForProSchool
