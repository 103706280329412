export const addNewClass = {
  addNewClass: "Add new class",
  className: "Class name",
  save: "Save",
  grade: "Grade",
  selectClassIcon: "Select class icon",
  sendPaycheckAutomatically:
    "Send paychecks automatically so that you don't forget!",
  sendExpenseAutomatically:
    "Send expenses automatically so that you don't forget!",
  allowYourStudentsToBudgetAndSeeThePowerOfCompoundInterest:
    "Allow your students to budget and set savings goals.",
  roundInterestToNearestWholeNumber: "Round interest to nearest whole number",
  allowStudentsToEarnInterestOnTheirSavings:
    "Allow students to earn interest on their savings.",
  autoPay: "Auto Pay",
  autoExpense: "Auto Expense",
  savingsAccount: "Savings account",
  expensesWillBeSent: "Expenses will be sent",
  salariesWillBeSent: "Salaries will be sent",
  interestWillBeSent: "Interest will be sent",
  grantingInterest: "Granting interest",
  interestRate: "Interest rate (%)",
  exWillRoundTo: "Ex. $5.25 will round to $5",
  classCreatedSucceed: "Class has been created successful",
  editSucceed: "Edit successful",
  editClass: "Edit Class",
  selectClass: "Select Class",
  importFrom: "Import from",
  importSettings: "Import settings",
  applySettings: "Apply Settings",
  importantNotice:
    "<strong>Please note:</strong> Importing settings from another class will overwrite all previous settings",
  importSettingsTo: "Import settings to {{className}}",
  whatSettingsWouldYouLikeToImportTo:
    "What settings would you like to import to <strong>{{className}}?</strong>",
  greatSettingsFromClassWillBeImportedToClass:
    "Great! Settings from {{classNameFrom}} will be imported to {{classNameTo}}",
  removeClass: "Delete Class",
  classHasBeenDeletedSuccessful: "Class has been deleted successful",
  areYouSureYouWantToDeleteClass:
    "Are you sure you want to delete {{class}} class?",
  selectOneIcon: "Select one of the possible icons",
  selectOneOption: "Choose one of the options",
  somethingWentWrong: "Something went wrong",
  classWithGivenNameAlreadyExist: "Class with given name already exists",
  allBonusesStoreItemsJobsExpensesAndFinesFromTheSelectedClassWillBeCopied:
    "All bonuses, store items, jobs, expenses and fines from the selected class will be copied.",
  whatClassWouldYouLikeToImportSettingsFrom:
    "What class would you like to import settings from?",
  ChooseWhatKingOfItemsDoYouNeedCopy:
    "Choose what kind of items do you need copy",
  selectAll: "Select all",
  areYouSureYouWantToCopyDB:
    "Are you sure you want copy {{object}} to {{classTitle}}?",
  copyHasBeenSuccesful: "Copy has been successful",
  creatingClass: "Creating class...",
  copyingItems: "Copying items...",
  thisIsListOfStudentsWhoMightLoseTheirJob:
    "This is a list of students who might lose their job:",
  chooseAtLeastOneOfTheOption: "Choose at least one of the option",
  exportStudents: "Export Students",
  thisActionCannotBeUndone:
    "This action cannot be undone. Deleting this class will also delete any settings associated with this class including bonuses, fines, jobs, store items and bills.",
  general: "General",
  advanced: "Advanced",
  descriptionUpgradeTeacherInterest:
    "Interest is a feature included in the 'Pro Subscription' and 'School Subscription'. Learn more about our <Link>paid plans here</Link>. Upgrade to pro or school to unlock this feature.",
  interestOption: "Interest option",
  mathCalculations: "Math calculations",
  manageMathCalculationsForStudents: "Manage math calculations for students",
  checkedStudentsWillBeResponsibleForDoingTheMath:
    "Checked students will be responsible for doing the math to calculate their new balance to make their deposits and withdraws. Uncheck students to opt them out of math calculations.",
  pleaseEnsureStudentsHaveTransitionedAllMoneyFromTheirSavingsToTheirCheckingAccountBeforeTurningOffSavingsAccounts:
    "Please ensure students have transitioned all money from their Savings to their Checking account before turning off Savings accounts.",
}
