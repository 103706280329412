export const initialState = {
  title: "",
  positions: "",
  qualifications: "",
  salary: "",
  responsibilities: "",
  open_for_all: false,
  id: 0,
}

export const initialStateDetailsJob = {
  title: "",
  open_for_all: true,
  positions: "",
  salary: "",
  id: 0,
  responsibilities: "",
  qualifications: "",
  employees: [],
  pending_applications_count: 0,
  pending_applications_students: [],
}
