export const setup = {
  bills: "Bills",
  bonuses: "Bonuses",
  fines: "Fines",
  item: "Item",
  value: "Value",
  searchForBills: "Search for bills",
  addBill: "Add bill",
  addNewBill: "Add a new bill",
  editBill: "Edit bill",
  addSuggestedBills: "Add suggested bills",
  billTitle: "Bill title",
  deleteBill: "Delete bill",
  searchForBonuses: "Search for bonuses",
  addBonus: "Add bonus",
  addNewBonus: "Add a new bonus",
  editBonus: "Edit bonus",
  addSuggestedBonuses: "Add suggested bonuses",
  bonusTitle: "Bonus title",
  deleteBonus: "Delete bonus",
  searchForFines: "Search for fines",
  addFine: "Add fine",
  addNewFine: "Add a new fine",
  editFine: "Edit fine",
  addSuggestedFines: "Add suggested fines",
  fineTitle: "Fine title",
  deleteFine: "Delete fine",
  youDontHaveAnyBillsYet: "You don’t have any bills yet",
  youCanChooseFromOurSuggestedBills:
    "You can choose from our suggested bills and customize them to fit your classroom, or add your own bills form scratch!",
  youDontHaveAnyBonusesYet: "You don’t have any bonuses yet",
  youCanChooseFromOurSuggestedBonuses:
    "You can choose from our suggested bonuses and customize them to fit your classroom, or add your own bonuses form scratch!",
  youDontHaveAnyFinesYet: "You don’t have any fines yet",
  youCanChooseFromOurSuggestedFines:
    "You can choose from our suggested fines and customize them to fit your classroom, or add your own fines form scratch!",
  noMatchesFor: 'No matches for "{{- match}}"', // eslint-disable-line
  sortBy: "Sort by",
  none: "None",
  billsAscending: "Bills ascending",
  billsDescending: "Bills descending",
  bonusesAscending: "Bonuses ascending",
  bonusesDescending: "Bonuses descending",
  finesAscending: "Fines ascending",
  finesDescending: "Fines descending",
  amountAscending: "Amount ascending",
  amountDescending: "Amount descending",
  billHasBeenAdded: "Bill {{- name}} has been added",
  bonusHasBeenAdded: "Bonus {{- name}} has been added",
  fineHasBeenAdded: "Fine {{- name}} has been added",
  billHasBeenEdited: "Bill {{- name}} has been edited",
  bonusHasBeenEdited: "Bonus {{- name}} has been edited",
  fineHasBeenEdited: "Fine {{- name}} has been edited",
  billHasBeenDeleted: "Bill {{- name}} has been deleted",
  bonusHasBeenDeleted: "Bonus {{- name}} has been deleted",
  fineHasBeenDeleted: "Fine {{- name}} has been deleted",
  thisActionCannotBeUndone: "This action cannot be undone",
  areYouSureYouWantToDeleteBill:
    "Are you sure you want to delete {{- name}} bill?",
  areYouSureYouWantToDeleteBonus:
    "Are you sure you want to delete {{- name}} bonus?",
  areYouSureYouWantToDeleteFine:
    "Are you sure you want to delete {{- name}} fine?",
  suggestedBillsHaveBeenAdded: "Suggested bills have been added",
  suggestedBonuesHaveBeenAdded: "Suggested bonuses have been added",
  suggestedFinesHaveBeenAdded: "Suggested fines have been added",
  billTitleShouldBeUnique: "Bill title should be unique",
  bonusTitleShouldBeUnique: "Bonus title should be unique",
  fineTitleShouldBeUnique: "Fine title should be unique",
  teacherChoice: "Teacher's Choice",
  randomActOfKindness: "Random act of kindness",
  complimentFromAnotherTeacher: "Compliment from another teacher",
  hundredClassAttendanceForTheDay: "100% class attendance for the day",
  showingRespect: "Showing respect",
  showingPerseverance: "Showing perseverance",
  hundredClassHomeworkCompletionForTheWeek:
    "100% class homework completion for the week",
  hundredOnaSmallQuiz: "100% on a small quiz",
  hundredOnaTest: "100% on a test",
  greatTeamwork: "Great teamwork",
  onTimeToClass: "On time to class",
  rent: "Rent",
  utilities: "Utilities",
  healthInsurance: "Health Insurance",
  phoneBill: "Phone bill",
  internetBill: "Internet bill",
  heatingBill: "Heating bill",
  airConditioning: "Air conditioning",
  disasterInsurance: "Disaster Insurance",
  breakingClassroomExpectation: "Breaking classroom expectation",
  talkingOverTheTeacher: "Talking over the teacher",
  borrowingPencil: "Borrowing pencil",
  notPreparedForClass: "Not prepared for class",
  preparedForClass: "Prepared for class",
  messyArea: "Messy area",
  noName: "No name",
  offTask: "Off task",
  dishonesty: "Dishonesty",
  chewingGumInClass: "Chewing Gum in Class",
  lateToClass: "Late to class",
  phoneInClas: "Phone in class",
  weRecommendSelectingBonuses: "We recommend selecting 5-8 bonuses ",
  weRecommendSelectingFines:
    "Fines are optional! If they don’t fit with your classroom culture, feel free to set the values equal to zero so that you can still track behaviors, or skip them all together!",
  weRecommendSelectingRentAndOtherExpense:
    "We recommend selecting “rent” and 1-2 other expenses",
  selectedItemsCannotHaveAnEmptyTitle:
    "Selected items cannot have an empty title",
  amountOfSelectedItemsHaveToBePositiveNumber:
    "Amount of selected items have to be a positive number",
  areYouSureYouWantToExit: "Are you sure you want to exit?",
  accept: "Accept",
  noChangesWillBeSaved: "No changes will be saved",
  clickOnTheTextToEditTheSuggestedSettings:
    "Click on the text to edit the suggested settings",
  participation: "Participation",
  showingKindness: "Showing kindness",
  helpingAPeer: "Helping a peer",
  upholdingClassroomContract: "Upholding classroom contract",
  demonstratingGrowth: "Demonstrating growth",
  talkingDuringInstruction: "Talking during instruction",
  autoExpense: "Autoexpense",
}
