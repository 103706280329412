import { FC } from "react"

import { Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import { useTranslation } from "react-i18next"

import { Cart } from "../../StoreStudent.types"

interface IProps {
  cart: Cart
  studentBalance: number
}

const CartDetails: FC<IProps> = ({ cart, studentBalance }) => {
  const { t } = useTranslation()

  const cartTotalValue = Object.values(cart).reduce(
    (acc, curr) => acc + curr.quantity * curr.item.price,
    0
  )

  return (
    <Stack
      pt="12px"
      pb="24px"
      borderTop={(theme) => `${theme.palette.mockup.neutral90} 1px solid`}
      borderBottom={(theme) => `${theme.palette.mockup.neutral90} 1px solid`}
      gap="24px"
    >
      <Stack
        borderRadius="16px"
        p="8px"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        bgcolor="secondary.light"
        direction="row"
      >
        <Typography
          variant="subtitle1"
          color="mockup.neutral10"
          lineHeight="24px"
        >
          {t("storeStudent.yourCurrentBalance")}:
        </Typography>
        <Coin amount={`${studentBalance}`} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
      >
        <Typography variant="subtitle1" color="mockup.neutral10">
          {t("storeStudent.inTotal")}:
        </Typography>
        <Coin
          amount={`${cartTotalValue}`}
          iconSize="30px"
          valueTextProps={{
            fontSize: "32px",
            lineHeight: "38px",
          }}
        />
      </Stack>
    </Stack>
  )
}

export default CartDetails
