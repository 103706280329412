import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostResetBalance,
  IResponseResetBalance,
  IRestBalanceArg,
} from "./balancesReset.types"

export const useMutationsResetBalance = (args?: IRestBalanceArg) =>
  useMutation<
    AxiosResponse<IResponseResetBalance>,
    AxiosError,
    IPostResetBalance
  >(async (data) => {
    return await axiosInstance.post(
      Endpoint.CLASSES_ID_STUDENTS_BALANCES_RESET.replace(
        "ID",
        `${args?.classId}`
      ),
      { ...data }
    )
  }, args?.options)
