import { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as Anonymous } from "@common_assets/svg/anonymous.svg"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"

import { JOBS_PANEL_VIEW_BREAKPOINT } from "../JobsPanel.config"
import Job from "./job"

const JobsOfferAside: FC<{
  jobsState: IGetStudentPossibleJobsInClass[]
  setJobId: Dispatch<React.SetStateAction<number | null>>
  handleExpandToggle: () => void
}> = ({ jobsState, setJobId, handleExpandToggle }) => {
  const isRowView = useIsBreakpointUp(JOBS_PANEL_VIEW_BREAKPOINT)
  const { t } = useTranslation()
  const theme = useTheme()
  const [checked, setChecked] = useState(false)
  const [filterJobs, setFilterJobs] = useState<
    IGetStudentPossibleJobsInClass[]
  >([])

  useEffect(() => {
    if (jobsState) {
      let job = jobsState
      if (checked) {
        job = job.filter((el) => el.pending_application || el.is_hired)
      }
      setFilterJobs(job)
    } else {
      setFilterJobs(jobsState)
    }
  }, [jobsState, checked])

  return (
    <Stack
      width="100%"
      px={2}
      bgcolor={theme.palette.mockup.primary95}
      sx={{ borderRadius: "8px" }}
    >
      {!isRowView && (
        <Stack mt="12px" alignItems="center">
          <Box
            width={{ mobile: "38px", tablet: "64px" }}
            height={{ mobile: "4px", tablet: "6px" }}
            bgcolor="mockup.primary99"
            borderRadius="8px"
            sx={{ cursor: "pointer" }}
            onClick={handleExpandToggle}
          />
        </Stack>
      )}
      <Box mt={{ mobile: "12px", desktop: "28px" }} mb="20px">
        <FormControlLabel
          control={
            <Checkbox
              sx={{ ml: 2 }}
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
          }
          label={
            <Typography
              variant="body2"
              fontWeight="500"
              color="mockup.neutral0"
            >
              {t("studentJobs.showOnlyMyJobs")}
            </Typography>
          }
        />
      </Box>
      <Box
        sx={{ overflowY: "auto", maxHeight: "calc(100vh - 68px - 150px)" }}
        className="smooth-scrollbar"
      >
        {filterJobs.length > 0 ? (
          <Job jobsState={filterJobs} setJobId={setJobId} />
        ) : (
          <Box textAlign="center" mb={2}>
            <Anonymous />
            <Typography
              variant="body2"
              color="mockup.darkBlue"
              fontWeight="500"
            >
              {t("studentJobs.youAreNeitherHiredNorPendingApplication")}
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default JobsOfferAside
