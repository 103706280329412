import {
  noLimitedStringSchema,
  requiredNumberSchema,
  requiredStringSchema,
  stringSchema,
} from "utils/yup"
import * as yup from "yup"

import { ApplicationModuleTypes } from "./ApplicationModule.types"

export const defaultValues = {
  choice: "",
  qualifications: "",
  motivation: "",
  resume_file: "",
  job_id: 0,
}

export const validateSchema = yup.object({
  choice: requiredStringSchema,
  qualifications: noLimitedStringSchema.required(),
  motivation: noLimitedStringSchema.required(),
  resume_file: stringSchema,
  job_id: requiredNumberSchema,
})

export const choiceMap = [
  { label: ApplicationModuleTypes.FIRST, value: 1 },
  { label: ApplicationModuleTypes.SECOND, value: 2 },
  { label: ApplicationModuleTypes.THIRD, value: 3 },
]
