import React, { useEffect, useState } from "react"

import { Stack, styled, Tab, Tabs, Typography } from "@mui/material"
import { useQueryGetHrJobsDetails } from "api/reactQuery/queries/jobs"
import { IGetJobsResponse } from "api/reactQuery/queries/jobs.types"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import { useParams } from "react-router"

import CustomTab from "./customTab"
import Employees from "./customTab/employees"
import General from "./customTab/general"
import PendingApplication from "./customTab/pendingApplication"

const TabsStyle = styled(Tabs)(({ theme }) => ({
  "& .MuiButtonBase-root.MuiTab-root ": {
    fontSize: "16px",
    lineHeight: "24px",
    padding: "5px",
  },
  "& .MuiTabs-scroller ": {
    borderBottom: `1px solid ${theme.palette.mockup.neutral90}`,
  },
}))

interface IEditJob {
  job: IGetJobsResponse
  id: string
}

const EditJob = ({ job, id }: IEditJob) => {
  const params = useParams()
  const [richTextEditorResponse, setRichTextEditorResponse] = useState("")
  const [richTextEditorQualify, setRichTextEditorQualify] = useState("")
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { data, isLoading } = useQueryGetHrJobsDetails({
    classId: Number(params.classId),
    jobId: job.id,
    options: {
      cacheTime: 0,
      enabled: !!job.id,
    },
  })

  useEffect(() => {
    if (data) {
      setRichTextEditorQualify(data?.data.qualifications)
      setRichTextEditorResponse(data?.data.responsibilities)
    }
  }, [data])

  const positionPlaceholder = () => {
    if (!data) return "undefined"
    if (data?.data.positions === null) {
      return `${data?.data.employees.length}/999`
    }
    return `${data?.data.employees.length}/${data?.data.positions}`
  }

  if (isLoading) return <StudentSkeleton height="500px" />

  return (
    <Stack>
      <TabsStyle
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        <Tab label={<Typography variant="body1">General</Typography>} />
        <Tab
          label={
            <Typography variant="body1">
              Employees({positionPlaceholder()})
            </Typography>
          }
        />
        <Tab
          label={
            <Typography variant="body1">
              Pending Application({data?.data.pending_applications_count})
            </Typography>
          }
        />
      </TabsStyle>
      <CustomTab index={0} value={value}>
        <General
          id={id}
          richTextEditor={{ richTextEditorQualify, richTextEditorResponse }}
          setRichTextEditor={{
            setRichTextEditorResponse,
            setRichTextEditorQualify,
          }}
          data={data?.data}
        />
      </CustomTab>
      <CustomTab index={1} value={value}>
        <Employees jobState={data?.data} />
      </CustomTab>
      <CustomTab index={2} value={value}>
        <PendingApplication jobState={data?.data} />
      </CustomTab>
    </Stack>
  )
}
export default EditJob
