import { useEffect, useRef, useState } from "react"

import { Stack } from "@mui/material"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import CheckableItem from "components/common/listing/checkableItem"
import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { setIsNextPage } from "store/utility/utility.slice"
import { showDecimal } from "utils/decimalNumber"
import { getSortingTextSecond } from "utils/listing"

import { sortingOptions, sortingTextMap } from "../ClassStudentsTeacher.config"
import ActionButtons from "../common/actionButtons"
import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import { HandleFunction } from "../common/viewCommonElementsContainer/ViewCommonElementsContainer"
import { Sort, SortingType } from "../tableView/TableView"

const ListingView = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isToggleAll, setIsToggleAll] = useState(false)
  const [sortBy, setSortBy] = useState<Sort>("first_name")
  const [sorting, setSorting] = useState<SortingType>("asc")
  const params = useParams()
  const [file, setFile] = useState("")

  useEffect(() => {
    setIsToggleAll(false)
  }, [params.classId])

  const refetchRef = useRef<HandleFunction | null>(null)

  const { data: classesByIdData } = useQueryClassesById({
    id: Number(params.classId),
  })

  const savingsAccountOption = classesByIdData?.data.savings_account_option

  return (
    <ViewCommonElementsContainer
      stateFile={{ file, setFile }}
      setIsToggle={setIsToggleAll}
      ref={refetchRef}
      sortBy={sortBy}
      sorting={sorting}
      isToggleAll={isToggleAll}
      renderDataFromBE={({
        apiData,
        selectingProps: {
          selectedItems,
          toggleAll,
          toggleItem,
          getIsChecked,
          toggleExcludedItems,
          clearSelectedItems,
          excludedItems,
          setExcludedItems,
          setSelectedItems,
        },
        invokeListItemAction,
      }) => (
        <>
          <ListingSortingHeader
            sortingText={t(getSortingTextSecond(sortBy, sortingTextMap))}
            sortingActive={!!sortBy}
            defaultRender={sortBy}
            sortingDirection={sorting}
            onSubmit={(data) => {
              const dataSortReturn = JSON.parse(data.sort)
              const { order, orderBy } = dataSortReturn
              dispatch(setIsNextPage(true))
              setSortBy(orderBy)
              setSorting(order)
              if (refetchRef.current) {
                refetchRef.current?.triggerRefetch()
              }
              clearSelectedItems()
            }}
            options={sortingOptions}
            checkboxProps={{
              checked:
                apiData.length === selectedItems.length && !!apiData?.length,
              onChange: () => {
                if (!!file.length && isToggleAll) {
                  toggleAll()
                  if (apiData.length !== selectedItems.length) {
                    const filteredArr = excludedItems.filter((element) => {
                      return !apiData.some((copyElement) => {
                        return element.id === copyElement.id
                      })
                    })
                    setExcludedItems(filteredArr)
                    return
                  }
                  setExcludedItems((prev) => {
                    const newItems = apiData.filter(
                      (item) =>
                        !prev.some(
                          (excludedItem) => excludedItem.id === item.id
                        )
                    )
                    return [...prev, ...newItems]
                  })
                  return
                }

                toggleAll()
                if (!!excludedItems.length) {
                  return
                }
                setIsToggleAll(!isToggleAll)
              },
              indeterminate:
                apiData.length !== selectedItems.length &&
                !!selectedItems.length,
            }}
          />
          <Stack>
            {apiData.map((student) => (
              <CheckableItem
                key={student.id}
                savingsAccountOption={savingsAccountOption}
                topText={`${student.first_name} ${student.last_name}`}
                bottomTextTitle={t("students.job")}
                bottomText={
                  student.jobs.length
                    ? student.jobs.join(", ")
                    : t("students.noJobs")
                }
                titleSavings={t("students.savings")}
                titleBookedBalance={t("students.checking")}
                titleTotalBalance={t("students.balance")}
                titlePendingBalance={t("students.pending")}
                valueSavings={showDecimal(`${student.savings}`) as number}
                valueBookedBalance={
                  showDecimal(`${student.booked_balance}`) as number
                }
                valueTotalBalance={showDecimal(`${student.balance}`) as number}
                valuePendingBalance={
                  showDecimal(`${student.pending_balance}`) as number
                }
                actionButtonsSlot={
                  <ActionButtons
                    handleSeeStudentProfile={() =>
                      invokeListItemAction(student, "visitProfile")
                    }
                    handleSendTransaction={() =>
                      invokeListItemAction(student, "sendTransaction")
                    }
                    student={student}
                    setSelectedItems={setSelectedItems}
                    setExcludedItems={setExcludedItems}
                  />
                }
                checkboxProps={{
                  checked: getIsChecked(student),
                  onChange: () => {
                    toggleItem(student)
                  },
                  onClick: () => {
                    if (isToggleAll) {
                      toggleExcludedItems(student)
                    }
                  },
                }}
              />
            ))}
          </Stack>
        </>
      )}
    />
  )
}

export default ListingView
