import { Box, Tab, Tabs } from "@mui/material"
import { useQueryStudentClassesById } from "api/reactQuery/queries/studentClasses"
import { useClassId, useGetCurrentTabName } from "hooks/navigation"
import { useAppSelector } from "hooks/store"
import { useUserRole } from "hooks/user"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import { selectUser } from "store/auth/auth.selectors"
import { UserRole, UserSchoolPlan } from "ts/enums/User"

import { IStudentClassGet } from "../../../ts/interfaces/Class"
import { ITab } from "../../../ts/interfaces/Tab"
import { classRoutes, studentClassRoutes } from "../Header.config"

const NavigationTabs = () => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)
  const userRole = useUserRole()
  const routes: ITab[] =
    userRole === UserRole.TEACHER ? classRoutes : studentClassRoutes
  const location = useLocation()

  const classId = useClassId()

  const { data } = useQueryStudentClassesById({
    id: Number(classId),
    options: {
      enabled: userRole === "student",
    },
  })

  const tabName = useGetCurrentTabName(routes)

  return (
    <Box>
      <Tabs value={tabName} variant="scrollable" scrollButtons={false}>
        {routes
          .filter((route) =>
            process.env.REACT_APP_TYPE === "bankeeonline" &&
            userInfo?.user.school_plan !== UserSchoolPlan.SCHOOL
              ? route.tabName !== "coteachers"
              : route
          )
          .filter(
            (route) =>
              route.alwaysVisible ||
              (route.visibilityCheck &&
                route.visibilityCheck(data?.data as IStudentClassGet))
          )
          .map((route) => (
            <Tab
              key={route.labelKey}
              label={t(route.labelKey)}
              component={NavLink}
              to={route.path.replace(":classId", classId)}
              state={{ from: location.pathname }}
              value={route.tabName}
              onClick={() => {
                if (route.tabName !== "coteachers") {
                  mixpanel.track("main_head_tab", {
                    event_name: route.tabName,
                  })
                }
              }}
            />
          ))}
      </Tabs>
    </Box>
  )
}

export default NavigationTabs
