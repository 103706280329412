export const compareAlphabeticallyAsc = (a: string, b: string) =>
  a.localeCompare(b)

export const compareByNumberAsc = (a: number, b: number) => a - b

export const compareDateStringsAsc = (a: string, b: string) =>
  Date.parse(a) - Date.parse(b)

export const compareNull = (a: number | null, b: number | null) => {
  const aa = a ?? -1
  const bb = b ?? -1
  return aa - bb
}
