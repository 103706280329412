import { createTheme } from "@mui/material"

import components from "./components"
import palette from "./palette"
import typography from "./typography"

const font = "'Quicksand', sans-serif"

const themeBreakpoints = createTheme({
  typography: {
    fontFamily: font,
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1024,
    },
  },
})

const theme = createTheme(themeBreakpoints, {
  palette,
  typography: typography(themeBreakpoints),
  components,
})

export default theme
