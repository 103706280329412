import axiosInstance from "api/api"
import { Endpoint } from "api/enpoints.types"
import { IGetStudentSavingAccountsArgs } from "api/reactQuery/queries/studentSavingAccounts.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"
import { IStudentSavingAccounts } from "ts/interfaces/Student"

export const useQueryStudentSavingAccounts = ({
  student_id,
  options,
}: IGetStudentSavingAccountsArgs) =>
  useQuery<AxiosResponse<IStudentSavingAccounts[]>, AxiosError>(
    ["studentSavingAccounts", student_id],
    async () =>
      axiosInstance(
        Endpoint.STUDENTS_ID_SAVINGS.replace("ID", `${student_id}`)
      ),
    options
  )
