import React from "react"

import { ReactComponent as Logo } from "@brand_assets/svg/main-logo-dark.svg"
import { ReactComponent as Monster } from "@common_assets/svg/downtime.svg"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material"
import { AxiosError } from "axios"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { Trans, useTranslation } from "react-i18next"

import { ErrorResponse } from "../../../AppProviders"

interface DowntimeError {
  axiosErrorObject: AxiosError<ErrorResponse> | null
}
const DowntimeError = ({ axiosErrorObject }: DowntimeError) => {
  const { t } = useTranslation()
  const isMobile = useIsBreakpointUp("tablet")
  const isDesktop = useIsBreakpointUp("desktop")

  return (
    <Stack
      alignItems="flex-start"
      sx={
        isDesktop
          ? { p: "46px 142px 166px" }
          : isMobile
          ? { pt: "46px", px: "24px" }
          : { pt: "46px", px: "16px" }
      }
    >
      <Box mb={isDesktop ? "96px" : isMobile ? "130px" : "37.5px"}>
        <Logo width="200px" height="100%" />
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={isMobile ? "row" : "column-reverse"}
      >
        <Box mt={!isMobile ? "44px" : ""} flex="1">
          <Typography
            fontSize={isDesktop ? "64px" : isMobile ? "44px" : "28px"}
            lineHeight={isDesktop ? "80px" : isMobile ? "53px" : "36px"}
            color="mockup.darkBlue"
          >
            {t("validations.serverTemporarilyUnAvailable")}
          </Typography>
          <Typography mt={2} mb={3} variant="body1" color="mockup.darkBlue">
            {t("validations.classEquityIsOverCapacity")}
          </Typography>
          <Typography color="mockup.darkBlue">
            <Trans
              i18nKey="validations.needToPivotYourLessonsPlan"
              components={{
                Link: (
                  <a
                    style={{ textDecoration: "underline", color: "inherit" }}
                    href="https://www.ngpf.org/arcade/"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                ),
              }}
            />
          </Typography>
          <Accordion disableGutters sx={{ border: "none", margin: 0 }}>
            <AccordionSummary>More info</AccordionSummary>
            <AccordionDetails>
              <Stack>
                <Typography color="mockup.darkBlue">
                  status code:
                  <Typography component="span" color="mockup.error50" pl="4px">
                    {axiosErrorObject?.response?.status}
                  </Typography>
                </Typography>
                <Typography color="mockup.darkBlue">
                  message: {axiosErrorObject?.message}
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box flex="1">
          <Monster
            width="100%"
            height={isDesktop ? "487px" : "100%"}
            style={{ minWidth: !isMobile ? "300px" : "400px" }}
          />
        </Box>
      </Box>
    </Stack>
  )
}
export default DowntimeError
