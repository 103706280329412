import { useMemo } from "react"

import { IObjectStudentDetailed } from "ts/interfaces/Student"

import { Sort, SortingType } from "../Advance.utility"

interface SortedStudentsProps {
  students: IObjectStudentDetailed[]
  sortBy: Sort
  sortOrder: SortingType
}

export const useSortedStudents = ({
  students,
  sortBy,
  sortOrder,
}: SortedStudentsProps) => {
  const sortedStudents = useMemo(() => {
    const sorted = [...students].sort((a, b) => {
      if (sortBy === "first_name" || sortBy === "last_name") {
        if (sortOrder === "asc") {
          return a[sortBy].localeCompare(b[sortBy])
        } else {
          return b[sortBy].localeCompare(a[sortBy])
        }
      } else if (sortBy === "total_balance") {
        if (sortOrder === "asc") {
          return a.balance - b.balance
        } else {
          return b.balance - a.balance
        }
      }
      return 0
    })
    return sorted
  }, [students, sortBy, sortOrder])

  return sortedStudents
}
