import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { IResponseSnapshots, SnapshotsArgs } from "./snapshots.types"

export const useQuerySavingsSnapshot = (args?: SnapshotsArgs) =>
  useQuery<AxiosResponse<IResponseSnapshots>, AxiosError>(
    ["savingsSnapshot"],
    async () => {
      return axiosInstance(Endpoint.STUDENT_SAVINGS_SNAPSHOT)
    },
    args?.options
  )
