import { RoutePath } from "routes/Route.types"

import { IStudentClassGet } from "../../ts/interfaces/Class"

export const classRoutes = [
  {
    path: RoutePath.CLASS,
    tabName: "students",
    labelKey: "layout.students",
    activeOnPaths: [RoutePath.CLASS],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_JOBS,
    tabName: "jobs",
    labelKey: "layout.jobs",
    activeOnPaths: [RoutePath.CLASS_JOBS],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_STORE,
    tabName: "store",
    labelKey: "layout.store",
    activeOnPaths: [`${RoutePath.CLASS_STORE}/*`],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_SETUP,
    tabName: "setup",
    labelKey: "layout.setup",
    activeOnPaths: [`${RoutePath.CLASS_SETUP}/*`],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_COTEACHERS,
    tabName: "coteachers",
    labelKey: "layout.coteachers",
    activeOnPaths: [RoutePath.CLASS_COTEACHERS],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_STUDENT_HELPERS,
    tabName: "Student Helpers",
    labelKey: "layout.studentHelpers",
    activeOnPaths: [RoutePath.CLASS_STUDENT_HELPERS],
    alwaysVisible: true,
  },
]

export const studentClassRoutes = [
  {
    path: RoutePath.CLASS,
    tabName: "jobs",
    labelKey: "layout.jobs",
    activeOnPaths: [RoutePath.CLASS],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_STORE,
    tabName: "store",
    labelKey: "layout.store",
    activeOnPaths: [RoutePath.CLASS_STORE],
    alwaysVisible: true,
  },
  {
    path: RoutePath.CLASS_BANKER,
    tabName: "banker",
    labelKey: "layout.banker",
    activeOnPaths: [RoutePath.CLASS_BANKER],
    visibilityCheck: (userData: IStudentClassGet) => userData?.is_banker,
  },
  {
    path: RoutePath.CLASS_STORECLERK,
    tabName: "storeClerk",
    labelKey: "layout.storeClerk",
    activeOnPaths: [`${RoutePath.CLASS_STORECLERK}/*`],
    visibilityCheck: (userData: IStudentClassGet) => userData?.is_store_clerk,
  },
  {
    path: RoutePath.CLASS_HR,
    tabName: "hr-representative",
    labelKey: "layout.hrRepresentative",
    activeOnPaths: [RoutePath.CLASS_HR],
    visibilityCheck: (userData: IStudentClassGet) =>
      userData?.is_hr_representative,
  },
]

export const basicRoutes = [
  {
    path: RoutePath.HOMEPAGE,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.ALL_TRANSACTIONS,
    name: "layout.allTransactions",
    icon: "document",
  },
  {
    path: RoutePath.STUDENT,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.ACCOUNTS,
    name: "layout.accounts",
    icon: "document",
  },
  {
    path: RoutePath.PAYMENT_SUCCESSFUL,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.SETTINGS,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.PAGE404,
    name: "layout.pageNotFound",
    icon: "star",
  },
]
