import { ReactComponent as IconChevron } from "@common_assets/svg/chevron-down.svg"
import { ThemeOptions } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"

const muiAccordion: ThemeOptions["components"] = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.mockup.primary95}`,
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "none",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&:first-of-type": {
          borderRadius: "8px",
        },
        "&:last-of-type": {
          borderRadius: "8px",
        },
      }),
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: (
        <IconWrapper>
          <IconChevron />
        </IconWrapper>
      ),
    },
    styleOverrides: {
      root: {
        flexDirection: "row-reverse",
        padding: "14px 16px",
        columnGap: "4px",
        "&.Mui-expanded": {
          minHeight: "auto",
        },
      },
      content: ({ theme }) => ({
        color: theme.palette.mockup.neutral10,
        fontSize: "18px",
        lineHeight: "24px",
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      }),
      expandIconWrapper: {
        alignSelf: "start",
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.mockup.primary99,
        borderRadius: "8px",
        padding: "16px",
        visibility: "visible",
      }),
    },
  },
}

export default muiAccordion
