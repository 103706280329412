import { FC, useEffect } from "react"

import { Accordion, Grid, Typography } from "@mui/material"
import { useQueryClassesTransactionItems } from "api/reactQuery/queries/classesTransactionItems"
import TextField from "components/form/common/field/textField"
import mixpanel from "mixpanel-browser"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { getFieldName } from "utils/field"

import AccordionTransaction from "../accordion/detailsTransaction"
import Summary from "../accordion/summary"
import { ISectionProps } from "../Transactions.types"

const Fines: FC<ISectionProps> = ({
  formRoot,
  classId,
  defaultExpanded,
  studentId,
}) => {
  const { t } = useTranslation()

  const { getValues, setValue } = useFormContext()

  const finesFieldName = getFieldName(formRoot, "fines")

  const finesWatcher: ITransactionItem[] = useWatch({
    name: finesFieldName,
    exact: true,
  })

  const { data, isLoading, isError } = useQueryClassesTransactionItems({
    classId: Number(classId),
    variant: "fine",
    options: {
      enabled: !!classId,
    },
  })

  useEffect(() => {
    if (classId) {
      setValue(finesFieldName, [])
    }
  }, [classId])

  const onTileClick = (item: ITransactionItem) => {
    setValue(finesFieldName, [...finesWatcher, item])
  }

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <Summary
        title={t("transaction.fines")}
        selectedItemsAmount={finesWatcher.length}
      />
      <AccordionTransaction
        selectedItems={finesWatcher}
        noElementsMessage={t("transaction.classDontHaveAnyFinesYet")}
        data={data?.data}
        isLoading={isLoading}
        isError={isError}
        onTileClick={(item) => {
          onTileClick(item)
          if (formRoot === "transactions") {
            if (getValues("transactions.fines").length === 1) {
              mixpanel.track("send_transaction", {
                event_name: "pre-set_fines",
              })
              return
            }
          }
          if (getValues("fines").length === 1) {
            mixpanel.track("send_transaction", {
              event_name: "pre-set_fines",
            })
          }
        }}
        variant="fine"
        studentId={studentId}
        fieldName={finesFieldName}
      >
        {(!!studentId || !!classId) && (
          <>
            <Typography variant="body3" color="mockup.neutral40">
              {t("transaction.other")}
            </Typography>
            <PrimaryTile p="24px 16px 32px" sx={{ borderRadius: "16px" }}>
              <Grid container columnSpacing="16px" rowSpacing="40px">
                <Grid item desktop={8} tablet={6} mobile={12}>
                  <TextField
                    onFocus={(e) => {
                      if (!e.target.value.length) {
                        mixpanel.track("send_transaction", {
                          event_name: "form_fines",
                        })
                      }
                    }}
                    label={t("transaction.fineTitle")}
                    size="small"
                    name={getFieldName(formRoot, "fineTitle")}
                  />
                </Grid>
                <Grid item desktop={4} tablet={6} mobile={12}>
                  <TextField
                    FormHelperTextProps={{
                      style: {
                        position: "relative",
                        transform: "unset",
                        marginBottom: "-30px",
                      },
                    }}
                    label={t("transaction.value")}
                    size="small"
                    name={getFieldName(formRoot, "fineValue")}
                  />
                </Grid>
              </Grid>
            </PrimaryTile>
          </>
        )}
      </AccordionTransaction>
    </Accordion>
  )
}

export default Fines
