/*
the hook is used for sorting given data of type T, by its properties in asc or desc order

- sortingState consists of order, orderBy and comparator (optional) properties,
  order can be asc or desc, orderBy is a property from data on which the sorting is executed,
  by default if the property (orderBy) is number it is sorted by number
  (ascending or descending depending on order prop), but if the property is string
  it is sorted lexically, you can also pass your own comparator using comparator
  property (predefined eg. dateString or custom function - passed from outside),
  if a proper comparator isn't found, the error message will be visible in the console
- setSorting is used for setting the sortingState manually (order, orderBy and comparator)
- sortingFn is a sorting function which has to be used on the given data
- handleSortingClick is a function which takes orderBy and comparator (optional) as
  arguments, is toggles given filter (none -> asc -> desc), here you can also
  pass your own comparator for given property (setSortingState is used there under the hood),
  it is used with tables sorting
- getIsSortingActive is for checking if the data is sorted by given property of the item
- getSortingDirection is for checking the sorting direction of the given property
*/

import { useState } from "react"

import {
  compareAlphabeticallyAsc,
  compareByNumberAsc,
  compareDateStringsAsc,
  compareNull,
} from "utils/sortingComparators"

export interface ISortingState<T> {
  orderBy: keyof T | undefined
  order: "asc" | "desc"
  comparator?: ((a: T, b: T) => number) | "dateStrings"
}

export interface IUseSortingArgs<T> {
  data: T[]
  initialSortingState?: ISortingState<T>
  areStudentsClass?: boolean
}

export const useSorting = <T>({
  data,
  initialSortingState = {
    orderBy: undefined,
    order: "asc",
  },
  areStudentsClass,
}: IUseSortingArgs<T>) => {
  const [sortingState, setSortingState] =
    useState<ISortingState<T>>(initialSortingState)

  const getIsSortingActive = (property: keyof T) => {
    return sortingState.orderBy === property
  }

  const getSortingDirection = (property: keyof T) => {
    return sortingState.orderBy === property ? sortingState.order : undefined
  }
  const handleSortingClick = ({
    property,
    comparator,
  }: {
    property: typeof sortingState.orderBy
    comparator?: typeof sortingState.comparator
  }) => {
    let order: "asc" | "desc" = "asc"

    if (areStudentsClass) {
      if (sortingState.orderBy !== property && !sortingState.orderBy) {
        order = "asc"
      } else if (
        sortingState.orderBy !== property &&
        !!sortingState.orderBy &&
        property === "value"
      ) {
        order = "desc"
      } else if (sortingState.orderBy !== property && !!sortingState.orderBy) {
        order = "asc"
      } else if (sortingState.order === "asc") {
        order = "desc"
      } else {
        order = "asc"
      }
    } else {
      if (sortingState.orderBy !== property && !sortingState.orderBy) {
        order = "asc"
      } else if (sortingState.orderBy !== property && !!sortingState.orderBy) {
        order = "asc"
      } else if (sortingState.order === "asc") {
        order = "desc"
      } else {
        order = "asc"
      }
    }

    setSortingState({
      order: order,
      orderBy: property,
      comparator,
    })
  }

  const sortingFn = (a: T, b: T) => {
    const { order, orderBy, comparator } = sortingState

    if (orderBy === undefined) return 0

    const aEl = a[orderBy]
    const bEl = b[orderBy]

    // custom comparators -> predefined string comparators or custom function
    if (comparator) {
      if (comparator === "dateStrings") {
        return order === "asc"
          ? compareDateStringsAsc(`${aEl}`, `${bEl}`)
          : compareDateStringsAsc(`${bEl}`, `${aEl}`)
      } else {
        return comparator(a, b)
      }
    }

    // allow null or numbers for comparator
    if (
      (typeof aEl === "object" || typeof aEl === "number") &&
      (typeof bEl === "object" || typeof bEl === "number")
    ) {
      //@ts-ignore
      return order === "asc" ? compareNull(aEl, bEl) : compareNull(bEl, aEl)
    }

    // comparators based on element's type when comparator is undefined
    if (typeof aEl === "number" && typeof bEl === "number") {
      return order === "asc"
        ? compareByNumberAsc(aEl, bEl)
        : compareByNumberAsc(bEl, aEl)
    }

    if (typeof aEl === "string" && typeof bEl === "string") {
      return order === "asc"
        ? compareAlphabeticallyAsc(aEl, bEl)
        : compareAlphabeticallyAsc(bEl, aEl)
    }

    //@ts-ignore
    // return order === "asc" ? compareNull(aEl, bEl) : compareNull(bEl, aEl)

    // error when the comparator wasn't passed and the element's type comparators weren't applied
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.error("Didn't find a proper comparator")
    }
    return 0
  }

  const sortedData = [...data].sort((a, b) => sortingFn(a, b))

  return {
    sortedData,
    sortingState,
    setSortingState,
    sortingFn,
    getIsSortingActive,
    getSortingDirection,
    handleSortingClick,
  }
}
