import React, { useCallback, useState } from "react"

import { ReactComponent as CheckIcon } from "@common_assets/svg/check.svg"
import { ReactComponent as CrossIcon } from "@common_assets/svg/cross.svg"
import { ReactComponent as EyeIcon } from "@common_assets/svg/pass_eye.svg"
import { InputAdornment, useTheme } from "@mui/material"
import TextField from "components/form/common/field/textField"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IProps {
  isSignUp?: boolean
}

const PasswordTextField = ({ isSignUp }: IProps) => {
  const theme = useTheme()
  const [showPass, setShowPass] = useState(false)
  const { t } = useTranslation()

  const methods = useFormContext()

  const showSign = useCallback(() => {
    const inputText = methods.getValues("password")
    const error = !!methods.formState.errors?.password

    if (!!inputText.length || error) {
      return error ? (
        <CrossIcon
          style={{
            color: theme.palette.mockup.error50,
          }}
        />
      ) : (
        <CheckIcon
          style={{
            color: theme.palette.mockup.success50,
          }}
        />
      )
    }
  }, [methods])

  return (
    <TextField
      name="password"
      type={showPass ? "text" : "password"}
      fullWidth
      label={t("noAuthFlow.password")}
      InputProps={{
        endAdornment: (
          <>
            {isSignUp && showSign()}
            <InputAdornment
              sx={[
                { cursor: "pointer", color: "primary.main" },

                (theme) => ({
                  "&:hover": {
                    color: theme.palette.mockup.primary80,
                  },
                }),
              ]}
              position={"start"}
              onClick={() => setShowPass(!showPass)}
            >
              <EyeIcon />
            </InputAdornment>
          </>
        ),
      }}
    />
  )
}

export default PasswordTextField
