import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { ICreateCVArgs, IPostCreateCV, IResponseCreateCV } from "./cv.types"

export const useMutationCreateCV = (arg?: ICreateCVArgs) =>
  useMutation<AxiosResponse<IResponseCreateCV>, AxiosError, IPostCreateCV>(
    async ({ upload }) => {
      const formData = new FormData()
      formData.append("upload", upload)

      return await axiosInstance.post(Endpoint.CREATE_CV, formData)
    },
    arg?.options
  )
