import React from "react"

import { ReactComponent as SvgStudentDashboard } from "@common_assets/svg/penguin.svg"
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"
import { IStudentBalance } from "ts/interfaces/Student"

import Modules from "../modules"

interface IWithSavingAccount {
  data?: IStudentBalance
  isLoading: boolean
  isError: boolean
}

const WithSavingAccount = ({
  isLoading,
  isError,
  data,
}: IWithSavingAccount) => {
  const isDisplayView = useIsBreakpointUp("tablet")
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Stack mt="4px" direction="row" gap="10px" alignItems="center">
          <Typography
            fontSize={{ mobile: "14px", tablet: "18px" }}
            lineHeight={{ mobile: "24px", tablet: "32px" }}
            color="mockup.neutral10"
          >
            {t("dashboardStudent.yourBalance")}:
          </Typography>
          {isError && <ErrorText />}
          {isLoading && <CircularProgress size="24px" />}
          {data && !isLoading && (
            <Coin
              amount={data.total_balance}
              iconSize={
                process.env.REACT_APP_TYPE === "classequity" ? "24px" : "36px"
              }
              valueTextProps={{
                fontSize: { mobile: "20px", tablet: "24px" },
                lineHeight: { mobile: "24px", tablet: "32px" },
              }}
            />
          )}
        </Stack>
      </Box>
      <Stack>
        <Stack direction="row">
          <Modules data={data} />
          <Stack position="relative">
            {isDisplayView && (
              <Box position="absolute" bottom="-24px" left="40px">
                <SvgStudentDashboard width="220px" height="220px" />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
export default WithSavingAccount
