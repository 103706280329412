import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentClassGet } from "ts/interfaces/Class"

import {
  IGetStudentClassesArgs,
  IGetStudentClassesByIdArgs,
} from "./studentClasses.types"

export const useQueryStudentClasses = (args?: IGetStudentClassesArgs) =>
  useQuery<AxiosResponse<IStudentClassGet[]>, AxiosError>(
    ["studentClasses"],
    async () => axiosInstance(Endpoint.STUDENT_CLASSES),
    {
      ...args?.options,
    }
  )

export const useQueryStudentClassesById = (args: IGetStudentClassesByIdArgs) =>
  useQuery<AxiosResponse<IStudentClassGet>, AxiosError>(
    ["studentClasses", args.id],
    async () => axiosInstance(`${Endpoint.STUDENT_CLASSES}/${args.id}`),
    {
      ...args?.options,
    }
  )
