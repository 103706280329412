import React, { Dispatch } from "react"

import { useMutationTransactionsRemove } from "api/reactQuery/mutations/students"
import { IStudentTransactionTeacherSide } from "api/reactQuery/queries/transaction.types"
import Dialog from "components/common/dialog/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { Params } from "react-router"

interface IProps {
  isOpen: boolean
  onClose: () => void
  params: Readonly<Params<string>>
  transaction?: IStudentTransactionTeacherSide
  setDataTransactions: Dispatch<
    React.SetStateAction<IStudentTransactionTeacherSide[]>
  >
}

const DialogTransactions = ({
  isOpen,
  onClose,
  transaction,
  setDataTransactions,
  params,
}: IProps) => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()

  const { mutate: removeTransaction } = useMutationTransactionsRemove({
    options: {
      onSuccess: () => {
        setDataTransactions((prevData) => {
          const selectedTransaction = prevData.find(
            (el) => el.id === transaction?.id
          )?.amount

          const findIndex = prevData.findIndex(
            (el) => el.id === transaction?.id
          )

          const partTable =
            transaction?.booked_at !== null
              ? prevData.slice(0, findIndex)
              : prevData.slice(findIndex)

          const updatePartTable = partTable.map((element) => ({
            ...element,
            balance: element.balance - selectedTransaction!,
          }))

          const updateTransactions = prevData.filter(
            (item) => item.id !== transaction?.id
          )

          updatePartTable.map((updatedPartElement) => {
            const matchElement = updateTransactions.find(
              (updateElement) => updateElement.id === updatedPartElement.id
            )
            if (matchElement) {
              matchElement.balance = updatedPartElement.balance
            }
          })
          return updateTransactions
        })

        showSnackbar({
          title: t("studentProfile.transactionHasBeenRemovedSuccessful"),
          variant: "success",
        })
        onClose()
      },
    },
  })

  const handleRemoveTransaction = () => {
    removeTransaction({
      studentId: Number(params.studentId),
      transactionId: Number(transaction?.id),
    })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      actionAcceptText={t("studentProfile.delete")}
      titleText={t("studentProfile.areYouSureYouWantToDeleteThisTransaction")}
      onActionButtonClick={handleRemoveTransaction}
    />
  )
}

export default DialogTransactions
