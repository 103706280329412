import { RootState } from "../store.types"

export const selectActivePageSignUp = (state: RootState) =>
  state.auth.activePageSignUp
export const selectActivePageLogin = (state: RootState) =>
  state.auth.activePageLogin
export const selectRole = (state: RootState) => state.auth.role
export const login = (state: RootState) => state.auth.login
export const personalForm = (state: RootState) => state.auth.personalForm
export const selectDataAuth = (state: RootState) => state.auth.data
export const selectOwnSchool = (state: RootState) => state.auth.ownSchool
export const selectUser = (state: RootState) => state.auth.userInfo
export const selectRegistrationData = (state: RootState) =>
  state.auth.teacherRegistrationData
export const selectGoogleToken = (state: RootState) => state.auth.googleToken
