import { ThemeOptions } from "@mui/material"

const muiFab: ThemeOptions["components"] = {
  MuiFab: {
    defaultProps: {
      variant: "outlined",
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 48,
        height: 48,
        minHeight: 48,
        boxShadow: "none",
      },
    },
    variants: [
      {
        props: { variant: "contained" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.mockup.neutral100,
          ":hover": {
            backgroundColor: theme.palette.mockup.primary80,
          },
          ":focus": {
            backgroundColor: theme.palette.mockup.primary20,
            boxShadow: "none",
          },
          ":disabled": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.mockup.neutral100,
          },
        }),
      },
      {
        props: { variant: "outlined" },
        style: ({ theme }) => ({
          backgroundColor: "transparent",
          border: `2px solid ${theme.palette.primary.light}`,
          color: theme.palette.primary.main,
          ":hover": {
            backgroundColor: theme.palette.primary.light,
          },
          ":focus": {
            backgroundColor: theme.palette.primary.light,
            boxShadow: "none",
          },
          ":disabled": {
            backgroundColor: "transparent",
            color: theme.palette.primary.light,
            border: `2px solid ${theme.palette.mockup.primary95}`,
          },
        }),
      },
      {
        props: { variant: "contained", color: "primary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          ":hover": {
            backgroundColor: theme.palette.primary.light,
          },
          ":focus": {
            backgroundColor: theme.palette.primary.light,
            boxShadow: "none",
          },
          ":disabled": {
            backgroundColor: "transparent",
            color: theme.palette.primary.light,
            border: `2px solid ${theme.palette.mockup.primary95}`,
          },
        }),
      },
      {
        props: { size: "small" },
        style: {
          width: 32,
          height: 32,
          minHeight: 32,
        },
      },
    ],
  },
}

export default muiFab
