import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostStudentClassesArgs,
  IPostStudentClassesInput,
} from "./studentClasses.types"

export const useMutationPostStudentClasses = (
  hookArgs?: IPostStudentClassesArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStudentClassesInput>(
    async ({ invitation_code }) =>
      await axiosInstance.post(Endpoint.STUDENT_CLASSES, {
        invitation_code,
      }),
    hookArgs?.options
  )
