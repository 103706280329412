import { useMemo, useState } from "react"

interface IArgs<T> {
  data: T[]
  getFilterBy?: (el: T) => string
}

export const useFilterItems = <T>({ data, getFilterBy }: IArgs<T>) => {
  const [searchFilter, setSearchFilter] = useState("")

  const filteredData = useMemo(
    () => [
      ...data.filter(
        (el) =>
          getFilterBy &&
          getFilterBy(el)
            .toLocaleLowerCase()
            .includes(searchFilter.toLocaleLowerCase())
      ),
    ],
    [data, searchFilter]
  )

  return {
    filteredData,
    searchFilter,
    setSearchFilter,
  }
}

export const useFilterMultipleItems = <
  T extends { first_name: string; last_name: string; title: string }
>({
  data,
}: IArgs<T>) => {
  const [searchFilter, setSearchFilter] = useState("")

  const filteredMultiData = data.filter((el) => {
    const { first_name, last_name, title } = el
    return (
      first_name
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase()) ||
      last_name
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase()) ||
      `${first_name} ${last_name}`
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase()) ||
      `${last_name} ${title}`
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase()) ||
      `${first_name} ${title}`
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase()) ||
      `${first_name} ${last_name} ${title}`
        .toLocaleLowerCase()
        .includes(searchFilter.toLocaleLowerCase())
    )
  })

  return {
    searchFilter,
    setSearchFilter,
    filteredMultiData,
  }
}
