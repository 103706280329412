import React, { FC } from "react"

import { Grid, useTheme } from "@mui/material"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import TextField from "components/form/common/field/textField"

interface IProps {
  isLoading: boolean
  name: string
  label: string
  isPassword?: boolean
  placeholder?: string
}

const TextFieldAccountInformation: FC<IProps> = ({
  isLoading,
  name,
  label,
  isPassword,
  placeholder,
}) => {
  const theme = useTheme()
  return (
    <Grid item tablet={6} mobile={12}>
      {isLoading ? (
        <StudentSkeleton height="50px" />
      ) : (
        <>
          {isPassword && (
            <TextField
              name={name}
              label={label}
              placeholder={placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.mockup.neutral0,
                    opacity: 1,
                  },
                  "&:focus::placeholder": {
                    color: "transparent",
                  },
                },
              }}
            />
          )}
          {!isPassword && <TextField name={name} label={label} />}
        </>
      )}
    </Grid>
  )
}

export default TextFieldAccountInformation
