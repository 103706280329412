import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentBalance } from "ts/interfaces/Student"

import { IGetStudentBalanceArgs } from "./studentBalance.types"

export const useQueryStudentBalance = (args?: IGetStudentBalanceArgs) =>
  useQuery<AxiosResponse<IStudentBalance>, AxiosError>(
    ["studentBalance"],
    async () => await axiosInstance(Endpoint.STUDENT_BALANCE),
    {
      ...args?.options,
    }
  )
