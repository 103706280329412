import { ReactComponent as Logo } from "@brand_assets/svg/main-logo.svg"
import { ReactComponent as IconArrowSide } from "@common_assets/svg/arrow-side.svg"
import { Stack } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useAppDispatch } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { NavLink } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { toggleIsMenuOpened } from "store/menu/menu.slice"
import { UserRole } from "ts/enums/User"
import { getAuthDataLocalStorage } from "utils/authData"

const NavHeader = () => {
  const dispatch = useAppDispatch()

  const toggleMenu = () => {
    if (getAuthDataLocalStorage()?.role === UserRole.TEACHER) {
      mixpanel.track("side_bar", {
        action: "close",
      })
    }
    dispatch(toggleIsMenuOpened())
  }

  return (
    <Stack
      mb="16px"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <NavLink to={RoutePath.HOMEPAGE}>
        <Logo />
      </NavLink>
      {getAuthDataLocalStorage()?.role === UserRole.TEACHER && (
        <IconWrapper
          color="mockup.neutral100"
          sx={{ cursor: "pointer" }}
          onClick={toggleMenu}
        >
          <IconArrowSide />
        </IconWrapper>
      )}
    </Stack>
  )
}

export default NavHeader
