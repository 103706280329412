import { FC } from "react"

import {
  useMutationPatchClassesStoreClerkItems,
  useMutationPatchClassesStoreItems,
} from "api/reactQuery/mutations/classesStoreItems"
import { shopDefaultAvatars } from "components/module/allItemsStoreTeacher/AllItemsStoreTeacher.config"
import ItemDialogForm from "components/module/allItemsStoreTeacher/common/itemDialogForm"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { IClassStoreItem } from "ts/interfaces/Class"
import { getErrorMessageFromTab } from "utils/api"

interface IProps {
  isOpen: boolean
  handleClose: () => void
  item: IClassStoreItem
  dashboardIcons?: boolean
}

const DialogEdit: FC<IProps> = ({
  isOpen,
  dashboardIcons,
  handleClose,
  item,
}) => {
  const { t } = useTranslation()
  const classId = dashboardIcons ? item.class_?.id : useClassId()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const location = useLocation()
  const isStudentClerk = location.pathname.includes("storeClerk")

  const { mutateAsync: patchItemAsync, isLoading: isLoadingPatchAsync } =
    useMutationPatchClassesStoreItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["classesStoreItems", Number(classId)])
          queryClient.invalidateQueries(["dashboardStoreUpdates"])

          showSnackbar({
            title: t("store.itemHasBeenEditedSuccessfully"),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  const {
    mutateAsync: patchItemClerkAsync,
    isLoading: isLoadingPatchClerkAsync,
  } = useMutationPatchClassesStoreClerkItems({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "classesStoreClerkItems",
          Number(classId),
        ])
        queryClient.invalidateQueries(["dashboardStoreUpdates"])

        showSnackbar({
          title: t("store.itemHasBeenEditedSuccessfully"),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  return (
    <ItemDialogForm
      open={isOpen}
      onClose={handleClose}
      title={t("store.editItem")}
      initialState={{
        title: item.name,
        quantity: item.quantity_in_stock,
        amount: item.price,
        imageUrl: item.icon_url || "",
        uploadedImageUrl:
          shopDefaultAvatars.find((avatar) => avatar === item.icon_url) ||
          !item.icon_url
            ? ""
            : item.icon_url,
      }}
      isLoading={
        isStudentClerk ? isLoadingPatchClerkAsync : isLoadingPatchAsync
      }
      onSubmit={(data) =>
        isStudentClerk
          ? patchItemClerkAsync({
              classId: Number(classId),
              data: {
                items: [
                  {
                    ...item,
                    icon_url: data.imageUrl,
                    price: Number(data.amount),
                    name: data.title,
                    quantity_in_stock:
                      !data.quantity && data.quantity !== 0
                        ? null
                        : Number(data.quantity),
                  },
                ],
              },
            })
          : patchItemAsync({
              classId: Number(classId),
              data: {
                items: [
                  {
                    ...item,
                    icon_url: data.imageUrl,
                    price: Number(data.amount),
                    name: data.title,
                    quantity_in_stock:
                      !data.quantity && data.quantity !== 0
                        ? null
                        : Number(data.quantity),
                  },
                ],
              },
            })
      }
    />
  )
}

export default DialogEdit
