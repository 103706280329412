import { FC } from "react"

import { Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import RichEditor from "pages/jobs/RichEditor"
import { useTranslation } from "react-i18next"
import { IStudentJob } from "ts/interfaces/Job"

interface IProps {
  job: IStudentJob
}

const JobItem: FC<IProps> = ({ job }) => {
  const { t } = useTranslation()

  return (
    <Stack
      p="16px"
      bgcolor="mockup.dashboardBlue"
      borderRadius="16px"
      gap="16px"
    >
      <Stack direction="row" gap="16px" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography
            variant="body1"
            color="mockup.neutral10"
            lineHeight="24px"
          >
            {job.title}
          </Typography>
          <Typography variant="body3" color="mockup.neutral40">
            |
          </Typography>
          <Typography fontWeight={500} variant="body1" color="mockup.neutral10">
            {job.class_.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Coin amount={`${job.salary}`} />
          <Typography
            textTransform="lowercase"
            color="mockup.neutral10"
            lineHeight="24px"
          ></Typography>
        </Stack>
      </Stack>
      <Stack gap="4px">
        <Typography variant="body3" color="primary.main" fontWeight={500}>
          {t("dashboardStudent.responsibilities")}:
        </Typography>
        <RichEditor state={job.responsibilities} readOnly={true} />
      </Stack>
    </Stack>
  )
}

export default JobItem
