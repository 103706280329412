import { memo } from "react"

import { useIsBreakpointUp } from "hooks/breakpoint"

import ListingView from "./listingView"
import TableView from "./tableView"

const AllItemsStoreTeacher = () => {
  const isTablet = useIsBreakpointUp("tablet")

  return isTablet ? <TableView /> : <ListingView />
}

export default memo(AllItemsStoreTeacher)
