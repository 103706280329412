import { Box, styled } from "@mui/material"

export const DropdownStyles = styled(Box)(({ theme }) => ({
  boxSizing: "content-box",
  position: "fixed",
  left: "-2px",
  bottom: "-58px",
  minHeight: "56px",
  textAlign: "center",
  lineHeight: "56px",
  width: "100%",
  border: "2px solid transparent",
  background: `${theme.palette.mockup.primary95}`,
  color: `${theme.palette.primary.main}`,
  textTransform: "uppercase",
  cursor: "pointer",
  borderBottomRightRadius: "16px",
  borderBottomLeftRadius: "16px",
  boxShadow: "0px 0px 0px rgba(210, 218, 249, 0.25)",
  ":hover": {
    transition: ".2s",
    background: theme.palette.primary.light,
  },
}))
