import { FC, FormEvent, useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useMutationPostStudentStorePurchase } from "api/reactQuery/mutations/studentStorePurchase"
import { useQueryStudentDashboardPendingTransactions } from "api/reactQuery/queries/studentDashboardTransactionsPending"
import TextField from "components/form/common/field/textField"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { FormProvider, get, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"
import { removeCommasNumber } from "utils/decimalNumber"
import { requiredNumberSchemaCommasAndDots } from "utils/yup"
import * as yup from "yup"

import { Cart } from "../../StoreStudent.types"

interface IProps {
  cart: Cart
  onPurchaseSuccess: () => void
}

const CartForm: FC<IProps> = ({ cart, onPurchaseSuccess }) => {
  const { t } = useTranslation()
  const classId = useClassId()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()

  const { data, refetch } = useQueryStudentDashboardPendingTransactions({
    options: {
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessage(error)),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  const { mutate: postPurchase, isLoading: isLoadingPurchase } =
    useMutationPostStudentStorePurchase({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["studentBalance"])
          queryClient.invalidateQueries(["studentStoreItems"])
          showSnackbar({
            title: t("dashboardStudent.transactionCalculatedCorrectly"),
          })
          onPurchaseSuccess()
        },
        onError: (error) => {
          const errorTab = get(error.response?.data, "detail", null)
          const index = errorTab.map((el: { loc: string[] }) =>
            el.loc.findIndex((el: string) => el === "items_ids")
          )

          if (index.some((el: number) => el !== -1)) {
            const res: {
              name: string
              amount: number
              error: string
            }[] = []

            Object.values(cart).map((element) => {
              const name = element.item.name
              const quantity = element.quantity
              const quantity_in_stock = element.item.quantity_in_stock

              const slicedArr =
                quantity_in_stock && quantity_in_stock < quantity
                  ? errorTab
                      .slice(0, 1)
                      .map(
                        ({ msg }: { msg: string[] }) =>
                          msg + ". Update your cart and try again"
                      )
                  : [""]

              quantity_in_stock &&
                quantity_in_stock < quantity &&
                errorTab.splice(0, quantity)

              res.push({
                name: name,
                amount: quantity,
                error: slicedArr.join(" "),
              })
            })

            res.forEach((element) => {
              if (element.error.length) {
                showSnackbar({
                  title: `${element.error
                    .replace("You can buy", "There are")
                    .replace("pieces of this item", element.name + " left")}.`,
                  variant: "error",
                  options: { autoHideDuration: 4000 },
                })
              }
            })
          } else {
            showSnackbar({
              title: getErrorMessageFromTab(error),
              variant: "error",
            })
          }
        },
      },
    })

  const methods = useForm<{
    newBalance: number | string
  }>({
    defaultValues: { newBalance: "" },
    resolver: yupResolver(
      yup.object({
        newBalance: requiredNumberSchemaCommasAndDots,
      })
    ),
  })

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    methods.handleSubmit((data) => {
      postPurchase({
        classId: Number(classId),
        data: {
          items_ids: Object.values(cart).flatMap((cartItem) =>
            [...Array(cartItem.quantity)].map(() => cartItem.item.id)
          ),
          new_balance: Number(removeCommasNumber(data.newBalance)),
        },
      })
    })()
  }

  return (
    <FormProvider {...methods}>
      <Stack component="form" gap="24px" onSubmit={onSubmit}>
        <TextField
          sx={{ mb: "12px" }}
          name="newBalance"
          label={t("storeStudent.calculateNewAccountBalance")}
        />
        <Stack>
          <Button
            type="submit"
            fullWidth
            disabled={!!data?.data.length || isLoadingPurchase}
          >
            {isLoadingPurchase && (
              <Box component="span" sx={{ mr: 1 }}>
                <CircularProgress size="16px" color="inherit" />
              </Box>
            )}
            <Stack direction="row" gap="8px">
              {t("storeStudent.payNow")}!
            </Stack>
          </Button>
          {!!data?.data.length && (
            <Typography
              mt={2}
              color="mockup.neutral40"
              variant="body3"
              lineHeight="17px"
            >
              {t("storeStudent.beforeYouBuy")}
            </Typography>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default CartForm
