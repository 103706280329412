import { ThemeOptions } from "@mui/material"

const muiAppBar: ThemeOptions["components"] = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: "none",
        backgroundColor: theme.palette.common.white,
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: "56px",
        [theme.breakpoints.up("tablet")]: {
          minHeight: "64px",
        },
        [theme.breakpoints.up("desktop")]: {
          minHeight: "68px",
        },
      }),
    },
  },
}

export default muiAppBar
