import { FC } from "react"

import { FormControlLabel, Radio, RadioProps } from "@mui/material"

interface IProps extends RadioProps {
  value: string
  label: string
}

const RadioField: FC<IProps> = ({ value, label, ...props }) => {
  return (
    <FormControlLabel
      value={value}
      label={label}
      control={<Radio color="primary" {...props} />}
    />
  )
}

export default RadioField
