import { FC } from "react"

import { Typography, TypographyProps } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps extends TypographyProps {
  text?: string
}

const ErrorText: FC<IProps> = ({
  text,
  variant = "subtitle1",
  color = "error.main",
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Typography variant={variant} color={color} {...props}>
      {text || t("validations.anErrorOccured")}
    </Typography>
  )
}

export default ErrorText
