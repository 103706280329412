export const transaction = {
  sendTransaction: "Send transaction",
  selected: "Selected",
  bonuses: "Bonuses",
  fines: "Fines",
  paychecks: "Paychecks",
  expenses: "Expenses",
  autopay: "Autopay",
  on: "On",
  of: "Off",
  other: "Other",
  value: "Value",
  bonusTitle: "Bonus title",
  fineTitle: "Fine title",
  paycheckTitle: "Paycheck title",
  expenseTitle: "Expense title",
  sendPaychecksForHiredJobs: "Send paychecks for hired jobs",
  selectStudent: "Select student",
  studentsClass: "Student's class",
  classDontHaveAnyBonusesYet: "The class doesn't have any bonuses yet",
  classDontHaveAnyFinesYet: "The class doesn't have any fines yet",
  classDontHaveAnyExpensesYet: "The class doesn't have any expenses yet",
  toSeeTheListSelectStudentAndTheirClass:
    "To see the list, select a student and their class",
  sendPaycheckForHiredJobs: "Send paychecks for hired jobs",
  dependsOnHiredJob: "Depends on hired job",
  selectAtLeastOneTile: "Select at least one tile",
  selectUpTo20Tiles: "Select up to 20 tiles",
  transactionHasBeenSent: "The transaction has been sent",
  completeAtLeastOneOfDisplayTransactions:
    "Complete at least one of display transactions",
  youDonyHavePermissionToTheListYouCanOnlySendTransactionsByCustomInput:
    "You don't have permission to the list, you can only send transaction by custom input",
}
