import BasicTable from "components/common/table/basicTable/BasicTable"
import { useTranslation } from "react-i18next"

const TodoHeader = () => {
  const { t } = useTranslation()
  return (
    <BasicTable.Row>
      <BasicTable.Cell tableCellProps={{ width: "50%" }} minWidth="100px">
        <BasicTable.HeadText>{t("dashboard.student")}</BasicTable.HeadText>
      </BasicTable.Cell>
      <BasicTable.Cell tableCellProps={{ width: "50%" }} minWidth="100px">
        <BasicTable.HeadText>{t("dashboard.title")}</BasicTable.HeadText>
      </BasicTable.Cell>
      <BasicTable.Cell minWidth="80px" justifyContent="center">
        <BasicTable.HeadText>{t("dashboard.value")}</BasicTable.HeadText>
      </BasicTable.Cell>
      <BasicTable.Cell minWidth="110px" />
    </BasicTable.Row>
  )
}

export default TodoHeader
