import { ReactComponent as IconChevronDown } from "@common_assets/svg/chevron-down.svg"
import { ThemeOptions } from "@mui/material"

const muiTable: ThemeOptions["components"] = {
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: "none",
      },
      head: {
        padding: "10px 0px",
        lineHeight: "16px",
      },
      body: ({ theme }) => ({
        padding: "12px 0px",

        "& .mui-body-cell-border": {
          borderRight: `1px solid ${theme.palette.mockup.primary95}`,
        },

        "&:last-child .mui-body-cell-border": {
          border: "none",
        },
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        border: "none",
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.mockup.primary99,
        },
      }),
      head: ({ theme }) => ({
        backgroundColor: theme.palette.mockup.primary95,
      }),
    },
  },
  MuiTableSortLabel: {
    defaultProps: {
      IconComponent: IconChevronDown,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        "&.Mui-active .MuiTableSortLabel-icon": {
          color: theme.palette.mockup.primary20,
        },
      }),
      icon: ({ theme }) => ({
        color: theme.palette.mockup.primary20,
        height: "16px",
        width: "16px",
        flexShrink: "0",
      }),
    },
  },
}

export default muiTable
