import React, { useState } from "react"

import { Stack, useMediaQuery } from "@mui/material"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"
import { selectAccount } from "store/savings/savings.selectors"
import { hasStudentAccount } from "utils/roleCheck"

import Chart from "./chart"
import Goals from "./goals"
import SavingsColumn from "./savingsColumn"
import InterestColumn from "./transactions/interestColumn"
import Transaction from "./transactions/Transaction"
import TransactionsHistory from "./transactionsHistory"

// My Activity section is renamed to Accounts!
const MyActivity = () => {
  const classData = useAppSelector(selectAccount)
  const userInfo = useAppSelector(selectUser)
  const [searchWithPage, setSearchWithPage] = useState({
    page: 0,
    value: "",
  })
  const [typeValue, setTypeValue] = useState("all_types")
  const [isDateDisplay, setIsDateDisplay] = useState("all")
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const isMobile = useMediaQuery("(max-width: 1400px)")
  const isInterestOption = classData?.interest_option

  const initialData = () => {
    return {
      setTypeValue,
      setIsDateDisplay,
      setStartDate,
      setEndDate,
      setSearchWithPage,
    }
  }

  const propsTransactionsHistory = () => {
    return {
      typeValue,
      isDateDisplay,
      startDate,
      endDate,
      searchWithPage,
    }
  }

  return (
    <Stack
      columnGap="10px"
      rowGap="16px"
      direction={isMobile ? "column" : "row"}
    >
      {hasStudentAccount(userInfo) && (
        <Stack>
          <SavingsColumn initialData={initialData} />
        </Stack>
      )}
      <Stack width="100%">
        <Transaction>
          <Transaction.TransactionRowComponent>
            <Chart />
            {isInterestOption &&
              !isMobile &&
              !!classData &&
              hasStudentAccount(userInfo) && <InterestColumn />}
          </Transaction.TransactionRowComponent>
          {isInterestOption &&
            isMobile &&
            !!classData &&
            hasStudentAccount(userInfo) && (
              <Transaction.TransactionRowComponent>
                <InterestColumn />
              </Transaction.TransactionRowComponent>
            )}
          <Transaction.TransactionColumnComponent>
            {!!classData && hasStudentAccount(userInfo) && <Goals />}
            <TransactionsHistory
              onClickLoadMore={() =>
                setSearchWithPage((prev) => ({ ...prev, page: prev.page + 10 }))
              }
              initialData={initialData}
              propsTransactionsHistory={propsTransactionsHistory}
            />
          </Transaction.TransactionColumnComponent>
        </Transaction>
      </Stack>
    </Stack>
  )
}
export default MyActivity
