import { FC } from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check-curved.svg"
import { Box } from "@mui/system"
import IconWrapper from "components/common/icon/iconWrapper"

interface IProps {
  avatarUrl: string
  isChecked: boolean
  onClick: () => void
}

const AvatarItem: FC<IProps> = ({ avatarUrl, isChecked, onClick }) => {
  return (
    <Box
      p={isChecked ? "6px" : "8px"}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      borderRadius="4px"
      border={(theme) =>
        isChecked ? `2px solid ${theme.palette.mockup.neutral0}` : "none"
      }
      position="relative"
      display="flex"
    >
      {isChecked && (
        <IconWrapper
          color="mockup.neutral0"
          position="absolute"
          top="-8px"
          right="-8px"
          width="16px"
          height="16px"
        >
          <IconCheck />
        </IconWrapper>
      )}
      <Box
        height="44px"
        width="44px"
        component="img"
        src={avatarUrl}
        alt="avatar"
      />
    </Box>
  )
}

export default AvatarItem
