const AVATAR_BASE_URL = process.env.REACT_APP_AVATARS_USER

export const userAvatars = [
  `${AVATAR_BASE_URL}bat.png`,
  `${AVATAR_BASE_URL}bee.png`,
  `${AVATAR_BASE_URL}cat.png`,
  `${AVATAR_BASE_URL}chameleon.png`,
  `${AVATAR_BASE_URL}chick.png`,
  `${AVATAR_BASE_URL}cow.png`,
  `${AVATAR_BASE_URL}crab.png`,
  `${AVATAR_BASE_URL}deer.png`,
  `${AVATAR_BASE_URL}elephant.png`,
  `${AVATAR_BASE_URL}fish.png`,
  `${AVATAR_BASE_URL}flamingo.png`,
  `${AVATAR_BASE_URL}fox.png`,
  `${AVATAR_BASE_URL}frog.png`,
  `${AVATAR_BASE_URL}giraffe.png`,
  `${AVATAR_BASE_URL}hippo.png`,
  `${AVATAR_BASE_URL}horse.png`,
  `${AVATAR_BASE_URL}lion.png`,
  `${AVATAR_BASE_URL}monkey.png`,
  `${AVATAR_BASE_URL}mouse.png`,
  `${AVATAR_BASE_URL}octopus.png`,
  `${AVATAR_BASE_URL}orca.png`,
  `${AVATAR_BASE_URL}panda.png`,
  `${AVATAR_BASE_URL}pig.png`,
  `${AVATAR_BASE_URL}snake.png`,
]

export const userAvatarsBankee = [
  `${AVATAR_BASE_URL}bat.png`,
  `${AVATAR_BASE_URL}bee.png`,
  `${AVATAR_BASE_URL}cat.png`,
  `${AVATAR_BASE_URL}chameleon.png`,
  `${AVATAR_BASE_URL}chick.png`,
  `${AVATAR_BASE_URL}cow.png`,
  `${AVATAR_BASE_URL}crab.png`,
  `${AVATAR_BASE_URL}deer.png`,
  `${AVATAR_BASE_URL}elephant.png`,
  `${AVATAR_BASE_URL}fish.png`,
  `${AVATAR_BASE_URL}flamingo.png`,
  `${AVATAR_BASE_URL}fox.png`,
  `${AVATAR_BASE_URL}frog.png`,
  `${AVATAR_BASE_URL}giraffe.png`,
  `${AVATAR_BASE_URL}horse.png`,
  `${AVATAR_BASE_URL}lion.png`,
  `${AVATAR_BASE_URL}monkey.png`,
  `${AVATAR_BASE_URL}mouse.png`,
  `${AVATAR_BASE_URL}octopus.png`,
  `${AVATAR_BASE_URL}orca.png`,
  `${AVATAR_BASE_URL}panda.png`,
  `${AVATAR_BASE_URL}snake.png`,
]
