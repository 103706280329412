import React from "react"

import { IPostCreateJobsItem } from "api/reactQuery/mutations/jobs.types"
import Dialog from "components/common/dialog/dialog"
import AddJob from "pages/jobs/addJob"
import { useTranslation } from "react-i18next"

interface IDialogAddJob {
  isOpen: boolean
  handleClose: () => void
  isLoading: boolean
  onCreate: (state: IPostCreateJobsItem) => void
  setRichEditorResponsibilities: React.Dispatch<React.SetStateAction<string>>
  setRichEditorQualifications: React.Dispatch<React.SetStateAction<string>>
  richEditorResponsibilities: string
  richEditorQualifications: string
}

const DialogAddJob = ({
  isOpen,
  handleClose,
  isLoading,
  onCreate,
  setRichEditorResponsibilities,
  setRichEditorQualifications,
  richEditorQualifications,
  richEditorResponsibilities,
}: IDialogAddJob) => {
  const { t } = useTranslation()

  return (
    <Dialog
      titleText={t("jobs.addJob")}
      open={isOpen}
      isLoading={isLoading}
      onClose={() => {
        setRichEditorResponsibilities("")
        setRichEditorQualifications("")
        handleClose()
      }}
      desktopMaxWidth="1040px"
      tabletMaxWidth="534px"
      actionAcceptText={t("jobs.addJob")}
      actionAcceptButtonProps={{
        type: "submit",
        form: "addNewJob",
        disabled: isLoading,
      }}
    >
      <AddJob
        setRichEditorResponsibilities={setRichEditorResponsibilities}
        richEditorResponsibilities={richEditorResponsibilities}
        setRichEditorQualifications={setRichEditorQualifications}
        richEditorQualifications={richEditorQualifications}
        id="addNewJob"
        createJob={onCreate}
      />
    </Dialog>
  )
}
export default DialogAddJob
