import { FC, useEffect, useRef, useState } from "react"

import { ReactComponent as Salary } from "@common_assets/svg/greenDollar.svg"
import { ReactComponent as Infinite } from "@common_assets/svg/infinity.svg"
import { ReactComponent as Suitcase } from "@common_assets/svg/suitcase.svg"
import { ReactComponent as UserTeacher } from "@common_assets/svg/userTeacher.svg"
import { Stack, Box, Typography, Button, Divider } from "@mui/material"
import { useMutationStudentJobApplications } from "api/reactQuery/mutations/students"
import { useQueryGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import RichEditor from "pages/jobs/RichEditor"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"

import { JOBS_PANEL_VIEW_BREAKPOINT } from "../JobsPanel.config"
import NoSpecifyJob from "../noSpecifyJob"
import ApplicationModule from "./applicationModule"

const JobsOfferSpecify: FC<{
  jobId: number | null
  isExpanded: boolean
}> = ({ jobId, isExpanded }) => {
  const editorsWrapperRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState(0)
  const isRowView = useIsBreakpointUp(JOBS_PANEL_VIEW_BREAKPOINT)
  const isTablet = useIsBreakpointUp("tablet")
  const params = useParams()
  const { t } = useTranslation()
  const [state, setState] = useState<IGetStudentPossibleJobsInClass[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const {
    handleOpen: handleOpenApplicationDialog,
    isOpen: isOpenApplicationDialog,
    handleClose: handleCloseApplicationDialog,
  } = useDialog()

  useQueryGetStudentPossibleJobsInClass({
    classId: Number(params.classId),
    options: {
      onSuccess: (data) => {
        const result = data.data.filter((el) => el.id === jobId)
        setState(result)
      },
    },
  })

  const { mutate: postJobApplication, isLoading: isLoadingPostJobApplication } =
    useMutationStudentJobApplications({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries("studentJobsInClass")
          showSnackbar({
            title: t("studentJobs.yourApplicationHasBeenSentSuccessfully"),
          })
          handleCloseApplicationDialog()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  useEffect(() => {
    return () => {
      setState([])
    }
  }, [params.classId])

  useEffect(() => {
    if (
      editorsWrapperRef.current &&
      editorsWrapperRef.current.clientHeight !== height
    )
      setHeight(editorsWrapperRef.current.clientHeight)
  })

  return (
    <>
      {state.length === 0 ? (
        <NoSpecifyJob />
      ) : (
        <Stack
          sx={(theme) => ({
            borderRadius: "8px",
            backgroundColor: "mockup.primary99",
            width: "100%",
            px: 0,
            py: 0,
            overflowY: "auto",
            maxHeight: {
              mobile: "none",
              desktop: "calc(100vh - 68px - 82px)",
            },
            [theme.breakpoints.up("tablet")]: {
              px: 3,
              py: 4,
              backgroundColor: "mockup.neutral100",
            },
          })}
          className="smooth-scrollbar"
        >
          <Box
            sx={{
              px: { mobile: 0, tablet: 3 },
              mb: 3,
              display: "flex",
              flexDirection: { mobile: "column", tablet: "row" },
              justifyContent: "space-between",
              alignItems: { mobile: "start", tablet: "center" },
              gap: "16px",
            }}
          >
            <Typography fontSize="24px" color="mockup.neutral10">
              {state[0].title}
            </Typography>
            <Box
              minHeight="48px"
              display="flex"
              alignItems="center"
              width={{ mobile: "100%", tablet: "auto" }}
            >
              {state[0].pending_application ? (
                <Box
                  sx={{
                    backgroundColor: "mockup.secondary90",
                    color: "mockup.secondary60",
                    p: "4px 8px",
                    borderRadius: "6px",
                  }}
                >
                  {t("studentJobs.pending").toUpperCase()}
                </Box>
              ) : state[0].is_hired ? (
                <Box
                  sx={{
                    backgroundColor: "mockup.success90",
                    color: "mockup.success60",
                    p: "4px 8px",
                    borderRadius: "6px",
                  }}
                >
                  {t("studentJobs.hired").toUpperCase()}
                </Box>
              ) : (
                <Button
                  disabled={
                    Number(state[0].positions) - state[0].employees_count ===
                      0 && state[0].positions !== null
                  }
                  sx={{ height: "100%" }}
                  onClick={handleOpenApplicationDialog}
                  fullWidth={!isTablet}
                >
                  {t("studentJobs.applyNow")}
                </Button>
              )}
            </Box>
          </Box>
          <Dialog
            open={isOpenApplicationDialog}
            onClose={handleCloseApplicationDialog}
            titleText={t("studentJobs.fillOutTheApplication")}
            isLoading={isLoading || isLoadingPostJobApplication}
            actionAcceptButtonProps={{
              disabled: isLoading || isLoadingPostJobApplication,
              type: "submit",
              form: "form",
            }}
          >
            <ApplicationModule
              setIsLoading={setIsLoading}
              postJobApplication={postJobApplication}
              state={state}
              id="form"
            />
          </Dialog>
          {isTablet && <Divider style={{ width: "100%" }} />}
          <Box
            sx={{
              px: { mobile: 0, tablet: 3 },
              minHeight: "46px",
              my: { mobile: 0, tablet: 3 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              px={{ mobile: 0, tablet: 3 }}
              width="100%"
              height="100%"
              display="flex"
              flexDirection={{ mobile: "column", tablet: "row" }}
              justifyContent="space-between"
              gap="16px"
            >
              <Box display="flex">
                <UserTeacher />
                <Box ml={2}>
                  <Typography variant="subtitle1" color="mockup.neutral70">
                    {t("studentJobs.employer")}:
                  </Typography>
                  <Typography
                    variant="body2"
                    color="mockup.neutral10"
                    fontWeight="500"
                  >
                    {state[0].teacher?.first_name
                      ? state[0].teacher.first_name
                      : "-"}{" "}
                    {state[0].teacher?.last_name
                      ? state[0].teacher.last_name
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Suitcase />
                <Box ml={2}>
                  <Typography variant="subtitle1" color="mockup.neutral70">
                    {t("studentJobs.openPositions")}:
                  </Typography>
                  <Typography
                    variant="body2"
                    color="mockup.neutral10"
                    fontWeight="500"
                  >
                    {state[0].positions === null ? (
                      <Infinite />
                    ) : (
                      Number(state[0].positions) - state[0].employees_count
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Salary />
                <Box ml={2}>
                  <Typography variant="subtitle1" color="mockup.neutral70">
                    {t("studentJobs.salary")}:
                  </Typography>
                  <Coin amount={state[0].salary} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Stack
            sx={{
              maxHeight: isRowView ? "none" : isExpanded ? height : 0,
              transition: "max-height 0.5s",
              overflowY: "auto",
            }}
            className="smooth-scrollbar"
          >
            <Stack ref={editorsWrapperRef}>
              {isTablet && <Divider style={{ width: "100%" }} />}
              <Box
                sx={{
                  px: { mobile: 0, tablet: 3 },
                  mt: 3,
                }}
              >
                <Box mb={3}>
                  <Typography
                    variant="subtitle1"
                    color="mockup.neutral70"
                    mb={2}
                  >
                    {t("studentJobs.responsibilities")}:
                  </Typography>
                  <RichEditor
                    state={state[0].responsibilities}
                    id={state[0].id}
                    readOnly={true}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="mockup.neutral70"
                    mb={2}
                  >
                    {t("studentJobs.qualifications")}:
                  </Typography>
                  <RichEditor
                    state={state[0].qualifications}
                    readOnly={true}
                    id={state[0].id}
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default JobsOfferSpecify
