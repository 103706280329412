import { FC, useEffect } from "react"

import { Stack, Typography } from "@mui/material"
import { useQueryClasses } from "api/reactQuery/queries/classes"
import SelectField from "components/form/common/field/selectField"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { selectUser } from "store/auth/auth.selectors"
import { StudentOverviewSorting, StudentOverviewTimeRange } from "ts/types/Api"
import { checkSubscription } from "utils/api"

import FabFiltersPopover from "./fabFiltersPopover"

interface IFormState {
  classId?: string
  timeRange?: StudentOverviewTimeRange
  sort?: StudentOverviewSorting
}

interface IProps {
  onSubmit: (data: IFormState) => void
}

const TableFilters: FC<IProps> = ({ onSubmit }) => {
  const userInfo = useAppSelector(selectUser)
  const { t } = useTranslation()
  const { data, isError } = useQueryClasses({
    options: { enabled: checkSubscription(userInfo) },
  })
  const isTablet = useIsBreakpointUp("tablet")

  const methods = useForm<IFormState>({
    defaultValues: {
      classId: "all",
      timeRange: "this_month",
      sort: "most_bonuses",
    },
  })

  const formWatcher = useWatch({
    name: ["classId", "timeRange", "sort"],
    control: methods.control,
  })

  const getClassesOptions = () => {
    const options = [
      {
        value: "all",
        label: t("dashboard.allClasses"),
      },
    ]

    if (!data) return options

    return [
      ...options,
      ...data.data.map((teacherClass) => ({
        value: `${teacherClass.id}`,
        label: teacherClass.name,
      })),
    ]
  }

  useEffect(() => {
    methods.handleSubmit(onSubmit)()
  }, [formWatcher])

  return (
    <FormProvider {...methods}>
      <Stack
        direction={isTablet ? "row" : "column"}
        justifyContent="space-between"
        alignItems={isTablet ? "center" : "start"}
        px="16px"
        py="12px"
        component="form"
      >
        <Typography
          variant="subtitle1"
          color="mockup.neutral10"
          mb={isTablet ? "0px" : "8px"}
        >
          {t("dashboard.classOverview")}
        </Typography>
        <Stack
          direction="row"
          columnGap="8px"
          alignItems="center"
          width={isTablet ? "auto" : "100%"}
        >
          <SelectField
            name="classId"
            sx={{
              width: isTablet ? "200px" : "100%",
            }}
            error={isError}
            helperText={isError && t("validations.anErrorOccured")}
            size="small"
            options={getClassesOptions()}
          />
          <FabFiltersPopover />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default TableFilters
