import { FC } from "react"

import { Stack, StackProps } from "@mui/material"

const ItemWrapper: FC<StackProps> = ({ children, gap, p, ...props }) => {
  return (
    <Stack
      {...props}
      p={p || "12px 16px"}
      gap={gap || "16px"}
      sx={(theme) => ({
        "&:nth-of-type(even)": {
          background: theme.palette.mockup.primary99,
        },
      })}
    >
      {children}
    </Stack>
  )
}

export default ItemWrapper
