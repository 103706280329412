export const teacherSettings = {
  settings: "Settings",
  myProfile: "My profile",
  teachers: "Teachers",
  coteachers: "Co-teachers",
  logout: "Logout",
  save: "Save",
  general: "General",
  password: "Password",
  removePicture: "Remove Picture",
  updatePicture: "Update Picture",
  accountDetails: "Account details",
  soundEffects: "Sound effects",
  soundIsOn: "Sound is on",
  soundIsOff: "Sound is off",
  additionalInformation: "Additional information",
  month: "Month",
  day: "Day",
  year: "Year",
  email: "Email address",
  firstName: "First name",
  lastName: "Last name",
  school: "School",
  dateOfBirthOptionalForBirthDaySurprise:
    "Date of birth (optional for birthday surprise)",
  currentPassword: "Current password",
  newPassword: "New password",
  forgotYourPassword: "Forgot your password?",
  reset: "Reset",
  updateSucceed: "Your profile has been updated",
  passwordUpdatedSuccessfully: "Password has been updated",
  incorrectCurrentPassword: "Incorrect current password",
  nothingChange: "Nothing change",
  upgradePlan: "Upgrade plan",
  upgradeNow: "Upgrade now",
  bestForTeachersWhoWorkInTeamLearnMoreAboutOurPlan:
    "Best for teachers who work in team. Learn more about our <Link>paid plans here</Link>",
  manageSubscription: "Manage subscription",
  manageStripeProfile: "Manage stripe profile",
  goToYourSubscriptionProfile:
    "Go to your subscription profile to change your payment profile",
}
