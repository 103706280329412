import { FormEvent } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import FileField from "components/form/common/field/fileField"
import TextField from "components/form/common/field/textField"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useCustomPayment } from "hooks/payment"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import mixpanel from "mixpanel-browser"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { size } from "utils/constants/size"

import { shopDefaultAvatars } from "../../AllItemsStoreTeacher.config"
import AvatarItem from "./avatarItem"
import { defaultValues, validationSchema } from "./ItemDialogForm.config"
import { IFormState } from "./ItemDialogForm.types"

interface IProps<T> {
  open: boolean
  onClose: () => void
  onSubmit: (data: IFormState) => Promise<T>
  isLoading: boolean
  title: string
  initialState?: IFormState
  handleDeleteClick?: () => void
}

const ItemDialogForm = <T,>({
  open,
  onClose,
  onSubmit,
  title,
  initialState,
  handleDeleteClick,
  isLoading,
}: IProps<T>) => {
  const { t } = useTranslation()

  const { payIt, isLoadingPayments } = useCustomPayment()

  const methods = useForm<IFormState>({
    defaultValues: initialState || defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const isMobile = useIsBreakpointUp("tablet")
  const isBreakErrorView = useMediaQuery(size.MAX_WIDTH_497)

  const imageUrlWatcher = useWatch({
    control: methods.control,
    name: "imageUrl",
  })

  const uploadedImageUrlWatcher = useWatch({
    control: methods.control,
    name: "uploadedImageUrl",
  })

  return (
    <Dialog
      titleText={title}
      isLoading={isLoading}
      open={open}
      onClose={() => {
        methods.reset(initialState || defaultValues)
        onClose()
      }}
      actionAcceptText={t("save")}
      onDeleteClick={handleDeleteClick}
      actionAcceptButtonProps={{
        type: "submit",
        form: title,
        disabled: isLoading,
      }}
    >
      <Stack
        component="form"
        id={title}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          methods.handleSubmit(async (data) => {
            try {
              if (data.imageUrl === "") {
                await onSubmit({
                  imageUrl: null,
                  title: data.title,
                  amount: data.amount,
                  uploadedImageUrl: data.uploadedImageUrl,
                  quantity: data.quantity,
                })
                methods.reset(initialState || defaultValues)
                onClose()
                return
              }
              await onSubmit(data)
              methods.reset(initialState || defaultValues)
              onClose()
            } catch {}
          })()
        }}
      >
        <FormProvider {...methods}>
          <Stack>
            <Typography variant="body3" color="mockup.neutral40" mb="12px">
              {t("store.uploadPicture")}
            </Typography>
            <FileField
              name="uploadedImageUrl"
              fileType="image/png, image/jpeg"
              isChecked={
                uploadedImageUrlWatcher === imageUrlWatcher &&
                uploadedImageUrlWatcher !== ""
              }
              onImageClick={() => {
                methods.setValue("imageUrl", uploadedImageUrlWatcher)
              }}
              onImageAdd={(imageUrl) => {
                methods.setValue("imageUrl", imageUrl)
              }}
              onImageRemove={(imageUrl) => {
                if (imageUrl === imageUrlWatcher)
                  methods.setValue("imageUrl", "")
              }}
            />
            <Typography
              variant="body3"
              color="mockup.neutral40"
              mt="24px"
              mb="16px"
            >
              {t("store.orSelectAvatar")}
            </Typography>
            <Stack
              direction="row"
              columnGap="8px"
              rowGap="16px"
              flexWrap="wrap"
              mb="32px"
            >
              {shopDefaultAvatars.map((avatar) => (
                <AvatarItem
                  key={avatar}
                  avatarUrl={avatar}
                  onClick={() => {
                    if (methods.getValues().imageUrl) {
                      methods.setValue("imageUrl", "")
                    } else {
                      methods.setValue("imageUrl", avatar)
                    }
                  }}
                  isChecked={avatar === imageUrlWatcher}
                />
              ))}
            </Stack>
            <Grid container columnSpacing={1}>
              <Grid item desktop={12} tablet={12} mobile={12}>
                <TextField
                  name="title"
                  label={t("store.itemTitle")}
                  sx={{ mb: "32px" }}
                />
              </Grid>
              <Grid item tablet={12} desktop={6} mobile={12}>
                <TextField
                  name="amount"
                  inputMode="decimal"
                  label={t("store.value")}
                  sx={{ mb: "32px" }}
                />
              </Grid>
              <Tooltip
                followCursor={userIsFreeDemoSchoolPlan()}
                title={
                  userIsFreeDemoSchoolPlan()
                    ? t("store.upgradeToProOrSchoolToUnlockInventory")
                    : ""
                }
              >
                <Grid item desktop={6} tablet={12} mobile={12} height="60px">
                  {userIsFreeDemoSchoolPlan() ? (
                    <TextField
                      sx={(theme) => ({
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          backgroundColor: theme.palette.mockup.neutral90,
                        },
                      })}
                      name="fake_name"
                      label={t("store.quantity")}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={userIsFreeDemoSchoolPlan()}
                    />
                  ) : (
                    <TextField
                      name="quantity"
                      inputMode="decimal"
                      label={t("store.quantity")}
                      {...(!!Object.keys(methods.formState.errors).length && {
                        sx: {
                          mb: !isMobile && !isBreakErrorView ? "0" : "44px",
                        },
                      })}
                    />
                  )}
                </Grid>
              </Tooltip>
              <Grid item desktop={6} sx={{ marginLeft: "auto" }}>
                <Box
                  display="flex"
                  sx={{
                    transform: "translateY(-100%)",
                    alignItems: "center",
                  }}
                >
                  {isLoadingPayments && (
                    <CircularProgress
                      size="10px"
                      color="inherit"
                      sx={{ mr: "5px" }}
                    />
                  )}
                  {userIsFreeDemoSchoolPlan() && (
                    <Typography
                      color="mockup.neutral40"
                      onClick={() => {
                        payIt(null)
                        mixpanel.track("quantity_used", {
                          event_name: "upgrade_button",
                        })
                      }}
                      sx={(theme) => ({
                        cursor: "pointer",
                        opacity: isLoadingPayments ? ".5" : "1",
                        pointerEvents: isLoadingPayments ? "none" : "all",
                        ":hover": {
                          color: theme.palette.mockup.neutral70,
                        },
                      })}
                    >
                      Upgrade account
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </FormProvider>
      </Stack>
    </Dialog>
  )
}

export default ItemDialogForm
