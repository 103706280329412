import React from "react"

import { Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ErrorStyle } from "../../SignAsTeacher.styles"
import { TextFieldStyle } from "../PersonalForm.styles"

interface IProps {
  name: string
  label: string
  handleFunction: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  invalid: boolean
}

const FormText = ({ name, handleFunction, value, invalid, label }: IProps) => {
  const methods = useFormContext()
  const { t } = useTranslation()

  const errorType =
    name === "first_name"
      ? methods.formState.errors?.first_name?.type
      : methods.formState.errors?.last_name?.type

  return (
    <Grid item tablet={6} mobile={12}>
      <TextFieldStyle
        label={t(`noAuthFlow.${label}`)}
        {...methods.register(name, {
          onChange: (event) => handleFunction(event),
        })}
        name={name}
        value={value}
        invalid={invalid}
      />
      {errorType === "required" && (
        <ErrorStyle>{t("validations.fieldIsRequired")}</ErrorStyle>
      )}
      {errorType === "min" && (
        <ErrorStyle>
          {t("validations.enterMinChars_other", { count: 2 })}
        </ErrorStyle>
      )}
    </Grid>
  )
}

export default FormText
