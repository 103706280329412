import axiosInstance from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import { IPostImagesArgs, IPostImagesInput } from "./images.types"

const createEndpoint = (base: string, params?: string) => {
  if (params) {
    return `${base.replace("ID", params)}`
  }
  return base
}

export const createMutationPostImage =
  (endpoint: string) => (args?: IPostImagesArgs) =>
    useMutation<AxiosResponse<{ url: string }>, AxiosError, IPostImagesInput>(
      async ({ upload }) => {
        const formData = new FormData()
        formData.append("upload", upload)

        const finalBaseEndpoint = createEndpoint(endpoint, args?.id)
        return await axiosInstance.post(finalBaseEndpoint, formData)
      },
      args?.options
    )
