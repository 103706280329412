import { Dispatch } from "react"

import { MenuItem, Stack, TextField, Typography, Box } from "@mui/material"
import { useQueryStudentsClasses } from "api/reactQuery/queries/students"
import { useTranslation } from "react-i18next"

import Table from "./table"

const ManageStudentJobs = ({
  studentId,
  checkedJobs,
  setCheckedJobs,
  selectedClass,
  setSelectedClass,
}: {
  studentId: string
  checkedJobs: number[]
  setCheckedJobs: Dispatch<React.SetStateAction<number[]>>
  selectedClass: string
  setSelectedClass: Dispatch<React.SetStateAction<string>>
}) => {
  const { t } = useTranslation()

  const { data: getStudentClasses } = useQueryStudentsClasses({
    studentId: Number(studentId),
    options: {
      enabled: !!studentId,
    },
  })

  return (
    <Stack>
      <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
        {t("studentProfile.introStudentJobs")}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        height="auto"
        sx={{ mt: 3, mb: 6 }}
      >
        <TextField
          value={selectedClass}
          select
          onChange={(e) => setSelectedClass(e.target.value)}
          label={t("studentProfile.studentClass")}
          sx={{ pr: 2 }}
        >
          {getStudentClasses?.data.map((classInfo) => {
            if (classInfo.is_in_class) {
              return (
                <MenuItem key={classInfo.id} value={classInfo.id}>
                  {classInfo.name}
                </MenuItem>
              )
            }
          })}
        </TextField>
      </Box>
      <Table
        studentId={studentId}
        selectedClass={selectedClass}
        checkedJobs={checkedJobs}
        setCheckedJobs={setCheckedJobs}
      />
    </Stack>
  )
}

export default ManageStudentJobs
