import { shopDefaultAvatars } from "components/module/allItemsStoreTeacher/AllItemsStoreTeacher.config"

import { ISuggestedObject } from "./DialogAddSuggestedItems.types"

export const suggestedItems: ISuggestedObject = {
  es: [
    {
      id: 1,
      name: "store.pencil",
      price: 1,
      icon_url: shopDefaultAvatars[4],
    },
    {
      id: 2,
      name: "store.smallTreasureBoxItem",
      price: 1,
      icon_url: shopDefaultAvatars[6],
    },
    {
      id: 3,
      name: "store.brainBreakChoice",
      price: 2,
      icon_url: shopDefaultAvatars[7],
    },
    {
      id: 4,
      name: "store.tellJokeToClass",
      price: 3,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 5,
      name: "store.switchJobsWithaClassmateForTheDay",
      price: 3,
      icon_url: shopDefaultAvatars[9],
    },
    {
      id: 6,
      name: "store.stinkyFeetNoShoesInClass",
      price: 4,
      icon_url: shopDefaultAvatars[15],
    },
    {
      id: 7,
      name: "store.sitInTheTeachersChair",
      price: 4,
      icon_url: shopDefaultAvatars[5],
    },
    {
      id: 8,
      name: "store.magicTrick",
      price: 4,
      icon_url: shopDefaultAvatars[10],
    },
    {
      id: 9,
      name: "store.swapSeatsForTheDay",
      price: 4,
      icon_url: shopDefaultAvatars[17],
    },
    {
      id: 10,
      name: "store.passToGoToTheFrontOfTheLunchLine",
      price: 4,
      icon_url: shopDefaultAvatars[23],
    },
    {
      id: 11,
      name: "store.fiveMinutesExtraRecess",
      price: 5,
      icon_url: shopDefaultAvatars[16],
    },
    {
      id: 12,
      name: "store.eatInClass",
      price: 5,
      icon_url: shopDefaultAvatars[21],
    },
    {
      id: 13,
      name: "store.positivePhoneCallHome",
      price: 5,
      icon_url: shopDefaultAvatars[12],
    },
    {
      id: 14,
      name: "store.homeworkPass",
      price: 10,
      icon_url: shopDefaultAvatars[1],
    },
    {
      id: 15,
      name: "store.lunchWithTeacher",
      price: 10,
      icon_url: shopDefaultAvatars[19],
    },
    {
      id: 16,
      name: "store.pieTeacherInTheFace",
      price: 10,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 17,
      name: "store.extraCreditPoints",
      price: 10,
      icon_url: shopDefaultAvatars[0],
    },
    {
      id: 18,
      name: "store.specialLeadershipRole",
      price: 10,
      icon_url: shopDefaultAvatars[11],
    },
  ],
  ms: [
    {
      id: 1,
      name: "store.pencil",
      price: 20,
      icon_url: shopDefaultAvatars[4],
    },
    {
      id: 2,
      name: "store.smallStoreItem",
      price: 20,
      icon_url: shopDefaultAvatars[6],
    },
    {
      id: 3,
      name: "store.brainBreakChoice",
      price: 40,
      icon_url: shopDefaultAvatars[7],
    },
    {
      id: 4,
      name: "store.tellJokeToClass",
      price: 60,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 5,
      name: "store.switchJobsWithaClassmateForTheDay",
      price: 60,
      icon_url: shopDefaultAvatars[9],
    },
    {
      id: 6,
      name: "store.stinkyFeetNoShoesInClass",
      price: 80,
      icon_url: shopDefaultAvatars[15],
    },
    {
      id: 7,
      name: "store.sitInTheTeachersChair",
      price: 80,
      icon_url: shopDefaultAvatars[5],
    },
    {
      id: 8,
      name: "store.magicTrick",
      price: 80,
      icon_url: shopDefaultAvatars[10],
    },
    {
      id: 9,
      name: "store.swapSeatsForTheDay",
      price: 80,
      icon_url: shopDefaultAvatars[17],
    },
    {
      id: 10,
      name: "store.passToGoToTheFrontOfTheLunchLine",
      price: 80,
      icon_url: shopDefaultAvatars[23],
    },
    {
      id: 11,
      name: "store.fiveMinutesExtraRecess",
      price: 100,
      icon_url: shopDefaultAvatars[16],
    },
    {
      id: 12,
      name: "store.eatInClass",
      price: 100,
      icon_url: shopDefaultAvatars[21],
    },
    {
      id: 13,
      name: "store.positivePhoneCallHome",
      price: 100,
      icon_url: shopDefaultAvatars[12],
    },
    {
      id: 14,
      name: "store.homeworkPass",
      price: 200,
      icon_url: shopDefaultAvatars[1],
    },
    {
      id: 15,
      name: "store.lunchWithTeacher",
      price: 200,
      icon_url: shopDefaultAvatars[19],
    },
    {
      id: 16,
      name: "store.pieTeacherInTheFace",
      price: 200,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 17,
      name: "store.extraCreditPoints",
      price: 200,
      icon_url: shopDefaultAvatars[0],
    },
    {
      id: 18,
      name: "store.specialLeadershipRole",
      price: 200,
      icon_url: shopDefaultAvatars[11],
    },
    {
      id: 19,
      name: "store.buyYourDesk",
      price: 300,
      icon_url: shopDefaultAvatars[1],
    },
  ],
  ps: [
    {
      id: 1,
      name: "store.pencil",
      price: 20,
      icon_url: shopDefaultAvatars[4],
    },
    {
      id: 2,
      name: "store.smallStoreItem",
      price: 50,
      icon_url: shopDefaultAvatars[6],
    },
    {
      id: 3,
      name: "store.brainBreakChoice",
      price: 100,
      icon_url: shopDefaultAvatars[7],
    },
    {
      id: 4,
      name: "store.tellJokeToClass",
      price: 100,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 5,
      name: "store.switchJobsWithaClassmateForTheDay",
      price: 200,
      icon_url: shopDefaultAvatars[9],
    },
    {
      id: 6,
      name: "store.sitInTheTeachersChair",
      price: 280,
      icon_url: shopDefaultAvatars[15],
    },
    {
      id: 7,
      name: "store.magicTrick",
      price: 280,
      icon_url: shopDefaultAvatars[10],
    },
    {
      id: 8,
      name: "store.swapSeatsForTheDay",
      price: 280,
      icon_url: shopDefaultAvatars[17],
    },
    {
      id: 9,
      name: "store.positivePhoneCallHome",
      price: 280,
      icon_url: shopDefaultAvatars[23],
    },
    {
      id: 10,
      name: "store.eatInClass",
      price: 300,
      icon_url: shopDefaultAvatars[16],
    },
    {
      id: 11,
      name: "store.fiveMinutesExtraRecess",
      price: 300,
      icon_url: shopDefaultAvatars[21],
    },
    {
      id: 12,
      name: "store.lunchWithTeacher",
      price: 300,
      icon_url: shopDefaultAvatars[12],
    },
    {
      id: 13,
      name: "store.homeworkPass",
      price: 400,
      icon_url: shopDefaultAvatars[1],
    },
    {
      id: 14,
      name: "store.extraCreditPoints",
      price: 400,
      icon_url: shopDefaultAvatars[19],
    },
    {
      id: 15,
      name: "store.specialLeadershipRole",
      price: 400,
      icon_url: shopDefaultAvatars[3],
    },
    {
      id: 16,
      name: "store.pieTeacherInTheFace",
      price: 400,
      icon_url: shopDefaultAvatars[0],
    },
    {
      id: 17,
      name: "store.buyYourDesk",
      price: 3000,
      icon_url: shopDefaultAvatars[11],
    },
  ],
}
