import {
  numberSchema,
  requiredHundredthNumberSchema,
  requiredStringSchema,
} from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  title: "",
  positions: "",
  salary: "",
  responsibilities: "",
  qualifications: "",
}

export const schemaAddJobs = yup.object({
  title: requiredStringSchema.min(3),
  positions: numberSchema.positive().notRequired(),
  salary: requiredHundredthNumberSchema,
  responsibilities: yup.string(),
  qualifications: yup.string(),
})
