export const noAuthFlow = {
  login: "Login",
  signup: "Sign up",
  signIn: "Sign in",
  signUpAs: "Sign up as",
  signInAs: "Sign in as",
  signUpWith: "Sign up with",
  usernameOrEmail: "username or Google account",
  googleOrEmail: "email address or Google account",
  signUpGoogle: "Sign up with Google",
  signInGoogle: "Sign in with Google",
  teacher: "Teacher",
  restorePassword: "Restore password",
  student: "Student",
  back: "Back",
  continue: "Continue",
  reset: "Reset",
  accept: "Accept",
  warning: "Warning",
  google: "Google",
  enterEmailToResetYourPassword: "Enter email to reset your password",
  checkYourEmailAndUseTheResetLinkToCreateANewPasswordPasswordResetEmailsCouldTakeUpToAnHourToAppearInYourInbox:
    "Check your email and use the reset link to create a new password. Password reset emails could take up to an hour to appear in your inbox",
  haveAccount: "You already have an account?",
  dontHaveAccount: "You don't have an account?",
  forgotPassword: "Forgot your password?",
  email: "E-mail address",
  password: "Password",
  bySigningUpYouAgreeToClassBanksTermsOfServiceAndPrivacyPolicy:
    "By signing up, you agree to ClassBank’s <Link1>Terms of Service</Link1> and <Link2>Privacy Policy</Link2>",
  getToKnowYou: "Let's get to know you!",
  firstName: "First name",
  lastName: "Last name",
  dateOfBirth: "Date of birth (optional for birthday surprise)",
  howDidYouHearAbout: "How did you hear about {{application}}?",
  month: "Month",
  day: "Day",
  year: "Year",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  findYourSchool: "Find your school",
  done: "Done",
  submit: "Submit",
  backToHomePage: "Back to home page",
  searchSchool: "Search by school name or address",
  addNewSchool: "Add new school",
  dontSeeYourSchoolAddItHere: "Don’t see your school? Add it here!",
  startTypingtoFindTSchool: "Start typing to find School",
  schoolName: "School name",
  district: "District",
  cityOrTown: "City or town",
  stateOrProvince: "State or province",
  timeZone: "Time zone",
  country: "Country",
  addSchool: "Add school",
  createANewPassword: "Create a new password",
  createNewPassword: "Create new password",
  yourPasswordHasBeenSuccessfullyChanged:
    "Your password has been successfully changed",
  goBackToLoginPage: "Go back to login page",
  userName: "User name",
  enterYourClassCode: "Enter your class code",
  welcomeOnBoard: "Welcome on board",
  emailAndPasswordCreated: "Email and password has been created",
  loginSuccessful: "Login successful",
  somethingWentWrongPleaseCheckConnectionOrContactWithAdmin:
    "Something went wrong, please check connection or contact with admin",
  passwordMustBeAtLeast6Characters: "Password must be at least 6 characters",
  wrongClassCode: "Wrong class code",
  schoolHasBeenCreated: "School has been created",
  yourTokenWasExpiredOrIsInvalidPleaseResetPasswordOnceAgain:
    "Your token was expired or is invalid, please reset password once again",
  linkToResetPassword: "Link to <Link>Reset Password</Link>",
  youHaveChosenDifferentSchoolIfYouSelectYourCurrentSchoolYouWillNotBeAbleToAcceptheInvitationFrom:
    "You have chosen a different school! If you select your current school, you will not be able to accept the invitation from school - {{schoolName}}",
  signInToConnectYourAccounts: "Sign in to connect your accounts",
  fillUpDetails: "Fill up details",
  alreadyHaveAnAccount: "Already have an account?",
  conenctToGoogle: "Connect to Google",
  alreadyHaveAnAccountDescription:
    "If you have an account associated with the same google email, we will ask you to log in to it in order to link your data",
  congratulationsYourAccountIsNowConnectedToGoogle:
    "Congratulations! Your account is now connected to Google",
  connectYourAccounts: "Connect Your accounts",
  skip: "Skip",
  yourAccountHasBeenCreated: "Your account has been created",
  accountHasBeenConnectedSuccessfully:
    "Account has been connected successfully",
  zipCode: "Zip code",
}
