import React, { FC } from "react"

import {
  Autocomplete,
  Box,
  useTheme,
  debounce,
  Typography,
  Stack,
  Button,
} from "@mui/material"
import { ReactComponent as Arrow } from "assets/common/svg/arrowBack.svg"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { setActivePageSignUp } from "store/auth/auth.slice"
import { DropdownStyles } from "styles/common/autocomplete"
import { IAutocompleteProps } from "ts/interfaces/Input"

import { ErrorStyle, TextFieldStyle } from "../FindSchoolForm.style"
import { IProps } from "./AutocompleteInput.types"

const AutocompleteInput: FC<IProps> = (props) => {
  const {
    userPost,
    data,
    state,
    errorInfo,
    setUserPost,
    setSchool,
    isLoading,
    setState,
    onClickNextSkip,
    onClickPrevSkip,
    skip,
    setSkip,
  } = props
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 4, mb: 5 }} position="relative">
      <Autocomplete
        value={state}
        onChange={(e) => {
          const event = e.currentTarget as HTMLInputElement
          setState(JSON.parse(event.getAttribute("data-option") as string))
          setSchool(Number(event.getAttribute("data-val")))
        }}
        loading={isLoading}
        loadingText="Loading..."
        renderInput={(params) => (
          <TextFieldStyle
            {...params}
            value={"hello"}
            label={t("noAuthFlow.searchSchool")}
            invalid={!!errorInfo}
          />
        )}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        filterOptions={(options) => options}
        noOptionsText={
          userPost?.length === 0 && data.length === 0 ? (
            <Box sx={{ textAlign: "center" }}>
              {t("noAuthFlow.startTypingtoFindTSchool")}...
            </Box>
          ) : userPost && userPost.length > 0 && data.length === 0 ? (
            <Box
              onClick={() => dispatch(setActivePageSignUp(4))}
              sx={{
                textAlign: "center",
                border: "3px solid transparent",
                textTransform: "uppercase",
                color: `${theme.palette.primary.main}`,
                cursor: "pointer",
                boxShadow: "0px 0px 0px rgba(210, 218, 249, 0.25)",
              }}
            >
              {t("noAuthFlow.dontSeeYourSchoolAddItHere")}
            </Box>
          ) : (
            ""
          )
        }
        options={data}
        getOptionLabel={(option: IAutocompleteProps) => `${option.name}`}
        onInputChange={debounce((_, val, reason) => {
          if (val) {
            setSkip(0)
          }
          setUserPost(val)
          if (reason === "reset") {
            setSkip(0)
          }
        }, 500)}
        renderOption={(props, originalOption) => {
          const option = originalOption as IAutocompleteProps
          const idArray = props?.id?.split("-")
          const index = (idArray ? idArray[3] : undefined) as undefined | string
          return (
            <React.Fragment key={option.id}>
              <li
                data-option={JSON.stringify(option)}
                data-val={option.id}
                {...props}
                style={{
                  position: "relative",
                  background: theme.palette.common.white,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Stack>
                    <Typography>{option.name}</Typography>
                    <Typography variant="body2" color="mockup.neutral60">
                      {option.district}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="mockup.neutral60">
                    {option.zip_code}
                  </Typography>
                </Box>
              </li>
              {index === `${data.length - 1}` && (
                <Box pt={1}>
                  {!skip && data?.length !== 20 ? null : (
                    <Box display="flex" justifyContent="space-around">
                      <Button
                        style={{ height: "10px" }}
                        onClick={onClickPrevSkip}
                        disabled={skip === 0}
                      >
                        <Arrow />
                      </Button>
                      <Button
                        disabled={data.length !== 20}
                        onClick={onClickNextSkip}
                        style={{ transform: "rotate(180deg)", height: "10px" }}
                      >
                        <Arrow />
                      </Button>
                    </Box>
                  )}
                  <DropdownStyles
                    onClick={() => dispatch(setActivePageSignUp(4))}
                  >
                    {t("noAuthFlow.dontSeeYourSchoolAddItHere")}
                  </DropdownStyles>
                </Box>
              )}
            </React.Fragment>
          )
        }}
        ListboxProps={{
          style: {
            maxHeight: "200px",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        }}
      />
      {errorInfo && <ErrorStyle>{errorInfo}</ErrorStyle>}
    </Box>
  )
}

export default AutocompleteInput
