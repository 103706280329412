import React from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Grid, Stack } from "@mui/material"
import { useMutationUpdateHrJobs } from "api/reactQuery/mutations/jobs"
import { IGetResponseJobsDetails } from "api/reactQuery/queries/jobs.types"
import TextField from "components/form/common/field/textField"
import { useCustomSnackbar } from "hooks/snackbar"
import RichEditor from "pages/jobs/RichEditor"
import { FormProvider, useForm, FieldError } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"
import { regexNumberAndCommon } from "utils/constants"

import { defaultValues, schemaAddJobs } from "./General.config"

enum TypesError {
  MAX = "max",
  MIN = "min",
  ISHUNDREDTH = "isHundredth",
}

interface IGeneral {
  id: string
  richTextEditor: {
    richTextEditorQualify: string
    richTextEditorResponse: string
  }
  setRichTextEditor: {
    setRichTextEditorQualify: React.Dispatch<React.SetStateAction<string>>
    setRichTextEditorResponse: React.Dispatch<React.SetStateAction<string>>
  }
  data?: IGetResponseJobsDetails
}

const General = ({ id, setRichTextEditor, richTextEditor, data }: IGeneral) => {
  const { t } = useTranslation()
  const params = useParams()

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      title: data?.title,
      positions: data?.positions,
      salary: data?.salary,
    },
    resolver: yupResolver(schemaAddJobs),
  })

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const { mutate: mutateEditJob } = useMutationUpdateHrJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("jobsHr")
        queryClient.invalidateQueries("jobsDetailsHr")
        showSnackbar({
          title: t("jobs.editCreateJob"),
          variant: "success",
        })
        // handleCloseDialogEdit()
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  //@ts-ignore
  const onSubmit = (formData) => {
    mutateEditJob({
      data: [
        {
          ...formData,
          id: data?.id as number,
          title: formData.title === data?.title ? undefined : formData.title,
          qualifications: richTextEditor.richTextEditorQualify,
          responsibilities: richTextEditor.richTextEditorResponse,
        },
      ],
      classId: Number(params.classId),
    })
  }

  const errorMessage = (message?: FieldError) => {
    if (message?.type === TypesError.MIN) {
      return `${t("validations.minNumberIs", { count: 1 })}`
    }
    if (message?.type === TypesError.MAX) {
      return `${t("validations.maxNumberIs", { count: 100000 })}`
    }
    if (message?.type === TypesError.ISHUNDREDTH) {
      return `${methods.formState.errors.salary?.message}`
    }
  }

  return (
    <Stack>
      <FormProvider {...methods}>
        <form id={id} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columnSpacing={1} rowSpacing={3} mt={1}>
            <Grid item desktop={6} tablet={12}>
              <TextField
                name="title"
                label={t("jobs.jobTitle")}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item desktop={3} tablet={12}>
              <TextField
                name="positions"
                label={t("jobs.positions")}
                type="number"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
            </Grid>
            <Grid item desktop={3} tablet={12}>
              <TextField
                name="salary"
                label={t("jobs.salary")}
                type="text"
                onKeyPress={(event) => {
                  if (!regexNumberAndCommon.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                onPaste={(e) => e.preventDefault()}
                helperText={errorMessage(methods.formState.errors.salary)}
              />
            </Grid>
            <Grid item desktop={12}>
              <RichEditor
                addjob
                state={richTextEditor.richTextEditorResponse}
                setState={setRichTextEditor.setRichTextEditorResponse}
                placeholder={t("jobs.responsibilities")}
              />
            </Grid>
            <Grid item desktop={12}>
              <RichEditor
                addjob
                state={richTextEditor.richTextEditorQualify}
                setState={setRichTextEditor.setRichTextEditorQualify}
                placeholder={t("jobs.qualifications")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Stack>
  )
}
export default General
