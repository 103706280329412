import { FC } from "react"

import { Typography, TypographyProps } from "@mui/material"

const HeadText: FC<TypographyProps> = ({
  children,
  variant = "body3",
  color = "primary.main",
  textTransform = "uppercase",
  ...props
}) => {
  return (
    <Typography
      variant={variant}
      color={color}
      textTransform={textTransform}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default HeadText
