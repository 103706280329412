import { RoutePath } from "routes/Route.types"

export const setupRoutes = [
  {
    path: RoutePath.CLASS_SETUP,
    tabName: "bonuses",
    labelKey: "setup.bonuses",
    activeOnPaths: [RoutePath.CLASS_SETUP],
  },
  {
    path: RoutePath.CLASS_SETUP_FINES,
    tabName: "fines",
    labelKey: "setup.fines",
    activeOnPaths: [RoutePath.CLASS_SETUP_FINES],
  },
  {
    path: RoutePath.CLASS_SETUP_BILLS,
    tabName: "bills",
    labelKey: "setup.bills",
    activeOnPaths: [RoutePath.CLASS_SETUP_BILLS],
  },
]
