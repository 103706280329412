import { ReactComponent as IconModule } from "@common_assets/svg/bill.svg"
import { ReactComponent as PiggyBank } from "@common_assets/svg/piggy-bank-outlined.svg"
import { ReactComponent as QuestionMark } from "@common_assets/svg/question.svg"
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Coin from "components/common/icon/coin"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { IStudentBalance } from "ts/interfaces/Student"

interface IModules {
  data?: IStudentBalance
}

const Modules = ({ data }: IModules) => {
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("tablet")
  const isDesktop = useMediaQuery("(min-width: 1024px) and (max-width: 1280px)")
  const isDesktopXl = useMediaQuery("(min-width:1281px)")

  return (
    <Box
      display="flex"
      flexDirection={isTablet ? "row" : "column"}
      columnGap={isDesktop ? 0.5 : 2}
      rowGap={isTablet ? 0 : 1}
      mt={3}
      width={isDesktopXl ? "65%" : isTablet ? "55%" : "100%"}
    >
      <Stack
        flex="1 1 0"
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "8px",
          color: theme.palette.common.white,
          p: isDesktop ? 1.2 : 2,
          rowGap: isDesktop ? 0.5 : 1,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="subtitle1"
            fontSize={isDesktop ? "14px" : "18px"}
          >
            Checking
          </Typography>
          <Tooltip
            placement="top"
            title="A checking account holds money for day to day spending, like a wallet."
          >
            <QuestionMark />
          </Tooltip>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Coin
            amount={data?.balance ?? 0}
            iconSize={isDesktop ? "16px" : "32px"}
            valueTextProps={{
              fontSize: isDesktop ? "16px" : "28px",
              color: theme.palette.common.white,
            }}
          />
          <IconModule />
        </Box>
      </Stack>
      <Stack
        flex="1 1 0"
        sx={{
          backgroundColor: theme.palette.mockup.success50,
          borderRadius: "8px",
          color: theme.palette.common.white,
          p: isDesktop ? 1.2 : 2,
          rowGap: isDesktop ? 0.5 : 1,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="subtitle1"
            fontSize={isDesktop ? "14px" : "18px"}
          >
            Savings
          </Typography>
          <Tooltip
            placement="top"
            title=" A savings account holds money set aside for the future, like a piggy bank."
          >
            <QuestionMark />
          </Tooltip>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Coin
            amount={data?.savings ?? 0}
            iconSize={isDesktop ? "16px" : "32px"}
            valueTextProps={{
              fontSize: isDesktop ? "16px" : "28px",
              color: theme.palette.common.white,
            }}
          />
          <PiggyBank />
        </Box>
      </Stack>
    </Box>
  )
}

export default Modules
