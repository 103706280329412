import { Grid } from "@mui/material"
import TextField from "components/form/common/field/textField"

interface IProps {
  name: string
  label: string
  spacing: number
  disabled?: boolean
}

const FormText = ({ name, label, spacing, disabled }: IProps) => {
  return (
    <Grid item mobile={spacing}>
      <TextField disabled={disabled} type="text" name={name} label={label} />
    </Grid>
  )
}

export default FormText
