import { Grid, Stack } from "@mui/material"
import WelcomeBack from "components/common/welcomeBack"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { Outlet } from "react-router"

import ClassOverview from "./classOverview"
import QuickAction from "./quickAction"
import StoreUpdates from "./storeUpdates"
import StudentSearch from "./studentSearch"

const DashboardTeacher = () => {
  const isTablet = useIsBreakpointUp("tablet")
  const isDesktop = useIsBreakpointUp("desktop")

  return (
    <Grid container spacing={3}>
      <Grid item mobile={12} desktop={7}>
        <Stack rowGap={3}>
          <WelcomeBack />
          {isDesktop && <ClassOverview />}
        </Stack>
      </Grid>
      <Grid item mobile={12} desktop={5}>
        <Stack rowGap={3}>
          <Stack gap={3} direction={isTablet && !isDesktop ? "row" : "column"}>
            <StudentSearch />
            <QuickAction />
          </Stack>
          <StoreUpdates />
          {!isDesktop && <ClassOverview />}
        </Stack>
      </Grid>
      <Outlet />
    </Grid>
  )
}

export default DashboardTeacher
