import { FC } from "react"

import { Box, BoxProps, TableCell, TableCellProps } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"

interface IProps extends BoxProps {
  tableCellProps?: TableCellProps
}

const Cell: FC<IProps> = ({ children, tableCellProps, ...props }) => {
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <TableCell {...tableCellProps}>
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        px={isTablet ? "16px" : "8px"}
        {...props}
        className="mui-body-cell-border"
      >
        {children}
      </Box>
    </TableCell>
  )
}

export default Cell
