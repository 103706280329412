import React from "react"

import { Typography } from "@mui/material"

export const fillTextOnInut = (
  text: string,
  regexAttributes: RegExp,
  noticeText: string
) => {
  if (!text.trim().length) {
    return (
      <Typography
        sx={{ cursor: "pointer", fontWeight: "500" }}
        color="mockup.neutral0"
      >
        {noticeText}
      </Typography>
    )
  } else {
    return text.replace(regexAttributes, "")
  }
}
