import { FC } from "react"

import Dialog from "components/common/dialog/dialog"

interface IProps {
  titleText: string
  isOpen: boolean
  onClose: () => void
  onActionButtonClick: () => void
  actionAcceptText: string
}

const DialogRemoveStudent: FC<IProps> = ({
  titleText,
  onActionButtonClick,
  onClose,
  isOpen,
  children,
  actionAcceptText,
}) => {
  return (
    <Dialog
      titleText={titleText}
      open={isOpen}
      onClose={onClose}
      onActionButtonClick={onActionButtonClick}
      actionAcceptText={actionAcceptText}
    >
      {children}
    </Dialog>
  )
}

export default DialogRemoveStudent
