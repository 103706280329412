export const showDecimal = (salary: string | number) => {
  return typeof salary === "string" && salary.includes(".")
    ? Number(salary).toFixed(Math.round(2))
    : typeof salary === "string" && salary.includes(",")
    ? Number(salary.replace(",", ".")).toFixed(Math.round(2))
    : Number(salary)
}

export const showDecimalNegative = (salary: string | number) => {
  const item = salary.toString()
  return item.includes(".")
    ? Number(item).toFixed(2)
    : item.includes(",")
    ? Number(item.replace(",", ".")).toFixed(2)
    : Number(salary)
}

export const removeCommasNumber = (item: string | number) => {
  return `${item}`.replace(/,/g, "")
}
