import React, { FC, useState } from "react"

import { ReactComponent as EyeIcon } from "@common_assets/svg/pass_eye.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Grid,
  InputAdornment,
  Typography,
  useTheme,
  Stack,
} from "@mui/material"
import { useMutationChangePassword } from "api/reactQuery/mutations/teacher"
import TextField from "components/form/common/field/textField"
import { useCustomSnackbar } from "hooks/snackbar"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

import { defaultValues, validateSchema } from "./Password.config"
import { IFormState } from "./Password.types"

interface IProps {
  id?: string
  handleClose?: () => void
}

const Password: FC<IProps> = ({ id, handleClose }) => {
  const [showNewPass, setShowNewPass] = useState(false)
  const [showCurrentPass, setShowCurrentPass] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()

  const { mutate } = useMutationChangePassword({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("teacherSettings.passwordUpdatedSuccessfully"),
        })
        handleClose && handleClose()
      },
      onError: (err) => {
        if (err.response?.status === 400) {
          showSnackbar({
            title: t("teacherSettings.incorrectCurrentPassword"),
            variant: "error",
          })
        }
      },
    },
  })

  const methods = useForm<IFormState>({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const onSubmit = (data: IFormState) => mutate(data)

  return (
    <Stack>
      <Typography
        sx={{ mt: 4, mb: 3 }}
        variant="subtitle1"
        color={theme.palette.mockup.neutral10}
      >
        {t("teacherSettings.accountDetails")}
      </Typography>
      <form id={id} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item desktop={6} mobile={12}>
              <TextField
                name="current_password"
                type={showCurrentPass ? "text" : "password"}
                fullWidth
                label={t("teacherSettings.currentPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={[
                        {
                          cursor: "pointer",
                          color: "primary.main",
                        },
                        (theme) => ({
                          "&:hover": {
                            color: theme.palette.mockup.primary80,
                          },
                        }),
                      ]}
                      position={"start"}
                      onClick={() => setShowCurrentPass(!showCurrentPass)}
                    >
                      <EyeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ my: 4 }}>
            <Grid item desktop={6} mobile={12}>
              <TextField
                name="password"
                FormHelperTextProps={{
                  sx: {
                    position: "relative",
                    transform: "translateY(0px)",
                  },
                }}
                type={showNewPass ? "text" : "password"}
                fullWidth
                label={t("teacherSettings.newPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={[
                        {
                          cursor: "pointer",
                          color: "primary.main",
                        },
                        (theme) => ({
                          "&:hover": {
                            color: theme.palette.mockup.primary80,
                          },
                        }),
                      ]}
                      position={"start"}
                      onClick={() => setShowNewPass(!showNewPass)}
                    >
                      <EyeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
      <Typography
        variant="body2"
        sx={{ fontWeight: "500", mt: 2 }}
        color={theme.palette.mockup.neutral0}
      >
        {t("teacherSettings.forgotYourPassword")}
        <Link
          onClick={handleClose}
          to={RoutePath.RESET_PASSWORD}
          style={{
            color: theme.palette.mockup.primary20,
            paddingLeft: "5px",
          }}
        >
          {t("teacherSettings.reset")}
        </Link>
      </Typography>
    </Stack>
  )
}

export default Password
