import React, { FC, HTMLAttributes, useEffect, useRef, useState } from "react"

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useMutationCoteachersAdd } from "api/reactQuery/mutations/coteachers"
import { useMutationTeacherManagementInvite } from "api/reactQuery/mutations/teacherManagement"
import { useQueryCoTeachersPossible } from "api/reactQuery/queries/coteachers"
import { IResponseCoteachersPossible } from "api/reactQuery/queries/coteachers.types"
import Dialog from "components/common/dialog/dialog"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { get } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"
import { getErrorMessageFromTab } from "utils/api"
import { isFreeDemoPlan, isProPlan } from "utils/roleCheck"

import InviteCoteachers from "../inviteCoteachers"
import { InviteButton } from "./AutoCompleteAddTeacher.styles"

interface IProps {
  id: string
  statePossibleTeacherInfo: {
    possibleTeacherInfo: IResponseCoteachersPossible[]
    setPossibleTeacherInfo: React.Dispatch<
      React.SetStateAction<IResponseCoteachersPossible[]>
    >
  }
  handleCloseAddTeacher: () => void
}

const AutoCompleteAddTeacher: FC<IProps> = ({
  id,
  handleCloseAddTeacher,
  statePossibleTeacherInfo,
}) => {
  const { possibleTeacherInfo, setPossibleTeacherInfo } =
    statePossibleTeacherInfo
  const queryClient = useQueryClient()
  const params = useParams()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const { classId } = params
  const [error, setError] = useState(false)
  const userInfo = useAppSelector(selectUser)
  const { isOpen, handleOpen, handleClose } = useDialog()
  const idsRefPro = useRef<number[] | null>(null)
  const idsRefSchool = useRef<number[] | null>(null)
  const emailRefFree = useRef<string[] | null>(null)
  const dataRef = useRef<IResponseCoteachersPossible[] | null>(null)

  const teacherOnFreeDemoArray = possibleTeacherInfo.filter(
    (teacher) =>
      teacher.school_account.plan === UserSchoolPlan.DEMO ||
      teacher.school_account.plan === UserSchoolPlan.FREE
  )

  const teacherOnSchoolArray = possibleTeacherInfo.filter(
    (teacher) => teacher.school_account.plan === UserSchoolPlan.SCHOOL
  )

  const teacherOnProArray = possibleTeacherInfo.filter(
    (teacher) => teacher.school_account.plan === UserSchoolPlan.PRO
  )

  const { data, isLoading } = useQueryCoTeachersPossible({
    classId: Number(classId),
    options: {
      cacheTime: 0,
    },
  })

  useEffect(() => {
    if (data?.data !== undefined) {
      dataRef.current = [...data?.data]
    }
  }, [data])

  const { mutate } = useMutationCoteachersAdd({
    classId: Number(classId),
    options: {
      onSuccess: (_, variables) => {
        const { teachers_ids } = variables
        idsRefPro.current = null
        idsRefSchool.current = null
        queryClient.invalidateQueries("coteachers")
        queryClient.invalidateQueries("possibleCoteachers")

        if (!teacherOnFreeDemoArray.length) {
          handleCloseAddTeacher()
          setPossibleTeacherInfo([])
        }

        if (teachers_ids.length >= 2) {
          showSnackbar({
            title: t("coteachers.usersHasBeenSuccessfullyAddedToClass"),
          })
        } else if (!!teacherOnProArray.length) {
          showSnackbar({
            title: t("coteachers.userHasBeenSuccessfullyAddedToClass", {
              firstname: teacherOnProArray.map((el) => el.first_name),
              lastname: teacherOnProArray.map((el) => el.last_name),
            }),
          })
        } else {
          showSnackbar({
            title: t("coteachers.userHasBeenSuccessfullyAddedToClass", {
              firstname: teacherOnSchoolArray.map((el) => el.first_name),
              lastname: teacherOnSchoolArray.map((el) => el.last_name),
            }),
          })
        }
      },

      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: sendInvite } = useMutationTeacherManagementInvite({
    options: {
      onSuccess: () => {
        if (emailRefFree.current !== null && emailRefFree.current?.length > 1) {
          showSnackbar({
            title: t("coteachers.invitationsHaveBeenSent", {
              count: emailRefFree.current?.length,
            }),
          })
        } else {
          showSnackbar({
            title: t("coteachers.invitationHasBeenSent"),
          })
        }
        emailRefFree.current = null
      },

      onError: (error) => {
        const errorMessage: { msg: string }[] = []
        const errorTab = get(error.response?.data, "detail", null)

        errorTab.map((el: { msg: string }, index: number) => {
          errorMessage.push({
            msg: el.msg.replace(
              "teacher",
              `${teacherOnFreeDemoArray[index].first_name} ${teacherOnFreeDemoArray[index].last_name}`
            ),
          })
        })

        errorMessage.forEach(({ msg }) => {
          showSnackbar({
            title: msg,
            variant: "error",
          })
        })
      },
    },
  })

  const {
    isOpen: isOpenManageTeachers,
    handleOpen: handleOpenManageTeachers,
    handleClose: handleCloseManageTeachers,
  } = useDialog()

  const {
    isOpen: isOpenSendInvite,
    handleOpen: handleOpenSendInvite,
    handleClose: handleCloseSendInvite,
  } = useDialog()

  const {
    isOpen: isOpenUpgradeAccount,
    handleOpen: handleOpenUpgradeAccount,
    handleClose: handleCloseUpgradeClose,
  } = useDialog()

  const { payIt, isLoadingPayments } = useCustomPayment()

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (!possibleTeacherInfo.length) {
      setError(true)
      return
    }

    idsRefPro.current = teacherOnProArray.map(({ id }) => id)
    emailRefFree.current = teacherOnFreeDemoArray.map(({ email }) => email)
    idsRefSchool.current = teacherOnSchoolArray.map(({ id }) => id)

    if (
      userInfo?.user.school_plan === UserSchoolPlan.SCHOOL ||
      userInfo?.user.school_plan === UserSchoolPlan.PRO
    ) {
      if (!!teacherOnProArray.length && !!teacherOnFreeDemoArray.length) {
        handleOpen()
        return
      }

      if (!!teacherOnProArray.length) {
        mutate({ teachers_ids: idsRefPro.current })
        return
      }

      if (!!teacherOnFreeDemoArray.length) {
        handleOpenSendInvite()
        return
      }

      if (!!teacherOnSchoolArray.length) {
        mutate({ teachers_ids: idsRefSchool.current })
        return
      }
    } else {
      handleOpenUpgradeAccount()
    }
  }

  useEffect(() => {
    setError(false)
  }, [possibleTeacherInfo])

  return (
    <>
      <form id={id} onSubmit={onSubmit}>
        <Autocomplete
          ListboxProps={{
            style: {
              paddingBottom: "0px",
            },
          }}
          multiple
          value={possibleTeacherInfo}
          disableCloseOnSelect
          sx={{
            mb: 2,
          }}
          onChange={(_, value) => {
            setPossibleTeacherInfo(value)
            mixpanel.track("add_coteacher", {
              action: "select_coteacher",
            })
          }}
          options={data?.data || []}
          loading={isLoading}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name} `
          }
          onInputChange={(_, value) => {
            if (data?.data !== undefined) {
              dataRef.current = [
                ...data?.data.filter((el) =>
                  `${el.first_name} ${el.last_name}`
                    .toLowerCase()
                    .includes(value.toLowerCase())
                ),
              ]
            }
          }}
          renderOption={(props, option) => {
            const defaultProps = props as HTMLAttributes<HTMLLIElement> & {
              "data-option-index"?: number
            }
            const index = defaultProps["data-option-index"]

            return (
              <React.Fragment key={option.id}>
                <Box component="li" {...props}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box display="flex" gap={1}>
                      <Typography>{option.first_name}</Typography>
                      <Typography>{option.last_name}</Typography>
                    </Box>

                    {option.school_account.plan === UserSchoolPlan.PRO && (
                      <Typography
                        sx={{
                          color: "mockup.neutral100",
                          borderRadius: "16px",
                          bgcolor: "primary.main",
                          padding: 1,
                        }}
                      >
                        {option.school_account.plan}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {index === (dataRef.current && dataRef.current?.length - 1) && (
                  <InviteButton
                    margin="0px"
                    onClick={() => {
                      userInfo && isFreeDemoPlan(userInfo)
                        ? (handleOpenUpgradeAccount(),
                          mixpanel.track("add_coteacher", {
                            action: "upgrade_plan",
                          }))
                        : handleOpenManageTeachers()
                    }}
                  >
                    <Stack rowGap={1}>
                      <Typography variant="body1">
                        {t("coteachers.notSeeingTheTeacherYouWantToAdd")}
                      </Typography>
                      <Typography variant="body1">
                        {t("coteachers.inviteThemToJoinClassBank")}
                      </Typography>
                    </Stack>
                  </InviteButton>
                )}
              </React.Fragment>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("coteachers.searchForTeacher")}
              error={error}
            />
          )}
          noOptionsText={
            <InviteButton
              margin="-14px -16px"
              onClick={() => {
                userInfo && isFreeDemoPlan(userInfo)
                  ? (handleOpenUpgradeAccount(),
                    mixpanel.track("add_coteacher", {
                      action: "upgrade_plan",
                    }))
                  : handleOpenManageTeachers()
              }}
            >
              <Stack rowGap={1}>
                <Typography variant="body1">
                  {t("coteachers.notSeeingTheTeacherYouWantToAdd")}
                </Typography>
                <Typography variant="body1">
                  {t("coteachers.inviteThemToJoinClassBank")}
                </Typography>
              </Stack>
            </InviteButton>
          }
        />
        {error && (
          <Typography variant="body1" fontWeight="500" color="mockup.error50">
            {t("coteachers.youHaveToSelectTeacher")}
          </Typography>
        )}
      </form>
      <DialogUpgradeAccount
        open={isOpenUpgradeAccount}
        onClose={handleCloseUpgradeClose}
        onActionButtonClick={() => payIt(null)}
        isLoading={isLoadingPayments}
      />
      <Dialog
        open={isOpenManageTeachers}
        onClose={handleCloseManageTeachers}
        titleText={
          userInfo && isProPlan(userInfo)
            ? t("coteachers.inviteTeachers")
            : t("coteachers.teachers")
        }
        desktopMaxWidth="867px"
        tabletMaxWidth="534px"
        customActionButtonsSlot
      >
        <InviteCoteachers />
      </Dialog>
      <Dialog
        titleText={
          teacherOnFreeDemoArray.length === 1
            ? t(
                "coteachers.thisTeacherHasntYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass"
              )
            : t(
                "coteachers.theseTeacherHaventYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass"
              )
        }
        open={isOpenSendInvite}
        onClose={handleCloseSendInvite}
        isLoading={isLoading}
        customActionButtonsSlot={
          <Stack px="32px" py="16px">
            <Button
              onClick={() => {
                if (emailRefFree.current !== null) {
                  sendInvite({
                    emails: emailRefFree.current,
                  })
                }
              }}
            >
              {t("coteachers.sendInvite")}
            </Button>
          </Stack>
        }
      />

      <Dialog
        titleText={t(
          "coteachers.thoseTeachersHaveDifferentPlansSendAndAddThemSeparately"
        )}
        open={isOpen}
        onClose={() => {
          handleClose()
          setPossibleTeacherInfo([])
        }}
        customActionButtonsSlot
      >
        <Stack>
          <Stack>
            <Typography mb="5px">
              {t("coteachers.listOfTeacherYouCanAddToTheClass")}
            </Typography>
            {teacherOnProArray.map((teacher) => (
              <Typography
                variant="body2"
                color="mockup.neutral10"
                my="3px"
                key={teacher.id}
              >
                {teacher.first_name} {teacher.last_name}
              </Typography>
            ))}
            <Button
              disabled={!idsRefPro.current}
              variant="contained"
              sx={{ alignSelf: "center", width: "200px" }}
              onClick={() => {
                if (idsRefPro.current !== null) {
                  mutate({ teachers_ids: idsRefPro.current })
                }
              }}
            >
              Add
            </Button>
          </Stack>
          <Divider style={{ width: "100%", margin: "10px 0" }} />
          <Stack>
            <Typography mb="5px">
              {teacherOnFreeDemoArray.length === 1
                ? t(
                    "coteachers.thisTeacherHasntYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass"
                  )
                : t(
                    "coteachers.theseTeacherHaventYetUpgradedToProInviteThemToSignUpForProAndJoinYourClass"
                  )}
            </Typography>
            {teacherOnProArray.map((teacher) => (
              <Typography
                variant="body2"
                color="mockup.neutral10"
                my="3px"
                key={teacher.id}
              >
                {teacher.first_name} {teacher.last_name}
              </Typography>
            ))}
            <Button
              disabled={!emailRefFree.current}
              variant="contained"
              sx={{ alignSelf: "center", width: "200px" }}
              onClick={() => {
                if (emailRefFree.current !== null) {
                  sendInvite({ emails: emailRefFree.current })
                }
              }}
            >
              Send
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default AutoCompleteAddTeacher
