export const sortingOptions = [
  {
    label: "store.nameAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "name",
    }),
  },
  {
    label: "store.nameDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "name",
    }),
  },
  {
    label: "store.valueAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "price",
    }),
  },
  {
    label: "store.valueDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "price",
    }),
  },
  {
    label: "store.quantityAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "quantity_in_stock",
    }),
  },
  {
    label: "store.quantityDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "quantity_in_stock",
    }),
  },
]

export const sortingOptionsForFree = [
  {
    label: "store.nameAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "name",
    }),
  },
  {
    label: "store.nameDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "name",
    }),
  },
  {
    label: "store.valueAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "price",
    }),
  },
  {
    label: "store.valueDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "price",
    }),
  },
]
