import React, { FC, useEffect, useState } from "react"

import { ReactComponent as IconDollar } from "@common_assets/svg/dolar.svg"
import { ReactComponent as IconDots } from "@common_assets/svg/threeDots.svg"
import { ReactComponent as IconUser } from "@common_assets/svg/user.svg"
import { Typography } from "@mui/material"
import { useMutationsResetBalance } from "api/reactQuery/mutations/balancesReset"
import { IPostResetBalance } from "api/reactQuery/mutations/balancesReset.types"
import {
  useMutationBulkDeleteStudents,
  useMutationRemoveStudentsClass,
} from "api/reactQuery/mutations/students"
import { IPostBulkDeleteRemoveStudents } from "api/reactQuery/mutations/students.types"
import FabIconButton from "components/common/button/fabIconButton"
import FabGroup from "components/common/container/fabGroup"
import { useDialog } from "hooks/dialog"
import { useFilterClassList, userFilterBalanceClasslist } from "hooks/filter"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import {
  selectClassList,
  selectClassname,
  selectStudentsNumber,
} from "store/utility/utility.selectors"
import { setClassList, setStudentsNumber } from "store/utility/utility.slice"
import { PopOverStyle, PopRow } from "styles/common/poprow"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"

import DialogRemoveStudent from "../DialogRemoveStudent"

interface IProps {
  handleSendTransaction: () => void
  handleSeeStudentProfile: () => void
  student: IObjectStudentDetailed
  setSelectedItems: React.Dispatch<
    React.SetStateAction<IObjectStudentDetailed[]>
  >
  setExcludedItems: React.Dispatch<
    React.SetStateAction<IObjectStudentDetailed[]>
  >
}

const ActionButtons: FC<IProps> = ({
  handleSendTransaction,
  handleSeeStudentProfile,
  student,
  setSelectedItems,
  setExcludedItems,
}) => {
  const { t } = useTranslation()
  const [balanceVariable, setBalanceVariable] =
    useState<IPostResetBalance | null>(null)
  const [variable, setVariable] =
    useState<IPostBulkDeleteRemoveStudents | null>(null)
  const dispatch = useAppDispatch()
  const classList = useAppSelector(selectClassList)
  const classname = useAppSelector(selectClassname)
  const params = useParams()
  const studentsNumber = useAppSelector(selectStudentsNumber)
  const styleBKZ = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "12px",
  }
  const { showSnackbar } = useCustomSnackbar()

  const queryClient = useQueryClient()

  const { popoverAnchorEl, handleClose, handleClick } =
    usePopover<HTMLButtonElement>()

  const { mutate: resetBalance } = useMutationsResetBalance({
    classId: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.balanceHasBeenSuccessfullyReset", {
            student: `${student.first_name} ${student.last_name}`,
          }),
        })

        queryClient.invalidateQueries("classesStudents")
        setBalanceVariable(variables)
        handleCloseResetBalance()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate } = useMutationBulkDeleteStudents({
    class_id: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.studentHasBeenSuccessfullyDeleted", {
            student: `${student.first_name} ${student.last_name}`,
          }),
        })

        queryClient.invalidateQueries("classesStudents")
        setVariable(variables)
        handleCloseDelete()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: removeStudent } = useMutationRemoveStudentsClass({
    class_id: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.studentHasBeenSuccessfullyRemovedFromClass", {
            student: `${student.first_name} ${student.last_name}`,
          }),
        })

        queryClient.invalidateQueries("classesCandidates")
        queryClient.invalidateQueries("classesStudents")

        setVariable(variables)
        handleCloseRemove()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    if (variable !== null) {
      const { filteredState } = useFilterClassList({
        classlist: classList,
        variables: variable,
      })
      dispatch(setClassList(filteredState))

      setSelectedItems((prev) =>
        prev.filter((el) => {
          if (variable.data.selected_ids) {
            return el.id !== variable.data.selected_ids[0]
          }
        })
      )

      setExcludedItems((prev) =>
        prev.filter((el) => {
          if (variable.data.selected_ids) {
            return el.id !== variable.data.selected_ids[0]
          }
        })
      )
      dispatch(setStudentsNumber(studentsNumber - 1))
    }
  }, [variable])

  useEffect(() => {
    if (balanceVariable !== null) {
      const { filteredBalance } = userFilterBalanceClasslist({
        classlist: classList,
        balanceVariable,
      })
      dispatch(setClassList(filteredBalance))
    }
  }, [balanceVariable])

  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete,
  } = useDialog()

  const {
    isOpen: isOpenRemove,
    handleOpen: handleOpenRemove,
    handleClose: handleCloseRemove,
  } = useDialog()

  const {
    isOpen: isOpenResetBalance,
    handleOpen: handleOpenResetBalance,
    handleClose: handleCloseResetBalance,
  } = useDialog()

  return (
    <FabGroup>
      <FabIconButton
        tooltipText={t("students.sendTransaction")}
        onClick={() => {
          handleSendTransaction()
          mixpanel.track("send_transaction", {
            event_name: "money_sign",
          })
        }}
        Icon={
          process.env.REACT_APP_TYPE === "classequity" ? (
            <IconDollar />
          ) : (
            <Typography sx={styleBKZ}>BKZ</Typography>
          )
        }
      />
      <FabIconButton
        tooltipText={t("students.seeStudentsProfile")}
        onClick={handleSeeStudentProfile}
        Icon={<IconUser />}
      />
      <FabIconButton onClick={handleClick} Icon={<IconDots />} />
      <PopOverStyle
        open={!!popoverAnchorEl}
        onClose={handleClose}
        anchorEl={popoverAnchorEl}
        marginThreshold={40}
        anchorOrigin={{
          vertical: 40,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ul>
          <PopRow onClick={handleOpenResetBalance}>
            <li>{t("students.resetBalance")}</li>
          </PopRow>
          <PopRow onClick={handleOpenRemove}>
            <li>{t("students.removeFromClass")}</li>
          </PopRow>
          <PopRow onClick={handleOpenDelete}>
            <li>{t("students.deleteStudent")}</li>
          </PopRow>
        </ul>
      </PopOverStyle>
      <DialogRemoveStudent
        titleText={t("students.areYouSureYouWantToResetBalance", {
          student: `${student.first_name} ${student.last_name}`,
        })}
        isOpen={isOpenResetBalance}
        onClose={handleCloseResetBalance}
        onActionButtonClick={() => {
          resetBalance({ selected_ids: [student.id] })
        }}
        actionAcceptText={t("students.resetBalance")}
      />
      <DialogRemoveStudent
        titleText={t("students.areYouSureYouWantToDeleteStudent", {
          student: `${student.first_name} ${student.last_name}`,
        })}
        isOpen={isOpenDelete}
        actionAcceptText={t("students.delete")}
        onClose={handleCloseDelete}
        onActionButtonClick={() => {
          mutate({
            data: { selected_ids: [student.id] },
          })
        }}
      >
        <Typography variant="body1" fontWeight="700" color="mockup.error50">
          {t("students.thisActionCannotBeUndone").toUpperCase()}
        </Typography>
      </DialogRemoveStudent>
      <DialogRemoveStudent
        titleText={t("students.areYouSureYouWantToRemoveStudentFromClass", {
          student: `${student.first_name} ${student.last_name}`,
          classname,
        })}
        isOpen={isOpenRemove}
        actionAcceptText={t("students.removeFromClass")}
        onClose={handleCloseRemove}
        onActionButtonClick={() => {
          removeStudent({ data: { selected_ids: [student.id] } })
        }}
      />
    </FabGroup>
  )
}

export default ActionButtons
