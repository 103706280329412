import { styled, TextField } from "@mui/material"

export const TextFieldErrorStyles = styled(TextField)(({ theme, error }) => ({
  background: theme.palette.common.white,
  "& .MuiOutlinedInput-root": {
    padding: 0,
    ":hover": {
      "& > fieldset": {
        transitionDuration: "300ms",
        border: error && `2px solid ${theme.palette.mockup.error50}`,
        boxShadow: error && `0 0 0 4px ${theme.palette.mockup.error90}`,
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: error && `2px solid ${theme.palette.mockup.error50}`,
  },
  position: "absolute",
  top: "0",
  left: "0",
  borderRadius: "16px",
  zIndex: "1",
  width: "90px",
}))
