export const sortingOptions = [
  {
    label: "students.studentFirstAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.studentFirstDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.studentLastAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "last_name",
    }),
  },
  {
    label: "students.studentLastDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "last_name",
    }),
  },
  {
    label: "students.balanceAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "total_balance",
    }),
  },
  {
    label: "students.balanceDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "total_balance",
    }),
  },
]

export const sortingTextMap = {
  first_name: "students.studentFirst",
  last_name: "students.studentLast",
  total_balance: "students.balance",
}

export type Sort = "first_name" | "last_name" | "total_balance"
export type SortingType = "asc" | "desc"
