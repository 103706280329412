import React, { useState } from "react"

import { ReactComponent as EditionBtn } from "@common_assets/svg/edition-button.svg"
import { ReactComponent as Infinity } from "@common_assets/svg/infinity.svg"
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Fab,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useMutationDeleteHrJobs } from "api/reactQuery/mutations/jobs"
import { useMutationHiringHrStudent } from "api/reactQuery/mutations/students"
import { SingleStudent } from "api/reactQuery/mutations/students.types"
import { HrResponse } from "api/reactQuery/queries/hr.types"
import { IGetJobsResponse } from "api/reactQuery/queries/jobs.types"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"

import DialogEditJob from "./dialogEditJob"
import EditJob from "./editJob"

interface IBoard {
  job: IGetJobsResponse
}

const AvatarStyle = styled(AvatarGroup)({
  "& .MuiAvatar-root": { width: "36px", height: "36px" },
  "& .MuiAvatar-root:last-child": {
    marginLeft: "-10px",
  },
})

const Board = ({ job }: IBoard) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const [droppedUsers, setDroppedUsers] = useState<SingleStudent[]>([])
  const [userName, setUserName] = useState<{
    first_name: string
    last_name: string
  } | null>(null)
  const [bunchUserNames, setBunchUserNames] = useState<
    | {
        first_name: string
        last_name: string
      }[]
    | null
  >(null)

  const {
    isOpen: isDialogOpenJob,
    handleOpen: handleOpenDialogDrop,
    handleClose: handleCloseDialogDrop,
  } = useDialog()

  const {
    isOpen: isOpenDialogEditJob,
    handleOpen: handleOpenDialogEdit,
    handleClose: handleCloseDialogEdit,
  } = useDialog()

  const {
    isOpen: isOpenDialogRemoveJob,
    handleOpen: handleOpenRemoveJob,
    handleClose: handleCloseRemoveJob,
  } = useDialog()

  const { mutate: hiringStudent, isLoading: isLoadingHiringStudent } =
    useMutationHiringHrStudent({
      options: {
        onSuccess: (_, variables) => {
          if (!!window.Intercom) {
            window.Intercom("trackEvent", "add to job by drag and drop", {
              classId: variables.classId,
              amount: variables.data.length,
              jobTitle: job.title,
            })
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "add_job_by_drag_and_drop",
            amount: variables.data.length,
            classId: variables.classId,
            jobTitle: job.title,
          })
          queryClient.invalidateQueries(["jobsDetailsHr"])
          queryClient.invalidateQueries(["jobsHr"])
          queryClient.invalidateQueries(["hrClass"])
          if (!Array.isArray(userName)) {
            showSnackbar({
              title: t("jobs.hasBeenSuccessfullyHired", {
                title: job.title,
                firstName: userName?.first_name,
                lastName: userName?.last_name,
              }),
            })
          } else {
            showSnackbar({
              title: t("jobs.haveBeenSuccessfullyHired", {
                count: droppedUsers.length,
                title: job.title,
              }),
            })
          }
          handleCloseDialogDrop()
          // handleCloseApplication()
          // setId(null)
          // setStudentsToChecked((prev) =>
          //   prev.map((el) => ({ ...el, checked: false }))
          // )
          // setCheckedPerson([])
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
          handleCloseDialogDrop()
        },
      },
    })

  const { mutate: removeJobs } = useMutationDeleteHrJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("jobsHr")
        queryClient.invalidateQueries(["hrClass"])
        showSnackbar({
          title: t("jobs.removeCreateJob"),
          variant: "success",
        })
      },
    },
  })

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["bunch", "student"],
    drop: (item: { user: HrResponse[] }) => {
      const convertyArrayToType = item.user.map((user) => ({
        student_id: user.id,
      }))

      if (item.user.length === 1) {
        const { first_name, last_name } = item.user[0]
        const info = { first_name, last_name }
        setUserName(info)
      }

      if (item.user.length > 1) {
        const info = item.user.map((usr) => ({
          first_name: usr.first_name,
          last_name: usr.last_name,
        }))

        setBunchUserNames(info)
      }

      setDroppedUsers(convertyArrayToType)
      handleOpenDialogDrop()
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }))

  return (
    <Stack
      mr={1}
      mb={1}
      minWidth="260px"
      height="245px"
      ref={drop}
      flex="1"
      sx={{
        borderRadius: 1,
        border: isOver
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.mockup.neutral90}`,
      }}
    >
      <Box
        py="20px"
        px={2}
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        bgcolor={theme.palette.mockup.neutral100}
        border={`2px solid ${theme.palette.mockup.primary95}`}
        borderRadius={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Tooltip
            title={job.title.length > 40 ? job.title : ""}
            placement="bottom"
          >
            <Typography
              sx={{
                width: "200px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: theme.palette.mockup.neutral0,
              }}
              variant="subtitle1"
            >
              {job.title}
            </Typography>
          </Tooltip>
          <Fab
            size="small"
            onClick={() => {
              handleOpenDialogEdit()
            }}
          >
            <EditionBtn />
          </Fab>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          alignItems="center"
        >
          {job.positions > 0 ? (
            <Typography
              variant="body3"
              color="mockup.neutral10"
              fontWeight="500"
              sx={{ pr: "3px" }}
            >
              {Number(job.positions) - Number(job.employees.length)}
            </Typography>
          ) : (
            <Infinity />
          )}
          <Typography variant="body3" color="mockup.neutral10" fontWeight="500">
            {t("jobs.openPositions")}
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1 }} />
          <Coin amount={job.salary} />
        </Box>
        <Divider sx={{ width: "100%", my: 2 }} />
        <Box>
          <Typography variant="body3" color="primary.main">
            {t("jobs.employeesTitle").toUpperCase()}
          </Typography>
          <Box display="flex" position="relative" sx={{ minHeight: "36px" }}>
            <AvatarStyle max={7}>
              {job.employees.map((el) => (
                <Tooltip key={el.id} title={`${el.first_name} ${el.last_name}`}>
                  <Box display="flex" sx={{ cursor: "pointer" }}>
                    {el.avatar_url ? (
                      <Avatar
                        src={el.avatar_url}
                        alt="avatar"
                        style={{
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Box>
                </Tooltip>
              ))}
            </AvatarStyle>
          </Box>
          <Box display="flex" position="relative" sx={{ minHeight: "36px" }}>
            {job.pending_applications_count !== 0 && (
              <Box>
                <Typography variant="body3" color="primary.main">
                  {t("jobs.pendingApplicationGrid").toUpperCase()}
                </Typography>
                <Box display="flex">
                  <AvatarStyle max={7}>
                    {job.pending_applications_students.map((el) => (
                      <Tooltip
                        key={el.id}
                        title={`${el.first_name} ${el.last_name}`}
                      >
                        <Box key={el.id} sx={{ cursor: "pointer" }}>
                          {el.avatar_url ? (
                            <Avatar
                              src={el.avatar_url}
                              alt="avatar"
                              style={{
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          ) : (
                            <Avatar />
                          )}
                        </Box>
                      </Tooltip>
                    ))}
                  </AvatarStyle>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        actionAcceptButtonProps={{ disabled: isLoadingHiringStudent }}
        titleText={
          droppedUsers?.length === 1
            ? t("jobs.areYouSureYouWantToHireSingleStudentAsAJob", {
                firstname: userName?.first_name,
                lastname: userName?.last_name,
                jobsTitle: job.title,
              })
            : t("jobs.areYouSureYouWantToHireStudentsAsAJob", {
                count: droppedUsers?.length,
                jobsTitle: job.title,
              })
        }
        open={isDialogOpenJob}
        onClose={() => {
          handleCloseDialogDrop()
          mixpanel.track("hire_students", {
            action: "cancel",
          })
        }}
        desktopMaxWidth="534px"
        tabletMaxWidth="534px"
        actionAcceptText={"Hire"}
        onActionButtonClick={() => {
          if (!!droppedUsers?.length) {
            hiringStudent({
              classId: Number(params.classId),
              jobId: job.id,
              data: droppedUsers,
            })
          }
        }}
      >
        <Stack>
          {bunchUserNames !== null &&
            bunchUserNames.map((user, index) => (
              <Typography key={index} color="mockup.neutral10" variant="body2">
                {user.first_name} {user.last_name}
              </Typography>
            ))}
        </Stack>
      </Dialog>
      <DialogEditJob
        isOpen={isOpenDialogEditJob}
        isLoading={false}
        onClose={() => handleCloseDialogEdit()}
        onDelete={handleOpenRemoveJob}
        actionAcceptButtonProps={{
          type: "submit",
          form: "form",
        }}
      >
        <EditJob job={job} id="form" />
      </DialogEditJob>
      <Dialog
        open={isOpenDialogRemoveJob}
        onClose={handleCloseRemoveJob}
        titleText="Are you sure you want to delete job?"
        onActionButtonClick={() =>
          removeJobs({ classId: Number(params.classId), jobId: job.id })
        }
      />
    </Stack>
  )
}
export default Board
