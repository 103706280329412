import { FC, ReactNode } from "react"

import {
  Box,
  Checkbox,
  CheckboxProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import Coin from "components/common/icon/coin"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"

import ItemWrapper from "../itemWrapper"

interface IProps {
  checkboxProps?: CheckboxProps
  topText: string
  bottomTextTitle: string
  bottomText: string
  valueTitle?: string
  titleSavings?: string
  titleBookedBalance?: string
  titleTotalBalance?: string
  titlePendingBalance?: string
  value?: number
  actionButtonsSlot: ReactNode
  valueSavings?: number
  valueBookedBalance?: number
  valueTotalBalance?: number
  valuePendingBalance?: number
  isAddNewClass?: boolean
  savingsAccountOption?: boolean
}

const CheckableItem: FC<IProps> = ({
  checkboxProps,
  topText,
  bottomTextTitle,
  bottomText,
  valueTitle,
  value,
  titleSavings,
  titleBookedBalance,
  titleTotalBalance,
  titlePendingBalance,
  valueSavings,
  valueBookedBalance,
  valueTotalBalance,
  valuePendingBalance,
  actionButtonsSlot,
  isAddNewClass,
  savingsAccountOption,
}) => {
  const { t } = useTranslation()
  const switchState = useAppSelector(selectDisplaySavings)
  return (
    <ItemWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Box
          ml="-4px"
          py="8px"
          pr="16px"
          borderRight={(theme) => `1px solid ${theme.palette.mockup.primary95}`}
        >
          <Checkbox {...checkboxProps} />
        </Box>
        <Typography
          py="6px"
          variant="body1"
          color="mockup.neutral10"
          width="100%"
          sx={{ wordBreak: "break-all" }}
        >
          {topText}
        </Typography>
        {!isAddNewClass && (
          <Box
            pl="16px"
            borderLeft={(theme) =>
              `1px solid ${theme.palette.mockup.primary95}`
            }
            alignSelf="start"
          >
            {actionButtonsSlot}
          </Box>
        )}
      </Stack>
      {!isAddNewClass && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
          pl="16px"
        >
          <Stack width="100%" minWidth="0" pr="68px">
            <Typography
              variant="body3"
              color="mockup.neutral40"
              fontWeight={500}
            >
              {bottomTextTitle}
            </Typography>
            <Tooltip title={bottomText}>
              <Typography
                variant="body2"
                color="mockup.neutral20"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight={500}
              >
                {bottomText}
              </Typography>
            </Tooltip>
          </Stack>
          {value ? (
            <Stack flexBasis="max-content">
              <Stack alignItems="end">
                <Typography
                  variant="body3"
                  color="mockup.neutral40"
                  fontWeight={500}
                >
                  {valueTitle || t("value")}
                </Typography>
                <Coin amount={`${value}`} />
              </Stack>
            </Stack>
          ) : savingsAccountOption && switchState ? (
            <Stack flexBasis="max-content">
              <Stack direction="row" columnGap="12px" justifyContent="end">
                <Stack alignItems="end" width="50px">
                  <Typography
                    variant="body3"
                    color="mockup.neutral40"
                    fontWeight={500}
                  >
                    {titleTotalBalance}
                  </Typography>
                  <Coin amount={`${valueTotalBalance}`} />
                </Stack>
                <Stack alignItems="end" width="50px">
                  <Typography
                    variant="body3"
                    color="mockup.neutral40"
                    fontWeight={500}
                  >
                    {titlePendingBalance}
                  </Typography>
                  <Coin amount={`${valuePendingBalance}`} />
                </Stack>
              </Stack>
              <Stack flexDirection="row" columnGap="12px">
                <Stack alignItems="end" width="60px">
                  <Typography
                    variant="body3"
                    color="mockup.neutral40"
                    fontWeight={500}
                  >
                    {titleBookedBalance}
                  </Typography>
                  <Coin amount={`${valueBookedBalance}`} />
                </Stack>
                <Stack alignItems="end" width="50px">
                  <Typography
                    variant="body3"
                    color="mockup.neutral40"
                    fontWeight={500}
                  >
                    {titleSavings}
                  </Typography>
                  <Coin amount={`${valueSavings}`} />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" columnGap="12px" justifyContent="end">
              <Stack alignItems="end" width="50px">
                <Typography
                  variant="body3"
                  color="mockup.neutral40"
                  fontWeight={500}
                >
                  {titleTotalBalance}
                </Typography>
                <Coin amount={`${valueTotalBalance}`} />
              </Stack>
              <Stack alignItems="end" width="50px">
                <Typography
                  variant="body3"
                  color="mockup.neutral40"
                  fontWeight={500}
                >
                  {titlePendingBalance}
                </Typography>
                <Coin amount={`${valuePendingBalance}`} />
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </ItemWrapper>
  )
}

export default CheckableItem
