import { Dispatch, FC, useState } from "react"

import { Checkbox } from "@mui/material"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useBeforeunload } from "react-beforeunload"

import UpdateInput from "../updateInput"
import UpdateInputNumber from "../updateInput/updateInputNumber"
import UpdateTitleInput from "../updateTitleInput"
import {
  ISuggestedJobsTypes,
  IUpdateToggleItem,
} from "./AddSuggestedJobs.types"

const Table: FC<{
  el: ISuggestedCreateJobs
  updateToggleItem: (arg: IUpdateToggleItem) => void
  handleSuggestedJobs: (el: ISuggestedCreateJobs) => void
  setSuggestedJobs: Dispatch<React.SetStateAction<ISuggestedCreateJobs[]>>
  suggestedJobs: ISuggestedCreateJobs[] | []
  setIsActiveInput: Dispatch<React.SetStateAction<boolean>>
  isActiveInput: boolean
  handleOpenDialogCloseSuggestedJobs: () => void
}> = ({
  el,
  handleSuggestedJobs,
  setSuggestedJobs,
  suggestedJobs,
  updateToggleItem,
  setIsActiveInput,
  isActiveInput,
  handleOpenDialogCloseSuggestedJobs,
}) => {
  const [isResponsibilities, setIsResponsibilities] = useState(false)
  const [isQualifications, setIsQualifications] = useState(false)
  const [isTitle, setIsTitle] = useState(false)
  const [isSalary, setIsSalary] = useState(false)

  const updateDate = (id: number, text: string, type: ISuggestedJobsTypes) => {
    const newState = suggestedJobs.map((element) => {
      if (element.id === id) {
        if (text === "" && type !== ISuggestedJobsTypes.SALARY) {
          return { ...element, [type]: "" }
        } else if (text === "" && type === ISuggestedJobsTypes.SALARY) {
          return { ...element, [type]: 0 }
        } else if (type !== ISuggestedJobsTypes.SALARY) {
          return { ...element, [type]: text }
        } else if (type === ISuggestedJobsTypes.SALARY) {
          return { ...element, [type]: text }
        } else {
          return { ...element, [type]: text }
        }
      } else {
        return element
      }
    })
    setSuggestedJobs(newState)
  }

  useBeforeunload((event) => {
    event.preventDefault()
  })

  window.onpopstate = (e) => {
    e.stopImmediatePropagation()
    history.go(1)
    handleOpenDialogCloseSuggestedJobs()
  }

  return (
    <BasicTable.Row key={el.responsibilities} sx={{ height: "100%" }}>
      <BasicTable.Cell>
        <Checkbox checked={el.check} onClick={() => handleSuggestedJobs(el)} />
      </BasicTable.Cell>
      <BasicTable.Cell sx={{ position: "relative", width: "163px" }}>
        <UpdateTitleInput
          handleSuggestedJobs={handleSuggestedJobs}
          el={el}
          updateToggleItem={updateToggleItem}
          updateDate={updateDate}
          text={el.title ?? ""}
          isClicked={isTitle}
          setIsClicked={setIsTitle}
          type={ISuggestedJobsTypes.TITLE}
        />
      </BasicTable.Cell>
      <BasicTable.Cell sx={{ position: "relative", color: "red" }}>
        <UpdateInput
          handleSuggestedJobs={handleSuggestedJobs}
          el={el}
          updateToggleItem={updateToggleItem}
          updateDate={updateDate}
          text={el.responsibilities ?? ""}
          isClicked={isResponsibilities}
          setIsClicked={setIsResponsibilities}
          type={ISuggestedJobsTypes.RESPONSIBILITIES}
          setIsActiveInput={setIsActiveInput}
          isActiveInput={isActiveInput}
        />
      </BasicTable.Cell>
      <BasicTable.Cell sx={{ position: "relative" }}>
        <UpdateInput
          handleSuggestedJobs={handleSuggestedJobs}
          el={el}
          updateToggleItem={updateToggleItem}
          updateDate={updateDate}
          text={el.qualifications ?? ""}
          isClicked={isQualifications}
          setIsClicked={setIsQualifications}
          type={ISuggestedJobsTypes.QUALIFICATIONS}
          setIsActiveInput={setIsActiveInput}
          isActiveInput={isActiveInput}
        />
      </BasicTable.Cell>
      <BasicTable.Cell
        sx={{
          position: "relative",
          width: "100px",
        }}
      >
        <UpdateInputNumber
          handleSuggestedJobs={handleSuggestedJobs}
          isSalary={isSalary}
          el={el}
          updateToggleItem={updateToggleItem}
          setIsSalary={setIsSalary}
          updateDate={updateDate}
          type={ISuggestedJobsTypes.SALARY}
        />
      </BasicTable.Cell>
    </BasicTable.Row>
  )
}

export default Table
