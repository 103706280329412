import React from "react"

import { Box, Stack, Tab, Tabs, Typography } from "@mui/material"
import { IResponseDashboardStoreUpdates } from "api/reactQuery/queries/dashboardTodo.types"
import { AxiosResponse } from "axios"
import Dialog from "components/common/dialog/dialog"
import { HREFLINK } from "components/common/link/hreflink"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { Trans, useTranslation } from "react-i18next"

interface IProps {
  tabNumber: number
  dataStore:
    | AxiosResponse<IResponseDashboardStoreUpdates[], unknown>
    | undefined
  setTabNumber: React.Dispatch<React.SetStateAction<number>>
}

const Top = ({ tabNumber, dataStore, setTabNumber }: IProps) => {
  const { t } = useTranslation()
  const isUserFree = userIsFreeDemoSchoolPlan()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isUserFree) return
    setTabNumber(newValue)
  }

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { handleOpen, isOpen, handleClose } = useDialog()

  return (
    <>
      <Typography variant="subtitle1" color="mockup.neutral10" p="16px">
        {t("dashboard.todo")}
      </Typography>
      <Tabs
        value={tabNumber}
        onChange={handleChange}
        onClick={() => !tabNumber && isUserFree && handleOpen()}
      >
        <Tab sx={{ ml: 2 }} label={t("dashboard.pendingTransaction")} />
        <Tab
          label={
            <Stack direction="row" alignItems="center" columnGap="5px">
              <Typography variant="body2">
                {t("dashboard.storeUpdates")}
              </Typography>
              {!!dataStore?.data.length && (
                <Box
                  sx={(theme) => ({
                    height: "8px",
                    width: "8px",
                    backgroundColor: theme.palette.mockup.primary60,
                    borderRadius: "50%",
                  })}
                />
              )}
            </Stack>
          }
        />
      </Tabs>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        titleText={t("dashboard.storeUpdates")}
        actionAcceptText={t("dashboard.upgrade")}
        onActionButtonClick={() => payIt(null)}
        isLoading={isLoadingPayments}
        actionAcceptButtonProps={{ disabled: isLoadingPayments }}
      >
        <Typography variant="body2" fontWeight="500">
          <Trans
            i18nKey={"dashboard.descriptionStoreUpdates"}
            components={{
              Link: HREFLINK,
            }}
          />
        </Typography>
      </Dialog>
    </>
  )
}

export default Top
