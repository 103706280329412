import { useEffect, useRef, useState } from "react"

import { useQueryClassesById } from "api/reactQuery/queries/classes"
import { useAppDispatch } from "hooks/store"
import { useParams } from "react-router"
import {
  setIsNextPage,
  setParamsClassStudentList,
} from "store/utility/utility.slice"

import ActionButtons from "../common/actionButtons"
import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import { HandleFunction } from "../common/viewCommonElementsContainer/ViewCommonElementsContainer"
import Table from "./table"
import TableRow from "./tableRow"

export type Sort = "first_name" | "last_name" | "balance"
export type SortingType = "asc" | "desc"

const TableView = () => {
  const dispatch = useAppDispatch()
  const [sortBy, setSortBy] = useState<Sort>("first_name")
  const [sorting, setSorting] = useState<SortingType>("asc")
  const [isToggleAll, setIsToggleAll] = useState(false)
  const [file, setFile] = useState("")
  const params = useParams()

  const refetchRef = useRef<HandleFunction | null>(null)

  useEffect(() => {
    setIsToggleAll(false)
  }, [params.classId])

  useEffect(() => {
    dispatch(setParamsClassStudentList({ sorting: sorting, sort_by: sortBy }))
  }, [sorting, sortBy])

  const setSortingTable = (property: string) => {
    if (property === "balance") {
      setSortBy("balance")
      if (sorting === "asc") {
        setSorting("desc")
      } else if (sorting === "desc") {
        setSorting("asc")
      }
    }

    if (property === "first_name") {
      if (sortBy === "balance") {
        setSortBy("first_name")
        setSorting("asc")
        return
      }

      if (sortBy === "first_name") {
        setSortBy("last_name")
        return
      }

      if (sortBy === "last_name" && sorting === "asc") {
        setSorting("desc")
        return
      }

      if (sortBy === "last_name" && sorting === "desc") {
        setSorting("asc")
        return
      }
    }
  }

  const { data: classesByIdData } = useQueryClassesById({
    id: Number(params.classId),
  })

  const savingsAccountOption = classesByIdData?.data.savings_account_option

  return (
    <ViewCommonElementsContainer
      stateFile={{ file, setFile }}
      setIsToggle={setIsToggleAll}
      ref={refetchRef}
      isToggleAll={isToggleAll}
      sortBy={sortBy}
      setSortBy={setSortBy}
      sorting={sorting}
      savingsAccountOption={savingsAccountOption}
      renderDataFromBE={({
        apiData,
        selectingProps: {
          selectedItems,
          excludedItems,
          toggleAll,
          toggleItem,
          getIsChecked,
          toggleExcludedItems,
          clearSelectedItems,
          setExcludedItems,
          setSelectedItems,
        },
        invokeListItemAction,
      }) => (
        <Table
          savingsAccountOption={savingsAccountOption}
          checkboxProps={{
            checked:
              apiData?.length === selectedItems?.length && !!apiData?.length,
            onChange: () => {
              if (!!file.length && isToggleAll) {
                toggleAll()
                if (apiData.length !== selectedItems.length) {
                  const filteredArr = excludedItems.filter((element) => {
                    return !apiData.some((copyElement) => {
                      return element.id === copyElement.id
                    })
                  })
                  setExcludedItems(filteredArr)
                  return
                }
                setExcludedItems((prev) => {
                  const newItems = apiData.filter(
                    (item) =>
                      !prev.some((excludedItem) => excludedItem.id === item.id)
                  )
                  return [...prev, ...newItems]
                })
                return
              }

              toggleAll()
              if (!!excludedItems.length) {
                return
              }
              setIsToggleAll(!isToggleAll)
            },
            indeterminate:
              apiData.length !== selectedItems.length && !!selectedItems.length,
          }}
          getTableSortLabelProps={(property) => ({
            direction: sorting as SortingType,
            onClick: () => {
              setSortingTable(property)
              dispatch(setIsNextPage(true))
              refetchRef.current && refetchRef.current.triggerRefetch()
              clearSelectedItems()
            },
          })}
        >
          {apiData.map((student) => (
            <TableRow
              key={student.id}
              savingsAccountOption={savingsAccountOption}
              getCheckboxProps={(item) => ({
                checked: getIsChecked(item),
                onChange: () => {
                  toggleItem(item)
                },
                onClick: () => {
                  if (isToggleAll) {
                    toggleExcludedItems(item)
                  }
                },
              })}
              student={student}
              actionButtonsSlot={
                <ActionButtons
                  handleSeeStudentProfile={() =>
                    invokeListItemAction(student, "visitProfile")
                  }
                  handleSendTransaction={() =>
                    invokeListItemAction(student, "sendTransaction")
                  }
                  student={student}
                  setSelectedItems={setSelectedItems}
                  setExcludedItems={setExcludedItems}
                />
              }
            />
          ))}
        </Table>
      )}
    />
  )
}
export default TableView
