import React from "react"

import {
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import { useTranslation } from "react-i18next"
import { IStudentBalance } from "ts/interfaces/Student"

interface IWithoutSavingAccount {
  data?: IStudentBalance
  isLoading: boolean
  isError: boolean
}

const WithoutSavingAccount = ({
  data,
  isLoading,
  isError,
}: IWithoutSavingAccount) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery("(min-width: 1440px)")

  return (
    <Stack
      mt={{ mobile: "20px", tablet: "24px" }}
      direction="row"
      gap="10px"
      alignItems="center"
      justifyContent={{
        mobile: "center",
        tablet: "start",
      }}
      minHeight="50px"
      sx={
        isDesktop
          ? {
              m: -1,
              p: 2,
              borderRadius: 2,
            }
          : {
              m: -2,
              p: 2,
              backgroundColor: theme.palette.common.white,
              borderRadius: 2,
            }
      }
    >
      <Typography
        fontSize={
          isDesktop
            ? {
                desktop: "24px",
              }
            : {
                mobile: "20px",
                tablet: "18px",
                desktop: "20px",
              }
        }
        lineHeight={{ mobile: "24px", tablet: "32px" }}
        color="mockup.neutral10"
        textTransform={{ mobile: "none", desktop: "uppercase" }}
      >
        {t("dashboardStudent.yourBalance")}:
      </Typography>
      {isError && <ErrorText />}
      {isLoading && <CircularProgress size="24px" />}
      {data && !isLoading && (
        <Coin
          amount={data.total_balance}
          iconSize={
            process.env.REACT_APP_TYPE === "classequity" ? "24px" : "36px"
          }
          valueTextProps={{
            fontSize: { mobile: "20px", tablet: "24px" },
            lineHeight: { mobile: "24px", tablet: "32px" },
          }}
        />
      )}
    </Stack>
  )
}
export default WithoutSavingAccount
