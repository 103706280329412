import React, { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check.svg"
import { ReactComponent as IconX } from "@common_assets/svg/cross.svg"
import { styled, Typography, useTheme } from "@mui/material"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import { useTranslation } from "react-i18next"

import {
  ISuggestedJobsTypes,
  IUpdateToggleItem,
} from "../addSuggestedJobs/AddSuggestedJobs.types"
import RichEditor from "../RichEditor"
import SelectIcon from "./SelectIcon"
import { IconTypes } from "./updateInputNumber/UpdateInput.types"

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  color: theme.palette.mockup.neutral10,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "250px",
}))

interface IProps {
  updateDate: (arg: number, text: string, type: ISuggestedJobsTypes) => void
  isClicked: boolean
  type: ISuggestedJobsTypes
  setIsClicked: Dispatch<React.SetStateAction<boolean>>
  updateToggleItem: (args: IUpdateToggleItem) => void
  handleSuggestedJobs: (el: ISuggestedCreateJobs) => void
  el: ISuggestedCreateJobs
  text: string
  setIsActiveInput: Dispatch<React.SetStateAction<boolean>>
  isActiveInput: boolean
}

const UpdateInput: FC<IProps> = ({
  updateDate,
  isClicked,
  updateToggleItem,
  setIsClicked,
  type,
  el,
  handleSuggestedJobs,
  text,
  setIsActiveInput,
  isActiveInput,
}) => {
  const { check, id } = el
  const { t } = useTranslation()
  const theme = useTheme()
  const [inputName, setInputName] = useState(text)
  const [isOnMouse, setIsOnMouse] = useState({
    cross: false,
    check: false,
  })

  const handleBlur = () => {
    setIsClicked(false)
    setIsActiveInput(false)
    updateDate(Number(id), inputName.trim(), type)
    updateToggleItem({
      id: Number(id),
      text: inputName.trim(),
      type,
    })
  }

  const refreshState = () => {
    setIsClicked(false)
    setIsActiveInput(false)
    setInputName(text)
  }

  useEffect(() => {
    if (process.env.REACT_APP_TYPE === "bankeeonline")
      document.documentElement.style.setProperty("--bankeeColor", "#CBE7E6")

    document.documentElement.style.setProperty("--transform-x", "translateX(0)")
    document.documentElement.style.setProperty("--position", "inline")
    document.documentElement.style.setProperty(
      "--cursorToEdit",
      isClicked ? "auto" : "pointer"
    )

    return () => {
      document.documentElement.style.setProperty(
        "--transform-x",
        "translateX(-50%)"
      )
      document.documentElement.style.setProperty("--position", "absolute")
      document.documentElement.style.setProperty("--cursorToEdit", "auto")
    }
  }, [isClicked])

  return (
    <>
      {!isClicked ? (
        <div style={{ width: "100%" }}>
          <RichEditor
            state={text === "<p><br></p>" ? t("jobs.clickToFillOut") : text}
            readOnly
            onClick={() => {
              if (!isActiveInput) {
                setIsActiveInput(true)
                setIsClicked(true)
                !check && handleSuggestedJobs(el)
              }
            }}
          />
        </div>
      ) : (
        <RichEditor
          name={type}
          suggestedEditable
          style={{
            position: "absolute",
            padding: "25px 16px",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            height: "auto",
            backgroundColor: theme.palette.common.white,
          }}
          cross={
            <SelectIcon
              Icon={<IconX onClick={refreshState} />}
              type={IconTypes.CROSS}
              isOnMouse={isOnMouse}
              setIsOnMouse={setIsOnMouse}
            />
          }
          check={
            <SelectIcon
              Icon={<IconCheck onClick={handleBlur} />}
              type={IconTypes.CHECK}
              isOnMouse={isOnMouse}
              setIsOnMouse={setIsOnMouse}
            />
          }
          state={inputName}
          setState={setInputName}
        />
      )}
    </>
  )
}

export default UpdateInput
