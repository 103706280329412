import { FC, useMemo } from "react"

import { Box, useTheme } from "@mui/material"
import TransactionIcon from "components/common/icon/transactionIcon"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { PrimaryTile } from "styles/common/tile"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionType } from "ts/types/Transaction"

import ContentTile from "./contentTile"

interface IProps {
  title: string
  amount: number | string
  variant?: TransactionType
  selected?: boolean
  onClick?: () => void
  watcher?: ITransactionItem[]
  id?: number
}

const Tile: FC<IProps> = ({
  title,
  amount,
  variant = "bonus",
  selected = false,
  onClick,
  watcher,
  id,
}) => {
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("tablet")

  const specifyWatcherAmount = (id: number): number => {
    return watcher?.filter((element) => element.id === id).length || 0
  }

  const memo = useMemo(() => specifyWatcherAmount(id as number), [watcher])

  return (
    <PrimaryTile
      onClick={onClick}
      position="relative"
      p="16px"
      sx={[
        {
          cursor: "pointer",
          "&:hover": {
            background: theme.palette.mockup.primary95,
            borderColor: theme.palette.mockup.primary95,
          },
        },
        selected && {
          background: theme.palette.primary.light,
          borderColor: theme.palette.primary.main,
        },
      ]}
    >
      <Box display="flex" gap="8px" width="100%" alignItems="center">
        {isTablet && <TransactionIcon variant={variant} />}
        <ContentTile title={title} memo={memo} amount={amount} />
      </Box>
    </PrimaryTile>
  )
}

export default Tile
