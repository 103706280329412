import React, { useEffect, useMemo, useState } from "react"

import {
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { useInfiniteQueryHrClass } from "api/reactQuery/queries/hr"
import { HrResponse } from "api/reactQuery/queries/hr.types"
import { useInView } from "react-intersection-observer"
import { useParams } from "react-router"

import BunchUsers from "./bunchUsers"
import DraggableUser from "./draggableUser"

const ListUsers = () => {
  const params = useParams()
  const { ref, inView } = useInView()
  const [checkedUsers, setCheckedUsers] = useState<HrResponse[]>([])
  const [currentUsers, setCurrentUsers] = useState<HrResponse[]>([])
  const [isToggleAll, setIsToggleAll] = useState(false)
  const [isWithoutJobs, setIsWithoutJobs] = useState(false)
  const { data, hasNextPage, fetchNextPage } = useInfiniteQueryHrClass({
    classId: Number(params.classId),
    filter: undefined,
    limit: 20,
    skip: 0,
    sorting: "asc",
    sort_by: "first_name",
  })

  const filteredUsers = useMemo(() => {
    if (isWithoutJobs) {
      return currentUsers.filter((item) => !item.jobs.length)
    }
    return currentUsers
  }, [isWithoutJobs, currentUsers])

  const getUsers = (user: HrResponse) => {
    const isAlreadyUser = checkedUsers.find((checked) => checked.id === user.id)

    if (isAlreadyUser) {
      setCheckedUsers((prevState) =>
        prevState.filter((checked) => checked.id !== isAlreadyUser.id)
      )
      return
    }

    setCheckedUsers((prev) => [...prev, user])
  }

  const selectAllUsers = () => {
    if (checkedUsers.length === filteredUsers.length) {
      setCheckedUsers([])
    } else {
      setCheckedUsers(filteredUsers)
    }
  }

  useEffect(() => {
    const initialState = data?.pages.flatMap((item) => item.data.objects)

    if (initialState) {
      setCurrentUsers((prev) => {
        const updateStudent = prev.map((existingUser) => {
          const usr = initialState.find((usr) => usr.id === existingUser.id)
          if (
            usr &&
            JSON.stringify(usr.jobs) !== JSON.stringify(existingUser.jobs)
          ) {
            return usr
          }
          return existingUser
        })
        const newStudent = initialState.filter(
          (item) => !prev.some((exist) => exist.id === item.id)
        )
        return [...updateStudent, ...newStudent]
      })
    }
  }, [data])

  useEffect(() => {
    const newState = data?.pages.flatMap((item) => item.data.objects)
    if (newState && isToggleAll) {
      setCheckedUsers((prev) => {
        const newIds = newState.filter(
          (item) => !prev.some((exist) => exist.id === item.id)
        )

        return [...prev, ...newIds]
      })
    }
  }, [data])

  useEffect(() => {
    if (!checkedUsers.length) setIsToggleAll(false)
  }, [checkedUsers])

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  return (
    <Stack sx={{ alignItems: "center", mt: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isWithoutJobs}
            onChange={() => setIsWithoutJobs(!isWithoutJobs)}
          />
        }
        label={
          <Typography fontWeight="500" variant="body2" pl={1}>
            Show students without a job
          </Typography>
        }
      />
      <Stack p={2}>
        {!!checkedUsers.length && (
          <BunchUsers
            checkedUsers={checkedUsers}
            onClickX={() => setCheckedUsers([])}
          />
        )}
        <FormControlLabel
          sx={{
            margin: "8px 0",
            alignItems: "center",
          }}
          control={
            <Checkbox
              indeterminate={
                checkedUsers.length !== filteredUsers.length &&
                !!checkedUsers.length
              }
              checked={
                checkedUsers.length === filteredUsers.length &&
                !!checkedUsers.length
              }
              onChange={selectAllUsers}
              onClick={() => setIsToggleAll(!isToggleAll)}
            />
          }
          label="Select All"
        />
        {filteredUsers?.map((user) => (
          <DraggableUser
            user={user}
            key={user.id}
            checkedUsers={checkedUsers}
            onChange={() => {
              getUsers(user)
            }}
          />
        ))}
        {hasNextPage && <div ref={ref} />}
      </Stack>
    </Stack>
  )
}
export default ListUsers
