import * as yup from "yup"

import {
  requiredNumberSchema,
  requiredStringSchema,
} from "../../../../../utils/yup"

export const defaultValues = {
  title: "",
  description: "",
  total_amount: "",
  icon_url: "",
}

export const validationSchema = yup.object({
  title: requiredStringSchema,
  total_amount: requiredNumberSchema.max(500000).positive(),
})
