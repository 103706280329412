import { ReactNode } from "react"

import { Box, Button } from "@mui/material"
import { IStudentSideTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll.type"
import ErrorText from "components/common/error/errorText"
import BasicItem from "components/common/listing/basicItem"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { PrimaryTile } from "styles/common/tile"

interface IProps {
  header?: ReactNode
  footer?: ReactNode
  handleTransactionClick: (transaction: IStudentSideTransactionsAll) => void
  data: IStudentSideTransactionsAll[] | []
  isError: boolean
}

const PendingTransactionsList = ({
  header,
  footer,
  handleTransactionClick,
  data,
  isError,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <PrimaryTile overflow="hidden">
      {header}
      <TransitionGroup component={null}>
        {isError ? (
          <ErrorText />
        ) : (
          data?.map((transaction) => (
            <CSSTransition
              key={transaction.id}
              classNames="animation-fade"
              timeout={700}
              unmountOnExit
            >
              <BasicItem
                topTextTitle={t("dashboardStudent.class")}
                topText={transaction.class.name}
                bottomTextTitle={t("dashboardStudent.title")}
                bottomText={transaction.title}
                actionButtonsSlot={
                  <Box
                    pl="16px"
                    borderLeft={(theme) =>
                      `1px solid ${theme.palette.mockup.primary95}`
                    }
                  >
                    <Button
                      variant="outlined"
                      sx={{ padding: "8px 22px" }}
                      onClick={() => {
                        handleTransactionClick(transaction)
                      }}
                    >
                      {transaction.type === "bonus" ||
                      transaction.type === "salary"
                        ? t("dashboardStudent.deposit")
                        : t("dashboardStudent.withdraw")}
                    </Button>
                  </Box>
                }
              />
            </CSSTransition>
          ))
        )}
      </TransitionGroup>
      {footer}
    </PrimaryTile>
  )
}

export default PendingTransactionsList
