import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"

export const elementSuggestedJobs: ISuggestedCreateJobs[] = [
  {
    id: 1,
    check: false,
    responsibilities:
      "<ul><li>Follow all classroom and school wide expectations.</li><li>Give my best and always seek to learn and improve.</li></ul> ",
    title: "Student",
    salary: [2, 4, 8],
    qualifications: "<ul><li>Must attend (Enter Your School's Name)</li></ul>",
    positions: null,
    open_for_all: true,
  },
  {
    id: 2,
    check: false,
    responsibilities:
      "<ul><li>Deliver papers to other teachers or to the office.</li><li> Represent class in a positive manner.</li></ul ",
    title: "Messenger",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be efficient, polite, and stay on task.</li><li>Must be able to navigate around the school.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 3,
    check: false,
    responsibilities:
      "<ul><li>Lead the class from one location to the next.</li><li>Represent class in a positive manner.</li></ul>",
    title: "Line Leader",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be a role model and uphold all hallway expectations.</li><li>Must be able to navigate around the school.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 4,
    check: false,
    responsibilities:
      "<ul><li>Update boards daily and as needed.</li><li>Support with updating bulletin boards, calendars, and decorations.</li></ul>",
    title: "Board Helper",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be creative, artistic, and have a keen eye for detail.</li><li>Passionate about interior design is a plus.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 5,
    check: false,
    responsibilities:
      "<ul><li>Record attendance and report absences to teacher daily.</li><li>Organize any homework assignments or other materials for absent students.</li><li>Provide absent students with work upon return.</li></ul>",
    title: "Attendance Monitor",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be punctual, reliable, and have good attendance.</li><li>Must be organized and salary close attention during class.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 6,
    check: false,
    responsibilities:
      "<ul><li>Turn off the lights after class.</li><li>Make sure that paper is recycled.</li><li>Water the classroom plants once per week.</li></ul>",
    title: "Environmental Specialist",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be respectful and polite to peers.</li><li>Passionate about plants is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 7,
    check: false,
    responsibilities:
      "<ul><li>Share motivational quotes with the class.</li><li>Shout out peers for positive achievements.</li></ul>",
    title: "Positivity Patrol",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be familiar with google and willing to look up quotes.</li><li>Must be caring, empathetic, and positive.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 8,
    check: false,
    responsibilities:
      "<ul><li>Plug in the computers at the end of class.</li><li>Make sure that all computers are accounted for.</li><li>Responsible for turning off lights when leaving classroom.</li></ul>",
    title: "Tech Team",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be familiar with technology procedures.</li><li>Must be careful with the technology.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 9,
    check: false,
    responsibilities:
      "<ul><li>Distribute and collect pencils to students that need to borrow one from the class.</li><li>Sharpen pencils at a time designated by the teacher.</li></ul>",
    title: "Pencil Patrol",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be organized and detail oriented.</li><li>Must follow the pencil borrowing procedures.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 10,
    check: false,
    responsibilities:
      "<ul><li>Collect homework at the end of class.</li><li>Maintain the list of who has submitted homework.</li></ul>",
    title: "Homework Helper",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be responsible.</li><li>Must have strong attendance.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 11,
    check: false,
    responsibilities:
      "<ul><li>Tidy up the classroom at the end of class.</li><li>Make sure the floors are clean and furniture is back in it's proper place.</li></ul>",
    title: "Clean-Up Crew",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be organized and detail oriented.</li><li>Must be familiar with the layout of the classroom.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 12,
    check: false,
    responsibilities:
      "<ul><li>Sign students in and out for the bathroom.</li><li>Keep track of the times students are out of the room.</li></ul>",
    title: "Bathroom Monitor",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be willing to complete work while managing responsibilities.</li><li>Must be organized.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 13,
    check: false,
    responsibilities:
      "<ul><li>Holds the door for everyone and makes sure everyone is out of the classroom.</li></ul>",
    title: "Door Holder",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must have good attendance.</li><li>Must be polite and responsible.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 14,
    check: false,
    responsibilities:
      "<ul><li>Assist in sending paychecks, charging rent, and handling paper money.</li><li>Transfer deposits from deposit slips onto bank deposit log.</li></ul>",
    title: "Banker",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be highly organized.</li><li>Must have an aptitude in math and strong attendance.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 15,
    check: false,
    responsibilities:
      "<ul><li>Keep classroom library organized and tidy.</li><li>Return books to school library when needed.</li></ul>",
    title: "Librarian",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be organized and have a strong interest in reading.</li><li>Can recommend books to classmates is a plus.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 16,
    check: false,
    responsibilities:
      "<ul><li>Help teacher with any tasks that they need assistance with.</li><li>Help teacher organize materials.</li></ul>",
    title: "Teacher Assistant",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be flexible and willing to help with a variety of tasks.</li><li>Must be organized and respectful.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 17,
    check: false,
    responsibilities:
      "<ul><li>Provide first-aid supplies and escorts student to nurse when needed.</li><li>Responsible for distributing hand sanitizer and wiping down desks.</li></ul>",
    title: "Doctor",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be caring, responsible, and empathetic.</li><li>Must know where classroom first-aid kit is.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 18,
    check: false,
    responsibilities:
      "<ul><li>Help ensure that the class is maintaining an appropriate voice volume.</li><li>Remind peers to use an inside voice.</li></ul>",
    title: "Noise Monitor",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be polite and professional.</li><li>Must speak kindly and use considerate language.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 19,
    check: false,
    responsibilities:
      "<ul><li>Responsible for passing out and collecting paper.</li><li>Help teacher with hole-punching, stapling, and filing.</li></ul>",
    title: "Paper Passer",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be efficient and responsible.</li><li>Must stay on task and not be easily distracted.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 20,
    check: false,
    responsibilities:
      "<ul><li>Step in for absent students to perform their job.</li><li>Assist the classroom substitute when teacher is out.</li></ul>",
    title: "Substitute",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must have a strong understanding of classroom roles and routines.</li><li>Must be adaptable and well-rounded.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 21,
    check: false,
    responsibilities:
      "<ul><li>Help set up and clean up classroom learning stations.</li><li>Make sure the stations are properly equipped.</li></ul>",
    title: "Station Manager",
    salary: [2, 4, 8],
    qualifications:
      "<ul><li>Must be organized.</li><li>Must be able to follow directions.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
]

export const msSuggestedJobs: ISuggestedCreateJobs[] = [
  {
    id: 1,
    check: false,
    responsibilities:
      "<ul><li>Follow all classroom and school wide expectations.</li><li>Give my best and always seek to learn and improve.</li></ul>",
    title: "Student",
    salary: [25, 50, 100],
    qualifications: "<ul><li>Must attend (Enter Your School's Name)</li></ul>",
    positions: null,
    open_for_all: true,
  },
  {
    id: 2,
    check: false,
    responsibilities:
      "<ul><li>Tidy up the classroom at the end of class.</li><li>Make sure the floors are clean and furniture is back in it's proper place.</li></ul>",
    title: "Clean-Up Crew",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must be organized and detail oriented.</li><li>Must be familiar with the layout of the classroom.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 3,
    check: false,
    responsibilities:
      "<ul><li>Plug in the computers at the end of class.</li><li>Make sure that all computers are accounted for.</li><li>Responsible for turning off lights when leaving classroom.</li></ul>",
    title: "Tech Team",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be familiar with technology procedures.</li><li>Must be careful with the technology.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 4,
    check: false,
    responsibilities:
      "<ul><li>Responsible for passing out and collecting paper and other materials.</li><li>Help teacher with hole-punching, stapling, and filing.</li></ul>",
    title: "Supplies Supervisor",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must be efficient and responsible.</li><li>Must stay on task and not be easily distracted.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 5,
    check: false,
    responsibilities:
      "<ul><li>Assist teacher in grading homework and other assignments as needed.</li><li>Organize student assignments and grades as instructed.</li></ul>",
    title: "Assistant Grader",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must have a strong attention to detail.</li><li>Must be trustworthy and sign a confidentiality agreement.</li></ul> ",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 6,
    check: false,
    responsibilities:
      "<ul><li>Help ensure that the class is maintaining an appropriate voice volume.</li><li>Remind peers to use an inside voice.</li></ul>",
    title: "Noise Monitor",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must be polite and professional.</li><li>Must speak kindly and use considerate language.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 7,
    check: false,
    responsibilities:
      "<ul><li>Help teacher with any tasks that they need assistance with.</li><li>Help teacher organize materials.</li></ul>",
    title: "Teacher Assistant",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must be flexible and willing to help with a variety of tasks.</li><li>Must be organized and respectful.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 8,
    check: false,
    responsibilities:
      "<ul><li>Collect homework at the end of class.</li><li>Maintain the list of who has submitted homework.</li></ul>",
    title: "Homework Helper",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be responsible.</li><li>Must have strong attendance.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 9,
    check: false,
    responsibilities:
      "<ul><li>Assist in sending paychecks, charging rent, and handling paper money.</li><li>Transfer deposits from deposit slips onto bank deposit log.</li></ul>",
    title: "Banker",
    salary: [30, 60, 120],
    qualifications:
      "<ul><li>Must be highly organized.</li><li>Must have an aptitude in math and strong attendance.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 10,
    check: false,
    responsibilities:
      "<ul><li>Put together a monthly podcast to share with the class.</li><li>Report on class events, news, jokes, historical events, or other topics of choice.</li></ul>",
    title: "Class Podcaster",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be good with computers and williing to learn how to work with audio programs.</li><li>Must be organized and able to budget time wisely.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 11,
    check: false,
    responsibilities:
      "<ul><li>Create short video tutorials based off what we are learning in class to help peers with class content.</li><li>Option to create supplemental materials for extra practice.</li></ul>",
    title: "Class Tutor",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be good with computers and williing to learn how to work with video programs.</li><li>Must be organized and able to budget time wisely.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 12,
    check: false,
    responsibilities:
      "<ul><li>Share motivational quotes with the class.</li><li>Shoutout peers for positive achievements and birthdays in fun ways such as decorating lockers, singing happy birthday, making a unique card or other activites determined by the Celebration Coordinator.</li></ul>",
    title: "Celebration Coordinator",
    salary: [20, 40, 80],
    qualifications:
      "<ul><li>Must be familiar with google and willing to look up quotes.</li><li>Must be creative, caring, empathetic, and positive.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 13,
    check: false,
    responsibilities:
      "<ul><li>Survey classmates on music preferences and favorite songs.</li><li>Create classroom playlist that is appropriate and update on occasion.</li></ul>",
    title: "Class DJ",
    salary: [20, 40, 80],
    qualifications:
      "<ul><li>Must be a team-player, inclusive, and have strong interview skills.</li><li>Passionate about music is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 14,
    check: false,
    responsibilities:
      "<ul><li>Help teacher design classroom space to create a positive learning environment.</li><li>Support with updating bulletin boards and decorations.</li></ul>",
    title: "Interior Designer",
    salary: [20, 40, 80],
    qualifications:
      "<ul><li>Must be creative, artistic, and have a keen eye for detail.</li><li>Passionate about interior design is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 15,
    check: false,
    responsibilities:
      "<ul><li>Deliver papers to other teachers or to the office.</li><li>Represent class in a positive manner.</li></ul>",
    title: "Messenger",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be efficient, polite, and stay on task.</li><li>Must be able to navigate around the school.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 16,
    check: false,
    responsibilities:
      "<ul><li>Keep classroom library organized and tidy.</li><li>Return books to school library when needed.</li></ul>",
    title: "Librarian",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be organized and have a strong interest in reading.</li><li>Can recommend books to classmates is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 17,
    check: false,
    responsibilities:
      "<ul><li>Step in for absent students to perform their job.</li><li>Assist the classroom substitute when teacher is out.</li></ul>",
    title: "Substitute",
    salary: [20, 40, 80],
    qualifications:
      "<ul><li>Must have a strong understanding of classroom roles and routines.</li><li>Must be adaptable and well-rounded.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 18,
    check: false,
    responsibilities:
      "<ul><li>Help organize class materials, supplies, and spaces.</li><li>Encourage and guide classmates in maintaining a tidy, joyful learning environment.</li></ul>",
    title: "Organization Specialist",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must be organized and have an understanding of the KonMari method (finding joy in belongings, organizing by category, etc.).</li><li>Must have a positive, patient demeanor when helping others with organization tasks.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 19,
    check: false,
    responsibilities:
      "<ul><li>Give motivational speeches during class assemblies or special events.</li><li>Help foster a positive, motivational atmosphere in the class.</li></ul>",
    title: "Motivational Speaker",
    salary: [20, 40, 80],
    qualifications:
      "<ul><li>Must be willing to speak in front of large groups.</li><li>Must have a positive outlook and ability to inspire others.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 20,
    check: false,
    responsibilities:
      "<ul><li>Take photographs during class activities and events.</li><li>Update the class Instagram account with engaging content and news.</li></ul>",
    title: "Class Photographer / Instagrammer",
    salary: [25, 50, 100],
    qualifications:
      "<ul><li>Must have a good eye for photography and a basic understanding of photo editing.</li><li>Must be responsible and understand the principles of digital citizenship and privacy when posting online.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
]
export const psSuggestedJobs: ISuggestedCreateJobs[] = [
  {
    id: 1,
    check: false,
    responsibilities:
      "<ul><li>Follow all classroom and school wide expectations.</li><li>Give my best and always seek to learn and improve.</li></ul>",
    title: "Student",
    salary: [250, 500, 1000],
    qualifications: "<ul><li>Must attend (Enter Your School's Name)</li></ul>",
    positions: null,
    open_for_all: true,
  },
  {
    id: 2,
    check: false,
    responsibilities:
      "<ul><li>Tidy up the classroom at the end of class.</li><li>Make sure the floors are clean and furniture is back in it's proper place.</li></ul>",
    title: "Clean-Up Crew",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must be organized and detail oriented.</li><li>Must be familiar with the layout of the classroom.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 3,
    check: false,
    responsibilities:
      "<ul><li>Plug in the computers at the end of class.</li><li>Make sure that all computers are accounted for.</li><li>Responsible for turning off lights when leaving classroom.</li></ul>",
    title: "Tech Team",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be familiar with technology procedures.</li><li>Must be careful with the technology.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 4,
    check: false,
    responsibilities:
      "<ul><li>Responsible for passing out and collecting paper and other materials.</li><li>Help teacher with hole-punching, stapling, and filing.</li></ul>",
    title: "Supplies Supervisor",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must be efficient and responsible.</li><li>Must stay on task and not be easily distracted.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 5,
    check: false,
    responsibilities:
      "<ul><li>Assist teacher in grading homework and other assignments as needed.</li><li>Organize student assignments and grades as instructed.</li></ul>",
    title: "Assistant Grader",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must have a strong attention to detail.</li><li>Must be trustworthy and sign a confidentiality agreement.</li></ul> ",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 6,
    check: false,
    responsibilities:
      "<ul><li>Help ensure that the class is maintaining an appropriate voice volume.</li><li>Remind peers to use an inside voice.</li></ul>",
    title: "Noise Monitor",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must be polite and professional.</li><li>Must speak kindly and use considerate language.</li></ul>",
    positions: 3,
    open_for_all: false,
  },
  {
    id: 7,
    check: false,
    responsibilities:
      "<ul><li>Help teacher with any tasks that they need assistance with.</li><li>Help teacher organize materials.</li></ul>",
    title: "Teacher Assistant",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must be flexible and willing to help with a variety of tasks.</li><li>Must be organized and respectful.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 8,
    check: false,
    responsibilities:
      "<ul><li>Collect homework at the end of class.</li><li>Maintain the list of who has submitted homework.</li></ul>",
    title: "Homework Helper",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be responsible.</li><li>Must have strong attendance.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 9,
    check: false,
    responsibilities:
      "<ul><li>Assist in sending paychecks, charging rent, and handling paper money.</li><li>Transfer deposits from deposit slips onto bank deposit log.</li></ul>",
    title: "Banker",
    salary: [300, 600, 1200],
    qualifications:
      "<ul><li>Must be highly organized.</li><li>Must have an aptitude in math and strong attendance.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 10,
    check: false,
    responsibilities:
      "<ul><li>Put together a monthly podcast to share with the class.</li><li>Report on class events, news, jokes, historical events, or other topics of choice.</li></ul>",
    title: "Class Podcaster",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be good with computers and williing to learn how to work with audio programs.</li><li>Must be organized and able to budget time wisely.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 11,
    check: false,
    responsibilities:
      "<ul><li>Create short video tutorials based off what we are learning in class to help peers with class content.</li><li>Option to create supplemental materials for extra practice.</li></ul>",
    title: "Class Tutor",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be good with computers and williing to learn how to work with video programs.</li><li>Must be organized and able to budget time wisely.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 12,
    check: false,
    responsibilities:
      "<ul><li>Share motivational quotes with the class.</li><li>Shoutout peers for positive achievements and birthdays in fun ways such as decorating lockers, singing happy birthday, making a unique card or other activites determined by the Celebration Coordinator.</li></ul>",
    title: "Celebration Coordinator",
    salary: [200, 400, 800],
    qualifications:
      "<ul><li>Must be familiar with google and willing to look up quotes.</li><li>Must be creative, caring, empathetic, and positive.</li></ul>",
    positions: 2,
    open_for_all: false,
  },
  {
    id: 13,
    check: false,
    responsibilities:
      "<ul><li>Survey classmates on music preferences and favorite songs.</li><li>Create classroom playlist that is appropriate and update on occasion.</li></ul>",
    title: "Class DJ",
    salary: [200, 400, 800],
    qualifications:
      "<ul><li>Must be a team-player, inclusive, and have strong interview skills.</li><li>Passionate about music is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 14,
    check: false,
    responsibilities:
      "<ul><li>Help teacher design classroom space to create a positive learning environment.</li><li>Support with updating bulletin boards and decorations.</li></ul>",
    title: "Interior Designer",
    salary: [200, 400, 800],
    qualifications:
      "<ul><li>Must be creative, artistic, and have a keen eye for detail.</li><li>Passionate about interior design is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 15,
    check: false,
    responsibilities:
      "<ul><li>Deliver papers to other teachers or to the office.</li><li>Represent class in a positive manner.</li></ul>",
    title: "Messenger",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be efficient, polite, and stay on task.</li><li>Must be able to navigate around the school.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 16,
    check: false,
    responsibilities:
      "<ul><li>Keep classroom library organized and tidy.</li><li>Return books to school library when needed.</li></ul>",
    title: "Librarian",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be organized and have a strong interest in reading.</li><li>Can recommend books to classmates is a plus.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 17,
    check: false,
    responsibilities:
      "<ul><li>Step in for absent students to perform their job.</li><li>Assist the classroom substitute when teacher is out.</li></ul>",
    title: "Substitute",
    salary: [200, 400, 800],
    qualifications:
      "<ul><li>Must have a strong understanding of classroom roles and routines.</li><li>Must be adaptable and well-rounded.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 18,
    check: false,
    responsibilities:
      "<ul><li>Help organize class materials, supplies, and spaces.</li><li>Encourage and guide classmates in maintaining a tidy, joyful learning environment.</li></ul>",
    title: "Organization Specialist",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must be organized and have an understanding of the KonMari method (finding joy in belongings, organizing by category, etc.).</li><li>Must have a positive, patient demeanor when helping others with organization tasks.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 19,
    check: false,
    responsibilities:
      "<ul><li>Give motivational speeches during class assemblies or special events.</li><li>Help foster a positive, motivational atmosphere in the class.</li></ul>",
    title: "Motivational Speaker",
    salary: [200, 400, 800],
    qualifications:
      "<ul><li>Must be willing to speak in front of large groups.</li><li>Must have a positive outlook and ability to inspire others.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
  {
    id: 20,
    check: false,
    responsibilities:
      "<ul><li>Take photographs during class activities and events.</li><li>Update the class Instagram account with engaging content and news.</li></ul>",
    title: "Class Photographer / Instagrammer",
    salary: [250, 500, 1000],
    qualifications:
      "<ul><li>Must have a good eye for photography and a basic understanding of photo editing.</li><li>Must be responsible and understand the principles of digital citizenship and privacy when posting online.</li></ul>",
    positions: 1,
    open_for_all: false,
  },
]
