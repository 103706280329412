import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { IStudentAuth } from "ts/interfaces/Student"

import { IAssignGoogleArgs } from "./assignGoogle.types"

export const useMutationAssignGoogle = (args?: IAssignGoogleArgs) =>
  useMutation<AxiosResponse<IStudentAuth>, AxiosError, Record<string, unknown>>(
    async (params) => {
      return await axiosInstance.post(Endpoint.STUDENTS_ASSIGN, undefined, {
        params,
      })
    },
    args?.option
  )
