import React from "react"

import { Box, Typography } from "@mui/material"
import CheckboxField from "components/form/common/field/checkboxField"
import { useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { POLICY, TERMS } from "../SignAsTeacher.config"
import { ErrorStyle } from "../SignAsTeacher.styles"

interface IProps {
  onClick: () => void
  name: string
  error: string
}

const CheckboxTerms = ({ onClick, name, error }: IProps) => {
  const methods = useFormContext()
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 7, textAlign: "left" }}>
      <CheckboxField
        onClick={onClick}
        name={name}
        sx={(theme) => ({
          color:
            methods.formState.errors?.terms || !!error
              ? `${theme.palette.mockup.error50}`
              : "",
          backgroundColor: theme.palette.mockup.neutral100,
        })}
        label={
          <Typography
            variant="body2"
            sx={(theme) => ({
              fontWeight: "500",
              color: theme.palette.mockup.neutral0,
            })}
          >
            <Trans
              i18nKey="noAuthFlow.bySigningUpYouAgreeToClassBanksTermsOfServiceAndPrivacyPolicy"
              components={{
                Link1: TERMS,
                Link2: POLICY,
              }}
            />
          </Typography>
        }
      />
      <ErrorStyle>
        {(methods.formState.errors?.terms || !!error) &&
          t("validations.checkTerms")}
      </ErrorStyle>
    </Box>
  )
}

export default CheckboxTerms
