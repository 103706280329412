import { FC, ReactNode } from "react"

import {
  Box,
  Checkbox,
  CheckboxProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import Coin from "components/common/icon/coin"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { useTranslation } from "react-i18next"

import ItemWrapper from "../itemWrapper"

interface IProps {
  checkboxProps?: CheckboxProps
  textTitle: string
  text: string
  value: number
  actionButtonsSlot: ReactNode
  active?: boolean
  quantity_in_stock?: number | null
}

const SecondaryCheckableItem: FC<IProps> = ({
  checkboxProps,
  text,
  textTitle,
  value,
  actionButtonsSlot,
  active = true,
  quantity_in_stock,
}) => {
  const { t } = useTranslation()

  return (
    <ItemWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Box
          ml="-4px"
          py="8px"
          pr="16px"
          borderRight={(theme) => `1px solid ${theme.palette.mockup.primary95}`}
        >
          <Checkbox {...checkboxProps} />
        </Box>
        <Stack width="100%" minWidth="0" sx={{ opacity: active ? 1 : 0.5 }}>
          <Typography variant="body3" color="mockup.neutral40" fontWeight={500}>
            {textTitle}
          </Typography>
          <Tooltip title={text}>
            <Typography
              variant="body2"
              color="mockup.neutral20"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              fontWeight={500}
            >
              {text}
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Stack direction="row" columnGap={2}>
          <Stack flexBasis="max-content" sx={{ opacity: active ? 1 : 0.5 }}>
            <Typography
              variant="body3"
              color="mockup.neutral40"
              fontWeight={500}
              textAlign="start"
            >
              {t("value")}
            </Typography>
            <Coin amount={`${value}`} />
          </Stack>
          {!userIsFreeDemoSchoolPlan() && (
            <Stack
              alignItems="center"
              flexBasis="max-content"
              sx={{ opacity: active ? 1 : 0.5 }}
            >
              <Typography
                variant="body3"
                color="mockup.neutral40"
                fontWeight={500}
                textAlign="start"
              >
                {t("store.quantity")}
              </Typography>
              <Typography lineHeight="24px" color="mockup.neutral10">
                {quantity_in_stock}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Box>{actionButtonsSlot}</Box>
      </Stack>
    </ItemWrapper>
  )
}

export default SecondaryCheckableItem
