import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import { IAccountTransfer, IAccountTransferArgs } from "./accountTransfer.types"

export const useMutationAccountTransfer = (args?: IAccountTransferArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IAccountTransfer>(
    async (data) => {
      return axiosInstance.post(Endpoint.STUDENT_ACCOUNT_TRANSFER, data)
    },
    { ...args?.options }
  )
