import { Endpoint } from "api"
import { TransactionTypeShort } from "ts/types/Transaction"

export const classTransactionItemsEndpoints: Record<
  TransactionTypeShort,
  Endpoint
> = {
  bonus: Endpoint.CLASSES_BONUSES,
  expense: Endpoint.CLASSES_EXPENSES,
  fine: Endpoint.CLASSES_FINES,
}
