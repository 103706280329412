import { FC, useCallback, useEffect } from "react"

import { ReactComponent as IconSearch } from "@common_assets/svg/search.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, debounce, InputAdornment } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router"

import TextField from "../common/field/textField"
import { defaultValues, validationSchema } from "./SearchbarForm.config"
import { IProps, ISearchbarFormState } from "./SearchbarForm.types"

const SearchbarForm: FC<IProps> = ({ onSubmit, placeholder, label }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const param = useParams()
  const methods = useForm<ISearchbarFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const onChangeFunc = useCallback(
    debounce(() => {
      methods.handleSubmit(onSubmit)()
    }, 500),
    []
  )

  useEffect(() => {
    if (location.state !== null) {
      methods.reset()
    }
  }, [param.classId])

  return (
    <Box
      width="100%"
      component="form"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <TextField
          name="search"
          label={label}
          placeholder={placeholder || t("search")}
          size="small"
          customOnChange={(e, onChange) => {
            onChange(e)
            onChangeFunc()
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconWrapper>
                  <IconSearch />
                </IconWrapper>
              </InputAdornment>
            ),
          }}
        />
      </FormProvider>
    </Box>
  )
}

export default SearchbarForm
