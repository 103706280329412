import { ThemeOptions } from "@mui/material"

const muiDialog: ThemeOptions["components"] = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: "20px",
        width: "calc(100% - 40px)",
      },
    },
  },
}
export default muiDialog
