import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  ICopySettingsArgs,
  ICopySettingsCheckArg,
  IPostCopySettings,
  IPostCopySettingsCheck,
  IResponseCopySettings,
  IResponseCopySettingsCheck,
} from "./classesCopySettings.types"

export const useMutationCopySettings = (args: ICopySettingsArgs) =>
  useMutation<
    AxiosResponse<IResponseCopySettings>,
    AxiosError,
    IPostCopySettings
  >(async (data) => {
    return await axiosInstance.post(Endpoint.CLASSES_COPY_SETTINGS, data)
  }, args?.options)

export const useMutationCopySettingsCheck = (args: ICopySettingsCheckArg) =>
  useMutation<
    AxiosResponse<IResponseCopySettingsCheck>,
    AxiosError,
    IPostCopySettingsCheck
  >(async (data) => {
    return await axiosInstance.post(Endpoint.CLASSES_COPY_SETTINGS_CHECK, data)
  }, args?.options)
