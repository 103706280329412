import { numberSchema, nullableStringSchema } from "utils/yup"
import * as yup from "yup"

import { ITransactionsData } from "./Transactions.types"

export const transactionsDefaultValue: ITransactionsData = {
  bonuses: [],
  bonusTitle: "",
  bonusValue: "",
  fines: [],
  fineTitle: "",
  fineValue: "",
  paychecks: [],
  paycheckTitle: "",
  paycheckValue: "",
  expenses: [],
  expenseTitle: "",
  expenseValue: "",
}

export const transactionsSchema = yup.object({
  bonusTitle: nullableStringSchema.min(2).max(100),
  bonusValue: numberSchema
    .positive()
    .max(500000)
    .when("bonusTitle", {
      is: (bonusTitle: string) => !!bonusTitle,
      then: (schema) => schema.required(),
    }),
  fineTitle: nullableStringSchema.min(2).max(100),
  fineValue: numberSchema
    .moreThan(-1, "please enter only positive number or zero")
    .max(500000)
    .when("fineTitle", {
      is: (fineTitle: string) => !!fineTitle,
      then: (schema) => schema.required(),
    }),
  paycheckTitle: nullableStringSchema.min(2).max(100),
  paycheckValue: numberSchema
    .positive()
    .max(500000)
    .when("paycheckTitle", {
      is: (paycheckTitle: string) => !!paycheckTitle,
      then: (schema) => schema.required(),
    }),
  expenseTitle: nullableStringSchema.min(2).max(100),
  expenseValue: numberSchema
    .positive()
    .max(500000)
    .when("expenseTitle", {
      is: (expenseTitle: string) => !!expenseTitle,
      then: (schema) => schema.required(),
    }),
})
