import { Box, Tooltip, Typography } from "@mui/material"
import TransactionActionButtons from "components/common/button/transactionActionButtons"
import Coin from "components/common/icon/coin"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useQueryClient } from "react-query"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ITodo } from "ts/interfaces/Todo"

interface IProps {
  data: ITodo[]
}

const TodoBody = ({ data }: IProps) => {
  const queryClient = useQueryClient()

  return (
    <TransitionGroup component={null}>
      {data?.map((todo) => (
        <CSSTransition
          key={todo.id}
          classNames="animation-fade"
          timeout={700}
          unmountOnExit
        >
          <BasicTable.Row key={todo.id}>
            <BasicTable.Cell>
              <Typography variant="body1" color="mockup.neutral10">
                {`${todo.first_name} ${todo.last_name}`}
              </Typography>
            </BasicTable.Cell>
            <BasicTable.Cell position="relative">
              <Tooltip placement="top" title={todo.title}>
                <Typography
                  variant="body2"
                  color="mockup.neutral10"
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    position: "absolute",
                    width: "100%",
                    paddingLeft: "12px",
                    paddingRight: "24px",
                    verticalAlign: "center",
                  }}
                >
                  {todo.title}
                </Typography>
              </Tooltip>
            </BasicTable.Cell>
            <BasicTable.Cell justifyContent="center">
              <Coin amount={`${todo.value}`} />
            </BasicTable.Cell>
            <BasicTable.Cell>
              <Box px="4px">
                <TransactionActionButtons
                  id={todo.id}
                  onSuccessCallback={() => {
                    queryClient.invalidateQueries("dashboardTodo")
                  }}
                />
              </Box>
            </BasicTable.Cell>
          </BasicTable.Row>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default TodoBody
