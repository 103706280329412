import React, { Dispatch, SetStateAction, useEffect } from "react"

import {
  Autocomplete,
  Box,
  Button,
  debounce,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { IResponseGetSchools } from "api/reactQuery/queries/getSchools.types"
import { ReactComponent as Arrow } from "assets/common/svg/arrowBack.svg"
import { useTranslation } from "react-i18next"
import { DropdownStyles } from "styles/common/autocomplete"
import { IAutocompleteProps } from "ts/interfaces/Input"

interface AutocompleteFindProps {
  state: IAutocompleteProps | null
  setState: Dispatch<SetStateAction<IAutocompleteProps | null>>
  isError: boolean
  data: IResponseGetSchools | undefined
  userPost: string
  handleOpenCreateSchool: () => void
  setUserPost: Dispatch<SetStateAction<string>>
  isLoading: boolean
  onClickNextSkip: () => void
  onClickPrevSkip: () => void
  skip: number
  setSkip: Dispatch<SetStateAction<number>>
}

const AutocompleteFindSchool = ({
  state,
  setState,
  isError,
  data,
  userPost,
  handleOpenCreateSchool,
  setUserPost,
  isLoading,
  onClickNextSkip,
  onClickPrevSkip,
  skip,
  setSkip,
}: AutocompleteFindProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (state !== null) {
      setSkip(0)
    }
  }, [state])

  return (
    <Autocomplete
      value={state}
      onChange={(e, val) => {
        setState(val)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          helperText={isError && t("validations.fieldIsRequired")}
          label={`${t("dashboard.searchSchool")}...`}
          error={isError}
        />
      )}
      options={data?.objects || []}
      noOptionsText={
        !userPost?.length && !data?.objects.length ? (
          <Box sx={{ textAlign: "center" }}>
            {`${t("dashboard.startTypingtoFindTSchool")}...`}
          </Box>
        ) : userPost && userPost.length > 0 && !data?.objects.length ? (
          <Box
            onClick={handleOpenCreateSchool}
            sx={(theme) => ({
              textAlign: "center",
              border: "3px solid transparent",
              textTransform: "uppercase",
              color: `${theme.palette.primary.main}`,
              cursor: "pointer",
              boxShadow: "0px 0px 0px rgba(210, 218, 249, 0.25)",
            })}
          >
            {t("dashboard.dontSeeYourSchoolAddItHere")}
          </Box>
        ) : (
          ""
        )
      }
      filterOptions={(options) => options}
      getOptionLabel={(option: IAutocompleteProps) => `${option.name}`}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onInputChange={debounce((e, val, reason) => {
        setUserPost(val)
        if (reason === "clear") {
          setState(null)
        }
        if (reason === "reset") {
          setSkip(0)
        }
      }, 500)}
      loading={isLoading}
      renderOption={(props, originalOption) => {
        const option = originalOption as IAutocompleteProps
        const idArray = props?.id?.split("-")
        const index = (idArray ? idArray[3] : undefined) as undefined | string
        return (
          <React.Fragment key={option.id}>
            <li
              {...props}
              style={{
                position: "relative",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Stack>
                  <Typography>{option.name}</Typography>
                  <Typography variant="body2" color="mockup.neutral60">
                    {option.district}
                  </Typography>
                </Stack>
                <Typography variant="body2" color="mockup.neutral60">
                  {option.zip_code}
                </Typography>
              </Box>
            </li>
            {data && index === `${data?.objects.length - 1}` && (
              <Box pt={1}>
                {!skip && data?.objects.length !== 20 ? null : (
                  <Box display="flex" justifyContent="space-around">
                    <Button
                      style={{ height: "10px" }}
                      onClick={onClickPrevSkip}
                      disabled={skip === 0}
                    >
                      <Arrow />
                    </Button>
                    <Button
                      disabled={data.objects.length !== 20}
                      onClick={onClickNextSkip}
                      style={{ transform: "rotate(180deg)", height: "10px" }}
                    >
                      <Arrow />
                    </Button>
                  </Box>
                )}
                {((data?.all_objects_count > 40 && skip >= 40) ||
                  (data?.all_objects_count <= 40 && skip >= 20) ||
                  (data?.all_objects_count <= 20 && skip < 20)) && (
                  <DropdownStyles onClick={handleOpenCreateSchool}>
                    {t("noAuthFlow.dontSeeYourSchoolAddItHere")}
                  </DropdownStyles>
                )}
              </Box>
            )}
          </React.Fragment>
        )
      }}
    />
  )
}
export default AutocompleteFindSchool
