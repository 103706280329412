import { FC, useEffect, useState } from "react"

import { ReactComponent as IconAdd } from "@common_assets/svg/plus.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Stack } from "@mui/material"
import { useMutationPostClassesStudents } from "api/reactQuery/mutations/classesStudents"
import { useQueryClassesCandidates } from "api/reactQuery/queries/classesCandidates"
import Dialog from "components/common/dialog/dialog"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { FormProvider, get, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectUser } from "store/auth/auth.selectors"
import {
  selectExistingStudent,
  selectNewStudents,
} from "store/utility/utility.selectors"
import {
  setClassList,
  setExisting,
  setNewStudents,
  setPage,
} from "store/utility/utility.slice"
import { IStudentCandidate } from "ts/interfaces/Student"
import { getErrorMessage } from "utils/api"
import { isSchoolPlan } from "utils/roleCheck"

import DialogClose from "./dialogClose"
import {
  defaultValues,
  emptyStudentValue,
  validationSchema,
} from "./DialogFormStudentsInfo.config"
import { IFormState } from "./DialogFormStudentsInfo.types"
import ExistingStudentFields from "./existingStudentFields"
import NewStudentFields from "./newStudentFields"
import StudentsSearch from "./studentsSearch"

interface IProps {
  isOpen: boolean
  handleClose: () => void
  refetch: () => void
}

const DialogFormStudentsInfo: FC<IProps> = ({
  isOpen,
  handleClose,
  refetch,
}) => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const classId = useClassId()
  const newStudents = useAppSelector(selectNewStudents)
  const exist = useAppSelector(selectExistingStudent)
  const isTablet = useIsBreakpointUp("tablet")
  const userInfo = useAppSelector(selectUser)
  const [isChangeEventType, setIsChangeEventyType] = useState(false)

  useEffect(() => {
    isOpen && localStorage.setItem("isOpenInfo", JSON.stringify(true))
  }, [isOpen])

  const [existingStudents, setExistingStudents] =
    useState<IStudentCandidate[]>(exist)

  const { data, isLoading, isError } = useQueryClassesCandidates({
    classId: Number(classId),
  })

  useEffect(() => {
    if (isChangeEventType) {
      mixpanel.track("add_students", {
        action: "search_student",
      })
    }
  }, [isChangeEventType])

  const { isLoading: isLoadingPost, mutate: postStudents } =
    useMutationPostClassesStudents({
      options: {
        onSuccess: (_, variables) => {
          if (!!window.Intercom) {
            window.Intercom("trackEvent", "number of students added", {
              classId: classId,
              students:
                variables.data.existing_student_ids.length +
                variables.data.new_students.length,
            })
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "add_students",
            allStudents:
              variables.data.existing_student_ids.length +
              variables.data.new_students.length,
          })

          showSnackbar({
            title: t(
              variables.data.existing_student_ids.length +
                variables.data.new_students.length <=
                1
                ? "students.studentAddedSuccessfully"
                : "students.studentsAddedSuccessfully"
            ),
          })
          queryClient.invalidateQueries(["classesStudents", Number(classId)])
          queryClient.invalidateQueries(["classesCandidates", Number(classId)])
          handleClose()
          localStorage.setItem("isOpenInfo", JSON.stringify(false))
          dispatch(setPage(0))
          dispatch(setClassList([]))
          dispatch(setNewStudents([]))
          dispatch(setExisting([]))
          setExistingStudents([])
          methods.reset()
          refetch()
        },
        onError: (error) => {
          const errorDetail = get(error, "response.data.detail", null)
          if (Array.isArray(errorDetail)) {
            errorDetail.forEach((err) => {
              if (typeof err === "object") {
                const errorIndex = get(err, "loc[2]", null)
                if (errorIndex !== null) {
                  methods.setError(`students.${errorIndex}.userName`, {
                    message: err.msg,
                  })
                }
              }
            })
          } else {
            showSnackbar({
              title: getErrorMessage(error),
              variant: "error",
            })
          }
        },
      },
    })

  const filteredExistingData = data
    ? [...data.data].filter(
        (el) => !existingStudents.some((student) => student.id === el.id)
      )
    : []

  const {
    isOpen: isOpenCloseConfirm,
    handleOpen: handleOpenCloseConfirm,
    handleClose: handleCloseCloseConfirm,
  } = useDialog()

  const methods = useForm<IFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const {
    fields: studentFields,
    append: appendStudent,
    remove: removeStudent,
  } = useFieldArray({
    control: methods.control,
    name: "students",
  })

  const onSubmit = (data: IFormState) => {
    if (!existingStudents.length && !data.students.length) {
      showSnackbar({
        title: t("students.youNeedToAddAtLeastOneStudent"),
        variant: "error",
      })
      return
    }
    postStudents({
      classId: Number(classId),
      data: {
        existing_student_ids: existingStudents.map((student) => student.id),
        new_students: data.students.map((student) => ({
          first_name: student.firstName,
          last_name: student.lastName,
          login: student.userName,
          password: student.password,
        })),
      },
    })
  }

  useEffect(() => {
    methods.setValue("students", newStudents)
  }, [newStudents])

  useEffect(() => {
    dispatch(setExisting(existingStudents))
  }, [existingStudents])

  useEffect(() => {
    return () => {
      dispatch(setNewStudents(methods.getValues("students")))
    }
  }, [isTablet])

  return (
    <>
      <Dialog
        titleText={t("students.addStudent")}
        open={
          isOpen || JSON.parse(localStorage.getItem("isOpenInfo") ?? "false")
        }
        onActionButtonClick={() => {
          methods.clearErrors()
          const students = methods.getValues("students")
          const userNames = new Set(students.map((student) => student.userName))
          if (userNames.size !== students.length) {
            showSnackbar({
              variant: "error",
              title: t("students.userNamesHaveToBeUnique"),
            })
            return
          }
          methods.handleSubmit(onSubmit)()
        }}
        onClose={handleOpenCloseConfirm}
        actionAcceptText={t("students.addToClass")}
        actionAcceptButtonProps={{
          disabled: isLoading,
        }}
        tabletMaxWidth="867px"
        desktopMaxWidth="867px"
        isLoading={isLoadingPost}
      >
        <Box
          width="100%"
          component="form"
          borderTop={(theme) => `1px solid ${theme.palette.mockup.primary95}`}
          pt="24px"
        >
          {userInfo && isSchoolPlan(userInfo) && (
            <StudentsSearch
              setIsChange={setIsChangeEventyType}
              onChange={(value) => {
                if (!value) return
                setExistingStudents([...existingStudents, value])
                mixpanel.track("add_students", {
                  action: "select_student",
                })
              }}
              onAddNewStudentClick={() => {
                appendStudent(emptyStudentValue)
              }}
              options={filteredExistingData}
              isLoading={isLoading}
              isError={isError}
            />
          )}
          <FormProvider {...methods}>
            <Stack gap={{ mobile: "16px", tablet: "32px" }}>
              {existingStudents.map((student) => (
                <ExistingStudentFields
                  key={student.id}
                  student={student}
                  onDeleteClick={() => {
                    setExistingStudents([
                      ...existingStudents.filter(
                        (existingStudent) => existingStudent.id !== student.id
                      ),
                    ])
                    mixpanel.track("add_students", {
                      action: "trash_icon",
                    })
                  }}
                />
              ))}
              {studentFields.map((field, index) => (
                <NewStudentFields
                  key={field.id}
                  index={index}
                  onDeleteClick={() => {
                    removeStudent(index)
                    mixpanel.track("add_students", {
                      action: "trash_icon",
                    })
                  }}
                />
              ))}
              <Button
                startIcon={<IconAdd />}
                variant="text"
                sx={{ width: "max-content" }}
                onClick={() => {
                  appendStudent(emptyStudentValue)
                  mixpanel.track("add_students", {
                    event_name: "add_another_student",
                    action: "open_form",
                  })
                }}
              >
                {t("students.addAnotherStudent")}
              </Button>
            </Stack>
          </FormProvider>
        </Box>
      </Dialog>
      <DialogClose
        open={isOpenCloseConfirm}
        onClose={handleCloseCloseConfirm}
        onAccept={() => {
          methods.reset()
          localStorage.setItem("isOpenInfo", JSON.stringify(false))
          dispatch(setNewStudents([]))
          dispatch(setExisting([]))
          setExistingStudents([])
          handleCloseCloseConfirm()
          handleClose()
          mixpanel.track("add_students", {
            event_name: "student_info",
            action: "cancel",
          })
        }}
      />
    </>
  )
}

export default DialogFormStudentsInfo
