import { FC, ReactNode, useState } from "react"

import { ReactComponent as SvgInvoice } from "@brand_assets/svg/invoice.svg"
import { ReactComponent as IconExport } from "@common_assets/svg/export.svg"
import { Box, CircularProgress, Tooltip, useTheme } from "@mui/material"
import { useQueryClassesStoreTransactions } from "api/reactQuery/queries/classesStoreTransactions"
import { useQueryExportClassStoreTransactions } from "api/reactQuery/queries/export"
import { AxiosResponse } from "axios"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import ErrorText from "components/common/error/errorText"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import SearchbarForm from "components/form/searchbarForm"
import { useBlobData } from "hooks/blobData"
import { useDialog } from "hooks/dialog"
import { useSelectedItems, useSorting } from "hooks/listing"
import { useFilterMultipleItems } from "hooks/listing/filtering"
import { useClassId } from "hooks/navigation"
import { useCustomPayment } from "hooks/payment"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { selectUser } from "store/auth/auth.selectors"
import { selectClassname } from "store/utility/utility.selectors"
import { ExportIconWrapper } from "styles/common/box/Box.styles"
import { PrimaryTile } from "styles/common/tile"
import { ITransactionItemClass } from "ts/interfaces/Transaction"
import { getErrorMessageFromTab } from "utils/api"
import { schoolPlanToExportData } from "utils/export"

import BatchActionButtons from "./batchActionButtons"

interface IProps {
  renderDataView: (data: {
    apiData: AxiosResponse<ITransactionItemClass[], unknown> | undefined
    sortingProps: ReturnType<typeof useSorting<ITransactionItemClass>>
    selectingProps: ReturnType<typeof useSelectedItems<ITransactionItemClass>>
    loading?: boolean
  }) => ReactNode
}

const ViewCommonElementsContainer: FC<IProps> = ({ renderDataView }) => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const classId = useClassId()
  const theme = useTheme()
  const classname = useAppSelector(selectClassname)
  const userInfo = useAppSelector(selectUser)
  const [isExportData, setIsExportData] = useState(false)
  const [loader, setLoader] = useState(false)

  const { data, isLoading, isError } = useQueryClassesStoreTransactions({
    classId: Number(classId),
  })

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { isOpen, handleOpen, handleClose } = useDialog()

  const { refetch, isFetching } = useQueryExportClassStoreTransactions({
    classId: Number(classId),
    options: {
      enabled: isExportData,
      cacheTime: 0,
      onSuccess: (response) => {
        setIsExportData(false)
        showSnackbar({
          title: t("store.dataHasBeenSuccessfullyExported"),
        })
        useBlobData({
          response: response.data,
          classname,
          exportData: t("jobs.storeTransactions"),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { searchFilter, setSearchFilter, filteredMultiData } =
    useFilterMultipleItems({ data: data ? data.data : [] })

  const sortingProps = useSorting({
    data: filteredMultiData,
  })
  const { sortedData } = sortingProps

  const selectingProps = useSelectedItems(sortedData)
  const { selectedItems, clearSelectedItems } = selectingProps

  return (
    <PrimaryTile sx={{ borderRadius: "0px 8px 8px 8px", overflow: "hidden" }}>
      <Box p="16px" pb="24px" display="flex" columnGap={2}>
        <SearchbarForm
          placeholder={`${t("store.searchForTransaction")}...`}
          onSubmit={(data) => setSearchFilter(data.search)}
        />
        <DialogUpgradeAccount
          open={isOpen}
          onClose={() => {
            handleClose()
            mixpanel.track("reports_export", {
              action: "cancel",
            })
          }}
          onActionButtonClick={() => {
            payIt(null)
            mixpanel.track("reports_export", {
              method: "class_store_transactions",
              action: "submit",
            })
          }}
          isLoading={isLoadingPayments}
          isExport
        />
        <Tooltip title={t("store.exportData")} placement="top">
          <ExportIconWrapper
            style={{
              height: "48px",
              minWidth: "48px",
              pointerEvents: isFetching ? "none" : "all",
              opacity: isFetching ? "0.7" : "1",
            }}
            onClick={() => {
              if (isFetching) return
              schoolPlanToExportData({
                user: userInfo,
                upgrade: handleOpen,
                exportData: refetch,
              })
            }}
          >
            <span style={{ margin: "auto" }}>
              {isFetching ? (
                <CircularProgress />
              ) : (
                <IconExport
                  style={{
                    color: theme.palette.primary.main,
                    margin: "auto",
                  }}
                />
              )}
            </span>
          </ExportIconWrapper>
        </Tooltip>
      </Box>
      <BatchActionButtons
        selectedItems={selectedItems}
        clearSelectedItems={clearSelectedItems}
        setLoader={setLoader}
        sortedData={sortedData}
      />

      {renderDataView({
        apiData: data,
        selectingProps,
        sortingProps,
        loading: loader,
      })}
      {isLoading && <TableSkeleton />}
      {isError && <ErrorText />}
      {!isLoading &&
        data?.data &&
        data?.data.length > 0 &&
        sortedData.length === 0 && <NoMatchesForSearch search={searchFilter} />}
      {!isLoading && data?.data?.length === 0 && (
        <NoElementMessageWithoutBtn
          Icon={<SvgInvoice />}
          title={t("store.youDoNotHaveAnyTasksToDo")}
          description={t("store.greatWorkStayingOnTop")}
        />
      )}
    </PrimaryTile>
  )
}

export default ViewCommonElementsContainer
