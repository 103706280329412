import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  ICreateSchoolArgs,
  IPostCreateSchool,
  IResponseCreateSchool,
} from "./createSchool.types"

export const useMutationCreateSchool = (args?: ICreateSchoolArgs) =>
  useMutation<
    AxiosResponse<IResponseCreateSchool>,
    AxiosError,
    IPostCreateSchool
  >(async (data) => {
    return await axiosInstance.post(Endpoint.SCHOOLS, data)
  }, args?.options)
