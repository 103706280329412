import { stringSchema } from "utils/yup"
import * as yup from "yup"

export const pinFieldDefaultValues = {
  c1: "",
  c2: "",
  c3: "",
  c4: "",
  c5: "",
  c6: "",
}

export const pinFieldSchema = yup.object({
  c1: stringSchema.required(""),
  c2: stringSchema.required(""),
  c3: stringSchema.required(""),
  c4: stringSchema.required(""),
  c5: stringSchema.required(""),
  c6: stringSchema.required(""),
})
