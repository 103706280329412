import { FC } from "react"

import { Stack } from "@mui/material"

const FieldsWrapper: FC = ({ children }) => {
  return (
    <Stack
      direction={{ mobile: "column-reverse", tablet: "row" }}
      gap={{ mobile: "16px", tablet: "8px" }}
      alignItems="center"
      border={(theme) => ({
        mobile: `${theme.palette.primary.light} 2px solid`,
        tablet: "none",
      })}
      borderRadius={{ mobile: "24px", tablet: "0" }}
      p={{ mobile: "16px 16px 24px", tablet: "0" }}
    >
      {children}
    </Stack>
  )
}

export default FieldsWrapper
