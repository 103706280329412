export const validations = {
  anErrorOccured: "An error occured",
  failedToFetchData: "Failed to fetch the data",
  fieldIsRequired: "The field is required",
  valueMustBeNumber: "The value must be a number",
  enterInteger: "Enter an integer",
  enterNegativeNumber: "Enter a negative number",
  enterPositiveNumber: "Enter a positive number",
  enterValidEmail: "Enter a valid email",
  enterValidDate: "Enter a valid date",
  enterMaxChars_one: "Enter up to {{count}} character",
  enterMaxChars_other: "Enter up to {{count}} characters",
  enterMinChars_one: "Enter at least {{count}} character",
  enterMinChars_other: "Enter at least {{count}} characters",
  maxNumberIs: "The maximum number is {{count}}",
  minNumberIs: "The minimum number is {{count}}",
  selectRole: "Choose the role",
  checkTerms: "Please accept terms and private policy",
  enterRegisterPassword:
    "Must be at least 7 characters, including at least 1 upper case, 1 lower case, and one special character (~!@#)",
  atLeast6CharsAndDigit: "At least 6 characters",
  alreadyExistEmail: "Teacher with given email already exists",
  enterFirstName: "First name is required",
  enterLastName: "Last name is required",
  enterEmail: "E-mail address is required",
  enterPassword: "Password is required",
  invalidEmail: "Incorrect email or password",
  selectSchool: "Please select a school",
  somethingWrong: "Something went wrong. Please try again",
  enoughPositions: "Not enough free slots",
  thisSamePerson: "{{first}} {{last}} has been hired",
  newAccountBalanceCalculatedIncorrectly:
    "The new account balance was calculated incorrectly",
  doubleCheckYourMath: "Double check your math!",
  userNameAlreadyUsed: "User name already used",
  enterAllCodeFields: "Enter all code fields",
  ooopsWeCantFindThatPage: "Ooops! We can't find that page.",
  thisPageIsMissingOrYouAssembledTheLinkIncorrectly:
    "This page is missing or you assembled the link incorrectly.",
  goBack: "Go back",
  theSalaryShouldBeADecimalWithMaximumTwoDigitsAfterComma:
    "The salary should be a decimal with maximum two digits after comma",
  serverTemporarilyUnAvailable: "Server temporarily unavailable",
  classEquityIsOverCapacity:
    "ClassEquity is over capacity and we’re working on getting things back up and running. Check back soon! ",
  needToPivotYourLessonsPlan:
    "Need to pivot your lessons plan? Check out the <Link>Personal Finance Arcade</Link> from NGPF!",
}
