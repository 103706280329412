import { TransactionTypeShort } from "ts/types/Transaction"

const translationKeysExpense = {
  items: "setup.bills",
  searchForItem: "setup.searchForBills",
  addItem: "setup.addBill",
  addNewItem: "setup.addNewBill",
  editItem: "setup.editBill",
  deleteItem: "setup.deleteBill",
  youDontHaveAnyItemsYet: "setup.youDontHaveAnyBillsYet",
  youCanChooseFromOurSuggestedItems: "setup.youCanChooseFromOurSuggestedBills",
  addSuggestedItems: "setup.addSuggestedBills",
  itemsAscending: "setup.billsAscending",
  itemsDescending: "setup.billsDescending",
  itemHasBeenAdded: "setup.billHasBeenAdded",
  itemHasBeenEdited: "setup.billHasBeenEdited",
  itemHasBeenDeleted: "setup.billHasBeenDeleted",
  areYouSureYouWantToDeleteItem: "setup.areYouSureYouWantToDeleteBill",
  itemTitle: "setup.billTitle",
  suggestedItemsHaveBeenAdded: "setup.suggestedBillsHaveBeenAdded",
  titleShouldBeUnique: "setup.billTitleShouldBeUnique",
}

const translationKeysBonus = {
  items: "setup.bonuses",
  searchForItem: "setup.searchForBonuses",
  addItem: "setup.addBonus",
  addNewItem: "setup.addNewBonus",
  editItem: "setup.editBonus",
  deleteItem: "setup.deleteBonus",
  youDontHaveAnyItemsYet: "setup.youDontHaveAnyBonusesYet",
  youCanChooseFromOurSuggestedItems:
    "setup.youCanChooseFromOurSuggestedBonuses",
  addSuggestedItems: "setup.addSuggestedBonuses",
  itemsAscending: "setup.bonusesAscending",
  itemsDescending: "setup.bonusesDescending",
  itemHasBeenAdded: "setup.bonusHasBeenAdded",
  itemHasBeenEdited: "setup.bonusHasBeenEdited",
  itemHasBeenDeleted: "setup.bonusHasBeenDeleted",
  areYouSureYouWantToDeleteItem: "setup.areYouSureYouWantToDeleteBonus",
  itemTitle: "setup.bonusTitle",
  suggestedItemsHaveBeenAdded: "setup.suggestedBonuesHaveBeenAdded",
  titleShouldBeUnique: "setup.bonusTitleShouldBeUnique",
}

const translationKeysFine = {
  items: "setup.fines",
  searchForItem: "setup.searchForFines",
  addItem: "setup.addFine",
  addNewItem: "setup.addNewFine",
  editItem: "setup.editFine",
  deleteItem: "setup.deleteFine",
  youDontHaveAnyItemsYet: "setup.youDontHaveAnyFinesYet",
  youCanChooseFromOurSuggestedItems: "setup.youCanChooseFromOurSuggestedFines",
  addSuggestedItems: "setup.addSuggestedFines",
  itemsAscending: "setup.finesAscending",
  itemsDescending: "setup.finesDescending",
  itemHasBeenAdded: "setup.fineHasBeenAdded",
  itemHasBeenEdited: "setup.fineHasBeenEdited",
  itemHasBeenDeleted: "setup.fineHasBeenDeleted",
  areYouSureYouWantToDeleteItem: "setup.areYouSureYouWantToDeleteFine",
  itemTitle: "setup.fineTitle",
  suggestedItemsHaveBeenAdded: "setup.suggestedFinesHaveBeenAdded",
  titleShouldBeUnique: "setup.fineTitleShouldBeUnique",
}

export const variantTranslationObj: Record<
  TransactionTypeShort,
  Record<
    | keyof typeof translationKeysExpense
    | keyof typeof translationKeysBonus
    | keyof typeof translationKeysFine,
    string
  >
> = {
  expense: translationKeysExpense,
  bonus: translationKeysBonus,
  fine: translationKeysFine,
}
