import { useEffect, useRef, useState } from "react"

import { ReactComponent as IconSearch } from "@common_assets/svg/search.svg"
import {
  Autocomplete,
  debounce,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useQueryStudentsSearch } from "api/reactQuery/queries/studentsSearch"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { ExtraSmallAvatar } from "styles/common/avatar"
import { PrimaryTile } from "styles/common/tile"
import { checkSubscription } from "utils/api"

const StudentSearch = () => {
  const userInfo = useAppSelector(selectUser)
  const isMountedRef = useRef(false)
  const isTablet = useIsBreakpointUp("tablet")
  const { t } = useTranslation()
  const [filter, setFilter] = useState("")
  const navigate = useNavigate()

  const { data, isLoading, isError } = useQueryStudentsSearch({
    params: {
      filter,
    },
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  })

  return (
    <PrimaryTile width="100%" py="16px" px={isTablet ? "24px" : "16px"}>
      <Stack rowGap="16px">
        <Typography variant="subtitle1" color="mockup.neutral10">
          {t("dashboard.studentSearch")}
        </Typography>
        <Autocomplete
          noOptionsText={t("noOptions")}
          loadingText={t("loading")}
          loading={isLoading}
          options={data ? [...data.data] : []}
          onChange={(_, value) => {
            navigate(
              `${RoutePath.STUDENT.replace(":studentId", `${value?.id}`)}`,
              { state: { name: "searchBar" } }
            )
          }}
          onInputChange={debounce((e) => {
            const event = e as React.ChangeEvent<HTMLInputElement>
            if (event && isMountedRef.current === true)
              setFilter(event.target.value)
          }, 300)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={isError}
              helperText={isError && t("validations.anErrorOccured")}
              placeholder={t("dashboard.searchForStudent")}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconWrapper>
                      <IconSearch />
                    </IconWrapper>
                  </InputAdornment>
                ),
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name} ${option.login}`
          }
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <Grid sx={{ overflow: "hidden", paddingY: "6px" }}>
                <ExtraSmallAvatar src={option.avatar_url} alt="avatar" />
                {`${option.first_name} ${option.last_name}`}
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.mockup.neutral10Opacity,
                    fontWeight: 600,
                    overflowWrap: "break-word",
                  })}
                >
                  {`${option.login}`}
                </Typography>
              </Grid>
            </li>
          )}
        />
      </Stack>
    </PrimaryTile>
  )
}

export default StudentSearch
