import React, { Dispatch, FC } from "react"

import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useDrag } from "react-dnd"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { ItemsTypes } from "utils/itemsTypes"

import { ICheckedPerson, IStudentsToChecked } from "../Jobs"

interface IStudent {
  student: IObjectStudentDetailed
  handleCheckedPerson: (arg: IObjectStudentDetailed) => void
  checkedPerson: ICheckedPerson[]
  stateStudentsToChecked: {
    studentsToChecked: IStudentsToChecked[]
    setStudentsToChecked: Dispatch<React.SetStateAction<IStudentsToChecked[]>>
  }
  students: IObjectStudentDetailed[]
}

const SingleStudent: FC<IStudent> = ({
  student,
  checkedPerson,
  handleCheckedPerson,
  stateStudentsToChecked,
  students,
}) => {
  const theme = useTheme()
  const { studentsToChecked, setStudentsToChecked } = stateStudentsToChecked

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemsTypes.STUDENT,
    item: () => [
      {
        id: student.id,
        first_name: student.first_name,
        last_name: student.last_name,
      },
    ],
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const handleOnChange = (position: number) => {
    setStudentsToChecked(
      studentsToChecked.map((box) =>
        box.id === position ? { ...box, checked: !box.checked } : box
      )
    )
  }

  const findStudent = (id: number) => {
    return studentsToChecked.find((el) => {
      if (el.id === id) {
        return el
      }
    })
  }

  return (
    <ListItem
      ref={!!checkedPerson.length ? null : drag}
      sx={{
        borderRadius: 1,
        padding: "10px 0",
        backgroundColor: isDragging ? `${theme.palette.mockup.primary80}` : "",
        cursor: !!checkedPerson.length ? "no-drop" : "move",
        color: isDragging ? `${theme.palette.mockup.primary80}` : "",
      }}
    >
      {students.length > 1 && (
        <Checkbox
          sx={{
            width: "24px",
            height: "24px",
            opacity: isDragging ? 0 : 1,
          }}
          checked={findStudent(student.id)?.checked}
          onChange={() => handleOnChange(student.id)}
          onClick={() => handleCheckedPerson(student)}
        />
      )}
      <ListItemAvatar sx={{ minWidth: "45px" }}>
        {student.avatar_url ? (
          <Avatar
            src={student.avatar_url}
            alt="avatar"
            style={{ width: 36, height: 36, opacity: isDragging ? 0 : 1 }}
          />
        ) : (
          <Avatar sx={{ width: 36, height: 36, opacity: isDragging ? 0 : 1 }} />
        )}
      </ListItemAvatar>
      <ListItemText
        sx={{ userSelect: !!checkedPerson.length ? "none" : "text" }}
        primary={
          <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            {student.first_name} {student.last_name}
          </Typography>
        }
        secondary={
          <Tooltip
            title={
              student.jobs.join(", ").length > 13 ? student.jobs.join(", ") : ""
            }
          >
            <Typography
              variant="body3"
              sx={{
                fontWeight: "500",
                color: isDragging
                  ? theme.palette.mockup.primary80
                  : theme.palette.mockup.neutral40,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100px",
                position: "absolute",
              }}
            >
              {student.jobs.join(", ")}
            </Typography>
          </Tooltip>
        }
      />
    </ListItem>
  )
}

export default SingleStudent
