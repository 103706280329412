import React, { useEffect, useState } from "react"

import { ReactComponent as SvgPhoto } from "@common_assets/svg/photo.svg"
import {
  Avatar,
  Button,
  Checkbox,
  Stack,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutationStudentHelpers } from "api/reactQuery/mutations/studentHelpers"
import { useInfiniteStudentHelpers } from "api/reactQuery/queries/studentHelpers"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import ErrorText from "components/common/error/errorText"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import { Cell, HeadText, Row } from "components/common/table/basicTable"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { getErrorMessageFromTab } from "utils/api"

import SearchbarForm from "../../form/searchbarForm"
import {
  OrderType,
  RoleHelper,
  StudentHelpersTypes,
} from "./StudentHelpers.types"

const StudentHelpers = () => {
  const { t } = useTranslation()
  const params = useParams()
  const isFree = userIsFreeDemoSchoolPlan()
  const [sorting, setSorting] = useState<OrderType>("asc")
  const [studentHelperObject, setStudentHelperObject] = useState<
    StudentHelpersTypes[]
  >([])
  const [initialStudentHelperObject, setInitialStudentHelperObject] = useState<
    StudentHelpersTypes[]
  >([])
  const [searchOption, setSearchOption] = useState({
    search: "",
  })

  const { isOpen, handleOpen, handleClose } = useDialog()

  const { showSnackbar } = useCustomSnackbar()

  const queryClient = useQueryClient()

  const { ref, inView } = useInView({
    threshold: 0.5,
  })

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isLoading: isLoadingStudentHelpers,
  } = useInfiniteStudentHelpers({
    classId: Number(params.classId),
    filter: searchOption.search.length < 2 ? undefined : searchOption.search,
    limit: 20,
    skip: 0,
    sorting: sorting,
    sort_by: "first_name",
  })

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { mutate, isLoading } = useMutationStudentHelpers({
    class_id: Number(params.classId),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("infiniteStudentHelpers")
        showSnackbar({
          title: "student helpers have been confirmed successfully",
          variant: "success",
        })
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    setStudentHelperObject([])
    setSearchOption({ search: "" })
    setSorting("asc")
  }, [params.classId])

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  useEffect(() => {
    const initialState = data?.pages.flatMap((item) => item.data.objects)

    if (initialState) {
      setStudentHelperObject((prev) => {
        const newStudent = initialState
          .filter(
            (item) => !prev.some((exist) => exist.fk_student_id === item.id)
          )
          .map((student) => ({
            fk_student_id: student.id,
            is_banker: student.is_banker,
            is_store_clerk: student.is_store_clerk,
            is_hr_representative: student.is_hr_representative,
          }))
        return [...prev, ...newStudent]
      })

      setInitialStudentHelperObject((prev) => {
        const newStudent = initialState
          .filter(
            (item) => !prev.some((exist) => exist.fk_student_id === item.id)
          )
          .map((student) => ({
            fk_student_id: student.id,
            is_banker: student.is_banker,
            is_store_clerk: student.is_store_clerk,
            is_hr_representative: student.is_hr_representative,
          }))
        return [...prev, ...newStudent]
      })
    }
  }, [data])

  const handleChecked = (id: number, name: RoleHelper) => {
    if (isFree) {
      handleOpen()
      return
    }

    setStudentHelperObject((prev) =>
      prev.map((user) => {
        if (user.fk_student_id === id) {
          return { ...user, [name]: !user[name] }
        }
        return user
      })
    )
  }

  const checkedAction = (id: number, name: RoleHelper) => {
    const student = studentHelperObject?.find(
      (item) => item.fk_student_id === id
    ) as StudentHelpersTypes
    return student ? student[name] : false
  }

  const handleSort = () => {
    const isAsc = sorting === "asc"
    setSorting(isAsc ? "desc" : "asc")
    queryClient.removeQueries(["infiniteStudentHelpers"])
  }

  // shallow comparison, if you need compare deeply please use ex _cloneDeep() from loadash or npm https://www.npmjs.com/package/lodash.clonedeep
  const areObjectArraysEqual = (
    arr1: StudentHelpersTypes[],
    arr2: StudentHelpersTypes[]
  ): boolean => {
    if (arr1.length !== arr2.length) return false

    return arr1.every((obj1, index) => {
      const obj2 = arr2[index]
      return (
        Object.keys(obj1).length === Object.keys(obj2).length &&
        (Object.keys(obj1) as Array<keyof StudentHelpersTypes>).every((key) => {
          return Object.is(obj1[key], obj2[key])
        })
      )
    })
  }

  const isButtonDisabled = () => {
    return (
      isLoading ||
      isFree ||
      areObjectArraysEqual(studentHelperObject, initialStudentHelperObject)
    )
  }

  return (
    <Stack position="relative">
      <Stack
        px={2}
        py={3}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
        })}
      >
        <SearchbarForm onSubmit={(data) => setSearchOption(data)} />
      </Stack>
      <BasicTable
        head={
          <Row>
            <Cell tableCellProps={{ width: "auto" }}>
              <TableSortLabel active direction={sorting} onClick={handleSort}>
                <HeadText>{t("studentHelpers.student")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell
              tableCellProps={{
                align: "center",
                sx: { width: "200px", minWidth: "100px" },
              }}
            >
              <Stack width="100%">
                <Tooltip
                  placement="top"
                  title={t("studentHelpers.manageSchoolStore")}
                >
                  <Stack>
                    <HeadText textTransform="capitalize">
                      {t("studentHelpers.storeClerk")}:
                    </HeadText>
                    <HeadText textTransform="inherit">
                      {t("studentHelpers.manageClassStore")}
                    </HeadText>
                  </Stack>
                </Tooltip>
              </Stack>
            </Cell>
            <Cell
              tableCellProps={{
                align: "center",
                sx: { width: "200px", minWidth: "100px" },
              }}
            >
              <Stack width="100%">
                <Tooltip
                  placement="top"
                  title={t("studentHelpers.manageHrRepresentative")}
                >
                  <Stack>
                    <HeadText textTransform="capitalize">
                      {t("studentHelpers.hrRepresentative")}:
                    </HeadText>
                    <HeadText textTransform="inherit">
                      {t("studentHelpers.manageJobBoard")}
                    </HeadText>
                  </Stack>
                </Tooltip>
              </Stack>
            </Cell>
            <Cell
              tableCellProps={{
                align: "center",
                sx: { width: "200px", minWidth: "100px" },
              }}
            >
              <Stack width="100%">
                <Tooltip
                  placement="top"
                  title={t("studentHelpers.manageSendTransactions")}
                >
                  <Stack>
                    <HeadText textTransform="capitalize">
                      {t("studentHelpers.banker")}:
                    </HeadText>
                    <HeadText textTransform="inherit">
                      {t("studentHelpers.sendTransactions")}
                    </HeadText>
                  </Stack>
                </Tooltip>
              </Stack>
            </Cell>
          </Row>
        }
        body={
          <TransitionGroup component={null}>
            {data?.pages.flatMap((data) =>
              data.data.objects.map((student) => (
                <CSSTransition
                  key={student.id}
                  timeout={700}
                  classNames="animation-fade"
                >
                  <Row>
                    <Cell>
                      <Stack
                        flexDirection="row"
                        columnGap={1}
                        alignItems="center"
                      >
                        <Avatar src={student.avatar_url} />
                        <Typography color="mockup.neutral10">
                          {student.first_name} {student.last_name}
                        </Typography>
                      </Stack>
                    </Cell>
                    <Cell>
                      <Stack width="100%" alignItems="center">
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 32 },
                          }}
                          checked={checkedAction(student.id, "is_store_clerk")}
                          onChange={() =>
                            handleChecked(student.id, "is_store_clerk")
                          }
                        />
                      </Stack>
                    </Cell>
                    <Cell>
                      <Stack width="100%" alignItems="center">
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 32 },
                          }}
                          checked={checkedAction(
                            student.id,
                            "is_hr_representative"
                          )}
                          onChange={() =>
                            handleChecked(student.id, "is_hr_representative")
                          }
                        />
                      </Stack>
                    </Cell>
                    <Cell>
                      <Stack width="100%" alignItems="center">
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 32 },
                          }}
                          checked={checkedAction(student.id, "is_banker")}
                          onChange={() =>
                            handleChecked(student.id, "is_banker")
                          }
                        />
                      </Stack>
                    </Cell>
                  </Row>
                </CSSTransition>
              ))
            )}
          </TransitionGroup>
        }
        bottomSlot={
          <>
            {hasNextPage && <div ref={ref} />}
            {isError && <ErrorText />}
            {!isLoadingStudentHelpers &&
              !!searchOption.search.length &&
              data?.pages.flatMap((item) => item.data.objects).length === 0 && (
                <NoMatchesForSearch search={searchOption.search} />
              )}
            {!isLoadingStudentHelpers &&
              data?.pages.flatMap((item) => item.data.objects).length === 0 &&
              !searchOption.search.length && (
                <NoElementsMessage
                  boxStyle={{ py: { mobile: "16px", tablet: "62px" } }}
                  Icon={<SvgPhoto />}
                  title={t("students.youDoNotHaveAnyStudentsYet")}
                  description={t("students.addStudentsManuallyOrHaveStudents")}
                />
              )}
          </>
        }
      ></BasicTable>
      <DialogUpgradeAccount
        open={isOpen}
        onClose={() => {
          handleClose()
          mixpanel.track("reports_export", {
            action: "cancel",
          })
        }}
        onActionButtonClick={() => {
          payIt(null)
          mixpanel.track("reports_export", {
            methods: "class_transactions",
            action: "submit",
          })
        }}
        isLoading={isLoadingPayments}
        isHelper
      />
      {!!studentHelperObject.length && (
        <Stack
          sx={{
            position: "sticky",
            bottom: "5px",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ width: "max-content" }}
            disabled={isButtonDisabled()}
            onClick={() => {
              mutate(studentHelperObject)
              setInitialStudentHelperObject(studentHelperObject)
            }}
          >
            Confirm
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
export default StudentHelpers
