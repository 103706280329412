import React, { useState } from "react"

import { ReactComponent as IconBulb } from "@common_assets/svg/bulb.svg"
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { TransactionType } from "ts/types/Transaction"

import DialogSendTransaction from "../dialogSendTransaction"
import {
  TransactionButton,
  BatchActionsContainer,
} from "./BatchActionsButtonsBanker.styles"

interface BatchActionButtonsBankerProps {
  markedStudentId: number[]
  isToggleAll: boolean
  studentNumbers: number
  excludeIds: number[]
  onSuccessPostClassesTransactions: () => void
}

const BatchActionButtonsBanker = ({
  markedStudentId,
  isToggleAll,
  studentNumbers,
  excludeIds,
  onSuccessPostClassesTransactions,
}: BatchActionButtonsBankerProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [defaultExpandedSections, setDefaultExpandedSections] = useState<
    TransactionType[]
  >([])
  const isTablet = useIsBreakpointUp("tablet")
  const isBetweenTablet = useMediaQuery(
    theme.breakpoints.between("tablet", "desktop")
  )

  const {
    isOpen: isOpenTransactions,
    handleOpen: handleOpenTransactions,
    handleClose: handleCloseTransactions,
  } = useDialog()

  if (markedStudentId.length === 0)
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderBottom={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
        borderTop={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
        p="8px"
      >
        <IconWrapper width="14px" height="20px">
          <IconBulb width="14px" height="14px" viewBox="0 0 14 14" />
        </IconWrapper>
        <Typography
          variant="subtitle2"
          fontWeight={500}
          color={(theme) => theme.palette.mockup.neutral10}
        >
          {t("students.sendTransactionsToMoreThan")}
        </Typography>
      </Stack>
    )

  return (
    <>
      <BatchActionsContainer isBetweenTablet={isBetweenTablet}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            color="mockup.neutral10"
            minWidth="max-content"
          >
            {t("students.numberOfStudentsSelected", {
              quantity: isToggleAll
                ? studentNumbers - excludeIds.length
                : markedStudentId.length,
            })}
          </Typography>
        </Stack>
        <Stack
          width="100%"
          sx={{
            overflowX: "auto",
          }}
          className="invisible-scrollbar"
        >
          <Stack
            direction="row"
            gap={{ mobile: "0px", tablet: "8px" }}
            width="max-content"
            mx={{ mobile: "-8px", tablet: "0px" }}
          >
            <TransactionButton
              scheme="bonus"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                setDefaultExpandedSections(["bonus"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendBonuses")}
            </TransactionButton>
            <TransactionButton
              scheme="fine"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                setDefaultExpandedSections(["fine"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendFines")}
            </TransactionButton>
            <TransactionButton
              scheme="salary"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                setDefaultExpandedSections(["salary"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendPaychecks")}
            </TransactionButton>
            <TransactionButton
              scheme="expense"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                setDefaultExpandedSections(["expense"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendExpenses")}
            </TransactionButton>
          </Stack>
        </Stack>
      </BatchActionsContainer>
      <DialogSendTransaction
        markedStudentId={markedStudentId}
        isOpen={isOpenTransactions}
        handleClose={handleCloseTransactions}
        defaultExpandedSections={defaultExpandedSections}
        isToggleAll={isToggleAll}
        excludedStudentId={excludeIds}
        onSuccessPostClassesTransactions={onSuccessPostClassesTransactions}
      />
    </>
  )
}

export default BatchActionButtonsBanker
