import React, { useEffect } from "react"

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useMutationDowngrade } from "api/reactQuery/mutations/downgrade"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { Trans, useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { selectUser } from "store/auth/auth.selectors"
import { Subscription_Status, UserSchoolPlan } from "ts/enums/User"
import { IUserTeacher } from "ts/interfaces/User"
import { getErrorMessageFromTab } from "utils/api"

const DowngradeTeacher = () => {
  const userInfo = useAppSelector(selectUser)
  const userT = userInfo?.user as IUserTeacher
  const { t } = useTranslation()
  const { isOpen, handleOpen } = useDialog()
  const location = useLocation()
  const { showSnackbar } = useCustomSnackbar()
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutationDowngrade({
    options: {
      onSuccess: () => navigate(0),
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { payIt, isLoadingPayments } = useCustomPayment()

  useEffect(() => {
    if (
      userT?.subscription_status === Subscription_Status.CANCELLED &&
      userT?.school_plan === UserSchoolPlan.PRO
    ) {
      handleOpen()
    }
  }, [userT?.subscription_status, location.pathname])

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onIconClose={false}
      desktopMaxWidth="534px"
      customActionButtonsSlot={
        <Box
          gap="10px"
          display="flex"
          justifyContent="flex-end"
          m="16px 32px 16px 0 "
        >
          <Button
            disabled={isLoading}
            onClick={() => mutate(null)}
            variant="outlined"
          >
            <span>{isLoading && <CircularProgress size="16px" />}</span>
            {t("dashboard.downgrade")}
          </Button>

          <Button
            disabled={isLoadingPayments}
            variant="contained"
            onClick={() => {
              payIt(null)
            }}
          >
            <span>{isLoadingPayments && <CircularProgress size="16px" />}</span>
            {t("dashboard.manageYourPayment")}
          </Button>
        </Box>
      }
    >
      <Stack textAlign="center">
        <Typography variant="h3" color="mockup.darkBlue" mb={3}>
          {t("dashboard.schoolNameNoLongerHasAnActive", {
            schoolName: userT?.school_name,
          })}
        </Typography>
        <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
          <Trans i18nKey="dashboard" components={{ strong: <b /> }}>
            {t("dashboard.yourAccountHasBeenRestored")}
          </Trans>
        </Typography>
      </Stack>
    </Dialog>
  )
}

export default DowngradeTeacher
