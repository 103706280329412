import { Box, styled, TextField } from "@mui/material"

export const ErrorStyle = styled(Box)(({ theme }) => ({
  color: theme.palette.mockup.error50,
  position: "absolute",
  bottom: "-40%",
  fontWeight: "500",
}))

export const TextFieldStyle = styled(TextField)<{ invalid: boolean }>(
  ({ theme, invalid }) => ({
    label: {
      color: theme.palette.mockup.neutral60,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: invalid && `2px solid ${theme.palette.mockup.error50}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: invalid && `2px solid ${theme.palette.mockup.error50}`,
    },
  })
)
