import { FC } from "react"

import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  TableSortLabel,
  TableSortLabelProps,
} from "@mui/material"
import BasicTable from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"
import { ITransactionItemClass } from "ts/interfaces/Transaction"

interface IProps {
  sortedData: ITransactionItemClass[]
  count: number
  next10Row: () => void
  checkboxProps: CheckboxProps
  getTableSortLabelProps: (
    property: keyof ITransactionItemClass
  ) => TableSortLabelProps
}

const Table: FC<IProps> = ({
  checkboxProps,
  getTableSortLabelProps,
  children,
  next10Row,
  sortedData,
  count,
}) => {
  const { t } = useTranslation()

  return (
    <BasicTable
      wrapperProps={{
        borderRadius: "0px",
        sx: { border: "none" },
      }}
      head={
        <BasicTable.Row>
          <BasicTable.Cell width="50px" px="8px">
            <Checkbox {...checkboxProps} />
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "50%" }}>
            <TableSortLabel {...getTableSortLabelProps("first_name")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.student")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "50%" }}>
            <TableSortLabel {...getTableSortLabelProps("title")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.item")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="120px">
            <TableSortLabel {...getTableSortLabelProps("created_at")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.date")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell width="80px">
            <TableSortLabel {...getTableSortLabelProps("value")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.value")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell width="109px" />
        </BasicTable.Row>
      }
      body={children}
      bottomSlot={
        <>
          {sortedData.length > 0 && sortedData.length > count && (
            <Box textAlign="center">
              <Button variant="text" onClick={next10Row}>
                {t("store.loadMore")}
              </Button>
            </Box>
          )}
        </>
      }
    />
  )
}

export default Table
