import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const Header = () => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={{ mobile: "4px", tablet: "10px" }}
      px="16px"
    >
      <Typography variant="subtitle1" color="mockup.neutral10">
        {t("dashboardStudent.pendingTransactions")}
      </Typography>
    </Stack>
  )
}

export default Header
