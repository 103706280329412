import { ThemeOptions } from "@mui/material"

const muiSkeleton: ThemeOptions["components"] = {
  MuiSkeleton: {
    defaultProps: {
      variant: "rectangular",
    },
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  },
}

export default muiSkeleton
