import { yupResolver } from "@hookform/resolvers/yup"
import { Typography, Grid } from "@mui/material"
import { useQueryGetCountries } from "api/reactQuery/queries/getCountries"
import SelectField from "components/form/common/field/selectField"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IAddSchoolForm } from "ts/interfaces/AddSchoolForm"
import { statesOfUSA } from "utils/constants/states"
import { timezones, kuweitzone, otherTimezones } from "utils/timezones"

import { defaultValues, validationSchema } from "./AddNewSchool.config"
import FormText from "./formText"

export interface AddNewSchoolProps {
  handleSubmit: (data: IAddSchoolForm) => void
  id: string
}

const AddNewSchool = ({ id, handleSubmit }: AddNewSchoolProps) => {
  const { t } = useTranslation()

  const methods = useForm<IAddSchoolForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })

  const { data: countriesData } = useQueryGetCountries()

  const onSubmit = (data: IAddSchoolForm) => handleSubmit(data)

  const countryIdWatch = useWatch({
    name: "country_id",
    control: methods.control,
  })

  const cleanTimezone = () => {
    methods.setValue("state", "")
    methods.setValue("timezone", "")
  }

  const selectTimezone = () => {
    if (process.env.REACT_APP_TYPE === "bankeeonline") {
      return kuweitzone
    }
    if (process.env.REACT_APP_TYPE === "classequity") {
      if (Number(countryIdWatch) === 1) {
        return timezones
      } else if (Number(countryIdWatch) === 2) {
        return otherTimezones.map((time) => ({ label: time, value: time }))
      }
    }
    return []
  }

  return (
    <form id={id} onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Typography
          color="mockup.neutral50"
          textAlign="left"
          mb={4}
          variant="h2"
        >
          {t("noAuthFlow.addNewSchool")}
        </Typography>
        <Grid container rowSpacing={5} columnSpacing={"12px"}>
          <Grid item mobile={12}>
            <SelectField
              onClick={cleanTimezone}
              name="country_id"
              label={t("noAuthFlow.country")}
              options={
                countriesData
                  ? countriesData.data.map((country) => ({
                      label: country.name,
                      value: country.id.toString(),
                    }))
                  : []
              }
            />
          </Grid>
          <FormText
            spacing={12}
            name="name"
            label={t("noAuthFlow.schoolName")}
          />
          <FormText
            spacing={12}
            name="district"
            label={t("noAuthFlow.district")}
          />
          <FormText
            spacing={12}
            name="city"
            label={t("noAuthFlow.cityOrTown")}
          />
          {countryIdWatch === "1" ? (
            <Grid item mobile={12}>
              <SelectField
                name="state"
                label={t("noAuthFlow.stateOrProvince")}
                options={statesOfUSA
                  .map((time) => ({
                    label: time,
                    value: time,
                  }))
                  .map((item) => ({ label: item.label, value: item.value }))}
              />
            </Grid>
          ) : (
            <FormText
              spacing={12}
              name="state"
              label={t("noAuthFlow.stateOrProvince")}
            />
          )}
          <FormText
            disabled={!countryIdWatch}
            spacing={6}
            name="zip_code"
            label={t("noAuthFlow.zipCode")}
          />
          <Grid item mobile={6}>
            <SelectField
              disabled={!countryIdWatch}
              name="timezone"
              label={t("noAuthFlow.timeZone")}
              options={selectTimezone().map((time) => ({
                label: time.label,
                value: time.value,
              }))}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </form>
  )
}

export default AddNewSchool
