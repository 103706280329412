import React, { Dispatch, FC, useEffect, useState } from "react"

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
  Tooltip,
} from "@mui/material"
import {
  useMutationCopySettings,
  useMutationCopySettingsCheck,
} from "api/reactQuery/mutations/classesCopySettings"
import {
  IPostCopySettingsCheck,
  IResponseCopySettingsCheck,
} from "api/reactQuery/mutations/classesCopySettings.types"
import { useQueryClasses } from "api/reactQuery/queries/classes"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { Trans, useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectUser } from "store/auth/auth.selectors"
import { CategoriesType } from "ts/enums/Class"
import { IClass } from "ts/interfaces/Class"
import { checkSubscription, getErrorMessageFromTab } from "utils/api"

import { applySettingsDB, IPropsApplySettings } from "./ApplySettings.config"

interface IProps {
  id: string
  getDataId?: IClass
  handleCloseDialogApplySettings: () => void
  cleanAfterCloseDialog: () => void
  setIsLoading: Dispatch<React.SetStateAction<boolean>>
}

const ApplySettings: FC<IProps> = ({
  id,
  getDataId,
  handleCloseDialogApplySettings,
  cleanAfterCloseDialog,
  setIsLoading,
}) => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)
  const theme = useTheme()
  const [itemsValue, setItemsValue] = useState<CategoriesType[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [classNameFrom, setClassNameFrom] = useState("")
  const [selectClassId, setSelectClassId] = useState(0)
  const [studentLostJob, setStudentLostJob] = useState<
    IResponseCopySettingsCheck["students_with_jobs"]
  >([])
  const [error, setError] = useState({
    select: false,
    checkbox: false,
  })

  const queryClient = useQueryClient()

  const { isOpen, handleOpen, handleClose } = useDialog()
  const { showSnackbar } = useCustomSnackbar()

  useEffect(() => {
    if (selectClassId !== 0) {
      setError((prevState) => ({ ...prevState, select: false }))
    }
    if (itemsValue.length > 0) {
      setError((prevState) => ({ ...prevState, checkbox: false }))
    }
  }, [selectClassId, itemsValue])

  const handleItems = (item: IPropsApplySettings) => {
    const position = itemsValue.findIndex((el) => el === item.label)
    const newItem = [...itemsValue]

    if (position === -1) {
      newItem.push(item.label)
      item.checkbox = true
    } else {
      newItem.splice(position, 1)
      item.checkbox = false
    }
    setItemsValue(newItem)
  }

  const toggleAll = () => {
    setIsCheckAll(!isCheckAll)
    setItemsValue(applySettingsDB.map((element) => element.label))
    applySettingsDB.forEach((element) => (element.checkbox = true))

    if (isCheckAll) {
      applySettingsDB.forEach((element) => (element.checkbox = false))
      setItemsValue([])
    }
  }

  const { data } = useQueryClasses({
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  const { mutate } = useMutationCopySettingsCheck({
    options: {
      onSuccess: (data) => {
        setStudentLostJob(data.data.students_with_jobs)
        handleOpen()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: copySettings, isLoading: isLoadingCopySettigns } =
    useMutationCopySettings({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["jobsDetails"])
          queryClient.invalidateQueries(["jobs"])
          queryClient.invalidateQueries(["studentsClass"])
          showSnackbar({
            title: t("addNewClass.copyHasBeenSuccesful"),
          })
          setIsLoading(false)
          cleanAfterCloseDialog()
          handleCloseDialogApplySettings()
          handleClose()
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (selectClassId === 0) {
      setError((prev) => ({ ...prev, select: true }))
      return
    }
    if (applySettingsDB.every((el) => !el.checkbox)) {
      setError((prev) => ({ ...prev, checkbox: true }))
      showSnackbar({
        title: t("addNewClass.chooseAtLeastOneOfTheOption"),
        variant: "error",
      })
      return
    }
    await mutate({
      class_from_id: Number(selectClassId),
      class_to_id: Number(getDataId?.id),
      categories: itemsValue,
    } as IPostCopySettingsCheck)
  }

  return (
    <form id={id} onSubmit={handleSubmit}>
      <Stack>
        <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
          {t("addNewClass.whatClassWouldYouLikeToImportSettingsFrom")}
        </Typography>
        <FormGroup />
        <Box display="flex" flexDirection="column">
          <TextField
            sx={{ mt: 3, mb: 1 }}
            error={error.select}
            select
            label={t("addNewClass.importFrom")}
            value={selectClassId || ""}
            onChange={(e) => {
              setSelectClassId(Number(e.target.value))
            }}
          >
            {data?.data.map((singleClass) => (
              <MenuItem
                key={singleClass.id}
                value={singleClass.id}
                onClick={(e) => {
                  const event = e.target as HTMLElement
                  setClassNameFrom(event.innerText)
                  mixpanel.track("import_class_settings", {
                    action: "select_class_name",
                  })
                }}
              >
                {singleClass.name}
              </MenuItem>
            ))}
          </TextField>
          {classNameFrom && (
            <Typography
              sx={{ textDecoration: "underline" }}
              variant="body3"
              color="mockup.neutral10"
              mb={1}
            >
              {t("addNewClass.greatSettingsFromClassWillBeImportedToClass", {
                classNameFrom: classNameFrom,
                classNameTo: getDataId?.name,
              })}
            </Typography>
          )}
        </Box>
        <Typography variant="body1" color="mockup.neutral10" fontWeight="500">
          <Trans i18nKey="addNewClass" components={{ strong: <b /> }}>
            {t("addNewClass.whatSettingsWouldYouLikeToImportTo", {
              className: getDataId?.name,
            })}
          </Trans>
        </Typography>
        <FormControlLabel
          disabled={selectClassId === 0}
          sx={{ my: 1 }}
          control={
            <Checkbox
              sx={{ color: error.checkbox ? theme.palette.mockup.error50 : "" }}
              onClick={() => {
                toggleAll()
                mixpanel.track("import_class_settings", {
                  action: "select_all_checkbox",
                })
              }}
              checked={
                itemsValue.length === applySettingsDB.length &&
                itemsValue.length !== 0
              }
              indeterminate={
                itemsValue.length !== applySettingsDB.length &&
                itemsValue.length !== 0
              }
            />
          }
          label={t("addNewClass.selectAll")}
        />

        {applySettingsDB.map((element) => (
          <Box key={element.id} sx={{ mb: 1 }}>
            <FormControlLabel
              disabled={selectClassId === 0}
              control={
                <Checkbox
                  sx={{
                    color: error.checkbox ? theme.palette.mockup.error50 : "",
                  }}
                  checked={element.checkbox}
                  onChange={() => handleItems(element)}
                  onClick={() => {
                    mixpanel.track("import_class_settings", {
                      action: `${element.text}_checkbox`,
                    })
                  }}
                />
              }
              label={
                <Typography fontWeight="500" color="mockup.neutral10">
                  {t(element.text)}
                </Typography>
              }
            />
          </Box>
        ))}
        <Typography
          mt={2}
          variant="body2"
          color="mockup.neutral10"
          fontWeight="500"
        >
          <Trans i18nKey="addNewClass" components={{ strong: <b /> }}>
            {t("addNewClass.importantNotice")}
          </Trans>
        </Typography>
      </Stack>
      <Dialog
        open={isOpen}
        desktopMaxWidth="534px"
        tabletMaxWidth="534px"
        onClose={handleClose}
        isLoading={isLoadingCopySettigns}
        actionAcceptText={
          isLoadingCopySettigns
            ? t("addNewClass.copyingItems")
            : t("addNewClass.save")
        }
        titleText={t("addNewClass.areYouSureYouWantToCopyDB", {
          classTitle: getDataId?.name,
          object: itemsValue.join(", ").replace("_", " "),
        })}
        actionAcceptButtonProps={{ disabled: isLoadingCopySettigns }}
        onActionButtonClick={() => {
          copySettings({
            class_from_id: Number(selectClassId),
            class_to_id: Number(getDataId?.id),
            categories: itemsValue,
          })
          setIsLoading(true)
        }}
      >
        <Box maxWidth="350px">
          {studentLostJob.length > 0 && (
            <Typography variant="body1" mb={2}>
              {t("addNewClass.thisIsListOfStudentsWhoMightLoseTheirJob")}
            </Typography>
          )}
          {studentLostJob
            ? studentLostJob
                .sort((a, b) => a.job.title.localeCompare(b.job.title))
                .map((object, index) => (
                  <Stack
                    key={index}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexBasis="70%">
                      <Typography
                        color="mockup.neutral10"
                        variant="body2"
                        mr={2}
                      >
                        {index + 1}.
                      </Typography>
                      <Typography
                        sx={{ mr: 1 }}
                        variant="body2"
                        color="mockup.neutral10"
                      >
                        {object.student?.first_name} {object.student?.last_name}
                      </Typography>
                    </Box>
                    <Tooltip
                      title={
                        object?.job.title.length >= 11 ? object?.job.title : ""
                      }
                    >
                      <Typography
                        flexBasis="30%"
                        color="mockup.neutral10"
                        fontWeight="500"
                        variant="body2"
                        justifyContent="flex-start"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {object?.job.title}
                      </Typography>
                    </Tooltip>
                  </Stack>
                ))
            : []}
        </Box>
      </Dialog>
    </form>
  )
}

export default ApplySettings
