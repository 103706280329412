import { requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  invitation_code: "",
  no1: "",
  no2: "",
  no3: "",
  no4: "",
  no5: "",
  no6: "",
  first_name: "",
  last_name: "",
}

export const validateSchema = yup.object({
  no1: yup.string().required(""),
  no2: yup.string().required(""),
  no3: yup.string().required(""),
  no4: yup.string().required(""),
  no5: yup.string().required(""),
  no6: yup.string().required(""),
  first_name: requiredStringSchema,
  last_name: requiredStringSchema,
})
