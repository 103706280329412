import { FC } from "react"

import ErrorText from "components/common/error/errorText"
import TableSkeleton from "components/common/skeleton/tableSkeleton"

import NoTransactions from "./noTransactions"

interface IProps {
  isLoading: boolean
  isError: boolean
  dataLength: number
  isNoMoreData: boolean
}

const StatusView: FC<IProps> = ({
  isLoading,
  isNoMoreData,
  isError,
  dataLength,
}) => {
  return (
    <>
      {isLoading && <TableSkeleton />}
      {isError && <ErrorText />}
      {!isLoading && isNoMoreData && dataLength === 0 && <NoTransactions />}
    </>
  )
}

export default StatusView
