import { FC, ReactNode } from "react"

import {
  Box,
  BoxProps,
  Table,
  TableBody,
  TableBodyProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
} from "@mui/material"

import Cell from "./cell"
import HeadText from "./headText"

interface IProps {
  head: ReactNode
  body: ReactNode
  topSlot?: ReactNode
  bottomSlot?: ReactNode
  wrapperProps?: BoxProps
  tableContainerProps?: TableContainerProps
  tableProps?: TableProps
  tableHeadProps?: TableHeadProps
  tableBodyProps?: TableBodyProps
}

const BasicTable: FC<IProps> = ({
  head,
  body,
  topSlot,
  bottomSlot,
  wrapperProps,
  tableContainerProps,
  tableProps,
  tableHeadProps,
  tableBodyProps,
}) => {
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.mockup.primary95}`,
      })}
      overflow="hidden"
      borderRadius="8px"
      {...wrapperProps}
    >
      <Box width="100%" bgcolor="common.white" position="sticky">
        {topSlot}
      </Box>
      <TableContainer {...tableContainerProps} className="smooth-scrollbar">
        <Table sx={{ height: "1px" }} {...tableProps}>
          <TableHead {...tableHeadProps}>{head}</TableHead>
          <TableBody {...tableBodyProps}>{body}</TableBody>
        </Table>
      </TableContainer>
      <Box width="100%" bgcolor="common.white">
        {bottomSlot}
      </Box>
    </Box>
  )
}

export default Object.assign(BasicTable, {
  Row: TableRow,
  Cell,
  HeadText,
})
