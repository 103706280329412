import React, { FC, useEffect, useState } from "react"

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  useMutationFireStudent,
  useMutationHiringStudent,
} from "api/reactQuery/mutations/students"
import {
  IGetPendingApplicationStudents,
  IGetResponseJobsDetails,
} from "api/reactQuery/queries/jobs.types"
import Dialog from "components/common/dialog/dialog"
import StudentList from "components/common/studentList"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { ExtraSmallAvatar } from "styles/common/avatar"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"

import Application from "../application"

interface IProps {
  students: IObjectStudentDetailed[]
  jobState: IGetResponseJobsDetails
  handleChangeInView: (value: boolean) => void
  isRefetching: boolean
}

interface ISingleStudent {
  student_id: number
}

const Employees: FC<IProps> = ({
  jobState,
  isRefetching,
  students,
  handleChangeInView,
}) => {
  const params = useParams()
  const theme = useTheme()
  const [studentId, setStudentId] = useState<IGetPendingApplicationStudents[]>(
    []
  )
  const { ref, inView } = useInView()
  const isMobile = useMediaQuery("(max-width:600px")
  const [studentArray, setStudentArray] = useState<
    IObjectStudentDetailed[] | []
  >([])
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [allHiringStudent, setAllHiringStudent] = useState<
    ISingleStudent[] | []
  >([])

  const {
    isOpen: isDialogOpenApplication,
    handleOpen: handleOpenApplication,
    handleClose: handleCloseApplication,
  } = useDialog()

  const {
    isOpen: isDialogOpenMultipleHiring,
    handleOpen: handleOpenMultipleHiring,
    handleClose: handleCloseMultipleHiring,
  } = useDialog()

  const handleHiringStudents = () => {
    allHiringStudent.length === 0
      ? showSnackbar({
          title: "You have to add a student.",
          variant: "error",
        })
      : hiringStudent({
          classId: Number(params.classId),
          jobId: Number(jobState.id),
          data: allHiringStudent,
        })
  }

  useEffect(() => {
    handleChangeInView(inView)
  }, [inView])

  useEffect(() => {
    const arr = []
    for (const student of studentArray) {
      arr.push({ student_id: student.id })
    }
    setAllHiringStudent(arr)
  }, [studentArray])

  const { mutate: hiringStudent, isLoading } = useMutationHiringStudent({
    options: {
      onSuccess: (_, variables) => {
        if (!!window.Intercom) {
          window.Intercom("trackEvent", "hire student", {
            classId: variables.classId,
            amount: variables.data.length,
            jobTitle: jobState.title,
          })
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "hire_student",
          amount: variables.data.length,
          classId: variables.classId,
          jobTitle: jobState.title,
        })
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        if (allHiringStudent.length === 1) {
          showSnackbar({
            title: t("jobs.hasBeenSuccessfullyHired", {
              title: jobState.title,
              firstName: studentArray[0]?.first_name,
              lastName: studentArray[0]?.last_name,
            }),
          })
        } else {
          showSnackbar({
            title: t("jobs.allOfStudentsHaveBeenSuccessfullyHiredAsTitle", {
              count: allHiringStudent.length,
              title: jobState.title,
            }),
          })
          handleCloseMultipleHiring()
        }
        setStudentArray([])
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
        handleCloseMultipleHiring()
      },
    },
  })

  const { mutate: firingStudent } = useMutationFireStudent({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        showSnackbar({
          title: t("jobs.removedSuccessfully"),
        })
        handleCloseApplication()
      },
      onError: (err) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(err)),
          variant: "error",
        })
      },
    },
  })

  return (
    <Stack>
      <Box
        display="flex"
        sx={{ mb: 7, flexDirection: isMobile ? "column" : "row" }}
      >
        <Box flexGrow="1">
          <Autocomplete
            ListboxProps={{
              role: "list-box",
            }}
            multiple
            disableCloseOnSelect
            value={studentArray}
            renderInput={(params) => (
              <TextField {...params} label={t("jobs.searchStudent")} />
            )}
            onChange={(e, value) => {
              setStudentArray(value)
            }}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              const idArray = props?.id?.split("-")
              const index = (idArray ? idArray[3] : undefined) as
                | undefined
                | string
              return (
                <React.Fragment key={option.id}>
                  {index === "0" && studentArray.length !== students.length && (
                    <Typography
                      sx={{
                        cursor: "pointer",
                        pl: "20px",
                        py: "5px",
                        ":hover": {
                          backgroundColor: theme.palette.mockup.primary95,
                        },
                      }}
                      onClick={() => setStudentArray(students)}
                    >
                      {t("jobs.selectAll")}
                    </Typography>
                  )}
                  <li {...props}>
                    <Stack
                      direction="row"
                      columnGap="8px"
                      alignItems="center"
                      py="6px"
                    >
                      <ExtraSmallAvatar src={option.avatar_url} alt="avatar" />
                      {`${option.first_name} ${option.last_name}`}
                    </Stack>
                  </li>
                  {index === `${students.length - 1}` && (
                    <Stack
                      my={2}
                      direction="row"
                      justifyContent="center"
                      width="100%"
                    >
                      {isRefetching && (
                        <span>
                          <CircularProgress size="20px" />
                        </span>
                      )}
                      <Box ref={ref} />
                    </Stack>
                  )}
                </React.Fragment>
              )
            }}
            options={students}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{
              height: "52px",
              ml: isMobile ? 0 : 1,
              width: isMobile ? "100%" : "auto",
              mt: isMobile ? 3 : 0,
              whiteSpace: "nowrap",
            }}
            disabled={isLoading}
            onClick={() => {
              allHiringStudent.length > 1
                ? handleOpenMultipleHiring()
                : handleHiringStudents()
            }}
          >
            {t("jobs.hireStudent")}
          </Button>
        </Box>
      </Box>
      {jobState.employees.length > 0 ? (
        <StudentList
          studentList={jobState.employees}
          onApplication={(id) => {
            setStudentId(jobState.employees.filter((el) => el.id === id))
            handleOpenApplication()
          }}
          onCancel={(id) => {
            const res = jobState.employees.filter((el) => el.id === id)
            setStudentId(res)
            firingStudent({
              classId: Number(params.classId),
              jobId: Number(jobState.id),
              data: [
                {
                  student_id: res[0].id,
                },
              ],
            })
          }}
        />
      ) : (
        <Typography
          variant="body2"
          fontWeight="500"
          color="mockup.neutral50A"
          textAlign="center"
          mb={4}
        >
          {t("jobs.nobodyEmployed").toUpperCase()}
        </Typography>
      )}
      <Dialog
        titleText={t("jobs.jobApplication")}
        open={isDialogOpenApplication}
        onClose={handleCloseApplication}
        desktopMaxWidth="1040px"
        tabletMaxWidth="534px"
        actionAcceptText={t("jobs.removeStudent")}
        onActionButtonClick={() => {
          firingStudent({
            classId: Number(params.classId),
            jobId: Number(jobState.id),
            data: [{ student_id: Number(studentId[0].id) }],
          })
        }}
      >
        {studentId?.map((el) => (
          <Application key={el.id} studentId={el} jobState={jobState} />
        ))}
      </Dialog>
      <Dialog
        actionAcceptButtonProps={{ disabled: isLoading }}
        open={isDialogOpenMultipleHiring}
        onClose={() => {
          handleCloseMultipleHiring()
          mixpanel.track("hire_students", {
            action: "cancel",
          })
        }}
        titleText={t("jobs.areYouSureYouWantToHireStudentTo", {
          jobname: jobState.title,
          count: allHiringStudent.length,
        })}
        actionAcceptText={t("jobs.accept")}
        onActionButtonClick={() =>
          hiringStudent({
            classId: Number(params.classId),
            jobId: Number(jobState.id),
            data: allHiringStudent,
          })
        }
      />
    </Stack>
  )
}

export default Employees
