import Snackbar from "components/common/snackbar/snackbar"
import { useSnackbar, OptionsObject } from "notistack"
import { SnackbarVariant } from "ts/types/Snackbar"

interface IArgsShowSnackbar {
  variant?: SnackbarVariant
  title: string
  description?: string
  options?: OptionsObject
}

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSnackbar = ({
    variant = "success",
    title,
    description,
    options,
  }: IArgsShowSnackbar) => {
    enqueueSnackbar(title, {
      ...options,
      content: (key, message) => (
        <Snackbar
          id={key}
          variant={variant}
          message={message}
          description={description}
        />
      ),
    })
  }

  return {
    showSnackbar,
  }
}
