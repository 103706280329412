import React, { useState } from "react"

import { Typography } from "@mui/material"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import Coin from "components/common/icon/coin"
import { regexNumberAndCommon } from "utils/constants"

import {
  ISuggestedJobsTypes,
  IUpdateToggleItem,
} from "../../addSuggestedJobs/AddSuggestedJobs.types"
import { TextFieldErrorStyles } from "../../addSuggestedJobs/TextFieldError.styles"

interface IProps {
  isSalary: boolean
  el: ISuggestedCreateJobs
  setIsSalary: React.Dispatch<React.SetStateAction<boolean>>
  updateDate: (arg: number, text: string, type: ISuggestedJobsTypes) => void
  type: ISuggestedJobsTypes
  handleSuggestedJobs: (el: ISuggestedCreateJobs) => void
  updateToggleItem: (arg: IUpdateToggleItem) => void
}

const UpdateInputNumber = ({
  isSalary,
  el,
  setIsSalary,
  updateDate,
  type,
  handleSuggestedJobs,
  updateToggleItem,
}: IProps) => {
  const { id, check, salary } = el
  const [inputName, setInputName] = useState(salary.toString())

  return (
    <>
      {!isSalary ? (
        <Typography
          variant="body1"
          color="mockup.neutral10"
          onClick={() => {
            setIsSalary(true)
            !check && handleSuggestedJobs(el)
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "70px",
            cursor: "pointer",
          }}
        >
          <Coin amount={salary as number} isEllipsis />
        </Typography>
      ) : (
        <TextFieldErrorStyles
          name={type}
          type="text"
          defaultValue={el.salary}
          onBlur={() => {
            setIsSalary(false)
            updateDate(Number(id ?? 0), inputName, type)
            updateToggleItem({ id: Number(id), text: inputName, type })
          }}
          onChange={(e) => {
            setInputName(e.target.value)
          }}
          onKeyPress={(event) => {
            if (!regexNumberAndCommon.test(event.key)) {
              event.preventDefault()
            }
          }}
          onPaste={(e) => e.preventDefault()}
          inputRef={(input) => input?.focus()}
        />
      )}
    </>
  )
}

export default UpdateInputNumber
