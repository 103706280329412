import React, { useEffect, useState } from "react"

import { ReactComponent as IconQuestionMark } from "@common_assets/svg/question.svg"
import { Skeleton, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { useQueryCharts } from "api/reactQuery/queries/chart"
import IconWrapper from "components/common/icon/iconWrapper"
import { format, subDays } from "date-fns"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts"
import { selectAccount } from "store/savings/savings.selectors"

import { useTransaction } from "../transactions/context"

interface IDataState {
  name: string
  value: number
}

const Chart = () => {
  const theme = useTheme()
  const [dataState, setDataState] = useState<IDataState[]>([])
  const classData = useAppSelector(selectAccount)
  const dataContext = useTransaction()
  const { t } = useTranslation()

  useEffect(() => {
    if (typeof dataContext?.data === "object") {
      const dataArr = Object.keys(
        dataContext.data.saving_account_chart_data
      ).map((key) => ({
        name: key.slice(-5),
        value: dataContext.data?.saving_account_chart_data[key] as number,
      }))
      setDataState(dataArr)
    }
  }, [dataContext])

  const isSavingsOrChecking = typeof dataContext?.data === "object" // if "true", it is "savings"

  const { isLoading } = useQueryCharts({
    start_date: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    options: {
      enabled: classData === null,
      staleTime: 0,
      onSuccess: (data) => {
        const dataArr = Object.keys(data?.data.student_balance_data).map(
          (key) => ({
            name: key.slice(-5),
            value: data?.data.student_balance_data[key],
          })
        )
        setDataState(dataArr)
      },
    },
  })

  if (isLoading || dataContext?.isLoading)
    return <Skeleton width="100%" height="200px" />

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.mockup.primary95}`,
        py: 2,
        flex: "3 1 500px",
        maxWidth: "100%",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          fontSize="20px"
          fontWeight={500}
          color="mockup.neutral10"
          padding={2}
        >
          {t("myActivity.totalBalance")}
        </Typography>
        <IconWrapper>
          <Tooltip
            title={
              isSavingsOrChecking
                ? t("myActivity.thisGraphShowsYourSavings")
                : t("myActivity.thisGraphShowsYourChecking")
            }
            componentsProps={{
              tooltip: {
                sx: {
                  color: "mockup.grey70",
                },
              },
            }}
          >
            <IconQuestionMark />
          </Tooltip>
        </IconWrapper>
      </Stack>
      <ResponsiveContainer height={200}>
        <LineChart
          data={dataState}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="value"
            stroke={theme.palette.mockup.primary_base}
            strokeWidth={2}
          />
          <CartesianGrid
            stroke={theme.palette.mockup.neutral90}
            strokeDasharray="5 5"
          />
          <XAxis
            dataKey="name"
            interval={5}
            label={{
              value: "DATE",
              offset: -5,
              position: "insideBottom",
              style: { fontSize: "12px" },
            }}
          />
          <YAxis
            label={{
              value: "BALANCE",
              position: "left",
              offset: -5,
              angle: -90,
              style: { fontSize: "12px" },
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  )
}
export default Chart
