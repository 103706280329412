import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostRemoveInvite,
  IPostTeacherManagementAccept,
  IPostTeacherManagementInvite,
  IPostTeacherManagementReject,
  IRemoveInvitationsArgs,
  IResponseTeacherManagementInvite,
  ITeacherManagementAcceptArgs,
  ITeacherManagementInviteArgs,
  ITeacherManagementRejectArgs,
} from "./teacherManagement.types"

export const useMutationTeacherManagementAccept = (
  args?: ITeacherManagementAcceptArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostTeacherManagementAccept>(
    async ({ teacherId }) => {
      return await axiosInstance.post(
        Endpoint.SCHOOL_TEACHERS_ID_ACCEPT.replace("ID", `${teacherId}`)
      )
    },
    args?.options
  )

export const useMutationTeacherManagementReject = (
  args?: ITeacherManagementRejectArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostTeacherManagementReject>(
    async ({ teacherId }) => {
      return await axiosInstance.post(
        Endpoint.SCHOOL_TEACHERS_ID_REJECT.replace("ID", `${teacherId}`)
      )
    },
    args?.options
  )

export const useMutationTeacherManagementInvite = (
  args?: ITeacherManagementInviteArgs
) =>
  useMutation<
    AxiosResponse<IResponseTeacherManagementInvite[]>,
    AxiosError,
    IPostTeacherManagementInvite
  >(
    async (data) => {
      return await axiosInstance.post(Endpoint.SCHOOL_INVITATIONS, data)
    },
    { ...args?.options }
  )

export const useMutationRemoveInvitations = (args?: IRemoveInvitationsArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostRemoveInvite>(
    async ({ invitation_id }) => {
      return await axiosInstance.delete(
        Endpoint.SCHOOL_INVITATIONS_ID.replace("ID", `${invitation_id}`)
      )
    },
    args?.options
  )
