import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IInputOption } from "ts/interfaces/Input"

import { getFieldError } from "../field.utils"

interface IProps<TOption extends IInputOption>
  extends Omit<
    AutocompleteProps<
      TOption,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    "renderInput" | "onChange" | "isOptionEqualToValue"
  > {
  name: string
  TextFieldProps?: TextFieldProps
}

export const AutocompleteField = <TOption extends IInputOption>({
  name,
  TextFieldProps,
  options,
  noOptionsText,
  loadingText,
  ...props
}: IProps<TOption>) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  const InputProps = TextFieldProps ? TextFieldProps.InputProps : undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error: fieldError } }) => {
        const { isError, errorMessage } = getFieldError(fieldError, t)

        return (
          <Autocomplete
            {...props}
            {...field}
            options={options}
            noOptionsText={noOptionsText || t("noOptions")}
            loadingText={loadingText || t("loading")}
            onChange={(_, data) => field.onChange(data)}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  {...TextFieldProps}
                  InputProps={{
                    ...params.InputProps,
                    ...InputProps,
                  }}
                  error={TextFieldProps?.error || isError}
                  helperText={TextFieldProps?.helperText || errorMessage}
                />
              )
            }}
          />
        )
      }}
    />
  )
}

export default AutocompleteField
