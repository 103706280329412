import { Paycheck_interval } from "../../components/module/addNewClass/AddNewClass.types"

export const payCheck_interval = [
  {
    label: "Weekly - on Fridays",
    value: Paycheck_interval.WEEKLY,
    id: 1,
  },
  {
    label: "Biweekly - first and third Friday",
    value: Paycheck_interval.BIWEEKLY,
    id: 2,
  },
  {
    label: "Monthly - first Friday",
    value: Paycheck_interval.MONTHLY,
    id: 3,
  },
]
