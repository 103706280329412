import { TransactionTypeShort } from "ts/types/Transaction"

import { getSetupClassTranslation } from "../SetupClassTeacher.utils"

export const getOptions = (variant: TransactionTypeShort) => {
  const tKey = getSetupClassTranslation(variant)

  return [
    {
      label: tKey.itemsAscending,
      value: JSON.stringify({
        order: "asc",
        orderBy: "title",
      }),
    },
    {
      label: tKey.itemsDescending,
      value: JSON.stringify({
        order: "desc",
        orderBy: "title",
      }),
    },
    {
      label: "setup.amountAscending",
      value: JSON.stringify({
        order: "asc",
        orderBy: "amount",
      }),
    },
    {
      label: "setup.amountDescending",
      value: JSON.stringify({
        order: "desc",
        orderBy: "amount",
      }),
    },
  ]
}
