export const dashboard = {
  welcome: "Welcome",
  welcomeBack: "Welcome back",
  todayIs: "Today is",
  classOverview: "Class overview",
  allClasses: "All classes",
  no: "No",
  student: "Student",
  todo: "To Do",
  item: "Item",
  quantity: "Quantity",
  pendingTransaction: "Pending transactions",
  value: "Value",
  filterByTimeRange: "Filter by time range",
  thisMonth: "This month",
  lastMonth: "Last month",
  year: "Year",
  sortBy: "Sort by",
  mostBonuses: "The most bonuses",
  fewestBonuses: "The fewest bonuses",
  studentSearch: "Student search",
  searchForStudent: "Search for a student",
  quickAction: "Quick action",
  sendTransaction: "Send transaction",
  storeUpdates: "Store updates",
  title: "Title",
  youDoNotHaveAnyStudentsYet: "You don’t have any students yet",
  headOnOverToYourClasses:
    "Head on over to your classes to add students and make the magic happen",
  youDoNotHaveAnyTasks: "You don’t have any pending transactions",
  greatWorkStayingOnTop:
    "Great work staying on top of your to do list! Check back later for new student purchases.",
  moreOptions: "More options",
  dataAnalyticsIsPremiumFeature:
    'Data analytics is a premium feature offered in our "Pro Subscription". Upgrade now to unlock this feature or learn more about our paid plans <Link>here</Link>.', // eslint-disable-line
  upgrade: "Upgrade",
  todoAccepted: "To do accepted",
  todoRejected: "To do rejected",
  congratulations: "Congratulations",
  yourAccountHasBeenSuccessfully:
    "<strong>Your account has been successfully upgraded.</strong> <br/> Click here to learn more about your new features such as adding a co-teacher and downloading reports!",
  paymentInformation:
    "Your payment is currently being processed, it should take up 2 minutes. Please refresh the page after that time to receive an updated account ",
  manageYourPayment: "Manage your payment",
  manageYourPaymentInformation: "Manage your payment information",
  logout: "Logout",
  weWereUnableToProcessYourLastPayment:
    "We were unable to process your last payment. Please update your payment information to continue enjoying ClassBank Pro.",
  schoolNameNoLongerHasAnActive:
    "{{schoolName}} no longer has an active ClassBank school subscription.",
  yourAccountHasBeenRestored:
    "Still interested in using ClassBank as a team? Let us know in the chat or at <strong>info@classbank.com</strong> and we’ll touch base with your school leadership! Downgrade to ClassEquity’s Free Essentials plan or manage your payment to activate your Pro subscription.",
  downgrade: "Downgrade",
  invalidEmail: "Invalid email",
  descriptionInvalidEmail:
    "Somebody is already logged in this application. If you want to continue, please logout and you will redirect to login page.",
  youDontHaveAnyStoreUpdates: "You don't have any store updates",
  classname: "Class",
  descriptionStoreUpdates:
    "Store updates is a feature included in the 'Pro Subscription' and 'School Subscription'. Learn more about our paid plans <Link>here</Link>. Upgrade to pro or school to unlock this feature.",
  dontSeeYourSchoolAddItHere: "Don't see your school add it here",
  startTypingtoFindTSchool: "Start typing to find school",
  searchSchool: "Search school",
  confirm: "Confirm",
  addSchool: "Add school",
  schoolHasBeenCreated: "School has been created",
}
