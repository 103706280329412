import { useEffect } from "react"

import { useLocation } from "react-router-dom"
import { scrollToTop } from "utils/scroll"

export const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop("auto")
  }, [pathname])
}
