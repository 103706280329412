import React, { Dispatch, FC, useState } from "react"

import { styled, Typography, useTheme } from "@mui/material"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import { useTranslation } from "react-i18next"

import {
  ISuggestedJobsTypes,
  IUpdateToggleItem,
} from "../addSuggestedJobs/AddSuggestedJobs.types"
import { TextFieldErrorStyles } from "../addSuggestedJobs/TextFieldError.styles"
import { removeAttributesHtmlRegex } from "../updateInput/UpdateInput.utility"
import { fillTextOnInut } from "./UpdateTitleInput.config"

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  color: theme.palette.mockup.neutral10,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "250px",
}))

interface IProps {
  updateDate: (arg: number, text: string, type: ISuggestedJobsTypes) => void
  isClicked: boolean
  type: ISuggestedJobsTypes
  setIsClicked: Dispatch<React.SetStateAction<boolean>>
  updateToggleItem: (args: IUpdateToggleItem) => void
  handleSuggestedJobs: (el: ISuggestedCreateJobs) => void
  el: ISuggestedCreateJobs
  text: string
}

const UpdateTitleInput: FC<IProps> = ({
  updateDate,
  isClicked,
  updateToggleItem,
  setIsClicked,
  type,
  el,
  handleSuggestedJobs,
  text,
}) => {
  const { check, id } = el
  const { t } = useTranslation()
  const theme = useTheme()
  const [inputName, setInputName] = useState(text)

  return (
    <>
      {!isClicked ? (
        <Typography
          sx={{
            cursor: "pointer",
            fontWeight: "500",
            fontSize: "14px",
          }}
          onClick={() => {
            setIsClicked(true)
            !check && handleSuggestedJobs(el)
          }}
        >
          {fillTextOnInut(
            text,
            removeAttributesHtmlRegex,
            t("jobs.clickToFillOut")
          )}
        </Typography>
      ) : (
        <TextFieldErrorStyles
          inputProps={{
            sx: {
              "&::placeholder": {
                color: theme.palette.mockup.error50,
              },
            },
          }}
          type={"text"}
          sx={{
            width: "100%",
          }}
          multiline
          name={type}
          defaultValue={text.replace(removeAttributesHtmlRegex, "")}
          onBlur={() => {
            setIsClicked(false)
            updateDate(Number(id), inputName, type)
            updateToggleItem({
              id: Number(id),
              text: inputName.trim(),
              type,
            })
          }}
          onChange={(e) => {
            setInputName(e.target.value)
          }}
          inputRef={(input) => input?.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
      )}
    </>
  )
}

export default UpdateTitleInput
