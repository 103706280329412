import { useState } from "react"

import { Box } from "@mui/material"
import { useQueryDashboardOverview } from "api/reactQuery/queries/dashboardOverview"
import { IQueryDashboardOverviewParams } from "api/reactQuery/queries/dashboardOverview.types"
import ErrorText from "components/common/error/errorText"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import BasicTable from "components/common/table/basicTable"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { selectUser } from "store/auth/auth.selectors"
import { checkSubscription } from "utils/api"

import NoStudents from "./noStudents"
import TableBodyRow from "./tableBodyRow"
import TableFilters from "./tableFilters"
import TableHeadRow from "./tableHeadRow"

const ClassOverview = () => {
  const userInfo = useAppSelector(selectUser)
  const [params, setParams] = useState<IQueryDashboardOverviewParams>({})

  const { data, isLoading, isError } = useQueryDashboardOverview({
    params,
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  return (
    <Box width="100%" flexBasis="100%" flexGrow="0">
      <BasicTable
        topSlot={
          <TableFilters
            onSubmit={({ classId, timeRange, sort }) => {
              setParams({
                class_id: classId !== "all" ? Number(classId) : undefined,
                time_range: timeRange,
                sort,
              })
              if (
                sort !== "most_bonuses" ||
                timeRange !== "this_month" ||
                classId !== "all"
              ) {
                mixpanel.track("class_overview", {
                  event_name_sort: sort,
                  event_name_timeRange: timeRange,
                })
              }
            }}
          />
        }
        head={<TableHeadRow />}
        body={
          data &&
          !isLoading &&
          data.data.map((student, index) => (
            <TableBodyRow key={student.id} student={student} index={index} />
          ))
        }
        bottomSlot={
          <>
            {isLoading && <TableSkeleton />}
            {isError && <ErrorText />}
            {!isLoading && data?.data.length === 0 && <NoStudents />}
          </>
        }
      />
    </Box>
  )
}

export default ClassOverview
