import React, { Dispatch, FC } from "react"

import { Box, Stack, Tab, Tabs, styled } from "@mui/material"
import { IPostResponseCreateJobs } from "api/reactQuery/mutations/jobs.types"
import { IGetResponseJobsDetails } from "api/reactQuery/queries/jobs.types"
import AddJob from "pages/jobs/addJob"
import { useTranslation } from "react-i18next"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

import Employees from "./employees"
import PendingApplications from "./pendingApplications"

const TabsStyle = styled(Tabs)(({ theme }) => ({
  "& .MuiButtonBase-root.MuiTab-root ": {
    fontSize: "16px",
    lineHeight: "24px",
    padding: "5px",
  },
  "& .MuiTabs-scroller ": {
    borderBottom: `1px solid ${theme.palette.mockup.neutral90}`,
  },
}))

interface IEditJob {
  jobsState: IGetResponseJobsDetails
  students: IObjectStudentDetailed[]
  setJobsState: Dispatch<React.SetStateAction<IGetResponseJobsDetails>>
  setRichEditorQualifications: Dispatch<React.SetStateAction<string>>
  richEditorQualifications: string
  id?: string
  editJobsDetails?: (state: IPostResponseCreateJobs) => void
  setRichEditorResponsibilities: Dispatch<React.SetStateAction<string>>
  richEditorResponsibilities: string
  getJobDetails?: IGetResponseJobsDetails
  tab: string
  setTab: Dispatch<React.SetStateAction<string>>
  classesId: number[]
  setId: Dispatch<React.SetStateAction<number | null>>
  currentId: number | null
  handleChangeInView: (value: boolean) => void
  isRefetching: boolean
}

const EditJob: FC<IEditJob> = ({
  students,
  editJobsDetails,
  jobsState,
  setJobsState,
  id,
  setRichEditorResponsibilities,
  setRichEditorQualifications,
  richEditorQualifications,
  richEditorResponsibilities,
  getJobDetails,
  tab,
  setTab,
  classesId,
  setId,
  currentId,
  handleChangeInView,
  isRefetching,
}) => {
  const { t } = useTranslation()
  const handleChange = (event: React.SyntheticEvent, newVal: string) => {
    setTab(newVal)
  }

  return (
    <Stack>
      <TabsStyle
        onChange={handleChange}
        value={tab}
        variant="scrollable"
        scrollButtons={false}
      >
        <Tab value="general" label={t("jobs.general")}></Tab>
        <Tab
          value="employees"
          label={t("jobs.employees", {
            curr: jobsState.employees.length,
            slot:
              jobsState.positions === null || jobsState.positions === ""
                ? "999"
                : jobsState.positions,
          })}
        ></Tab>
        <Tab
          value="pending_applications"
          label={t("jobs.pendingApplication", {
            count: jobsState.pending_applications_count,
          })}
        ></Tab>
      </TabsStyle>
      <Box sx={{ mt: 3 }}>
        {tab === "general" && (
          <AddJob
            getJobDetails={getJobDetails}
            editJobsDetails={editJobsDetails}
            richEditorQualifications={richEditorQualifications}
            setRichEditorQualifications={setRichEditorQualifications}
            jobsState={jobsState}
            setJobsState={setJobsState}
            id={id}
            richEditorResponsibilities={richEditorResponsibilities}
            setRichEditorResponsibilities={setRichEditorResponsibilities}
            classesId={classesId}
            setId={setId}
            currentId={currentId}
          />
        )}
        {tab === "employees" && (
          <Employees
            isRefetching={isRefetching}
            students={students}
            jobState={jobsState}
            handleChangeInView={handleChangeInView}
          />
        )}
        {tab === "pending_applications" && (
          <PendingApplications jobState={jobsState} />
        )}
      </Box>
    </Stack>
  )
}

export default EditJob
