import { OptionType } from "./DialogAddSuggested.types"

export const suggestedItems: {
  expense: OptionType[]
  bonus: OptionType[]
  fine: OptionType[]
} = {
  bonus: [
    {
      id: 1,
      title: "setup.teacherChoice",
      amount: 1,
      type: "es",
    },
    {
      id: 2,
      title: "setup.participation",
      amount: 1,
      type: "es",
    },
    {
      id: 3,
      title: "setup.showingRespect",
      amount: 2,
      type: "es",
    },
    {
      id: 4,
      title: "setup.showingPerseverance",
      amount: 2,
      type: "es",
    },
    {
      id: 5,
      title: "setup.showingKindness",
      amount: 2,
      type: "es",
    },
    {
      id: 6,
      title: "setup.helpingAPeer",
      amount: 3,
      type: "es",
    },
    {
      id: 7,
      title: "setup.complimentFromAnotherTeacher",
      amount: 3,
      type: "es",
    },
    {
      id: 8,
      title: "setup.upholdingClassroomContract",
      amount: 3,
      type: "es",
    },
    {
      id: 9,
      title: "setup.demonstratingGrowth",
      amount: 4,
      type: "es",
    },
    {
      id: 10,
      title: "setup.hundredClassAttendanceForTheDay",
      amount: 5,
      type: "es",
    },
    {
      id: 11,
      title: "setup.teacherChoice",
      amount: 5,
      type: "ms",
    },
    {
      id: 12,
      title: "setup.participation",
      amount: 5,
      type: "ms",
    },
    {
      id: 13,
      title: "setup.showingRespect",
      amount: 10,
      type: "ms",
    },
    {
      id: 14,
      title: "setup.showingPerseverance",
      amount: 10,
      type: "ms",
    },
    {
      id: 15,
      title: "setup.showingKindness",
      amount: 10,
      type: "ms",
    },
    {
      id: 16,
      title: "setup.helpingAPeer",
      amount: 15,
      type: "ms",
    },
    {
      id: 17,
      title: "setup.complimentFromAnotherTeacher",
      amount: 15,
      type: "ms",
    },
    {
      id: 18,
      title: "setup.upholdingClassroomContract",
      amount: 15,
      type: "ms",
    },
    {
      id: 19,
      title: "setup.demonstratingGrowth",
      amount: 15,
      type: "ms",
    },
    {
      id: 20,
      title: "setup.hundredClassAttendanceForTheDay",
      amount: 20,
      type: "ms",
    },
  ],
  fine: [
    {
      id: 1,
      title: "setup.noName",
      amount: 1,
      type: "es",
    },
    {
      id: 2,
      title: "setup.talkingDuringInstruction",
      amount: 2,
      type: "es",
    },
    {
      id: 3,
      title: "setup.offTask",
      amount: 2,
      type: "es",
    },
    {
      id: 4,
      title: "setup.dishonesty",
      amount: 3,
      type: "es",
    },
    {
      id: 5,
      title: "setup.noName",
      amount: 5,
      type: "ms",
    },
    {
      id: 6,
      title: "setup.talkingDuringInstruction",
      amount: 10,
      type: "ms",
    },
    {
      id: 7,
      title: "setup.offTask",
      amount: 10,
      type: "ms",
    },
    {
      id: 8,
      title: "setup.phoneInClas",
      amount: 15,
      type: "ms",
    },
    {
      id: 9,
      title: "setup.dishonesty",
      amount: 20,
      type: "ms",
    },
  ],
  expense: [
    {
      id: 1,
      title: "setup.rent",
      amount: 1,
      type: "es",
    },
    {
      id: 2,
      title: "setup.rent",
      amount: 70,
      type: "ms",
    },
    {
      id: 3,
      title: "setup.utilities",
      amount: 10,
      type: "ms",
    },
    {
      id: 4,
      title: "setup.rent",
      amount: 700,
      type: "ps",
    },
    {
      id: 5,
      title: "setup.utilities",
      amount: 100,
      type: "ps",
    },
  ],
}
