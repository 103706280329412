import { Box, styled } from "@mui/material"

export const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mockup.primary99,
  flex: "1 1 auto",
  padding: "28px 16px",
  [theme.breakpoints.up("tablet")]: {
    padding: "40px 24px",
  },
}))
