import { FC, useEffect, useRef, useState } from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check-curved.svg"
import { Button, CircularProgress, Stack } from "@mui/material"
import { Endpoint } from "api"
import { createMutationPostImage } from "api/reactQuery/mutations/images"
import IconWrapper from "components/common/icon/iconWrapper"
import { useCustomSnackbar } from "hooks/snackbar"
import { useUserRole } from "hooks/user"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { StyledImage, StyledImageContainer } from "./FileField.styles"

interface IProps {
  fileType: string
  name: string
  isChecked?: boolean
  onImageClick?: () => void
  onImageAdd?: (url: string) => void
  onImageRemove?: (url: string) => void
}

const FileField: FC<IProps> = ({
  fileType,
  name,
  isChecked = false,
  onImageClick,
  onImageAdd,
  onImageRemove,
}) => {
  const hiddenFileInputEl = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const userRole = useUserRole()
  const params = useParams()

  const { showSnackbar } = useCustomSnackbar()

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)

  const handleClick = () => {
    if (hiddenFileInputEl.current) {
      hiddenFileInputEl.current.click()
    }
  }

  const useTeacherCreateImage = createMutationPostImage(Endpoint.IMAGES)
  const useHelperCreateImage = createMutationPostImage(
    Endpoint.ID_HELPER_HR_IMAGES
  )

  const { mutate: teacherCreate, isLoading: createImageLoading } =
    useTeacherCreateImage({
      options: {
        onSuccess: (data) => {
          setValue(name, data.data.url)
          if (onImageAdd) onImageAdd(data.data.url)
          showSnackbar({ title: t("fileUploadedSuccessfully") })
        },
        onError: () => {
          showSnackbar({
            title: t("pictureShouldHasOnly2Mb"),
            variant: "error",
          })
        },
      },
    })

  const { mutate, isLoading } = useHelperCreateImage({
    options: {
      onSuccess: (data) => {
        setValue(name, data.data.url)
        if (onImageAdd) onImageAdd(data.data.url)
        showSnackbar({ title: t("fileUploadedSuccessfully") })
      },
      onError: () => {
        showSnackbar({
          title: t("pictureShouldHasOnly2Mb"),
          variant: "error",
        })
      },
    },
    ...(userRole === "student" && { id: params.classId }),
  })

  useEffect(() => {
    if (selectedFile) {
      userRole === "teacher"
        ? teacherCreate({ upload: selectedFile })
        : mutate({ upload: selectedFile })
    }
  }, [selectedFile])

  const imageWatcher = useWatch({ name })

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={{ mobile: "8px", tablet: "12px" }}
    >
      <input
        ref={hiddenFileInputEl}
        type="file"
        style={{ display: "none" }}
        accept={fileType}
        onChange={(e) => {
          if (e.target.files?.length) {
            setSelectedFile(e.target.files[0])
          }
          e.target.value = ""
        }}
      />
      <StyledImageContainer
        border={(theme) =>
          isChecked
            ? `2px solid ${theme.palette.mockup.neutral0}`
            : `2px solid ${theme.palette.mockup.primary95}`
        }
        sx={{ cursor: onImageClick && imageWatcher ? "pointer" : "auto" }}
        onClick={onImageClick}
      >
        {isChecked && (
          <IconWrapper
            color="mockup.neutral0"
            position="absolute"
            top="-6px"
            right="-6px"
            width="16px"
            height="16px"
          >
            <IconCheck />
          </IconWrapper>
        )}
        {(isLoading || createImageLoading) && (
          <CircularProgress
            sx={{ mr: "4px", position: "absolute" }}
            size="32px"
          />
        )}
        {imageWatcher && <StyledImage src={imageWatcher} />}
      </StyledImageContainer>
      <Stack direction={{ mobile: "column", tablet: "row" }}>
        <Button
          variant="outlined"
          sx={(theme) => ({
            textTransform: "uppercase",
            paddingX: "14px",
            [theme.breakpoints.up("tablet")]: {
              paddingX: "22px",
            },
          })}
          onClick={() => {
            handleClick()
          }}
        >
          {t("uploadPicture")}
        </Button>
        <Button
          variant="text"
          sx={(theme) => ({
            textTransform: "uppercase",
            paddingX: "14px",
            [theme.breakpoints.up("tablet")]: {
              paddingX: "16px",
            },
          })}
          onClick={() => {
            if (onImageRemove) onImageRemove(imageWatcher)
            setSelectedFile(undefined)
            setValue(name, "")
          }}
        >
          {t("removePicture")}
        </Button>
      </Stack>
    </Stack>
  )
}

export default FileField
