import { FC } from "react"

import { useIsBreakpointUp } from "hooks/breakpoint"
import { TransactionTypeShort } from "ts/types/Transaction"

import Listing from "./listing"
import Table from "./table"

interface IProps {
  variant: TransactionTypeShort
}

const SetupClassTeacher: FC<IProps> = ({ variant }) => {
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <>
      {isTablet ? <Table variant={variant} /> : <Listing variant={variant} />}
    </>
  )
}

export default SetupClassTeacher
