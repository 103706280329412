import { ReactComponent as Waiting } from "@common_assets/svg/waitingBall.svg"
import { Box, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const WelcomeSite = () => {
  const { t } = useTranslation()
  return (
    <Grid item sx={{ margin: "auto", textAlign: "center" }}>
      <Waiting />
      <Box>
        <Typography variant="subtitle1" color="mockup.darkBlue">
          {t("studentJobs.thereAreNoOpenPositionsYet")}
        </Typography>
        <Typography variant="body2" fontWeight="500" color="mockup.neutral20">
          {t("studentJobs.checkBackLaterForNewJobListings")}
        </Typography>
      </Box>
    </Grid>
  )
}

export default WelcomeSite
