import { passwordSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  password: "",
  current_password: "",
}

export const validateSchema = yup.object({
  password: passwordSchema,
  current_password: requiredStringSchema,
})
