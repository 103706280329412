import { FC, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import { useMutationPostClassesTransactions } from "api/reactQuery/mutations/classesTransactions"
import { IPostClassesTransactionsInput } from "api/reactQuery/mutations/classesTransactions.types"
import Dialog from "components/common/dialog/dialog"
import Transactions from "components/form/common/formPart/transactions"
import {
  transactionsDefaultValue,
  transactionsSchema,
} from "components/form/common/formPart/transactions/Transactions.config"
import { ITransactionsData } from "components/form/common/formPart/transactions/Transactions.types"
import {
  getCombinedTransactions,
  getIsNoTransactionAdded,
} from "components/form/common/formPart/transactions/Transactions.utils"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectSound } from "store/utility/utility.selectors"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import {
  ISalaryTransactionApiCreate,
  ITransactionApiCreate,
} from "ts/interfaces/Transaction"
import { TransactionType } from "ts/types/Transaction"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"
import { checkTransaction } from "utils/sound"

interface IProps {
  defaultExpandedSections?: TransactionType[]
  isOpen: boolean
  handleClose: () => void
  onSuccess?: () => void
  studentsIds: number[]
  isToggleAll?: boolean
  excludedItems?: IObjectStudentDetailed[]
  file?: string
  setIsToggle?: React.Dispatch<React.SetStateAction<boolean>>
  action?: string
  setVariables?: React.Dispatch<
    React.SetStateAction<IPostClassesTransactionsInput | null>
  >
}

const TransactionsDialogForm: FC<IProps> = ({
  defaultExpandedSections,
  isOpen,
  handleClose,
  onSuccess,
  studentsIds,
  isToggleAll,
  excludedItems,
  file,
  setIsToggle,
  action,
  setVariables,
}) => {
  const { t } = useTranslation()

  const excludedId = excludedItems?.map((el) => el.id)
  const { showSnackbar } = useCustomSnackbar()
  const soundChecked = useAppSelector(selectSound)
  const queryClient = useQueryClient()
  const [transactionType, setTransactionType] = useState<
    (ITransactionApiCreate | ISalaryTransactionApiCreate)[]
  >([])

  const classId = useClassId()

  const methods = useForm<ITransactionsData>({
    resolver: yupResolver(transactionsSchema),
    defaultValues: transactionsDefaultValue,
  })

  useEffect(() => {
    checkTransaction(transactionType, soundChecked)
  }, [transactionType])

  const { mutate, isLoading } = useMutationPostClassesTransactions({
    options: {
      onSuccess: (_, variables) => {
        setTransactionType(variables.data.transactions)

        const bonuses = variables.data.transactions.filter(
          (transaction) => transaction.type === "bonus"
        )
        const fines = variables.data.transactions.filter(
          (transaction) => transaction.type === "fine"
        )
        const expenses = variables.data.transactions.filter(
          (transaction) => transaction.type === "expense"
        )
        const paychecks = variables.data.transactions.filter(
          (transaction) => transaction.type === "salary"
        )
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "transactions",
          bonuses: bonuses.length,
          fines: fines.length,
          expenses: expenses.length,
          paychecks: paychecks.length,
        })

        if (!!window.Intercom) {
          window.Intercom("trackEvent", "amount of transactions", {
            bonuses: bonuses.length,
            fines: fines.length,
            expenses: expenses.length,
            paychecks: paychecks.length,
          })
        }
        setVariables && setVariables(variables)
        queryClient.invalidateQueries("classesStudentsCopy")

        showSnackbar({ title: t("students.transactionsSentSuccessfully") })
        onSuccess && onSuccess()
        setIsToggle && setIsToggle(false)
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const handleActionButtonClick = () => {
    const data = methods.getValues()

    if (getIsNoTransactionAdded(data)) {
      showSnackbar({
        title: t("students.selectAtLeaseOneTransaction"),
        variant: "info",
      })
      return
    }

    const allTransactions = getCombinedTransactions(data)
    if (allTransactions.length > 20) {
      showSnackbar({
        title: t("students.selectUpTo20Transactions"),
        variant: "info",
      })
      return
    }

    methods.handleSubmit(() => onSubmit(allTransactions))()
  }

  const onSubmit = (
    allTransactions: (ITransactionApiCreate | ISalaryTransactionApiCreate)[]
  ) => {
    if (isToggleAll && !!file?.length) {
      mutate({
        classId: Number(classId),
        data: {
          send_to_whole_class: false,
          student_ids: studentsIds,
          transactions: allTransactions,
        },
      })
    } else if (isToggleAll && !file?.length) {
      mutate({
        classId: Number(classId),
        data: {
          send_to_whole_class: isToggleAll,
          excluded_student_ids: excludedId,
          transactions: allTransactions,
        },
      })
    } else {
      mutate({
        classId: Number(classId),
        data: {
          send_to_whole_class: isToggleAll,
          student_ids: studentsIds,
          transactions: allTransactions,
        },
      })
    }
  }

  return (
    <Dialog
      titleText={t("students.sendTransaction")}
      onActionButtonClick={handleActionButtonClick}
      isLoading={isLoading}
      open={isOpen}
      onClose={() => {
        handleClose()
        mixpanel.track("send_transaction", {
          event_name: action
            ? "money_sign"
            : !excludedId?.length
            ? "select_all"
            : "select_individuals",
          action: "cancel",
        })
      }}
      desktopMaxWidth="1040px"
      tabletMaxWidth="720px"
      actionAcceptButtonProps={{ disabled: isLoading }}
    >
      <Box width="100%" component="form">
        <FormProvider {...methods}>
          <Transactions
            onClickForm={(e) =>
              handleKeyDown(e, handleActionButtonClick, isLoading)
            }
            classId={Number(classId)}
            expandedSections={defaultExpandedSections}
          />
        </FormProvider>
      </Box>
    </Dialog>
  )
}

export default TransactionsDialogForm
