import { ThemeOptions } from "@mui/material"

const muiTab: ThemeOptions["components"] = {
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: "auto",
      },
      flexContainer: ({ theme }) => ({
        gap: "40px",
        [theme.breakpoints.up("tablet")]: {
          gap: "32px",
        },
      }),
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: "0",
        paddingBottom: "10px",
        minHeight: "auto",
        textTransform: "initial",
        fontSize: "14px",
        lineHeight: "18px",
        minWidth: "auto",
        color: theme.palette.mockup.neutral10,

        [theme.breakpoints.up("tablet")]: {
          gap: "32px",
          paddingBottom: "8px",
        },

        "&a, &a:visited": {
          color: theme.palette.mockup.neutral10,
        },

        "&.Mui-selected": {
          color: theme.palette.mockup.primary20,
        },
      }),
    },
  },
}

export default muiTab
