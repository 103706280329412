import { FC } from "react"

import { Box, Stack, Typography } from "@mui/material"
import SearchbarForm from "components/form/searchbarForm"
import { ISearchbarFormState } from "components/form/searchbarForm/SearchbarForm.types"
import { useTranslation } from "react-i18next"

interface IProps {
  availableItemsCount: number
  onSubmit: (data: ISearchbarFormState) => void
}

const TopSearchbar: FC<IProps> = ({ availableItemsCount, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Stack
      direction={{ mobile: "column", tablet: "row" }}
      gap={{ mobile: "8px", tablet: "16px" }}
      alignItems={{ mobile: "start", tablet: "center" }}
      justifyContent="space-between"
    >
      <Box
        bgcolor="common.white"
        borderRadius="16px"
        width="100%"
        maxWidth={{ mobile: "auto", tablet: "470px" }}
      >
        <SearchbarForm
          label={t("storeStudent.searchForItem")}
          placeholder={t("storeStudent.searchForItem")}
          onSubmit={onSubmit}
        />
      </Box>
      <Typography
        variant="body3"
        fontWeight={400}
        color="mockup.netural10"
        minWidth="max-content"
      >
        {t("storeStudent.itemsAvailable", {
          count: availableItemsCount,
        })}
      </Typography>
    </Stack>
  )
}

export default TopSearchbar
