import { useEffect } from "react"

import { Button, Box } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

import SuccessComponent from "./successComponent"

const Payment = () => {
  const { isOpen, handleOpen } = useDialog()
  const navigate = useNavigate()

  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      customActionButtonsSlot={
        <Box mb={4} textAlign="center">
          <Button onClick={() => navigate(RoutePath.HOMEPAGE)}>Continue</Button>
        </Box>
      }
      desktopMaxWidth="440px"
      onIconClose={false}
    >
      <SuccessComponent />
    </Dialog>
  )
}

export default Payment
