import { Typography } from "@mui/material"
import { useQueryTeacherRegistrationData } from "api/reactQuery/queries/teacherRegistrationData"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { selectToken } from "store/utility/utility.selectors"
import { clearAuthDataLocalStorage } from "utils/authData"

const CompareAccount = () => {
  const { t } = useTranslation()
  const token = useAppSelector(selectToken)
  const userInfo = useAppSelector(selectUser)

  const { isOpen, handleOpen, handleClose } = useDialog()

  const logout = () => {
    clearAuthDataLocalStorage()
    handleClose()
    if (token) {
      localStorage.setItem("token", token)
    }
    window.location.replace(RoutePath.SIGNUP)
  }

  useQueryTeacherRegistrationData({
    token: token ?? "",
    options: {
      enabled: !!token,
      onSuccess: (data) => {
        if (data?.data.email !== userInfo?.user.email) {
          handleOpen()
        }
      },
    },
  })

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      titleText={t("dashboard.invalidEmail")}
      onActionButtonClick={() => logout()}
      actionAcceptText={t("dashboard.logout")}
    >
      <Typography color="mockup.neutral10" variant="body2">
        {t("dashboard.descriptionInvalidEmail")}
      </Typography>
    </Dialog>
  )
}

export default CompareAccount
