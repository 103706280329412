import { Box, Typography } from "@mui/material"
import { IResponseDashboardStoreUpdates } from "api/reactQuery/queries/dashboardTodo.types"
import ActionButtons from "components/common/button/actionButtons"
import Coin from "components/common/icon/coin"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { CSSTransition, TransitionGroup } from "react-transition-group"

interface IProps {
  data: IResponseDashboardStoreUpdates[] | []
}

const StoreUpdatesBody = ({ data }: IProps) => {
  return (
    <TransitionGroup component={null}>
      {data.map((todo) => (
        <CSSTransition
          key={todo.id}
          classNames="animation-fade"
          timeout={700}
          unmountOnExit
        >
          <BasicTable.Row key={todo.id}>
            <BasicTable.Cell>
              <Typography
                sx={{ wordBreak: "break-all" }}
                variant="body1"
                color="mockup.neutral10"
              >
                {todo.name}
              </Typography>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <Typography
                sx={{ wordBreak: "break-all" }}
                variant="body1"
                color="mockup.neutral10"
              >
                {todo.class_.name}
              </Typography>
            </BasicTable.Cell>
            <BasicTable.Cell position="relative">
              <Typography
                variant="body2"
                color="mockup.neutral10"
                sx={{
                  verticalAlign: "center",
                }}
              >
                {todo.quantity_in_stock}
              </Typography>
            </BasicTable.Cell>
            <BasicTable.Cell justifyContent="center">
              <Coin amount={`${todo.price}`} />
            </BasicTable.Cell>
            <BasicTable.Cell>
              <Box px="4px">
                <ActionButtons item={todo} dashboardIcons />
              </Box>
            </BasicTable.Cell>
          </BasicTable.Row>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default StoreUpdatesBody
