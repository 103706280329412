import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import { IAsyncTaskArgs, IResponseAsyncTask } from "./tasks.types"

export const useQueryAsyncTask = (args?: IAsyncTaskArgs) =>
  useQuery<AxiosResponse<IResponseAsyncTask>, AxiosError>(
    ["asyncTask"],
    async () =>
      axiosInstance(
        Endpoint.ASYNCTASKS_ID_STATE.replace("ID", `${args?.task_id}`)
      ),
    {
      ...args?.options,
    }
  )
