import { FC, memo, useEffect, useState } from "react"

import { Checkbox, Typography } from "@mui/material"
import {
  useMutationPostClassesStoreClerkItems,
  useMutationPostClassesStoreItems,
} from "api/reactQuery/mutations/classesStoreItems"
import { AxiosResponse } from "axios"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"
import HelpLink from "components/common/link/helpLink"
import BasicTable from "components/common/table/basicTable"
import { ISuggestedItem } from "components/module/allItemsStoreTeacher/common/viewCommonElementsContainer/dialogAddSuggestedItems/DialogAddSuggestedItems.types"
import { useSelectedItems } from "hooks/listing"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectUser } from "store/auth/auth.selectors"
import { IClass, IStudentClassGet } from "ts/interfaces/Class"
import { getErrorMessage } from "utils/api"
import { isStudent } from "utils/roleCheck"

import { suggestedItems } from "./DialogAddSuggestedItems.config"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const DialogAddSuggestedItems: FC<IProps> = ({ isOpen, onClose }) => {
  const classId = useClassId()
  const userInfo = useAppSelector(selectUser)
  const [typeOfSuggestedItems, setTypeOfSuggestedItems] =
    useState<AxiosResponse<IClass | IStudentClassGet> | null>(null)
  const [currentSuggestedItems, setCurrentSuggestedItems] =
    useState<ISuggestedItem[]>()

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  useEffect(() => {
    if (userInfo !== null) {
      const isStoreClerk = isStudent(userInfo.user)
      const suggestedItems = queryClient.getQueryData([
        isStoreClerk ? "studentClasses" : "classes",
        Number(classId),
      ]) as AxiosResponse<IClass | IStudentClassGet>

      setTypeOfSuggestedItems(suggestedItems)
    }
  }, [userInfo])

  useEffect(() => {
    let currentType: "es" | "ms" | "ps" | undefined = undefined

    if (
      typeOfSuggestedItems?.data.grade === "K" ||
      Number(typeOfSuggestedItems?.data.grade) <= 2
    ) {
      currentType = "es"
    }

    if (
      Number(typeOfSuggestedItems?.data.grade) > 2 &&
      Number(typeOfSuggestedItems?.data.grade) <= 5
    ) {
      currentType = "ms"
    }

    if (
      Number(typeOfSuggestedItems?.data.grade) > 5 &&
      Number(typeOfSuggestedItems?.data.grade) <= 12
    ) {
      currentType = "ps"
    }

    if (currentType) {
      setCurrentSuggestedItems(suggestedItems[currentType])
    }
  }, [typeOfSuggestedItems])

  const { t } = useTranslation()

  const {
    getIsChecked,
    toggleItem,
    toggleAll,
    clearSelectedItems,
    selectedItems,
  } = useSelectedItems(currentSuggestedItems as ISuggestedItem[])

  const { isLoading, mutate: postTransactionItems } =
    useMutationPostClassesStoreItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["classesStoreItems", Number(classId)])
          showSnackbar({
            title: t("store.suggestedItemsHaveBeenAdded"),
          })
          clearSelectedItems()
          onClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const {
    isLoading: isLoadingStoreClerk,
    mutate: postTransactionItemsStoreClerk,
  } = useMutationPostClassesStoreClerkItems({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "classesStoreClerkItems",
          Number(classId),
        ])
        showSnackbar({
          title: t("store.suggestedItemsHaveBeenAdded"),
        })
        clearSelectedItems()
        onClose()
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessage(error)),
          variant: "error",
        })
      },
    },
  })

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelectedItems()
        onClose()
      }}
      titleText={t("store.addSuggestedItems")}
      desktopMaxWidth="867px"
      tabletMaxWidth="867px"
      isLoading={isLoading || isLoadingStoreClerk}
      onActionButtonClick={() => {
        userInfo && userInfo.type === "student"
          ? postTransactionItemsStoreClerk({
              classId: Number(classId),
              data: {
                items: [...selectedItems].map((item) => ({
                  name: t(item.name),
                  price: item.price,
                  icon_url: item.icon_url,
                })),
              },
            })
          : postTransactionItems({
              classId: Number(classId),
              data: {
                items: [...selectedItems].map((item) => ({
                  name: t(item.name),
                  price: item.price,
                  icon_url: item.icon_url,
                })),
              },
            })
      }}
      actionAcceptButtonProps={{ disabled: !selectedItems.length }}
    >
      <BasicTable
        head={
          <BasicTable.Row>
            <BasicTable.Cell width="50px" px="8px">
              <Checkbox
                checked={currentSuggestedItems?.length === selectedItems.length}
                onChange={toggleAll}
                indeterminate={
                  currentSuggestedItems?.length !== selectedItems.length &&
                  !!selectedItems.length
                }
              />
            </BasicTable.Cell>
            <BasicTable.Cell
              minWidth="100px"
              tableCellProps={{ width: "100%" }}
            >
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.item")}
              </BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell width="80px">
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.value")}
              </BasicTable.HeadText>
            </BasicTable.Cell>
          </BasicTable.Row>
        }
        body={currentSuggestedItems?.map((item) => (
          <BasicTable.Row key={item.id}>
            <BasicTable.Cell px="8px">
              <Checkbox
                checked={getIsChecked(item)}
                onChange={() => {
                  toggleItem(item)
                }}
              />
            </BasicTable.Cell>
            <BasicTable.Cell>
              {item.icon_url && (
                <img
                  src={item.icon_url}
                  alt={item.name}
                  width="32px"
                  height="32px"
                  style={{ marginRight: "8px" }}
                />
              )}
              <Typography variant="body2" fontWeight={500}>
                {t(item.name)}
              </Typography>
            </BasicTable.Cell>
            <BasicTable.Cell>
              <Coin amount={`${item.price}`} />
            </BasicTable.Cell>
          </BasicTable.Row>
        ))}
      />
      {process.env.REACT_APP_TYPE === "classequity" && <HelpLink />}
    </Dialog>
  )
}

export default memo(DialogAddSuggestedItems)
