import React from "react"

import { ReactComponent as IconBulb } from "@common_assets/svg/bulb.svg"
import { Stack, Typography } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useTranslation } from "react-i18next"

const BatchInfo = () => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap="8px"
      borderBottom={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
      borderTop={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
      p="8px"
    >
      <IconWrapper width="14px" height="20px">
        <IconBulb width="14px" height="14px" viewBox="0 0 14 14" />
      </IconWrapper>
      <Typography
        variant="subtitle2"
        fontWeight={500}
        color={(theme) => theme.palette.mockup.neutral10}
      >
        {t("students.sendTransactionsToMoreThan")}
      </Typography>
    </Stack>
  )
}

export default BatchInfo
