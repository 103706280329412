import { ThemeOptions } from "@mui/material"

const muiSwitch: ThemeOptions["components"] = {
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        overflow: "visible",
        "& .MuiSwitch-switchBase": {
          padding: "3px 0 0 3px",
          margin: 2,
          transitionDuration: "300ms",
          color: theme.palette.mockup.primary80,
          "&.Mui-checked": {
            "& .MuiSwitch-thumb": {
              width: 22,
              height: 22,
            },
            transform: "translateX(16px)",
            color: theme.palette.mockup.primary90,
            padding: "0px",
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              backgroundColor: theme.palette.mockup.primary80,
              border: "none",
            },
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.common.white,
            boxShadow: "none",
            opacity: 0.7,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 1,
            border: `2px solid ${theme.palette.mockup.primary90}`,
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 16,
          height: 16,
        },
        ":hover .MuiSwitch-thumb": {
          boxShadow: "0 0 0 8px rgba(133,149,244,.2)",
        },
        "& .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb": {
          color: theme.palette.mockup.primary90,
        },
        "& .MuiSwitch-track": {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mockup.primary99,
          opacity: 1,
          border: `2px solid ${theme.palette.mockup.primary90}`,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
      }),
    },
  },
}

export default muiSwitch
