export const sortingTextMap = {
  first_name: "students.studentFirst",
  last_name: "students.studentLast",
  balance: "students.checkings",
  savings: "students.savings",
  total_balance: "students.balance",
}

export const sortingTextMapDashboard = {
  first_name: "students.student",
  value: "students.balance",
}

export const sortingOptions = [
  {
    label: "students.studentFirstAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.studentFirstDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.studentLastAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "last_name",
    }),
  },
  {
    label: "students.studentLastDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "last_name",
    }),
  },
  {
    label: "students.balanceAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "total_balance",
    }),
  },
  {
    label: "students.balanceDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "total_balance",
    }),
  },
  {
    label: "students.checkingsAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "balance",
    }),
  },
  {
    label: "students.checkingsDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "balance",
    }),
  },
  {
    label: "students.savingsAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "savings",
    }),
  },
  {
    label: "students.savingsDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "savings",
    }),
  },
]
export const sortingOptionsDashboard = [
  {
    label: "students.studentAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.studentDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "first_name",
    }),
  },
  {
    label: "students.balanceAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "value",
    }),
  },
  {
    label: "students.balanceDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "value",
    }),
  },
]
