import { useEffect, useState } from "react"

import { Box } from "@mui/material"
import { useQueryStudentTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll"
import { IStudentSideTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll.type"
import LoadMore from "components/common/listing/loadMore"
import PendingTransactionsList from "components/common/listing/pendingTransactionsList"
import TransactionDialog from "components/common/transactionDialog"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { emptyPendingTransactions } from "utils/pendingTransactions"

import Header from "./header"
import StatusView from "./statusView"
import TransactionsTable from "./transactionsTable"

const PendingTransactions = () => {
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")
  const [nextPendingTransactions, setNextPendingTransactions] = useState(0)
  const [pendingDataTransactions, setPendingDataTransactions] = useState<
    IStudentSideTransactionsAll[] | []
  >([])
  const [isNoMoreData, setIsNoMoreData] = useState(false)

  const [currentTransaction, setCurrentTransaction] = useState<
    IStudentSideTransactionsAll | undefined
  >(undefined)

  useEffect(() => {
    emptyPendingTransactions({
      data: pendingDataTransactions,
      isNoMoreData,
      refetch,
    })
  }, [pendingDataTransactions])

  const { isLoading, isError, refetch } = useQueryStudentTransactionsAll({
    page: nextPendingTransactions,
    limit: 3,
    is_pending: true,
    options: {
      onSuccess: (data) => {
        setPendingDataTransactions((prevState) => [...prevState, ...data?.data])

        if (!!data?.data.length) {
          setNextPendingTransactions((prev) => prev + 3)
        }
        if (data?.data.length < 2) {
          setIsNoMoreData(true)
        }
      },
    },
  })

  const handleTransactionClick = (transaction: IStudentSideTransactionsAll) => {
    setCurrentTransaction(transaction)
    handleOpen()
  }

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Box
      sx={{ height: "500px", overflowY: "auto" }}
      className="smooth-scrollbar"
    >
      {isTablet ? (
        <TransactionsTable
          data={pendingDataTransactions}
          handleTransactionClick={handleTransactionClick}
          topSlot={<Header />}
          bottomSlot={
            <>
              {!isError && !isLoading && (
                <LoadMore
                  data={pendingDataTransactions}
                  refetch={refetch}
                  isNoMoreData={isNoMoreData}
                  noMoreRecordsMessage={t(
                    "dashboardStudent.youDoNotHaveMoreTransactions"
                  )}
                />
              )}
              <StatusView
                isLoading={isLoading}
                isError={isError}
                isNoMoreData={isNoMoreData}
                dataLength={pendingDataTransactions.length}
              />
            </>
          }
        />
      ) : (
        <PendingTransactionsList
          data={pendingDataTransactions}
          isError={isError}
          handleTransactionClick={handleTransactionClick}
          header={<Header />}
          footer={
            <>
              {!!pendingDataTransactions.length && !isError && (
                <LoadMore
                  refetch={refetch}
                  isNoMoreData={isNoMoreData}
                  noMoreRecordsMessage={t(
                    "dashboardStudent.youDoNotHaveMoreTransactions"
                  )}
                />
              )}
              <StatusView
                isLoading={isLoading}
                isError={isError}
                isNoMoreData={isNoMoreData}
                dataLength={pendingDataTransactions.length}
              />
            </>
          }
        />
      )}
      <TransactionDialog
        open={isOpen}
        pendingData={pendingDataTransactions}
        setNextPendingTransactions={setNextPendingTransactions}
        setNewData={setPendingDataTransactions}
        onClose={() => {
          handleClose()
          setCurrentTransaction(undefined)
        }}
        transaction={currentTransaction}
      />
    </Box>
  )
}

export default PendingTransactions
