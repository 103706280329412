import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostStudentStorePurchaseArgs,
  IPostStudentStorePurchaseInput,
} from "./studentStorePurchase.types"

export const useMutationPostStudentStorePurchase = (
  args?: IPostStudentStorePurchaseArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStudentStorePurchaseInput>(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.STUDENT_ID_STORE_PURCHASE.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )
