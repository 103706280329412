import { TypographyStyleOptions } from "@mui/material/styles/createTypography"

export const mockupTypography: Record<string, TypographyStyleOptions> = {
  uiHeadingH1: {
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: 700,
  },
  uiHeadingH2: {
    fontSize: "32px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  uiHeadingH3: {
    fontSize: "28px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  uiHeadingH4: {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 700,
  },
  uiHeadingH5: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: 700,
  },
  uiSubtitle1: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  uiSubtitle2: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
  },
  uiBody1: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
  },
  uiBody2: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 700,
  },
  uiBody3: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 700,
  },
  uiButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
}
