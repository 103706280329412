import { Skeleton } from "@mui/material"
import { useQueryStudentBalance } from "api/reactQuery/queries/studentBalance"
import WelcomeBack from "components/common/welcomeBack"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"
import { isStudent } from "utils/roleCheck"

import WithoutSavingAccount from "./withoutSavingAccount"
import WithSavingAccount from "./withSavingAccount"

const WelcomeStudent = () => {
  const { data, isLoading, isError } = useQueryStudentBalance()

  const userInfo = useAppSelector(selectUser)
  const hasSavingAccount =
    userInfo && isStudent(userInfo.user) && userInfo.user.has_savings_accounts

  if (isLoading) return <Skeleton height="200px" />

  return (
    <WelcomeBack variant="student">
      {hasSavingAccount ? (
        <WithSavingAccount {...{ data: data?.data, isLoading, isError }} />
      ) : (
        <WithoutSavingAccount {...{ data: data?.data, isLoading, isError }} />
      )}
    </WelcomeBack>
  )
}

export default WelcomeStudent
