import { useCallback, useState } from "react"

export const usePopover = <T>() => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<T | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<T>) => {
    setPopoverAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setPopoverAnchorEl(null)
  }, [])

  return {
    popoverAnchorEl,
    handleClick,
    handleClose,
    setPopoverAnchorEl,
  }
}
