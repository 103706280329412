import { Box, styled } from "@mui/material"

export const PopoverBox = styled(Box)({
  display: "flex",
  columnGap: "8px",
  alignItems: "center",
})

export const SelectPropsCustomize = (isMobile: boolean) => {
  if (isMobile) {
    return {
      MenuProps: {
        anchorOrigin: {
          vertical: 45,
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        PaperProps: {
          style: {
            marginLeft: "-17px",
          },
        },
      },
      renderValue: (val: string) => {
        return val ? `Pay Frequency: ${val}` : ""
      },
    }
  } else {
    return {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
      renderValue: (val: string) => {
        return val ? `Pay Frequency: ${val}` : ""
      },
    }
  }
}
