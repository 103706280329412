/**
 * function parseQuillContent(html)
 * parse and sanitize HTML content from quill
 * @description
 * 1. Sanitize by DOMPurify
 * 2. check that node type is working on html elements like (ol,ul,li)
 * @example
 * if (node.nodeType === Node.ELEMENT_NODE)
 * @description
 *
 * 3. convert ul/ol attr to ol
 * 4. add data-list to li (depend on it was ordered or bullet before)
 * 5. convert and again sanitize before return do quill
 * @param{string} html
 * @returns {string} sanitized html string
 */

import DOMPurify from "dompurify"

export const parseQuillContent = (html: string): string => {
  const sanitizedHtml = DOMPurify.sanitize(html)
  const parser = new DOMParser()
  const doc = parser.parseFromString(sanitizedHtml, "text/html")

  const processLists = (node: Node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement
      if (
        element.tagName.toLowerCase() === "ul" ||
        element.tagName.toLowerCase() === "ol"
      ) {
        const ol = doc.createElement("ol")
        const listType =
          element.tagName.toLowerCase() === "ul" ? "bullet" : "ordered"
        while (element.firstChild) {
          const li = element.firstChild as HTMLElement
          if (li.tagName.toLowerCase() === "li") {
            if (!li.hasAttribute("data-list")) {
              li.setAttribute("data-list", listType)
            }
          }
          ol.appendChild(li)
        }
        element.parentNode?.replaceChild(ol, element)
      }
      element.childNodes.forEach(processLists)
    }
  }

  processLists(doc.body)
  return DOMPurify.sanitize(doc.body.innerHTML)
}
