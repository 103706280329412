import React from "react"

import { ReactComponent as BackgroundImage } from "@brand_assets/svg/left_side_pic.svg"
import { Grid } from "@mui/material"
import { colors, colorsBankee } from "themes/palette/colors"
import { getColorApp } from "utils/color"

const BackgroundImageLeft = () => {
  return (
    <Grid
      item
      desktop={4}
      position={"relative"}
      sx={{
        backgroundColor: getColorApp(
          colors.additional90,
          colorsBankee.primary20
        ),
      }}
    >
      <BackgroundImage
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
        }}
      />
    </Grid>
  )
}

export default BackgroundImageLeft
