import React, { Dispatch, useEffect, useState } from "react"

import { Box, Button, Typography } from "@mui/material"
import { IStudentAllTransactions } from "api/reactQuery/queries/transaction.types"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Type } from "ts/enums/Filtration"

import TableBodyRow from "./TableBodyRow"
import TableHeadRow from "./TableHeadRow"

interface IProps {
  copyArrayFiltersName: Type[]
  data: IStudentAllTransactions[]
  isLoading: boolean
  setNextTransactions: Dispatch<React.SetStateAction<number>>
  limitCounter: number
  isNextTransactions?: boolean
  isFetching: boolean
  setDataTransactions: Dispatch<React.SetStateAction<IStudentAllTransactions[]>>
}

const Table = ({
  isLoading,
  isNextTransactions,
  setNextTransactions,
  data,
  isFetching,
  limitCounter,
  setDataTransactions,
  copyArrayFiltersName,
}: IProps) => {
  const { t } = useTranslation()
  const [isScroll, setIsScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => setIsScroll(true))

    return () => {
      window.removeEventListener("scroll", () => setIsScroll(true))
    }
  }, [])

  return (
    <Box>
      <BasicTable
        head={<TableHeadRow />}
        body={
          <TransitionGroup component={null}>
            {data.map((transaction) => (
              <CSSTransition
                key={transaction.id}
                timeout={500}
                classNames="animation-fade"
              >
                <TableBodyRow
                  setIsScroll={setIsScroll}
                  isScroll={isScroll}
                  key={transaction.id}
                  transaction={transaction}
                  setDataTransactions={setDataTransactions}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        }
        bottomSlot={
          <div
            style={{
              display: isFetching || isLoading ? "block" : "flex",
              justifyContent: "center",
            }}
          >
            {isNextTransactions && !isLoading && !isFetching && (
              <Button
                variant="text"
                onClick={() => {
                  setNextTransactions((prev) => prev + limitCounter)
                }}
              >
                {t("studentProfile.loadMore")}
              </Button>
            )}
            {!data.length &&
              !isFetching &&
              !isLoading &&
              !!copyArrayFiltersName.length && (
                <Typography
                  variant="body1"
                  color="mockup.neutral10"
                  sx={{ py: 1 }}
                >
                  No results for: {copyArrayFiltersName.join(", ")}{" "}
                </Typography>
              )}
            {!data.length &&
              !copyArrayFiltersName.length &&
              !isFetching &&
              !isLoading && (
                <Typography
                  variant="body1"
                  color="mockup.neutral10"
                  sx={{ py: 2 }}
                >
                  {t("studentProfile.nothingToDisplay")}
                </Typography>
              )}
          </div>
        }
      />
    </Box>
  )
}

export default Table
