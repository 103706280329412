import React from "react"

import { Avatar, Checkbox, Stack, Typography } from "@mui/material"
import { HrResponse } from "api/reactQuery/queries/hr.types"
import { useDrag } from "react-dnd"

import { DraggingBoxStyle } from "./DraggableUser.style"

interface IDraggableUser {
  user: HrResponse
  checkedUsers: HrResponse[]
  onChange: () => void
}

const DraggableUser = ({ user, checkedUsers, onChange }: IDraggableUser) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "student",
    item: { user: [user] },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <DraggingBoxStyle
      ref={drag}
      isdragging={isDragging}
      sx={{ zIndex: 2, position: "relative" }}
    >
      <Checkbox
        checked={checkedUsers.some((checked) => checked.id === user.id)}
        onChange={onChange}
      />
      <Stack flexDirection="row" alignItems="center" columnGap={1}>
        <Avatar
          sx={{
            width: "32px",
            height: "32px",
          }}
          src={user.avatar_url}
          alt="avatar"
        />
        <Stack justifyContent="flex-end">
          <Typography
            variant="body2"
            color="mockup.neutral10"
            sx={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user.first_name} {user.last_name}
          </Typography>

          <Typography
            variant="body3"
            color="mockup.neutral40"
            sx={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user.jobs.sort((a, b) => a.localeCompare(b)).join(", ")}
          </Typography>
        </Stack>
      </Stack>
    </DraggingBoxStyle>
  )
}
export default DraggableUser
