import { Theme, ThemeOptions } from "@mui/material"

import { mockupTypography } from "./mockupTypography"

const typography = (theme: Theme): ThemeOptions["typography"] => ({
  h1: {
    ...mockupTypography.uiHeadingH3,
    [theme.breakpoints.up("tablet")]: {
      ...mockupTypography.uiHeadingH2,
    },
    [theme.breakpoints.up("desktop")]: {
      ...mockupTypography.uiHeadingH1,
    },
  },
  h2: {
    ...mockupTypography.uiHeadingH4,
    [theme.breakpoints.up("tablet")]: {
      ...mockupTypography.uiHeadingH3,
    },
    [theme.breakpoints.up("desktop")]: {
      ...mockupTypography.uiHeadingH2,
    },
  },
  h3: {
    ...mockupTypography.uiHeadingH5,
    [theme.breakpoints.up("tablet")]: {
      ...mockupTypography.uiHeadingH4,
    },
    [theme.breakpoints.up("desktop")]: {
      ...mockupTypography.uiHeadingH3,
    },
  },
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: {
    ...mockupTypography.uiSubtitle1,
  },
  subtitle2: {
    ...mockupTypography.uiSubtitle2,
  },
  body1: { ...mockupTypography.uiBody1 },
  body2: { ...mockupTypography.uiBody2 },
  body3: { ...mockupTypography.uiBody3 },
  button: { ...mockupTypography.uiButton },
  caption: undefined,
  overline: undefined,
})

export default typography
