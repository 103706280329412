import { ISortingState } from "hooks/listing"

export const getSortingText = <T>(
  sortingState: ISortingState<T>,
  map: Record<string, string>
) => {
  const { orderBy } = sortingState
  if (!orderBy) return "none"
  if (orderBy in map) {
    return map[orderBy as string]
  } else {
    return "none"
  }
}

export const getSortingTextSecond = (
  sortBy: string,
  map: Record<string, string>
) => {
  return map[sortBy]
}
