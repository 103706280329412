import { Button, Stack, Typography } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"

import SendTransactionForm from "./sendTransactionForm"

const QuickAction = () => {
  const isTablet = useIsBreakpointUp("tablet")
  const { t } = useTranslation()
  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <>
      <PrimaryTile width="100%" py="16px" px={isTablet ? "24px" : "16px"}>
        <Stack rowGap="16px">
          <Typography variant="subtitle1" color="mockup.neutral10">
            {t("dashboard.quickAction")}
          </Typography>
          <Button
            onClick={() => {
              handleOpen()
              mixpanel.track("send_quick_transaction", {
                action: "open",
              })
            }}
          >
            {t("dashboard.sendTransaction")}
          </Button>
        </Stack>
      </PrimaryTile>
      <SendTransactionForm isOpen={isOpen} onClose={handleClose} />
    </>
  )
}

export default QuickAction
