import { FC } from "react"

import { ReactComponent as IconEdit } from "@common_assets/svg/edit.svg"
import { ReactComponent as IconEyeClosed } from "@common_assets/svg/eye-closed.svg"
import { ReactComponent as IconEyeOpened } from "@common_assets/svg/eye-open.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import {
  useMutationPatchClassesStoreClerkItems,
  useMutationPatchClassesStoreItems,
} from "api/reactQuery/mutations/classesStoreItems"
import FabIconButton from "components/common/button/fabIconButton"
import FabGroup from "components/common/container/fabGroup"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { IClassStoreItem } from "ts/interfaces/Class"
import { getErrorMessage } from "utils/api"

import DialogDelete from "./dialogDelete"
import DialogEdit from "./dialogEdit"

interface IProps {
  item: IClassStoreItem
  dashboardIcons?: boolean
}

const ActionButtons: FC<IProps> = ({ item, dashboardIcons }) => {
  const { t } = useTranslation()
  const classId = useClassId()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const location = useLocation()

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
  } = useDialog()

  const {
    isOpen: isOpenEdit,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
  } = useDialog()

  const { mutate: patchItem, isLoading: isLoadingPatch } =
    useMutationPatchClassesStoreItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["classesStoreItems", Number(classId)])

          showSnackbar({
            title: item.out_of_stock
              ? t("store.itemMarkedAsInStock", {
                  name: item.name,
                })
              : t("store.itemMarkedAsOutOfStock", {
                  name: item.name,
                }),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const { mutate: patchItemClerk, isLoading: isLoadingPatchClerk } =
    useMutationPatchClassesStoreClerkItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "classesStoreClerkItems",
            Number(classId),
          ])

          showSnackbar({
            title: item.out_of_stock
              ? t("store.itemMarkedAsInStock", {
                  name: item.name,
                })
              : t("store.itemMarkedAsOutOfStock", {
                  name: item.name,
                }),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  return (
    <>
      <FabGroup>
        {!dashboardIcons && (
          <FabIconButton
            Icon={!item.out_of_stock ? <IconEyeOpened /> : <IconEyeClosed />}
            onClick={() => {
              location.pathname.includes("storeClerk")
                ? patchItemClerk({
                    classId: Number(classId),
                    data: {
                      items: [{ ...item, out_of_stock: !item.out_of_stock }],
                    },
                  })
                : patchItem({
                    classId: Number(classId),
                    data: {
                      items: [{ ...item, out_of_stock: !item.out_of_stock }],
                    },
                  })
            }}
            tooltipText={
              !item.out_of_stock
                ? t("store.hideItemInStore")
                : t("store.showItemInStore")
            }
            isLoading={
              location.pathname.includes("storeClerk")
                ? isLoadingPatchClerk
                : isLoadingPatch
            }
          />
        )}
        <FabIconButton
          Icon={<IconEdit />}
          onClick={() => {
            handleOpenEdit()
          }}
          tooltipText={t("store.editItem")}
        />
        <FabIconButton
          Icon={<IconTrash />}
          onClick={() => {
            handleOpenDelete()
          }}
          tooltipText={t("store.deleteItem")}
        />
      </FabGroup>
      {isOpenDelete && (
        <DialogDelete
          dashboardIcons={dashboardIcons}
          item={item}
          isOpen={isOpenDelete}
          handleClose={handleCloseDelete}
        />
      )}
      {isOpenEdit && (
        <DialogEdit
          dashboardIcons={dashboardIcons}
          item={item}
          isOpen={isOpenEdit}
          handleClose={handleCloseEdit}
        />
      )}
    </>
  )
}

export default ActionButtons
