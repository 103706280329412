import { FC } from "react"

import Dialog from "components/common/dialog/dialog"
import { useTranslation } from "react-i18next"

interface IProps {
  open: boolean
  onClose: () => void
  onActionButtonClick: () => void
}

const DialogClearApplication: FC<IProps> = ({
  open,
  onClose,
  onActionButtonClick,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onActionButtonClick={onActionButtonClick}
      titleText={t("jobs.areYouSureYouWantToClearAllApplications")}
      desktopMaxWidth="534px"
      tabletMaxWidth="344px"
      actionAcceptText={t("jobs.clear")}
    >
      {children}
    </Dialog>
  )
}

export default DialogClearApplication
