import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IGetJobsArgs,
  IGetJobsDetailsArgs,
  IGetJobsResponse,
  IGetResponseJobsDetails,
} from "./jobs.types"

export const useQueryGetJobs = ({ options, classId }: IGetJobsArgs) =>
  useQuery<AxiosResponse<IGetJobsResponse[]>, AxiosError>(
    ["jobs", classId],
    async () =>
      await axiosInstance(Endpoint.CLASSES_JOBS.replace("ID", `${classId}`)),
    options
  )

export const useQueryGetJobsDetails = ({
  classId,
  jobId,
  options,
}: IGetJobsDetailsArgs) =>
  useQuery<AxiosResponse<IGetResponseJobsDetails>, AxiosError>(
    ["jobsDetails", classId, jobId],
    async () =>
      await axiosInstance(
        `${Endpoint.CLASSES_JOBS.replace("ID", `${classId}`)}/${jobId}`
      ),
    options
  )

export const useQueryGetHrJobs = ({ options, classId }: IGetJobsArgs) =>
  useQuery<AxiosResponse<IGetJobsResponse[]>, AxiosError>(
    ["jobsHr", classId],
    async () =>
      await axiosInstance(
        Endpoint.CLASSES_ID_HELPER_HR_JOBS.replace("ID", `${classId}`)
      ),
    options
  )

export const useQueryGetHrJobsDetails = ({
  classId,
  jobId,
  options,
}: IGetJobsDetailsArgs) =>
  useQuery<AxiosResponse<IGetResponseJobsDetails>, AxiosError>(
    ["jobsDetailsHr", classId, jobId],
    async () =>
      await axiosInstance(
        `${Endpoint.CLASSES_ID_HELPER_HR_JOBS.replace(
          "ID",
          `${classId}`
        )}/${jobId}`
      ),
    options
  )
