export const initialState = {
  name: "",
  description: "",
  grade: "",
  icon: null,
  paycheck_automatic: false,
  paycheck_interval: "",
  expense_automatic: false,
  expense_interval: "",
  savings_account_option: false,
  interest_option: false,
  saving_interest_rate: "",
  round_interest_to_whole_number: false,
  interest_granting_interval: "",
}
