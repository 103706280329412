import { Row, Cell, HeadText } from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"

const TableHead = () => {
  const { t } = useTranslation()

  return (
    <Row>
      <Cell minWidth="80px" justifyContent="center">
        <HeadText>{t("dashboard.no")}.</HeadText>
      </Cell>
      <Cell minWidth="120px" tableCellProps={{ width: "100%" }}>
        <HeadText>{t("dashboard.student")}</HeadText>
      </Cell>
      <Cell minWidth="80px" justifyContent="center">
        <HeadText>{t("dashboard.value")}</HeadText>
      </Cell>
    </Row>
  )
}

export default TableHead
