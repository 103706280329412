import React, { FC, useEffect, useState } from "react"

import { useMutationPasswordRecovery } from "api/reactQuery/mutations/password"
import { useCustomSnackbar } from "hooks/snackbar"
import { getErrorMessage } from "utils/api"
import {
  clearAuthDataLocalStorage,
  getAuthDataLocalStorage,
} from "utils/authData"

import Layout from "../layout"
import First from "./pages/first"
import Second from "./pages/second"

const ResetPassword: FC = () => {
  const [email, setEmail] = useState("")
  const [page, setPage] = useState(0)
  const [error, setError] = useState(false)
  const { showSnackbar } = useCustomSnackbar()

  const { mutate: recoverPassword, isLoading: isLoadingRecoverPassword } =
    useMutationPasswordRecovery({
      options: {
        onSuccess: () => {
          if (getAuthDataLocalStorage()) {
            clearAuthDataLocalStorage()
          }
          setPage(1)
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessage(error),
            variant: "error",
          })
        },
      },
    })

  const handleClick = () => {
    if (email.length === 0) {
      setError(true)
      return
    }

    recoverPassword({
      email: email,
    })
  }

  useEffect(() => {
    if (email.length > 0) {
      setError(false)
    }
  }, [email])

  return (
    <Layout>
      {page === 0 && (
        <First
          error={error}
          email={email}
          handleClick={handleClick}
          isLoadingRecoverPassword={isLoadingRecoverPassword}
          setEmail={setEmail}
        />
      )}
      {page === 1 && <Second />}
    </Layout>
  )
}
export default ResetPassword
