interface PendingTransactions<T> {
  data: T[]
  isNoMoreData: boolean
  refetch: () => void
}

export const emptyPendingTransactions = <T>({
  data,
  isNoMoreData,
  refetch,
}: PendingTransactions<T>) => {
  if (!data.length && !isNoMoreData) {
    setTimeout(() => {
      refetch()
    }, 0)
  }
}
