import React, { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Stack, Typography, Box, Button, useTheme } from "@mui/material"
import { useMutationStudentSignUp } from "api/reactQuery/mutations/students"
import ClassCode from "components/common/classCode"
import PasswordTextField from "components/common/textField/registerInput/passwordTextField"
import TextField from "components/form/common/field/textField"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch } from "hooks/store"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { setActivePageSignUp, setAuthData } from "store/auth/auth.slice"
import { UserRole } from "ts/enums/User"
import { getErrorMessageFromTab } from "utils/api"

import { defaultValues, validateSchema } from "./SignByEmail.config"

interface IFormState {
  password: string
  login: string
  invitation_code: string
  first_name: string
  last_name: string
  no1: string
  no2: string
  no3: string
  no4: string
  no5: string
  no6: string
}

const SignByEmail = () => {
  const theme = useTheme()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [inviteCode, setInviteCode] = useState({
    no1: "",
    no2: "",
    no3: "",
    no4: "",
    no5: "",
    no6: "",
  })
  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const { mutate: signUp, isLoading } = useMutationStudentSignUp({
    options: {
      onSuccess: (data) => {
        queryClient.invalidateQueries("userMe")
        dispatch(
          setAuthData({
            token: data.data.access_token,
            finalized: true,
            role: UserRole.STUDENT,
          })
        )
        navigate(RoutePath.HOMEPAGE)
        showSnackbar({
          title: t("noAuthFlow.loginSuccessful"),
        })
      },
      onError: (error) => {
        if (error.response?.status === 404) {
          showSnackbar({
            title: t("noAuthFlow.wrongClassCode"),
            variant: "error",
          })
          return
        }
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const onSubmit = (
    data: Pick<
      IFormState,
      "password" | "login" | "first_name" | "last_name" | "invitation_code"
    >
  ) => {
    signUp({
      password: data.password,
      login: data.login,
      first_name: data.first_name,
      last_name: data.last_name,
      invitation_code: inviteCode.no1.concat(
        inviteCode.no2,
        inviteCode.no3,
        inviteCode.no4,
        inviteCode.no5,
        inviteCode.no6
      ),
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Stack textAlign="left">
          <Typography variant="body1" color="primary.main" fontWeight="600">
            {t("noAuthFlow.student")}
          </Typography>
          <Typography
            variant="h2"
            color="mockup.neutral50"
            sx={{ pt: 1, pb: 4 }}
          >
            {t("noAuthFlow.signup")}
          </Typography>
          <Typography variant="body3" color="mockup.neutral40" sx={{ pb: 1 }}>
            {t("noAuthFlow.enterYourClassCode")}
          </Typography>
          <Stack direction="row" spacing="12px" sx={{ mb: 3 }}>
            <ClassCode name="no1" setInviteCode={setInviteCode} />
            <ClassCode name="no2" setInviteCode={setInviteCode} />
            <ClassCode name="no3" setInviteCode={setInviteCode} />
            <ClassCode name="no4" setInviteCode={setInviteCode} />
            <ClassCode name="no5" setInviteCode={setInviteCode} />
            <ClassCode name="no6" setInviteCode={setInviteCode} />
          </Stack>
          <Stack>
            <Stack direction="row" spacing="12px" sx={{ mb: 3 }}>
              <TextField name="first_name" label={t("noAuthFlow.firstName")} />
              <TextField name="last_name" label={t("noAuthFlow.lastName")} />
            </Stack>
            <TextField
              name="login"
              label={t("noAuthFlow.userName")}
              sx={{ mb: 4 }}
            />
            <PasswordTextField />
            <Box>
              <Typography
                fontWeight="600"
                fontSize="12px"
                color="mockup.neutral50"
                mt={3}
              >
                {t("noAuthFlow.passwordMustBeAtLeast6Characters")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 5 }}>
              <Button
                sx={{ mr: "10px", maxWidth: "100px" }}
                variant="outlined"
                fullWidth
                onClick={() => dispatch(setActivePageSignUp(1))}
              >
                {t("noAuthFlow.back")}
              </Button>
              <Button type="submit" fullWidth disabled={isLoading}>
                {t("noAuthFlow.signup")}
              </Button>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "500", mt: 2, textAlign: "center" }}
            >
              {t("noAuthFlow.haveAccount")}
              <Link
                to={RoutePath.LOGIN}
                style={{
                  color: theme.palette.mockup.primary20,
                  paddingLeft: "5px",
                }}
              >
                {t("noAuthFlow.signIn")}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </FormProvider>
    </form>
  )
}
export default SignByEmail
