import { useEffect, useState } from "react"

import { Box, Button, Stack, Typography } from "@mui/material"
import axiosInstance, { Endpoint } from "api"
import Dialog from "components/common/dialog/dialog"
import AllTransactionsTeacher from "components/module/allTransactionsTeacher"
import ConfirmedSchool from "components/module/confirmedSchool"
import CompareAccount from "components/module/dashboardTeacher/compareAccount"
import DowngradeTeacher from "components/module/dashboardTeacher/downgradeTeacher"
import PaymentManage from "components/module/dashboardTeacher/paymentManage"
import StudentProfile from "components/module/studentProfile/StudentProfile"
import { useDialog } from "hooks/dialog"
import { useAppSelector } from "hooks/store"
import BaseLayout from "layout/baseLayout"
import TeacherSetupLayout from "layout/teacherSetupLayout"
import TeacherStoreLayout from "layout/teacherStoreLayout"
import TeacherStudentsLayout from "layout/teacherStudentsLayout"
import { DashboardTeacherPage } from "pages"
import { ClassSetupPage } from "pages/class/[classId]/setup"
import { ClassSetupBonusesPage } from "pages/class/[classId]/setup/bonuses"
import { ClassSetupFinesPage } from "pages/class/[classId]/setup/fines"
import { ClassStoreTeacherPage } from "pages/class/[classId]/store"
import { ClassStoreAllItemsTeacherPage } from "pages/class/[classId]/store/allItems"
import Coteachers from "pages/coteachers"
import Jobs from "pages/jobs"
import { Page404 } from "pages/page404"
import ResetPassword from "pages/password/resetPassword"
import Payment from "pages/payment"
import { get } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import {
  Navigate,
  Route,
  Routes as Switch,
  useNavigate,
} from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { checkSubscription } from "utils/api"

import StudentHelpers from "../../components/module/studentHelpers"
import ProtectedRoute from "../protected"

interface IDataState {
  task_id: string
  classname: string
  filename: string
}

interface IResultState extends IDataState {
  progress: null
  result: string
  status: string
}

const TeacherRoutes = () => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)
  const navigate = useNavigate()
  const [data, setData] = useState<IDataState[]>([])
  const [result, setResult] = useState<IResultState[]>([])

  useEffect(() => {
    if (!checkSubscription(userInfo) && userInfo !== null) {
      navigate(RoutePath.HOMEPAGE, { replace: true })
    }
  }, [userInfo])

  useEffect(() => {
    const result = localStorage.getItem("tasks")
    if (result) {
      const arr = JSON.parse(result)
      setData(arr)
    }
  }, [])

  const { isOpen, handleOpen, handleClose } = useDialog()

  const fetchData = async (id: string) => {
    return await axiosInstance(Endpoint.ASYNCTASKS_ID_STATE.replace("ID", id))
  }

  const fetchDataForIds = async (ids: IDataState[]) => {
    const promises = ids.map(({ task_id }) => fetchData(task_id))
    return await Promise.all(promises)
  }

  useQuery("data", () => fetchDataForIds(data), {
    enabled: !!data.length,
    onSuccess: (fetchData) => {
      const res = fetchData
        .map((element) => element.data)
        .map((resObj) => {
          const match = data.find(
            (dataObj) => dataObj.task_id === resObj.task_id
          )
          if (match) {
            return {
              ...resObj,
              classname: match.classname,
              filename: match.filename,
            }
          }
          return resObj
        })
      setResult(res)
      handleOpen()
      localStorage.removeItem("tasks")
    },
  })

  return (
    <>
      <Switch>
        <Route
          path="/"
          element={
            <>
              <BaseLayout />
              <PaymentManage />
              <DowngradeTeacher />
              <CompareAccount />
              <ConfirmedSchool />
            </>
          }
        >
          <Route element={<ProtectedRoute />}>
            <Route path={RoutePath.HOMEPAGE} element={<DashboardTeacherPage />}>
              <Route
                path={RoutePath.PAYMENT_SUCCESSFUL}
                element={<Payment />}
              />
              <Route path={RoutePath.SETTINGS} element={null} />
            </Route>
            <Route
              path={RoutePath.ALL_TRANSACTIONS}
              element={<AllTransactionsTeacher />}
            />
            <Route path={RoutePath.CLASS} element={<TeacherStudentsLayout />} />
            <Route path={RoutePath.CLASS_JOBS} element={<Jobs />} />
            <Route
              path={RoutePath.CLASS_SETUP}
              element={<TeacherSetupLayout />}
            >
              <Route
                path={RoutePath.CLASS_SETUP}
                element={<ClassSetupBonusesPage />}
              />
              <Route
                path={RoutePath.CLASS_SETUP_FINES}
                element={<ClassSetupFinesPage />}
              />
              <Route
                path={RoutePath.CLASS_SETUP_BILLS}
                element={<ClassSetupPage />}
              />
            </Route>
            <Route
              path={RoutePath.CLASS_STUDENT_HELPERS}
              element={<StudentHelpers />}
            />
            <Route
              path={RoutePath.CLASS_STORE}
              element={<TeacherStoreLayout />}
            >
              <Route
                path={RoutePath.CLASS_STORE}
                element={<ClassStoreTeacherPage />}
              />
              <Route
                path={RoutePath.CLASS_STORE_ALL_ITEMS}
                element={<ClassStoreAllItemsTeacherPage />}
              />
            </Route>
            <Route path={RoutePath.CLASS_COTEACHERS} element={<Coteachers />} />
          </Route>
          <Route path={RoutePath.STUDENT} element={<StudentProfile />} />
          <Route
            path={`${RoutePath.HOMEPAGE}/login`}
            element={<Navigate replace to="/" />}
          />
          <Route
            path={`${RoutePath.HOMEPAGE}/sign-up`}
            element={<Navigate replace to="/" />}
          />
        </Route>
        <Route>
          <Route path={RoutePath.PAGE404} element={<Page404 />} />
          <Route path={RoutePath.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={RoutePath.PAGE404_NOT_FOUND} element={<Page404 />} />
        </Route>
      </Switch>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        titleText={t("students.importUpdates")}
        customActionButtonsSlot={
          <Box mb={4} textAlign="center">
            <Button onClick={handleClose}>Confirm</Button>
          </Box>
        }
      >
        {result.map((data) => (
          <Stack key={data.task_id}>
            <Typography variant="subtitle1" color="mockup.neutral10">
              classname - {data.classname}
            </Typography>
            <Typography variant="body2" color="mockup.neutral10">
              filename - {data.filename}
            </Typography>
            <Typography
              variant="body2"
              color={
                data.status === "SUCCESS"
                  ? "mockup.success50"
                  : data.status === "FAILURE"
                  ? "mockup.error50"
                  : "mockup.neutral10"
              }
            >
              {data.status}
            </Typography>
            <Typography variant="body2" color="mockup.error50">
              {get(data, "result.details", null)}
            </Typography>
          </Stack>
        ))}
      </Dialog>
    </>
  )
}

export default TeacherRoutes
