import { ThemeOptions } from "@mui/material"
import { getColorApp, getMockupColor } from "utils/color"

import { colors, colorsBankee } from "./colors"

export const palette: ThemeOptions["palette"] = {
  primary: {
    dark: getColorApp(colors.primary10, colorsBankee.primary10),
    main: getColorApp(colors.primary60, colorsBankee.primary60),
    light: getColorApp(colors.primary90, colorsBankee.primary90),
  },
  secondary: {
    dark: getColorApp(colors.secondary60, colorsBankee.secondary60),
    main: getColorApp(colors.secondary80, colorsBankee.secondary80),
    light: getColorApp(colors.secondary90, colorsBankee.secondary90),
  },
  success: {
    dark: colors.success30,
    main: colors.success50,
    light: colors.success90,
  },
  error: {
    dark: colors.error30,
    main: colors.error50,
    light: colors.error90,
  },
  mockup: getMockupColor(colors, colorsBankee),
}
