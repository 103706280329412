import React, { useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutationCreateSchool } from "api/reactQuery/mutations/createSchool"
import { useMutationChooseSchoolDashboard } from "api/reactQuery/mutations/teacher"
import { useQueryGetSchools } from "api/reactQuery/queries/getSchools"
import { useQueryUserMe } from "api/reactQuery/queries/userMe"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { useTranslation, Trans } from "react-i18next"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"
import { IAutocompleteProps } from "ts/interfaces/Input"
import { IUserTeacher } from "ts/interfaces/User"
import { getErrorMessage } from "utils/api"

import AddNewSchool from "./addNewSchool"
import AutocompleteFindSchool from "./addNewSchool/autocompleteFindSchool/AutocompleteFindSchool"

const ConfirmedSchool = () => {
  const { t } = useTranslation()
  const { isOpen, handleOpen, handleClose } = useDialog()

  const {
    isOpen: isOpenCreateSchool,
    handleOpen: handleOpenCreateSchool,
    handleClose: handleCloseCreateSchool,
  } = useDialog()

  const user = useAppSelector(selectUser)
  const { showSnackbar } = useCustomSnackbar()

  const [state, setState] = useState<IAutocompleteProps | null>(null)
  const [isError, setIsError] = useState(false)
  const [userPost, setUserPost] = useState<string>("")
  const teacherUser = user?.user as IUserTeacher
  const [acceptedSchool, setAcceptedSchool] = useState(false)
  const [skip, setSkip] = useState(0)

  const { mutate } = useMutationChooseSchoolDashboard({
    options: {
      onSuccess: () => {
        setAcceptedSchool(true)
        handleClose()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessage(err),
          variant: "error",
        })
      },
    },
  })

  useQueryUserMe({
    options: {
      enabled: acceptedSchool,
    },
  })

  const { data, isLoading, refetch } = useQueryGetSchools({
    params: { filter: userPost, limit: 20, skip: skip },
    options: {
      enabled: !!userPost,
    },
  })

  const { mutate: mutateCreateSchool, isLoading: isLoadingCreateSchool } =
    useMutationCreateSchool({
      options: {
        onSuccess: (data) => {
          handleCloseCreateSchool()
          setState(data?.data)
          showSnackbar({
            title: t("dashboard.schoolHasBeenCreated"),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessage(error),
            variant: "error",
          })
        },
      },
    })

  const handleConfirmSchool = async () => {
    if (teacherUser?.school_zip_code !== null && state === null) {
      mutate({ school_id: teacherUser.school_id })
      return
    }

    if (state === null) {
      setIsError(true)
      return
    }
    mutate({ school_id: Number(state?.id) })
  }

  useEffect(() => {
    if (
      user &&
      user.user.school_plan === UserSchoolPlan.FREE &&
      !teacherUser?.school_confirmed
    ) {
      handleOpen()
    }
  }, [user])

  useEffect(() => {
    if (state !== null) {
      setIsError(false)
    }
  }, [state])

  return (
    <Dialog
      desktopMaxWidth="867px"
      tabletMaxWidth="1040px"
      sx={{
        ".MuiDialog-paper": {
          verticalAlign: "top",
        },
      }}
      disableEscapeKeyDown
      open={isOpen}
      onIconClose={false}
      titleText={t("layout.hiUsername", {
        firstname: teacherUser?.first_name,
        lastname: teacherUser?.last_name,
      })}
      customActionButtonsSlot={
        <Stack
          px="32px"
          py="16px"
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.mockup.primary95}`,
          })}
          gap="20px"
          justifyContent="space-between"
        >
          <AutocompleteFindSchool
            {...{
              data: data?.data,
              state,
              setState,
              isError,
              userPost,
              handleOpenCreateSchool,
              setUserPost,
              isLoading,
              skip,
              setSkip,
            }}
            onClickNextSkip={() => {
              setSkip((prev) => prev + 20)
              setTimeout(() => {
                refetch()
              }, 0)
            }}
            onClickPrevSkip={() => {
              setSkip((prev) => prev - 20)
              setTimeout(() => {
                refetch()
              }, 0)
            }}
          />
          <Tooltip
            title={
              teacherUser?.school_zip_code !== null || state !== null
                ? ""
                : "You can't confirm school without zip code"
            }
            followCursor
          >
            <span>
              <Button
                fullWidth
                disabled={
                  isLoadingCreateSchool ||
                  (teacherUser?.school_zip_code === null && state === null)
                }
                onClick={handleConfirmSchool}
              >
                {isLoadingCreateSchool && (
                  <span style={{ paddingRight: "10px" }}>
                    <CircularProgress color="inherit" size="16px" />
                  </span>
                )}
                {t("layout.confirm")}
              </Button>
            </span>
          </Tooltip>
          <Dialog
            onClose={handleCloseCreateSchool}
            actionAcceptButtonProps={{ type: "submit", form: "form" }}
            open={isOpenCreateSchool}
            actionAcceptText={t("dashboard.addSchool")}
          >
            <AddNewSchool
              id="form"
              handleSubmit={(data) => mutateCreateSchool(data)}
            />
          </Dialog>
        </Stack>
      }
    >
      <Typography
        variant="body2"
        fontWeight="500"
        color="mockup.neutral10"
        sx={{ whiteSpace: "pre-line" }}
      >
        <Trans
          i18nKey={"layout.descriptionConfirmSchool"}
          values={{
            schoolname: teacherUser?.school_name,
            city: teacherUser?.school_city,
            zipcode:
              teacherUser?.school_zip_code === null
                ? "-"
                : teacherUser?.school_zip_code,
          }}
        />
      </Typography>
    </Dialog>
  )
}

export default ConfirmedSchool
