import { FC } from "react"

import ErrorText from "components/common/error/errorText"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"

import NoItemsYet from "./noItemsYet"

interface IProps {
  variant: TransactionTypeShort
  isLoading: boolean
  isError: boolean
  data: ITransactionItem[] | null | undefined
  sortedAndFilteredData: ITransactionItem[]
  searchFilter: string
  onAddItemButtonClick: () => void
  onAddSuggestedClick: () => void
}

const DataFetchingStatus: FC<IProps> = ({
  variant,
  isLoading,
  isError,
  data,
  sortedAndFilteredData,
  searchFilter,
  onAddItemButtonClick,
  onAddSuggestedClick,
}) => {
  return (
    <>
      {isLoading && <TableSkeleton />}
      {isError && <ErrorText />}
      {!isLoading &&
        data &&
        data.length > 0 &&
        sortedAndFilteredData.length === 0 && (
          <NoMatchesForSearch search={searchFilter} />
        )}
      {!isLoading && data?.length === 0 && (
        <NoItemsYet
          onAddSuggestedClick={onAddSuggestedClick}
          onAddClick={onAddItemButtonClick}
          variant={variant}
        />
      )}
    </>
  )
}

export default DataFetchingStatus
