import { FC, useEffect } from "react"

import {
  Box,
  Checkbox,
  CheckboxProps,
  Popover,
  Stack,
  StackProps,
  TableSortLabel,
  Typography,
} from "@mui/material"
import RadioField from "components/form/common/field/radioField"
import RadioFieldGroup from "components/form/common/field/radioFieldGroup"
import { usePopover } from "hooks/popover"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IInputOption } from "ts/interfaces/Input"

interface IFormState {
  sort: string
}

interface IProps {
  onSubmit: (data: IFormState) => void
  options: IInputOption[]
  sortingActive: boolean
  sortingDirection?: "asc" | "desc"
  sortingText: string
  checkboxProps?: CheckboxProps
  customStyle?: StackProps
  isTableCheckBox?: boolean
  defaultRender?: string
}

const ListingSortingHeader: FC<IProps> = ({
  onSubmit,
  options,
  sortingActive,
  sortingDirection,
  sortingText,
  checkboxProps,
  customStyle,
  isTableCheckBox,
  defaultRender,
}) => {
  const { t } = useTranslation()

  const { popoverAnchorEl, handleClick, handleClose } =
    usePopover<HTMLDivElement>()

  const methods = useForm<IFormState>({
    defaultValues: {
      sort: JSON.stringify({
        order: "asc",
        orderBy: defaultRender,
      }),
    },
  })

  const sortWatcher = useWatch({
    name: "sort",
    control: methods.control,
  })

  useEffect(() => {
    methods.handleSubmit(onSubmit)()
    handleClose()
  }, [sortWatcher])

  return (
    <>
      <Stack
        bgcolor={(theme) => theme.palette.mockup.primary95}
        lineHeight="16px"
        direction="row"
        alignItems="end"
        {...customStyle}
      >
        {checkboxProps && (
          <Box p="12px" pr="28px" sx={{ ml: isTableCheckBox ? "-70px" : 0 }}>
            <Checkbox {...checkboxProps} />
          </Box>
        )}
        <Stack
          p={checkboxProps ? "12px 16px 12px 0px" : "16px"}
          direction="row"
          width="100%"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            variant="body3"
            color="primary.main"
            mr="4px"
            textTransform="uppercase"
          >
            {t("sortBy")}:
          </Typography>
          <TableSortLabel
            style={{ pointerEvents: "none" }}
            active={sortingActive}
            direction={sortingDirection}
          >
            <Typography
              variant="body3"
              color="primary.main"
              textTransform="uppercase"
            >
              {sortingText}
            </Typography>
          </TableSortLabel>
        </Stack>
      </Stack>
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FormProvider {...methods}>
          <Stack p="32px" rowGap="24px" component="form">
            <Stack rowGap="16px">
              <Typography variant="subtitle2" textTransform="uppercase">
                {t("setup.sortBy")}:
              </Typography>
              <RadioFieldGroup name="sort">
                <Stack rowGap="16px">
                  {options.map(({ value, label }) => (
                    <RadioField key={value} value={value} label={t(label)} />
                  ))}
                </Stack>
              </RadioFieldGroup>
            </Stack>
          </Stack>
        </FormProvider>
      </Popover>
    </>
  )
}

export default ListingSortingHeader
