import { FC } from "react"

import { ReactComponent as IconCoinBonus } from "@brand_assets/svg/bonus-coin.svg"
import { ReactComponent as IconEmoteSad } from "@common_assets/svg/emote-sad.svg"
import { ReactComponent as IconBriefCase } from "@common_assets/svg/suitcase-outlined.svg"
import { ReactComponent as IconWallet } from "@common_assets/svg/wallet-outlined.svg"
import { useTheme } from "@mui/material"
import { TransactionType } from "ts/types/Transaction"
import { getColorApp } from "utils/color"

import IconWrapper from "../iconWrapper"

interface IProps {
  variant: TransactionType
}

const TransactionIcon: FC<IProps> = ({ variant }) => {
  const theme = useTheme()

  const getVariantTheme = () => {
    if (variant === "fine") {
      return {
        Icon: IconEmoteSad,
        background: theme.palette.mockup.tertiary90,
        color: theme.palette.mockup.tertiary20,
      }
    }
    if (variant === "salary") {
      return {
        Icon: IconBriefCase,
        background: theme.palette.mockup.primary90,
        color: theme.palette.mockup.primary60,
      }
    }
    if (variant === "expense") {
      return {
        Icon: IconWallet,
        background: theme.palette.mockup.secondary90,
        color: theme.palette.mockup.secondary60,
      }
    }
    return {
      Icon: IconCoinBonus,
      background: getColorApp(
        theme.palette.mockup.success90,
        theme.palette.mockup.primary95
      ),
      color: theme.palette.mockup.success50,
    }
  }

  const variantTheme = getVariantTheme()

  return (
    <IconWrapper
      height="44px"
      width="44px"
      p="8px"
      borderRadius="8px"
      bgcolor={variantTheme.background}
      color={variantTheme.color}
      position="relative"
    >
      <variantTheme.Icon height="28px" width="28px" />
    </IconWrapper>
  )
}

export default TransactionIcon
