import React from "react"

import { ReactComponent as IconFireworks } from "@common_assets/svg/fireworks.svg"
import { Divider, Stack, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"

const SuccessComponent = () => {
  const { t } = useTranslation()
  return (
    <Stack alignItems="center">
      <Stack>
        <IconFireworks />
      </Stack>
      <Stack textAlign="center" mt="24px" mb="16px">
        <Typography variant="h3" color="mockup.darkBlue" mb="24px">
          {t("dashboard.congratulations")}!
        </Typography>
        <Typography
          lineHeight="24px"
          variant="body1"
          fontWeight="500"
          color="mockup.neutral10"
        >
          <Trans i18nKey="dashboard" components={{ strong: <b /> }}>
            {t("dashboard.yourAccountHasBeenSuccessfully")}
          </Trans>
        </Typography>
        <Divider orientation="horizontal" sx={{ my: 2 }} />
        <Typography variant="body2">
          {t("dashboard.paymentInformation")}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default SuccessComponent
