import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  ITeacherResetPasswordArgs,
  ITeacherRecoveryPasswordArgs,
  IResponseTeacherPassword,
  IPostTeacherRecoveryPassword,
  IPostTeacherResetPassword,
} from "./password.types"

export const useMutationPasswordRecovery = (
  args?: ITeacherRecoveryPasswordArgs
) =>
  useMutation<
    AxiosResponse<IResponseTeacherPassword>,
    AxiosError,
    IPostTeacherRecoveryPassword
  >(async ({ email }) => {
    return await axiosInstance.post(
      `${Endpoint.TEACHER_PASSWORD_RECOVERY}/${email}`
    )
  }, args?.options)

export const useMutationResetPassword = (args?: ITeacherResetPasswordArgs) =>
  useMutation<
    AxiosResponse<IResponseTeacherPassword>,
    AxiosError,
    IPostTeacherResetPassword
  >(async (data) => {
    return await axiosInstance.post(Endpoint.TEACHER_PASSWORD_RESET, data)
  }, args?.options)
