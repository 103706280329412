export const dashboardStudent = {
  yourBalance: "Your balance",
  yourTotalBalance: "Your Total Balance",
  pendingTransactions: "Pending transactions",
  seeAll: "See all",
  type: "Type",
  class: "Class",
  title: "Title",
  withdraw: "Withdraw",
  deposit: "Deposit",
  youDoNotHaveAnyPendingTransactions: "You don’t have any pending transactions",
  youDoNotHaveMoreTransactions: "You don't have more transactions",
  greatWorkMakingAll:
    "Great work making all your deposits and withdraws! Check back later for new transactions.",
  yourCurrentBalance: "Your current balance",
  bonusDeposit: "Bonus deposit",
  expenseWithdraw: "Expense withdraw",
  paycheckDeposit: "Paycheck deposit",
  fineWithdraw: "Fine withdraw",
  transactionCalculatedCorrectly: "Transaction calculated correctly",
  calculateNewAccountBalance: "Calculate new account balance",
  myJobs: "My jobs",
  youDoNotHaveJobsYet: "You don’t have any jobs yet",
  goToTheJobBoard: "Go to the job board to apply for a job.",
  responsibilities: "Responsibilities",
  week: "Week",
  welcome: "Welcome, {{name}}",
  savingSnapshot: "Savings Snapshot",
  viewSavings: "View Savings",
  totalInterestEarned: "Total Interest Earned",
  projectedMonthlyInterest: "Projected Monthly Interest",
}
