import { passwordStudentSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  first_name: "",
  last_name: "",
  login: "",
  password: "",
  invitation_code: "",
  no1: "",
  no2: "",
  no3: "",
  no4: "",
  no5: "",
  no6: "",
}

export const validateSchema = yup.object({
  password: passwordStudentSchema,
  login: requiredStringSchema.min(3),
  first_name: requiredStringSchema.min(2),
  last_name: requiredStringSchema.min(2),
  no1: yup.string().required(""),
  no2: yup.string().required(""),
  no3: yup.string().required(""),
  no4: yup.string().required(""),
  no5: yup.string().required(""),
  no6: yup.string().required(""),
})
