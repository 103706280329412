import { Grid, Skeleton } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"

const TilesSkeleton = () => {
  const rows = new Array(6).fill(null).map((_, i) => i)
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Grid container rowSpacing="16px" columnSpacing="12px">
      {rows.map((row) => (
        <Grid key={row} item mobile={6} desktop={4}>
          <Skeleton width="100%" height={isTablet ? "76px" : "90px"} />
        </Grid>
      ))}
    </Grid>
  )
}

export default TilesSkeleton
