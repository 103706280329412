import { styled, Tab, Tabs, ToggleButton, Typography } from "@mui/material"

export const TabStyle = styled(Tab)({
  paddingTop: "10px",
})

export const TabsStyle = styled(Tabs)({
  "&.MuiTabs-root .Mui-disabled": {
    opacity: "0",
    "@media(max-width:600px)": {
      opacity: ".3",
    },
  },
})

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    color: theme.palette.mockup.navy,
    border: "unset",
    ":hover": {
      backgroundColor: theme.palette.mockup.lightBlue,
      borderRadius: "4px",
    },
  },
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.mockup.lightBlue,
    borderRadius: "4px",
  },
}))

export const ErrorStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mockup.error50,
  fontWeight: "500",
}))
