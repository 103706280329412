import React, { ChangeEvent, Dispatch, FC } from "react"

import { ReactComponent as Upload } from "@common_assets/svg/uploadArrow.svg"
import { Button, Typography, useMediaQuery } from "@mui/material"
import { useMutationCreateCV } from "api/reactQuery/mutations/cv"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { getErrorMessageFromTab } from "utils/api"

interface IProps {
  invalid?: boolean
  name: string
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>
  selectedFile: File | null
  setSelectedFile: Dispatch<React.SetStateAction<File | null>>
  setLink: Dispatch<React.SetStateAction<string>>
}

const UploadFile: FC<IProps> = ({
  invalid,
  selectedFile,
  setIsLoading,
  setSelectedFile,
  setLink,
  name,
}) => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const isMobile = useMediaQuery("(max-width:464px)")

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length === 0) return
    if (
      event.target.files &&
      (event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      if (event.target.files && event.target.files[0]) {
        setIsLoading && setIsLoading(true)
        mutate({ upload: event.target.files && event.target.files[0] })
        setSelectedFile(event.target.files && event.target.files[0])
      }
    } else {
      showSnackbar({
        title: t("studentJobs.acceptFormatsPDFandDOCX"),
        variant: "error",
      })
    }
  }

  const { mutate } = useMutationCreateCV({
    options: {
      onSuccess: (data) => {
        showSnackbar({
          title: t("studentJobs.uploadFileSucceed"),
        })
        setLink(data.data.url)
        setIsLoading && setIsLoading(false)
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
        setIsLoading && setIsLoading(false)
      },
    },
  })

  return (
    <>
      <input
        type="file"
        name={name}
        onChange={(e) => onFileChange(e)}
        id="button-text"
        style={{ display: "none" }}
        accept=".pdf, .docx"
      />
      <label
        htmlFor="button-text"
        style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        <Button
          variant="text"
          component="span"
          sx={{ color: invalid ? "mockup.error50" : "" }}
        >
          <Upload
            style={{
              marginRight: "12px",
              color: invalid ? "mockup.error50" : "",
            }}
          />
          {t("studentJobs.uploadResume")}
        </Button>
        <Typography
          variant="body3"
          fontWeight="500"
          color="mockup.neutral50A"
          sx={{ pl: isMobile ? 2 : 0 }}
        >
          - {t("studentJobs.acceptOnlyPdfOrDocxFiles")}
        </Typography>
      </label>
      <Typography
        pl={2}
        mt={1}
        variant="body2"
        color="mockup.neutral10"
        fontWeight="500"
      >
        {selectedFile && selectedFile.name.slice(0, 30)}
        {selectedFile && selectedFile.name.length > 30 ? "..." : ""}
      </Typography>
    </>
  )
}

export default UploadFile
