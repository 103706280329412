import { FC, ReactNode } from "react"

import { CircularProgress, Fab, Tooltip } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"

interface IProps {
  isLoading?: boolean
  tooltipText?: string
  Icon: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const FabIconButton: FC<IProps> = ({
  Icon,
  onClick,
  tooltipText,
  isLoading,
}) => {
  return (
    <Tooltip title={tooltipText || ""}>
      <Fab size="small" onClick={onClick}>
        <IconWrapper>
          {isLoading ? <CircularProgress size={24} /> : Icon}
        </IconWrapper>
      </Fab>
    </Tooltip>
  )
}

export default FabIconButton
