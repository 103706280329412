import { LinearProgress, linearProgressClasses, styled } from "@mui/material"

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, value }) => {
    const colorPrimary =
      value !== undefined
        ? value <= 15
          ? theme.palette.mockup.error90
          : value <= 40
          ? theme.palette.mockup.secondary90
          : theme.palette.mockup.success90
        : theme.palette.mockup.error90

    const barColor =
      value !== undefined
        ? value <= 15
          ? theme.palette.mockup.error50
          : value <= 40
          ? theme.palette.mockup.secondary60
          : theme.palette.mockup.success50
        : theme.palette.mockup.error50

    const progressWidth = value !== undefined ? `${value}%` : "0%"

    return {
      height: 24,
      borderRadius: 12,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colorPrimary,
        "&::after": {
          content: `'${progressWidth}'`,
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          color: theme.palette.mockup.neutral0,
        },
      },
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: barColor,
      },
    }
  }
)
