export const allTransactions = {
  searchForStudentOrTransactionTitle: "Search for {{things}}",
  type: "Type",
  classAndTeacher: "Class & Teacher",
  student: "Student",
  title: "Title",
  date: "Date",
  amount: "Amount",
  typeOfTransaction: "Type of transaction",
  dateRange: "Date range",
  dateFrom: "Date from",
  dateTo: "Date to",
  resetFilters: "Reset filters",
  apply: "Apply",
  noResults: "No results",
  class: "Class",
  areYouSureYouWantToDeleteTransaction:
    "Are you sure you want to delete transaction?",
  areYouSureYouWantToDeleteSelectedTransactions:
    "Are you sure you want to delete selected transactions?",
  transactionHasBeenDeletedSuccessful:
    "Transaction has been deleted Successful",
  removeSelected: "Remove Selected",
  options: "Options",
  itemsSelected: "item{{s}} selected:",
  export: "Export",
  dataHasBeenSuccessfullyExported: "Data has been successfully exported!",
}
