import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from "@mui/material"

const ProgressBar = (props: LinearProgressProps & { value: number }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="mockup.neutral10">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Stack>
  )
}

export default ProgressBar
