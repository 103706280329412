import { ThemeOptions } from "@mui/material"

const muiUseMediaQuery: ThemeOptions["components"] = {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
}

export default muiUseMediaQuery
