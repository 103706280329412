import React, { FC } from "react"

import { Stack } from "@mui/material"
import { useQuerySavingsCharts } from "api/reactQuery/queries/chart"
import { format, subDays } from "date-fns"
import { useAppSelector } from "hooks/store"
import { selectAccount } from "store/savings/savings.selectors"

import { TransactionContext } from "./context"

interface TransactionProps {
  children: React.ReactNode
}
interface TransactionSectionProps {
  children: React.ReactNode
}

interface TransactionType extends FC<TransactionProps> {
  TransactionRowComponent: React.FC<TransactionSectionProps>
  TransactionColumnComponent: React.FC<TransactionSectionProps>
}

const TransactionBase: FC<TransactionProps> = ({ children }) => {
  const classData = useAppSelector(selectAccount)
  const { data, isLoading } = useQuerySavingsCharts({
    savings_account_id: classData?.id as number,
    start_date: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    options: {
      enabled: !!classData,
      staleTime: 0,
    },
  })

  return (
    <TransactionContext.Provider value={{ data: data?.data, isLoading }}>
      {children}
    </TransactionContext.Provider>
  )
}

const TransactionRowComponent = ({ children }: TransactionSectionProps) => {
  return (
    <Stack flexDirection="row" width="100%" columnGap={2} mb={2}>
      {children}
    </Stack>
  )
}

const TransactionColumnComponent = ({ children }: TransactionSectionProps) => {
  return <>{children}</>
}

const Transaction = TransactionBase as TransactionType
Transaction.TransactionRowComponent = TransactionRowComponent
Transaction.TransactionColumnComponent = TransactionColumnComponent

export default Transaction
