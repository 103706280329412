import React, { FC, useEffect, useState } from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check-curved.svg"
import { ReactComponent as GoogleLogo } from "@common_assets/svg/google-color.svg"
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { GOOGLE_CLIENT_ID } from "api/api.config"
import { useMutationAssignGoogle } from "api/reactQuery/mutations/assignGoogle"
import { useMutationPatchStudentMe } from "api/reactQuery/mutations/studentMe"
import { AxiosResponse } from "axios"
import Dialog from "components/common/dialog/dialog"
import IconWrapper from "components/common/icon/iconWrapper"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { IUserMe } from "ts/interfaces/User"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"
import { size } from "utils/constants/size"
import { isLogin, isTeacher } from "utils/roleCheck"

import { userAvatars, userAvatarsBankee } from "./StudentSettingsDialog.config"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const StudentSettingsDialog: FC<IProps> = ({ isOpen, onClose }) => {
  //@ts-ignore
  const google = window.google

  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const userData = queryClient.getQueryData<AxiosResponse<IUserMe>>("userMe")
  const userAvatar = userData?.data.user.avatar_url
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar || null)
  const [stateUserAvatars] = useState(
    process.env.REACT_APP_TYPE === "classequity"
      ? userAvatars
      : userAvatarsBankee
  )
  const isTablet = useMediaQuery(size.MIN_WIDTH_410)
  const isMobile = useMediaQuery(size.MAX_WIDTH_375)

  const clientId = GOOGLE_CLIENT_ID

  const { mutate: assignGoogle } = useMutationAssignGoogle({
    option: {
      onSuccess: () => {
        queryClient.invalidateQueries("userMe")
        showSnackbar({
          title: t(
            "noAuthFlow.congratulationsYourAccountIsNowConnectedToGoogle"
          ),
        })
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    if (!userData?.data.user.email) {
      if (!google) return
      setTimeout(() => {
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleGoogleLogin,
        })

        google.accounts.id.renderButton(document.getElementById("googleBtn"), {
          width: isTablet ? "400px" : !isMobile ? "360px" : "330px",
        })
      }, 0)
    }
  }, [isTablet, isMobile])

  const handleGoogleLogin = async (res: { credential: string }) => {
    assignGoogle({ token: res.credential })
  }

  const { mutate, isLoading } = useMutationPatchStudentMe({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["userMe"])
        showSnackbar({ title: t("layout.profileUpdatedSuccessfully") })
        onClose()
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessage(error)),
          variant: "error",
        })
      },
    },
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      titleText={t("layout.profileSettings")}
      actionAcceptText={t("save")}
      desktopMaxWidth="867px"
      tabletMaxWidth="867px"
      isLoading={isLoading}
      actionAcceptButtonProps={{
        disabled: isLoading,
      }}
      onActionButtonClick={() => {
        if (!selectedAvatar) {
          showSnackbar({
            title: t("layout.chooseYourAvatar"),
            variant: "error",
          })
          return
        }
        mutate({ avatar_url: selectedAvatar })
      }}
    >
      <Stack
        py="24px"
        borderTop={(theme) => `${theme.palette.mockup.primary95} 1px solid`}
        borderBottom={(theme) => `${theme.palette.mockup.primary95} 1px solid`}
      >
        <Typography variant="subtitle1" color="mockup.neutral10" mb="24px">
          {t("layout.profilePicture")}
        </Typography>
        <Stack direction="row" columnGap="8px" rowGap="24px" flexWrap="wrap">
          {stateUserAvatars.map((avatar) => (
            <Stack
              key={avatar}
              position="relative"
              onClick={() => {
                setSelectedAvatar(avatar)
              }}
              sx={{ cursor: "pointer" }}
            >
              {selectedAvatar === avatar && (
                <IconWrapper
                  color="mockup.neutral0"
                  position="absolute"
                  top="-2px"
                  right="-2px"
                  width="16px"
                  height="16px"
                >
                  <IconCheck />
                </IconWrapper>
              )}
              <img
                src={avatar}
                alt="avatar"
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "9999px",
                  border:
                    selectedAvatar === avatar
                      ? `2px solid ${theme.palette.mockup.neutral0}`
                      : "none",
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack py="24px">
        <Typography variant="subtitle1" color="mockup.neutral10" mb="24px">
          {t("layout.accountDetails")}
        </Typography>
        {userData && isTeacher(userData.data.user) && (
          <Grid container columnSpacing="16px" rowSpacing="48px">
            <Grid item mobile={12} tablet={6}>
              <TextField
                label={t("layout.firstName")}
                value={userData.data.user.first_name}
                disabled
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                label={t("layout.lastName")}
                value={userData.data.user.last_name}
                disabled
              />
            </Grid>
            {isLogin(userData.data.user) && userData.data.user.login && (
              <Grid item mobile={12}>
                <TextField
                  label={t("layout.userName")}
                  value={
                    isLogin(userData.data.user) && userData.data.user.login
                  }
                  disabled
                />
              </Grid>
            )}
          </Grid>
        )}
        <Stack maxWidth="400px">
          <Typography mt={6} mb="40px">
            {t("noAuthFlow.google")}
          </Typography>
          {!userData?.data.user.email ? (
            <Button
              variant="outlined"
              sx={(theme) => ({
                borderRadius: "8px",
                height: "40px",
                position: "relative",
                border: `1px solid ${theme.palette.mockup.neutral90}`,
                color: theme.palette.mockup.neutral10,
              })}
            >
              <div id="googleBtn" style={{ opacity: 0.01 }}></div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ position: "absolute", pointerEvents: "none" }}
              >
                <GoogleLogo style={{ marginRight: "10px" }} />
                <Typography variant="subtitle1">
                  {t("noAuthFlow.conenctToGoogle").toUpperCase()}
                </Typography>
              </Box>
            </Button>
          ) : (
            <TextField
              label={t("noAuthFlow.email")}
              disabled
              value={userData?.data.user.email}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default StudentSettingsDialog
