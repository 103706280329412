import { FC } from "react"

import { Skeleton } from "@mui/material"
import ErrorText from "components/common/error/errorText"
import { IStudentStoreItem } from "ts/interfaces/Store"
import { IStudentBalance } from "ts/interfaces/Student"

import { Cart as TCart } from "../StoreStudent.types"
import CartDetails from "./cartDetails"
import CartForm from "./cartForm"
import CartItems from "./cartItems"
import StoreCart from "./storeCart"

interface IProps {
  studentBalanceData?: IStudentBalance
  isLoadingBalance: boolean
  isErrorBalance: boolean
  cart: TCart
  onPurchaseSuccess: () => void
  onItemDeleteClick: (item: IStudentStoreItem) => void
  isDialogView?: boolean
}

const Cart: FC<IProps> = ({
  studentBalanceData,
  isLoadingBalance,
  isErrorBalance,
  cart,
  onPurchaseSuccess,
  onItemDeleteClick,
  isDialogView,
}) => {
  return (
    <StoreCart cart={cart} isDialogView={isDialogView}>
      <CartItems cart={cart} onItemDeleteClick={onItemDeleteClick} />
      {isLoadingBalance && <Skeleton height="100px" width="100%" />}
      {isErrorBalance && <ErrorText />}
      {studentBalanceData && (
        <>
          <CartDetails
            cart={cart}
            studentBalance={studentBalanceData.balance}
          />
          <CartForm cart={cart} onPurchaseSuccess={onPurchaseSuccess} />
        </>
      )}
    </StoreCart>
  )
}

export default Cart
