import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IResponseTeacherRegistrationData } from "api/reactQuery/queries/teacherRegistrationData.types"
import { IAuthData } from "ts/interfaces/Auth"
import { IAutocompleteProps } from "ts/interfaces/Input"
import { IUserMe } from "ts/interfaces/User"
import { setAuthDataLocalStorage } from "utils/authData"

import { IAuthState, IPersonalForm, ISelectUser } from "./auth.types"
import { getAuthInitialData } from "./auth.utils"

const initialState: IAuthState = {
  data: getAuthInitialData(),
  activePageSignUp: 1,
  activePageLogin: 1,
  role: {
    teacher: false,
    student: false,
  },
  ownSchool: null,
  login: true,
  personalForm: {
    first_name: "",
    last_name: "",
    survey: "",
  },
  userInfo: null,
  teacherRegistrationData: undefined,
  googleToken: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOwnSchool: (
      state,
      { payload }: PayloadAction<IAutocompleteProps | null>
    ) => {
      state.ownSchool = payload
    },
    setAuthData: (state, { payload }: PayloadAction<IAuthData | null>) => {
      state.data = payload
      setAuthDataLocalStorage(payload)
    },
    setActivePageSignUp: (state, { payload }: PayloadAction<number>) => {
      state.activePageSignUp = payload
    },
    setActivePageLogin: (state, { payload }: PayloadAction<number>) => {
      state.activePageLogin = payload
    },
    setSelectRole: (state, { payload }: PayloadAction<ISelectUser>) => {
      state.role = payload
    },
    setLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.login = payload
    },
    setPersonalForm: (state, { payload }: PayloadAction<IPersonalForm>) => {
      state.personalForm = payload
    },
    setUser: (state, { payload }: PayloadAction<IUserMe | null>) => {
      state.userInfo = payload
    },
    setGoogleToken: (state, { payload }: PayloadAction<string | null>) => {
      state.googleToken = payload
    },

    setTeacherRegistrationData: (
      state,
      { payload }: PayloadAction<IResponseTeacherRegistrationData | undefined>
    ) => {
      state.teacherRegistrationData = payload
    },
  },
})

export const {
  setActivePageSignUp,
  setActivePageLogin,
  setSelectRole,
  setLogin,
  setPersonalForm,
  setAuthData,
  setOwnSchool,
  setUser,
  setTeacherRegistrationData,
  setGoogleToken,
} = authSlice.actions

export default authSlice.reducer
