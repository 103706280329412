import { FC } from "react"

import { ReactComponent as IconStore } from "@common_assets/svg/store.svg"
import { Box, Button, Stack } from "@mui/material"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import { useTranslation } from "react-i18next"

interface IProps {
  onAddClick: () => void
  onAddSuggestedClick: () => void
}

const NoItemsYet: FC<IProps> = ({ onAddClick, onAddSuggestedClick }) => {
  const { t } = useTranslation()

  return (
    <Box p={{ mobile: "16px", tablet: "62px" }}>
      <NoElementsMessage
        Icon={<IconStore />}
        title={t("store.youDoNotHaveAnyItemsYet")}
        description={t("store.youCanChooseFromOurSuggestedStoreItems")}
        bottomSlot={
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            gap="16px"
            mt="24px"
            mx={{ mobile: "0px", tablet: "-10px" }}
          >
            <Button onClick={onAddClick} sx={{ width: "100%" }}>
              {t("store.addItem")}
            </Button>
            <Button
              onClick={onAddSuggestedClick}
              variant="outlined"
              sx={{ minWidth: "max-content" }}
            >
              {t("store.addSuggestedItems")}
            </Button>
          </Stack>
        }
      />
    </Box>
  )
}

export default NoItemsYet
