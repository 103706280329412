import { FC } from "react"

import { ReactComponent as IconImage } from "@common_assets/svg/image-solid.svg"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { IStudentStoreItem } from "ts/interfaces/Store"

interface IProps {
  storeItems: IStudentStoreItem[]
  onItemAddClick: (item: IStudentStoreItem) => void
}

const StoreItemsGrid: FC<IProps> = ({
  storeItems,
  onItemAddClick,
  children,
}) => {
  const { t } = useTranslation()

  const displayPieces = (item: number | null | undefined) => {
    if (item == null) return
    if (item >= 1) {
      return "left!"
    }
    return "out of stock!"
  }

  return (
    <Stack
      bgcolor="mockup.primary95"
      borderRadius="8px"
      p="16px"
      sx={{ overflowY: "auto", maxHeight: "calc(100vh - 68px - 153px)" }}
      className="smooth-scrollbar"
    >
      <Grid container rowSpacing="12px" columnSpacing="16px">
        <TransitionGroup component={null}>
          {storeItems.map((item) => (
            <CSSTransition
              key={item.id}
              classNames="animation-fade"
              timeout={700}
              unmountOnExit
            >
              <Grid
                item
                sx={(theme) => ({
                  flexBasis: "100%",
                  maxWidth: "100%",
                  [theme.breakpoints.between(500, "tablet")]: {
                    flexBasis: "50%",
                    maxWidth: "50%",
                  },
                  [theme.breakpoints.up("tablet")]: {
                    flexBasis: "33.3%",
                    maxWidth: "33.3%",
                  },
                  [theme.breakpoints.up("desktop")]: {
                    flexBasis: "50%",
                    maxWidth: "50%",
                  },
                  [theme.breakpoints.up(1300)]: {
                    flexBasis: "33.3%",
                    maxWidth: "33.3%",
                  },
                })}
              >
                <Stack
                  alignItems="center"
                  p="16px"
                  pt="20px"
                  position="relative"
                  bgcolor="common.white"
                  borderRadius="16px"
                  sx={{ opacity: item.quantity_in_stock === 0 ? 0.3 : 1 }}
                >
                  <Box position="absolute" top="12px" right="10px">
                    <Coin amount={`${item.price}`} size="small" />
                  </Box>
                  <Box position="absolute" top="12px" left="10px">
                    <Typography fontWeight="500">
                      {item.quantity_in_stock === 0
                        ? ""
                        : item.quantity_in_stock}{" "}
                      {displayPieces(item.quantity_in_stock)}
                    </Typography>
                  </Box>

                  {item.icon_url ? (
                    <Box
                      component="img"
                      src={item.icon_url}
                      alt={item.name}
                      width="120px"
                      height="120px"
                      mb="20px"
                    />
                  ) : (
                    <Box
                      width="120px"
                      height="120px"
                      mb="20px"
                      bgcolor="primary.light"
                      borderRadius="16px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconWrapper height="60px" width="60px">
                        <IconImage height="60px" width="60px" />
                      </IconWrapper>
                    </Box>
                  )}
                  <Typography
                    variant="body1"
                    color="mockup.neutral10"
                    fontWeight={500}
                    mb="16px"
                    textAlign="center"
                    sx={{ overflowWrap: "anywhere" }}
                  >
                    {item.name}
                  </Typography>
                  <Button
                    disabled={item.quantity_in_stock === 0}
                    variant="outlined"
                    onClick={() => onItemAddClick(item)}
                    fullWidth
                    sx={{ paddingX: "24px" }}
                  >
                    {t("storeStudent.addToCart")}
                  </Button>
                </Stack>
              </Grid>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Grid>
      {children}
    </Stack>
  )
}

export default StoreItemsGrid
