import { styled, Typography } from "@mui/material"

export const TileTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.mockup.primary99,
  borderRadius: "50%",
  minWidth: "30px",
  height: "30px",
  fontWeight: "500",
}))
