import { forwardRef, useCallback } from "react"

import { ReactComponent as IconSuccess } from "@common_assets/svg/check-circle.svg"
import { ReactComponent as IconError } from "@common_assets/svg/cross-circle.svg"
import { ReactComponent as IconClose } from "@common_assets/svg/cross.svg"
import { ReactComponent as IconInfo } from "@common_assets/svg/info-circle.svg"
import { ReactComponent as IconCaution } from "@common_assets/svg/warning-circle.svg"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import {
  useSnackbar,
  SnackbarContent,
  SnackbarKey,
  SnackbarMessage,
} from "notistack"
import { SnackbarVariant } from "ts/types/Snackbar"

interface IProps {
  id: SnackbarKey
  message: SnackbarMessage
  description?: string
  variant?: SnackbarVariant
}

const Snackbar = forwardRef<HTMLDivElement, IProps>(
  ({ id, message, description, variant = "success" }, ref) => {
    const theme = useTheme()
    const { closeSnackbar } = useSnackbar()

    const getVariantTheme = useCallback(() => {
      if (variant === "error") {
        return {
          Icon: <IconError />,
          iconColor: theme.palette.error.main,
          background: theme.palette.error.light,
        }
      }
      if (variant === "caution") {
        return {
          Icon: <IconCaution />,
          iconColor: theme.palette.secondary.main,
          background: theme.palette.secondary.light,
        }
      }
      if (variant === "info") {
        return {
          Icon: <IconInfo />,
          iconColor: theme.palette.mockup.additional40,
          background: theme.palette.mockup.additional90,
        }
      }
      return {
        Icon: <IconSuccess />,
        iconColor: theme.palette.success.main,
        background: theme.palette.success.light,
      }
    }, [variant])

    const snackbarTheme = getVariantTheme()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent ref={ref}>
        <Stack
          direction="row"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.mockup.neutral90}`,
            background: theme.palette.common.white,
            width: "calc(100vw - 32px)",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            [theme.breakpoints.up("tablet")]: {
              width: "448px",
            },
          })}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="48px"
            p="16px"
            bgcolor={snackbarTheme.background}
          >
            <IconWrapper color={snackbarTheme.iconColor}>
              {snackbarTheme.Icon}
            </IconWrapper>
          </Box>
          <Stack p="16px" pr="64px" gap="4px" justifyContent="center">
            <Typography color="common.black" variant="body2">
              {message}
            </Typography>
            {description && (
              <Typography color="common.black" variant="body3">
                {description}
              </Typography>
            )}
          </Stack>
          <IconWrapper
            top="16px"
            right="20px"
            position="absolute"
            color="primary.main"
            sx={{ cursor: "pointer" }}
            onClick={handleDismiss}
          >
            <IconClose />
          </IconWrapper>
        </Stack>
      </SnackbarContent>
    )
  }
)

Snackbar.displayName = "CustomSnackbar"

export default Snackbar
