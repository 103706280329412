import React, { useEffect, useRef, useState } from "react"

import { ReactComponent as SvgPhoto } from "@common_assets/svg/photo.svg"
import { Box, CircularProgress, LinearProgress } from "@mui/material"
import { useQueryClassesStudents } from "api/reactQuery/queries/classesStudents"
import { useQueryExportStudentPage } from "api/reactQuery/queries/export"
import ListingDisplayModeButtons from "components/common/button/listingDisplayModeButtons"
import ErrorText from "components/common/error/errorText"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import SearchbarFormWithButtons from "components/form/searchbarFormWithButtons"
import { useBlobData } from "hooks/blobData"
import { useDialog } from "hooks/dialog"
import { useSelectedItems } from "hooks/listing"
import { useListItemActions } from "hooks/listing/listItemActions"
import { useClassId } from "hooks/navigation"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { useLocation } from "react-router"
import { selectDisplayMode } from "store/displayMode/displayMode.selectors"
import {
  selectClassList,
  selectClassname,
  selectIsNextPage,
  selectPage,
  selectStudentsNumber,
  selectTaskData,
} from "store/utility/utility.selectors"
import {
  setClassList,
  setIsNextPage,
  setPage,
  setStudentsNumber,
} from "store/utility/utility.slice"
import { PrimaryTile } from "styles/common/tile"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"
import { filteredData } from "utils/filterClassList"

import BatchActionButtons from "./batchActionButtons"
import DialogClassCode from "./dialogClassCode"
import DialogFormStudentsInfo from "./dialogFormStudentsInfo"
import DialogImport from "./dialogImport"
import ListItemActionsHandler from "./listItemActionsHandler"
import PopoverSearchbarButton from "./popoverSearchbarButton"
import ProgressBar from "./progressBar"
import { IProps, ListItemActions } from "./ViewCommonElementsContainer.types"

export type HandleFunction = {
  triggerRefetch: () => void
}

const ViewCommonElementsContainer: React.ForwardRefRenderFunction<
  HandleFunction,
  IProps
> = (
  {
    sortBy,
    setIsToggle,
    sorting,
    renderDataFromBE,
    isToggleAll,
    renderDataFromBEOutside,
    stateFile,
    setSortBy,
    isEditClass,
    savingsAccountOption,
  },
  ref
) => {
  const bottomViewRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const classname = useAppSelector(selectClassname)
  const [isFetchStudentPage, setIsFetchStudentPage] = useState(false)
  const { showSnackbar } = useCustomSnackbar()
  const dispatch = useAppDispatch()
  const classList = useAppSelector(selectClassList)
  const page = useAppSelector(selectPage)
  const isNextPage = useAppSelector(selectIsNextPage)
  const displayMode = useAppSelector(selectDisplayMode)
  const location = useLocation()
  const classId = useClassId()
  const studentsNumber = useAppSelector(selectStudentsNumber)
  const firstRender = useRef(false)

  const taskData = useAppSelector(selectTaskData)
  const { ref: inViewRef, inView } = useInView()

  const { file, setFile } = stateFile
  const [filterPage, setFilterPage] = useState(0)
  const [filterElements, setFilterElements] = useState<
    IObjectStudentDetailed[]
  >([])

  const { popoverAnchorEl, handleClose, setPopoverAnchorEl } =
    usePopover<HTMLButtonElement>()

  const {
    isOpen: isOpenInfo,
    handleOpen: handleOpenInfo,
    handleClose: handleCloseInfo,
  } = useDialog()

  const {
    isOpen: isOpenCode,
    handleOpen: handleOpenCode,
    handleClose: handleCloseCode,
  } = useDialog()

  const {
    isOpen: isOpenImport,
    handleOpen: handleOpenImport,
    handleClose: handleCloseImport,
  } = useDialog()

  const { action, resetActionState, invokeListItemAction } = useListItemActions<
    IObjectStudentDetailed,
    ListItemActions
  >()

  const { data, isLoading, isRefetching, isError, refetch } =
    useQueryClassesStudents({
      classId: Number(classId),
      page: file.length >= 2 ? filterPage : page,
      sorting: sorting,
      sort_by: sortBy,
      limit: 30,
      filter: file,
      options: {
        cacheTime: 0,
        enabled:
          file.length >= 2 || (inView && isNextPage) || firstRender.current,
        onSuccess: (data) => {
          if (isEditClass) {
            const result = data?.data.objects.filter((value) => {
              return value.is_math_enabled
            })
            setSelectedItems(result)
          }
          if (isToggleAll) {
            const filterSelected = filteredData(
              data?.data.objects,
              excludedItems
            )
            if (file.length >= 2) {
              setSelectedItems([...filterSelected])
            } else {
              setSelectedItems((prev) => [...prev, ...filterSelected])
            }
          }
          if (inView && isNextPage) {
            if (data?.data.objects.length === 30) {
              dispatch(setPage(30))
            } else if (data?.data.objects.length < 30) {
              dispatch(setPage(0))
              dispatch(setIsNextPage(false))
            }
          }

          if (!inView && page === 0) {
            if (data?.data.objects.length === 30) {
              dispatch(setPage(30))
              dispatch(setIsNextPage(true))
            } else if (data?.data.objects.length < 30) {
              dispatch(setPage(0))
              dispatch(setIsNextPage(false))
            }
          }

          if (!!file.length) {
            if (data?.data.objects.length === 30) {
              setFilterPage(30)
            } else if (data?.data.objects.length < 30) {
              setFilterPage(0)
            }
            setFilterElements(data?.data.objects)
            return
          }
          dispatch(setStudentsNumber(data?.data.all_objects_count))
          if (data?.data.objects.length !== 0) {
            dispatch(setClassList(data?.data.objects))
          }
          firstRender.current = false
        },
      },
    })

  React.useImperativeHandle(
    ref,
    () => ({
      triggerRefetch() {
        dispatch(setPage(0))
        dispatch(setClassList([]))
        setTimeout(() => {
          refetch()
        }, 0)
      },
    }),
    []
  )

  useEffect(() => {
    if (inView && isNextPage && data?.data.objects.length === 30) {
      refetch()
    }
  }, [inView])

  useEffect(() => {
    if (bottomViewRef.current !== null) {
      bottomViewRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }

    if (!classList.length) {
      firstRender.current = true
    }
  }, [classname])

  useEffect(() => {
    if (location.state !== null) {
      dispatch(setClassList([]))
      dispatch(setPage(0))
      dispatch(setIsNextPage(true))
      setSortBy && setSortBy("first_name")
      setFile("")
    }
  }, [classId])

  useEffect(() => {
    const filterSelected = filteredData(classList, excludedItems)
    if (file.length >= 2) {
      dispatch(setIsNextPage(false))
    } else if (!file.length && page !== 0) {
      setTimeout(() => {
        dispatch(setIsNextPage(true))
      }, 1000)
      setFilterElements([])
      isToggleAll && setSelectedItems([...filterSelected])
    } else {
      isToggleAll && setSelectedItems([...filterSelected])
    }
  }, [file])

  const selectingProps = useSelectedItems(
    !!file.length ? filterElements : classList
  )

  const {
    selectedItems,
    clearSelectedItems,
    setSelectedItems,
    excludedItems,
    clearExcludedItems,
    setExcludedItems,
  } = selectingProps

  useEffect(() => {
    clearSelectedItems()
    clearExcludedItems()
  }, [classId])

  useEffect(() => {
    if (isToggleAll && !excludedItems.length) {
      mixpanel.track("send_transaction", {
        event_name: "select_all",
      })
    } else if (isToggleAll && !!excludedItems.length) {
      mixpanel.track("send_transaction", {
        event_name: "select individuals",
      })
    } else if (
      selectedItems.length !== classList.length &&
      classList.length !== 0 &&
      setIsToggle
    ) {
      setIsToggle(false)
    } else if (
      selectedItems.length === classList.length &&
      classList.length !== 0 &&
      setIsToggle
    ) {
      setIsToggle(true)
    }
  }, [isToggleAll, excludedItems, selectedItems])

  const {
    refetch: refetchExportStudentPage,
    isFetching: isFetchingExportStudentPage,
  } = useQueryExportStudentPage({
    classId: Number(classId),
    options: {
      enabled: isFetchStudentPage,
      onSuccess: (response) => {
        setIsFetchStudentPage(false)
        showSnackbar({
          title: t("jobs.dataHasBeenSuccessfullyExported"),
        })
        useBlobData({
          response: response.data,
          classname,
          exportData: t("jobs.studentAssignedJobs"),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  return (
    <>
      <PrimaryTile
        sx={{
          borderRadius: "0px 8px 8px 8px",
        }}
      >
        <SearchbarFormWithButtons
          searchbarFormProps={{
            placeholder: `${t("students.searchForStudent")}...`,
            onSubmit: (data) => setFile(data.search),
          }}
          buttonTitle={t("students.addStudent")}
          onButtonClick={(e) => {
            e && setPopoverAnchorEl(e?.currentTarget)
            mixpanel.track("add_students", {
              event_name: "add_student_button",
              action: "open",
            })
          }}
          actionSlot={<ListingDisplayModeButtons />}
          isExport
          isFetching={isFetchingExportStudentPage}
          setIsFetching={setIsFetchStudentPage}
          refetch={refetchExportStudentPage}
          isAddNewClass={isEditClass}
          savingsAccountOption={savingsAccountOption}
        />
        {taskData?.status === "PENDING" && <LinearProgress color="primary" />}
        {taskData?.status === "IN_PROGRESS" && (
          <ProgressBar value={taskData.progress} />
        )}
        <BatchActionButtons
          isLoading={isLoading}
          studentsNumber={studentsNumber}
          file={file}
          stateSelectedItems={{ setSelectedItems, selectedItems }}
          stateExcludedItems={{ setExcludedItems, excludedItems }}
          stateIsToggleAll={{ setIsToggle, isToggleAll }}
          clearSelectedItems={clearSelectedItems}
          clearExcludedItems={clearExcludedItems}
        />
        {renderDataFromBE &&
          renderDataFromBE({
            apiData: file.length >= 2 ? filterElements : classList,
            selectingProps,
            invokeListItemAction,
          })}
        {isLoading && page === 0 && <TableSkeleton />}
        {isError && <ErrorText />}
        {!isLoading && !!file.length && !filterElements.length && (
          <NoMatchesForSearch search={file} />
        )}
        {!isLoading && studentsNumber === 0 && (
          <NoElementsMessage
            boxStyle={{ py: { mobile: "16px", tablet: "62px" } }}
            Icon={<SvgPhoto />}
            title={t("students.youDoNotHaveAnyStudentsYet")}
            description={t("students.addStudentsManuallyOrHaveStudents")}
          />
        )}
        <PopoverSearchbarButton
          sx={{
            "& .MuiTypography-root": {
              ":last-child": {
                opacity: taskData ? 0.3 : 1,
              },
            },
          }}
          open={!!popoverAnchorEl}
          anchorEl={popoverAnchorEl}
          onClose={handleClose}
          items={[
            {
              name: t("students.studentsInfo"),
              onClick: () => {
                handleOpenInfo()
                mixpanel.track("add_students", {
                  event_name: "student_info",
                  action: "open",
                })
              },
            },
            {
              name: t("students.classCode"),
              onClick: () => {
                handleOpenCode()
                mixpanel.track("add_students", {
                  event_name: "class_code",
                  action: "open",
                })
              },
            },
            {
              name: t("students.importStudents"),
              onClick: () => {
                if (taskData) return
                handleOpenImport()
                mixpanel.track("add_students", {
                  event_name: "import_students",
                  action: "open",
                })
              },
            },
          ]}
        />
        <DialogFormStudentsInfo
          isOpen={isOpenInfo}
          handleClose={() => {
            handleCloseInfo()
            handleClose()
          }}
          refetch={refetch}
        />
        {isOpenCode && (
          <DialogClassCode
            isOpen={isOpenCode}
            handleClose={() => {
              handleCloseCode()
              mixpanel.track("add_students", {
                event_name: "class_code",
                action: "cancel",
              })
            }}
          />
        )}
        <DialogImport
          isOpen={isOpenImport}
          onClose={() => {
            handleCloseImport()
            mixpanel.track("add_students", {
              method: "import_students",
              action: "cancel",
            })
          }}
          classId={classId}
          refetch={refetch}
          clearSelectedItems={clearSelectedItems}
          clearExcludedItems={clearExcludedItems}
          setIsToggleAll={setIsToggle}
        />

        {renderDataFromBEOutside &&
          renderDataFromBEOutside({
            apiData: file.length >= 2 ? filterElements : classList,
            selectingProps,
            invokeListItemAction,
          })}
      </PrimaryTile>
      <Box ref={inViewRef}></Box>
      <Box ref={bottomViewRef} />
      {displayMode === "grid" && isRefetching && (
        <Box
          sx={{
            paddingY: 2,
            width: "100%",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {((displayMode === "list" && isRefetching) ||
        (page !== 0 && isLoading)) && (
        <Box
          sx={{
            paddingY: 2,
            width: "100%",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ListItemActionsHandler
        action={action}
        resetActionState={resetActionState}
      />
    </>
  )
}

export default React.forwardRef(ViewCommonElementsContainer)
