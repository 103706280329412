import { ThemeOptions } from "@mui/material"

const muiPopover: ThemeOptions["components"] = {
  MuiPopover: {
    styleOverrides: {
      paper: ({ theme }) => ({
        boxShadow: "0px 4px 20px rgba(74, 95, 223, 0.1)",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.mockup.neutral90}`,
      }),
    },
  },
}

export default muiPopover
