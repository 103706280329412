import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  IFundsArgs,
  IGoalsArgs,
  IGoalsDeleteArgs,
  IGoalsUpdateArgs,
  IPostFunds,
  IPostGoals,
  IPostGoalsUpdate,
  IPostWithdraw,
  IResponseFunds,
  IResponseGoals,
  IResponseGoalsUpdate,
  IResponseWithdraw,
  IWithdrawArgs,
} from "./goals.types"

export const useMutationGoalsCreate = (args?: IGoalsArgs) =>
  useMutation<AxiosResponse<IResponseGoals>, AxiosError, IPostGoals>(
    async (data) =>
      axiosInstance.post(
        Endpoint.STUDENT_ID_GOALS.replace("ID", `${args?.savings_account_id}`),
        data
      ),
    { ...args?.options }
  )

export const useMutationGoalsUpdate = (args?: IGoalsUpdateArgs) =>
  useMutation<
    AxiosResponse<IResponseGoalsUpdate>,
    AxiosError,
    IPostGoalsUpdate
  >(
    async (data) =>
      axiosInstance.patch(
        Endpoint.STUDENT_ID_GOALS_ID.replace(
          "ACCOUNTID",
          `${args?.savings_account_id}`
        ).replace("GOALID", `${args?.goal_id}`),
        data
      ),
    { ...args?.options }
  )
export const useMutationGoalsDelete = (args?: IGoalsDeleteArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, null>(
    async () =>
      axiosInstance.delete(
        Endpoint.STUDENT_ID_GOALS_ID.replace(
          "ACCOUNTID",
          `${args?.savings_account_id}`
        ).replace("GOALID", `${args?.goal_id}`)
      ),
    { ...args?.options }
  )

export const useMutationFunds = (args?: IFundsArgs) =>
  useMutation<AxiosResponse<IResponseFunds>, AxiosError, IPostFunds>(
    async (data) =>
      axiosInstance.post(
        Endpoint.STUDENT_GOALS_ID_ADD_FUNDS.replace("ID", `${args?.goal_id}`),
        data
      ),
    { ...args?.options }
  )

export const useMutationWithdraw = (args?: IWithdrawArgs) =>
  useMutation<AxiosResponse<IResponseWithdraw>, AxiosError, IPostWithdraw>(
    async (data) =>
      axiosInstance.post(
        Endpoint.STUDENT_GOALS_ID_WITHDRAW_FUNDS.replace(
          "ID",
          `${args?.goal_id}`
        ),
        data
      ),
    { ...args?.options }
  )
