import { KeyboardEvent } from "react"

type HandleKeyDownType = (
  event: KeyboardEvent<HTMLElement>,
  callback: () => void,
  isSubmitting: boolean
) => void

export const handleKeyDown: HandleKeyDownType = (
  event,
  callback,
  isSubmitting
) => {
  if (event.key === "Enter" && !isSubmitting) {
    event.preventDefault()
    callback()
  }
}
