import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IResponseTeacherRegistrationData,
  ITeacherRegistrationDataArgs,
} from "./teacherRegistrationData.types"

export const useQueryTeacherRegistrationData = (
  args?: ITeacherRegistrationDataArgs
) =>
  useQuery<AxiosResponse<IResponseTeacherRegistrationData>, AxiosError>(
    ["teacherRegistrationData", args?.token],
    async () =>
      await axiosInstance(
        Endpoint.TEACHER_REGISTRATION_DATA_ID.replace("ID", `${args?.token}`)
      ),
    args?.options
  )
