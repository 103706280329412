import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentStoreItems } from "ts/interfaces/Store"

import { IGetStudentStoreItemsArgs } from "./studentStoreItems.types"

export const useQueryStudentStoreItems = (args: IGetStudentStoreItemsArgs) =>
  useQuery<AxiosResponse<IStudentStoreItems>, AxiosError>(
    ["studentStoreItems", args.classId],
    async () =>
      axiosInstance(
        Endpoint.STUDENT_ID_STORE_ITEMS.replace("ID", `${args.classId}`)
      ),
    {
      ...args?.options,
    }
  )
