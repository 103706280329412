import React, { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { useMutationStudentFinalize } from "api/reactQuery/mutations/students"
import { useQueryGetStudentFinalize } from "api/reactQuery/queries/students"
import ClassCode from "components/common/classCode"
import TextField from "components/form/common/field/textField"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectDataAuth } from "store/auth/auth.selectors"
import { setActivePageSignUp, setAuthData } from "store/auth/auth.slice"
import { getErrorMessageFromTab } from "utils/api"
import { clearAuthDataLocalStorage } from "utils/authData"

import { validateSchema, defaultValues } from "./Finalization.config"

interface IFormData {
  first_name: string
  last_name: string
  invitation_code: string
}

const Finalization = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const authData = useAppSelector(selectDataAuth)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar } = useCustomSnackbar()

  const [inviteCode, setInviteCode] = useState({
    no1: "",
    no2: "",
    no3: "",
    no4: "",
    no5: "",
    no6: "",
  })

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const { data } = useQueryGetStudentFinalize()

  useEffect(() => {
    if (data) {
      methods.setValue("first_name", data?.data.first_name)
      methods.setValue("last_name", data?.data.last_name)
    }
  }, [data])

  const { mutate: finalize } = useMutationStudentFinalize({
    options: {
      onSuccess: () => {
        dispatch(
          setAuthData({
            ...authData,
            finalized: true,
          })
        )
        showSnackbar({
          title: t("noAuthFlow.loginSuccessful"),
        })
        navigate(RoutePath.HOMEPAGE)
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const onSubmit = (value: IFormData) => {
    finalize({
      first_name: value.first_name,
      last_name: value.last_name,
      invitation_code: inviteCode.no1.concat(
        inviteCode.no2,
        inviteCode.no3,
        inviteCode.no4,
        inviteCode.no5,
        inviteCode.no6
      ),
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Stack textAlign="left">
          <Typography variant="body1" color="primary.main" fontWeight="600">
            {t("noAuthFlow.student")}
          </Typography>
          <Typography
            variant="h2"
            color="mockup.neutral50"
            sx={{ pt: 1, pb: 4 }}
          >
            {t("noAuthFlow.fillUpDetails")}
          </Typography>
          <Typography variant="body3" color="mockup.neutral40" sx={{ pb: 1 }}>
            {t("noAuthFlow.enterYourClassCode")}
          </Typography>
          <Stack direction="row" spacing="12px" sx={{ mb: 3 }}>
            <ClassCode name="no1" setInviteCode={setInviteCode} />
            <ClassCode name="no2" setInviteCode={setInviteCode} />
            <ClassCode name="no3" setInviteCode={setInviteCode} />
            <ClassCode name="no4" setInviteCode={setInviteCode} />
            <ClassCode name="no5" setInviteCode={setInviteCode} />
            <ClassCode name="no6" setInviteCode={setInviteCode} />
          </Stack>
          <Grid container spacing={3} mb={3}>
            <Grid item tablet={6} mobile={12}>
              <TextField name="first_name" label={t("noAuthFlow.firstName")} />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField name="last_name" label={t("noAuthFlow.lastName")} />
            </Grid>
          </Grid>
        </Stack>
        <Box display="flex" columnGap="10px" mt="10px">
          <Button
            onClick={() => {
              clearAuthDataLocalStorage()
              dispatch(setAuthData(null))

              if (location.state === "login") {
                navigate(-1)
              } else {
                dispatch(setActivePageSignUp(0))
              }
            }}
            sx={{
              width: "max-content",
            }}
            variant="outlined"
          >
            {t("noAuthFlow.back")}
          </Button>
          <Button type="submit" fullWidth>
            {t("noAuthFlow.continue")}
          </Button>
        </Box>
      </FormProvider>
    </form>
  )
}

export default Finalization
