import React, { useState } from "react"

import { Box, Stack, Tab, Tabs, Typography } from "@mui/material"
import { IResponseDashboardStoreUpdates } from "api/reactQuery/queries/dashboardTodo.types"
import { AxiosResponse } from "axios"
import ActionButtons from "components/common/button/actionButtons"
import TransactionActionButtons from "components/common/button/transactionActionButtons"
import ErrorText from "components/common/error/errorText"
import BasicItem from "components/common/listing/basicItem"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import { Trans, useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { PrimaryTile } from "styles/common/tile"
import { ITodo } from "ts/interfaces/Todo"
import { showDecimal } from "utils/decimalNumber"

import { useDialog } from "../../../../../../hooks/dialog"
import { useCustomPayment } from "../../../../../../hooks/payment"
import { userIsFreeDemoSchoolPlan } from "../../../../../../hooks/schoolPlans"
import Dialog from "../../../../../common/dialog/dialog"
import { HREFLINK } from "../../../../../common/link/hreflink"
import NoStudentUpdates from "../../noStoreUpdates"
import NoTodo from "../../noTodo"

interface IProps {
  dataStore:
    | AxiosResponse<IResponseDashboardStoreUpdates[], unknown>
    | undefined
  data: AxiosResponse<ITodo[], unknown> | undefined
  isLoading: boolean
  isLoadingDataStore: boolean
  isError: boolean
  isErrorDataStore: boolean
}
const ListingForProSchool = ({
  dataStore,
  data,
  isLoading,
  isLoadingDataStore,
  isError,
  isErrorDataStore,
}: IProps) => {
  const { t } = useTranslation()
  const isUserFree = userIsFreeDemoSchoolPlan()
  const [tabNumber, setTabNumber] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isUserFree) return
    setTabNumber(newValue)
  }

  const { payIt, isLoadingPayments } = useCustomPayment()
  const { handleOpen, isOpen, handleClose } = useDialog()

  const queryClient = useQueryClient()

  return (
    <PrimaryTile>
      <Typography variant="subtitle1" color="mockup.neutral10" p="16px">
        {t("dashboard.todo")}
      </Typography>
      <Tabs
        value={tabNumber}
        onChange={handleChange}
        onClick={() => !tabNumber && isUserFree && handleOpen()}
      >
        <Tab sx={{ ml: 2 }} label={t("dashboard.pendingTransaction")} />
        <Tab
          label={
            <Stack direction="row" alignItems="center" columnGap="5px">
              <Typography variant="body2">
                {t("dashboard.storeUpdates")}
              </Typography>
              {!!dataStore?.data.length && (
                <Box
                  sx={(theme) => ({
                    height: "8px",
                    width: "8px",
                    backgroundColor: theme.palette.mockup.primary60,
                    borderRadius: "50%",
                  })}
                />
              )}
            </Stack>
          }
        />
      </Tabs>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        titleText={t("dashboard.storeUpdates")}
        actionAcceptText={t("dashboard.upgrade")}
        onActionButtonClick={() => payIt(null)}
        isLoading={isLoadingPayments}
        actionAcceptButtonProps={{ disabled: isLoadingPayments }}
      >
        <Typography variant="body2" fontWeight="500">
          <Trans
            i18nKey={"dashboard.descriptionStoreUpdates"}
            components={{
              Link: HREFLINK,
            }}
          />
        </Typography>
      </Dialog>
      <TransitionGroup component={null}>
        {!tabNumber &&
          data &&
          !isLoading &&
          data.data.map((todo) => (
            <CSSTransition
              key={todo.id}
              classNames="animation-fade"
              timeout={700}
              unmountOnExit
            >
              <BasicItem
                topTextTitle={t("dashboard.student")}
                topText={`${todo.first_name} ${todo.last_name}`}
                bottomText={todo.title}
                bottomTextTitle={t("dashboard.title")}
                actionButtonsSlot={
                  <TransactionActionButtons
                    id={todo.id}
                    onSuccessCallback={() => {
                      queryClient.invalidateQueries("dashboardTodo")
                    }}
                  />
                }
                value={showDecimal(`${todo.value}`) as number}
              />
            </CSSTransition>
          ))}
        {!!tabNumber &&
          dataStore &&
          !isLoadingDataStore &&
          dataStore.data.map((todo) => (
            <CSSTransition
              key={todo.id}
              classNames="animation-fade"
              timeout={700}
              unmountOnExit
            >
              <BasicItem
                topTextTitle={t("dashboard.item")}
                topText={todo.name}
                bottomText={todo.quantity_in_stock}
                bottomTextTitle={t("dashboard.quantity")}
                classText={todo.class_.name}
                classTextTitle={t("dashboard.classname")}
                actionButtonsSlot={<ActionButtons item={todo} dashboardIcons />}
                value={showDecimal(`${todo.price}`) as number}
              />
            </CSSTransition>
          ))}
      </TransitionGroup>
      {!tabNumber && isLoading && <TableSkeleton />}
      {!tabNumber && isError && <ErrorText />}
      {!tabNumber && !isLoading && !data?.data.length && <NoTodo />}
      {!!tabNumber && isLoadingDataStore && <TableSkeleton />}
      {!!tabNumber && isErrorDataStore && <ErrorText />}
      {!!tabNumber && !isLoadingDataStore && !dataStore?.data.length && (
        <NoStudentUpdates />
      )}
    </PrimaryTile>
  )
}

export default ListingForProSchool
