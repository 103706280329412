import { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as Infinite } from "@common_assets/svg/infinity.svg"
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Divider,
} from "@mui/material"
import { useQueryGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import Coin from "components/common/icon/coin"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { scrollToTop } from "utils/scroll"

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    padding: "16px",
    marginBottom: "16px",
    width: "100%",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.mockup.primary95}`,
    backgroundColor: theme.palette.mockup.neutral100,
    display: "flex",
    flexDirection: "column",
  },
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.mockup.primary90,
  },
  ":hover": {
    backgroundColor: theme.palette.mockup.primary90,
  },
}))

const Job: FC<{
  jobsState: IGetStudentPossibleJobsInClass[]
  setJobId: Dispatch<React.SetStateAction<number | null>>
}> = ({ jobsState, setJobId }) => {
  const params = useParams()
  const { t } = useTranslation()
  const [state, setState] = useState<number | null>(null)

  const { refetch } = useQueryGetStudentPossibleJobsInClass({
    classId: Number(params.classId),
  })

  const handleChangeState = (
    event: React.MouseEvent<HTMLElement>,
    newState: number | null
  ) => {
    setState(newState)
    setJobId(newState)
    refetch()
    scrollToTop()
  }

  useEffect(() => {
    return () => {
      setState(null)
    }
  }, [jobsState])

  return (
    <>
      {jobsState.map((job, index) => (
        <ToggleButtonGroup
          key={index}
          sx={{ display: "flex", flexDirection: "column" }}
          value={state}
          exclusive
          onChange={handleChangeState}
        >
          <ToggleButtonStyle value={job.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              textAlign="start"
            >
              <Typography
                variant="subtitle1"
                color="mockup.neutral10"
                sx={{ wordBreak: "break-word" }}
              >
                {job.title}
              </Typography>
              <Box
                lineHeight="16px"
                bgcolor={
                  job.is_hired
                    ? "mockup.success90"
                    : job.pending_application
                    ? "mockup.secondary90"
                    : ""
                }
                color={
                  job.is_hired
                    ? "mockup.success50"
                    : job.pending_application
                    ? "mockup.secondary60"
                    : ""
                }
                p="4px 8px"
                borderRadius="6px"
                maxHeight="24px"
              >
                {job.is_hired
                  ? t("studentJobs.hired").toUpperCase()
                  : job.pending_application
                  ? t("studentJobs.pending").toUpperCase()
                  : ""}
              </Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              mt={2}
              alignItems="center"
              color="mockup.neutral10"
            >
              <Coin amount={job.salary} iconSize="17px" />
              <Divider
                orientation="vertical"
                sx={{ height: "10px", mx: "10px" }}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  paddingRight: "5px",
                }}
              >
                {job.open_for_all ? (
                  <Infinite />
                ) : (
                  Number(job.positions) - Number(job.employees_count)
                )}
              </span>
              <Typography variant="body2" fontWeight="500">
                {Number(job.positions) - Number(job.employees_count) <= 1 &&
                !job.open_for_all
                  ? t("studentJobs.openPosition")
                  : t("studentJobs.openPositions")}
              </Typography>
            </Box>
          </ToggleButtonStyle>
        </ToggleButtonGroup>
      ))}
    </>
  )
}

export default Job
