import { FC, useState } from "react"

import { Typography } from "@mui/material"
import {
  useMutationHiringStudent,
  useMutationRejectStudent,
} from "api/reactQuery/mutations/students"
import {
  IGetPendingApplicationStudents,
  IGetResponseJobsDetails,
} from "api/reactQuery/queries/jobs.types"
import Dialog from "components/common/dialog/dialog"
import StudentList from "components/common/studentList"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"

import Application from "../application"

const PendingApplications: FC<{
  jobState: IGetResponseJobsDetails
}> = ({ jobState }) => {
  const params = useParams()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [studentId, setStudentId] = useState<IGetPendingApplicationStudents[]>(
    []
  )
  const { showSnackbar } = useCustomSnackbar()

  const {
    isOpen: isDialogOpenApplication,
    handleOpen: handleOpenApplication,
    handleClose: handleCloseApplication,
  } = useDialog()
  const {
    isOpen: isDialogOpenRemovePendingApplication,
    handleOpen: handleOpenRemovePendingApplication,
    handleClose: handleCloseRemovePendingApplication,
  } = useDialog()

  const { mutate: rejectStudent } = useMutationRejectStudent({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        if (isDialogOpenRemovePendingApplication) {
          showSnackbar({
            title: t("jobs.jobApplicationRemoveSuccessfully"),
          })
          handleCloseRemovePendingApplication()
          handleCloseApplication()
          return
        }
        showSnackbar({
          title: t("jobs.rejectSuccessfully"),
        })
        handleCloseRemovePendingApplication()
        handleCloseApplication()
      },
      onError: (err) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(err)),
          variant: "error",
        })
      },
    },
  })
  const { mutate: hiringStudent } = useMutationHiringStudent({
    options: {
      onSuccess: (_, variables) => {
        if (!!window.Intercom) {
          window.Intercom("trackEvent", "hire student", {
            classId: variables.classId,
            amount: variables.data.length,
            jobTitle: jobState.title,
          })
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "hire_student",
          amount: variables.data.length,
          classId: variables.classId,
          jobTitle: jobState.title,
        })
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        showSnackbar({
          title: t("jobs.hireSuccessfully"),
        })
        handleCloseApplication()
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const rejectStudentApplication = (
    studentId: number,
    classId: string | undefined,
    jobId: number
  ) => {
    rejectStudent({
      classId: Number(classId),
      jobId: Number(jobId),
      data: [
        {
          student_id: studentId,
        },
      ],
    })
  }
  const hiringStudentApplication = (
    studentId: number,
    classId: string | undefined,
    jobId: number
  ) => {
    hiringStudent({
      classId: Number(classId),
      jobId: Number(jobId),
      data: [
        {
          student_id: studentId,
        },
      ],
    })
  }

  return (
    <>
      {jobState.pending_applications_count > 0 ? (
        <StudentList
          studentList={jobState.pending_applications_students}
          onApplication={(id) => {
            setStudentId(
              jobState.pending_applications_students.filter(
                (el) => el.id === id
              )
            )
            handleOpenApplication()
          }}
          onApply={(id) => {
            const res = jobState.pending_applications_students.filter(
              (el) => el.id === id
            )
            hiringStudentApplication(res[0].id, params.classId, jobState.id)
          }}
          onCancel={(id) => {
            const res = jobState.pending_applications_students.filter(
              (el) => el.id === id
            )
            setStudentId(res)
            rejectStudentApplication(res[0].id, params.classId, jobState.id)
          }}
        />
      ) : (
        <Typography
          variant="body2"
          fontWeight="500"
          color="mockup.neutral50A"
          textAlign="center"
          my={4}
        >
          {t("jobs.nobodyEmployed").toUpperCase()}
        </Typography>
      )}
      <Dialog
        titleText={t("jobs.jobApplication")}
        open={isDialogOpenApplication}
        onClose={handleCloseApplication}
        onDeleteClick={() => {
          handleOpenRemovePendingApplication()
        }}
        desktopMaxWidth="1040px"
        tabletMaxWidth="534px"
        actionAcceptText={t("jobs.hireStudent")}
        onActionButtonClick={() =>
          hiringStudentApplication(studentId[0].id, params.classId, jobState.id)
        }
      >
        {studentId?.map((el) => (
          <Application key={el.id} studentId={el} jobState={jobState} />
        ))}
      </Dialog>
      <Dialog
        open={isDialogOpenRemovePendingApplication}
        actionAcceptText={t("jobs.delete")}
        titleText={t("jobs.areYouSureYouWantToDeleteJobApplication")}
        onClose={handleCloseRemovePendingApplication}
        desktopMaxWidth="534px"
        tabletMaxWidth="344px"
        onActionButtonClick={() =>
          rejectStudentApplication(studentId[0].id, params.classId, jobState.id)
        }
      />
    </>
  )
}

export default PendingApplications
