import { FC, ReactNode } from "react"

import { ReactComponent as IconAnonymous } from "@common_assets/svg/anonymous.svg"
import { Box, Stack, Typography } from "@mui/material"

interface IProps {
  Icon?: ReactNode
  title?: string
  description?: string
  bottomSlot?: ReactNode
}

const NoElementMessageWithoutBtn: FC<IProps> = ({
  Icon,
  title,
  description,
  bottomSlot,
}) => {
  return (
    <Stack py="44px" width="100%" alignItems="center" rowGap="24px" px="16px">
      {Icon || <IconAnonymous />}
      <Box maxWidth="400px">
        {title && (
          <Typography
            variant="subtitle1"
            textAlign="center"
            mb="8px"
            color="mockup.darkBlue"
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body2"
            textAlign="center"
            fontWeight={500}
            color="mockup.neutral20"
          >
            {description}
          </Typography>
        )}
        {bottomSlot}
      </Box>
    </Stack>
  )
}

export default NoElementMessageWithoutBtn
