import { FC } from "react"

import {
  Checkbox,
  CheckboxProps,
  TableSortLabel,
  TableSortLabelProps,
} from "@mui/material"
import BasicTable from "components/common/table/basicTable"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { useTranslation } from "react-i18next"
import { IClassStoreItem } from "ts/interfaces/Class"

interface IProps {
  checkboxProps: CheckboxProps
  getTableSortLabelProps: (
    property: keyof IClassStoreItem
  ) => TableSortLabelProps
}

const Table: FC<IProps> = ({
  checkboxProps,
  getTableSortLabelProps,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <BasicTable
      wrapperProps={{
        borderRadius: "0px",
        sx: { border: "none" },
      }}
      head={
        <BasicTable.Row>
          <BasicTable.Cell>
            <Checkbox {...checkboxProps} />
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "100%" }}>
            <TableSortLabel {...getTableSortLabelProps("name")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.item")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          {!userIsFreeDemoSchoolPlan() && (
            <BasicTable.Cell minWidth="100px">
              <TableSortLabel {...getTableSortLabelProps("quantity_in_stock")}>
                <BasicTable.HeadText textTransform="uppercase">
                  {t("store.quantity")}
                </BasicTable.HeadText>
              </TableSortLabel>
            </BasicTable.Cell>
          )}
          <BasicTable.Cell width="80px">
            <TableSortLabel {...getTableSortLabelProps("price")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("store.value")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell width="120px" />
        </BasicTable.Row>
      }
      body={children}
    />
  )
}

export default Table
