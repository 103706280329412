import { requiredStringSchema, stringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  first_name: "",
  last_name: "",
  avatar_url: "",
  email: "",
  school_name: "",
}

export const validateSchema = yup.object({
  first_name: requiredStringSchema,
  last_name: requiredStringSchema,
  avatar_url: stringSchema.nullable(),
})
