import React, { ChangeEvent } from "react"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  SelectProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { AutoExpenseType } from "components/form/searchbarFormWithButtons/SearchbarFormWithButtons"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"
import { payCheck_interval } from "utils/paycheckInterval"

import { SelectPropsCustomize, PopRowContent } from "./SettingsContent.utility"

interface SettingsContentProps {
  autoExpense: AutoExpenseType
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeIsActive: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmitAutoexpense: () => void
  isDisabled: boolean
}

const SettingsContent = ({
  autoExpense,
  onChange,
  onChangeIsActive,
  onSubmitAutoexpense,
  isDisabled,
}: SettingsContentProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <>
      <Box pl={1} pt={1}>
        <Typography variant="body3" color="mockup.neutral50A">
          {t("setup.autoExpense")}
        </Typography>
      </Box>
      <ul style={{ width: "250px" }}>
        <PopRowContent>
          <li>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoExpense.isActive}
                  onChange={onChangeIsActive}
                />
              }
              label={
                <Typography variant="body2" fontWeight="500">
                  {t("setup.autoExpense")}
                </Typography>
              }
            />
          </li>
        </PopRowContent>
        <PopRowContent
          sx={{
            ":hover": {
              backgroundColor: theme.palette.common.white,
            },
          }}
        >
          <li>
            <TextField
              variant="standard"
              select
              onChange={onChange}
              disabled={!autoExpense.isActive}
              value={autoExpense.value}
              SelectProps={SelectPropsCustomize(isTablet) as SelectProps}
              sx={{
                "& .MuiSelect-icon": {
                  transform: "translateY(-50%) rotate(-90deg)",
                  top: "50%",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                  backgroundColor: theme.palette.common.white,
                  fontWeight: 500,
                },
              }}
            >
              {payCheck_interval.map((pay) => (
                <MenuItem
                  key={pay.id}
                  value={pay.value}
                  sx={{ fontWeight: 500 }}
                >
                  {pay.label}
                </MenuItem>
              ))}
            </TextField>
          </li>
        </PopRowContent>
        <Button
          onClick={onSubmitAutoexpense}
          disabled={isDisabled}
          variant="text"
          fullWidth
          sx={{
            backgroundColor: theme.palette.mockup.primary95,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            py: "12px",
          }}
        >
          {t("defaultTranslations.confirm")}
        </Button>
      </ul>
    </>
  )
}
export default SettingsContent
