import { RootState } from "../store.types"
import { IDisplayModeState } from "./displayMode.types"

export const selectDisplayMode = (
  state: RootState
): IDisplayModeState["displayMode"] => state.displayMode.displayMode

export const selectDisplaySavings = (
  state: RootState
): IDisplayModeState["displaySavings"] => state.displayMode.displaySavings
