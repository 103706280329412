import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import qs from "qs"
import { useQuery } from "react-query"

import {
  IExportClassJobsArgs,
  IExportClassStoreTransactionsArgs,
  IExportJobsArgs,
  IExportStudentPageArgs,
  IExportTeacherTransactionsArgs,
  IExportTransactionsArgs,
} from "./export.types"

export const useQueryExportJobs = (args: IExportJobsArgs) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportJobs"],
    async () =>
      axiosInstance(
        Endpoint.STUDENTS_ID_JOBS_EXPORT.replace("ID", `${args.studentId}`),
        {
          responseType: "blob",
        }
      ),
    args.options
  )

export const useQueryExportTransactions = (args: IExportTransactionsArgs) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportTransactions"],
    async () =>
      axiosInstance(
        Endpoint.STUDENTS_ID_TRANSACTIONS_EXPORT.replace(
          "ID",
          `${args.studentId}`
        ),
        { responseType: "blob" }
      ),
    args?.options
  )

export const useQueryExportStudentPage = (args: IExportStudentPageArgs) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportStudentPage"],
    async () =>
      await axiosInstance(
        Endpoint.CLASSES_ID_STUDENTS_EXPORT.replace("ID", `${args.classId}`),
        {
          responseType: "blob",
        }
      ),
    args?.options
  )

export const useQueryExportClassStoreTransactions = (
  args: IExportClassStoreTransactionsArgs
) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportClassStoreTransactions"],
    async () =>
      await axiosInstance(
        Endpoint.CLASSES_ID_STORE_TRANSACTIONS_EXPORT.replace(
          "ID",
          `${args.classId}`
        ),
        { responseType: "blob" }
      ),
    args?.options
  )

export const useQueryExportClassJobs = (args: IExportClassJobsArgs) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportClassJobs"],
    async () =>
      axiosInstance(
        Endpoint.CLASSES_ID_JOBS_EXPORT.replace("ID", `${args.classId}`),
        { responseType: "blob" }
      ),
    args?.options
  )

export const useQueryExportTeacherTransactions = (
  args: IExportTeacherTransactionsArgs
) =>
  useQuery<AxiosResponse<Blob>, AxiosError>(
    ["exportTeacherTransactions"],
    async () =>
      axiosInstance(Endpoint.TEACHER_TRANSACTIONS_EXPORT, {
        params: {
          classes: args.classes,
          type_in: args.type_in,
          date_from: args.date_from,
          date_to: args.date_to,
          title_filter: args.title_filter,
          student_filter: args.student_filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
        responseType: "blob",
      }),
    args?.options
  )
