import { ReactComponent as IconStore } from "@common_assets/svg/store.svg"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const NoStudentUpdates = () => {
  const { t } = useTranslation()

  return (
    <NoElementMessageWithoutBtn
      Icon={<IconStore />}
      title={t("dashboard.youDontHaveAnyStoreUpdates")}
    />
  )
}
export default NoStudentUpdates
