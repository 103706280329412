import mixpanel from "mixpanel-browser"
import theme from "themes"

export const HREFLINK = (
  <a
    onClick={() => {
      mixpanel.track("plan_upgrade", {
        action: "paid_plans_link",
      })
    }}
    style={{
      textDecorationColor: "none",
      textDecorationLine: "underline",
      color: theme.palette.primary.main,
    }}
    href="https://www.classbank.com/pricing"
    rel="noreferrer"
    target="_blank"
  ></a>
)
