import React, { useEffect, useReducer, useState } from "react"

import { ReactComponent as IconSettings } from "@common_assets/svg/settings.svg"
import {
  Box,
  Button,
  Fab,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useMutationEditClass } from "api/reactQuery/mutations/classes"
import {
  useMutationClearAllApplications,
  useMutationDeleteJobs,
  useMutationPostCreateJobs,
} from "api/reactQuery/mutations/jobs"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import { useQueryClassesStudents } from "api/reactQuery/queries/classesStudents"
import { useQueryExportClassJobs } from "api/reactQuery/queries/export"
import { useQueryGetJobs } from "api/reactQuery/queries/jobs"
import Dialog from "components/common/dialog/dialog"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import JobsSkeleton from "components/common/skeleton/jobs"
import { useBlobData } from "hooks/blobData"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { selectClassname } from "store/utility/utility.selectors"
import { PopOverStyle } from "styles/common/poprow"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"
import { schoolPlanToExportData } from "utils/export"
import { renderMultipleComponents } from "utils/jobs/jobs"

import StudentSkeleton from "../../components/common/skeleton/studentProfile"
import AddJob from "./addJob"
import NoStudent from "./class/NoStudent"
import StudentBoard from "./class/StudentBoard"
import DialogClearApplication from "./dialog"
import { KindAction, reducer } from "./Jobs.styles"
import { IAutopayProps } from "./Jobs.types"
import NoJobs from "./jobsBoard/NoJobs"
import JobsOffer from "./jobsOffer"
import { Content } from "./optionsJobPopover"

export interface IStudentsToChecked {
  id: number
  checked: boolean
}
export interface ICheckedPerson {
  id: number
  first_name: string
  last_name: string
}

const Jobs = () => {
  const params = useParams()
  const theme = useTheme()
  const { showSnackbar } = useCustomSnackbar()
  const userInfo = useAppSelector(selectUser)
  const isTablet = useMediaQuery(theme.breakpoints.down("desktop"))
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"))
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const classname = useAppSelector(selectClassname)
  const [isFetchJobs, setIsFetchJobs] = useState(false)
  const [checkedPerson, setCheckedPerson] = useState<ICheckedPerson[] | []>([])
  const [checked, setChecked] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [students, setStudents] = useState<IObjectStudentDetailed[]>([])
  const [studentsToChecked, setStudentsToChecked] = useState<
    IStudentsToChecked[] | []
  >([])
  const [richEditorResponsibilities, setRichEditorResponsibilities] =
    useState("")
  const [richEditorQualifications, setRichEditorQualifications] = useState("")
  const [inView, setInView] = useState(false)
  const [copyArray, setCopyArray] = useState<IObjectStudentDetailed[]>([])

  const [paginationState, dispatch] = useReducer(reducer, {
    page: 0,
    isNextPage: true,
  })
  const [autopay, setAutopay] = useState<IAutopayProps>({
    isActive: false,
    value: "",
  })

  const { handleClick, handleClose, popoverAnchorEl } =
    usePopover<HTMLButtonElement>()

  const { data: getJobs, isLoading: loadingGetJobs } = useQueryGetJobs({
    classId: Number(params.classId),
  })

  const handleChangeInView = (value: boolean) => {
    setInView(value)
  }

  const { data: classOptions, isLoading: isLoadingClassOptions } =
    useQueryClassesById({
      id: Number(params.classId),
    })

  const { mutate: patchOpenJob } = useMutationEditClass({
    options: {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["classes"])
        if (data?.data.is_open_for_job_applications) {
          showSnackbar({
            title: t("jobs.jobBoardOpen"),
          })
        } else {
          showSnackbar({
            title: t("jobs.jobBoardClosed"),
          })
        }
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const {
    isOpen: isDialogOpenUpgradeAccount,
    handleOpen: handleOpenDialogUpgradeAccount,
    handleClose: handleCloseDialogUpgradeAccount,
  } = useDialog()

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { refetch: refetchExportClass, isFetching: isFetchingExportClass } =
    useQueryExportClassJobs({
      classId: Number(params.classId),
      options: {
        enabled: isFetchJobs,
        onSuccess: (response) => {
          setIsFetchJobs(false)
          showSnackbar({
            title: t("jobs.dataHasBeenSuccessfullyExported"),
          })
          useBlobData({
            response: response.data,
            classname,
            exportData: t("jobs.jobDetails"),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  useEffect(() => {
    const filter = studentsToChecked.filter((el) => el.checked)
    if (checked) {
      const res = students.filter((el) => {
        return filter.some((el2) => {
          return el.id === el2.id
        })
      })
      setCheckedPerson(res)
      return
    }
    const res = copyArray.filter((el) => {
      return filter.some((el2) => {
        return el.id === el2.id
      })
    })
    setCheckedPerson(res)
  }, [studentsToChecked, students])

  const {
    data: getStudentClass,
    isLoading: isLoadingStudentClass,
    refetch,
    isRefetching,
  } = useQueryClassesStudents({
    classId: Number(params.classId),
    sorting: "asc",
    limit: 30,
    page: paginationState.page,
    sort_by: "first_name",
    options: {
      cacheTime: 0,
      enabled: paginationState.isNextPage,
      onSuccess: (data) => {
        if (isCheckAll) {
          data?.data.objects.map(({ id }) => {
            setStudentsToChecked((prev) => [...prev, { id, checked: true }])
          })
        } else {
          data?.data.objects.map(({ id }) => {
            setStudentsToChecked((prev) => [...prev, { id, checked: false }])
          })
        }
        if (paginationState.isNextPage) {
          if (!!data?.data.objects.length && data?.data.objects.length === 30) {
            dispatch({
              type: KindAction.PAGE,
              payload: 30,
            })
          } else if (
            !!data?.data.objects.length &&
            data?.data.objects.length < 30
          ) {
            dispatch({
              type: KindAction.PAGE,
              payload: 0,
            })
            dispatch({
              type: KindAction.ISNEXTPAGE,
              payload: false,
            })
          }
        }
        setCopyArray((prev) => [...prev, ...data?.data.objects])
      },
    },
  })

  useEffect(() => {
    if (
      inView &&
      paginationState.isNextPage &&
      getStudentClass?.data.objects.length === 30
    ) {
      refetch()
    }
  }, [inView])

  const { mutate: createJob, isLoading: isLoadingCreateJob } =
    useMutationPostCreateJobs({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries("jobs")
          showSnackbar({
            title: t("jobs.succeedCreateJob"),
            variant: "success",
          })
          setRichEditorResponsibilities("")
          setRichEditorQualifications("")
          handleCloseDialogJob()
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  useMutationDeleteJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("jobs")
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { mutate: clearApp } = useMutationClearAllApplications({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("jobs.allApplicationsHaveBeenRemoved"),
          variant: "success",
        })
        queryClient.invalidateQueries("jobs")
        queryClient.invalidateQueries("studentsClass")
        queryClient.invalidateQueries(["jobsDetails"])
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { mutate, isLoading } = useMutationEditClass({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "Autopay has been changed successfully",
          variant: "success",
        })
        queryClient.invalidateQueries(["classes", Number(params.classId)])
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const clearApplications = () => {
    clearApp({
      classId: Number(params.classId),
    })
    handleCloseDialogClearApplication()
  }

  useEffect(() => {
    if (getStudentClass) {
      if (checked) {
        setStudents(copyArray.filter((student) => student.jobs.length === 0))
      } else {
        setStudents(copyArray)
      }
    }
  }, [getStudentClass, checked])

  const {
    isOpen: isDialogOpenJob,
    handleOpen: handleOpenDialogJob,
    handleClose: handleCloseDialogJob,
  } = useDialog()
  const {
    isOpen: isDialogOpenClearApplications,
    handleOpen: handleOpenDialogClearApplications,
    handleClose: handleCloseDialogClearApplication,
  } = useDialog()

  const handleSendAutopay = () => {
    handleClose()
    if (
      autopay.isActive === classOptions?.data.paycheck_automatic &&
      autopay.value === classOptions?.data.paycheck_interval
    )
      return

    mutate({
      classId: Number(params.classId),
      data: {
        paycheck_automatic: autopay.isActive,
        paycheck_interval: autopay.value,
      },
    })
  }

  return (
    <Stack direction="row">
      {!isTablet && (
        <Box
          sx={{
            height: "calc(100vh - 80px - 70px)",
            overflowY: "auto",
            scrollbarGutter: "stable both-edges",
          }}
          minWidth="300px"
        >
          <Box
            bgcolor={theme.palette.mockup.neutral100}
            border={`2px solid ${theme.palette.mockup.primary95}`}
            borderRadius={1}
            pl={2}
            pr={2}
          >
            {isLoadingStudentClass ? (
              renderMultipleComponents(
                <JobsSkeleton height="50px" style={{ margin: "10px 0" }} />,
                4
              )
            ) : !!copyArray.length ? (
              <StudentBoard
                isRefetching={isRefetching}
                handleChangeInView={handleChangeInView}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                students={students}
                checked={checked}
                setChecked={setChecked}
                checkedPerson={checkedPerson}
                setCheckedPerson={setCheckedPerson}
                stateStudentsToChecked={{
                  studentsToChecked,
                  setStudentsToChecked,
                }}
              />
            ) : (
              <NoStudent />
            )}
          </Box>
        </Box>
      )}
      <Box width="100%" ml={isTablet ? 0 : 3}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          minHeight="56px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            gap={1}
            flexWrap="wrap"
            {...(isMobile && { mb: 2 })}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "700",
              }}
              color="mockup.neutral10"
            >
              {t("jobs.jobBoard")}
            </Typography>
            {isLoadingClassOptions ? (
              <StudentSkeleton height="50px" />
            ) : (
              <Stack direction="row" gap={1} flexWrap="wrap">
                <Box
                  lineHeight="16px"
                  bgcolor={
                    classOptions?.data.is_open_for_job_applications
                      ? "mockup.success90"
                      : "mockup.neutral90"
                  }
                  color={
                    classOptions?.data.is_open_for_job_applications
                      ? "mockup.success50"
                      : "mockup.neutral40"
                  }
                  p="4px 8px"
                  borderRadius="6px"
                  maxHeight="24px"
                >
                  {classOptions?.data.is_open_for_job_applications
                    ? t("jobs.open").toUpperCase()
                    : t("jobs.closed").toUpperCase()}
                </Box>
                <Box
                  display="inline"
                  lineHeight="16px"
                  bgcolor={
                    classOptions?.data.paycheck_automatic
                      ? "mockup.success90"
                      : "mockup.error90"
                  }
                  p="4px 8px"
                  borderRadius="6px"
                  maxHeight="24px"
                  color={
                    classOptions?.data.paycheck_automatic
                      ? "mockup.success50"
                      : "mockup.error50"
                  }
                >
                  {`${t("jobs.autopay").toUpperCase()}: ${
                    classOptions?.data.paycheck_automatic
                      ? t("jobs.on")
                      : t("jobs.off")
                  } `}
                </Box>
              </Stack>
            )}
          </Stack>
          <Box
            display="flex"
            justifyContent={isMobile ? "center" : "space-between"}
            alignItems="center"
          >
            <Button
              sx={{ padding: "16px 30px", whiteSpace: "nowrap" }}
              onClick={() => {
                handleOpenDialogJob()
                mixpanel.track("add_class_settings", {
                  action: "add_job_searchbar",
                })
              }}
            >
              {t("jobs.addJob")}
            </Button>
            <Dialog
              titleText={t("jobs.addJob")}
              open={isDialogOpenJob}
              isLoading={isLoadingCreateJob}
              onClose={() => {
                setRichEditorResponsibilities("")
                setRichEditorQualifications("")
                handleCloseDialogJob()
                mixpanel.track("add_class_settings", {
                  action: "cancel_add_job",
                })
              }}
              desktopMaxWidth="1040px"
              tabletMaxWidth="534px"
              actionAcceptText={t("jobs.addJob")}
              actionAcceptButtonProps={{
                type: "submit",
                form: "addNewJob",
                style: { padding: isMobile ? "16px 27px" : "" },
                disabled: isLoadingCreateJob,
              }}
            >
              <AddJob
                setRichEditorResponsibilities={setRichEditorResponsibilities}
                richEditorResponsibilities={richEditorResponsibilities}
                setRichEditorQualifications={setRichEditorQualifications}
                richEditorQualifications={richEditorQualifications}
                id="addNewJob"
                createJob={createJob}
              />
            </Dialog>
            <Fab
              disabled={isLoading}
              onClick={(e) => {
                handleClick(e)
                mixpanel.track("job_options")
              }}
              sx={{ marginLeft: "16px", minWidth: "48px" }}
            >
              <IconSettings />
            </Fab>
            <PopOverStyle
              open={!!popoverAnchorEl}
              onClose={handleSendAutopay}
              anchorEl={popoverAnchorEl}
              marginThreshold={40}
              anchorOrigin={{
                vertical: 60,
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Content
                onSubmitAutopay={handleSendAutopay}
                getAutopayValues={(isActive, value) =>
                  setAutopay({
                    isActive,
                    value,
                  })
                }
                isFetchingExportClass={isFetchingExportClass}
                onExportData={() => {
                  if (isFetchingExportClass) return
                  schoolPlanToExportData({
                    user: userInfo,
                    upgrade: handleOpenDialogUpgradeAccount,
                    exportData: refetchExportClass,
                  })
                  setIsFetchJobs(false)
                }}
                onJobsOpen={() => {
                  patchOpenJob({
                    classId: Number(params.classId),
                    data: {
                      is_open_for_job_applications:
                        !classOptions?.data.is_open_for_job_applications,
                    },
                  })
                }}
                handleOpenDialog={handleOpenDialogClearApplications}
                isOpenApplication={
                  classOptions?.data.is_open_for_job_applications
                }
                paycheck={{
                  isActive: classOptions?.data.paycheck_automatic,
                  interval: classOptions?.data.paycheck_interval,
                }}
              />
            </PopOverStyle>
            <DialogUpgradeAccount
              open={isDialogOpenUpgradeAccount}
              onClose={() => {
                handleCloseDialogUpgradeAccount()
                mixpanel.track("reports_export", {
                  event_name: "class_jobs_export_data",
                  action: "cancel",
                })
              }}
              onActionButtonClick={() => {
                payIt(null)
                mixpanel.track("reports_export", {
                  event_name: "class_jobs_export_data",
                  action: "submit",
                })
              }}
              isLoading={isLoadingPayments}
              isExport
            />
            <DialogClearApplication
              open={isDialogOpenClearApplications}
              onClose={handleCloseDialogClearApplication}
              onActionButtonClick={clearApplications}
            >
              <Typography
                variant="body1"
                fontWeight="500"
                color="mock.neutral10"
              >
                {t(
                  "jobs.thisWillClearAllHiredStudentsAndPendingApplicationsFromYourJobBoard"
                )}
              </Typography>
            </DialogClearApplication>
          </Box>
        </Stack>
        {loadingGetJobs ? (
          renderMultipleComponents(
            <JobsSkeleton
              height="200px"
              style={{
                margin: "10px 0 ",
              }}
            />,
            2
          )
        ) : (
          <Stack
            direction={getJobs && getJobs.data.length > 0 ? "row" : "column"}
            flexWrap="wrap"
            justifyContent="flex-start"
            mt={2}
            sx={{
              maxHeight: "calc(100vh - 65px - 80px - 85px)",
              overflowY: "auto",
            }}
          >
            {getJobs && getJobs.data.length > 0 ? (
              <JobsOffer
                isRefetching={isRefetching}
                handleChangeInView={handleChangeInView}
                richEditorResponsibilities={richEditorResponsibilities}
                setRichEditorResponsibilities={setRichEditorResponsibilities}
                setRichEditorQualifications={setRichEditorQualifications}
                richEditorQualifications={richEditorQualifications}
                data={getJobs.data}
                students={copyArray}
                checkedPerson={checkedPerson}
                setStudentsToChecked={setStudentsToChecked}
                setCheckedPerson={setCheckedPerson}
              />
            ) : (
              <NoJobs
                richEditorResponsibilities={richEditorResponsibilities}
                setRichEditorResponsibilities={setRichEditorResponsibilities}
                richEditorQualifications={richEditorQualifications}
                setRichEditorQualifications={setRichEditorQualifications}
              />
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default Jobs
