import React, { FC, useEffect, useState } from "react"

import { Box, Tab, Tabs, Typography } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { RoutePath } from "routes/Route.types"
import { clearAuthDataLocalStorage } from "utils/authData"

import General from "./general"
import { IFormState } from "./general/General.types"
import Password from "./password"

interface IProps {
  id?: string
  userInfo?: IFormState
  handleClose?: () => void
}

const TeacherSettings: FC<IProps> = ({ id, userInfo, handleClose }) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState("general")
  const location = useLocation()
  const handleChange = (event: React.SyntheticEvent, newVal: string) => {
    setTab(newVal)
  }

  const {
    isOpen: isOpenDialogCompareAccount,
    handleOpen: handleOpenDialogCompareAccount,
    handleClose: handleCloseDialogCompareAccount,
  } = useDialog()

  useEffect(() => {
    if (!!location.search) {
      if (location.search.slice(7) !== userInfo?.email) {
        handleOpenDialogCompareAccount()
      }
    }
  }, [])

  const logout = () => {
    clearAuthDataLocalStorage()
    handleCloseDialogCompareAccount()
    localStorage.setItem("isBadAccount", JSON.stringify(true))
    window.location.replace(RoutePath.LOGIN)
  }

  return (
    <Box>
      <Tabs
        onChange={handleChange}
        value={tab}
        variant="scrollable"
        scrollButtons={false}
      >
        <Tab value="general" label={t("teacherSettings.general")}></Tab>
        <Tab value="password" label={t("teacherSettings.password")}></Tab>
      </Tabs>
      {tab === "general" && (
        <General id={id} userInfo={userInfo} handleClose={handleClose} />
      )}
      {tab === "password" && <Password id={id} handleClose={handleClose} />}
      <Dialog
        open={isOpenDialogCompareAccount}
        onClose={handleCloseDialogCompareAccount}
        titleText={t("dashboard.invalidEmail")}
        onActionButtonClick={() => logout()}
        actionAcceptText={t("dashboard.logout")}
      >
        <Typography color="mockup.neutral10" variant="body2">
          {t("dashboard.descriptionInvalidEmail")}
        </Typography>
      </Dialog>
    </Box>
  )
}

export default TeacherSettings
