import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { ITransactionsListCreateStudent } from "ts/interfaces/Transaction"

import { IPostDashboardTransactionsArgs } from "./dashboardTransactions.types"

export const useMutationPostDashboardTransactions = (
  hookArgs?: IPostDashboardTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, ITransactionsListCreateStudent>(
    async (data) =>
      await axiosInstance.post(Endpoint.DASHBOARD_TRANSACTIONS, data),
    hookArgs?.options
  )
