import { FC } from "react"

import { Popover, PopoverProps, Stack } from "@mui/material"

import ListItem from "./listItem"

interface IProps extends PopoverProps {
  items: { name: string; onClick: () => void }[]
}

const PopoverSearchbarButton: FC<IProps> = ({
  items,
  open,
  anchorEl,
  onClose,
  ...props
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPopover-paper": {
          border: (theme) => `2px solid ${theme.palette.mockup.primary95}`,
          borderRadius: "16px",
        },
      }}
      {...props}
    >
      <Stack>
        {items.map(({ name, onClick }) => (
          <ListItem key={name} text={name} onClick={onClick} />
        ))}
      </Stack>
    </Popover>
  )
}
export default PopoverSearchbarButton
