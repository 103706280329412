import React, { useState } from "react"

import { ReactComponent as IconWand } from "@common_assets/svg/wand-outlined.svg"
import { Button, CircularProgress, useTheme } from "@mui/material"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { UserSchoolPlan } from "ts/enums/User"

interface IProps {
  school_plan: UserSchoolPlan
  isLoadingPayments: boolean
  payIt: (arg: null) => void
}

const StripeButtons = ({ school_plan, isLoadingPayments, payIt }: IProps) => {
  const [isGoingToPage, setIsGoingToPage] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <>
      {school_plan === UserSchoolPlan.PRO ? (
        <Button
          disabled={isGoingToPage}
          onClick={() => {
            window.location.href =
              "https://billing.stripe.com/p/login/fZecPKg3y6SpdYA8ww"
            setIsGoingToPage(!isGoingToPage)
          }}
        >
          <span>
            {isGoingToPage && (
              <CircularProgress
                size="16px"
                style={{
                  marginRight: "12px",
                  color: theme.palette.common.white,
                }}
              />
            )}
          </span>
          <IconWand style={{ minWidth: "24px", marginRight: "12px" }} />
          {t("teacherSettings.manageSubscription")}
        </Button>
      ) : (
        <Button
          disabled={isLoadingPayments}
          onClick={() => {
            payIt(null)
            mixpanel.track("plan_upgrade", {
              action: "upgrade_now_button",
            })
          }}
        >
          <span>
            {isLoadingPayments && (
              <CircularProgress
                size="16px"
                style={{
                  marginRight: "5px",
                  color: theme.palette.common.white,
                }}
              />
            )}
          </span>
          <IconWand style={{ marginRight: "12px" }} />
          {t("teacherSettings.upgradeNow")}
        </Button>
      )}
    </>
  )
}

export default StripeButtons
