import { useEffect, useState } from "react"

import { useMutationPayments } from "api/reactQuery/mutations/payments"
import { getErrorMessage } from "utils/api"

import { useCustomSnackbar } from "../snackbar"

export const useCustomPayment = () => {
  const [link, setLink] = useState("")
  const { showSnackbar } = useCustomSnackbar()

  const {
    mutate: payIt,
    isSuccess: isSuccessPayments,
    isLoading: isLoadingPayments,
  } = useMutationPayments({
    options: {
      onSuccess: (data) => {
        setLink(data?.data)
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessage(error),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    if (isSuccessPayments) window.location.href = link
  }, [isSuccessPayments])

  return {
    payIt,
    isLoadingPayments,
  }
}
