import { useState } from "react"

export const useListItemActions = <TData, TActions>() => {
  const [action, setAction] = useState<{
    actionName: TActions
    chosenItem: TData
  } | null>(null)

  const invokeListItemAction = (chosenItem: TData, actionName: TActions) => {
    setAction({
      actionName,
      chosenItem,
    })
  }

  const resetActionState = () => setAction(null)

  return {
    action,
    invokeListItemAction,
    resetActionState,
  }
}
