import { ReactComponent as IconBurger } from "@common_assets/svg/menu-hamburger.svg"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppDispatch } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { toggleIsMenuOpened } from "store/menu/menu.slice"
import { UserRole } from "ts/enums/User"
import { getAuthDataLocalStorage } from "utils/authData"

const Burger = () => {
  const dispatch = useAppDispatch()
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <IconWrapper
      sx={{ cursor: "pointer" }}
      mr={isTablet ? "28px" : "16px"}
      onClick={() => {
        dispatch(toggleIsMenuOpened())
        if (getAuthDataLocalStorage()?.role === UserRole.TEACHER) {
          mixpanel.track("side_bar", {
            action: "open",
          })
        }
      }}
    >
      <IconBurger />
    </IconWrapper>
  )
}

export default Burger
