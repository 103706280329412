import { Stack } from "@mui/material"
import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import { ISortingState } from "hooks/listing"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { useTranslation } from "react-i18next"
import { IClassStoreItem } from "ts/interfaces/Class"

import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import Listing from "./listing"
import { sortingOptions, sortingOptionsForFree } from "./ListingView.config"

const ListingView = () => {
  const { t } = useTranslation()

  const titleList = (sort: ISortingState<IClassStoreItem>) => {
    if (userIsFreeDemoSchoolPlan()) {
      return sort.orderBy === "price"
        ? t("store.value")
        : sort.orderBy === "name"
        ? t("setup.name")
        : t("none")
    } else {
      return sort.orderBy === "price"
        ? t("store.value")
        : sort.orderBy === "name"
        ? t("setup.name")
        : sort.orderBy === "quantity_in_stock"
        ? t("store.quantity")
        : t("none")
    }
  }

  return (
    <>
      <ViewCommonElementsContainer
        renderDataView={({
          apiData,
          sortingProps: { sortingState, setSortingState, sortedData },
          selectingProps: {
            selectedItems,
            getIsChecked,
            toggleItem,
            toggleAll,
          },
        }) => (
          <Stack>
            <ListingSortingHeader
              sortingText={titleList(sortingState)}
              sortingActive={!!sortingState.orderBy}
              sortingDirection={sortingState.order}
              onSubmit={(data) => setSortingState(JSON.parse(data.sort))}
              options={
                userIsFreeDemoSchoolPlan()
                  ? sortingOptionsForFree
                  : sortingOptions
              }
              checkboxProps={{
                checked:
                  sortedData.length === selectedItems.length &&
                  !!apiData?.data.length,
                onChange: toggleAll,
                indeterminate:
                  sortedData.length !== selectedItems.length &&
                  !!selectedItems.length,
              }}
            />
            <Listing
              items={sortedData}
              getListItemCheckboxProps={(el) => ({
                checked: getIsChecked(el),
                onChange: () => {
                  toggleItem(el)
                },
              })}
            />
          </Stack>
        )}
      />
    </>
  )
}

export default ListingView
