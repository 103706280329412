import { ReactComponent as SvgInvoice } from "@brand_assets/svg/invoice.svg"
import { Box } from "@mui/material"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const NoTransactions = () => {
  const { t } = useTranslation()

  return (
    <Box py="44px">
      <NoElementMessageWithoutBtn
        title={t("dashboardStudent.youDoNotHaveAnyPendingTransactions")}
        description={t("dashboardStudent.greatWorkMakingAll")}
        Icon={<SvgInvoice />}
      />
    </Box>
  )
}

export default NoTransactions
