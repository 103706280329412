import { FC, useMemo, ReactElement, useEffect } from "react"

import { Stack, Tooltip, Typography } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { NavLink, matchPath, useLocation } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

interface IProps {
  Icon?: ReactElement
  IconActive?: ReactElement
  to: string
  text: string
  linkActiveRoutes?: string[] // array of routes, where the NavItem should be active, when not passed -> the NavItem will be active according to "to" props, when an empty array will be passed, then link will always be not active
  linkActiveRoutesExceptions?: string[] // array of routes, where the NavItem shouldn't be active, for example we have route: items/:id in linkActivePaths, but we want to set the link as not active on route items/add, so we put here the exceptions from linkActivePaths prop
  onClick?: () => void
}

const NavItem: FC<IProps> = ({
  Icon,
  IconActive,
  to,
  text,
  linkActiveRoutes = [to],
  linkActiveRoutesExceptions = [],
  onClick,
}) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (RoutePath.HOMEPAGE === pathname) {
      localStorage.removeItem("classname")
    }
  }, [pathname])

  const matchingPathsArray = useMemo(
    () => linkActiveRoutes.map((path) => !!matchPath({ path }, pathname)),
    [pathname]
  )

  const exceptionsPathsArray = useMemo(
    () =>
      linkActiveRoutesExceptions.map((path) => !!matchPath({ path }, pathname)),
    [pathname]
  )

  const isActive = useMemo(
    () =>
      !exceptionsPathsArray.includes(true) && matchingPathsArray.includes(true),
    [matchingPathsArray, exceptionsPathsArray]
  )

  const getIcon = () => {
    return isActive && IconActive ? IconActive : Icon
  }

  return (
    <NavLink onClick={onClick} to={to} state={{ from: pathname }}>
      <Stack
        position="relative"
        p="12px"
        direction="row"
        columnGap="8px"
        alignItems="center"
        color={isActive ? "secondary.main" : "common.white"}
      >
        {Icon && <IconWrapper color="inherit">{getIcon()}</IconWrapper>}
        {text === "All transactions" ? (
          <Typography color="inherit" variant="subtitle2" fontWeight="500">
            {text.trim()}
          </Typography>
        ) : (
          <Tooltip title={text.length > 14 ? text : ""} placement="bottom">
            <Typography
              color="inherit"
              variant="subtitle2"
              sx={{
                fontWeight: "500",
                textOverflow: "ellipsis",
                width: "110px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {text.trim()}
            </Typography>
          </Tooltip>
        )}
      </Stack>
    </NavLink>
  )
}

export default NavItem
