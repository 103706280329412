import { FC } from "react"

import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import { Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { IStudentStoreItem } from "ts/interfaces/Store"

import { Cart } from "../../StoreStudent.types"

interface IProps {
  cart: Cart
  onItemDeleteClick: (item: IStudentStoreItem) => void
}

const CartItems: FC<IProps> = ({ cart, onItemDeleteClick }) => {
  return (
    <Stack gap="20px">
      {Object.values(cart).map(({ item, quantity }) => (
        <Stack
          key={item.id}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
        >
          <Typography
            variant="subtitle2"
            color="mockup.neutral10"
            fontWeight={500}
            sx={{ overflowWrap: "anywhere" }}
          >
            {quantity > 1 ? `${quantity} x ` : ""}
            {item.name}
          </Typography>
          <Stack direction="row" gap="16px">
            <Coin amount={item.price} />
            <IconWrapper
              sx={{ cursor: "pointer" }}
              color="mockup.neutral10"
              onClick={() => {
                onItemDeleteClick(item)
              }}
            >
              <IconTrash />
            </IconWrapper>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

export default CartItems
