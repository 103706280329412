import { Popover, styled, Typography } from "@mui/material"

export const PopRow = styled(Typography)(({ theme }) => ({
  listStyleType: "none",
  width: "190px",
  fontWeight: "500",
  padding: "12px 16px",
  fontSize: "14px",
  cursor: "pointer",
  color: `${theme.palette.mockup.neutral10}`,
  ":hover": {
    backgroundColor: theme.palette.mockup.primary95,
  },
}))

export const PopOverStyle = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root.MuiPopover-paper": {
    borderRadius: "16px",
    border: `2px solid ${theme.palette.mockup.primary95}`,
    boxShadow: "none",
  },
}))
