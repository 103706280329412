import { FC } from "react"

import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const AutopayBox: FC<{ classById?: boolean }> = ({ classById }) => {
  const { t } = useTranslation()

  return (
    <>
      {classById ? (
        <Box
          p="2px 8px"
          bgcolor={(theme) => theme.palette.mockup.success90}
          borderRadius="6px"
          width="max-content"
        >
          <Typography color="mockup.success60" variant="body1">
            {t("transaction.autopay").toUpperCase()}:{" "}
            {t("transaction.on").toUpperCase()}
          </Typography>
        </Box>
      ) : (
        <Box
          p="2px 8px"
          bgcolor={(theme) => theme.palette.mockup.error90}
          borderRadius="6px"
          width="max-content"
        >
          <Typography color="mockup.error50" variant="body1">
            {t("transaction.autopay").toUpperCase()}:{" "}
            {t("transaction.off").toUpperCase()}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default AutopayBox
