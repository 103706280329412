import { Dispatch, FC } from "react"

import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import { Avatar, Grid, Typography, useMediaQuery } from "@mui/material"
import { IResponseCoteachers } from "api/reactQuery/queries/coteachers.types"
import { BoxIconTrash } from "styles/common/box"

interface IProps {
  teacher: IResponseCoteachers
  setTeacherFullName: Dispatch<React.SetStateAction<IResponseCoteachers | null>>
  handleOpen: () => void
}

const GridViewCoteachers: FC<IProps> = ({
  teacher,
  setTeacherFullName,
  handleOpen,
}) => {
  const isOneGrid = useMediaQuery("(max-width:440px)")

  return (
    <Grid
      item
      textAlign="center"
      px="10px"
      bgcolor="mockup.neutral100"
      height="200px"
      minWidth="200px"
      maxWidth={isOneGrid ? "100%" : "270px"}
      flex="1 1 auto"
      flexWrap="wrap"
      borderRadius="16px"
      position="relative"
      sx={{ overflowY: "auto" }}
    >
      <BoxIconTrash>
        <IconTrash
          style={{ margin: "auto" }}
          onClick={() => {
            setTeacherFullName(teacher)
            handleOpen()
          }}
        />
      </BoxIconTrash>
      <Avatar
        alt="avatar"
        src={teacher.avatar_url ? teacher.avatar_url : ""}
        sx={{
          width: "100px",
          height: "100px",
          margin: "24px auto 16px auto",
        }}
      />
      <Typography variant="subtitle1" mb={2} sx={{ wordBreak: "break-word" }}>
        {teacher.first_name} {teacher.last_name}
      </Typography>
    </Grid>
  )
}

export default GridViewCoteachers
