import { FC } from "react"

import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  useMutationRemoveInvitations,
  useMutationTeacherManagementAccept,
  useMutationTeacherManagementReject,
} from "api/reactQuery/mutations/teacherManagement"
import { IResponseTeachersManagement } from "api/reactQuery/queries/teachersManagement.types"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { BoxIconTrash } from "styles/common/box"
import { getErrorMessageFromTab } from "utils/api"

import DialogRemoveTeacher from "../dialogRemoveTeacher"

interface IProps {
  teacher: IResponseTeachersManagement
}

const GridViewInviteCoteachers: FC<IProps> = ({ teacher }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const isOneGrid = useMediaQuery(theme.breakpoints.down("desktop"))
  const isTablet = useMediaQuery(theme.breakpoints.only("tablet"))
  const { isOpen, handleOpen, handleClose } = useDialog()
  const { showSnackbar } = useCustomSnackbar()

  const {
    mutate: teacherManagementAccept,
    isLoading: isLoadingTeacherManagementAccept,
  } = useMutationTeacherManagementAccept({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("teachersManagement")
        showSnackbar({
          title: t("coteachers.userHasBeenSuccessfullyAccepted", {
            firstname: teacher.teacher?.first_name,
            lastname: teacher.teacher?.last_name,
          }),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const {
    mutate: teacherManagementReject,
    isLoading: isLoadingTeacherManagementReject,
  } = useMutationTeacherManagementReject({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("teachersManagement")
        showSnackbar({
          title: t("coteachers.userHasBeenSuccessfullyRejected", {
            firstname: teacher.teacher?.first_name,
            lastname: teacher.teacher?.last_name,
          }),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { mutate: removeInvitations } = useMutationRemoveInvitations({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("coteachers.invitationHasBeenRemoved"),
        })
        queryClient.invalidateQueries("teachersManagement")
        handleClose()
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  return (
    <Grid
      item
      textAlign="center"
      px="10px"
      py="24px"
      bgcolor="mockup.neutral100"
      height={isTablet ? "100%" : "283px"}
      width="222px"
      maxWidth={isOneGrid ? "100%" : "257px"}
      flex="1 0 auto"
      flexWrap="wrap"
      borderRadius="16px"
      position="relative"
      sx={{
        overflowY: "auto",
        border: `2px solid ${theme.palette.mockup.primary95}`,
      }}
    >
      {teacher.invitation_id !== null && (
        <BoxIconTrash>
          <IconTrash
            style={{ margin: "auto" }}
            onClick={() => {
              handleOpen()
            }}
          />
        </BoxIconTrash>
      )}
      <DialogRemoveTeacher
        isOpen={isOpen}
        handleClose={handleClose}
        teacher={teacher}
        invitations={teacher.invitation_id}
        mutate={removeInvitations}
      />
      <Avatar
        alt="avatar"
        src={teacher.teacher ? teacher.teacher.avatar_url : ""}
        sx={{
          width: isTablet ? "48px" : "100px",
          height: isTablet ? "48px" : "100px",
          margin: "0 auto",
        }}
      />
      <Typography
        variant="subtitle1"
        mt={2}
        sx={{ wordBreak: "break-word" }}
        color="mockup.grey70"
      >
        {teacher.email}
        {teacher.teacher?.first_name} {teacher.teacher?.last_name}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ wordBreak: "break-word" }}
        color="mockup.neutral60"
        mb={2}
      >
        {teacher.teacher?.email}
      </Typography>
      {!!teacher.teacher && !teacher.teacher?.is_accepted && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection={isTablet ? "column-reverse" : "row"}
        >
          <Button
            disabled={
              isLoadingTeacherManagementAccept ||
              isLoadingTeacherManagementReject
            }
            size="small"
            variant="text"
            onClick={() => {
              teacherManagementReject({ teacherId: teacher.teacher.id })
            }}
          >
            {isLoadingTeacherManagementReject && (
              <CircularProgress size="16px" />
            )}
            {t("coteachers.reject")}
          </Button>
          <Button
            disabled={
              isLoadingTeacherManagementAccept ||
              isLoadingTeacherManagementReject
            }
            size="small"
            variant="outlined"
            onClick={() =>
              teacherManagementAccept({ teacherId: teacher.teacher.id })
            }
          >
            {isLoadingTeacherManagementAccept && (
              <CircularProgress size="16px" />
            )}
            {t("coteachers.accept")}
          </Button>
        </Box>
      )}
    </Grid>
  )
}

export default GridViewInviteCoteachers
