import React, { FC, useEffect, useState } from "react"

import { ReactComponent as UploadArrow } from "@common_assets/svg/uploadArrow.svg"
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material"
import {
  useQueryHrJobApplications,
  useQueryJobApplications,
} from "api/reactQuery/queries/jobApplications"
import { IResponseJobApplications } from "api/reactQuery/queries/jobApplications.types"
import {
  IGetPendingApplicationStudents,
  IGetResponseJobsDetails,
} from "api/reactQuery/queries/jobs.types"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { selectUser } from "store/auth/auth.selectors"

interface IProps {
  studentId?: IGetPendingApplicationStudents
  jobState?: IGetResponseJobsDetails
}
const Application: FC<IProps> = ({ studentId, jobState }) => {
  const isMobile = useMediaQuery("(max-width:500px)")
  const { t } = useTranslation()
  const [jobApplication, setJobApplication] = useState(0)
  const [state, setState] = useState<IResponseJobApplications>()
  const params = useParams()
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (jobState && studentId) {
      const applications = jobState?.employees.concat(
        jobState.pending_applications_students
      )
      const result = applications.filter((el) => el.id === studentId.id)
      setJobApplication(result[0]?.job_application_id)
    }
  }, [jobState, studentId])

  useQueryJobApplications({
    job_application_id: jobApplication,
    options: {
      enabled: !!jobApplication && !!(user && user.type === "teacher"),
      cacheTime: 0,
      onSuccess: (data) => {
        setState(data?.data)
      },
    },
  })

  useQueryHrJobApplications({
    job_application_id: jobApplication,
    class_id: Number(params.classId),
    options: {
      enabled: !!jobApplication && !!(user && user.type === "student"),
      cacheTime: 0,
      onSuccess: (data) => {
        setState(data?.data)
      },
    },
  })

  return (
    <Stack>
      <Grid
        container
        justifyContent="space-between"
        alignItems={isMobile ? "flex-start" : "center"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Grid item display="flex" alignItems="center">
          <Box sx={{ mr: 1 }}>
            <Avatar
              src={studentId && studentId.avatar_url}
              alt="avatar"
              style={{
                width: "44px",
                height: "44px",
              }}
            />
          </Box>
          <Box>
            <Typography variant="body1" color="mockup.neutral10">
              {studentId && studentId.first_name}{" "}
              {studentId && studentId.last_name}
            </Typography>
            <Typography
              variant="body3"
              sx={{ fontWeight: "500" }}
              color="mockup.neutral70"
            >
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            bgcolor="mockup.additional90"
            color="mockup.additional20"
            sx={{
              px: 1,
              py: "4px",
              borderRadius: "6px",
              mt: isMobile ? "12px" : 0,
            }}
          >
            {state?.choice.toUpperCase()}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", pt: 3 }} />
      <Grid container flexDirection="column">
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{ pt: 3, pb: 2 }}
            color="mockup.neutral70"
          >
            {t("jobs.whatExperienceOfQualifications")}
          </Typography>
          <Typography variant="body2" fontWeight="500" color="mockup.neutral10">
            {state ? state.qualifications : <StudentSkeleton height={"50px"} />}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{ pt: 3, pb: 2 }}
            color="mockup.neutral70"
          >
            {t("jobs.whyDoYouWantThisJob")}
          </Typography>
          <Typography variant="body2" fontWeight="500" color="mockup.neutral10">
            {state ? state.motivation : <StudentSkeleton height={"50px"} />}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{ pt: 3, pb: 4 }}
            variant="subtitle1"
            color="mockup.neutral70"
          >
            {t("jobs.studentResume")}
          </Typography>
          {state?.resume_file ? (
            <a href={state?.resume_file}>
              <Button variant="text">
                <UploadArrow style={{ marginRight: "5px" }} />{" "}
                {t("jobs.resumeUpload")}
              </Button>
            </a>
          ) : (
            <Typography
              variant="body1"
              fontWeight="500"
              color="mockup.neutral50A"
            >
              {t("jobs.notAttached").toUpperCase()}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Application
