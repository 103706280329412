import axios from "axios"

import { getAuthDataLocalStorage } from "../utils/authData"
import { BASE_API_URL } from "./api.config"

const defaultOptions = {
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
}

const axiosInstance = axios.create(defaultOptions)
axiosInstance.interceptors.request.use(function (config) {
  const token = getAuthDataLocalStorage()?.token
  if (config.headers && token) config.headers.Authorization = `Bearer ${token}`
  return config
})

export default axiosInstance
