import { Box, styled } from "@mui/material"

export const InviteButton = styled(Box)<{ margin: string }>(
  ({ theme, margin }) => ({
    minHeight: "56px",
    textAlign: "center",
    lineHeight: "46px",
    paddingTop: "20px",
    paddingBottom: "10px",
    width: "auto",
    margin: margin,
    color: `${theme.palette.primary.main}`,
    background: `${theme.palette.mockup.primary95}`,
    cursor: "pointer",
    boxShadow: "0px 0px 0px rgba(210, 218, 249, 0.25)",
    ":hover": {
      transition: ".2s",
      background: theme.palette.primary.light,
    },
  })
)
