import React, { useEffect, useState } from "react"

import { ReactComponent as SvgInvoice } from "@brand_assets/svg/invoice.svg"
import {
  Checkbox,
  Stack,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material"
import { useQueryClassesStoreClerkTransactions } from "api/reactQuery/queries/classesStoreTransactions"
import TransactionActionButtons from "components/common/button/transactionActionButtons"
import Coin from "components/common/icon/coin"
import { Cell, HeadText, Row } from "components/common/table/basicTable"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { format } from "date-fns"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ITransactionItemClass } from "ts/interfaces/Transaction"
import { OrderByProps } from "ts/types/TableLabelOrders"

import ErrorText from "../../common/error/errorText"
import NoElementMessageWithoutBtn from "../../common/listing/noElementMessageWithoutBtn"
import NoMatchesForSearch from "../../common/listing/noMatchesForSearch"
import StudentSkeleton from "../../common/skeleton/studentProfile"
import SearchbarForm from "../../form/searchbarForm"
import BathActionButtons from "./bathActionButtons"
import { StoreClerkSortingTypes } from "./StoreClerk.types"

const StoreClerk = () => {
  const classId = useClassId()
  const theme = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [orderBy, setOrderBy] = useState<OrderByProps>("asc")
  const [sorting, setSorting] = useState<StoreClerkSortingTypes>(null)
  const [sortedUsers, setSortedUsers] = useState<ITransactionItemClass[]>([])
  const [searchOption, setSearchOption] = useState({
    search: "",
  })
  const [markedStudentsId, setMarkedStudentsId] = useState<number[]>([])

  const { data, isLoading, isError } = useQueryClassesStoreClerkTransactions({
    classId: Number(classId),
    filter: !searchOption.search.length ? undefined : searchOption.search,
  })

  const handleSort = (name: keyof ITransactionItemClass) => {
    const isCurrentColumn = sorting === name
    const newOrderBy = isCurrentColumn
      ? orderBy === "asc"
        ? "desc"
        : "asc"
      : "asc"
    setOrderBy(newOrderBy)
    const updateSortedUser = [...(sortedUsers as ITransactionItemClass[])].sort(
      (a, b) => {
        if (name === "created_at") {
          return newOrderBy === "asc"
            ? new Date(a[name]).getTime() - new Date(b[name]).getTime()
            : new Date(b[name]).getTime() - new Date(a[name]).getTime()
        } else if (name === "value") {
          if (a !== undefined && b !== undefined) {
            return newOrderBy === "asc" ? a[name] - b[name] : b[name] - a[name]
          }
          if (a === undefined) return 1
          if (b === undefined) return -1
          return 0
        } else {
          if (
            a !== undefined &&
            b !== undefined &&
            (name === "first_name" || name === "title")
          ) {
            return newOrderBy === "asc"
              ? a[name].localeCompare(b[name])
              : b[name].localeCompare(a[name])
          }
          if (a === undefined) return 1
          if (b === undefined) return -1
          return 0
        }
      }
    )
    setSortedUsers(updateSortedUser)
  }

  useEffect(() => {
    setSortedUsers(data?.data ?? [])
  }, [data])

  const handleChecked = (id: number) => {
    if (markedStudentsId.some((markedId) => markedId === id)) {
      const excludedStudent = markedStudentsId.filter(
        (studentId) => studentId !== id
      )
      setMarkedStudentsId(excludedStudent)
      return
    }
    setMarkedStudentsId((prev) => [...prev, id])
  }

  const handleMainCheckbox = () => {
    if (markedStudentsId.length === sortedUsers?.length) {
      setMarkedStudentsId([])
    } else {
      const allStudentsId = sortedUsers?.map((el) => el.id)
      if (allStudentsId) setMarkedStudentsId(allStudentsId)
    }
  }

  return (
    <Stack>
      <Stack
        flexDirection="row"
        columnGap={1}
        sx={{ backgroundColor: theme.palette.common.white, p: 2 }}
      >
        <SearchbarForm onSubmit={(data) => setSearchOption(data)} />
      </Stack>
      <BasicTable
        topSlot={
          !!markedStudentsId.length && (
            <BathActionButtons
              selectedItems={markedStudentsId}
              sortedData={sortedUsers}
              clearSelectedItems={() => setMarkedStudentsId([])}
            />
          )
        }
        head={
          <Row>
            <Cell>
              <Checkbox
                indeterminate={
                  !!markedStudentsId.length &&
                  markedStudentsId.length !== sortedUsers?.length
                }
                checked={
                  !!markedStudentsId.length &&
                  markedStudentsId.length === sortedUsers?.length
                }
                onChange={handleMainCheckbox}
              />
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <TableSortLabel
                active={sorting === "first_name"}
                direction={orderBy}
                onClick={() => {
                  handleSort("first_name")
                  setSorting("first_name")
                }}
              >
                <HeadText>{t("store.student")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <TableSortLabel
                active={sorting === "title"}
                direction={orderBy}
                onClick={() => {
                  handleSort("title")
                  setSorting("title")
                }}
              >
                <HeadText>{t("store.item")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell width="90px">
              <TableSortLabel
                active={sorting === "created_at"}
                direction={orderBy}
                onClick={() => {
                  handleSort("created_at")
                  setSorting("created_at")
                }}
              >
                <HeadText>{t("store.date")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell width="90px">
              <TableSortLabel
                active={sorting === "value"}
                direction={orderBy}
                onClick={() => {
                  handleSort("value")
                  setSorting("value")
                }}
              >
                <HeadText>{t("store.value")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell width="50px"></Cell>
          </Row>
        }
        body={
          <TransitionGroup component={null}>
            {sortedUsers?.map((user) => {
              return (
                <CSSTransition
                  key={user.id}
                  timeout={300}
                  classNames="animation-fade"
                >
                  <Row>
                    <Cell>
                      <Checkbox
                        onChange={() => handleChecked(user.id)}
                        checked={markedStudentsId.some((el) => user.id === el)}
                      />
                    </Cell>
                    <Cell>
                      {user.first_name} {user.last_name}
                    </Cell>
                    <Cell>
                      <Typography>{user.title}</Typography>
                    </Cell>
                    <Cell>
                      <Typography>
                        {format(new Date(user.created_at), "MMM, dd yyyy")}
                      </Typography>
                    </Cell>
                    <Cell>
                      <Coin amount={user.value} />
                    </Cell>
                    <Cell>
                      <TransactionActionButtons
                        classId={classId}
                        id={user.id}
                        onSuccessCallback={() => {
                          queryClient.invalidateQueries([
                            "classesStoreClerkTransactions",
                            Number(classId),
                          ])
                        }}
                      />
                    </Cell>
                  </Row>
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        }
        bottomSlot={
          <>
            {isLoading && <StudentSkeleton height="300px" />}
            {isError && <ErrorText />}
            {!isLoading &&
              !!searchOption.search.length &&
              sortedUsers?.length === 0 && (
                <NoMatchesForSearch search={searchOption.search} />
              )}
            {!isLoading &&
              sortedUsers?.length === 0 &&
              !searchOption.search.length && (
                <NoElementMessageWithoutBtn
                  Icon={<SvgInvoice />}
                  title={t("store.youDoNotHaveAnyTasksToDo")}
                  description={t("store.greatWorkStayingOnTop")}
                />
              )}
          </>
        }
      />
    </Stack>
  )
}
export default StoreClerk
