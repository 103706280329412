import {
  quantityItemsSchema,
  requiredNumberSchema,
  requiredStringSchema,
} from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  imageUrl: "",
  uploadedImageUrl: "",
  title: "",
  amount: "",
  quantity: null,
}

export const validationSchema = yup.object({
  title: requiredStringSchema.max(100),
  amount: requiredNumberSchema.positive().max(500000),
  quantity: quantityItemsSchema,
})
