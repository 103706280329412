import { ReactComponent as Research } from "@common_assets/svg/research.svg"
import { Stack, Typography, Box, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next"

const NoSpecifyJob = () => {
  const isTablet = useMediaQuery("(min-width:1240px)")
  const { t } = useTranslation()
  return (
    <Stack alignItems="center" pt="100px">
      <Box textAlign="center" maxWidth="400px" width="100%">
        <Research />
        <Typography variant="subtitle1" color="mockup.darkBlue" mt={3} mb={1}>
          {t("studentJobs.pleaseChooseInterestingJobs")}
        </Typography>
        <Typography variant="body2" fontWeight="500" color="mockup.neutral20">
          {isTablet
            ? t(
                "studentJobs.onTheLeftSideYouCanClickOnPossibleJobsAndCheckDetailsOfThem"
              )
            : t(
                "studentJobs.atTheBottomYouCanClickOnPossibleJobsAndCheckDetailsOfThem"
              )}
        </Typography>
      </Box>
    </Stack>
  )
}

export default NoSpecifyJob
