import { useEffect } from "react"

import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material"
import { useQueryUserMe } from "api/reactQuery/queries/userMe"
import Dialog from "components/common/dialog/dialog"
import ErrorText from "components/common/error/errorText"
import TeacherSettings from "components/module/teacherSettings"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { usePopover } from "hooks/popover"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useUserRole } from "hooks/user"
import mixpanel from "mixpanel-browser"
import InviteCoteachers from "pages/coteachers/inviteCoteachers"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { NavLink, useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { setUser } from "store/auth/auth.slice"
import { PopRow, PopOverStyle } from "styles/common/poprow"
import { UserRole, UserSchoolPlan } from "ts/enums/User"
import { IUserTeacher } from "ts/interfaces/User"
import { clearAuthDataLocalStorage } from "utils/authData"
import { isTeacher } from "utils/roleCheck"

import StudentBalance from "./studentBalance"
import StudentSettingsDialog from "./studentSettingsDialog"
import UpgradeAccount from "./upgradeAccount"

const UserData = () => {
  const userInfo = useAppSelector(selectUser)
  const userInfoTeacher = userInfo?.user as IUserTeacher
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")
  const userRole = useUserRole()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (userInfo?.type === "teacher") {
      mixpanel.identify(`${userInfo?.user.id}`)
      mixpanel.track("log_in")
      mixpanel.people.set_once({
        $name: userInfo?.user.first_name,
        $userID: userInfo?.user.id,
      })
    }
  }, [userInfo])

  useEffect(() => {
    if (userInfo?.type === "teacher") {
      mixpanel.track("page_view", {
        url: location.pathname,
      })
    }
  }, [location, userInfo])

  useEffect(() => {
    if (location.pathname === RoutePath.SETTINGS) {
      handleOpenDialogSettings()
    }
  }, [location.pathname])

  const {
    isOpen: isOpenDialogSettings,
    handleOpen: handleOpenDialogSettings,
    handleClose: handleCloseDialogSettings,
  } = useDialog()

  const {
    isOpen: isOpenStudentSettings,
    handleOpen: handleOpenStudentSettings,
    handleClose: handleCloseStudentSettings,
  } = useDialog()

  const {
    isOpen: isOpenManageTeachers,
    handleOpen: handleOpenManageTeachers,
    handleClose: handleCloseManageTeachers,
  } = useDialog()

  const { popoverAnchorEl, handleClick, handleClose } =
    usePopover<HTMLDivElement>()

  const { data, isLoading, isError } = useQueryUserMe({
    options: {
      staleTime: 60 * 60 * 1000,
      onSuccess: (data) => {
        if (typeof data?.data !== null) {
          dispatch(setUser(data.data))
        }
      },
    },
  })

  if (
    process.env.REACT_APP_TYPE === "classequity" &&
    !!window.Intercom &&
    !!userInfo
  ) {
    window.Intercom("boot", {
      app_id: "s6s4mt3i",
      name: `${userInfoTeacher.first_name} ${userInfoTeacher.last_name}`,
      grades: JSON.stringify(userInfoTeacher.classes_grades),
      email: userInfoTeacher.email,
      user_id: `${userInfoTeacher.id}`,
      school_plan: userInfoTeacher.school_plan,
      company: {
        name: userInfoTeacher.school_name,
        plan: userInfoTeacher.school_plan,
        company_id: userInfoTeacher.school_id,
      },
    })
  }

  if (isLoading || (!data && !isError))
    return <Skeleton height="44px" width="150px" />

  if (isError) return <ErrorText />

  const handleLogout = () => {
    clearAuthDataLocalStorage()
    window.location.href = "/"
    if (!!window.Intercom) window.Intercom("shutdown")
    if (userInfo?.type === "teacher") {
      mixpanel.track("log_out")
    }
    mixpanel.reset()
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        {userRole === UserRole.STUDENT && <StudentBalance />}
        {userRole === UserRole.TEACHER && <UpgradeAccount user={userInfo} />}
        <Stack
          direction="row"
          alignItems="center"
          onClick={(e) => {
            handleClick(e)
            if (userRole === UserRole.TEACHER) {
              mixpanel.track("profile_icon")
            }
          }}
          sx={{ cursor: "pointer" }}
        >
          {data?.data.user.avatar_url ? (
            <NavLink
              onClick={(e) => e.preventDefault()}
              to={RoutePath.HOMEPAGE}
            >
              <img
                src={data?.data.user.avatar_url}
                style={{ width: "40px" }}
                alt="logo"
              />
            </NavLink>
          ) : (
            <Avatar />
          )}
          {isTablet && (
            <Box ml="8px">
              <Typography
                variant="body3"
                fontWeight="500"
                color="mockup.neutral30"
              >
                {t("hello")},
              </Typography>
              <Typography variant="subtitle2" color="mockup.primary20">
                {`${data.data.user.first_name} ${data.data.user.last_name}`}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
      <PopOverStyle
        disableScrollLock
        open={!!popoverAnchorEl}
        onClose={handleClose}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 50,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ul>
          {userRole === UserRole.TEACHER && (
            <>
              <PopRow
                onClick={() => {
                  handleOpenDialogSettings()
                  mixpanel.track("my_profile", {
                    action: "click",
                  })
                }}
              >
                <li>{t("teacherSettings.myProfile")}</li>
              </PopRow>
              {userInfo?.user.school_plan === UserSchoolPlan.SCHOOL && (
                <PopRow onClick={handleOpenManageTeachers}>
                  <li>{t("teacherSettings.teachers")}</li>
                </PopRow>
              )}
            </>
          )}
          {userRole === UserRole.STUDENT && (
            <PopRow onClick={handleOpenStudentSettings}>
              <li>{t("layout.myProfile")}</li>
            </PopRow>
          )}
          <PopRow onClick={handleLogout}>
            <li>{t("teacherSettings.logout")}</li>
          </PopRow>
        </ul>
      </PopOverStyle>
      {isTeacher(data.data.user) && (
        <Dialog
          titleText={t("teacherSettings.settings")}
          open={isOpenDialogSettings}
          onClose={() => {
            handleCloseDialogSettings()
            if (location.pathname === RoutePath.SETTINGS) {
              navigate(RoutePath.HOMEPAGE)
            }
          }}
          desktopMaxWidth={"867px"}
          tabletMaxWidth={"534px"}
          actionAcceptText={t("teacherSettings.save")}
          actionAcceptButtonProps={{ form: "form", type: "submit" }}
        >
          <TeacherSettings
            id="form"
            userInfo={data.data.user}
            handleClose={handleCloseDialogSettings}
          />
        </Dialog>
      )}
      {isOpenStudentSettings && (
        <StudentSettingsDialog
          isOpen={isOpenStudentSettings}
          onClose={handleCloseStudentSettings}
        />
      )}
      <Dialog
        open={isOpenManageTeachers}
        onClose={() => {
          handleCloseManageTeachers()
          mixpanel.track("approve_teachers", {
            action: "cancel",
          })
        }}
        titleText={t("coteachers.teachers")}
        desktopMaxWidth="867px"
        tabletMaxWidth="534px"
        customActionButtonsSlot
      >
        <InviteCoteachers />
      </Dialog>
    </>
  )
}

export default UserData
