import { RoutePath } from "../../routes/Route.types"

export const storeClerkRoutes = [
  {
    path: RoutePath.CLASS_STORECLERK,
    tabName: "storeTransactions",
    labelKey: "store.storeTransactions",
    activeOnPaths: [RoutePath.CLASS_STORECLERK],
  },
  {
    path: RoutePath.CLASS_STORECLERK_ALL_ITEMS,
    tabName: "manageItems",
    labelKey: "store.manageItems",
    activeOnPaths: [RoutePath.CLASS_STORECLERK_ALL_ITEMS],
  },
]
