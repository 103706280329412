import * as yup from "yup"

import {
  numberSchema,
  requiredHundredthNumberSchema,
  requiredStringSchema,
} from "../../../../../../../utils/yup"

export const defaultValues = {
  title: "",
  positions: "",
  salary: "",
  responsibilities: "",
  qualifications: "",
}

export interface IdefaultValues {
  title: string
  position: number | null
  salary: number
  responsibilities: string
  qualifications: string
}

export const schemaAddJobs = yup.object({
  title: requiredStringSchema.min(3),
  positions: numberSchema.positive().notRequired(),
  salary: requiredHundredthNumberSchema,
  responsibilities: yup.string(),
  qualifications: yup.string(),
})
