import { FC } from "react"

import { ISearchbarFormState } from "components/form/searchbarForm/SearchbarForm.types"
import SearchbarFormWithButtons from "components/form/searchbarFormWithButtons"
import { useTranslation } from "react-i18next"
import { TransactionTypeShort } from "ts/types/Transaction"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"

interface IProps {
  variant: TransactionTypeShort
  onAddItemClick: () => void
  onSearchFormSubmit: (data: ISearchbarFormState) => void
}

const SearchbarAndAddButton: FC<IProps> = ({
  variant,
  onAddItemClick,
  onSearchFormSubmit,
}) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  return (
    <SearchbarFormWithButtons
      searchbarFormProps={{
        placeholder: t(tKey.searchForItem),
        onSubmit: onSearchFormSubmit,
      }}
      buttonTitle={t(tKey.addItem)}
      onButtonClick={onAddItemClick}
    />
  )
}

export default SearchbarAndAddButton
