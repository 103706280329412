import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IRetrieveStudent, IStudentSearch } from "ts/interfaces/Student"

import {
  IGetStudentsSearchArgs,
  IRetrieveStudentArgs,
} from "./studentsSearch.types"

export const useQueryStudentsSearch = (args?: IGetStudentsSearchArgs) =>
  useQuery<AxiosResponse<IStudentSearch[]>, AxiosError>(
    ["studentsSearch", JSON.stringify(args?.params)],
    async () =>
      axiosInstance(Endpoint.STUDENTS_SEARCH, {
        params: args?.params,
      }),
    {
      ...args?.options,
    }
  )

export const useQueryStudentRetrieve = ({
  params,
  options,
}: IRetrieveStudentArgs) =>
  useQuery<AxiosResponse<IRetrieveStudent>, AxiosError>(
    ["studentRetrieve", params],
    async () => axiosInstance(`${Endpoint.RETRIEVE_STUDENT}/${params}`),
    {
      ...options,
    }
  )
