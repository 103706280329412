import { requiredStringSchema, stringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  first_name: "",
  last_name: "",
  survey: "",
}

export const validationSchema = yup.object({
  first_name: requiredStringSchema.min(2),
  last_name: requiredStringSchema.min(2),
  survey: stringSchema,
})
