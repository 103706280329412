import React from "react"

import { Box, CircularProgress, Stack } from "@mui/material"
import { useQueryStudentBalance } from "api/reactQuery/queries/studentBalance"
import ErrorText from "components/common/error/errorText"
import { useIsBreakpointUp } from "hooks/breakpoint"

import SavingAccountBalance from "./savingAccountBalance"

const StudentBalance = () => {
  const { data, isLoading, isError } = useQueryStudentBalance()
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Stack direction="row" mr="16px" gap="16px" alignItems="center">
      <Stack alignItems="end">
        {isLoading && <CircularProgress size="24px" />}
        {isError && <ErrorText />}
        {data?.data && (
          <>
            {isTablet && (
              <SavingAccountBalance
                savingsAmount={data?.data.savings}
                checkingAmount={data?.data.balance}
                totalAmount={data?.data.total_balance}
              />
            )}
          </>
        )}
      </Stack>
      <Box height="34px" width="1px" bgcolor="mockup.primary95" />
    </Stack>
  )
}

export default StudentBalance
