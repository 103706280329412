import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { IUserStudent } from "ts/interfaces/User"

import { IPatchStudentMeArgs, IPatchStudentMeInput } from "./studentMe.types"

export const useMutationPatchStudentMe = (hookArgs?: IPatchStudentMeArgs) =>
  useMutation<AxiosResponse<IUserStudent>, AxiosError, IPatchStudentMeInput>(
    async ({ avatar_url }) =>
      await axiosInstance.patch(Endpoint.STUDENT_ME, {
        avatar_url,
      }),
    hookArgs?.options
  )
