export const transactionsTypeOptions = [
  "Salary",
  "Expense",
  "Bonus",
  "Fine",
  "Purchase",
  "Refund",
  "Reset",
  "Transfer",
  "Interest",
]
