import React from "react"

import { Box, Button, Typography } from "@mui/material"

interface IProps {
  onClick: () => void
}

const SignByEmailButton = ({ onClick }: IProps) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      fullWidth
      sx={(theme) => ({
        borderRadius: "8px",
        height: "40px",
        position: "relative",
        border: `1px solid ${theme.palette.mockup.neutral90}`,
        color: theme.palette.mockup.neutral10,
      })}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ position: "absolute", pointerEvents: "none" }}
      >
        <Typography>Sign up with username</Typography>
      </Box>
    </Button>
  )
}

export default SignByEmailButton
