import { stringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  search: "",
}

export const validationSchema = yup.object({
  search: stringSchema,
})
