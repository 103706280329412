import { useEffect } from "react"

import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useUserRole } from "hooks/user"
import { selectIsMenuOpened } from "store/menu/menu.selectors"
import { setIsMenuOpened } from "store/menu/menu.slice"
import { UserRole } from "ts/enums/User"

import { AsideNavContainer } from "./AsideNav.styles"
import NavHeader from "./navHeader"
import Navigation from "./navigation"
import NavigationStudent from "./navigationStudent"
import Overlay from "./overlay"

const AsideNav = () => {
  const isDesktop = useIsBreakpointUp("desktop")
  const isMenuOpened = useAppSelector(selectIsMenuOpened)
  const userRole = useUserRole()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isMenuOpened && !isDesktop) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "auto"
    }
  }, [isMenuOpened, isDesktop])

  useEffect(() => {
    const isMenuOpened = localStorage.getItem("isMenuOpened")
    if (isDesktop && isMenuOpened !== null) {
      dispatch(setIsMenuOpened(JSON.parse(isMenuOpened)))
    } else if (isDesktop && isMenuOpened === null) {
      localStorage.setItem("isMenuOpened", JSON.stringify(true))
      dispatch(setIsMenuOpened(true))
    }
  })

  useEffect(() => {
    if (isMenuOpened && !isDesktop) {
      localStorage.setItem("isMenuOpened", JSON.stringify(false))
      dispatch(setIsMenuOpened(false))
    }
  }, [isDesktop])

  const actualState = localStorage.getItem("isMenuOpened")
  return (
    <>
      <AsideNavContainer
        isOpened={
          !isDesktop ? isMenuOpened : actualState && JSON.parse(actualState)
        }
        component="aside"
      >
        <NavHeader />
        {userRole === UserRole.TEACHER && <Navigation />}
        {userRole === UserRole.STUDENT && <NavigationStudent />}
      </AsideNavContainer>
      <Overlay />
    </>
  )
}

export default AsideNav
