import React, { forwardRef, useImperativeHandle, useState } from "react"

import { ReactComponent as IconBulb } from "@common_assets/svg/bulb.svg"
import { ReactComponent as IconCheck } from "@common_assets/svg/check-curved.svg"
import { ReactComponent as IconCoin } from "@common_assets/svg/coin.svg"
import { ReactComponent as IconEdit } from "@common_assets/svg/edit.svg"
import { ReactComponent as IconExpenses } from "@common_assets/svg/expenses.svg"
import { ReactComponent as GreenDollarIcon } from "@common_assets/svg/greenDollarTwo.svg"
import { ReactComponent as IconConfetti } from "@common_assets/svg/noto-v1_confetti-ball.svg"
import { ReactComponent as IconConfettiV2 } from "@common_assets/svg/noto-v1_confetti-ball2.svg"
import { ReactComponent as IconPlus } from "@common_assets/svg/plus.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
  TextField,
} from "@mui/material"
import { useMutationAccountTransfer } from "api/reactQuery/mutations/accountTransfer"
import {
  useMutationFunds,
  useMutationGoalsDelete,
  useMutationGoalsUpdate,
  useMutationWithdraw,
} from "api/reactQuery/mutations/goals"
import { StudentGoalsTypes } from "api/reactQuery/queries/goals.types"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { selectAccount } from "store/savings/savings.selectors"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"
import { progressColorSchema } from "utils/progressColorSchema"

import CustomTextField from "../../../../form/common/field/textField"
import GoalDialog from "./goalDialog"
import { validationSchema } from "./GridGoal.utility"

interface GridGoalTypes {
  data: StudentGoalsTypes
  handleCloseSpendMoney: () => void
}

export interface GridGoalRef {
  buttonFunds: () => void
  isLoadingUpdateFunds: boolean
}

const GridGoal = forwardRef<GridGoalRef, GridGoalTypes>(
  ({ data, handleCloseSpendMoney }, ref) => {
    const { showSnackbar } = useCustomSnackbar()
    const isDesktop = useIsBreakpointUp(1440)
    const theme = useTheme()
    const [error, setError] = useState({
      amount: "",
      balance: "",
    })
    const [balance, setBalance] = useState<number | string>("")
    const [amount, setAmount] = useState<number | string>("")
    const { title, description, total_amount, id, current_amount } = data
    const { isOpen, handleOpen, handleClose } = useDialog()
    const [isGoalCompleted, setIsGoalCompleted] = useState(false)
    const {
      isOpen: isOpenFunds,
      handleOpen: handleOpenFunds,
      handleClose: handleCloseFunds,
    } = useDialog()

    const {
      isOpen: isOpenWithdraw,
      handleOpen: handleOpenWithdraw,
      handleClose: handleCloseWithdraw,
    } = useDialog()

    const {
      isOpen: isOpenTransferMoney,
      handleOpen: handleOpenTransferMoney,
      handleClose: handleCloseTransferMoney,
    } = useDialog()

    const dataClass = useAppSelector(selectAccount)

    const buttonFunds = () => {
      handleOpenFunds()
    }

    useImperativeHandle(ref, () => ({
      buttonFunds,
      isLoadingUpdateFunds: isLoadingGoalsUpdate,
    }))

    const { mutate: mutationTransferSavingsToChecking } =
      useMutationAccountTransfer({
        options: {
          onError: (err) => {
            showSnackbar({
              title: getErrorMessageFromTab(err),
              variant: "error",
            })
          },
        },
      })

    const { mutate: withdrawMoney, isLoading: isLoadingWithdrawMoney } =
      useMutationWithdraw({
        goal_id: id,
        options: {
          onSuccess: () => {
            showSnackbar({
              title: "Withdraw has been done successfully",
            })
            if (isGoalCompleted && typeof amount === "number") {
              mutationTransferSavingsToChecking({
                is_to_balance: true,
                account_from_id: dataClass?.id as number,
                amount: amount,
                is_from_balance: false,
              })
            }
            handleCloseWithdraw()
            setError({ amount: "", balance: "" })
            setBalance("")
            setAmount("")
            queryClient.invalidateQueries("studentGoals")

            // added timeout to get updated data
            setTimeout(() => {
              queryClient.invalidateQueries("studentSavings")
            }, 300)
          },
          onError: (err) => {
            showSnackbar({
              title: getErrorMessageFromTab(err),
              variant: "error",
            })
          },
        },
      })

    const { mutate: deleteGoal } = useMutationGoalsDelete({
      goal_id: id,
      savings_account_id: dataClass?.id as number,
      options: {
        onSuccess: () => {
          showSnackbar({
            title: "Funds have been deleted successfully",
          })
          queryClient.invalidateQueries("studentGoals")
          queryClient.invalidateQueries("studentSavings")
          queryClient.invalidateQueries("savingsSnapshot")
        },
      },
    })

    const queryClient = useQueryClient()

    const methods = useForm({
      defaultValues: {
        title,
        description,
        total_amount,
      },
      resolver: yupResolver(validationSchema),
    })

    const { mutate, isLoading: isLoadingGoalsUpdate } = useMutationGoalsUpdate({
      goal_id: id,
      savings_account_id: dataClass?.id as number,
      options: {
        onSuccess: () => {
          showSnackbar({
            title: "Edit has been updated successfully",
          })
          handleClose()
          queryClient.invalidateQueries("studentGoals")
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

    const { mutate: mutateFunds, isLoading: isLoadingFunds } = useMutationFunds(
      {
        goal_id: id,
        options: {
          onSuccess: () => {
            showSnackbar({
              title: "Funds have been added successfully",
            })
            handleCloseFunds()
            handleCloseSpendMoney()
            setError({ amount: "", balance: "" })
            setBalance("")
            setAmount("")
            queryClient.invalidateQueries("studentGoals")
          },
          onError: (err) => {
            showSnackbar({
              title: getErrorMessageFromTab(err),
              variant: "error",
            })
          },
        },
      }
    )

    const handleGoalsSubmit = () => {
      mutateFunds({
        amount: Number(amount),
        fk_savings_account_id: dataClass?.id as number,
      })
    }

    const handleWithdrawSubmit = () => {
      withdrawMoney({
        amount: Number(amount),
        fk_savings_account_id: dataClass?.id as number,
      })
    }

    const handleEditGoal = () => {
      methods.handleSubmit((data) => {
        mutate(data)
      })()
    }

    return (
      <Grid item desktop={isDesktop ? 6 : 12} tablet={12}>
        <Stack
          sx={{
            backgroundColor: theme.palette.mockup.primary99,
            borderRadius: "16px",
            p: 2,
            rowGap: 1,
            height: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" columnGap={1}>
              {!data.icon_url.length ? (
                <IconWrapper width="32px" height="32px">
                  <IconBulb width="100%" height="100%" />
                </IconWrapper>
              ) : (
                <img
                  src={data.icon_url}
                  width="32px"
                  height="32px"
                  alt="icon"
                />
              )}
              <Typography sx={{ wordBreak: "break-all" }}>
                {data.title}
              </Typography>
            </Box>
            <IconWrapper
              width="24px"
              height="24px"
              color={
                data.goal_progress === 100
                  ? theme.palette.mockup.success50
                  : theme.palette.primary.main
              }
              sx={{ ":hover": { opacity: ".7" } }}
            >
              {data.goal_progress === 100 ? (
                <IconCheck width="100%" height="100%" />
              ) : (
                <IconEdit onClick={handleOpen} style={{ cursor: "pointer" }} />
              )}
            </IconWrapper>
          </Stack>
          <Typography
            variant="body2"
            fontWeight="500"
            pr={3}
            sx={{ wordBreak: "break-all" }}
          >
            {data.description}
          </Typography>
          <Box display="flex">
            <Coin
              amount={data.current_amount}
              valueTextProps={{ fontSize: "28px" }}
            />
            <Typography
              component="span"
              sx={{
                fontSize: "12px",
                transform: "translateY(30%)",
                fontWeight: "normal",
              }}
            >
              /${data.total_amount}
            </Typography>
          </Box>
          <Box
            sx={{
              height: "24px",
              width: "100%",
              backgroundColor: progressColorSchema(data.goal_progress, theme)
                .main,
              borderRadius: "12px",
              display: "flex",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: `calc(${data.goal_progress}/100 * 100%)`,
                backgroundColor: progressColorSchema(data.goal_progress, theme)
                  .second,
                borderBottomLeftRadius: "12px",
                borderTopLeftRadius: "12px",
              }}
            />
            <Typography
              variant="body3"
              color="mockup.neutral10"
              fontWeight="500"
              sx={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {data.goal_progress}%
            </Typography>
          </Box>
          {data.goal_progress === 100 ? (
            <Button
              onClick={() => {
                handleOpenTransferMoney()
                setAmount(data.current_amount)
                setIsGoalCompleted(true)
              }}
            >
              Spend money
            </Button>
          ) : (
            <Box display="flex" gap={1} width="100%" flexWrap="wrap">
              <Button
                fullWidth
                startIcon={<IconPlus />}
                onClick={handleOpenFunds}
              >
                Add Funds
              </Button>
              <Button fullWidth variant="outlined" onClick={handleOpenWithdraw}>
                Withdraw
              </Button>
            </Box>
          )}
        </Stack>
        <Dialog
          open={isOpenTransferMoney}
          onClose={() => {
            handleCloseTransferMoney()
            setIsGoalCompleted(false)
          }}
          titleText="Congratulations! Transfer money from this goal to your Checking Account to spend!"
          onActionButtonClick={() => {
            setIsGoalCompleted(false)
            withdrawMoney({
              fk_savings_account_id: dataClass?.id as number,
              amount: Number(amount),
            })
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            position="relative"
            py={2}
          >
            <IconConfetti style={{ transform: "translateX(30%)" }} />
            <Coin
              amount={amount}
              valueTextProps={{ fontSize: "72px" }}
              iconSize="32px"
            />
            <IconConfettiV2 style={{ transform: "translateX(-20%)" }} />
          </Stack>
        </Dialog>
        <Dialog
          titleText="Edit goal"
          open={isOpen}
          onClose={handleClose}
          onIconClose
          onDeleteClick={() => deleteGoal(null)}
          actionAcceptButtonProps={{ disabled: isLoadingGoalsUpdate }}
          onActionButtonClick={() => {
            methods.handleSubmit((data) => {
              mutate(data)
            })()
          }}
        >
          <form
            onKeyDown={(e) =>
              handleKeyDown(e, handleEditGoal, isLoadingGoalsUpdate)
            }
          >
            <FormProvider {...methods}>
              <Stack rowGap={2}>
                <CustomTextField name="title" label="Name" />
                <CustomTextField
                  name="description"
                  label="Description (Optional)"
                />
                <CustomTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconCoin />
                      </InputAdornment>
                    ),
                  }}
                  name="total_amount"
                  label="Savings goals"
                />
              </Stack>
            </FormProvider>
          </form>
        </Dialog>
        <GoalDialog
          onActionButtonClick={() => handleGoalsSubmit()}
          isOpen={isOpenFunds}
          handleClose={() => {
            handleCloseFunds()
            setError({ amount: "", balance: "" })
            setBalance("")
            setAmount("")
          }}
          titleText={`Add funds to ${title}`}
          titleBeforeSlot={<GreenDollarIcon />}
          dataClass={dataClass}
          goalname={title}
          current_amount={current_amount}
          isLoading={isLoadingFunds}
        >
          <TextField
            onKeyDown={(e) =>
              handleKeyDown(e, handleGoalsSubmit, isLoadingFunds)
            }
            label="How much would you like to add?"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            helperText={error.amount}
            error={!!error.amount}
          />
        </GoalDialog>
        <GoalDialog
          onActionButtonClick={() => handleWithdrawSubmit()}
          isOpen={isOpenWithdraw}
          handleClose={() => {
            handleCloseWithdraw()
            setError({ amount: "", balance: "" })
            setBalance("")
            setAmount("")
          }}
          titleText={`Withdraw funds to ${title}`}
          titleBeforeSlot={<IconExpenses />}
          dataClass={dataClass}
          goalname={title}
          current_amount={current_amount}
          isWithdraw
          isLoading={isLoadingWithdrawMoney}
        >
          <TextField
            onKeyDown={(e) =>
              handleKeyDown(e, handleWithdrawSubmit, isLoadingWithdrawMoney)
            }
            label="How much would you like to withdraw?"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            helperText={error.amount}
            error={!!error.amount}
          />
        </GoalDialog>
      </Grid>
    )
  }
)

GridGoal.displayName = "GridGoal"
export default GridGoal
