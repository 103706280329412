import { Dispatch, FC, useEffect, useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { IResponseStudentClasses } from "api/reactQuery/queries/students.types"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"
interface IProps {
  classes: IResponseStudentClasses[]
  checked: number[]

  setChecked: Dispatch<React.SetStateAction<number[]>>
}

const ManageStudentClasses: FC<IProps> = ({ classes, checked, setChecked }) => {
  const { t } = useTranslation()
  const [state, setState] = useState([...classes])

  const [radioValue, setRadioValue] = useState(
    classes.find((classItem) => classItem.is_in_class)?.id
  )
  const user = useAppSelector(selectUser)

  useEffect(() => {
    state.forEach((el) => {
      if (el.is_in_class) {
        setChecked((prev) => [...prev, el.id])
      }
    })
  }, [])

  const handleChange = (id: number) => {
    const currentIndex = checked.indexOf(id)
    setState(
      state.map((item) => {
        if (item.id === id) {
          checked.indexOf(id) === -1
            ? setChecked((prev) => [...prev, id])
            : checked.splice(currentIndex, 1)
          return { ...item, is_in_class: !item.is_in_class }
        } else {
          return item
        }
      })
    )
  }

  const handleChangeRadio = (value: string) => {
    setRadioValue(+value)
    setChecked([+value])
  }

  return (
    <Stack>
      <Typography
        variant="body1"
        fontWeight="500"
        color="mockup.neutral10"
        lineHeight="24px"
      >
        {t("studentProfile.introStudentClasses")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        {user?.user.school_plan !== UserSchoolPlan.FREE &&
          state.map((classInfo) => (
            <Tooltip
              arrow
              placement="right"
              key={classInfo.id}
              title={classInfo.teachers.map((el) => (
                <Box key={el.id}>
                  {el.first_name} {el.last_name}
                </Box>
              ))}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={classInfo.is_in_class}
                    onChange={() => handleChange(classInfo.id)}
                  />
                }
                label={
                  <Typography variant="body2" fontWeight="500" pl={1}>
                    {classInfo.name}
                  </Typography>
                }
                sx={{ mb: 4, width: "max-content" }}
              />
            </Tooltip>
          ))}
        {user?.user.school_plan === UserSchoolPlan.FREE && (
          <Stack gap="16px">
            <RadioGroup
              value={radioValue}
              onChange={(_, value) => handleChangeRadio(value)}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              {state.map((classInfo) => (
                <FormControlLabel
                  key={classInfo.id}
                  value={classInfo.id}
                  control={<Radio />}
                  label={
                    <Typography variant="body2" fontWeight="500" pl={1}>
                      {classInfo.name}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
            <Button
              sx={{ alignSelf: "start", mt: "16px" }}
              variant="outlined"
              onClick={() => {
                setRadioValue(undefined)
                setChecked([])
              }}
            >
              {t("studentProfile.removeFromClass")}
            </Button>
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default ManageStudentClasses
