import { FC, ReactNode } from "react"

import { useQueryClassesStoreItems } from "api/reactQuery/queries/classesStoreItems"
import { AxiosResponse } from "axios"
import ErrorText from "components/common/error/errorText"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import SearchbarFormWithButtons from "components/form/searchbarFormWithButtons"
import { useDialog } from "hooks/dialog"
import { useSelectedItems, useSorting } from "hooks/listing"
import { useFilterItems } from "hooks/listing/filtering"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"
import { IClassStoreItem } from "ts/interfaces/Class"

import BatchActionButtons from "./batchActionButtons"
import DialogAddItem from "./dialogAddItem"
import DialogAddSuggestedItems from "./dialogAddSuggestedItems"
import NoItemsYet from "./noItemsYet"

interface IProps {
  renderDataView: (data: {
    apiData: AxiosResponse<IClassStoreItem[], unknown> | undefined
    sortingProps: ReturnType<typeof useSorting<IClassStoreItem>>
    selectingProps: ReturnType<typeof useSelectedItems<IClassStoreItem>>
  }) => ReactNode
}

const DataWrapper: FC<IProps> = ({ renderDataView }) => {
  const { t } = useTranslation()

  const classId = useClassId()

  const { data, isLoading, isError } = useQueryClassesStoreItems({
    classId: Number(classId),
  })

  const { filteredData, searchFilter, setSearchFilter } = useFilterItems({
    data: data ? data.data : [],
    getFilterBy: (el) => el.name,
  })

  const sortingProps = useSorting({
    data: filteredData,
  })
  const { sortedData } = sortingProps

  const selectingProps = useSelectedItems(sortedData)
  const { selectedItems, clearSelectedItems } = selectingProps

  const {
    isOpen: isOpenAddItem,
    handleOpen: handleOpenAddItem,
    handleClose: handleCloseAddItem,
  } = useDialog()

  const {
    isOpen: isOpenAddSuggestedItems,
    handleOpen: handleOpenAddSuggestedItems,
    handleClose: handleCloseAddSuggestedItems,
  } = useDialog()

  return (
    <>
      <PrimaryTile sx={{ borderRadius: "0px 8px 8px 8px", overflow: "hidden" }}>
        <SearchbarFormWithButtons
          searchbarFormProps={{
            onSubmit: (data) => setSearchFilter(data.search),
            placeholder: `${t("store.searchForItem")}...`,
          }}
          buttonTitle={t("store.addItem")}
          onButtonClick={() => {
            handleOpenAddItem()
          }}
        />
        <BatchActionButtons
          selectedItems={selectedItems}
          clearSelectedItems={clearSelectedItems}
        />
        {renderDataView({
          apiData: data,
          sortingProps,
          selectingProps,
        })}
        {isLoading && <TableSkeleton />}
        {isError && <ErrorText />}
        {!isLoading &&
          data?.data &&
          data?.data.length > 0 &&
          sortedData.length === 0 && (
            <NoMatchesForSearch search={searchFilter} />
          )}
        {!isLoading && data?.data?.length === 0 && (
          <NoItemsYet
            onAddClick={() => {
              handleOpenAddItem()
            }}
            onAddSuggestedClick={() => {
              handleOpenAddSuggestedItems()
            }}
          />
        )}
      </PrimaryTile>
      <DialogAddItem isOpen={isOpenAddItem} onClose={handleCloseAddItem} />
      <DialogAddSuggestedItems
        isOpen={isOpenAddSuggestedItems}
        onClose={handleCloseAddSuggestedItems}
      />
    </>
  )
}

export default DataWrapper
