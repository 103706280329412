import { useState } from "react"

import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import { ISortingState } from "hooks/listing"
import { useTranslation } from "react-i18next"
import { ITransactionItemClass } from "ts/interfaces/Transaction"
import { getSortingText } from "utils/listing"

import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import Listing from "./listing"
import { sortingOptions, sortingTextMap } from "./ListingView.config"

const ListingView = () => {
  const { t } = useTranslation()
  const [count, setCount] = useState(10)

  return (
    <ViewCommonElementsContainer
      renderDataView={({
        apiData,
        selectingProps: {
          selectedItems,
          toggleAll,
          toggleItem,
          getIsChecked,
          setSelectedItems,
        },
        sortingProps: { sortedData, sortingState, setSortingState },
      }) => (
        <>
          <ListingSortingHeader
            sortingText={t(getSortingText(sortingState, sortingTextMap))}
            sortingActive={!!sortingState.orderBy}
            sortingDirection={sortingState.order}
            onSubmit={(data) => {
              const parsedFormData = JSON.parse(
                data.sort
              ) as ISortingState<ITransactionItemClass>
              setSortingState({
                ...parsedFormData,
                comparator:
                  parsedFormData.orderBy === "created_at"
                    ? "dateStrings"
                    : undefined,
              })
            }}
            options={sortingOptions}
            checkboxProps={{
              checked:
                sortedData.length === selectedItems.length &&
                !!apiData?.data.length,
              onChange: () => {
                if (apiData?.data && apiData?.data.length > 500) {
                  if (selectedItems.length === 500) {
                    setSelectedItems([])
                    return
                  }
                  setSelectedItems(apiData?.data.slice(0, 500))
                } else {
                  toggleAll()
                }
              },
              indeterminate:
                sortedData.length !== selectedItems.length &&
                !!selectedItems.length,
            }}
          />
          <Listing
            countsStat={{ count, setCount }}
            transactions={sortedData.slice(0, count)}
            sortedDataLength={sortedData.length}
            getListItemCheckboxProps={(el) => ({
              checked: getIsChecked(el),
              onChange: () => {
                toggleItem(el)
              },
            })}
          />
        </>
      )}
    />
  )
}

export default ListingView
