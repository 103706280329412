import axiosInstance from "api"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { classTransactionItemsEndpoints } from "utils/reactQuery"

import { IGetClassesTransactionItemsArgs } from "./classesTransactionItems.types"

export const useQueryClassesTransactionItems = ({
  classId,
  variant,
  options,
}: IGetClassesTransactionItemsArgs) =>
  useQuery<AxiosResponse<ITransactionItem[]>, AxiosError>(
    [`classes${variant}`, classId],
    async () =>
      axiosInstance(
        classTransactionItemsEndpoints[variant].replace("ID", `${classId}`)
      ),
    options
  )
