import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  CompletedGoalsTypes,
  ICompletedGoalsArgs,
  IStudentGoalsArgs,
  StudentGoalsTypes,
} from "./goals.types"

export const useQueryStudentGoals = (args?: IStudentGoalsArgs) =>
  useQuery<AxiosResponse<StudentGoalsTypes[]>, AxiosError>(
    ["studentGoals", args?.saving_account_id],
    async () => {
      return axiosInstance(
        Endpoint.STUDENT_ID_GOALS.replace("ID", `${args?.saving_account_id}`)
      )
    },
    args?.options
  )

export const useQueryCompletedGoals = (args?: ICompletedGoalsArgs) =>
  useQuery<AxiosResponse<CompletedGoalsTypes[]>, AxiosError>(
    ["completedGoals"],
    async () => axiosInstance(Endpoint.STUDENT_GOALS_COMPLETED),
    args?.options
  )
