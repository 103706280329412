import React, { useEffect } from "react"

import { ReactComponent as IconTransfer } from "@common_assets/svg/transfer.svg"
import {
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { useQueryStudentSavings } from "api/reactQuery/queries/savings"
import AccountTransferDialog from "components/common/accountTransferDialog"
import ErrorText from "components/common/error/errorText"
import { useDialog } from "hooks/dialog"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectAccount } from "store/savings/savings.selectors"
import { setAccount } from "store/savings/savings.slice"

import { IInitialDate } from "./SavingsColumn.types"
import Tabs from "./tabs/Tabs"

interface ISavingsColumn {
  initialData: () => IInitialDate
}

const SavingsColumn = ({ initialData }: ISavingsColumn) => {
  const theme = useTheme()
  const { isOpen, handleOpen, handleClose } = useDialog()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const selectClass = useAppSelector(selectAccount)

  const { data, isLoading, isError } = useQueryStudentSavings()

  useEffect(() => {
    if (selectClass !== null && data) {
      const currentClass =
        data?.data.find((item) => item.id === selectClass.id) || null
      dispatch(setAccount(currentClass))
    }
  }, [data])

  if (isLoading)
    return (
      <Stack rowGap={3}>
        <Skeleton height="200px" width="400px" />
        <Skeleton height="200px" width="400px" />
        <Skeleton height="200px" width="400px" />
      </Stack>
    )

  if (isError) return <ErrorText />

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 3,
        borderRadius: 2,
        border: `1px solid ${theme.palette.mockup.primary95}`,
      }}
    >
      <Stack flexDirection="row" columnGap={2} alignItems="center">
        <Typography
          color="mockup.neutral10"
          width="max-content"
          fontSize="24px"
        >
          {t("myActivity.all_accounts")}
        </Typography>
        <Button onClick={handleOpen} endIcon={<IconTransfer />}>
          {t("myActivity.transfer")}
        </Button>
      </Stack>
      <Divider style={{ margin: "20px" }} />
      <Tabs
        data={data?.data}
        onChangeClass={() => {
          initialData().setSearchWithPage((prev) => ({ ...prev, page: 0 }))
          initialData().setTypeValue("all_types")
          initialData().setIsDateDisplay("all")
          initialData().setStartDate(null)
          initialData().setEndDate(null)
        }}
      />
      <AccountTransferDialog
        isOpen={isOpen}
        handleClose={handleClose}
        data={data?.data}
      />
    </Stack>
  )
}
export default SavingsColumn
