import { FC } from "react"

import {
  Checkbox,
  CheckboxProps,
  TableSortLabel,
  TableSortLabelProps,
} from "@mui/material"
import BasicTable from "components/common/table/basicTable"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

interface IProps {
  checkboxProps: CheckboxProps
  getTableSortLabelProps: (
    property: keyof IObjectStudentDetailed
  ) => TableSortLabelProps
  savingsAccountOption?: boolean
}

const Table: FC<IProps> = ({
  checkboxProps,
  getTableSortLabelProps,
  savingsAccountOption,
  children,
}) => {
  const { t } = useTranslation()
  const switchState = useAppSelector(selectDisplaySavings)

  return (
    <BasicTable
      wrapperProps={{
        borderRadius: "0px",
        sx: { border: "none" },
      }}
      head={
        <BasicTable.Row>
          <BasicTable.Cell width="50px" px="8px">
            <Checkbox {...checkboxProps} />
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "50%" }}>
            <TableSortLabel active {...getTableSortLabelProps("first_name")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("students.student")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell minWidth="100px" tableCellProps={{ width: "50%" }}>
            <BasicTable.HeadText textTransform="uppercase">
              {t("students.job")}
            </BasicTable.HeadText>
          </BasicTable.Cell>
          <BasicTable.Cell width="90px">
            <TableSortLabel active {...getTableSortLabelProps("balance")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("students.balance")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          <BasicTable.Cell width="90px">
            <TableSortLabel active {...getTableSortLabelProps("balance")}>
              <BasicTable.HeadText textTransform="uppercase">
                {t("students.pending")}
              </BasicTable.HeadText>
            </TableSortLabel>
          </BasicTable.Cell>
          {savingsAccountOption && switchState && (
            <>
              <BasicTable.Cell width="90px">
                <TableSortLabel active {...getTableSortLabelProps("balance")}>
                  <BasicTable.HeadText textTransform="uppercase">
                    {t("students.checking")}
                  </BasicTable.HeadText>
                </TableSortLabel>
              </BasicTable.Cell>
              <BasicTable.Cell width="90px">
                <TableSortLabel active {...getTableSortLabelProps("balance")}>
                  <BasicTable.HeadText textTransform="uppercase">
                    {t("students.savings")}
                  </BasicTable.HeadText>
                </TableSortLabel>
              </BasicTable.Cell>
            </>
          )}
          <BasicTable.Cell width="109px" />
        </BasicTable.Row>
      }
      body={children}
    />
  )
}

export default Table
