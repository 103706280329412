import theme from "themes"
import { TransactionType } from "ts/types/Transaction"

export const colorSchemeMap: Record<
  TransactionType,
  {
    color: string
    background: string
    borderColor: string
  }
> = {
  bonus: {
    color: theme.palette.mockup.success50,
    background: theme.palette.mockup.success90,
    borderColor: theme.palette.mockup.success50,
  },
  fine: {
    color: theme.palette.mockup.error50,
    background: theme.palette.mockup.error90,
    borderColor: theme.palette.mockup.error50,
  },
  expense: {
    color: theme.palette.mockup.secondary60,
    background: theme.palette.mockup.secondary90,
    borderColor: theme.palette.mockup.secondary80,
  },
  salary: {
    color: theme.palette.mockup.primary60,
    background: theme.palette.mockup.primary90,
    borderColor: theme.palette.mockup.primary80,
  },
}
