import { ThemeOptions } from "@mui/material"

const muiTooltip: ThemeOptions["components"] = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        border: `1px solid ${theme.palette.mockup.neutral90}`,
        background: theme.palette.common.white,
        borderRadius: "8px",
        color: theme.palette.mockup.neutral10,
        padding: "8px",
        fontSize: "12px",
        fontWeight: 400,
      }),
    },
  },
}

export default muiTooltip
