import { ReactComponent as SvgStoreClosed } from "@common_assets/svg/store-closed.svg"
import { Stack } from "@mui/material"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const StoreClosed = () => {
  const { t } = useTranslation()

  return (
    <Stack height="100%" direction="row" alignItems="center">
      <NoElementMessageWithoutBtn
        title={t("storeStudent.storeIsClosed")}
        description={t("storeStudent.checkBackLaterOrAshYourTeacher")}
        Icon={<SvgStoreClosed />}
      />
    </Stack>
  )
}
export default StoreClosed
