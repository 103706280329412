export const storeStudent = {
  storeIsClosed: "The store is closed",
  checkBackLaterOrAshYourTeacher:
    "Check back later or ask your teacher when the store will be open next!",
  searchForItem: "Search for item",
  itemsAvailable_one: "{{count}} item available",
  itemsAvailable_other: "{{count}} items available",
  addToCart: "Add to cart",
  cart: "Cart",
  yourCurrentBalance: "Your current balance",
  inTotal: "In total",
  calculateNewAccountBalance: "Calculate new account balance",
  payNow: "Pay now",
  itemsPurchasedSuccessfully: "Items purchased successfully",
  yourCartIsEmpty: "Your cart is empty",
  chooseSomethingFromTheStore:
    "Choose something from the store and add to cart.",
  incorrectNewBalance: "Incorrect new balance",
  beforeYouBuy:
    "Before you buy an item, resolve all your pending transactions first",
  cartAmountFor: "Cart ({{count}}) for",
}
