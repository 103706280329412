import { Box, styled } from "@mui/material"

export const ExportIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  minWidth: "56px",
  height: "56px",
  borderRadius: "50%",
  cursor: "pointer",
  border: `2px solid ${theme.palette.mockup.primary90}`,
  ":hover": {
    transition: "background .3s",
    background: theme.palette.mockup.primary90,
  },
  transition: "background .3s",
  background: theme.palette.common.white,
}))

export const BoxIconTrash = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "20px",
  right: "20px",
  display: "flex",
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  flexWrap: "wrap",
  border: `1.5px solid ${theme.palette.primary.light}`,
  color: `${theme.palette.primary.main}`,
  cursor: "pointer",
  ":hover": {
    transition: "background .5s",
    background: theme.palette.mockup.primary95,
  },
}))
