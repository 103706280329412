import { CSSProperties, FC } from "react"

import { ReactComponent as IconCoin } from "@brand_assets/svg/coin.svg"
import { Stack, Tooltip, Typography, TypographyProps } from "@mui/material"
import { showDecimal } from "utils/decimalNumber"

import IconWrapper from "../iconWrapper"

interface IProps {
  amount: number | string
  sign?: "+" | "-"
  size?: "small" | "big"
  iconSize?: string
  valueTextProps?: TypographyProps
  signTextProps?: TypographyProps
  onClick?: () => void
  isEllipsis?: boolean
  style?: CSSProperties
}

const Coin: FC<IProps> = ({
  amount,
  sign,
  size = "big",
  iconSize = process.env.REACT_APP_TYPE === "classequity" ? "16px" : "22px",
  valueTextProps,
  signTextProps,
  onClick,
  isEllipsis,
  style,
}) => {
  const getColor = () => {
    if (sign === "+") return "mockup.success50"
    if (sign === "-") return "mockup.error50"
    return "mockup.neutral10"
  }

  const color = getColor()

  return (
    <Stack
      direction="row"
      columnGap="4px"
      alignItems="center"
      onClick={onClick}
      style={style}
    >
      {sign && (
        <Typography
          variant={size === "big" ? "body1" : "body2"}
          lineHeight={size === "big" ? "24px" : "14px"}
          color={color}
          {...signTextProps}
        >
          {sign}
        </Typography>
      )}
      <IconWrapper height={iconSize} width={iconSize}>
        <IconCoin height={iconSize} width={iconSize} />
      </IconWrapper>
      <Tooltip
        title={isEllipsis && amount.toString().length >= 4 ? amount : ""}
      >
        <Typography
          variant={size === "big" ? "body1" : "body2"}
          lineHeight={size === "big" ? "24px" : "14px"}
          color={color}
          sx={
            isEllipsis
              ? {
                  maxWidth: "40px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }
              : {}
          }
          {...valueTextProps}
        >
          {showDecimal(`${amount}`)}
        </Typography>
      </Tooltip>
    </Stack>
  )
}

export default Coin
