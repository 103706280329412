export const sortingTextMap = {
  first_name: "store.student",
  title: "store.item",
  created_at: "store.date",
  value: "store.value",
}

export const sortingOptions = [
  {
    label: "store.studentAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "first_name",
    }),
  },
  {
    label: "store.studentDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "first_name",
    }),
  },
  {
    label: "store.itemAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "title",
    }),
  },
  {
    label: "store.itemDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "title",
    }),
  },
  {
    label: "store.dateAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "created_at",
    }),
  },
  {
    label: "store.dateDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "created_at",
    }),
  },
  {
    label: "store.valueAscending",
    value: JSON.stringify({
      order: "asc",
      orderBy: "value",
    }),
  },
  {
    label: "store.valueDescending",
    value: JSON.stringify({
      order: "desc",
      orderBy: "value",
    }),
  },
]
