import { useMemo } from "react"

import { matchPath, useLocation, useParams } from "react-router"
import { ITab } from "ts/interfaces/Tab"

export const useClassId = () => {
  const params = useParams()

  return params.classId || ""
}

export const useIsMatchingClassRoutes = () => {
  const classId = useClassId()

  return !!classId
}
export const useGetCurrentTabName = (tabs: ITab[]) => {
  const { pathname } = useLocation()

  const matchingRoute = useMemo(
    () =>
      tabs.find((route) =>
        route.activeOnPaths.some((path) => matchPath(path, pathname))
      ),
    [pathname]
  )

  return matchingRoute?.tabName
}
