import { yupResolver } from "@hookform/resolvers/yup"
import { Typography, Grid } from "@mui/material"
import { useMutationCreateSchool } from "api/reactQuery/mutations/createSchool"
import { useQueryGetCountries } from "api/reactQuery/queries/getCountries"
import RegisterButtons from "components/common/button/registerButtons"
import SelectField from "components/form/common/field/selectField"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch } from "hooks/store"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { setActivePageSignUp, setOwnSchool } from "store/auth/auth.slice"
import { IAddSchoolForm } from "ts/interfaces/AddSchoolForm"
import { getErrorMessage } from "utils/api"
import { statesOfUSA } from "utils/constants/states"
import { timezones, kuweitzone, otherTimezones } from "utils/timezones"

import { defaultValues, validationSchema } from "./AddSchoolForm.config"
import FormText from "./formText"

const AddSchoolForm = () => {
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const methods = useForm<IAddSchoolForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })

  const { mutate, isLoading } = useMutationCreateSchool({
    options: {
      onSuccess: (data) => {
        showSnackbar({
          title: t("noAuthFlow.schoolHasBeenCreated"),
        })
        dispatch(setOwnSchool(data.data))
        dispatch(setActivePageSignUp(3))
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessage(error),
          variant: "error",
        })
      },
    },
  })

  const { data: countriesData } = useQueryGetCountries()

  const onSubmit = (data: IAddSchoolForm) => {
    mutate(data)
  }

  const countryIdWatch = useWatch({
    name: "country_id",
    control: methods.control,
  })

  const cleanTimezone = () => {
    methods.setValue("state", "")
    methods.setValue("timezone", "")
  }

  const selectTimezone = () => {
    if (process.env.REACT_APP_TYPE === "bankeeonline") {
      return kuweitzone
    }
    if (process.env.REACT_APP_TYPE === "classequity") {
      if (Number(countryIdWatch) === 1) {
        return timezones
      } else if (Number(countryIdWatch) === 2) {
        return otherTimezones.map((time) => ({ label: time, value: time }))
      }
    }
    return []
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Typography
          color="mockup.neutral50"
          textAlign="left"
          mb={4}
          variant="h2"
        >
          {t("noAuthFlow.addNewSchool")}{" "}
        </Typography>
        <Grid container rowSpacing={5} columnSpacing={"12px"}>
          <Grid item mobile={12}>
            <SelectField
              onClick={cleanTimezone}
              name="country_id"
              label={t("noAuthFlow.country")}
              options={
                countriesData
                  ? countriesData.data.map((country) => ({
                      label: country.name,
                      value: country.id.toString(),
                    }))
                  : []
              }
            />
          </Grid>
          <FormText
            spacing={12}
            name="name"
            label={t("noAuthFlow.schoolName")}
          />
          <FormText
            spacing={12}
            name="district"
            label={t("noAuthFlow.district")}
          />
          <FormText
            spacing={12}
            name="city"
            label={t("noAuthFlow.cityOrTown")}
          />
          {countryIdWatch === "1" ? (
            <Grid item mobile={12}>
              <SelectField
                name="state"
                label={t("noAuthFlow.stateOrProvince")}
                options={statesOfUSA
                  .map((time) => ({
                    label: time,
                    value: time,
                  }))
                  .map((item) => ({ label: item.label, value: item.value }))}
              />
            </Grid>
          ) : (
            <FormText
              spacing={12}
              name="state"
              label={t("noAuthFlow.stateOrProvince")}
            />
          )}
          <FormText
            disabled={!countryIdWatch}
            spacing={6}
            name="zip_code"
            label={t("noAuthFlow.zipCode")}
          />
          <Grid item mobile={6}>
            <SelectField
              disabled={!countryIdWatch}
              name="timezone"
              label={t("noAuthFlow.timeZone")}
              options={selectTimezone().map((time) => ({
                label: time.label,
                value: time.value,
              }))}
            />
          </Grid>
        </Grid>
        <RegisterButtons
          submitButton={t("noAuthFlow.addSchool")}
          onClick={() => dispatch(setActivePageSignUp(3))}
          isLoading={isLoading}
        />
      </FormProvider>
    </form>
  )
}

export default AddSchoolForm
