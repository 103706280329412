import { add, isBefore } from "date-fns"
import { parseJwt } from "utils/api"
import {
  clearAuthDataLocalStorage,
  getAuthDataLocalStorage,
} from "utils/authData"

export const getAuthInitialData = () => {
  const authData = getAuthDataLocalStorage()
  if (!authData || !authData.token) {
    clearAuthDataLocalStorage()
    return null
  }
  const tokenParsedToken = parseJwt(authData.token) as {
    exp: number
    sub: string
  }

  const tokenExpireTime = new Date(tokenParsedToken.exp * 1000)
  const currentDatePlusFiveDays = add(new Date(), { days: 5 })

  if (!isBefore(currentDatePlusFiveDays, tokenExpireTime)) {
    clearAuthDataLocalStorage()
    window.location.href = "/"
    return null
  }
  return authData
}
