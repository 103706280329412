import { FC } from "react"

import { Box, BoxProps } from "@mui/material"

const IconWrapper: FC<BoxProps> = ({
  children,
  height = "24px",
  width = "24px",
  color = "primary.main",
  ...props
}) => {
  return (
    <Box color={color} height={height} width={width} {...props}>
      {children}
    </Box>
  )
}

export default IconWrapper
