import { FC } from "react"

import { Skeleton } from "@mui/material"

interface IProps {
  height: string
  style?: React.CSSProperties
}

const JobsSkeleton: FC<IProps> = ({ children, height, style }) => {
  return (
    <Skeleton width="100%" height={height} style={style}>
      {children}
    </Skeleton>
  )
}

export default JobsSkeleton
