export enum PaycheckInterval {
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  monthly = "monthly",
}

export enum Grade {
  K = "K",
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  TEN = "10",
  ELEVEN = "11",
  TWELVE = "12",
}

export enum CategoriesType {
  EXPENSES = "expenses",
  BONUSES = "bonuses",
  FINES = "fines",
  JOBS = "jobs",
  STORE_ITEMS = "store_items",
}
