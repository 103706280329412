import React, { useState } from "react"

import { Typography } from "@mui/material"
import { TextFieldErrorStyles } from "pages/jobs/addSuggestedJobs/TextFieldError.styles"
import { useTranslation } from "react-i18next"

import { OptionType, UpdateItemType } from "../../DialogAddSuggested.types"
import { fillTextOnInut } from "../BodyTableSugested.config"

interface IProps {
  getIsChecked: (arg: OptionType) => boolean
  title: string
  amount: number
  updateDate: (e: React.ChangeEvent, item: number | undefined) => void
  id: number
  toggleItem: (arg: OptionType) => void
  updateToggleItem: (arg: UpdateItemType) => void
}

const UpdateTitle = ({
  title,
  getIsChecked,
  amount,
  updateDate,
  id,
  toggleItem,
  updateToggleItem,
}: IProps) => {
  const { t } = useTranslation()
  const [isEditTitle, setIsEditTitle] = useState(false)
  const [inputName, setInputName] = useState(title)

  return (
    <>
      {!isEditTitle ? (
        <Typography
          sx={{ cursor: "pointer" }}
          variant="body2"
          fontWeight={500}
          onClick={() => {
            setIsEditTitle(true)
            if (!getIsChecked({ title, id, amount })) {
              toggleItem({ id, title, amount })
            }
          }}
        >
          {fillTextOnInut(t(title), t("students.clickToFillOut"))}
        </Typography>
      ) : (
        <TextFieldErrorStyles
          multiline
          name="title"
          sx={{
            width: "100%",
            overflowWrap: "anywhere",
          }}
          defaultValue={t(title)}
          onBlur={(e) => {
            setIsEditTitle(false)
            updateDate(e, id)
            updateToggleItem({ id, title: inputName.trim() })
          }}
          onChange={(e) => setInputName(e.target.value)}
          inputRef={(input) => input?.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          inputProps={{
            sx: {
              padding: "5px 10px",
            },
          }}
        />
      )}
    </>
  )
}

export default UpdateTitle
