import { ReactComponent as IconCoin } from "@brand_assets/svg/coin.svg"
import { Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import { useIsBreakpointUp } from "hooks/breakpoint"

import { TileTypography } from "../Tile.styles"

export interface IProps {
  title: string
  memo: number
  amount: number | string
}

const ContentTile = ({ title, memo, amount }: IProps) => {
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <>
      {isTablet ? (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack gap="4px">
            <Typography
              variant="body2"
              fontWeight={500}
              color="mockup.neutral10"
            >
              {title}
            </Typography>
            {typeof amount === "number" ? (
              <Coin size="small" amount={`${amount}`} />
            ) : (
              <Stack direction="row" alignItems="center" columnGap="4px">
                <IconCoin
                  style={
                    process.env.REACT_APP_TYPE === "classequity"
                      ? { maxWidth: "16px", minHeight: "16px" }
                      : { maxWidth: "22px", minHeight: "22px" }
                  }
                />
                <Typography variant="body2" color="mockup.neutral10">
                  {amount}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="row">
            {!!memo ? (
              <TileTypography variant="body3">{`x${memo}`}</TileTypography>
            ) : (
              <Typography sx={{ minWidth: "30px" }}></Typography>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" width="100%">
          <Stack gap="4px" width="100%">
            <Typography
              variant="body2"
              fontWeight={500}
              color="mockup.neutral10"
              sx={{ width: "100%" }}
            >
              {title}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              {typeof amount === "number" ? (
                <Coin size="small" amount={`${amount}`} isEllipsis />
              ) : (
                <Stack direction="row" alignItems="center" columnGap="4px">
                  <IconCoin
                    style={
                      process.env.REACT_APP_TYPE === "classequity"
                        ? { maxWidth: "16px", minHeight: "16px" }
                        : { maxWidth: "22px", minHeight: "22px" }
                    }
                  />
                  <Typography variant="body2" color="mockup.neutral10">
                    {amount}
                  </Typography>
                </Stack>
              )}
              {!!memo && (
                <TileTypography
                  sx={{ minWidth: "28px", height: "28px" }}
                  variant="body3"
                >{`x${memo}`}</TileTypography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default ContentTile
