import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IHrJobApplicationsArgs,
  IJobApplicationsArgs,
  IResponseJobApplications,
} from "./jobApplications.types"

export const useQueryJobApplications = (args?: IJobApplicationsArgs) => {
  useQuery<AxiosResponse<IResponseJobApplications>, AxiosError>(
    ["getJobApplication", args?.job_application_id],
    async () =>
      axiosInstance(`${Endpoint.JOB_APPLICATIONS}/${args?.job_application_id}`),
    {
      ...args?.options,
    }
  )
}

export const useQueryHrJobApplications = (args?: IHrJobApplicationsArgs) => {
  useQuery<AxiosResponse<IResponseJobApplications>, AxiosError>(
    ["getHrJobApplication", args?.job_application_id],
    async () =>
      axiosInstance(
        `${Endpoint.CLASS_ID_HELPER_HR_JOB_APPLICATIONS_ID.replace(
          "CLASSID",
          `${args?.class_id}`
        ).replace("JOBAPPLICATIONID", `${args?.job_application_id}`)}`
      ),
    {
      ...args?.options,
    }
  )
}
