import { IChangePassword } from "../pages/password/changePassword/ChangePassword.types"
import axiosInstance from "./api"
import { Endpoint } from "./enpoints.types"

export const getSchool = async (filter: string) => {
  return await axiosInstance.get(Endpoint.SCHOOLS, {
    params: {
      filter,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export const getUserInfo = async () => {
  return await axiosInstance.get(Endpoint.USER_ME)
}

export const resetPassword = async (data: IChangePassword) => {
  return await axiosInstance.post(Endpoint.TEACHER_PASSWORD_RESET, data)
}

export const googleLogin = async (token: string) => {
  return await axiosInstance.post(Endpoint.TEACHER_LOGIN_GOOGLE, undefined, {
    params: {
      token,
    },
  })
}
