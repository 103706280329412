import { Box, styled } from "@mui/material"

interface IAsideNavProps {
  isOpened: boolean
}

export const AsideNavContainer = styled(Box)<IAsideNavProps>(
  ({ theme, isOpened }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    height: "100vh",
    minWidth: "269px",
    padding: "16px 24px",
    top: scrollY,
    position: "fixed",
    zIndex: theme.zIndex.drawer,
    transform: isOpened ? "translateX(0)" : "translateX(-100%)",
    transition: "transform 0.3s",

    [theme.breakpoints.up("desktop")]: {
      position: isOpened ? "sticky" : "fixed",
      top: 0,
      flexBasis: "260px",
      flexShrink: "0",
      transform: isOpened ? "translateX(0)" : "translateX(-100%)",
    },
  })
)
