import React from "react"

import { Stack, Grid, Divider, Typography, Box } from "@mui/material"
import { useQueryStudentGoals } from "api/reactQuery/queries/studentGoals"
import { useParams } from "react-router"

import StudentSkeleton from "../../../common/skeleton/studentProfile"
import Container from "./container"

const Goals = () => {
  const params = useParams()

  const { data: studentGoalsData, isLoading } = useQueryStudentGoals({
    student_id: Number(params.studentId),
  })

  if (isLoading) {
    return (
      <Box mt={2}>
        <StudentSkeleton height="100px" />
      </Box>
    )
  }

  if (!studentGoalsData?.data.length) {
    return (
      <Stack my={3}>
        <Typography variant="subtitle1">
          The student has no set goals.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack py={2}>
      <Grid container spacing={2}>
        {studentGoalsData?.data
          .filter((item) => !item.completed_at)
          .map((student) => (
            <Container key={student.id} student={student} />
          ))}
      </Grid>
      <Divider orientation="horizontal" sx={{ my: 4 }} />
      <Grid container spacing={2}>
        {studentGoalsData?.data
          .filter((item) => !!item.completed_at)
          .map((student) => (
            <Container key={student.id} student={student} />
          ))}
      </Grid>
    </Stack>
  )
}

export default Goals
