import React from "react"

import TextField from "components/form/common/field/textField"
import { useFormContext } from "react-hook-form"

interface IProps {
  name: string
  setInviteCode: React.Dispatch<
    React.SetStateAction<{
      no1: string
      no2: string
      no3: string
      no4: string
      no5: string
      no6: string
    }>
  >
}

const ClassCode = ({ name, setInviteCode }: IProps) => {
  const methods = useFormContext()

  const handleGetNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value
    const name = e.target.name

    setInviteCode((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (value.length === 1 && name === "no1") {
      methods.setFocus("no2")
      methods.resetField("no2")
    }
    if (value.length === 1 && name === "no2") {
      methods.setFocus("no3")
      methods.resetField("no3")
    }
    if (value.length === 1 && name === "no3") {
      methods.setFocus("no4")
      methods.resetField("no4")
    }
    if (value.length === 1 && name === "no4") {
      methods.setFocus("no5")
      methods.resetField("no5")
    }
    if (value.length === 1 && name === "no5") {
      methods.setFocus("no6")
      methods.resetField("no6")
    }
  }
  return (
    <TextField
      inputProps={{
        maxLength: 1,
        style: { textAlign: "center" },
      }}
      name={name}
      customOnChange={(e, onChange) => {
        onChange(e)
        handleGetNumber(e)
      }}
    />
  )
}

export default ClassCode
