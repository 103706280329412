import { IStudentStoreItem } from "ts/interfaces/Store"

import { Cart } from "./StoreStudent.types"

const onAddToCart = (item: IStudentStoreItem, cart: Cart) => {
  if (item.id in cart) {
    return {
      ...cart,
      [item.id]: {
        item: cart[item.id].item,
        quantity: cart[item.id].quantity + 1,
      },
    }
  }
  return {
    ...cart,
    [item.id]: {
      item,
      quantity: 1,
    },
  }
}

const onRemoveFromCart = (item: IStudentStoreItem, cart: Cart) => {
  if (!(item.id in cart)) return cart

  const cartItem = cart[item.id]

  if (cartItem.quantity === 1) {
    const cartTemp = { ...cart }
    delete cartTemp[item.id]
    return cartTemp
  }

  return {
    ...cart,
    [item.id]: {
      item: cart[item.id].item,
      quantity: cart[item.id].quantity - 1,
    },
  }
}

export const getNewCart = (
  item: IStudentStoreItem,
  cart: Cart,
  action: "add" | "delete"
) => {
  if (action === "add") return onAddToCart(item, cart)
  return onRemoveFromCart(item, cart)
}
