import { FC } from "react"

import { Grid } from "@mui/material"
import TextField from "components/form/common/field/textField"
import { useTranslation } from "react-i18next"

import FabDeleteItem from "../fabDeleteItem"
import FieldsWrapper from "../fieldsWrapper"

interface IProps {
  index: number
  onDeleteClick: () => void
}

const NewStudentFields: FC<IProps> = ({ index, onDeleteClick }) => {
  const { t } = useTranslation()

  return (
    <FieldsWrapper>
      <Grid container rowSpacing="24px" columnSpacing="8px">
        <Grid item mobile={12} tablet={3}>
          <TextField
            name={`students.${index}.firstName`}
            label={t("students.firstName")}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextField
            name={`students.${index}.lastName`}
            label={t("students.lastName")}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextField
            name={`students.${index}.userName`}
            label={t("students.userName")}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextField
            name={`students.${index}.password`}
            label={t("students.password")}
          />
        </Grid>
      </Grid>
      <FabDeleteItem onClick={onDeleteClick} />
    </FieldsWrapper>
  )
}

export default NewStudentFields
