import { ReactComponent as IconPhoto } from "@common_assets/svg/photoStudentSite.svg"
import { Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

const noStudent = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Stack
      height="326px"
      width="100%"
      alignItems="center"
      justifyContent="center"
      margin="0 auto"
      textAlign="center"
    >
      <IconPhoto />
      <Typography
        sx={{ mt: 4, mb: 3, width: "225px" }}
        color="mockup.darkBlue"
        variant="subtitle1"
      >
        {t("jobs.youDoNotHaveAnyStudentsYet")}
      </Typography>
      <Button
        onClick={() =>
          navigate(
            RoutePath.CLASS.replace(":classId", `${Number(params.classId)}`)
          )
        }
      >
        {t("jobs.addStudents")}
      </Button>
    </Stack>
  )
}

export default noStudent
