import { Dispatch, FC, MouseEventHandler, useEffect } from "react"

import { useQuill } from "react-quilljs"

import { parseQuillContent } from "./ParserHtml"

import "quill/dist/quill.bubble.css"
import "./style.css"

interface IProps {
  state: string
  setState?: Dispatch<React.SetStateAction<string>>
  placeholder?: string
  readOnly?: boolean
  id?: number
  onBlur?: () => void
  style?: React.CSSProperties
  icon?: React.ReactElement
  crossProps?: React.CSSProperties
  checkProps?: React.CSSProperties
  cross?: React.ReactNode
  check?: React.ReactNode
  suggestedEditable?: boolean
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  name?: string
  onClick?: () => void
  onMouseDown?: MouseEventHandler
  addjob?: boolean
}

const RichEditor: FC<IProps> = ({
  setState,
  state,
  readOnly = false,
  placeholder,
  id,
  onBlur,
  style,
  cross,
  check,
  crossProps,
  checkProps,
  suggestedEditable,
  onMouseEnter,
  onMouseLeave,
  name,
  onClick,
  onMouseDown,
  addjob,
  ...props
}) => {
  const theme = "bubble"
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ color: [] }, { background: [] }],
    ],
  }

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "link",
    "color",
    "background",
  ]

  const inputPlaceholders = document.querySelectorAll(
    "input[data-link]"
  ) as NodeListOf<HTMLInputElement>

  const cleanState = state.replace("<p><br></p>", "")

  useEffect(() => {
    if (inputPlaceholders) {
      for (const link of inputPlaceholders) {
        link.dataset.link =
          "https://www.classequity.com - to save link hit 'Enter'"
      }
    }
  }, [inputPlaceholders])

  const { quillRef, quill } = useQuill({
    theme,
    modules,
    formats,
    readOnly: readOnly as boolean,
  })

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        if (setState) {
          setState(quill.root.innerHTML)
        }
      })
      quill.root.setAttribute("name", name as string)
      !addjob && quill.focus()
      !addjob &&
        window.requestAnimationFrame(() => {
          const length = quill.getLength()
          quill.setSelection(length, 0)
        })
    }
  }, [quill])

  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = parseQuillContent(state)
    }
  }, [quill, id])

  return (
    <>
      {!readOnly ? (
        <div
          className={`main ${
            !!cleanState?.length && addjob && "active-label"
          } ${addjob && "active-focus-label"}`}
          style={style}
          onBlur={onBlur}
          onMouseDown={onMouseDown}
          {...props}
        >
          {suggestedEditable && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
              }}
            >
              <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{ cursor: "pointer", ...checkProps }}
              >
                {check}
              </div>
              <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{ cursor: "pointer", ...crossProps }}
              >
                {cross}
              </div>
            </div>
          )}
          <div className="form-input" ref={quillRef} />
          <span className="form-input-label">{placeholder}</span>
        </div>
      ) : (
        <div className="mainRead" onClick={onClick} style={style}>
          <div ref={quillRef} />
        </div>
      )}
    </>
  )
}

export default RichEditor
