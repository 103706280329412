import i18n from "i18n"

import { IPaycheck } from "../Transactions.types"

export const paychecksData: IPaycheck[] = [
  {
    id: 1,
    amount: i18n.t("transaction.dependsOnHiredJob"),
    title: i18n.t("transaction.sendPaycheckForHiredJobs"),
  },
]
