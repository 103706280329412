import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IInvitationsProPlanArgs,
  IResponseInvitationsProPlan,
} from "./invitations.types"

export const useQueryInvitationsProPlan = (args?: IInvitationsProPlanArgs) =>
  useQuery<AxiosResponse<IResponseInvitationsProPlan[]>, AxiosError>(
    ["invitationsProPlan"],
    async () => {
      return await axiosInstance(Endpoint.SCHOOL_INVITATIONS)
    },
    args?.options
  )
