import { UserSchoolPlan } from "ts/enums/User"
import { IUserMe } from "ts/interfaces/User"

export const correctDisplayCSV = (data: string) => {
  return data
    .replace(/""/g, "-")
    .replace(/"/g, "")
    .replace(/^((?:\w+\s?){1,13}).*/, (x: string) => x.toUpperCase())
}

interface IPropsDataExport {
  user: IUserMe | null
  upgrade: () => void
  exportData: () => void
}

export const schoolPlanToExportData = ({
  user,
  upgrade,
  exportData,
}: IPropsDataExport) => {
  const plan = user?.user.school_plan

  if (plan === UserSchoolPlan.FREE || plan === UserSchoolPlan.DEMO) {
    upgrade()
  } else {
    exportData()
  }
}

export const hiddenExport = (user: IUserMe | null) => {
  return !(
    user &&
    process.env.REACT_APP_TYPE === "bankeeonline" &&
    user?.user.school_plan === UserSchoolPlan.FREE
  )
}
