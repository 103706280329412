import { useLayoutEffect } from "react"

import { setLogin } from "store/auth/auth.slice"

import { useAppDispatch } from "../store"

export const useDirectLink = () => {
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    if (window.location.pathname === "/sign-up") {
      dispatch(setLogin(false))
    } else {
      dispatch(setLogin(true))
    }
  }, [])
}
