import { FC, useEffect } from "react"

import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material"
import TextField from "components/form/common/field/textField"
import mixpanel from "mixpanel-browser"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"
import { getFieldName } from "utils/field"

import Summary from "../accordion/summary"
import Tile from "../accordion/tile"
import { IPaycheck, ISectionProps } from "../Transactions.types"
import { paychecksData } from "./Paychecks.config"

const Paychecks: FC<ISectionProps> = ({
  formRoot,
  classId,
  defaultExpanded,
  studentId,
}) => {
  const { t } = useTranslation()

  const { getValues, setValue } = useFormContext()

  const paychecksFieldName = getFieldName(formRoot, "paychecks")

  const paychecksWatcher: IPaycheck[] = useWatch({
    name: paychecksFieldName,
    exact: true,
  })

  useEffect(() => {
    if (classId) {
      setValue(paychecksFieldName, [])
    }
  }, [classId])

  const onTileClick = (item: IPaycheck) => {
    if (paychecksWatcher.find((paycheck) => paycheck.id === item.id)) {
      setValue(paychecksFieldName, [
        ...paychecksWatcher.filter((paycheck) => paycheck.id !== item.id),
      ])
    } else {
      setValue(paychecksFieldName, [...paychecksWatcher, item])
    }
  }

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <Summary
        classId={classId}
        title={t("transaction.paychecks")}
        selectedItemsAmount={paychecksWatcher.length}
        autopay
      />
      <AccordionDetails>
        {!!studentId && !classId ? (
          <Typography
            variant="subtitle1"
            textAlign="center"
            py="44px"
            color="mockup.darkBlue"
          >
            {t(
              "transaction.youDonyHavePermissionToTheListYouCanOnlySendTransactionsByCustomInput"
            )}
          </Typography>
        ) : (
          <Grid container rowSpacing="16px" columnSpacing="12px" pb={2}>
            {paychecksData.map((tile) => (
              <Grid
                key={tile.id}
                item
                mobile={6}
                desktop={4}
                onClick={() => {
                  onTileClick(tile)
                  if (formRoot === "transactions") {
                    if (getValues("transactions.paychecks").length === 1) {
                      mixpanel.track("send_transaction", {
                        event_name: "pre-set_paychecks",
                      })
                      return
                    }
                  }

                  if (getValues("paychecks").length === 1) {
                    mixpanel.track("send_transaction", {
                      event_name: "pre-set_paychecks",
                    })
                  }
                }}
              >
                <Tile
                  title={tile.title}
                  amount={tile.amount}
                  variant="salary"
                  selected={
                    !!paychecksWatcher.find((item) => item.id === tile.id)
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
        {(!!studentId || !!classId) && (
          <>
            <Typography variant="body3" color="mockup.neutral40">
              {t("transaction.other")}
            </Typography>
            <PrimaryTile
              mt={2}
              p="24px 16px 32px"
              sx={{ borderRadius: "16px" }}
            >
              <Grid container columnSpacing="16px">
                <Grid item desktop={8} tablet={6} mobile={12}>
                  <TextField
                    onFocus={(e) => {
                      if (!e.target.value.length) {
                        mixpanel.track("send_transaction", {
                          event_name: "form_paychecks",
                        })
                      }
                    }}
                    label={t("transaction.paycheckTitle")}
                    size="small"
                    name={getFieldName(formRoot, "paycheckTitle")}
                  />
                </Grid>
                <Grid item desktop={4} tablet={6} mobile={12}>
                  <TextField
                    label={t("transaction.value")}
                    size="small"
                    name={getFieldName(formRoot, "paycheckValue")}
                  />
                </Grid>
              </Grid>
            </PrimaryTile>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Paychecks
