import { FC } from "react"

import Dialog from "components/common/dialog/dialog"
import { DialogMaxWidth } from "components/common/dialog/dialog/Dialog"
import { useTranslation } from "react-i18next"

interface IProps {
  open: boolean
  onClose: () => void
  form: string
  desktopWidth?: DialogMaxWidth
}

const DialogAddTeacher: FC<IProps> = ({
  children,
  desktopWidth,
  form,
  onClose,
  open,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleText={t("coteachers.addCoTeacher")}
      actionAcceptButtonProps={{ form: form, type: "submit" }}
      desktopMaxWidth={desktopWidth}
    >
      {children}
    </Dialog>
  )
}

export default DialogAddTeacher
