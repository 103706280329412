import React, { Dispatch, useEffect } from "react"

import {
  Box,
  FormControlLabel,
  List,
  Switch,
  Typography,
  Checkbox,
  CircularProgress,
} from "@mui/material"
import { useDrag } from "react-dnd"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { ItemsTypes } from "utils/itemsTypes"

import { ICheckedPerson, IStudentsToChecked } from "../Jobs"
import MultipleBoardHire from "./multipleBoardHire"
import SingleStudent from "./SingleStudent"

interface IStudent {
  checked: boolean
  isCheckAll: boolean
  setIsCheckAll: Dispatch<React.SetStateAction<boolean>>
  setChecked: Dispatch<React.SetStateAction<boolean>>
  students: IObjectStudentDetailed[]
  checkedPerson: ICheckedPerson[]
  setCheckedPerson: Dispatch<React.SetStateAction<ICheckedPerson[]>>
  stateStudentsToChecked: {
    studentsToChecked: IStudentsToChecked[]
    setStudentsToChecked: Dispatch<React.SetStateAction<IStudentsToChecked[]>>
  }
  handleChangeInView: (value: boolean) => void
  isRefetching: boolean
}

const StudentBoard = ({
  setChecked,
  checked,
  students,
  checkedPerson,
  setCheckedPerson,
  stateStudentsToChecked,
  isCheckAll,
  setIsCheckAll,
  handleChangeInView,
  isRefetching,
}: IStudent) => {
  const { setStudentsToChecked } = stateStudentsToChecked
  const { ref, inView } = useInView()
  const { t } = useTranslation()

  const handleCheckedPerson = (elements: IObjectStudentDetailed) => {
    const currentPosition = checkedPerson.findIndex(
      (el) => el.id === elements.id
    )
    const newChecked = [...checkedPerson]

    if (currentPosition === -1) {
      newChecked.push({
        id: elements.id,
        first_name: elements.first_name,
        last_name: elements.last_name,
      })
    } else {
      newChecked.splice(currentPosition, 1)
    }
    setCheckedPerson(newChecked)
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemsTypes.STUDENT,
      item: () => checkedPerson,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [checkedPerson]
  )

  useEffect(() => {
    handleChangeInView(inView)
  }, [inView])

  const toggleAll = () => {
    setIsCheckAll(!isCheckAll)

    setStudentsToChecked((prevState) =>
      prevState.map((student) => ({ ...student, checked: true }))
    )
    setCheckedPerson(students)

    if (isCheckAll) {
      setStudentsToChecked((prevState) =>
        prevState.map((student) => ({ ...student, checked: false }))
      )
      setCheckedPerson([])
    }
  }

  return (
    <List
      sx={{
        width: "100%",
        mt: 4,
        textAlign: "center",
      }}
    >
      <FormControlLabel
        sx={{ mr: "0", mb: 1 }}
        control={
          <Switch
            checked={checked ?? false}
            onChange={() => setChecked(!checked)}
          />
        }
        label={
          <Typography
            variant="body2"
            fontWeight="500"
            sx={{ textAlign: "left", ml: 1.5 }}
          >
            {t("jobs.showStudentsWithoutJob")}
          </Typography>
        }
      />

      {!students.length ? (
        <Typography mt={2}>{t("jobs.everyoneHasJob")}</Typography>
      ) : (
        <Box sx={{ position: "relative" }}>
          {!!checkedPerson.length && students.length > 1 && (
            <MultipleBoardHire
              drag={drag}
              setStudentsToChecked={setStudentsToChecked}
              setCheckedPerson={setCheckedPerson}
              checkedPerson={checkedPerson}
              isDragging={isDragging}
              setIsCheckAll={setIsCheckAll}
            />
          )}
          {students.length > 1 && (
            <Box
              display="flex"
              alignItems="center"
              mt={!!checkedPerson.length ? "8px" : "0"}
            >
              <Checkbox
                checked={students.length === checkedPerson.length}
                onChange={toggleAll}
                indeterminate={
                  students.length !== checkedPerson.length &&
                  !!checkedPerson.length
                }
                sx={{ width: "24px", height: "24px" }}
              />
              <Typography variant="body2">{t("jobs.allStudents")}</Typography>
            </Box>
          )}
          {students.map((student) => {
            return (
              <SingleStudent
                key={student.id}
                student={student}
                handleCheckedPerson={handleCheckedPerson}
                checkedPerson={checkedPerson}
                stateStudentsToChecked={stateStudentsToChecked}
                students={students}
              />
            )
          })}
          <Box ref={ref} />
          <span>{isRefetching && <CircularProgress size="16px" />}</span>
        </Box>
      )}
    </List>
  )
}

export default StudentBoard
