import { IAuthData } from "ts/interfaces/Auth"

const AUTH_DATA_KEY = "authData"

export const getAuthDataLocalStorage = (): IAuthData | null => {
  const authData = localStorage.getItem(AUTH_DATA_KEY)
  if (!authData) return null
  return JSON.parse(authData) as IAuthData
}

export const setAuthDataLocalStorage = (authData: IAuthData | null) => {
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData))
}

export const clearAuthDataLocalStorage = () => {
  localStorage.removeItem(AUTH_DATA_KEY)
}
