import { FC, memo, ReactNode } from "react"

import {
  Avatar,
  Checkbox,
  CheckboxProps,
  Tooltip,
  Typography,
} from "@mui/material"
import Coin from "components/common/icon/coin"
import { Cell, Row } from "components/common/table/basicTable"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

interface IProps {
  student: IObjectStudentDetailed
  getCheckboxProps: (el: IObjectStudentDetailed) => CheckboxProps
  actionButtonsSlot: ReactNode
  savingsAccountOption?: boolean
}

const TableRow: FC<IProps> = ({
  student,
  getCheckboxProps,
  actionButtonsSlot,
  savingsAccountOption,
}) => {
  const isDesktop = useIsBreakpointUp("desktop")
  const { t } = useTranslation()
  const switchState = useAppSelector(selectDisplaySavings)

  return (
    <Row>
      <Cell px="8px">
        <Checkbox {...getCheckboxProps(student)} />
      </Cell>
      <Cell py={{ tablet: "12px", desktop: "4px" }} gap="8px">
        {isDesktop && (
          <Avatar
            src={student.avatar_url}
            alt={`${student.first_name} ${student.last_name}`}
          />
        )}
        <Typography
          variant="body1"
          color="mockup.neutral10"
          sx={{ wordBreak: "break-all" }}
        >
          {`${student.first_name} ${student.last_name}`}
        </Typography>
      </Cell>
      <Cell gap="8px" position="relative">
        <Tooltip title={student.jobs.join(", ")}>
          <Typography
            variant="body2"
            fontWeight={500}
            color="mockup.neutral10"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            position="absolute"
            width="calc(100% - 32px)"
          >
            {student.jobs.length
              ? student.jobs.join(", ")
              : t("students.noJobs")}
          </Typography>
        </Tooltip>
      </Cell>
      <Cell>
        <Coin amount={`${student.balance}`} />
      </Cell>
      <Cell>
        <Coin amount={`${student.pending_balance}`} />
      </Cell>
      {savingsAccountOption && switchState && (
        <>
          <Cell>
            <Coin amount={`${student.booked_balance}`} />
          </Cell>
          <Cell>
            <Coin amount={`${student.savings}`} />
          </Cell>
        </>
      )}
      <Cell>{actionButtonsSlot}</Cell>
    </Row>
  )
}

export default memo(TableRow)
