import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  isMenuOpened: false,
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setIsMenuOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isMenuOpened = payload
    },
    toggleIsMenuOpened: (state) => {
      state.isMenuOpened = !state.isMenuOpened
      localStorage.setItem("isMenuOpened", state.isMenuOpened.toString())
    },
  },
})

export const { setIsMenuOpened, toggleIsMenuOpened } = menuSlice.actions

export default menuSlice.reducer
