export enum UserRole {
  TEACHER = "teacher",
  STUDENT = "student",
}

export enum UserSchoolPlan {
  FREE = "free",
  PRO = "pro",
  SCHOOL = "school",
  DEMO = "demo",
}

export enum Subscription_Status {
  ACTIVE = "active",
  PAST_DUE = "past_due",
  UNPAID = "unpaid",
  CANCELLED = "cancelled",
}
