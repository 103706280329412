import * as yup from "yup"

export const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/
const patternWithoutZero = /^[^0].*/
const patternWithCommasAndDots = /^-?\d+(,\d{3})*(\.\d{1,2})?$/
const zeroPattern = /^(?!.*-\..*)[^.].*/
const patternOnlyNumbers = /^[-\d.,]+$/

// NUMBERS //

export const numberSchema = yup
  .number()
  .max(20000000)
  .nullable()
  .transform((value, originalValue) =>
    `${originalValue}`.trim() === "" ? null : value
  )
  .typeError("validations.valueMustBeNumber")

export const numberWithDotOrComma = yup
  .number()
  .nullable()
  .transform((_value, originalValue) => {
    if (typeof originalValue !== "number") {
      return Number(originalValue.replace(/,/, "."))
    }
    return originalValue
  })
  .typeError("validations.valueMustBeNumber")

export const requiredNumberSchemaCommasAndDots = yup
  .mixed()
  .test("required", "This place should not be empty", (val: string) => {
    if (val !== undefined) {
      return !!val.length
    }
    return true
  })
  .test(
    "allow numbers or minus",
    "You can only put positive or negative numbers",
    (val: string) => {
      if (val !== undefined) {
        return patternOnlyNumbers.test(val)
      }
      return true
    }
  )
  .test(
    "0 include in decimals number",
    "Don’t forget to include a digit in the dollar place value position!",
    (val: string) => {
      if (val !== undefined) {
        return zeroPattern.test(val)
      }
      return true
    }
  )
  .test(
    "bad place of comma",
    "Please double check your decimal for typos!",
    (val: string) => {
      if (val !== undefined) {
        return patternWithCommasAndDots.test(val)
      }
      return true
    }
  )

export const requiredNumberSchema = yup
  .number()
  .required()
  .max(20000000)
  .typeError("validations.valueMustBeNumber")

export const requiredHundredthNumberSchema = yup
  .mixed()
  .transform((_value, originalValue) => {
    if (typeof originalValue !== "number") {
      return Number(originalValue.replace(/,/, "."))
    }
  })
  .test("min", "validations.minNumberIs", (val) => {
    if (val !== undefined) return patternWithoutZero.test(val)
    return true
  })

  .test("max", "validations.maxNumberIs", (val) => {
    if (val !== undefined) return val <= 100000
    return true
  })
  .test(
    "isHundredth",
    "The salary should be a decimal with maximum two digits after comma",
    (val: string | undefined) => {
      if (val !== undefined) {
        return patternTwoDigisAfterComma.test(val.toString())
      }
      return true
    }
  )

// STRINGS //

export const stringSchema = yup.string().max(200).trim()
export const noLimitedStringSchema = yup.string().trim()

export const requiredStringSchema = stringSchema.required()

// use it when .min() is specified and the field is not required
export const nullableStringSchema = stringSchema
  .nullable()
  .transform((value, originalValue) =>
    `${originalValue}`.trim() === "" ? null : value
  )

export const emailSchema = stringSchema.email()

export const requiredEmailSchema = stringSchema.email().required()

// DATES //

export const dateSchema = yup
  .date()
  .typeError("validations.enterValidDate")
  .nullable()

export const requiredDateSchema = dateSchema.required()

// OBJECTS //

export const requiredObject = yup
  .object()
  .typeError("validations.fieldIsRequired")

// PASSWORD //
// At least 7 characters include [ 1 number, 1 uppercase letter, 1 lowercase letter, one special character]

export const passwordSchema = yup
  .string()
  .matches(/^.*(?=.{7,}).*$/, "Enter at least 7 characters")
  .matches(/^.*(?=.*\d).*$/, "Enter at least 1 number")
  .matches(/^.*((?=.*[a-z]){1}).*$/, "Enter at least 1 lowercase character")
  .matches(/^.*((?=.*[A-Z]){1}).*$/, "Enter at least 1 uppercase character")
  .matches(
    /^.*((?=.*?[#?!@$%^&*-]){1}).*$/,
    "Enter at least 1 special character"
  )
  .trim()
  .required()

export const passwordStudentSchema = yup.string().min(6).trim().required()

// CHECKBOX ACCEPTED //

export const termsSchema = yup
  .bool()
  .oneOf([true], "validation.checkTerms")
  .required()

// SELECT //

export const selectSchema = yup.object().required().nullable()

// MIXED //

export const quantityItemsSchema = yup
  .mixed()
  .nullable()
  .test(
    "zero-null-positive",
    "The quantity must be a positive number or empty place",
    (value) => {
      if (value === null) {
        return true
      }
      if (Number(value) === 0) {
        return true
      }
      if (typeof Number(value) === "number" && value > 0) {
        return true
      }
      return false
    }
  )
