import { FC } from "react"

import { RadioGroup, RadioGroupProps } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

interface IProps extends Omit<RadioGroupProps, "name"> {
  name: string
}

const RadioFieldGroup: FC<IProps> = ({ children, name, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <RadioGroup {...props} {...field}>
          {children}
        </RadioGroup>
      )}
      name={name}
      control={control}
    />
  )
}

export default RadioFieldGroup
