import { requiredStringSchema, stringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  name: "",
  district: "",
  city: "",
  state: "",
  timezone: "",
  country_id: "",
  zip_code: "",
}

export const validationSchema = yup.object({
  name: requiredStringSchema.min(2),
  district: requiredStringSchema.min(2),
  city: requiredStringSchema,
  state: requiredStringSchema,
  country_id: requiredStringSchema,
  timezone: stringSchema.when("country_id", {
    is: (country_id: string) => !!country_id,
    then: (schema) => schema.required(),
  }),
  zip_code: stringSchema.when("country_id", {
    is: (country_id: string) => country_id === "1",
    then: (schema) => {
      return schema
        .matches(/^\d{5}$/, "Invalid format: 5 digits")
        .trim()
        .required()
    },
    otherwise: (schema) =>
      schema.when("country_id", {
        is: (country_id: string) => country_id === "2",
        then: (schema) => schema.required(),
      }),
  }),
})

export const validationSchemaSelected = yup.object({
  name: requiredStringSchema.min(2),
  district: requiredStringSchema.min(2),
  city: requiredStringSchema,
  state: requiredStringSchema,
  timezone: requiredStringSchema,
  country_id: requiredStringSchema,
  zip_code: requiredStringSchema,
})
