interface IProps {
  response: Blob
  classname: string
  exportData: string
}

export const useBlobData = ({ response, classname, exportData }: IProps) => {
  const href = URL.createObjectURL(response as Blob)
  const link = document.createElement("a")
  link.href = href
  link.setAttribute("download", `${classname} - ${exportData}.xlsx`)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
