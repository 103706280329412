import muiAccordion from "./accordion/muiAccordion"
import muiAppBar from "./appBar/muiAppBar"
import muiAvatar from "./avatar/muiAvatar"
import muiButton from "./button/muiButton"
import muiCheckBox from "./checkbox/muiCheckBox"
import muiDialog from "./dialog/muiDialog"
import muiFab from "./fab/muiFab"
import muiInput from "./input/muiInput"
import muiPopover from "./popover/muiPopover"
import muiRadio from "./radio/muiRadio"
import muiSkeleton from "./skeleton/muiSkeleton"
import muiSwitch from "./switch/muiSwitch"
import muiTab from "./tab/muiTab"
import muiTable from "./table/muiTable"
import muiTooltip from "./tooltip/muiTooltip"
import muiUseMediaQuery from "./useMediaQuery/muiUseMediaQuery"

export default {
  ...muiButton,
  ...muiFab,
  ...muiInput,
  ...muiCheckBox,
  ...muiSwitch,
  ...muiRadio,
  ...muiAppBar,
  ...muiUseMediaQuery,
  ...muiAvatar,
  ...muiTab,
  ...muiTable,
  ...muiTooltip,
  ...muiSkeleton,
  ...muiPopover,
  ...muiDialog,
  ...muiAccordion,
}
