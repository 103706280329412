import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import {
  selectActivePageLogin,
  selectActivePageSignUp,
} from "store/auth/auth.selectors"

interface IProps<T> {
  Component: React.ComponentType<T>
  registerType: "signUp" | "login"
}

function withRegisterLayout<T>({ Component, registerType }: IProps<T>) {
  function newComponent(
    props: Omit<T, "desktopView" | "activePage" | "registerType">
  ) {
    const desktopView = useIsBreakpointUp("desktop")
    const signUpActivePage = useAppSelector(selectActivePageSignUp)
    const loginActivePage = useAppSelector(selectActivePageLogin)

    return (
      <Component
        {...(props as T)}
        desktopView={desktopView}
        activePage={
          registerType === "signUp" ? signUpActivePage : loginActivePage
        }
        registerType={registerType}
      />
    )
  }
  return newComponent
}

export default withRegisterLayout
