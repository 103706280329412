import { FC } from "react"

import { Box, Stack } from "@mui/material"
import { useAppSelector } from "hooks/store"
import AsideNav from "layout/asideNav"
import Header from "layout/header"
import { Outlet } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { checkSubscription } from "utils/api"

import { ContentBox } from "./BaseLayout.styles"

const BaseLayout: FC = () => {
  const userInfo = useAppSelector(selectUser)

  return (
    <Box
      sx={{
        filter:
          !checkSubscription(userInfo) && userInfo !== null
            ? "blur(5px)"
            : "blur(0px)",
        pointerEvents: checkSubscription(userInfo) ? "all" : "none",
      }}
    >
      <Box display="flex">
        <AsideNav />
        <Stack flexGrow="1" minHeight="100vh" minWidth={0}>
          <Header />
          <ContentBox component="main">
            <Outlet />
          </ContentBox>
        </Stack>
      </Box>
    </Box>
  )
}

export default BaseLayout
