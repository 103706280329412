import { FC } from "react"

import { ReactComponent as IconEdit } from "@common_assets/svg/edit.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import FabIconButton from "components/common/button/fabIconButton"
import FabGroup from "components/common/container/fabGroup"
import { useTranslation } from "react-i18next"
import { TransactionTypeShort } from "ts/types/Transaction"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"

interface IProps {
  variant: TransactionTypeShort
  handleOpenEdit: () => void
  handleOpenDelete: () => void
}

const ActionButtons: FC<IProps> = ({
  variant,
  handleOpenEdit,
  handleOpenDelete,
}) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  return (
    <FabGroup>
      <FabIconButton
        onClick={handleOpenEdit}
        Icon={<IconEdit />}
        tooltipText={t(tKey.editItem)}
      />
      <FabIconButton
        onClick={handleOpenDelete}
        Icon={<IconTrash />}
        tooltipText={t(tKey.deleteItem)}
      />
    </FabGroup>
  )
}

export default ActionButtons
