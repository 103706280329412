import React, { ChangeEvent, useState } from "react"

import { Checkbox } from "@mui/material"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useBeforeunload } from "react-beforeunload"

import {
  OptionType,
  UpdateAmountType,
  UpdateItemType,
} from "../DialogAddSuggested.types"
import UpdateAmount from "./updateAmount"
import UpdateTitle from "./updateTitle"

interface IProps {
  title: string
  amount: number
  id: number
  getIsChecked: (arg: OptionType) => boolean
  toggleItem: (arg: OptionType) => void
  updateToggleItem: (el: UpdateItemType) => void
  updateToggleAmount: (el: UpdateAmountType) => void
  filterOptions: OptionType[]
  setFilterOptions: React.Dispatch<React.SetStateAction<OptionType[]>>
  handleOpen: () => void
}

const BodyTableSuggested = ({
  title,
  amount,
  getIsChecked,
  toggleItem,
  filterOptions,
  setFilterOptions,
  updateToggleItem,
  updateToggleAmount,
  handleOpen,
  id,
}: IProps) => {
  const [isEditAmount, setIsEditAmount] = useState(false)

  const updateDate = (e: ChangeEvent, id: number | undefined) => {
    const event = e.target as HTMLInputElement
    const newState = filterOptions.map((element) => {
      if (element.id === id && event.value === "" && event.name === "title") {
        return { ...element, title: "" }
      }

      if (element.id === id && event.value === "" && event.name === "amount") {
        return { ...element, amount: 0 }
      }

      if (element.id === id) {
        return { ...element, [event.name]: event.value }
      } else {
        return element
      }
    })

    setFilterOptions(newState)
  }

  useBeforeunload((event) => {
    event.preventDefault()
  })

  window.onpopstate = (e) => {
    e.stopImmediatePropagation()
    history.go(1)
    handleOpen()
  }

  return (
    <BasicTable.Row key={title} sx={{ height: "100%" }}>
      <BasicTable.Cell px="8px">
        <Checkbox
          checked={getIsChecked({ id, title, amount })}
          onChange={() => {
            toggleItem({ id, title, amount })
          }}
        />
      </BasicTable.Cell>
      <BasicTable.Cell style={{ position: "relative" }}>
        <UpdateTitle
          id={id}
          updateDate={updateDate}
          getIsChecked={getIsChecked}
          title={title}
          amount={amount}
          toggleItem={toggleItem}
          updateToggleItem={updateToggleItem}
        />
      </BasicTable.Cell>
      <BasicTable.Cell
        sx={{
          position: "relative",
          px: isEditAmount ? 0 : "16px",
          height: "24px",
        }}
      >
        <UpdateAmount
          isEditAmount={isEditAmount}
          amount={amount}
          title={title}
          id={id}
          getIsChecked={getIsChecked}
          setIsEditAmount={setIsEditAmount}
          updateDate={updateDate}
          toggleItem={toggleItem}
          updateToggleAmount={updateToggleAmount}
        />
      </BasicTable.Cell>
    </BasicTable.Row>
  )
}

export default BodyTableSuggested
