import React from "react"

import { ReactComponent as IconX } from "@common_assets/svg/cross.svg"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { HrResponse } from "api/reactQuery/queries/hr.types"
import { useDrag } from "react-dnd"
import { useTranslation } from "react-i18next"

import { DraggingBoxStyle } from "../draggableUser/DraggableUser.style"

interface IBunchUsers {
  checkedUsers: HrResponse[]
  onClickX: () => void
}

const BunchUsers = ({ checkedUsers, onClickX }: IBunchUsers) => {
  const { t } = useTranslation()
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "bunch",
      item: { user: checkedUsers },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [checkedUsers]
  )

  return (
    <DraggingBoxStyle
      ref={drag}
      isdragging={isDragging}
      isbunch={true}
      sx={{ p: 2 }}
    >
      <Stack>
        <Typography
          textAlign="start"
          fontWeight="500"
          variant="body2"
          component="h3"
        >
          {t("jobs.hireMultipleStudentsAtOnce")}:
          <Divider sx={{ width: "100%", my: "10px" }} />
        </Typography>
        <Typography sx={{ textAlignLast: "left" }}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              pl: "10px",
            }}
          >
            {t("jobs.selected")} : {checkedUsers.length}
          </Typography>
        </Typography>
        <Divider sx={{ width: "100%", my: "10px" }} />
        <Typography fontWeight="500" variant="body2">
          {t("jobs.dragFromHereToHire")}
        </Typography>
      </Stack>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: "3",
          cursor: "pointer",
          ":hover": {
            transition: "color 0.3s",
            color: theme.palette.mockup.error50,
          },
        })}
      >
        <IconX onClick={onClickX} />
      </Box>
    </DraggingBoxStyle>
  )
}
export default BunchUsers
