import React from "react"

import { Button, Stack, Typography } from "@mui/material"
import { useMutationGoogleLoginStudent } from "api/reactQuery/mutations/googleLogin"
import GoogleButton from "components/common/googleButton"
import RegisterInformation from "components/common/registerInformation"
import { useGoogle } from "hooks/google"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { setActivePageSignUp, setAuthData } from "store/auth/auth.slice"
import { UserRole } from "ts/enums/User"
import { getErrorMessageFromTab } from "utils/api"

import SignByEmailButton from "./signByEmailButton"

const SignAsStudent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { showSnackbar } = useCustomSnackbar()

  const { mutate } = useMutationGoogleLoginStudent({
    option: {
      onSuccess: (data) => {
        dispatch(
          setAuthData({
            token: data.data.access_token,
            role: UserRole.STUDENT,
            finalized: !data.data.finalization_required,
          })
        )

        if (!data.data.finalization_required) {
          showSnackbar({
            title: t("noAuthFlow.loginSuccessful"),
          })
        } else {
          showSnackbar({
            title: t("noAuthFlow.yourAccountHasBeenCreated"),
          })
        }

        if (data.data.finalization_required) {
          dispatch(setActivePageSignUp(3))
        }
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { refButton } = useGoogle({ mutate })

  return (
    <Stack>
      <Stack textAlign="left">
        <Typography
          variant="body1"
          color="primary.main"
          fontSize="24px"
          lineHeight="24px"
          fontWeight="600"
        >
          {t("noAuthFlow.student")}
        </Typography>
        <Typography variant="h2" color="mockup.neutral50" sx={{ pt: 1, pb: 4 }}>
          {t("noAuthFlow.signup")}
        </Typography>
      </Stack>
      <GoogleButton text={t("noAuthFlow.signUpGoogle")} ref={refButton} />
      <Typography variant="subtitle1" color="mockup.grey80" py={2}>
        {t("defaultTranslations.or")}
      </Typography>
      <SignByEmailButton onClick={() => dispatch(setActivePageSignUp(2))} />
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 6 }}
        onClick={() => (window.location.href = "https://www.classbank.com/")}
      >
        {t("noAtuhFlow.back")}
      </Button>
      <RegisterInformation isSignInOrUp />
    </Stack>
  )
}

export default SignAsStudent
