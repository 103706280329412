import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IUserMe } from "ts/interfaces/User"

import { IUserMeArgs } from "./userMe.types"

export const useQueryUserMe = (args?: IUserMeArgs) =>
  useQuery<AxiosResponse<IUserMe>, AxiosError>(
    ["userMe"],
    async () => axiosInstance(Endpoint.USER_ME),
    {
      ...args?.options,
    }
  )
