import React from "react"

import { ReactComponent as IconPiggyBank } from "@common_assets/svg/piggy-bank.svg"
import { ReactComponent as IconPlus } from "@common_assets/svg/plus.svg"
import { Button, Stack, Typography, useTheme } from "@mui/material"

interface IBannerGoal {
  handleOpen: () => void
}

const BannerGoal = ({ handleOpen }: IBannerGoal) => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.mockup.primary70,
        borderRadius: 3,
        p: 3,
        rowGap: 3,
      }}
    >
      <IconPiggyBank />
      <Typography variant="subtitle1" color="mockup.neutral10">
        Save for your goals!
      </Typography>
      <Typography variant="body2" fontWeight="500" color="mockup.neutral10">
        Make saving for the future easy by setting a goal and watch your savings
        grow!
      </Typography>
      <Button
        onClick={handleOpen}
        style={{ width: "max-content" }}
        endIcon={<IconPlus />}
      >
        add new goal
      </Button>
    </Stack>
  )
}
export default BannerGoal
