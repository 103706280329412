import React, { useEffect, useState } from "react"

import { ReactComponent as IconSearch } from "@common_assets/svg/search.svg"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { StudentSavingsTypes } from "api/reactQuery/queries/savings.types"
import { useQueryAccountTransactions } from "api/reactQuery/queries/transaction"
import { IAccountTransactions } from "api/reactQuery/queries/transaction.types"
import { format } from "date-fns"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { selectAccount } from "store/savings/savings.selectors"
import { AccountTransactionType } from "ts/enums/Transaction"

import { IInitialDate } from "../savingsColumn/SavingsColumn.types"
import TransactionsListing from "./transactionsListing"
import { transactionsTypeOptions } from "./transactionsListing/TransactionListing.utility"

interface ITransactionsHistory {
  onClickLoadMore: () => void
  initialData: () => IInitialDate
  propsTransactionsHistory: () => {
    typeValue: string
    isDateDisplay: string
    startDate: string | null
    endDate: string | null
    searchWithPage: { page: number; value: string }
  }
}

const TransactionsHistory = ({
  onClickLoadMore,
  initialData,
  propsTransactionsHistory,
}: ITransactionsHistory) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const [currentValue, setCurrentValue] = useState("")
  const [accountTransferData, setAccountTransferData] = useState<
    IAccountTransactions[]
  >([])

  const classData = useAppSelector(selectAccount)

  const filteredTransactionType = (classData: StudentSavingsTypes | null) => {
    if (!classData) {
      return transactionsTypeOptions.filter((type) => type !== "Interest")
    } else {
      return transactionsTypeOptions.filter(
        (type) => type === "Interest" || type === "Transfer"
      )
    }
  }

  const isFetching = (
    start: string | null,
    end: string | null,
    type: string,
    isDisplay: string
  ) => {
    return (
      isDisplay === "all" ||
      (isDisplay === "select" && start !== null && end !== null)
    )
  }

  const {
    setTypeValue,
    setIsDateDisplay,
    setStartDate,
    setEndDate,
    setSearchWithPage,
  } = initialData()

  const { typeValue, isDateDisplay, startDate, endDate, searchWithPage } =
    propsTransactionsHistory()

  const {
    data,
    isLoading,
    isRefetching,
    isFetching: isFetchingNextData,
  } = useQueryAccountTransactions({
    saving_account_id: !!classData ? classData.id : undefined,
    transaction_filter: searchWithPage.value || undefined,
    transaction_type: typeValue === "all_types" ? undefined : typeValue,
    skip: searchWithPage.page,
    start_date:
      startDate !== null
        ? format(new Date(startDate), "yyyy-MM-dd")
        : undefined,
    end_date:
      endDate !== null ? format(new Date(endDate), "yyyy-MM-dd") : undefined,
    options: {
      staleTime: 0,
      enabled: isFetching(startDate, endDate, typeValue, isDateDisplay),
      onSuccess: (data) => {
        const isExisting = data?.data.some((transaction) => {
          return accountTransferData.some((item) => item.id === transaction.id)
        })

        if (isExisting) {
          setAccountTransferData(data?.data)
          return
        }
        setAccountTransferData((prev) => [...prev, ...data?.data])
      },
    },
  })

  useEffect(() => {
    if (
      isDateDisplay === "all" ||
      (isDateDisplay === "select" && startDate !== null && endDate !== null)
    ) {
      setAccountTransferData([])
    }
  }, [typeValue, startDate, endDate, classData, searchWithPage.value])

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchWithPage({
        page: 0,
        value: currentValue,
      })
    }, 500)

    return () => clearTimeout(handler)
  }, [currentValue])

  const handleOpenSetDate = () => {
    setIsDateDisplay("all")
    setSearchWithPage((prev) => ({ ...prev, page: 0 }))
    setStartDate(null)
    setEndDate(null)
    if (isDateDisplay === "all") {
      setIsDateDisplay("select")
    }
  }

  return (
    <Stack
      width="100%"
      my={2}
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.mockup.primary95}`,
        borderRadius: "16px",
        py: 2,
        px: 2,
      }}
    >
      <Box>
        <Typography fontSize="24px" color="mockup.neutral10">
          {t("studentProfile.transactionHistory")}
        </Typography>
        <Divider
          orientation="horizontal"
          style={{
            borderColor: theme.palette.mockup.primary95,
            marginTop: "16px",
            marginBottom: "20px",
            marginLeft: "-16px",
            marginRight: "-16px",
          }}
        />
      </Box>
      <Box>
        <Box display="flex" columnGap={1} mb={2}>
          <TextField
            sx={{
              flex: "1 0 50%",
            }}
            label={`${t("myActivity.searchFor")}...`}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    color: theme.palette.primary.main,
                    p: 0.25,
                    ml: -1,
                    mr: 1,
                  }}
                >
                  <IconSearch />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: focused || !!currentValue,
              style: {
                marginLeft: focused || !!currentValue ? 5 : 20,
              },
            }}
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
          <TextField
            label={t("myActivity.transactionType")}
            select
            value={typeValue}
            onChange={(e) => {
              setTypeValue(e.target.value)
              setSearchWithPage((prev) => ({ ...prev, page: 0 }))
            }}
          >
            <MenuItem value={AccountTransactionType.ALL_TYPES}>
              {t("myActivity.allTypes")}
            </MenuItem>
            {filteredTransactionType(classData).map((transaction) => (
              <MenuItem key={transaction} value={transaction.toLowerCase()}>
                {transaction}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          <Button variant="outlined" onClick={handleOpenSetDate} fullWidth>
            {t("myActivity.setDate")}
          </Button>
        </Box>
        {isDateDisplay === "select" && (
          <Box display="flex" columnGap={2} mt={2} mb={2}>
            <DatePicker
              label="Start date"
              onChange={(value) => {
                setStartDate(value)
                setSearchWithPage((prev) => ({
                  ...prev,
                  page: 0,
                }))
              }}
              value={startDate}
              renderInput={(textFieldProps) => {
                return (
                  <TextField
                    {...textFieldProps}
                    sx={{ width: "max-content" }}
                    helperText={<span>{textFieldProps.error}</span>}
                    inputProps={{
                      ...textFieldProps.inputProps,
                      placeholder: "DD/MM/YYYY",
                    }}
                  />
                )
              }}
            />
            <DatePicker
              label="End date"
              onChange={(value) => {
                setEndDate(value)
                initialData().setSearchWithPage((prev) => ({
                  ...prev,
                  page: 0,
                }))
              }}
              value={endDate}
              renderInput={(textFieldProps) => {
                return (
                  <TextField
                    {...textFieldProps}
                    sx={{ width: "max-content" }}
                    helperText={<span>{textFieldProps.error}</span>}
                    inputProps={{
                      ...textFieldProps.inputProps,
                      placeholder: "DD/MM/YYYY",
                    }}
                  />
                )
              }}
            />
          </Box>
        )}
        {!accountTransferData.length && (isLoading || isFetchingNextData) && (
          <span style={{ display: "block", textAlign: "center" }}>
            <CircularProgress />
          </span>
        )}
        <Stack rowGap={2}>
          <Box>
            <TransitionGroup component={null}>
              {accountTransferData.map((transaction) => (
                <CSSTransition
                  key={transaction.id}
                  timeout={700}
                  classNames="animation-fade"
                >
                  <TransactionsListing data={transaction} />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Box>
          {!!accountTransferData.length && isFetchingNextData && (
            <span style={{ display: "block", textAlign: "center" }}>
              <CircularProgress />
            </span>
          )}
          {!accountTransferData.length && !isLoading && !isRefetching && (
            <Stack justifyContent="center" alignItems="center" p={2}>
              <Typography variant="subtitle1" fontWeight="600">
                {t("myActivity.noTransactionsDuringThisDateRange")}
              </Typography>
            </Stack>
          )}
          {data?.data.length === 10 && !isLoading && !isRefetching && (
            <Button variant="text" onClick={onClickLoadMore}>
              {t("myActivity.loadMore")}
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}
export default TransactionsHistory
