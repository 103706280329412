import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IResponseTeachersManagement,
  ITeachersManagementArgs,
} from "./teachersManagement.types"

export const useQueryTeachersManagement = (args?: ITeachersManagementArgs) =>
  useQuery<AxiosResponse<IResponseTeachersManagement[]>, AxiosError>(
    "teachersManagement",
    async () => await axiosInstance(Endpoint.SCHOOL_TEACHERS),
    args?.options
  )
