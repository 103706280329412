import { ChangeEvent, useEffect, useState } from "react"

import { ReactComponent as IconDocument } from "@common_assets/svg/document-circular.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash-circular.svg"
import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import { useMutationImportStudents } from "api/reactQuery/mutations/importClassStudent"
import { useQueryAsyncTask } from "api/reactQuery/queries/tasks"
import Dialog from "components/common/dialog/dialog"
import IconWrapper from "components/common/icon/iconWrapper"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { get } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { selectTaskId } from "store/utility/utility.selectors"
import {
  setIsNextPage,
  setTaskData,
  setTaskId,
} from "store/utility/utility.slice"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"

import { IProps, Task, ArrayError } from "./DialogImport.types"

const DialogImport = ({
  isOpen,
  onClose,
  classId,
  refetch,
  clearExcludedItems,
  clearSelectedItems,
  setIsToggleAll,
}: IProps) => {
  const theme = useTheme()
  const [isDisabled, setIsDisabled] = useState(false)
  const [filesList, setFilesList] = useState<File | null>(null)
  const [errorDataTab, setErrorDataTab] = useState<ArrayError[] | null>(null)
  const [errorData, setErrorData] = useState<string | null>(null)
  const [isError, setIsError] = useState(false)
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const EXCEL_SHEET = process.env.REACT_APP_XLSX
  const dispatch = useAppDispatch()
  const taskId = useAppSelector(selectTaskId)
  const classnameLocalStorage = localStorage.getItem("classname")

  useEffect(() => {
    filesList !== null && setIsError(false)
  }, [filesList])

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return
    if (event.target.files) setFilesList(event.target.files[0])
    setErrorDataTab(null)
    setErrorData(null)
  }

  const handleClickTrash = () => {
    const fileType = document.getElementById("button-import")
    if (fileType) (fileType as HTMLInputElement).value = ""
    setFilesList(null)
    setIsError(false)
    setIsDisabled(true)
    setErrorDataTab(null)
    setErrorData(null)
  }

  const handleErrorLoginType = (item: ArrayError[]) => {
    if (!item) {
      return null
    }

    const setItem: { msg: string; row: number[] }[] = []

    item.forEach((error) => {
      const existingItem = setItem.findIndex(
        (newError) => newError.msg === error.msg
      )

      if (existingItem !== -1 && error.loc[3] === "login") {
        setItem[existingItem].row.push(error.loc[2])
      } else if (existingItem === -1 && error.loc[3] === "login") {
        setItem.push({ msg: error.msg, row: [error.loc[2]] })
      }
    })

    return (
      <>
        {setItem.map((type, index) => (
          <li key={index}>
            <Typography display="inline" variant="body2" fontWeight="500">
              {type.msg}
            </Typography>{" "}
            - {type.row.join(",")}
          </li>
        ))}
      </>
    )
  }

  const localStorageArr = ({
    data,
    classname,
    filename,
  }: {
    data: Task
    classname: string
    filename: string | null
  }) => {
    const TASKS = "tasks"
    const jsonLocalStorage = localStorage.getItem(TASKS)
    if (jsonLocalStorage === null && data.task_id !== null) {
      localStorage.setItem(
        TASKS,
        JSON.stringify([
          { task_id: data.task_id, classname: classname, filename: filename },
        ])
      )
      return
    }

    if (jsonLocalStorage !== null) {
      const res: (Task & { classname: string; filename: string | null })[] =
        jsonLocalStorage && JSON.parse(jsonLocalStorage)
      const index = res.findIndex((element) => element.task_id === data.task_id)
      if (index === -1 && data.task_id !== null) {
        res.push({
          task_id: data.task_id,
          classname: classnameLocalStorage ?? "undefined class",
          filename: filename,
        })
      }
      localStorage.setItem(TASKS, JSON.stringify(res))
    }
  }

  const { mutate: importStudents, isLoading } = useMutationImportStudents({
    options: {
      onSuccess: (data) => {
        if (data?.data.task_id !== null) {
          showSnackbar({
            title: t("students.processing"),
            variant: "info",
          })
        } else {
          refetch()
          showSnackbar({
            title: t("students.importHasBeenCompleted"),
          })
        }

        localStorageArr({
          data: data?.data,
          classname: classnameLocalStorage ?? "undefined class",
          filename: filesList && filesList.name,
        })

        setFilesList(null)
        dispatch(setIsNextPage(true))
        clearSelectedItems()
        clearExcludedItems()
        setIsToggleAll && setIsToggleAll(false)
        dispatch(setTaskId(data?.data.task_id))
        onClose()
      },
      onError: (err) => {
        const error = get(err.response?.data, "detail", null)

        if (typeof error === "object") {
          setErrorDataTab(error)
        } else {
          setErrorData(getErrorMessage(err))
        }

        showSnackbar({
          title: t("students.somethingWentWrongTryAgain"),
          variant: "error",
        })

        setFilesList(null)
        const fileType = document.getElementById("button-import")
        if (fileType) (fileType as HTMLInputElement).value = ""
      },
    },
  })

  useQueryAsyncTask({
    task_id: taskId,
    options: {
      refetchIntervalInBackground: true,
      refetchInterval: 5 * 1000,
      enabled: !!taskId?.length,
      onSuccess: (data) => {
        if (data?.data.status === "SUCCESS") {
          dispatch(setTaskId(null))
          dispatch(setTaskData(null))
          showSnackbar({
            title: t("students.importHasBeenCompleted"),
          })
          localStorage.removeItem("tasks")
          refetch()
          dispatch(setIsNextPage(true))
          return
        }
        if (data?.data.status === "FAILURE") {
          dispatch(setTaskId(null))
          dispatch(setTaskData(null))
          showSnackbar({
            title: t("students.somethingWentWrong"),
            variant: "error",
            options: {
              persist: true,
            },
          })
          return
        }
        dispatch(setTaskData(data?.data))
        clearSelectedItems()
        clearExcludedItems()
        setIsToggleAll && setIsToggleAll(false)
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const displayErrorText = (text: string) => {
    if (text.indexOf("Please") === -1) {
      return (
        <Typography
          fontWeight="500"
          variant="body1"
          sx={{ marginBottom: "5px" }}
        >
          {text}
        </Typography>
      )
    }
    const indexText = text.indexOf("Please")
    const textPlease = text.slice(indexText)
    const firstText = text.substring(0, indexText)

    return (
      <Stack>
        <Typography
          fontWeight="500"
          variant="body1"
          sx={{ marginBottom: "5px" }}
        >
          {firstText}
        </Typography>
        <Typography fontWeight="500" variant="body1">
          {textPlease}
        </Typography>
      </Stack>
    )
  }

  return (
    <Dialog
      onActionButtonClick={() => {
        if (filesList === null) setIsError(true)
        if (filesList !== null) {
          importStudents({ file: filesList, classId: Number(classId) })
        }
      }}
      open={isOpen}
      onClose={() => {
        onClose()
        setErrorDataTab(null)
        setErrorData(null)
        setFilesList(null)
      }}
      titleText={t("students.importStudents")}
      desktopMaxWidth="534px"
      actionAcceptText={t("students.import")}
      isLoading={isLoading}
      actionAcceptButtonProps={{ disabled: isLoading }}
    >
      <Stack>
        <Typography variant="body1" color="mockup.neutral10" fontWeight="500">
          <Trans
            i18nKey={t("students.importStudentsDescription")}
            components={{
              Link: <a href={EXCEL_SHEET} />,
            }}
          />
        </Typography>
        <input
          disabled={isDisabled}
          type="file"
          id="button-import"
          style={{ display: "none" }}
          onChange={(e) => handleChangeFile(e)}
          accept=".xlsx"
        />
        <label
          htmlFor="button-import"
          style={{
            height: "100%",
            marginTop: "16px",
            position: "relative",
            marginBottom: "10px",
          }}
        >
          <Box
            onClick={handleClickTrash}
            onMouseLeave={() => setIsDisabled(false)}
            component="div"
          >
            <IconTrash
              style={{
                color: theme.palette.primary.main,
                position: "absolute",
                top: "23px",
                right: "24px",
                zIndex: "2",
                cursor: "pointer",
              }}
            />
          </Box>
          <Button
            variant="outlined"
            component="span"
            sx={(theme) => ({
              width: "100%",
              minHeight: "200px",
              display: "flex",
              border: `2px solid  ${
                isError
                  ? theme.palette.mockup.error50
                  : theme.palette.mockup.primary95
              }`,
              borderRadius: "16px",
              justifyContent: "center",
              textTransform: "none",
            })}
          >
            <Stack margin="auto" width="max-content" alignItems="center">
              {!!filesList ? (
                <Typography variant="body1" color="mockup.darkBlue">
                  {filesList.name}
                </Typography>
              ) : (
                <>
                  <IconWrapper>
                    <IconDocument />
                  </IconWrapper>
                  <Typography
                    variant="body1"
                    color={isError ? "mockup.error50" : "mockup.darkBlue"}
                    mt={2}
                  >
                    {t("students.selectFileToUpload")}
                  </Typography>
                </>
              )}
            </Stack>
          </Button>
        </label>
        <Stack
          color="mockup.error50"
          sx={{ display: errorData || errorDataTab ? "block" : "none" }}
        >
          <Typography sx={{ mb: 1 }}>{t("students.importFailed")}</Typography>
          {errorData && displayErrorText(errorData)}
          {errorDataTab && (
            <Box
              fontWeight="500"
              mt={1}
              height="100px"
              sx={{ overflowY: "auto" }}
            >
              <ul>
                {errorDataTab.find((item) => item.loc[3] === "password") && (
                  <li style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="body2"
                      fontWeight="500"
                      display="inline"
                    >
                      Invalid Password in rows -{" "}
                    </Typography>
                    {errorDataTab.map((el) => {
                      if (el.loc[3] === "password") {
                        return `${el.loc[2]}, `
                      }
                    })}
                    <Typography variant="body2" fontWeight="500">
                      Passwords must be at least 6 characters.
                    </Typography>
                  </li>
                )}
                {handleErrorLoginType(errorDataTab)}
                <li>
                  {errorDataTab.find(
                    (item) =>
                      item.loc[3] !== "password" && item.loc[3] !== "login"
                  ) && (
                    <Typography
                      display="inline"
                      variant="body2"
                      fontWeight="500"
                    >
                      {" "}
                      {t("students.notAllowedDataOrEmptyCellsInRows")} -
                    </Typography>
                  )}
                  {errorDataTab.map((el) => {
                    if (el.loc[3] !== "password" && el.loc[3] !== "login") {
                      return `${el.loc[2]}, `
                    }
                  })}
                </li>
              </ul>
            </Box>
          )}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DialogImport
