import { ReactComponent as SvgInvoice } from "@brand_assets/svg/invoice.svg"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const NoTodo = () => {
  const { t } = useTranslation()

  return (
    <NoElementMessageWithoutBtn
      Icon={<SvgInvoice />}
      title={t("dashboard.youDoNotHaveAnyTasks")}
      description={t("dashboard.greatWorkStayingOnTop")}
    />
  )
}

export default NoTodo
