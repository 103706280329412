import { FC } from "react"

import { Button, Typography } from "@mui/material"
import { IStudentSideTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll.type"
import { useTranslation } from "react-i18next"

interface IProps {
  noMoreRecordsMessage: string | null
  refetch: () => void
  isNoMoreData: boolean
  data?: [] | IStudentSideTransactionsAll[]
}

const LoadMore: FC<IProps> = ({
  noMoreRecordsMessage,
  refetch,
  isNoMoreData,
  data,
}) => {
  const { t } = useTranslation()

  const updateFetching = () => refetch()

  return (
    <div style={{ textAlign: "center" }}>
      {isNoMoreData && !data?.length ? (
        ""
      ) : isNoMoreData ? (
        <Typography
          display="flex"
          alignItems="center"
          justifyContent="center"
          variant="body1"
          fontWeight="500"
          color="mockup.neutral10"
          height="44px"
        >
          {noMoreRecordsMessage}
        </Typography>
      ) : (
        <Button variant="text" onClick={updateFetching}>
          {t("loadMore")}
        </Button>
      )}
    </div>
  )
}

export default LoadMore
