import React, { forwardRef } from "react"

import { ReactComponent as GoogleLogo } from "@common_assets/svg/google-color.svg"
import { Box, Button, Typography } from "@mui/material"

interface IProps {
  checked?: boolean
  isSignUp?: boolean
  onClick?: () => void
  text: string
}

type Ref = HTMLDivElement

const GoogleButton = forwardRef<Ref, IProps>(
  ({ checked, isSignUp, text, onClick }, ref) => {
    return (
      <Button
        onClick={onClick}
        variant="outlined"
        fullWidth
        sx={(theme) => ({
          borderRadius: "8px",
          height: "40px",
          position: "relative",
          border: `1px solid ${theme.palette.mockup.neutral90}`,
          color: theme.palette.mockup.neutral10,
        })}
      >
        <div
          ref={ref}
          style={
            isSignUp
              ? { opacity: 0, pointerEvents: checked ? "fill" : "none" }
              : { opacity: 0 }
          }
        ></div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ position: "absolute", pointerEvents: "none" }}
        >
          <GoogleLogo style={{ marginRight: "10px" }} />
          <Typography>{text}</Typography>
        </Box>
      </Button>
    )
  }
)

GoogleButton.displayName = "GoogleButton"

export default GoogleButton
