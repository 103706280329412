import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { ITodo } from "ts/interfaces/Todo"

import {
  IDashboardStoreUpdatesAgrs,
  IGetDashboardTodoArgs,
  IResponseDashboardStoreUpdates,
} from "./dashboardTodo.types"

export const useQueryDashboardTodo = (args?: IGetDashboardTodoArgs) =>
  useQuery<AxiosResponse<ITodo[]>, AxiosError>(
    ["dashboardTodo"],
    async () => axiosInstance(Endpoint.DASHBOARD_TODO),
    {
      ...args?.options,
    }
  )

export const useQueryDashboarStoreUpdates = (
  args?: IDashboardStoreUpdatesAgrs
) =>
  useQuery<AxiosResponse<IResponseDashboardStoreUpdates[]>, AxiosError>(
    ["dashboardStoreUpdates"],
    async () => axiosInstance(Endpoint.DASHBOARD_TODO_STOREUPDATED),
    { ...args?.options }
  )
