import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentJob } from "ts/interfaces/Job"

import {
  IGetStudentJobsArgs,
  IGetStudentPossibleJobsInClass,
  IGetStudentPossibleJobsInClassArgs,
} from "./studentJobs.types"

export const useQueryStudentJobs = (args?: IGetStudentJobsArgs) =>
  useQuery<AxiosResponse<IStudentJob[]>, AxiosError>(
    ["studentJobs"],
    async () => axiosInstance(Endpoint.STUDENT_JOBS),
    {
      ...args?.options,
    }
  )
export const useQueryGetStudentPossibleJobsInClass = (
  args?: IGetStudentPossibleJobsInClassArgs
) =>
  useQuery<AxiosResponse<IGetStudentPossibleJobsInClass[]>, AxiosError>(
    ["studentJobsInClass", args?.classId],
    async () => axiosInstance(`${Endpoint.STUDENT_JOBS}/${args?.classId}`),
    {
      ...args?.options,
    }
  )
