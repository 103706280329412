import { numberWithDotOrComma, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  title: "",
  amount: "",
}

export const validationSchema = yup.object({
  title: requiredStringSchema.max(100),
  amount: numberWithDotOrComma.positive().max(500000),
})

export const validationSchemaForFine = yup.object({
  title: requiredStringSchema.max(100),
  amount: numberWithDotOrComma
    .moreThan(-1, "please enter only positive number or zero")
    .max(500000),
})
