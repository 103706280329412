import { FC, useRef } from "react"

import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import {
  useMutationDeleteClassesStoreClerkItems,
  useMutationPatchClassesStoreClerkItems,
} from "api/reactQuery/mutations/classesStoreItems"
import Dialog from "components/common/dialog/dialog"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { IClassStoreClerkItems } from "ts/interfaces/Class"
import { getErrorMessage } from "utils/api"

interface IProps {
  selectedItems: IClassStoreClerkItems[]
  clearSelectedItems: () => void
}

const TopBatchActionButtons: FC<IProps> = ({
  selectedItems,
  clearSelectedItems,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const isTablet = useIsBreakpointUp("tablet")
  const classId = useClassId()

  const stockActionRef = useRef<"out" | "in" | undefined>(undefined)

  const {
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    isOpen: isOpenDelete,
  } = useDialog()

  const { mutate: deleteItems, isLoading: isLoadingDelete } =
    useMutationDeleteClassesStoreClerkItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "classesStoreClerkItems",
            Number(classId),
          ])
          showSnackbar({
            title: t("store.selectedItemDeleted", {
              count: selectedItems.length,
            }),
          })
          handleCloseDelete()
          clearSelectedItems()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const { mutate: patchItems, isLoading: isLoadingPatch } =
    useMutationPatchClassesStoreClerkItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "classesStoreClerkItems",
            Number(classId),
          ])
          if (stockActionRef.current === "in")
            showSnackbar({
              title: t("store.selectedMarkedAsInStock", {
                count: selectedItems.length,
              }),
            })

          if (stockActionRef.current === "out")
            showSnackbar({
              title: t("store.selectedMarkedAsOutOfStock", {
                count: selectedItems.length,
              }),
            })

          stockActionRef.current = undefined
          clearSelectedItems()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const handleChangeStockStatus = (status: "out" | "in") => {
    stockActionRef.current = status
    patchItems({
      classId: Number(classId),
      data: {
        items: selectedItems.map((item) => ({
          ...item,
          out_of_stock: status === "out" ? true : false,
        })),
      },
    })
  }

  if (selectedItems.length === 0) return null

  return (
    <>
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        justifyContent="space-between"
        alignItems={{ mobile: "start", tablet: "center" }}
        p="8px 16px"
        pb={{ mobile: "0px", tablet: "8px" }}
        bgcolor="mockup.primary95"
        borderBottom={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
      >
        <Typography variant="subtitle1" color="mockup.neutral10">
          {t("store.itemsSelected", { count: selectedItems.length })}:
        </Typography>
        <Stack
          width={{ mobile: "100%", tablet: "max-content" }}
          sx={{ overflowX: "auto" }}
          className="invisible-scrollbar"
        >
          <Stack
            direction="row"
            gap="8px"
            width="max-content"
            mx={{ mobile: "-16px", tablet: "0px" }}
          >
            <Button
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                handleChangeStockStatus("out")
              }}
            >
              {isLoadingPatch && stockActionRef.current === "out" && (
                <CircularProgress size="16px" sx={{ mr: "4px" }} />
              )}
              {t("store.outOfStock")}
            </Button>
            <Button
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                handleChangeStockStatus("in")
              }}
            >
              {isLoadingPatch && stockActionRef.current === "in" && (
                <CircularProgress size="16px" sx={{ mr: "4px" }} />
              )}
              {t("store.inStock")}
            </Button>
            <Button
              variant={isTablet ? "outlined" : "text"}
              onClick={handleOpenDelete}
            >
              {t("store.delete")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={isOpenDelete}
        titleText={t("store.areYouSureYouWantToDelete", {
          count: selectedItems.length,
        })}
        isLoading={isLoadingDelete}
        onClose={handleCloseDelete}
        actionAcceptText={t("store.delete")}
        onActionButtonClick={() => {
          deleteItems({
            classId: Number(classId),
            data: {
              item_ids: selectedItems.map((item) => item.id),
            },
          })
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={500}
          color="mockup.neutral10"
        >
          {t("store.thisCanNotBeRestored")}
        </Typography>
      </Dialog>
    </>
  )
}

export default TopBatchActionButtons
