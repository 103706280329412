import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getLocalStorageClassname } from "utils/classname"
import { getSoundLocalStorage } from "utils/sound"

import { IResponseAsyncTask } from "../../api/reactQuery/queries/tasks.types"
import {
  IObjectStudentDetailed,
  IStudentCandidate,
} from "../../ts/interfaces/Student"
import { INewStudent, IUtilityState, ParamsType } from "./utility.types"

const params: ParamsType = {
  sorting: "asc",
  sort_by: "first_name",
}

const initialState: IUtilityState = {
  classname: getLocalStorageClassname(),
  sound: getSoundLocalStorage(),
  newStudents: [],
  existingStudents: [],
  token: null,
  classList: [],
  page: 0,
  isNextPage: true,
  isNextPageOnStudent: false,
  classListParams: params,
  studentsNumber: 0,
  taskData: null,
  taskId: null,
}

export const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setProfileStudentNextList: (state, { payload }: PayloadAction<boolean>) => {
      state.isNextPageOnStudent = payload
    },
    setClassname: (state, { payload }: PayloadAction<string>) => {
      state.classname = payload
    },
    setSound: (state, { payload }: PayloadAction<boolean>) => {
      state.sound = payload
    },
    setNewStudents: (state, { payload }: PayloadAction<INewStudent[] | []>) => {
      state.newStudents = payload
    },
    setExisting: (state, { payload }: PayloadAction<IStudentCandidate[]>) => {
      state.existingStudents = payload
    },
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      state.token = payload
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      if (payload === 0) {
        state.page = 0
        return
      }
      state.page += payload
    },
    setClassList: (
      state,
      { payload }: PayloadAction<IObjectStudentDetailed[]>
    ) => {
      if (!payload.length) {
        state.classList = []
        return
      }

      const isDuplicate = state.classList.some((el) =>
        payload.some((el2) => el.id === el2.id)
      )

      if (isDuplicate) {
        state.classList = payload
      } else {
        state.classList = [...state.classList, ...payload]
      }
    },
    setIsNextPage: (state, { payload }: PayloadAction<boolean>) => {
      state.isNextPage = payload
    },
    setStudentsNumber: (state, { payload }: PayloadAction<number>) => {
      state.studentsNumber = payload
    },
    setParamsClassStudentList: (
      state,
      { payload }: PayloadAction<ParamsType>
    ) => {
      state.classListParams = payload
    },
    setTaskData: (
      state,
      { payload }: PayloadAction<IResponseAsyncTask | null>
    ) => {
      state.taskData = payload
    },
    setTaskId: (state, { payload }: PayloadAction<string | null>) => {
      state.taskId = payload
    },
  },
})

export const {
  setClassname,
  setSound,
  setNewStudents,
  setExisting,
  setToken,
  setClassList,
  setPage,
  setIsNextPage,
  setProfileStudentNextList,
  setParamsClassStudentList,
  setStudentsNumber,
  setTaskData,
  setTaskId,
} = utilitySlice.actions

export default utilitySlice.reducer
