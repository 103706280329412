export const studentJobs = {
  thereAreNoOpenPositionsYet: "There are no open positions yet",
  checkBackLaterForNewJobListings: "Check back later for new job listings!",
  showOnlyMyJobs: "Show only my jobs",
  week: "week",
  openPositions: "Open positions",
  openPosition: "Open position",
  applyNow: "Apply now!",
  employer: "Employer",
  salary: "Salary",
  hired: "Hired",
  pending: "Pending",
  responsibilities: "Responsibilities",
  qualifications: "Qualifications",
  fillOutTheApplication: "Fill out the application",
  rankYourChoice: "Rank your choice",
  whatExperiencesOrQualificationsMakeYouTheBestCandidate:
    "What experiences or qualifications make you the best candidate?",
  whyDoYouWantThisJob: "Why do you want this job? ",
  uploadResume: "Upload Resume",
  pleaseChooseInterestingJobs: "Please choose interesting jobs",
  onTheLeftSideYouCanClickOnPossibleJobsAndCheckDetailsOfThem:
    "On the left side, you can click on possible jobs and check details of them",
  atTheBottomYouCanClickOnPossibleJobsAndCheckDetailsOfThem:
    "At the bottom, you can click on possible jobs and check details of them",

  yourApplicationHasBeenSentSuccessfully:
    "Your application has been sent successfully",
  thereAreNoAvailablePositionsInJob: "There are no available positions in job",
  uploadFileSucceed: "Upload File Succeed",
  acceptFormatsPDFandDOCX: "Accepted format - PDF or DOCX",
  acceptOnlyPdfOrDocxFiles: "accept only pdf or docx files",
  youAreNeitherHiredNorPendingApplication:
    "You are neither hired nor pending application",
  jobsAreClosed: "The jobs are closed",
  checkBackLaterOrAskYourTeacher:
    "Check back later or ask your teacher when the jobs will be open next!",
}
