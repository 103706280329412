import { FC } from "react"

import { TextField as TextFieldMui, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { IStudentCandidate } from "ts/interfaces/Student"

import FabDeleteItem from "../fabDeleteItem"
import FieldsWrapper from "../fieldsWrapper"

interface IProps {
  student: IStudentCandidate
  onDeleteClick: () => void
}

const ExistingStudentFields: FC<IProps> = ({ student, onDeleteClick }) => {
  const { t } = useTranslation()

  return (
    <FieldsWrapper>
      <Grid container rowSpacing="24px" columnSpacing="8px">
        <Grid item mobile={12} tablet={3}>
          <TextFieldMui
            disabled
            label={t("students.firstName")}
            value={student.first_name}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextFieldMui
            disabled
            label={t("students.lastName")}
            value={student.last_name}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextFieldMui
            disabled
            label={t("students.userName")}
            value={student.login}
          />
        </Grid>
        <Grid item mobile={12} tablet={3}>
          <TextFieldMui
            disabled
            label={t("students.password")}
            value="********"
          />
        </Grid>
      </Grid>
      <FabDeleteItem onClick={onDeleteClick} />
    </FieldsWrapper>
  )
}

export default ExistingStudentFields
