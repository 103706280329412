import React from "react"

import { ReactComponent as IconCoin } from "@brand_assets/svg/coin.svg"
import { ReactComponent as IconWallet } from "@common_assets/svg/wallet-solid.svg"
import { Box, Grid, Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { IStudentGoals } from "ts/interfaces/Student"

import { BorderLinearProgress } from "../Goals.utility"

interface ContainerProps {
  student: IStudentGoals
}

const Container = ({ student }: ContainerProps) => {
  return (
    <Grid item mobile={12} tablet={12} desktop={6}>
      <Stack
        minHeight="168px"
        height="100%"
        bgcolor="mockup.primary99"
        borderRadius="16px"
        sx={{
          padding: "32px",
          paddingBottom: "28px",
          border: "1px solid",
          borderColor: "mockup.primary95",
        }}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          columnGap={1}
        >
          <Stack
            bgcolor="mockup.neutral100"
            color="mockup.primary60"
            width="48px"
            height="48px"
            borderRadius="50%"
            justifyContent="center"
            alignItems="center"
          >
            {!student.icon_url ? (
              <IconWallet />
            ) : (
              <Box width="32px" height="32px">
                <img src={student.icon_url} alt="" width="100%" height="100%" />
              </Box>
            )}
          </Stack>
          <Typography variant="subtitle1" sx={{ wordBreak: "break-all" }}>
            {student.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="subtitle2"
            fontWeight="500"
            sx={{ wordBreak: "break-all" }}
          >
            {student.description}
          </Typography>
        </Stack>
        {!student.completed_at ? (
          <>
            <Stack
              direction="row"
              columnGap="4px"
              alignItems="center"
              pb={{ mobile: 1, desktop: 2 }}
            >
              <IconWrapper height="20px" width="20px">
                <IconCoin height="20px" width="20px" />
              </IconWrapper>

              <Stack direction="row" alignItems="end">
                <Typography variant="h2">{student.current_amount}</Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="500"
                  lineHeight="24px"
                >
                  /${student.total_amount}
                </Typography>
              </Stack>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={student.goal_progress}
            />
          </>
        ) : (
          <Stack flexDirection="row" columnGap={1}>
            <Typography variant="subtitle1" sx={{ my: 2 }}>
              Goals Completed
            </Typography>
            <Coin amount={student.total_amount} />
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}
export default Container
