import { FC } from "react"

import { ReactComponent as IconList } from "@common_assets/svg/grid-horizontal.svg"
import { ReactComponent as IconGrid } from "@common_assets/svg/grid-outlined.svg"
import { Box, Stack } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { selectDisplayMode } from "store/displayMode/displayMode.selectors"
import { setDisplayMode } from "store/displayMode/displayMode.slice"
import {
  setClassList,
  setIsNextPage,
  setPage,
} from "store/utility/utility.slice"

interface IProps {}

const ListingDisplayModeButtons: FC<IProps> = () => {
  const dispatch = useAppDispatch()
  const displayMode = useAppSelector(selectDisplayMode)

  return (
    <Stack
      direction="row"
      boxShadow={(theme) =>
        `inset 0px 0px 0px 2px ${theme.palette.mockup.primary90}`
      }
      borderRadius="16px"
      gap="8px"
      p="12px"
      alignItems="center"
      width="max-content"
    >
      <IconWrapper
        sx={{
          cursor: "pointer",
          transition: "color .5s",
          color: displayMode === "grid" ? "primary.main" : "primary.light",
          "&:hover": {
            color: "mockup.primary80",
          },
        }}
        onClick={() => {
          dispatch(setDisplayMode("grid"))
          dispatch(setClassList([]))
          dispatch(setPage(0))
          dispatch(setIsNextPage(true))
          mixpanel.track("change_page_view", {
            event_name: "grid",
          })
        }}
      >
        <IconGrid />
      </IconWrapper>
      <Box height="17px" width="2px" bgcolor="mockup.primary95" />
      <IconWrapper
        sx={{
          cursor: "pointer",
          transition: "color .5s",
          color: displayMode === "list" ? "primary.main" : "primary.light",
          "&:hover": {
            color: "mockup.primary80",
          },
        }}
        onClick={() => {
          dispatch(setDisplayMode("list"))
          dispatch(setClassList([]))
          dispatch(setPage(0))
          dispatch(setIsNextPage(true))
          mixpanel.track("change_page_view", {
            event_name: "table",
          })
        }}
      >
        <IconList />
      </IconWrapper>
    </Stack>
  )
}

export default ListingDisplayModeButtons
