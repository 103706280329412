import React, { FC, useEffect, useState } from "react"

import { ReactComponent as IconDots } from "@common_assets/svg/threeDots.svg"
import { Fab, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useMutationsResetBalance } from "api/reactQuery/mutations/balancesReset"
import { IPostResetBalance } from "api/reactQuery/mutations/balancesReset.types"
import { IPostClassesTransactionsInput } from "api/reactQuery/mutations/classesTransactions.types"
import {
  useMutationBulkDeleteStudents,
  useMutationRemoveStudentsClass,
} from "api/reactQuery/mutations/students"
import { IPostBulkDeleteRemoveStudents } from "api/reactQuery/mutations/students.types"
import { useQueryHelpUpdateClassesStudents } from "api/reactQuery/queries/classesStudents"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import {
  useFilterClassListArray,
  userFilterBalanceClasslistArray,
  userUpdateBalanceClasslist,
} from "hooks/filter"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import {
  selectClassList,
  selectClassname,
} from "store/utility/utility.selectors"
import {
  setClassList,
  setIsNextPage,
  setPage,
  setStudentsNumber,
} from "store/utility/utility.slice"
import { PopOverStyle, PopRow } from "styles/common/poprow"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { TransactionType } from "ts/types/Transaction"
import { getErrorMessageFromTab } from "utils/api"

import DialogRemoveStudent from "../../DialogRemoveStudent"
import DialogContent from "../../DialogRemoveStudent/DialogContent"
import TransactionsDialogForm from "../transactionsDialogForm"
import {
  BatchActionsContainer,
  TransactionButton,
} from "./BatchActionButtons.styles"
import BatchInfo from "./batchInfo"

interface IProps {
  clearSelectedItems: () => void
  file?: string
  clearExcludedItems: () => void
  studentsNumber: number
  isLoading: boolean
  stateSelectedItems: {
    setSelectedItems: React.Dispatch<
      React.SetStateAction<IObjectStudentDetailed[]>
    >
    selectedItems: IObjectStudentDetailed[]
  }
  stateExcludedItems: {
    setExcludedItems: React.Dispatch<
      React.SetStateAction<IObjectStudentDetailed[]>
    >
    excludedItems: IObjectStudentDetailed[]
  }
  stateIsToggleAll: {
    setIsToggle?: React.Dispatch<React.SetStateAction<boolean>>
    isToggleAll?: boolean
  }
}

const BatchActionButtons: FC<IProps> = ({
  clearSelectedItems,
  file,
  clearExcludedItems,
  studentsNumber,
  isLoading,
  stateIsToggleAll,
  stateExcludedItems,
  stateSelectedItems,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isToggleAll, setIsToggle } = stateIsToggleAll
  const { selectedItems, setSelectedItems } = stateSelectedItems
  const { excludedItems } = stateExcludedItems
  const params = useParams()
  const dispatch = useAppDispatch()
  const classList = useAppSelector(selectClassList)
  const { showSnackbar } = useCustomSnackbar()
  const classname = useAppSelector(selectClassname)
  const [defaultExpandedSections, setDefaultExpandedSections] = useState<
    TransactionType[]
  >([])
  const isTablet = useIsBreakpointUp("tablet")
  const isBetweenTablet = useMediaQuery(
    theme.breakpoints.between("tablet", "desktop")
  )

  const [variablesBulkAction, setVariablesBulkAction] =
    useState<IPostClassesTransactionsInput | null>(null)
  const [variables, setVariables] =
    useState<IPostBulkDeleteRemoveStudents | null>(null)
  const [balanceVariable, setBalanceVariable] =
    useState<IPostResetBalance | null>(null)
  const queryClient = useQueryClient()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isClickOnButton, setIsClickOnButton] = useState(false)

  const selectedIds = selectedItems.map(({ id }) => id)
  const excludedIds = excludedItems.map(({ id }) => id)

  const {
    isOpen: isOpenTransactions,
    handleOpen: handleOpenTransactions,
    handleClose: handleCloseTransactions,
  } = useDialog()

  const {
    isOpen: isOpenRemoveStudents,
    handleClose: handleCloseRemoveStudents,
    handleOpen: handleOpenRemoveStudents,
  } = useDialog()

  const {
    isOpen: isOpenDeleteStudents,
    handleClose: handleCloseDeleteStudents,
    handleOpen: handleOpenDeleteStudents,
  } = useDialog()

  const {
    isOpen: isOpenResetBalance,
    handleOpen: handleOpenResetBalance,
    handleClose: handleCloseResetBalance,
  } = useDialog()

  const { data } = useQueryHelpUpdateClassesStudents({
    classId: Number(params.classId),
    page: 0,
    limit: 200,
    options: {
      enabled: isUpdate,
      cacheTime: 0,
      staleTime: 0,
    },
  })

  const { mutate: resetBalances } = useMutationsResetBalance({
    classId: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.studentsBalancesHasBeenSuccessfullyReset"),
        })

        queryClient.invalidateQueries("classesStudents")
        setBalanceVariable(variables)
        handleCloseResetBalance()
        handleClose()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: deleteStudents } = useMutationBulkDeleteStudents({
    class_id: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.studentHaveBeenSuccessfullyDeleted"),
        })
        queryClient.invalidateQueries("classesStudents")
        if (isToggleAll) {
          dispatch(setIsNextPage(false))
          dispatch(setPage(0))
        }
        setVariables(variables)
        handleCloseDeleteStudents()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })
  const { mutate: removeStudents } = useMutationRemoveStudentsClass({
    class_id: Number(params.classId),
    options: {
      onSuccess: (_, variables) => {
        showSnackbar({
          title: t("students.studentsHaveBeenSuccessfullyRemovedFromClass"),
        })

        queryClient.invalidateQueries("classesCandidates")
        queryClient.invalidateQueries("classesStudents")
        if (isToggleAll) {
          dispatch(setIsNextPage(false))
          dispatch(setPage(0))
        }
        setVariables(variables)
        handleCloseRemoveStudents()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    if (variables !== null) {
      const variables_selected = variables.data.selected_ids
      const { filteredState } = useFilterClassListArray({
        classlist: classList,
        variables,
      })
      dispatch(setClassList(filteredState))

      if (variables.data.all_selected) {
        clearSelectedItems()
        clearExcludedItems()
        setIsToggle && setIsToggle(false)
        dispatch(
          setStudentsNumber(studentsNumber - Number(selectedItems?.length))
        )
      }

      if (!variables.data.all_selected) {
        setSelectedItems((prev) =>
          prev.filter((el) => {
            return !variables_selected?.some((variable) => {
              return el.id === variable
            })
          })
        )

        dispatch(
          setStudentsNumber(studentsNumber - Number(variables_selected?.length))
        )
      }
      handleClose()
    }
  }, [variables])

  useEffect(() => {
    if (balanceVariable !== null) {
      const { updatedBalance } = userFilterBalanceClasslistArray({
        classlist: classList,
        balanceVariable,
      })
      dispatch(setClassList(updatedBalance))

      clearSelectedItems()
      clearExcludedItems()
      setIsToggle && setIsToggle(false)
    }
  }, [balanceVariable])

  useEffect(() => {
    if (data && isClickOnButton) {
      const updatedClassList = userUpdateBalanceClasslist({
        classlist: classList,
        variable: variablesBulkAction,
        updatedClasslist: data?.data.objects,
      })

      dispatch(setClassList(updatedClassList))
      setIsUpdate(false)
      setVariablesBulkAction(null)
      setIsClickOnButton(false)
    }
  }, [data])

  const { popoverAnchorEl, handleClose, handleClick } =
    usePopover<HTMLButtonElement>()

  if (selectedItems.length === 0) return <BatchInfo />

  return (
    <>
      <BatchActionsContainer isBetweenTablet={isBetweenTablet}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            color="mockup.neutral10"
            minWidth="max-content"
          >
            {t("students.numberOfStudentsSelected", {
              quantity:
                isToggleAll && !file?.length
                  ? studentsNumber - excludedItems.length
                  : isLoading
                  ? "-"
                  : selectedItems.length,
            })}
          </Typography>
        </Stack>
        <Stack
          width="100%"
          sx={{
            overflowX: "auto",
          }}
          className="invisible-scrollbar"
        >
          <Stack
            direction="row"
            gap={{ mobile: "0px", tablet: "8px" }}
            width="max-content"
            mx={{ mobile: "-8px", tablet: "0px" }}
            onClick={() => setIsClickOnButton(true)}
          >
            <TransactionButton
              scheme="bonus"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                mixpanel.track("send_transaction", {
                  event_name: "send_bonuses",
                })
                setDefaultExpandedSections(["bonus"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendBonuses")}
            </TransactionButton>
            <TransactionButton
              scheme="fine"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                mixpanel.track("send_transaction", {
                  event_name: "send_fines",
                })
                setDefaultExpandedSections(["fine"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendFines")}
            </TransactionButton>
            <TransactionButton
              scheme="salary"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                mixpanel.track("send_transaction", {
                  event_name: "send_paychecks",
                })
                setDefaultExpandedSections(["salary"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendPaychecks")}
            </TransactionButton>
            <TransactionButton
              scheme="expense"
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                mixpanel.track("send_transaction", {
                  event_name: "send_expenses",
                })
                setDefaultExpandedSections(["expense"])
                handleOpenTransactions()
              }}
            >
              {t("students.sendExpenses")}
            </TransactionButton>
            <Fab
              size={isTablet ? "large" : "small"}
              onClick={handleClick}
              {...(!isTablet && { sx: { mb: "5px" } })}
            >
              <IconWrapper>
                <IconDots />
              </IconWrapper>
            </Fab>
            <PopOverStyle
              open={!!popoverAnchorEl}
              onClose={handleClose}
              anchorEl={popoverAnchorEl}
              marginThreshold={40}
              anchorOrigin={{
                vertical: isTablet ? 50 : 40,
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ul>
                <PopRow onClick={handleOpenResetBalance}>
                  <li>{t("students.resetBalance")}</li>
                </PopRow>
                <PopRow onClick={handleOpenRemoveStudents}>
                  <li>{t("students.removeFromClass")}</li>
                </PopRow>
                <PopRow onClick={handleOpenDeleteStudents}>
                  <li>{t("students.deleteStudent")}</li>
                </PopRow>
              </ul>
            </PopOverStyle>
          </Stack>
        </Stack>
      </BatchActionsContainer>
      <TransactionsDialogForm
        setVariables={setVariablesBulkAction}
        setIsToggle={setIsToggle}
        isOpen={isOpenTransactions}
        file={file}
        isToggleAll={isToggleAll}
        handleClose={handleCloseTransactions}
        defaultExpandedSections={defaultExpandedSections}
        excludedItems={excludedItems}
        studentsIds={selectedItems.map((student) => student.id)}
        onSuccess={() => {
          handleCloseTransactions()
          clearSelectedItems()
          clearExcludedItems && clearExcludedItems()
          setIsUpdate(true)
        }}
      />
      <DialogRemoveStudent
        titleText={t("students.areYouSureYouWantToResetBalancesForClass", {
          classname,
        })}
        isOpen={isOpenResetBalance}
        onClose={handleCloseResetBalance}
        onActionButtonClick={() => {
          if (isToggleAll) {
            resetBalances({
              all_selected: isToggleAll,
              excluded_ids: excludedIds,
            })
          } else {
            resetBalances({ selected_ids: selectedIds })
          }
        }}
        actionAcceptText={t("students.resetBalance")}
      >
        <DialogContent
          isToggleAll={isToggleAll}
          excludedItems={excludedItems}
          selectedItems={selectedItems}
          actionText="ResetBalance"
        />
      </DialogRemoveStudent>
      <DialogRemoveStudent
        titleText={t(
          "students.areYouSureYouWantToRemoveTheseStudentsFromTheClass",
          {
            classname,
          }
        )}
        isOpen={isOpenRemoveStudents}
        onClose={handleCloseRemoveStudents}
        actionAcceptText={t("students.removeFromClass")}
        onActionButtonClick={() => {
          if (isToggleAll) {
            removeStudents({
              data: {
                all_selected: isToggleAll,
                excluded_ids: excludedIds,
              },
            })
          } else {
            removeStudents({
              data: {
                selected_ids: selectedIds,
              },
            })
          }
        }}
      >
        <DialogContent
          actionText="Remove"
          isToggleAll={isToggleAll}
          excludedItems={excludedItems}
          selectedItems={selectedItems}
        />
      </DialogRemoveStudent>
      <DialogRemoveStudent
        titleText={t("students.areYouSureYouWantToDeleteStudents")}
        isOpen={isOpenDeleteStudents}
        onClose={handleCloseDeleteStudents}
        onActionButtonClick={() => {
          if (isToggleAll) {
            deleteStudents({
              data: {
                all_selected: isToggleAll,
                excluded_ids: excludedIds,
              },
            })
          } else {
            deleteStudents({
              data: {
                selected_ids: selectedIds,
              },
            })
          }
        }}
        actionAcceptText={t("students.delete")}
      >
        <DialogContent
          isToggleAll={isToggleAll}
          excludedItems={excludedItems}
          selectedItems={selectedItems}
          actionText="Delete"
          isCaution
        />
      </DialogRemoveStudent>
    </>
  )
}

export default BatchActionButtons
