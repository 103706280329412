export const defaultTranslations = {
  yes: "Yes",
  no: "No",
  loadMore: "Load more",
  loading: "Loading...",
  noOptions: "No options",
  hello: "Hello",
  submit: "Submit",
  cancel: "Cancel",
  close: "Close",
  delete: "Delete",
  save: "Save",
  confirm: "Confirm",
  or: "or",
  search: "Search",
  value: "Value",
  sortBy: "Sort by",
  none: "None",
  uploadPicture: "Upload picture",
  removePicture: "Remove picture",
  fileUploadedSuccessfully: "File uploaded successfully",
  reject: "Reject",
  accept: "Accept",
  transactionAccepted: "Transaction accepted",
  transactionRejected: "Transaction rejected",
  acceptTransaction: "Accept transaction",
  rejectTransaction: "Reject transaction",
  pictureShouldHasOnly2Mb: "File too big. You can upload files to 2Mb",
  findMoreHere: "Find more pricing suggestions <Link>here!</Link>",
}
