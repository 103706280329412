import { AxiosError, AxiosResponse } from "axios"
import qs from "qs"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  IStudentSideTransactionsAll,
  IStudentSideTransactionsAllArgs,
} from "./studentTransactionsAll.type"

export const useQueryStudentTransactionsAll = (
  args: IStudentSideTransactionsAllArgs
) =>
  useQuery<AxiosResponse<IStudentSideTransactionsAll[]>, AxiosError>(
    ["studentTransactionAll"],
    async () =>
      axiosInstance(Endpoint.STUDENT_TRANSACTIONS_ALL, {
        params: {
          skip: args.page,
          limit: args.limit,
          pending: args.is_pending,
          type_in: args.type,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
      }),
    { ...args?.options }
  )
