import { useEffect, useState } from "react"

import { debounce, Grid, Stack } from "@mui/material"
import { useQueryStudentsSearch } from "api/reactQuery/queries/studentsSearch"
import AutocompleteField from "components/form/common/field/autocompleteField"
import SelectField from "components/form/common/field/selectField"
import mixpanel from "mixpanel-browser"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ExtraSmallAvatar } from "styles/common/avatar"
import { IInputOption } from "ts/interfaces/Input"
import { IStudentSearch } from "ts/interfaces/Student"

const SelectStudent = () => {
  const { t } = useTranslation()
  const [classesList, setClassesList] = useState<IInputOption[]>([])
  const [filter, setFilter] = useState("")
  const { data, isError } = useQueryStudentsSearch({
    params: { filter },
  })
  const [isSearching, setIsSearching] = useState(false)

  const { setValue } = useFormContext()

  const studentWatcher: (IStudentSearch & IInputOption) | undefined = useWatch({
    exact: true,
    name: "student",
  })

  useEffect(() => {
    if (isSearching) {
      mixpanel.track("send_quick_transaction", {
        event_name: "search_student",
      })
    }
  }, [isSearching])

  useEffect(() => {
    setValue(
      "studentClass",
      studentWatcher?.classes.length !== 0
        ? studentWatcher?.classes?.[0].id
        : ""
    )
    setClassesList(
      studentWatcher && studentWatcher?.classes.length !== 0
        ? studentWatcher.classes.map((studentClass) => ({
            value: `${studentClass.id}`,
            label: studentClass.name,
          }))
        : []
    )
  }, [studentWatcher])

  return (
    <Grid container spacing="16px" mb="24px">
      <Grid item desktop={4} tablet={8} mobile={12}>
        <AutocompleteField
          noOptionsText={
            !filter?.length
              ? "Type a student’s name"
              : data === undefined || (!!filter?.length && !!data.data.length)
              ? "Loading..."
              : "No options"
          }
          name="student"
          TextFieldProps={{
            error: isError,
            helperText: isError && t("validations.failedToFetchData"),
            label: t("transaction.selectStudent"),
          }}
          filterOptions={(options) => options}
          options={
            data
              ? data.data.map((student) => ({
                  ...student,
                  label: `${student.first_name} ${student.last_name}`,
                  value: `${student.id}`,
                }))
              : []
          }
          onInputChange={debounce((e) => {
            const event = e as React.ChangeEvent<HTMLInputElement>
            const value = event?.target.value as string | 0

            !!e.target.value?.length
              ? setIsSearching(true)
              : setIsSearching(false)

            if (event) {
              setFilter((prev) => (value === 0 ? prev : value))
            }

            return () => setFilter("")
          }, 300)}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <Stack
                direction="row"
                columnGap="8px"
                alignItems="center"
                py="6px"
              >
                <ExtraSmallAvatar src={option.avatar_url} alt="avatar" />
                {`${option.first_name} ${option.last_name}`}
              </Stack>
            </li>
          )}
        />
      </Grid>
      <Grid item desktop={3} tablet={4} mobile={12}>
        <SelectField
          name="studentClass"
          label={t("transaction.studentsClass")}
          options={classesList}
          disabled={!classesList.length}
          onClick={() => {
            mixpanel.track("send_quick_transaction", {
              event_name: "class",
            })
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SelectStudent
