import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostTransactionsBookArgs,
  IPostTransactionsBookInput,
} from "./transactionsBook.types"

export const useMutationPostTransactionsBook = (
  hookArgs?: IPostTransactionsBookArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostTransactionsBookInput>(
    async ({ transactionId, data }) =>
      await axiosInstance.post(
        Endpoint.TRANSACTIONS_ID_BOOK.replace("ID", `${transactionId}`),
        data
      ),
    hookArgs?.options
  )
