import { ITab } from "ts/interfaces/Tab"

export const transformRoutes = (
  tabs: ITab[],
  paramName: string,
  paramValue: string
) => {
  return tabs.map((tab) => ({
    ...tab,
    path: tab.path.replace(paramName, paramValue),
  }))
}
