import { useQueryStudentClassesById } from "api/reactQuery/queries/studentClasses"
import { useClassId } from "hooks/navigation"
import { useNavigate } from "react-router"
import { RoutePath } from "routes/Route.types"

import SubNavigation from "../subNavigation"

const SubNavigationStudent = () => {
  const navigate = useNavigate()
  const classId = useClassId()

  const { data, isLoading, isError } = useQueryStudentClassesById({
    id: Number(classId),
    options: {
      enabled: !!classId,
      staleTime: 5 * 60 * 1000,
      onError: (data) => {
        if (data.response?.status === 404) {
          navigate(RoutePath.PAGE404_NOT_FOUND)
        }
      },
    },
  })

  return (
    <SubNavigation
      routeName={data?.data.name}
      iconName={data?.data.icon}
      isLoading={isLoading}
      isError={isError}
    />
  )
}

export default SubNavigationStudent
