import React, { FC, useEffect, KeyboardEvent } from "react"

import {
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectSound } from "store/utility/utility.selectors"
import { setSound } from "store/utility/utility.slice"
import { TransactionType } from "ts/types/Transaction"
import { setSoundLocalStorage } from "utils/sound"

import Bonuses from "./bonuses"
import Expenses from "./expenses"
import Fines from "./fines"
import Paychecks from "./paychecks"

interface IProps {
  formRoot?: string
  classId: number
  expandedSections?: TransactionType[]
  studentId?: number
  onClickForm?: (e: KeyboardEvent<HTMLElement>) => void
}

const Transactions: FC<IProps> = ({
  formRoot = "",
  studentId,
  classId,
  expandedSections,
  onClickForm,
}) => {
  const soundChecked = useAppSelector(selectSound)
  const dispatch = useAppDispatch()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const isDefaultExpanded = (section: TransactionType) =>
    expandedSections?.some((el) => el === section)

  useEffect(() => {
    setSoundLocalStorage(soundChecked)
  }, [soundChecked])

  return (
    <Stack
      gap="24px"
      mx="-8px"
      onKeyDown={(e) => onClickForm && onClickForm(e)}
    >
      <Bonuses
        studentId={studentId}
        formRoot={formRoot}
        classId={classId}
        defaultExpanded={isDefaultExpanded("bonus")}
      />
      <Fines
        studentId={studentId}
        formRoot={formRoot}
        classId={classId}
        defaultExpanded={isDefaultExpanded("fine")}
      />
      <Paychecks
        studentId={studentId}
        formRoot={formRoot}
        classId={classId}
        defaultExpanded={isDefaultExpanded("salary")}
      />
      <Expenses
        studentId={studentId}
        formRoot={formRoot}
        classId={classId}
        defaultExpanded={isDefaultExpanded("expense")}
      />
      <FormGroup sx={{ pl: 2, display: "block" }}>
        <FormControlLabel
          control={
            <Switch
              checked={soundChecked}
              sx={{ mr: "12px" }}
              onClick={() => {
                dispatch(setSound(!soundChecked))
                showSnackbar({
                  title: !soundChecked
                    ? t("teacherSettings.soundIsOn")
                    : t("teacherSettings.soundIsOff"),
                })
              }}
            />
          }
          label={
            <Typography variant="body1" color="mockup.neutral0">
              {t("teacherSettings.soundEffects")}
            </Typography>
          }
        />
      </FormGroup>
    </Stack>
  )
}

export default Transactions
