import { useEffect } from "react"

import { useScrollToTop } from "hooks/scroll"
import mixpanel from "mixpanel-browser"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import StudentRoutes from "routes/student"
import TeacherRoutes from "routes/teacher"
import UnauthRoutes from "routes/unauth"
import { UserRole } from "ts/enums/User"

import { getUserInfo } from "./api/user"
import { useAppDispatch, useAppSelector } from "./hooks/store"
import { RoutePath } from "./routes/Route.types"
import { selectDataAuth } from "./store/auth/auth.selectors"
import { setActivePageSignUp, setAuthData } from "./store/auth/auth.slice"
import "styles/index.css"
import { setToken } from "./store/utility/utility.slice"
import { clearAuthDataLocalStorage } from "./utils/authData"

const App = () => {
  const dataAuth = useAppSelector(selectDataAuth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useScrollToTop()
  const location = useLocation()

  useEffect(() => {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
      debug: true,
      persistence: "localStorage",
    })
  }, [])

  useEffect(() => {
    location.search.includes("token") &&
      dispatch(setToken(location.search.slice(7)))
  }, [])

  useEffect(() => {
    getUserInfo().catch((err) => {
      if (
        err.response.status === 404 ||
        (dataAuth?.finalized && err.response.status === 401)
      ) {
        clearAuthDataLocalStorage()
        navigate(0)
        return
      }
      if (err.response.status === 403) {
        clearAuthDataLocalStorage()
        dispatch(setAuthData(null))
        navigate(RoutePath.HOMEPAGE)
        return
      }

      if (err.response.data?.detail === "User not finalized") {
        if (dataAuth?.role === "teacher") {
          dispatch(
            setAuthData({
              token: dataAuth?.token,
              finalized: false,
              role: UserRole.TEACHER,
              is_email_confirmed: dataAuth.is_email_confirmed,
            })
          )
          navigate(RoutePath.SIGNUP)
          dispatch(setActivePageSignUp(2))
        }
        if (dataAuth?.role === "student") {
          dispatch(
            setAuthData({
              token: dataAuth?.token,
              finalized: false,
              role: UserRole.STUDENT,
            })
          )
          navigate(RoutePath.SIGNUP)
          dispatch(setActivePageSignUp(3))
        }
      }
    })
  }, [])

  return (
    <>
      {dataAuth?.token &&
        dataAuth.role === UserRole.TEACHER &&
        dataAuth.finalized && <TeacherRoutes />}

      {dataAuth?.token &&
        dataAuth.role === UserRole.STUDENT &&
        dataAuth.finalized && <StudentRoutes />}

      {dataAuth?.token &&
        dataAuth.role === UserRole.STUDENT &&
        !dataAuth.finalized && <UnauthRoutes />}

      {dataAuth?.token &&
        dataAuth.role === UserRole.TEACHER &&
        !dataAuth.finalized && <UnauthRoutes />}

      {dataAuth === null && <UnauthRoutes />}
    </>
  )
}

export default App
