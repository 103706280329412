import { Typography } from "@mui/material"
import { Row, Cell } from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"

const TableHeadRow = () => {
  const { t } = useTranslation()

  return (
    <Row>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.type")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.class")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.teacher")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.title")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.date")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.withdraw")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.deposit")}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body3"
          color="primary.main"
          textTransform="uppercase"
        >
          {t("studentProfile.balance")}
        </Typography>
      </Cell>
      <Cell />
    </Row>
  )
}

export default TableHeadRow
