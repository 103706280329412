import {
  ISalaryTransactionApiCreate,
  ITransactionApiCreate,
} from "ts/interfaces/Transaction"

import { ITransactionsData } from "./Transactions.types"

export const getIsNoTransactionAdded = (transactions: ITransactionsData) =>
  !transactions.bonusTitle &&
  !transactions.fineTitle &&
  !transactions.paycheckTitle &&
  !transactions.expenseTitle &&
  !transactions.bonuses.length &&
  !transactions.fines.length &&
  !transactions.paychecks.length &&
  !transactions.expenses.length

export const getCombinedTransactions = (transactions: ITransactionsData) =>
  [
    ...transactions.bonuses.map((bonus) => ({
      type: "bonus",
      title: bonus.title,
      amount: bonus.amount,
    })),
    ...transactions.fines.map((fine) => ({
      type: "fine",
      title: fine.title,
      amount: fine.amount,
    })),
    ...transactions.expenses.map((expense) => ({
      type: "expense",
      title: expense.title,
      amount: expense.amount,
    })),
    ...(transactions.paychecks.length ? [{ type: "salary" }] : []),
    ...(transactions.bonusTitle
      ? [
          {
            type: "bonus",
            title: transactions.bonusTitle,
            amount: Number(transactions.bonusValue),
          },
        ]
      : []),
    ...(transactions.fineTitle
      ? [
          {
            type: "fine",
            title: transactions.fineTitle,
            amount: Number(transactions.fineValue),
          },
        ]
      : []),
    ...(transactions.paycheckTitle
      ? [
          {
            type: "salary",
            title: transactions.paycheckTitle,
            amount: Number(transactions.paycheckValue),
          },
        ]
      : []),
    ...(transactions.expenseTitle
      ? [
          {
            type: "expense",
            title: transactions.expenseTitle,
            amount: Number(transactions.expenseValue),
          },
        ]
      : []),
  ] as (ITransactionApiCreate | ISalaryTransactionApiCreate)[]
