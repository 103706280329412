const ITEMS_BASE_URL = process.env.REACT_APP_AVATARS_SHOP

export const shopDefaultAvatars = [
  `${ITEMS_BASE_URL}apple.svg`,
  `${ITEMS_BASE_URL}book.svg`,
  `${ITEMS_BASE_URL}candy.svg`,
  `${ITEMS_BASE_URL}clown.svg`,
  `${ITEMS_BASE_URL}crayons.svg`,
  `${ITEMS_BASE_URL}crown.svg`,
  `${ITEMS_BASE_URL}gift+box.svg`,
  `${ITEMS_BASE_URL}glasses.svg`,
  `${ITEMS_BASE_URL}globe.svg`,
  `${ITEMS_BASE_URL}leaf.svg`,
  `${ITEMS_BASE_URL}magic+hat.svg`,
  `${ITEMS_BASE_URL}microphone.svg`,
  `${ITEMS_BASE_URL}note.svg`,
  `${ITEMS_BASE_URL}notes.svg`,
  `${ITEMS_BASE_URL}palette.svg`,
  `${ITEMS_BASE_URL}rocket.svg`,
  `${ITEMS_BASE_URL}school+bell.svg`,
  `${ITEMS_BASE_URL}scrapbook.svg`,
  `${ITEMS_BASE_URL}sport.svg`,
  `${ITEMS_BASE_URL}sticker.svg`,
  `${ITEMS_BASE_URL}telescope.svg`,
  `${ITEMS_BASE_URL}trophy.svg`,
  `${ITEMS_BASE_URL}tshirt.svg`,
  `${ITEMS_BASE_URL}unicorn.svg`,
]
