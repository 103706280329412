import {
  useQueryDashboardTodo,
  useQueryDashboarStoreUpdates,
} from "api/reactQuery/queries/dashboardTodo"
import { IUserMe } from "ts/interfaces/User"
import { checkSubscription } from "utils/api"

import ListingForProSchool from "./listingForProSchool"

const Listing = ({ userInfo }: { userInfo: IUserMe | null }) => {
  const { data, isLoading, isError } = useQueryDashboardTodo({
    options: { enabled: checkSubscription(userInfo) },
  })

  const {
    data: dataStore,
    isLoading: isLoadingDataStore,
    isError: isErrorDataStore,
  } = useQueryDashboarStoreUpdates({
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  return (
    <ListingForProSchool
      dataStore={dataStore}
      data={data}
      isLoading={isLoading}
      isLoadingDataStore={isLoadingDataStore}
      isError={isError}
      isErrorDataStore={isErrorDataStore}
    />
  )
}

export default Listing
