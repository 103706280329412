import React from "react"

import { ButtonProps } from "@mui/material"
import { useTranslation } from "react-i18next"

import Dialog from "../../../../common/dialog/dialog"

interface IDialogEditJob {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onDelete: () => void
  children: React.ReactNode
  actionAcceptButtonProps: ButtonProps
}

const DialogEditJob = ({
  onDelete,
  isOpen,
  isLoading,
  onClose,
  children,
  actionAcceptButtonProps,
}: IDialogEditJob) => {
  const { t } = useTranslation()

  return (
    <Dialog
      titleText={t("jobs.editJob")}
      open={isOpen}
      isLoading={isLoading}
      contentWrapperProps={{ px: "24px" }}
      onClose={onClose}
      desktopMaxWidth="1040px"
      tabletMaxWidth="534px"
      actionAcceptText={"Save"}
      onDeleteClick={onDelete}
      // customActionButtonsSlot={tab !== "general"}
      actionAcceptButtonProps={actionAcceptButtonProps}
      // onActionButtonClick={() => {
      //   setEmployeesTab(false)
      // }}
    >
      {children}
    </Dialog>
  )
}
export default DialogEditJob
