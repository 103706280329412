import { FC } from "react"

import { Skeleton } from "@mui/material"

const TableSkeleton: FC = ({ children }) => {
  return (
    <Skeleton width="100%" height="340px">
      {children}
    </Skeleton>
  )
}

export default TableSkeleton
