import { Box, styled } from "@mui/material"

export const PopRowContent = styled(Box)(({ theme }) => ({
  listStyleType: "none",
  width: "100%",
  fontWeight: "500",
  padding: "12px 16px",
  fontSize: "14px",
  cursor: "pointer",
  color: `${theme.palette.mockup.neutral10}`,
  ":hover": {
    backgroundColor: theme.palette.mockup.primary95,
  },
}))

export const SelectPropsCustomize = (isMobile: boolean) => {
  if (isMobile) {
    return {
      MenuProps: {
        anchorOrigin: {
          vertical: 45,
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        PaperProps: {
          style: {
            marginLeft: "-17px",
          },
        },
      },
      renderValue: (val: string) => {
        return val ? `Pay Frequency: ${val}` : ""
      },
    }
  } else {
    return {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
      renderValue: (val: string) => {
        return val ? `Pay Frequency: ${val}` : ""
      },
    }
  }
}
