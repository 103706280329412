import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentDetailed } from "ts/interfaces/Student"

import { IGetClassesStudentsArgs } from "./classesStudents.types"

export const useQueryClassesStudents = ({
  classId,
  options,
  page,
  sorting,
  filter,
  sort_by,
  limit,
}: IGetClassesStudentsArgs) =>
  useQuery<AxiosResponse<IStudentDetailed>, AxiosError>(
    ["classesStudents", classId, filter],
    async () =>
      axiosInstance(Endpoint.CLASSES_ID_STUDENTS.replace("ID", `${classId}`), {
        params: {
          skip: page,
          limit,
          sorting,
          sort_by,
          filter: filter && filter?.length > 0 ? filter : null,
        },
      }),
    options
  )

export const useQueryHelpUpdateClassesStudents = ({
  classId,
  options,
  page,
  sorting,
  filter,
  sort_by,
  limit,
}: IGetClassesStudentsArgs) =>
  useQuery<AxiosResponse<IStudentDetailed>, AxiosError>(
    ["classesStudentsCopy", classId],
    async () =>
      axiosInstance(Endpoint.CLASSES_ID_STUDENTS.replace("ID", `${classId}`), {
        params: {
          skip: page,
          limit,
          sorting,
          sort_by,
          filter: filter && filter?.length > 0 ? filter : null,
        },
      }),
    options
  )
