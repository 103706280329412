import React, { useEffect, useState } from "react"

import { ReactComponent as IconCoin } from "@brand_assets/svg/coin.svg"
import { Stack, Typography, useTheme } from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

interface StudentCharts {
  data: {
    [key: string]: number
  }
  title: string
  totalTitle: string
}

const StudentCharts = ({ data, title, totalTitle }: StudentCharts) => {
  const [state, setState] = useState<{ name: string; value: number }[]>([])
  const theme = useTheme()

  const totalValue = state[state.length - 1]?.value

  useEffect(() => {
    const dataArr = Object.keys(data).map((key) => ({
      name: key.slice(-5),
      value: data[key],
    }))

    if (dataArr) {
      setState(dataArr)
    }
  }, [data])

  return (
    <Stack
      sx={{
        position: "relative",
        border: `1px solid ${theme.palette.mockup.primary95}`,
        borderRadius: 2,
        py: { mobile: 0.5, desktop: 2 },
        width: "100%",
        minWidth: { mobile: "80px", desktop: "180px" },
      }}
    >
      <Stack
        direction={{ mobile: "row", desktop: "column" }}
        alignItems={{ mobile: "center", desktop: "start" }}
        justifyItems="center"
        columnGap={1}
        p={1}
      >
        <Typography
          variant="subtitle1"
          color="mockup.neutral10"
          fontWeight={500}
        >
          {totalTitle}
        </Typography>
        <Stack direction="row" alignItems="center" columnGap={1}>
          <IconWrapper height="20px" width="20px">
            <IconCoin height="20px" width="20px" />
          </IconWrapper>
          <Typography variant="h2" color="mockup.neutral10">
            {totalValue}
          </Typography>
        </Stack>
      </Stack>
      <ResponsiveContainer height={120}>
        <LineChart
          data={state}
          margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            interval={8}
            dataKey="name"
            label={{
              value: "DATE",
              offset: -5,
              position: "insideBottom",
              style: { fontSize: "12px" },
            }}
            tick={{ fontSize: "12px" }}
          />
          <YAxis
            tick={false}
            label={{
              value: title,
              position: "insideRight",
              dy: -25,
              offset: 10,
              angle: -90,
              style: { fontSize: "12px" },
            }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={theme.palette.mockup.primary60}
            dot={{ r: 1 }}
            strokeWidth={2}
          />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  )
}

export default StudentCharts
