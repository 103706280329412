import { Dispatch, useEffect } from "react"

import { useQueryGetStudentPossibleJob } from "api/reactQuery/queries/students"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import BasicTable from "components/common/table/basicTable"

import TableHead from "./TableHead"
import TableRow from "./TableRow"

const Table = ({
  studentId,
  selectedClass,
  checkedJobs,
  setCheckedJobs,
}: {
  studentId: string
  selectedClass: string
  checkedJobs: number[]
  setCheckedJobs: Dispatch<React.SetStateAction<number[]>>
}) => {
  const { data: studentPossibleJob, isLoading: isLoadingPossibleJobs } =
    useQueryGetStudentPossibleJob({
      studentId: Number(studentId),
      classId: Number(selectedClass),
      options: {
        refetchOnMount: "always",
        cacheTime: 0,
        enabled: !!selectedClass,
        onSuccess: (data) => {
          data.data.forEach((el) => {
            if (el.is_hired) {
              setCheckedJobs((prev) => [...prev, el.id])
            }
          })
        },
      },
    })

  useEffect(() => {
    setCheckedJobs([])
  }, [selectedClass])

  return (
    <BasicTable
      head={
        <TableHead
          allJobs={studentPossibleJob?.data}
          checkedJobs={checkedJobs}
          setCheckedJobs={setCheckedJobs}
        />
      }
      body={
        <TableRow
          checkedJobs={checkedJobs}
          setCheckedJobs={setCheckedJobs}
          allJobs={studentPossibleJob?.data}
        />
      }
      bottomSlot={isLoadingPossibleJobs && <StudentSkeleton height="300px" />}
    />
  )
}

export default Table
