import { FC, memo } from "react"

import { Avatar, Checkbox, CheckboxProps, Typography } from "@mui/material"
import TransactionActionButtons from "components/common/button/transactionActionButtons"
import Coin from "components/common/icon/coin"
import { Cell, Row } from "components/common/table/basicTable"
import { format } from "date-fns"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useClassId } from "hooks/navigation"
import { useQueryClient } from "react-query"
import { ITransactionItemClass } from "ts/interfaces/Transaction"

interface IProps {
  transaction: ITransactionItemClass
  getCheckboxProps: (el: ITransactionItemClass) => CheckboxProps
  setSelectedItems?: React.Dispatch<
    React.SetStateAction<ITransactionItemClass[]>
  >
}

const TableRow: FC<IProps> = ({
  transaction,
  setSelectedItems,
  getCheckboxProps,
}) => {
  const isDesktop = useIsBreakpointUp("desktop")
  const queryClient = useQueryClient()
  const classId = useClassId()

  return (
    <Row>
      <Cell px="8px">
        <Checkbox {...getCheckboxProps(transaction)} />
      </Cell>
      <Cell py={{ tablet: "12px", desktop: "4px" }} gap="8px">
        {isDesktop && (
          <Avatar
            src={transaction.student_avatar_url}
            alt={`${transaction.first_name} ${transaction.last_name}`}
          />
        )}
        <Typography variant="body1" color="mockup.neutral10" lineHeight="24px">
          {`${transaction.first_name} ${transaction.last_name}`}
        </Typography>
      </Cell>
      <Cell gap="8px">
        {isDesktop && transaction.item_icon_url && (
          <img
            src={transaction.item_icon_url}
            alt={transaction.item_icon_url}
            width="32px"
            height="32px"
          />
        )}
        <Typography variant="body2" fontWeight={500} color="mockup.neutral10">
          {transaction.title}
        </Typography>
      </Cell>
      <Cell gap="8px">
        <Typography variant="body2" fontWeight={500} color="mockup.neutral10">
          {format(new Date(transaction.created_at), "MMM dd, yyyy")}
        </Typography>
      </Cell>
      <Cell>
        <Coin amount={`${transaction.value}`} />
      </Cell>
      <Cell>
        <TransactionActionButtons
          setSelectedItems={setSelectedItems}
          classId={classId}
          id={transaction.id}
          onSuccessCallback={() => {
            queryClient.invalidateQueries([
              "classesStoreTransactions",
              Number(classId),
            ])
          }}
        />
      </Cell>
    </Row>
  )
}

export default memo(TableRow)
