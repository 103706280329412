import React, { ChangeEvent, useState } from "react"

import Coin from "components/common/icon/coin"
import { TextFieldErrorStyles } from "pages/jobs/addSuggestedJobs/TextFieldError.styles"
import { regexNumberAndCommon } from "utils/constants"
import { showDecimal } from "utils/decimalNumber"

import { OptionType, UpdateAmountType } from "../../DialogAddSuggested.types"

interface IProps {
  isEditAmount: boolean
  amount: number
  title: string
  getIsChecked: (arg: OptionType) => boolean
  setIsEditAmount: React.Dispatch<React.SetStateAction<boolean>>
  updateDate: (e: ChangeEvent, id: number | undefined) => void
  id: number
  toggleItem: (arg: OptionType) => void
  updateToggleAmount: (arg: UpdateAmountType) => void
}

const UpdateAmount = ({
  updateDate,
  isEditAmount,
  setIsEditAmount,
  amount,
  getIsChecked,
  title,
  toggleItem,
  id,
  updateToggleAmount,
}: IProps) => {
  const [inputAmount, setInputAmount] = useState<number | undefined>(amount)

  return (
    <>
      {!isEditAmount ? (
        <Coin
          style={{ cursor: "pointer" }}
          isEllipsis
          amount={showDecimal(amount) === "NaN" ? 0 : showDecimal(amount)}
          onClick={() => {
            setIsEditAmount(true)
            if (!getIsChecked({ title, id, amount })) {
              toggleItem({ id, title, amount })
            }
          }}
        />
      ) : (
        <TextFieldErrorStyles
          sx={{ width: "100%" }}
          inputProps={{
            sx: {
              padding: "5px 10px",
            },
          }}
          type="text"
          inputRef={(input) => input?.focus()}
          defaultValue={amount}
          onBlur={(e) => {
            setIsEditAmount(false)
            updateDate(e, id)
            updateToggleAmount({ id, amount: inputAmount ?? 0 })
          }}
          onChange={(e) =>
            setInputAmount(
              inputAmount === undefined
                ? 0
                : Number(showDecimal(e.target.value))
            )
          }
          name="amount"
          onPaste={(e) => e.preventDefault()}
          onKeyPress={(event) => {
            if (!regexNumberAndCommon.test(event.key)) {
              event.preventDefault()
            }
          }}
        />
      )}
    </>
  )
}

export default UpdateAmount
