import { Theme } from "@mui/material"

export const progressColorSchema = (progress: number, theme: Theme) => {
  if (progress <= 15) {
    return {
      main: theme.palette.mockup.error90,
      second: theme.palette.mockup.error50,
    }
  } else if (progress > 15 && progress <= 50) {
    return {
      main: theme.palette.mockup.secondary90,
      second: theme.palette.mockup.secondary80,
    }
  } else {
    return {
      main: theme.palette.mockup.success90,
      second: theme.palette.mockup.success50,
    }
  }
}
