import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IClassStoreClerkItems, IClassStoreItem } from "ts/interfaces/Class"

import {
  IGetClassesStoreClerkItemsArgs,
  IGetClassesStoreItemsArgs,
} from "./classesStoreItems.types"

export const useQueryClassesStoreItems = ({
  classId,
  options,
}: IGetClassesStoreItemsArgs) =>
  useQuery<AxiosResponse<IClassStoreItem[]>, AxiosError>(
    ["classesStoreItems", classId],
    async () =>
      axiosInstance(Endpoint.CLASSES_STORE_ITEMS.replace("ID", `${classId}`)),
    options
  )

export const useQueryClassesStoreClerkItems = ({
  classId,
  options,
}: IGetClassesStoreClerkItemsArgs) =>
  useQuery<AxiosResponse<IClassStoreClerkItems[]>, AxiosError>(
    ["classesStoreClerkItems", classId],
    async () =>
      axiosInstance(
        Endpoint.CLASSES_ID_HELPER_STORE_CLERK_STORE_ITEMS.replace(
          "ID",
          `${classId}`
        )
      ),
    options
  )
