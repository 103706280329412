import { requiredEmailSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  username: "",
  password: "",
}

export const validationSchema = yup.object({
  username: requiredEmailSchema,
  password: requiredStringSchema,
})
