import { styled } from "@mui/material"

export const StyledImg = styled("img")({
  objectFit: "cover",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  borderRadius: "16px",
})
