import { Type } from "ts/enums/Filtration"
import { stringSchema } from "utils/yup"
import * as yup from "yup"

export const validateSchema = yup.object({
  avatar_url: stringSchema,
})

export interface ISubFilters {
  name: Type
  check: boolean
}

export interface IRadioFilters {
  name: Type.pending | Type.allTransactions
}

export interface IFilter {
  status: IRadioFilters[]
  type: ISubFilters[]
}

export const filterTransactions: IFilter = {
  status: [{ name: Type.allTransactions }, { name: Type.pending }],

  type: [
    { name: Type.purchase, check: false },
    { name: Type.bonus, check: false },
    { name: Type.fine, check: false },
    { name: Type.salary, check: false },
    { name: Type.expense, check: false },
    { name: Type.transfer, check: false },
  ],
}
