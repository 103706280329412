import React, { useState } from "react"

import { Button, Stack, Typography, useTheme } from "@mui/material"
import { useMutationPostHrCreateJobs } from "api/reactQuery/mutations/jobs"
import { useQueryGetHrJobs } from "api/reactQuery/queries/jobs"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"
import { renderMultipleComponents } from "utils/jobs/jobs"

import JobsSkeleton from "../../common/skeleton/jobs"
import Board from "./board"
import DialogAddJob from "./dialogAddJob"
import ListUsers from "./listUsers"
import NoJobs from "./noJobs"

const Hr = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const params = useParams()
  const queryClient = useQueryClient()
  const isTablet = useIsBreakpointUp("desktop")
  const theme = useTheme()
  const isMobile = useIsBreakpointUp("tablet")

  const [richEditorResponsibilities, setRichEditorResponsibilities] =
    useState("")
  const [richEditorQualifications, setRichEditorQualifications] = useState("")

  const { isOpen, handleOpen, handleClose } = useDialog()

  const { data: jobs, isLoading: loadingJobs } = useQueryGetHrJobs({
    classId: Number(params.classId),
  })

  const { mutate: createJob, isLoading: isLoadingCreateJob } =
    useMutationPostHrCreateJobs({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries("jobsHr")
          showSnackbar({
            title: t("jobs.succeedCreateJob"),
            variant: "success",
          })
          handleClose()
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  return (
    <Stack height="100%" flexDirection="row">
      {isTablet && (
        <Stack
          sx={{
            flex: "0 0 300px",
            overflowX: "auto",
            height: "calc(-150px + 100vh)",
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.mockup.primary95}`,
          }}
        >
          <ListUsers />
        </Stack>
      )}
      <Stack sx={{ flex: "1 1 max-content", ml: isTablet ? 4 : 0, mt: 4 }}>
        <Stack
          sx={{ height: "56px", mb: 5 }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              backgroundColor: isMobile ? theme.palette.common.white : "inset",
              p: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "26px" }}
              color="mockup.neutral10"
              flex="0 0 150px"
            >
              {t("jobs.jobBoard")}
            </Typography>
            {isMobile && (
              <Typography variant="subtitle2" fontWeight="500" flex="1 1 0">
                You’ve been assigned as an HR Representative. This means you now
                have the ability to hire and fire students. Remember, hiring
                changes have an impact on your classroom community, so stay
                trustworthy!
              </Typography>
            )}
          </Stack>
          <Button sx={{ whiteSpace: "nowrap" }} onClick={handleOpen}>
            {t("jobs.addJob")}
          </Button>
        </Stack>
        {loadingJobs ? (
          renderMultipleComponents(
            <JobsSkeleton
              height="200px"
              style={{
                margin: "10px 0 ",
              }}
            />,
            2
          )
        ) : (
          <Stack
            direction={jobs && jobs.data.length > 0 ? "row" : "column"}
            flexWrap="wrap"
            justifyContent="flex-start"
            mt={2}
            sx={{
              maxHeight: "calc(100vh - 65px - 80px - 85px)",
              overflowY: "auto",
            }}
          >
            {!jobs?.data.length ? (
              <NoJobs
                richEditorResponsibilities={richEditorResponsibilities}
                setRichEditorResponsibilities={setRichEditorResponsibilities}
                richEditorQualifications={richEditorQualifications}
                setRichEditorQualifications={setRichEditorQualifications}
              />
            ) : (
              jobs?.data
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((job) => <Board job={job} key={job.id} />)
            )}
          </Stack>
        )}
      </Stack>
      <DialogAddJob
        setRichEditorQualifications={setRichEditorQualifications}
        setRichEditorResponsibilities={setRichEditorResponsibilities}
        richEditorResponsibilities={richEditorResponsibilities}
        richEditorQualifications={richEditorQualifications}
        handleClose={handleClose}
        isOpen={isOpen}
        isLoading={isLoadingCreateJob}
        onCreate={createJob}
      />
    </Stack>
  )
}
export default Hr
