import { FC } from "react"

import { Stack, Typography } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { get, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { getFieldName } from "utils/field"

import TextField from "../textField"
import { pinFieldDefaultValues } from "./PinField.config"

interface IProps {
  name: string
  label?: string
}

const PinField: FC<IProps> = ({ name, label }) => {
  const { t } = useTranslation()
  const { setFocus, formState } = useFormContext()
  const isTablet = useIsBreakpointUp("tablet")

  const pinError = get(formState, `errors.${name}`, null)

  return (
    <Stack>
      {label && (
        <Typography
          mb="8px"
          variant="body3"
          fontWeight={600}
          color="mockup.neutral40"
        >
          {label}
        </Typography>
      )}
      <Stack direction="row" gap={{ mobile: "8px", tablet: "12px" }}>
        {Object.keys(pinFieldDefaultValues).map((key) => {
          return (
            <TextField
              key={key}
              name={getFieldName(name, key)}
              sx={{
                width: "58px",
              }}
              inputProps={{
                maxLength: 1,
                style: {
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 700,
                  padding: isTablet ? "18px 16px" : "18px 10px",
                },
              }}
              customOnChange={(e, onChange) => {
                onChange(e)
                if (e.target.value === "") {
                  if (Number(key.slice(1)) !== 1) {
                    setFocus(getFieldName(name, `c${Number(key.slice(1)) - 1}`))
                  }
                } else {
                  if (
                    Number(key.slice(1)) <
                    Object.keys(pinFieldDefaultValues).length
                  ) {
                    setFocus(getFieldName(name, `c${Number(key.slice(1)) + 1}`))
                  }
                }
              }}
            />
          )
        })}
      </Stack>
      {pinError && (
        <Typography
          color="error.main"
          fontWeight={500}
          variant="body2"
          mt="8px"
        >
          {t("validations.enterAllCodeFields")}
        </Typography>
      )}
    </Stack>
  )
}

export default PinField
