import React from "react"

import { Box, Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
  submitButton: string
  onClick: () => void
  isLoading?: boolean
}

const RegisterButtons = ({ onClick, submitButton, isLoading }: IProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex", mt: 4 }}>
      <Button
        sx={{ mr: "10px", maxWidth: "100px" }}
        variant="outlined"
        fullWidth
        onClick={onClick}
      >
        {t("noAuthFlow.back")}
      </Button>
      <Button type="submit" fullWidth disabled={isLoading}>
        {isLoading && (
          <CircularProgress
            size="16px"
            sx={{ color: "common.white", mr: "5px" }}
          />
        )}
        {submitButton}
      </Button>
    </Box>
  )
}

export default RegisterButtons
