import React from "react"

import { Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { ClassnameProps } from "../AllTransactionsTeacher"

interface FilterOptionsProps {
  filterName: (string | ClassnameProps)[]
  onClick: (val: string | ClassnameProps) => void
  onClickReset: () => void
}

const FilterOptions = ({
  filterName,
  onClick,
  onClickReset,
}: FilterOptionsProps) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" columnGap={2} rowGap={1} pb={1} flexWrap="wrap">
      {filterName?.map((name, index) => (
        <Stack
          onClick={() => onClick(name)}
          direction="row"
          alignItems="center"
          key={typeof name === "object" ? name?.id : name + index}
          sx={(theme) => ({
            backgroundColor: theme.palette.mockup.neutral90,
            color: theme.palette.mockup.neutral10,
            borderRadius: "8px",
            p: "4px 8px",
            cursor: "pointer",
          })}
        >
          <Typography variant="body3" pr={1}>
            {typeof name === "object"
              ? name?.name.toUpperCase()
              : name?.toUpperCase()}
          </Typography>
          <Typography variant="body3">x</Typography>
        </Stack>
      ))}
      {!!filterName.length && (
        <Button
          sx={{ p: "5px", minHeight: "15px", fontSize: "12px" }}
          variant="text"
          onClick={onClickReset}
        >
          {t("studentProfile.resetFilters")}
        </Button>
      )}
    </Stack>
  )
}
export default FilterOptions
