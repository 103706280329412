import { FieldError } from "react-hook-form"
import { TFunction } from "react-i18next"
import { FieldErrorRHF } from "ts/types/RHF"

export const getFieldError = (
  originalError: FieldError | undefined,
  t: TFunction
) => {
  const fieldError = originalError as FieldErrorRHF
  let errorMessage: string | undefined
  if (fieldError?.message) {
    errorMessage =
      typeof fieldError.message === "string"
        ? t(fieldError.message)
        : t(fieldError.message.key, fieldError.message.values)
  }

  return {
    isError: !!fieldError,
    errorMessage,
  }
}
