import { FC, ReactNode } from "react"

import { ReactComponent as IconClose } from "@common_assets/svg/cross.svg"
import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import {
  Button,
  ButtonProps,
  CircularProgress,
  Dialog as DialogMui,
  DialogProps,
  Fab,
  Stack,
  StackProps,
  Typography,
} from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"

export type DialogMaxWidth =
  | "344px"
  | "440px"
  | "534px"
  | "720px"
  | "867px"
  | "1040px"

interface IProps extends DialogProps {
  tabletMaxWidth?: DialogMaxWidth
  desktopMaxWidth?: DialogMaxWidth
  titleText?: string
  titleBeforeSlot?: ReactNode
  actionAcceptText?: string
  isLoading?: boolean
  actionAcceptButtonProps?: ButtonProps
  onDeleteClick?: () => void
  onActionButtonClick?: () => void
  customActionButtonsSlot?: ReactNode
  contentWrapperProps?: StackProps
  onIconClose?: boolean
}

const Dialog: FC<IProps> = ({
  children,
  PaperProps,
  scroll = "body",
  tabletMaxWidth = "534px",
  desktopMaxWidth = "534px",
  titleText,
  titleBeforeSlot,
  actionAcceptText,
  isLoading = false,
  actionAcceptButtonProps,
  onDeleteClick,
  onActionButtonClick,
  customActionButtonsSlot,
  contentWrapperProps,
  onIconClose = "true",
  ...props
}) => {
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <DialogMui
      {...props}
      scroll={scroll}
      fullWidth
      PaperProps={{
        ...PaperProps,
        sx: (theme) => ({
          [theme.breakpoints.up("tablet")]: {
            maxWidth: tabletMaxWidth,
          },
          [theme.breakpoints.up("desktop")]: {
            maxWidth: desktopMaxWidth,
          },
        }),
      }}
    >
      <Stack position="relative">
        <Stack
          position="absolute"
          top="12px"
          right="12px"
          direction="row"
          gap="12px"
        >
          {onDeleteClick && (
            <Fab size="small" onClick={onDeleteClick}>
              <IconWrapper color="error.main">
                <IconTrash />
              </IconWrapper>
            </Fab>
          )}
          {onIconClose && (
            <Fab
              size="small"
              onClick={() =>
                props.onClose && props.onClose({}, "backdropClick")
              }
            >
              <IconWrapper>
                <IconClose />
              </IconWrapper>
            </Fab>
          )}
        </Stack>
        <Stack p="32px" pb="24px" gap="24px" {...contentWrapperProps}>
          {titleText && (
            <Stack direction="row" gap="12px" alignItems="center">
              {titleBeforeSlot}
              <Typography
                variant="h3"
                color="mockup.darkBlue"
                sx={{ overflowWrap: "anywhere" }}
              >
                {titleText}
              </Typography>
            </Stack>
          )}
          {children}
        </Stack>
        {customActionButtonsSlot}
        {!customActionButtonsSlot && (
          <Stack
            px="32px"
            py="16px"
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.mockup.primary95}`,
            })}
            direction="row"
            gap="10px"
            justifyContent="end"
          >
            <Button
              size="small"
              fullWidth={!isTablet}
              onClick={onActionButtonClick}
              {...actionAcceptButtonProps}
            >
              <Stack
                color="common.white"
                gap="4px"
                direction="row"
                alignItems="center"
              >
                <span>
                  {isLoading && (
                    <CircularProgress size="20px" color="inherit" />
                  )}
                </span>
                {actionAcceptText || t("submit")}
              </Stack>
            </Button>
          </Stack>
        )}
      </Stack>
    </DialogMui>
  )
}

export default Dialog
