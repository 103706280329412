import bonusSound from "@common_assets/sound/Bonus_Paycheck.wav"
import finesSound from "@common_assets/sound/Fine_Expense.wav"

import {
  ISalaryTransactionApiCreate,
  ITransactionApiCreate,
} from "../../ts/interfaces/Transaction"

const SOUND_EFFECT_KEY = "soundEffect"

export const getSoundLocalStorage = () => {
  const soundData = localStorage.getItem(SOUND_EFFECT_KEY)
  if (soundData) {
    return JSON.parse(soundData) as boolean
  } else {
    return true
  }
}

export const setSoundLocalStorage = (soundData: boolean) => {
  return localStorage.setItem(SOUND_EFFECT_KEY, JSON.stringify(soundData))
}

export const checkTransaction = (
  transactionType: (ITransactionApiCreate | ISalaryTransactionApiCreate)[],
  soundChecked: boolean
) => {
  const res = transactionType.map((el) => el.type)
  if (
    (res.includes("bonus") || res.includes("salary")) &&
    (res.includes("fine") || res.includes("expense"))
  ) {
    soundChecked && new Audio(bonusSound).play()
    return
  }

  if (res.includes("fine") || res.includes("expense")) {
    soundChecked && new Audio(finesSound).play()
    return
  }

  if (res.includes("bonus") || res.includes("salary")) {
    soundChecked && new Audio(bonusSound).play()
    return
  }
}
