import axiosInstance from "api/api"
import { Endpoint } from "api/enpoints.types"
import { IGetStudentsBalanceChartsArgs } from "api/reactQuery/queries/studentsBalanceCharts.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"
import { IStudentsBalanceCharts } from "ts/interfaces/Student"

export const useQueryStudentsBalanceCharts = ({
  student_id,
  start_date,
  end_date,
  options,
}: IGetStudentsBalanceChartsArgs) =>
  useQuery<AxiosResponse<IStudentsBalanceCharts>, AxiosError>(
    ["studentBalanceCharts", student_id],
    async () =>
      axiosInstance(
        Endpoint.STUDENTS_ID_BALANCE_CHARTS.replace("ID", `${student_id}`),
        { params: { start_date, end_date } }
      ),
    options
  )
