import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  StudentHelpersArgs,
  StudentHelpersRequest,
  StudentHelpersResponse,
} from "./studentHelpers.types"

export const useMutationStudentHelpers = (args?: StudentHelpersArgs) =>
  useMutation<
    AxiosResponse<StudentHelpersResponse[]>,
    AxiosError,
    StudentHelpersRequest[]
  >(
    async (data) =>
      await axiosInstance.post(
        Endpoint.CLASSES_ID_STUDENT_HELPERS.replace("ID", `${args?.class_id}`),
        data
      ),
    args?.options
  )
