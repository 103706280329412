export enum KindAction {
  PAGE = "PAGE",
  ISNEXTPAGE = "ISNEXTPAGE",
}

type Action =
  | { type: KindAction.PAGE; payload: number }
  | { type: KindAction.ISNEXTPAGE; payload: boolean }

type State = {
  page: number
  isNextPage: boolean
}

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action
  switch (type) {
    case KindAction.PAGE:
      return {
        ...state,
        page: payload === 0 ? 0 : state.page + payload,
      }
    case KindAction.ISNEXTPAGE:
      return { ...state, isNextPage: payload }
    default:
      return state
  }
}
