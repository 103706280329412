const CLASSNAME = "classname"
export const getLocalStorageClassname = () => {
  const classname = localStorage.getItem(CLASSNAME)
  if (classname) {
    return classname
  } else {
    return ""
  }
}

export const setLocalStorageClassname = (name: string) => {
  return localStorage.setItem(CLASSNAME, name)
}
