import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import { IResponseGoogleStudent } from "ts/interfaces/GoogleStudent"

import {
  IGoogleLoginArgs,
  IGoogleLoginStudent,
  IResponseGoogleLogin,
} from "./googleLogin.types"

export const useMutationGoogleLogin = ({ option }: IGoogleLoginArgs) =>
  useMutation<
    AxiosResponse<IResponseGoogleLogin>,
    AxiosError,
    Record<string, unknown>
  >(async (params) => {
    return await axiosInstance.post(Endpoint.TEACHER_LOGIN_GOOGLE, undefined, {
      params,
    })
  }, option)

export const useMutationGoogleLoginStudent = (args?: IGoogleLoginStudent) =>
  useMutation<
    AxiosResponse<IResponseGoogleStudent>,
    AxiosError,
    Record<string, unknown>
  >(async (params) => {
    return await axiosInstance.post(Endpoint.STUDENTS_LOGIN_GOOGLE, undefined, {
      params,
    })
  }, args?.option)
