import React, { Dispatch, useEffect, useRef, useState } from "react"

import { ReactComponent as IconBulb } from "@common_assets/svg/bulb.svg"
import { ReactComponent as SvgPhoto } from "@common_assets/svg/photo.svg"
import { ReactComponent as IconQuestionMark } from "@common_assets/svg/question.svg"
import {
  Box,
  Checkbox,
  FormControl,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useQueryClassesStudents } from "api/reactQuery/queries/classesStudents"
import ErrorText from "components/common/error/errorText"
import IconWrapper from "components/common/icon/iconWrapper"
import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import { PrimaryTile } from "components/common/tile"
import SearchbarForm from "components/form/searchbarForm"
import { useSelectedItems } from "hooks/listing"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getSortingTextSecond } from "utils/listing"

import { Action } from "../../../../layout/asideNav/navigation/Navigation.utility"
import {
  sortingOptions,
  sortingTextMap,
  Sort,
  SortingType,
} from "./Advance.utility"
import { useSortedStudents } from "./hook"

interface AdvanceProps {
  classId?: number
  dispatchState?: Dispatch<Action>
}

const Advance = ({ classId, dispatchState }: AdvanceProps) => {
  const { t } = useTranslation()
  const refBlocked = useRef(true)
  const theme = useTheme()

  const [sorting, setSorting] = useState<SortingType>("asc")
  const [showNoMatches, setShowNoMatches] = useState(false)
  const [searchFilter, setSearchFilter] = useState("")
  const [sortBy, setSortBy] = useState<Sort>("first_name")
  const [originalStudents, setOriginalStudents] = useState<
    IObjectStudentDetailed[]
  >([])
  const [temporaryStudents, setTemporaryStudents] = useState<
    IObjectStudentDetailed[]
  >([])
  const [temporarySelectedItems, setTemporarySelectedItems] = useState<
    IObjectStudentDetailed[]
  >([])

  const sortedStudent = useSortedStudents({
    students: temporaryStudents,
    sortBy: sortBy,
    sortOrder: sorting,
  })

  const sortingProps = useSelectedItems(temporaryStudents)

  const {
    toggleAll,
    getIsChecked,
    selectedItems,
    setSelectedItems,
    toggleItem,
  } = sortingProps

  const displayMathView =
    temporaryStudents.length === originalStudents.length
      ? !selectedItems.length
      : !temporarySelectedItems.length

  const { isLoading, isError } = useQueryClassesStudents({
    classId: classId as number,
    limit: 200,
    page: 0,
    options: {
      cacheTime: 0,
      enabled: !!classId && refBlocked.current,
      onSuccess: (data) => {
        setOriginalStudents(data.data.objects)
        const filterIsMathEnabled = data?.data.objects.filter(
          (item) => item.is_math_enabled
        )
        setSelectedItems(filterIsMathEnabled)
        refBlocked.current = false
      },
    },
  })

  useEffect(() => {
    return () => {
      refBlocked.current = true
    }
  }, [])

  useEffect(() => {
    if (!isLoading && !!searchFilter.length && !temporaryStudents.length) {
      const timer = setTimeout(() => {
        setShowNoMatches(true)
      }, 500)

      return () => clearTimeout(timer)
    } else {
      setShowNoMatches(false)
    }
  }, [isLoading, searchFilter, temporaryStudents])

  useEffect(() => {
    const search = originalStudents.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchFilter.toLowerCase())
    )
    setTemporaryStudents(search)

    const temporarySelected = search.filter((item) => {
      return selectedItems.some((item2) => item2.id === item.id)
    })

    setTemporarySelectedItems(temporarySelected)
  }, [searchFilter, originalStudents, selectedItems])

  useEffect(() => {
    dispatchState &&
      dispatchState({
        type: "enableCheckedStudents",
        studentArr: selectedItems.map((item) => item.id),
      })
  }, [selectedItems])

  useEffect(() => {
    dispatchState &&
      classId &&
      dispatchState({
        type: "classID",
        classID: classId,
      })
  }, [classId])

  return (
    <Stack pt={2}>
      <Typography variant="subtitle2">Math calculations</Typography>
      <Stack
        direction="row"
        columnGap={1}
        sx={{
          pl: 3,
          mt: 1,
        }}
      >
        <Typography
          variant="body1"
          fontWeight="500"
          color={theme.palette.mockup.grey70}
        >
          {t("addNewClass.manageMathCalculationsForStudents")}
        </Typography>
        <Tooltip
          title={t(
            "addNewClass.checkedStudentsWillBeResponsibleForDoingTheMath"
          )}
        >
          <IconQuestionMark />
        </Tooltip>
      </Stack>
      <PrimaryTile mt={2}>
        <Stack p={2}>
          <SearchbarForm
            onSubmit={(data) => setSearchFilter(data.search)}
            placeholder={`${t("store.searchForItem")}...`}
          />
        </Stack>
        {displayMathView && (
          <Stack
            sx={{
              borderTop: `1px solid ${theme.palette.mockup.primary_base}`,
              borderBottom: `1px solid ${theme.palette.mockup.primary_base}`,
              flexDirection: "row",
              alignItems: "center",
              p: 2,
            }}
          >
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {t("students.mathOn")}
            </Typography>
            <Box display="flex" alignItems="center" pl={2}>
              <IconWrapper width="14px" height="20px" sx={{ mr: 1 }}>
                <IconBulb width="14px" height="14px" viewBox="0 0 14 14" />
              </IconWrapper>
              <Typography fontWeight="500">
                {t(
                  "students.dontWantStudentsToHaveToAddOrSubtractTheirTransactions"
                )}
              </Typography>
            </Box>
          </Stack>
        )}
        {!displayMathView && (
          <Box
            display="flex"
            sx={{
              borderBottom: `1px solid ${theme.palette.mockup.primary_base}`,
              justifyContent: "space-between",
              backgroundColor: theme.palette.mockup.primary95,
              p: 2,
            }}
          >
            <Typography variant="body2">Math On</Typography>
            <Typography>
              {`${
                originalStudents.length === temporaryStudents.length
                  ? selectedItems.length
                  : temporarySelectedItems.length
              } students selected`}
            </Typography>
          </Box>
        )}
        <ListingSortingHeader
          onSubmit={(data) => {
            const dataSortReturn = JSON.parse(data.sort)
            const { order, orderBy } = dataSortReturn
            setSortBy(orderBy)
            setSorting(order)
          }}
          checkboxProps={{
            checked:
              temporaryStudents.length === originalStudents.length
                ? originalStudents?.length === selectedItems.length &&
                  !!originalStudents?.length
                : temporaryStudents?.length === temporarySelectedItems.length &&
                  !!temporaryStudents?.length,
            onChange: () => {
              if (temporaryStudents.length === originalStudents.length) {
                toggleAll()
                return
              }

              if (temporarySelectedItems.length === temporaryStudents.length) {
                setTemporarySelectedItems([])
                setSelectedItems(
                  selectedItems.filter(
                    (item) => !temporaryStudents.includes(item)
                  )
                )
              } else {
                setTemporarySelectedItems([...temporaryStudents])
                setSelectedItems([
                  ...selectedItems,
                  ...temporaryStudents.filter(
                    (item) => !selectedItems.includes(item)
                  ),
                ])
              }
            },
            indeterminate:
              temporaryStudents.length === originalStudents.length
                ? originalStudents?.length !== selectedItems.length &&
                  !!selectedItems.length
                : temporaryStudents.length !== temporarySelectedItems.length &&
                  !!temporarySelectedItems.length,
          }}
          sortingDirection={sorting}
          options={sortingOptions}
          sortingActive={!!sortBy}
          defaultRender={sortBy}
          sortingText={t(getSortingTextSecond(sortBy, sortingTextMap))}
        />
        {isLoading && <TableSkeleton />}
        {isError && <ErrorText />}
        {!isLoading && showNoMatches && (
          <NoMatchesForSearch search={searchFilter} />
        )}
        {!isLoading && !searchFilter.length && !temporaryStudents?.length && (
          <NoElementsMessage
            boxStyle={{ py: { mobile: "16px", tablet: "62px" } }}
            Icon={<SvgPhoto />}
            title={t("students.youDoNotHaveAnyStudentsYet")}
            description={t("students.addStudentsManuallyOrHaveStudents")}
          />
        )}
        <TransitionGroup component={null}>
          {sortedStudent?.map((item) => (
            <CSSTransition
              key={item.id}
              timeout={500}
              classNames="animation-fade"
              unmountOnExit
            >
              <Box
                display="flex"
                key={item.id}
                alignItems="center"
                sx={{
                  ":nth-of-type(even)": {
                    backgroundColor: theme.palette.mockup.primary99,
                  },
                }}
              >
                <FormControl sx={{ py: 2, pl: 1, pr: 3 }}>
                  <Checkbox
                    checked={getIsChecked(item)}
                    onChange={() => {
                      toggleItem(item)
                    }}
                    sx={{ width: "24px", height: "24px" }}
                  />
                </FormControl>
                <Typography>{`${item.first_name} ${item.last_name}`}</Typography>
              </Box>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </PrimaryTile>
    </Stack>
  )
}
export default Advance
