import { FC } from "react"

import { Typography } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { useTranslation } from "react-i18next"

interface IProps {
  open: boolean
  onClose: () => void
  onAccept: () => void
}

const DialogClose: FC<IProps> = ({ open, onClose, onAccept }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      titleText={t("students.areYoutSure")}
      open={open}
      onClose={onClose}
      actionAcceptText={t("close")}
      onActionButtonClick={onAccept}
    >
      <Typography variant="subtitle2" color="mockup.neutral10" fontWeight={500}>
        {t("students.enteredInformationWillBeLost")}
      </Typography>
    </Dialog>
  )
}

export default DialogClose
