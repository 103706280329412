import { FC, ReactNode } from "react"

import { ReactComponent as IconAnonymous } from "@common_assets/svg/anonymous.svg"
import { Box, BoxProps, Typography, useTheme } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"

interface IProps {
  Icon?: ReactNode
  title?: string
  description?: string
  bottomSlot?: ReactNode
  boxStyle?: BoxProps
}

const NoElementsMessage: FC<IProps> = ({
  Icon,
  title,
  description,
  boxStyle,
  bottomSlot,
}) => {
  const theme = useTheme()
  const isMobile = useIsBreakpointUp("tablet")
  return (
    <Box
      {...boxStyle}
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      rowGap="24px"
    >
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.mockup.primary99,
            borderRadius: "50%",
            width: "156px",
            height: "156px",
            mr: 3,
          }}
        >
          {Icon || <IconAnonymous />}
        </Box>
      )}
      <Box maxWidth="400px">
        {title && (
          <Typography
            variant="subtitle1"
            mb="8px"
            color="mockup.darkBlue"
            textAlign={{ tablet: "start", mobile: "center" }}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body2"
            fontWeight={500}
            color="mockup.neutral20"
            textAlign={{ tablet: "start", mobile: "center" }}
          >
            {description}
          </Typography>
        )}
        {bottomSlot}
      </Box>
    </Box>
  )
}

export default NoElementsMessage
