import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IPendingTransactionStudent } from "ts/interfaces/Transaction"

import { IStudentDashboardTransactionsArgs } from "./studentDashboardTransactionsPending.types"

export const useQueryStudentDashboardPendingTransactions = (
  args?: IStudentDashboardTransactionsArgs
) =>
  useQuery<AxiosResponse<IPendingTransactionStudent[]>, AxiosError>(
    ["studentDashboardTransactions"],
    async () =>
      axiosInstance(Endpoint.STUDENT_TRANSACTIONS_PENDING, {
        params: {
          skip: args?.page,
          limit: args?.limit,
        },
      }),
    args?.options
  )
