import { useState } from "react"

import { Box, Button, Skeleton, Stack } from "@mui/material"
import { useQueryStudentBalance } from "api/reactQuery/queries/studentBalance"
import { useQueryStudentStoreItems } from "api/reactQuery/queries/studentStoreItems"
import Dialog from "components/common/dialog/dialog"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useFilterItems } from "hooks/listing/filtering"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"

import Cart from "./cart"
import StoreClosed from "./storeClosed"
import StoreItemsGrid from "./storeItemsGrid"
import { Cart as TCart } from "./StoreStudent.types"
import { getNewCart } from "./StoreStudent.utils"
import TopSearchbar from "./topSearchbar"

const StoreStudent = () => {
  const { t } = useTranslation()
  const classId = useClassId()
  const [cart, setCart] = useState<TCart>({})
  const isDesktop = useIsBreakpointUp("desktop")

  const { isOpen, handleOpen, handleClose } = useDialog()

  const { data, isLoading, isError } = useQueryStudentStoreItems({
    classId: Number(classId),
    options: {
      refetchOnWindowFocus: true,
    },
  })

  const {
    data: studentBalanceData,
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
  } = useQueryStudentBalance()

  const { filteredData, searchFilter, setSearchFilter } = useFilterItems({
    data: data ? data.data.items : [],
    getFilterBy: (el) => el.name,
  })

  const cartTotalValue = Object.values(cart).reduce(
    (acc, curr) => acc + curr.quantity * curr.item.price,
    0
  )

  const cartItemsAmount = Object.values(cart).reduce(
    (acc, curr) => acc + curr.quantity,
    0
  )

  if (isLoading) return <Skeleton height="300px" width="100%" />

  if (isError && !isLoading) return <ErrorText />

  if (!data?.data.open) return <StoreClosed />

  return (
    <Box
      display={!isDesktop ? "block" : "flex"}
      flexDirection="row"
      gap={!isDesktop ? "0" : "24px"}
    >
      <Stack width={{ mobile: "100%", desktop: "60%" }}>
        <Stack gap="24px">
          <TopSearchbar
            onSubmit={(data) => setSearchFilter(data.search)}
            availableItemsCount={data.data.items.length}
          />
          <StoreItemsGrid
            onItemAddClick={(item) => {
              setCart(getNewCart(item, cart, "add"))
            }}
            storeItems={filteredData}
          >
            {!isLoading &&
              data?.data &&
              data?.data.items.length > 0 &&
              filteredData.length === 0 && (
                <NoMatchesForSearch search={searchFilter} />
              )}
          </StoreItemsGrid>
        </Stack>
      </Stack>
      <Stack width={isDesktop ? "40%" : "0"}>
        {isDesktop && (
          <Stack
            sx={{
              position: isDesktop ? "sticky" : "relative",
              top: "68px",
            }}
          >
            <Cart
              studentBalanceData={studentBalanceData?.data}
              isLoadingBalance={isLoadingBalance}
              isErrorBalance={isErrorBalance}
              cart={cart}
              onItemDeleteClick={(item) => {
                setCart(getNewCart(item, cart, "delete"))
              }}
              onPurchaseSuccess={() => {
                setCart({})
              }}
            />
          </Stack>
        )}
      </Stack>
      {!isDesktop && (
        <>
          {cartItemsAmount > 0 && (
            <Box
              position="sticky"
              left="0"
              bottom="0"
              width="100%"
              p="24px 18px"
              borderTop={({ palette }) =>
                `solid 2px ${palette.mockup.primary80}`
              }
              bgcolor={({ palette }) => palette.common.white}
              display="flex"
              justifyContent="center"
            >
              <Button
                onClick={handleOpen}
                sx={{ width: "100%", maxWidth: "400px", gap: "8px" }}
              >
                {t("storeStudent.cartAmountFor", { count: cartItemsAmount })}
                <Coin
                  amount={cartTotalValue}
                  valueTextProps={{
                    color: ({ palette }) => palette.common.white,
                  }}
                />
              </Button>
            </Box>
          )}
          <Dialog
            titleText={t("storeStudent.cart")}
            open={isOpen}
            onClose={handleClose}
            customActionButtonsSlot
            contentWrapperProps={{ px: { mobile: "24px", tablet: "32px" } }}
          >
            <Cart
              studentBalanceData={studentBalanceData?.data}
              isLoadingBalance={isLoadingBalance}
              isErrorBalance={isErrorBalance}
              cart={cart}
              onItemDeleteClick={(item) => {
                setCart(getNewCart(item, cart, "delete"))
              }}
              onPurchaseSuccess={() => {
                setCart({})
                handleClose()
              }}
              isDialogView={true}
            />
          </Dialog>
        </>
      )}
    </Box>
  )
}

export default StoreStudent
