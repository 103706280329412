export const studentProfile = {
  type: "Type",
  class: "Class",
  classAndTeacher: "Class & Teacher",
  teacher: "Teacher",
  title: "Title",
  value: "Value",
  date: "Date",
  withdraw: "Withdraw",
  deposit: "Deposit",
  balance: "Balance",
  jobs: "Jobs",
  manageStudentClasses: "Manage student's classes",
  manageStudentJobs: "Manage student's jobs",
  studentClass: "Student's class",
  deleteProfile: "Delete profile",
  personalInformation: "Personal Information",
  transactions: "Transactions",
  accounts: "Accounts",
  goals: "Goals",
  trends: "Trends",
  removePicture: "Remove Picture",
  updatePicture: "Upload Picture",
  accountDetails: "Account details",
  firstName: "First name",
  lastName: "Last name",
  userName: "User name",
  password: "Password",
  saveChanges: "Save changes",
  youNeedToAddStudentToClass: "You need to add student to class",
  somethingWentWrong: "Something went wrong, try again",
  updateSucceeded: "Update successful",
  nothingChange: "Nothing change",
  save: "Save",
  delete: "Delete",
  transactionHasBeenRemovedSuccessful:
    "Transaction has been removed successful",
  areYouSureYouWantToDeleteThisTransaction:
    "Are you sure you want to delete this transaction?",
  areYouSureYouWantToDeleteProfile:
    "Are you sure you want to delete profile {{firstName}} {{lastName}}?",
  introStudentClasses:
    "Here you can choose to which classes student should be assigned.",
  introStudentJobs:
    "Here you can choose to which jobs student should be hired.",
  job: "Job",
  positions: "Positions",
  salary: "Salary",
  studentProfileIsWasDeleted: "Student profile has been deleted",
  thisCantBeRestored: "This can't be restored",
  thisActionCannotBeUndone:
    "This action cannot be undone. All student data will be permanently deleted for you and all other teachers.",
  titleAsc: "Title ascending",
  titleDesc: "Title descending",
  classAsc: "Class ascending",
  classDesc: "Class descending",
  depositAsc: "Deposit ascending",
  depositDesc: "Deposit descending",
  transactionRemoveSuccessful: "Transaction remove successful",
  studentIsntAssignedToAnyClass:
    "{{firstName}} {{lastName}} isn't assigned to any class",
  removeFromClass: "Remove from class",
  exportData: "Export Data",
  dataHasBeenSuccessfullyExported: "Data has been successfully exported",
  studentJobs: "Student Jobs",
  studentTransactions: "Student Transactions",
  transactionHistory: "Transaction history",
  pendingTransactions: "Pending transactions",
  loadMore: "Load more",
  noRecordsMessage: "No records message",
  noMoreRecordsMessage: "No more records message",
  status: "Status",
  transactionType: "Transaction type",
  resetFilters: "Reset filters",
  purchase: "Purchase",
  fine: "Fine",
  bonus: "Bonus",
  expense: "Expense",
  transfer: "Transfer",
  pending: "Pending",
  granted: "Granted",
  selectAtLeastOneCheckbox: "Select at least one checkbox",
  nothingToDisplay: "Nothing to display",
  allTransactions: "All transactions",
}
