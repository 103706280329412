import { useEffect } from "react"

import { Box, Button, Typography } from "@mui/material"
import {
  useQueryClasses,
  useQueryClassesById,
} from "api/reactQuery/queries/classes"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useParams } from "react-router"
import { useNavigate } from "react-router-dom"

import { useAppSelector } from "../../hooks/store"
import { selectUser } from "../../store/auth/auth.selectors"
import { isSchoolPlan } from "../../utils/roleCheck"
import { RoutePath } from "../Route.types"

const ProtectedRoute = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)

  const { data } = useQueryClasses()
  const { isSuccess } = useQueryClassesById({
    id: Number(params.classId),
    options: {
      enabled: !!params.classId,
    },
  })

  const { isOpen, handleOpen } = useDialog()

  useEffect(() => {
    if (!!data?.data && userInfo && isSchoolPlan(userInfo) && isSuccess) {
      if (!data?.data.some((el) => el.id === Number(params.classId))) {
        handleOpen()
      }
    }
  }, [data, userInfo, isSuccess])

  if (!!data?.data && userInfo && isSchoolPlan(userInfo)) {
    if (
      data?.data.some((el) => el.id === Number(params.classId)) ||
      location.pathname === RoutePath.HOMEPAGE ||
      location.pathname === RoutePath.ALL_TRANSACTIONS
    ) {
      return <Outlet />
    } else {
      return (
        <>
          <div style={{ filter: isSuccess ? "blur(5px)" : "blur(0px)" }}>
            <Outlet />
            <Dialog
              titleText={t("layout.noAdmission")}
              open={isOpen}
              onIconClose={false}
              disableEscapeKeyDown
              actionAcceptText="Go back"
              customActionButtonsSlot={
                <Box
                  gap="10px"
                  display="flex"
                  justifyContent="flex-end"
                  m="16px 32px 16px 0 "
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(RoutePath.HOMEPAGE, { replace: true })
                    }
                  >
                    {t("layout.goToDashboard")}
                  </Button>
                </Box>
              }
            >
              <Typography variant="subtitle1">
                {t("layout.youAreNotPermittedToEnterThisClass")}
              </Typography>
            </Dialog>
          </div>
        </>
      )
    }
  }
  return <Outlet />
}

export default ProtectedRoute
