import axiosInstance from "api/api"
import { Endpoint } from "api/enpoints.types"
import { IGetStudentGoalsArgs } from "api/reactQuery/queries/studentGoals.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"
import { IStudentGoals } from "ts/interfaces/Student"

export const useQueryStudentGoals = ({
  student_id,
  options,
}: IGetStudentGoalsArgs) =>
  useQuery<AxiosResponse<IStudentGoals[]>, AxiosError>(
    ["studentGoals", student_id],
    async () =>
      axiosInstance(Endpoint.STUDENTS_ID_GOALS.replace("ID", `${student_id}`)),
    options
  )
