import { useEffect, useState } from "react"

import { Grid, Skeleton } from "@mui/material"
import { useQueryStudentClasses } from "api/reactQuery/queries/studentClasses"
import { useQueryGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import { useParams } from "react-router"

import ErrorText from "../../common/error/errorText"
import JobsClosed from "./JobsClosed"
import JobsPanel from "./jobsPanel"
import WelcomeSite from "./welcomeSite"

const JobsBoardStudent = () => {
  const [jobsState, setJobsState] = useState<IGetStudentPossibleJobsInClass[]>(
    []
  )
  const params = useParams()

  const {
    data,
    isLoading: isLoadingClass,
    isError: isErrorClass,
  } = useQueryStudentClasses()

  const { data: jobsInClass, isLoading } =
    useQueryGetStudentPossibleJobsInClass({
      classId: Number(params.classId),
      options: {
        enabled: !!params.classId,
      },
    })

  useEffect(() => {
    setJobsState(jobsInClass?.data || [])
  }, [jobsInClass?.data])

  const specifyClass = data?.data.find((el) => el.id === Number(params.classId))

  if (isLoadingClass) return <Skeleton height="300px" width="100%" />

  if (isErrorClass && !isLoadingClass) return <ErrorText />

  if (!specifyClass?.is_open_for_job_applications) return <JobsClosed />

  return (
    <Grid
      container
      sx={{
        height: "100%",
      }}
    >
      {jobsInClass?.data.length === 0 ? (
        <WelcomeSite />
      ) : (
        <JobsPanel jobsState={jobsState} isLoading={isLoading} />
      )}
    </Grid>
  )
}

export default JobsBoardStudent
