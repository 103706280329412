import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  IChartArgs,
  IResponseChart,
  IResponseSavingsCharts,
  ISavingsCharts,
} from "./chart.types"

export const useQueryCharts = (args?: IChartArgs) =>
  useQuery<AxiosResponse<IResponseChart>, AxiosError>(
    ["studentChart"],
    async () => {
      return axiosInstance(Endpoint.STUDENT_BALANCE_CHARTS, {
        params: {
          start_date: args?.start_date,
          end_date: args?.end_date,
        },
      })
    },
    { ...args?.options }
  )

export const useQuerySavingsCharts = (args?: ISavingsCharts) =>
  useQuery<AxiosResponse<IResponseSavingsCharts>, AxiosError>(
    ["savingsCharts", args?.savings_account_id],
    async () => {
      return axiosInstance(
        Endpoint.STUDENT_SAVINGS_CHARTS_ID.replace(
          "ID",
          `${args?.savings_account_id}`
        ),
        {
          params: {
            start_date: args?.start_date,
            end_date: args?.end_date,
          },
        }
      )
    },
    { ...args?.options }
  )
