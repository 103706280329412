import { Grid, Stack } from "@mui/material"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"
import { isStudent } from "utils/roleCheck"

import MyJobs from "./myJobs"
import PendingTransactions from "./pendingTransactions"
import SavingSnapshot from "./savingsSnapshot"
import WelcomeStudent from "./welcomeStudent"

const DashboardStudent = () => {
  const userInfo = useAppSelector(selectUser)
  const hasSavingAccount =
    userInfo && isStudent(userInfo.user) && userInfo.user.has_savings_accounts

  return (
    <Grid container spacing={3}>
      <Grid item mobile={12} desktop={7}>
        <Stack rowGap={3}>
          <WelcomeStudent />
          <PendingTransactions />
        </Stack>
      </Grid>
      <Grid item mobile={12} desktop={5}>
        {hasSavingAccount && <SavingSnapshot />}
        <MyJobs hasSavingAccount={hasSavingAccount} />
      </Grid>
    </Grid>
  )
}

export default DashboardStudent
