import { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as Anonymous } from "@common_assets/svg/anonymous.svg"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useMutationPostCreateJobs } from "api/reactQuery/mutations/jobs"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import Dialog from "components/common/dialog/dialog"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { getErrorMessageFromTab } from "utils/api"

import AddJob from "../addJob"
import AddSuggestedJobs from "../addSuggestedJobs"
import {
  elementSuggestedJobs,
  msSuggestedJobs,
} from "../addSuggestedJobs/suggestedJobs"

interface IProps {
  setRichEditorResponsibilities: Dispatch<React.SetStateAction<string>>
  richEditorResponsibilities: string
  richEditorQualifications: string
  setRichEditorQualifications: Dispatch<React.SetStateAction<string>>
}

const NoJobs: FC<IProps> = ({
  richEditorResponsibilities,
  setRichEditorResponsibilities,
  setRichEditorQualifications,
  richEditorQualifications,
}) => {
  const params = useParams()
  const theme = useTheme()
  const isMobile = useIsBreakpointUp("tablet")
  const betweenTwoSides = useMediaQuery(theme.breakpoints.between(1024, 1224))
  const [checked, setChecked] = useState<ISuggestedCreateJobs[]>([])
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const [errorSuggested, setErrorSuggested] = useState("")

  useEffect(() => {
    if (checked.length > 0) {
      setErrorSuggested("")
    }
  }, [checked])

  const handleSuggestedJobs = (elements: ISuggestedCreateJobs) => {
    const currentPosition = checked.findIndex((el) => el.id === elements.id)
    const newChecked = [...checked]

    if (currentPosition === -1) {
      newChecked.push(elements)
      elements.check = true
    } else {
      newChecked.splice(currentPosition, 1)
      elements.check = false
    }
    setChecked(newChecked)
  }

  const {
    isOpen: isDialogOpenSuggested,
    handleOpen: handleOpenDialogSuggested,
    handleClose: handleCloseDialogSuggested,
  } = useDialog()

  const {
    isOpen: isDialogOpenJob,
    handleOpen: handleOpenDialogJob,
    handleClose: handleCloseDialogJob,
  } = useDialog()

  const {
    isOpen: isDialogCloseSuggestedJobs,
    handleOpen: handleOpenDialogCloseSuggestedJobs,
    handleClose: handleCloseDialogCloseSuggestedJobs,
  } = useDialog()

  const { mutate: createJob } = useMutationPostCreateJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("jobs")
        showSnackbar({
          title: t("jobs.succeedCreateJob"),
          variant: "success",
        })
        setRichEditorResponsibilities("")
        setRichEditorQualifications("")
        handleCloseDialogSuggested()
        setChecked([])
        elementSuggestedJobs.forEach((el) => (el.check = false))
        msSuggestedJobs.forEach((el) => (el.check = false))
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { data: classData } = useQueryClassesById({
    id: Number(params.classId),
    options: {
      enabled: !!params.classId,
    },
  })

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign={
        betweenTwoSides ? "center" : { tablet: "start", mobile: "center" }
      }
      py={{ tablet: "62px", mobile: "16px" }}
    >
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.mockup.primary95,
            borderRadius: "50%",
            minWidth: "156px",
            height: "156px",
            mr: 3,
          }}
        >
          <Anonymous />
        </Box>
      )}
      <Box maxWidth="400px">
        <Box>
          <Typography
            sx={{ mb: 1 }}
            color="mockup.darkBlue"
            variant="subtitle1"
          >
            {t("jobs.youDoNotHaveAnyJobsYet")}
          </Typography>
          <Typography
            color="mockup.neutral20"
            variant="body2"
            mb={3}
            fontWeight="500"
          >
            {t("jobs.instructionJobs")}
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            justifyContent: { tablet: "start", mobile: "center" },
            flexDirection: betweenTwoSides
              ? { tablet: "column", mobile: "row" }
              : { tablet: "row", mobile: "column" },
            width: "100%",
            gap: 2,
          }}
        >
          <Button
            onClick={() => {
              handleOpenDialogJob()
              mixpanel.track("add_class_settings", {
                action: "add_job",
              })
            }}
          >
            {t("jobs.addJob")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleOpenDialogSuggested()
              mixpanel.track("add_class_settings", {
                action: "add_suggested_job",
              })
            }}
          >
            {t("jobs.addSuggestedJobs")}
          </Button>
          <Dialog
            titleText={t("jobs.addSuggestedJobs")}
            open={isDialogOpenSuggested}
            onClose={(event, reason) => {
              if (reason === "backdropClick") {
                handleOpenDialogCloseSuggestedJobs()
              }
              mixpanel.track("add_class_settings", {
                action: "cancel_add_suggested_job",
              })
            }}
            desktopMaxWidth="1040px"
            tabletMaxWidth="867px"
            actionAcceptText={t("jobs.addSelectedJobs", {
              count: checked.length,
            })}
            onActionButtonClick={() => {
              if (checked.length === 0) {
                setErrorSuggested(t("jobs.atleastOneCheckbox"))
                return
              }

              if (
                !!checked.find(
                  (el) => (el.title && el.title.length <= 3) || !el.title
                )
              ) {
                showSnackbar({
                  title: t(
                    "jobs.selectedItemsShouldHaveTitleOfAtLeast3Characters"
                  ),
                  variant: "error",
                })
                return
              }

              if (!!checked.find((el) => el.salary === 0)) {
                showSnackbar({
                  title: t("jobs.amountOfSelectedItemsHaveToBePositiveNumber"),
                  variant: "error",
                })
                return
              }

              createJob({
                classId: Number(params.classId),
                data: checked,
              })
            }}
          >
            <>
              <Typography variant="body2" color="mockup.neutral10">
                {t("jobs.clickOnTheTextToEditTheSuggestedSettings")}
              </Typography>
              <AddSuggestedJobs
                paymentClass={classData?.data}
                handleSuggestedJobs={handleSuggestedJobs}
                setChecked={setChecked}
                checked={checked}
                errorSuggested={errorSuggested}
                handleOpenDialogCloseSuggestedJobs={
                  handleOpenDialogCloseSuggestedJobs
                }
              />
            </>
          </Dialog>
          <Dialog
            titleText={t("jobs.addJob")}
            open={isDialogOpenJob}
            onClose={() => {
              setRichEditorResponsibilities("")
              setRichEditorQualifications("")
              handleCloseDialogJob()
              mixpanel.track("add_class_settings", {
                action: "cancel_add_job",
              })
            }}
            desktopMaxWidth="1040px"
            tabletMaxWidth="534px"
            actionAcceptText={t("jobs.addJob")}
            actionAcceptButtonProps={{ type: "submit", form: "addNewJob" }}
          >
            <AddJob
              richEditorQualifications={richEditorQualifications}
              setRichEditorQualifications={setRichEditorQualifications}
              setRichEditorResponsibilities={setRichEditorResponsibilities}
              richEditorResponsibilities={richEditorResponsibilities}
              id="addNewJob"
              createJob={createJob}
            />
          </Dialog>
          <Dialog
            open={isDialogCloseSuggestedJobs}
            onClose={handleCloseDialogCloseSuggestedJobs}
            titleText={t("jobs.areYouSureYouWantToExit")}
            actionAcceptText={t("jobs.accept")}
            onActionButtonClick={() => {
              setChecked([])
              setErrorSuggested("")
              elementSuggestedJobs.forEach((el) => (el.check = false))
              msSuggestedJobs.forEach((el) => (el.check = false))
              handleCloseDialogSuggested()
              handleCloseDialogCloseSuggestedJobs()
            }}
          >
            <Typography>{t("jobs.noChangesWillBeSaved")}</Typography>
          </Dialog>
        </Box>
      </Box>
    </Box>
  )
}

export default NoJobs
