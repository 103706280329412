import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import { IDowngradeArgs, IResponseDowngrade } from "./downgrade.types"

export const useMutationDowngrade = (args?: IDowngradeArgs) =>
  useMutation<AxiosResponse<IResponseDowngrade>, AxiosError, null>(async () => {
    return await axiosInstance.post(Endpoint.TEACHER_DOWNGRADE)
  }, args?.options)
