import { FC } from "react"

import { useMutationPatchClassesTransactionItems } from "api/reactQuery/mutations/classesTransactionItems"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"
import { getErrorMessage } from "utils/api"

import { getSetupClassTranslation } from "../../SetupClassTeacher.utils"
import TransactionSetupDialogForm from "../transactionSetupDialogForm"

interface IProps {
  item: ITransactionItem | null
  variant: TransactionTypeShort
  isOpen: boolean
  handleClose: () => void
  handleOpenDelete: () => void
}

const DialogEditItem: FC<IProps> = ({
  item,
  variant,
  isOpen,
  handleClose,
  handleOpenDelete,
}) => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)

  const classId = useClassId()

  const { isLoading: isLoadingPatch, mutateAsync: patchTransactionItem } =
    useMutationPatchClassesTransactionItems({
      options: {
        onSuccess: (data) => {
          queryClient.invalidateQueries(`classes${variant}`)
          showSnackbar({
            title: t(tKey.itemHasBeenEdited, { name: data.data.title }),
          })
          handleClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  if (!item) return null

  return (
    <TransactionSetupDialogForm
      open={isOpen}
      variant={variant}
      onClose={handleClose}
      title={t(tKey.editItem)}
      titleFieldLabel={t(tKey.itemTitle)}
      isLoading={isLoadingPatch}
      initialState={{ amount: item.amount, title: item.title }}
      onSubmit={(data) =>
        patchTransactionItem({
          classId: Number(classId),
          itemId: item.id,
          variant,
          data: {
            title: data.title,
            amount: Number(data.amount),
          },
        })
      }
      handleDeleteClick={handleOpenDelete}
    />
  )
}

export default DialogEditItem
