import { passwordStudentSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  students: [],
}

export const validationSchema = yup.object({
  students: yup.array(
    yup.object({
      firstName: requiredStringSchema.min(2),
      lastName: requiredStringSchema.min(2),
      userName: requiredStringSchema.min(3),
      password: passwordStudentSchema,
    })
  ),
})

export const emptyStudentValue = {
  firstName: "",
  lastName: "",
  userName: "",
  password: "",
}
