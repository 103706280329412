import { ReactComponent as Logo } from "@brand_assets/svg/logo.svg"
import { Box, Grid } from "@mui/material"
import LoginAsStudent from "components/module/loginAsStudent"
import LoginAsTeacher from "components/module/loginAsTeacher"
import SignAsStudent from "components/module/signAsStudent"
import Finalization from "components/module/signAsStudent/finalization"
import SignByEmail from "components/module/signAsStudent/signByEmail"
import SignAsTeacher from "components/module/signAsTeacher"
import AddSchoolForm from "components/module/signAsTeacher/addSchool/AddSchoolForm"
import FindSchoolForm from "components/module/signAsTeacher/findSchool"
import PersonalForm from "components/module/signAsTeacher/personalForm"
import { useDirectLink } from "hooks/directLink"

import BackgroundImageLeft from "../backgroundImageLeft"

interface IProps {
  desktopView: boolean
  activePage: number
  registerType: "signUp" | "login"
}

const RegisterLayout = ({ desktopView, activePage, registerType }: IProps) => {
  const location = window.location.origin

  useDirectLink()

  return (
    <Grid container minHeight="100vh" width="100%">
      {desktopView && <BackgroundImageLeft />}
      <Grid
        item
        width={{ desktop: "", mobile: "100%" }}
        px={{ desktop: 0, mobile: "10px" }}
        alignItems="center"
        justifyContent={{ desktop: "center", mobile: "stretch" }}
        flexDirection={{ desktop: "row", mobile: "column" }}
        display="flex"
        textAlign="center"
        flex="1 0 auto"
      >
        <Box maxWidth="100%" width="426px">
          {!desktopView && (
            <Box textAlign="left" my={5} width="115px">
              <Logo />
            </Box>
          )}
          {registerType === "signUp" &&
            location.includes("localhost") &&
            process.env.REACT_APP_USER === "teacher" &&
            ((activePage === 1 && <SignAsTeacher />) ||
              (activePage === 2 && <PersonalForm />) ||
              (activePage === 3 && <FindSchoolForm />) ||
              (activePage === 4 && <AddSchoolForm />))}

          {registerType === "signUp" &&
            location.includes("localhost") &&
            process.env.REACT_APP_USER === "student" &&
            ((activePage === 1 && <SignAsStudent />) ||
              (activePage === 2 && <SignByEmail />) ||
              (activePage === 3 && <Finalization />))}

          {registerType === "signUp" &&
            location.includes("teacher") &&
            ((activePage === 1 && <SignAsTeacher />) ||
              (activePage === 2 && <PersonalForm />) ||
              (activePage === 3 && <FindSchoolForm />) ||
              (activePage === 4 && <AddSchoolForm />))}

          {registerType === "signUp" &&
            location.includes("student") &&
            ((activePage === 1 && <SignAsStudent />) ||
              (activePage === 2 && <SignByEmail />) ||
              (activePage === 3 && <Finalization />))}

          {registerType === "login" &&
            location.includes("localhost") &&
            process.env.REACT_APP_USER === "teacher" && <LoginAsTeacher />}

          {registerType === "login" &&
            location.includes("localhost") &&
            process.env.REACT_APP_USER === "student" && <LoginAsStudent />}

          {registerType === "login" && location.includes("teacher") && (
            <LoginAsTeacher />
          )}

          {registerType === "login" && location.includes("student") && (
            <LoginAsStudent />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default RegisterLayout
