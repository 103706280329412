import ClassStudentsTeacher from "components/module/classStudentsTeacher"

const TeacherStudentsLayout = () => {
  return (
    <>
      <ClassStudentsTeacher />
    </>
  )
}

export default TeacherStudentsLayout
