import { FC } from "react"

import { Avatar, Stack, Typography } from "@mui/material"
import Coin from "components/common/icon/coin"
import { Row, Cell } from "components/common/table/basicTable"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { ExtraSmallAvatar } from "styles/common/avatar"
import { IStudentOverview } from "ts/interfaces/Student"

interface IProps {
  student: IStudentOverview
  index: number
}

const TableBodyRow: FC<IProps> = ({ student, index }) => {
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Row>
      <Cell justifyContent="center">
        <Typography variant="body2" color="mockup.neutral10" fontWeight={500}>
          {index + 1}.
        </Typography>
      </Cell>
      <Cell>
        <Stack direction="row" columnGap="8px" alignItems="center">
          {isTablet ? (
            <Avatar src={student.avatar_url} alt="user avatar" />
          ) : (
            <ExtraSmallAvatar src={student.avatar_url} alt="user avatar" />
          )}
          <Typography variant="body1" color="mockup.neutral10">
            {`${student.first_name} ${student.last_name}`}
          </Typography>
        </Stack>
      </Cell>
      <Cell justifyContent="center">
        <Coin amount={`${student.value}`} />
      </Cell>
    </Row>
  )
}

export default TableBodyRow
