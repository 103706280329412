import { useState } from "react"

export const useSelectedItems = <
  T extends {
    id: number
  }
>(
  data: T[]
) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([])
  const [excludedItems, setExcludedItems] = useState<T[]>([])

  const getIsChecked = (el: T) => {
    return !!selectedItems.find((item) => item.id === el.id)
  }

  const toggleAll = () => {
    if (selectedItems.length === data.length) {
      setSelectedItems([])
    } else {
      setSelectedItems([...data])
      setExcludedItems([])
    }
  }

  const toggleItem = (el: T) => {
    const isChecked = getIsChecked(el)
    if (isChecked) {
      setSelectedItems([...selectedItems].filter((item) => item.id !== el.id))
    } else {
      setSelectedItems([...selectedItems, el])
    }
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const clearExcludedItems = () => {
    setExcludedItems([])
  }

  const toggleExcludedItems = (item: T) => {
    const isChecked = getIsChecked(item)
    if (!isChecked) {
      setExcludedItems(
        [...excludedItems].filter((student) => student.id !== item.id)
      )
    } else {
      setExcludedItems([...excludedItems, item])
    }
  }

  return {
    getIsChecked,
    toggleAll,
    toggleItem,
    clearSelectedItems,
    selectedItems,
    setSelectedItems,
    excludedItems,
    toggleExcludedItems,
    clearExcludedItems,
    setExcludedItems,
  }
}
