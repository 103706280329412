import { FC } from "react"

import { ReactComponent as IconBlackBoard } from "@common_assets/svg/blackboard.svg"
import { Button, Stack, Typography, Box } from "@mui/material"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"

interface IProps {
  onClick: () => void
}

const NoCoteachers: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Stack display="flex" height="470px">
      <Box maxWidth="400px" margin="auto" textAlign="center">
        <IconBlackBoard />
        <Typography variant="subtitle1" color="mockup.darkBlue" mt={3}>
          {t("coteachers.youDontHaveAnyCoteachersYet")}
        </Typography>
        <Typography
          fontWeight="500"
          color="mockup.neutral20"
          variant="body2"
          mt={1}
          mb={3}
          lineHeight="18px"
        >
          {t(
            "coteachers.shareThisClassWithAnotherTeacherByAddingThemAsCoTeacher"
          )}
        </Typography>
        <Button
          onClick={() => {
            onClick()
            mixpanel.track("add_coteacher", {
              action: "click",
            })
          }}
        >
          {t("coteachers.addCoTeacher")}
        </Button>
      </Box>
    </Stack>
  )
}

export default NoCoteachers
