import i18n, { Resource } from "i18next"
import { initReactI18next } from "react-i18next"

import en from "./en/en"

const resources: Resource = {
  en,
}

i18n.use(initReactI18next).init({
  resources,
  defaultNS: "defaultTranslations",
  lng: "en",
  keySeparator: false,
  nsSeparator: ".",
})

export default i18n
