import React from "react"

import { Box, useTheme } from "@mui/material"

import { IconTypes } from "../updateInputNumber/UpdateInput.types"

interface IProps {
  Icon: React.ReactElement
  type: IconTypes
  isOnMouse: { cross: boolean; check: boolean }
  setIsOnMouse: React.Dispatch<
    React.SetStateAction<{ cross: boolean; check: boolean }>
  >
}

const SelectIcon = ({ Icon, type, isOnMouse, setIsOnMouse }: IProps) => {
  const theme = useTheme()
  const icons = React.cloneElement(Icon, {
    style:
      type === IconTypes.CROSS
        ? {
            color: isOnMouse.cross ? theme.palette.mockup.error50 : undefined,
          }
        : {
            color: isOnMouse.check ? theme.palette.mockup.success50 : undefined,
          },
  })

  return (
    <Box
      onMouseEnter={() => {
        if (type === IconTypes.CROSS) {
          setIsOnMouse((prev) => ({ ...prev, cross: true }))
        } else {
          setIsOnMouse((prev) => ({ ...prev, check: true }))
        }
      }}
      onMouseLeave={() => {
        if (type === IconTypes.CROSS) {
          setIsOnMouse((prev) => ({ ...prev, cross: false }))
        } else {
          setIsOnMouse((prev) => ({ ...prev, check: false }))
        }
      }}
    >
      {icons}
    </Box>
  )
}

export default SelectIcon
