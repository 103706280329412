import { FC } from "react"

import {
  Tooltip,
  Typography,
  styled,
  TypographyProps,
  TooltipProps,
} from "@mui/material"

const TypographyDots = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontSize: "14px",
  fontWeight: "500",
  color: theme.palette.mockup.neutral10,
}))

interface IProps {
  children: React.ReactNode
  title: string
  width?: string
  color?: string
  size?: string
  customProps?: TypographyProps
  placement?: TooltipProps["placement"]
}

const ToolTipDots: FC<IProps> = ({
  children,
  color,
  size,
  width,
  title,
  customProps,
  placement,
}) => {
  return (
    <Tooltip title={title} disableFocusListener placement={placement}>
      <TypographyDots
        color={color}
        fontSize={size}
        width={width}
        {...customProps}
      >
        {children}
      </TypographyDots>
    </Tooltip>
  )
}

export default ToolTipDots
