import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IStudentCandidate } from "ts/interfaces/Student"

import { IGetClassesCandidatesArgs } from "./classesCandidates.types"

export const useQueryClassesCandidates = ({
  classId,
  filter,
  options,
}: IGetClassesCandidatesArgs) =>
  useQuery<AxiosResponse<IStudentCandidate[]>, AxiosError>(
    ["classesCandidates", classId, filter],
    async () =>
      axiosInstance(
        Endpoint.CLASSES_ID_CANDIDATES.replace("ID", `${classId}`),
        {
          params: {
            filter,
          },
        }
      ),
    options
  )
