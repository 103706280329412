import { useState } from "react"

import { Box, Button, Grid, Typography } from "@mui/material"
import { useMutationCoteachersRemove } from "api/reactQuery/mutations/coteachers"
import { useQueryCoteachers } from "api/reactQuery/queries/coteachers"
import {
  IResponseCoteachers,
  IResponseCoteachersPossible,
} from "api/reactQuery/queries/coteachers.types"
import Dialog from "components/common/dialog/dialog"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { getErrorMessageFromTab } from "utils/api"

import AutoCompleteAddTeacher from "./autocompleteAddTeacher"
import DialogAddTeacher from "./dialogAddTeacher"
import GridViewCoteachers from "./gridViewCoteachers"
import NoCoTeachers from "./noCoteachers"

const Coteachers = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const params = useParams()
  const { classId } = params

  const [teacherFullName, setTeacherFullName] =
    useState<IResponseCoteachers | null>(null)
  const [possibleTeacherInfo, setPossibleTeacherInfo] = useState<
    IResponseCoteachersPossible[]
  >([])

  const {
    isOpen: isOpenSchoolPlan,
    handleOpen: handleOpenSchoolPlan,
    handleClose: handleCloseSchoolPlan,
  } = useDialog()

  const {
    isOpen: isOpenRemoveTeacher,
    handleOpen: handleOpenRemoveTeacher,
    handleClose: handleCloseRemoveTeacher,
  } = useDialog()

  const { data, isLoading } = useQueryCoteachers({
    classId: Number(classId),
  })

  const { mutate } = useMutationCoteachersRemove({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("coteachers")
        showSnackbar({
          title: t("coteachers.userHasBeenSuccessfullyRemovedFromClass", {
            firstname: teacherFullName?.first_name,
            lastname: teacherFullName?.last_name,
          }),
        })
        handleCloseRemoveTeacher()
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb="20px"
      >
        <Typography variant="h3" color="mockup.neutral10">
          {t("coteachers.coteachers")}
        </Typography>
        {isLoading ? (
          <StudentSkeleton height="50px" width="200px" />
        ) : (
          <Button
            onClick={() => {
              handleOpenSchoolPlan()
              mixpanel.track("add_coteacher", {
                action: "click",
              })
            }}
          >
            {t("coteachers.addCoTeacher")}
          </Button>
        )}
      </Box>
      <DialogAddTeacher
        open={isOpenSchoolPlan}
        onClose={() => {
          handleCloseSchoolPlan()
          setPossibleTeacherInfo([])
          mixpanel.track("add_coteacher", {
            action: "cancel",
          })
        }}
        form="schoolPlan"
      >
        <AutoCompleteAddTeacher
          id="schoolPlan"
          handleCloseAddTeacher={handleCloseSchoolPlan}
          statePossibleTeacherInfo={{
            possibleTeacherInfo,
            setPossibleTeacherInfo,
          }}
        />
      </DialogAddTeacher>
      <TransitionGroup component={null}>
        {data && !!data.data.length ? (
          <CSSTransition
            timeout={1500}
            classNames="animation-fade"
            unmountOnExit
          >
            <Grid container gap="10px">
              {data.data.map((teacher) => (
                <GridViewCoteachers
                  key={teacher.id}
                  teacher={teacher}
                  handleOpen={handleOpenRemoveTeacher}
                  setTeacherFullName={setTeacherFullName}
                />
              ))}
            </Grid>
          </CSSTransition>
        ) : (
          <>
            {isLoading ? (
              <StudentSkeleton height="300px" />
            ) : (
              <NoCoTeachers onClick={handleOpenSchoolPlan} />
            )}
          </>
        )}
      </TransitionGroup>
      <Dialog
        open={isOpenRemoveTeacher}
        onClose={handleCloseRemoveTeacher}
        actionAcceptText={t("coteachers.remove")}
        titleText={t(
          "coteachers.areYouSureYouWantToRemoveCoTeacherFromTheClass",
          {
            firstname: teacherFullName?.first_name,
            lastname: teacherFullName?.last_name,
          }
        )}
        onActionButtonClick={() =>
          mutate({
            classId: Number(classId),
            teacherId: Number(teacherFullName?.id),
          })
        }
      >
        <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
          {t(
            "coteachers.youWillOnlyRemoveATeacherFromThisClassAndYouCanStillAddThemToItLater"
          )}
        </Typography>
      </Dialog>
    </>
  )
}

export default Coteachers
