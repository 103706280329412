import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IGetStudentFinalize,
  IGetStudentFinalizeArgs,
  IGetStudentPossibleJobArgs,
  IResponseGetStudentPossibleJob,
  IResponseStudentClasses,
  IStudentClassesArgs,
} from "./students.types"

export const useQueryStudentsClasses = (args: IStudentClassesArgs) =>
  useQuery<AxiosResponse<IResponseStudentClasses[]>, AxiosError>(
    ["students", args.studentId],
    async () => {
      return axiosInstance(
        Endpoint.STUDENT_ID_CLASSES.replace("ID", `${args.studentId}`)
      )
    },
    { ...args?.options }
  )

export const useQueryGetStudentPossibleJob = (
  args: IGetStudentPossibleJobArgs
) =>
  useQuery<AxiosResponse<IResponseGetStudentPossibleJob[]>, AxiosError>(
    ["possibleJobs", args.classId, args.studentId],
    async () => {
      return axiosInstance(
        `${Endpoint.STUDENT_POSSIBLE_JOBS.replace("ID", `${args.studentId}`)}/${
          args.classId
        }`
      )
    },
    {
      ...args?.options,
    }
  )

export const useQueryGetStudentFinalize = (args?: IGetStudentFinalizeArgs) =>
  useQuery<AxiosResponse<IGetStudentFinalize>, AxiosError>(
    ["beforeFinalize"],
    async () => {
      return axiosInstance(Endpoint.STUDENTS_FINALIZE)
    },
    args?.options
  )
