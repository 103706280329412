import { CategoriesType } from "ts/enums/Class"

export interface IPropsApplySettings {
  id: number
  label: CategoriesType
  text: string
  checkbox: boolean
}

export const applySettingsDB: IPropsApplySettings[] = [
  {
    id: 1,
    label: CategoriesType.EXPENSES,
    text: "Expenses",
    checkbox: false,
  },
  {
    id: 2,
    label: CategoriesType.BONUSES,
    text: "Bonuses",
    checkbox: false,
  },
  {
    id: 3,
    label: CategoriesType.FINES,
    text: "Fines",
    checkbox: false,
  },
  {
    id: 4,
    label: CategoriesType.JOBS,
    text: "Jobs",
    checkbox: false,
  },
  {
    id: 5,
    label: CategoriesType.STORE_ITEMS,
    text: "Store items",
    checkbox: false,
  },
]
