import checkerImage from "@common_assets/image/png/checker.png"
import { Stack, styled } from "@mui/material"

export const StyledImageContainer = styled(Stack)(({ theme }) => ({
  height: "96px",
  width: "96px",
  borderRadius: "16px",
  flexShrink: 0,
  background: `url(${checkerImage})`,
  alignItems: "center",
  justifyContent: "center",
  position: "relative",

  [theme.breakpoints.up("tablet")]: {
    height: "100px",
    width: "100px",
  },
}))

export const StyledImage = styled("img")({
  height: "60px",
  width: "60px",
  border: "none",
})
