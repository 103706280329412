import { ReactComponent as IconAnonymousDashboard } from "@common_assets/svg/anonymousDashboard.svg"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import { useTranslation } from "react-i18next"

const NoStudents = () => {
  const { t } = useTranslation()
  return (
    <NoElementMessageWithoutBtn
      title={t("dashboard.youDoNotHaveAnyStudentsYet")}
      description={`${t("dashboard.headOnOverToYourClasses")} ✨!`}
      Icon={<IconAnonymousDashboard />}
    />
  )
}

export default NoStudents
