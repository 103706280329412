import { IUserMe, IUserStudent, IUserTeacher } from "ts/interfaces/User"

import { UserSchoolPlan } from "../../ts/enums/User"

export const isTeacher = (
  userData: IUserStudent | IUserTeacher
): userData is IUserTeacher => (userData as IUserTeacher).email !== undefined

export const isStudent = (
  userData: IUserStudent | IUserTeacher
): userData is IUserStudent =>
  (userData as IUserStudent).has_savings_accounts !== undefined

export const isLogin = (
  userData: IUserStudent | IUserTeacher
): userData is IUserStudent => {
  return (userData as IUserStudent).login !== undefined
}

export const isProPlan = (user: IUserMe): user is IUserMe => {
  return (user as IUserMe).user.school_plan === UserSchoolPlan.PRO
}

export const isSchoolPlan = (user: IUserMe): user is IUserMe => {
  return (user as IUserMe).user.school_plan === UserSchoolPlan.SCHOOL
}

export const isFreeDemoPlan = (user: IUserMe): user is IUserMe => {
  return (
    (user as IUserMe).user.school_plan === UserSchoolPlan.FREE ||
    (user as IUserMe).user.school_plan === UserSchoolPlan.DEMO
  )
}

export const hasStudentAccount = (student: IUserMe | null) => {
  return (
    student?.user &&
    isStudent(student.user) &&
    student.user.has_savings_accounts
  )
}
