import { styled, Tabs, Tab } from "@mui/material"

export const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    columnGap: "8px",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
})

export const StyledTab = styled(Tab)(({ theme }) => ({
  padding: "10px 32px",
  borderRadius: "8px 8px 0 0",
  background: theme.palette.mockup.primary95,
  border: `solid 1px ${theme.palette.mockup.primary95}`,
  lineHeight: "24px",
  fontSize: "16px",
  borderBottomColor: "transparent",

  "&.Mui-selected": {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
})) as typeof Tab
