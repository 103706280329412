import { FC } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Stack } from "@mui/material"
import { useMutationPostStudentClasses } from "api/reactQuery/mutations/studentClasses"
import Dialog from "components/common/dialog/dialog"
import PinField from "components/form/common/field/pinField"
import {
  pinFieldDefaultValues,
  pinFieldSchema,
} from "components/form/common/field/pinField/PinField.config"
import { useCustomSnackbar } from "hooks/snackbar"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"
import * as yup from "yup"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const AddClassDialog: FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const methods = useForm({
    defaultValues: {
      pin: pinFieldDefaultValues,
    },
    resolver: yupResolver(
      yup.object({
        pin: pinFieldSchema,
      })
    ),
  })

  const { mutate, isLoading } = useMutationPostStudentClasses({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["studentClasses"])
        showSnackbar({
          title: t("layout.classAddedSuccessfully"),
        })
        methods.reset()
        onClose()
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        methods.reset()
        onClose()
      }}
      isLoading={isLoading}
      titleText={t("layout.addClass")}
      onActionButtonClick={() => {
        methods.handleSubmit((data) => {
          mutate({
            invitation_code: Object.values(data.pin).join("").toUpperCase(),
          })
        })()
      }}
      contentWrapperProps={{
        p: { mobile: "24px", tablet: "32px" },
      }}
    >
      <FormProvider {...methods}>
        <Stack mb="24px" component="form">
          <PinField name="pin" label={t("layout.enterYourClassCode")} />
        </Stack>
      </FormProvider>
    </Dialog>
  )
}

export default AddClassDialog
