import React, { Dispatch } from "react"

import { IGetJobsResponse } from "api/reactQuery/queries/jobs.types"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

import { ICheckedPerson, IStudentsToChecked } from "../Jobs"
import SingleGrid from "./SingleGrid"

interface IJobsOffer {
  data: IGetJobsResponse[]
  students: IObjectStudentDetailed[]
  setRichEditorResponsibilities: Dispatch<React.SetStateAction<string>>
  richEditorResponsibilities: string
  setRichEditorQualifications: Dispatch<React.SetStateAction<string>>
  richEditorQualifications: string
  checkedPerson: ICheckedPerson[]
  setStudentsToChecked: Dispatch<React.SetStateAction<IStudentsToChecked[]>>
  setCheckedPerson: Dispatch<React.SetStateAction<ICheckedPerson[]>>
  handleChangeInView: (value: boolean) => void
  isRefetching: boolean
}

const jobsOffer = ({
  setRichEditorResponsibilities,
  richEditorResponsibilities,
  setRichEditorQualifications,
  richEditorQualifications,
  data,
  students,
  checkedPerson,
  setStudentsToChecked,
  setCheckedPerson,
  handleChangeInView,
  isRefetching,
}: IJobsOffer) => {
  return (
    <>
      {data
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((el) => (
          <SingleGrid
            isRefetching={isRefetching}
            handleChangeInView={handleChangeInView}
            richEditorQualifications={richEditorQualifications}
            setRichEditorQualifications={setRichEditorQualifications}
            richEditorResponsibilities={richEditorResponsibilities}
            setRichEditorResponsibilities={setRichEditorResponsibilities}
            key={el.id}
            jobsOffer={el}
            students={students}
            allOfClasses={data}
            checkedPerson={checkedPerson}
            setStudentsToChecked={setStudentsToChecked}
            setCheckedPerson={setCheckedPerson}
          />
        ))}
    </>
  )
}

export default jobsOffer
