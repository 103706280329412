import {
  transactionsDefaultValue,
  transactionsSchema,
} from "components/form/common/formPart/transactions/Transactions.config"
import { stringSchema } from "utils/yup"
import * as yup from "yup"

export const sendTransactionFormDefaultValues = {
  student: null,
  studentClass: "",
  transactions: transactionsDefaultValue,
}

export const sendTransactionFormSchema = yup.object({
  student: yup.object().typeError("validations.fieldIsRequired"),
  studentClass: stringSchema,
  transactions: transactionsSchema,
})
