import React, { useState, useRef } from "react"

import { ReactComponent as IconPlus } from "@common_assets/svg/plus.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { useMutationGoalsCreate } from "api/reactQuery/mutations/goals"
import {
  useQueryCompletedGoals,
  useQueryStudentGoals,
} from "api/reactQuery/queries/goals"
import Dialog from "components/common/dialog/dialog"
import BasicTable from "components/common/table/basicTable"
import { format } from "date-fns"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { selectAccount } from "store/savings/savings.selectors"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"

import Coin from "../../../common/icon/coin"
import CreateGoalsDialog from "../common/createGoalsDialog"
import {
  defaultValues,
  validationSchema,
} from "../common/createGoalsDialog/CreateGoalsDialog.utility"
import BannerGoal from "./bannerGoal"
import GridGoal from "./gridGoal"
import { GridGoalRef } from "./gridGoal/GridGoal"

const Goals = () => {
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()

  const [indexChecked, setIndexChecked] = useState<number | null>(null)
  const { isOpen, handleOpen, handleClose } = useDialog()

  const dialogRef = useRef<GridGoalRef>(null)
  const callFundsOpen = () => {
    if (dialogRef.current !== null) dialogRef.current.buttonFunds()
  }

  const isLoadingUpdateFunds =
    dialogRef.current !== null && dialogRef.current.isLoadingUpdateFunds

  const {
    isOpen: isOpenSpendMoney,
    handleOpen: handleOpenSpendMoney,
    handleClose: handleCloseSpendMoney,
  } = useDialog()

  const {
    isOpen: isOpenCompletedGoals,
    handleOpen: handleOpenCompletedGoals,
    handleClose: handleCloseCompletedGoals,
  } = useDialog()

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  })
  const dataClass = useAppSelector(selectAccount)

  const { data, isLoading } = useQueryStudentGoals({
    saving_account_id: dataClass?.id as number,
    options: {
      enabled: !!dataClass,
    },
  })

  const { mutate, isLoading: isLoadingCreateGoals } = useMutationGoalsCreate({
    savings_account_id: dataClass?.id as number,
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "New goal has been created successfully",
        })
        handleClose()
        methods.reset()
        setIndexChecked(null)
        queryClient.invalidateQueries("studentGoals")
        queryClient.invalidateQueries("studentSavings")
        handleOpenSpendMoney()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { data: dataCompletedGoals } = useQueryCompletedGoals()

  const handleActionCreateGoal = () => {
    methods.handleSubmit((data) => {
      mutate({ ...data, total_amount: Number(data.total_amount) })
    })()
  }

  if (isLoading) return <Skeleton width="100%" height="300px" />

  return (
    <Stack>
      <Accordion defaultExpanded>
        <AccordionSummary>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography>Goals</Typography>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                handleOpen()
              }}
              style={{ maxHeight: "44px" }}
              endIcon={<IconPlus />}
            >
              New Goal
            </Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme) => ({ backgroundColor: theme.palette.common.white })}
        >
          {!data?.data.length ? (
            <BannerGoal {...{ handleOpen }} />
          ) : (
            <>
              <Grid container spacing={2}>
                {data?.data
                  .sort(
                    (a, b) =>
                      new Date(a.created_at).getTime() -
                      new Date(b.created_at).getTime()
                  )
                  .map((goal) => (
                    <GridGoal
                      key={goal.id}
                      data={goal}
                      ref={dialogRef}
                      handleCloseSpendMoney={handleCloseSpendMoney}
                    />
                  ))}
              </Grid>
            </>
          )}
          <Button variant="text" onClick={handleOpenCompletedGoals}>
            See completed goals
          </Button>
        </AccordionDetails>
      </Accordion>
      <FormProvider {...methods}>
        <CreateGoalsDialog
          isOpen={isOpen}
          onKeyDown={(e) =>
            handleKeyDown(e, handleActionCreateGoal, isLoadingCreateGoals)
          }
          onClose={() => {
            handleClose()
            methods.reset()
            setIndexChecked(null)
          }}
          isLoading={isLoadingCreateGoals}
          indexChecked={indexChecked}
          onIndexChecked={(arg) => setIndexChecked(arg)}
          onActionButton={handleActionCreateGoal}
        />
      </FormProvider>
      <Dialog
        open={isOpenSpendMoney}
        actionAcceptButtonProps={{ disabled: isLoadingUpdateFunds }}
        onClose={handleCloseSpendMoney}
        titleText="Would you like to contribute money to this goal now?"
        actionAcceptText="Transfer"
        onActionButtonClick={callFundsOpen}
        onKeyDown={(e) => handleKeyDown(e, callFundsOpen, isLoadingUpdateFunds)}
      />
      <Dialog
        tabletMaxWidth="720px"
        open={isOpenCompletedGoals}
        onClose={handleCloseCompletedGoals}
        titleText="Completed goals"
        desktopMaxWidth="867px"
        actionAcceptText="Done"
        onActionButtonClick={handleCloseCompletedGoals}
      >
        <BasicTable
          head={
            <BasicTable.Row>
              <BasicTable.Cell>
                <BasicTable.HeadText>Title</BasicTable.HeadText>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <BasicTable.HeadText>Description</BasicTable.HeadText>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <BasicTable.HeadText>Start Date</BasicTable.HeadText>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <BasicTable.HeadText>End Date</BasicTable.HeadText>
              </BasicTable.Cell>
              <BasicTable.Cell>
                <BasicTable.HeadText>Goal</BasicTable.HeadText>
              </BasicTable.Cell>
            </BasicTable.Row>
          }
          body={
            <>
              {dataCompletedGoals?.data.map((goal) => (
                <React.Fragment key={goal.id}>
                  <BasicTable.Row>
                    <BasicTable.Cell>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="mockup.neutral10"
                      >
                        {goal.title}
                      </Typography>
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="mockup.neutral10"
                      >
                        {goal.description}
                      </Typography>
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="mockup.neutral10"
                      >
                        {format(new Date(goal.created_at), "MMM, dd,yyyy ")}
                      </Typography>
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="mockup.neutral10"
                      >
                        {format(new Date(goal.completed_at), "MMM, dd,yyyy ")}
                      </Typography>
                    </BasicTable.Cell>
                    <BasicTable.Cell>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="mockup.neutral10"
                      >
                        <Coin amount={goal.total_amount} />
                      </Typography>
                    </BasicTable.Cell>
                  </BasicTable.Row>
                </React.Fragment>
              ))}
            </>
          }
        />
      </Dialog>
    </Stack>
  )
}
export default Goals
