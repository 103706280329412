import React, { useCallback, useState } from "react"

import { ReactComponent as CheckIcon } from "@common_assets/svg/check.svg"
import { ReactComponent as CrossIcon } from "@common_assets/svg/cross.svg"
import { ReactComponent as EyeIcon } from "@common_assets/svg/pass_eye.svg"
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { size } from "utils/constants/size"

const ErrorStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mockup.error50,
  textAlign: "left",
  fontSize: "14px",
  fontWeight: "500",
}))

interface IProps {
  error: string
  state: { password: string; token: string }
  setState: React.Dispatch<
    React.SetStateAction<{ password: string; token: string }>
  >
  linkToResetPassword: boolean
  isLoading: boolean
  handleClick: () => void
}

const First = ({
  error,
  state,
  setState,
  linkToResetPassword,
  isLoading,
  handleClick,
}: IProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [showPass, setShowPass] = useState(false)
  const isMobile = useMediaQuery(size.MAX_WIDTH_420)

  const showSign = useCallback(() => {
    if (!!error || !!state.password.length) {
      return !!error ? (
        <CrossIcon
          style={{
            color: theme.palette.mockup.error50,
          }}
        />
      ) : (
        <CheckIcon
          style={{
            color: theme.palette.mockup.success50,
          }}
        />
      )
    }
  }, [error])

  return (
    <>
      <Typography
        variant="h2"
        color="mockup.neutral50"
        sx={{ textAlign: "center" }}
      >
        {t("noAuthFlow.createANewPassword")}
      </Typography>
      <Box sx={{ position: "relative" }}>
        <TextField
          error={Boolean(error)}
          sx={{ mt: 4 }}
          value={state.password}
          onChange={(e) =>
            setState((prev) => ({ ...prev, password: e.target.value }))
          }
          name="password"
          type={showPass ? "text" : "password"}
          fullWidth
          label={t("noAuthFlow.password")}
          InputProps={{
            endAdornment: (
              <>
                {showSign()}
                <InputAdornment
                  sx={[
                    { cursor: "pointer", color: "primary.main" },
                    (theme) => ({
                      "&:hover": {
                        color: theme.palette.mockup.primary80,
                      },
                    }),
                  ]}
                  position={"start"}
                  onClick={() => setShowPass(!showPass)}
                >
                  <EyeIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
        {error && <ErrorStyle>{error}</ErrorStyle>}
        {linkToResetPassword && (
          <Typography
            sx={{
              textAlign: "left",
            }}
          >
            <Trans
              i18nKey="noAuthFlow.linkToResetPassword"
              components={{
                Link: <Link to={{ pathname: RoutePath.RESET_PASSWORD }}></Link>,
              }}
            />
          </Typography>
        )}
      </Box>
      <Button
        disabled={isLoading}
        fullWidth
        onClick={handleClick}
        sx={{ mt: isMobile ? 4 : 4 }}
      >
        {isLoading && (
          <CircularProgress
            size="18px"
            sx={{
              color: "common.white",
            }}
          />
        )}{" "}
        {t("noAuthFlow.createNewPassword")}
      </Button>
    </>
  )
}

export default First
