import React, { FC, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material"
import { useMutationStudentUpdate } from "api/reactQuery/mutations/students"
import { useQueryClassesStudents } from "api/reactQuery/queries/classesStudents"
import ArrowButton from "components/common/button/arrowButton"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import {
  selectClassList,
  selectIsNextPageOnStudent,
  selectPage,
  selectParamsClassStudentList,
} from "store/utility/utility.selectors"
import {
  setClassList,
  setIsNextPage,
  setPage,
  setProfileStudentNextList,
} from "store/utility/utility.slice"
import { IRetrieveStudent } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"

import { LocationState } from "../StudentProfile.types"
import TextFieldAccountInformation from "../textFieldAccountInformation"
import {
  accountSchema,
  checkInputFields,
  defaultValues,
} from "./AccountInformation.config"

interface IStudentProfile {
  first_name: string
  last_name: string
  login?: string
  password: string
  avatar_url?: string
}

interface IProps {
  val: string | null
  userInfo?: IRetrieveStudent
  isLoadingRetrieveStudent: boolean
  state: LocationState
  setClassId: () => number | undefined
}

const AccountInformation: FC<IProps> = ({
  val,
  userInfo,
  isLoadingRetrieveStudent,
  state,
  setClassId,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()
  const mobile = useMediaQuery(theme.breakpoints.up("mobile"))
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const [isDisplayArrow, setIsDisplayArrow] = useState({
    left: false,
    right: false,
  })
  const [index, setIndex] = useState(0)
  const page = useAppSelector(selectPage)
  const isNextPageOnStudent = useAppSelector(selectIsNextPageOnStudent)
  const classList = useAppSelector(selectClassList)
  const { sorting, sort_by } = useAppSelector(selectParamsClassStudentList)

  const methods = useForm<IStudentProfile>({
    resolver: yupResolver(accountSchema),
    defaultValues: defaultValues,
  })

  const nextStudent = () => {
    if (classList) {
      setIndex(index + 1)
      methods.resetField("password")
      navigate(
        `${RoutePath.STUDENT.replace(
          ":studentId",
          `${classList[index + 1].id}`
        )}`
      )
    }
  }

  const prevStudent = () => {
    if (classList) {
      setIndex(index - 1)
      methods.resetField("password")
      navigate(
        `${RoutePath.STUDENT.replace(
          ":studentId",
          `${classList[index - 1].id}`
        )}`
      )
    }
  }

  useEffect(() => {
    const copyArray = classList && [...classList]
    const currentIndex = copyArray?.findIndex(
      (item) => item.id === Number(params.studentId)
    )

    currentIndex && setIndex(currentIndex)
    if (index === 0) {
      setIsDisplayArrow((prev) => ({ ...prev, left: true }))
    } else {
      setIsDisplayArrow((prev) => ({ ...prev, left: false }))
    }

    if (index === Number(copyArray?.length) - 2) {
      if (page !== 0) {
        dispatch(setProfileStudentNextList(true))
      }
    }

    if (index === Number(copyArray?.length) - 1) {
      setIsDisplayArrow((prev) => ({ ...prev, right: true }))
    } else {
      setIsDisplayArrow((prev) => ({ ...prev, right: false }))
    }

    if (index === -1) {
      setIsDisplayArrow((prev) => ({ ...prev, right: true }))
      setIsDisplayArrow((prev) => ({ ...prev, left: true }))
    }
  }, [classList, index])

  useQueryClassesStudents({
    classId: Number(setClassId()),
    page,
    sorting,
    sort_by,
    options: {
      enabled: !!setClassId() && isNextPageOnStudent,
      onSuccess: (data) => {
        if (!!data?.data.objects.length && data?.data.objects.length === 30) {
          dispatch(setPage(30))
          dispatch(setProfileStudentNextList(false))
        } else if (!!data?.data && data?.data.objects.length < 30) {
          dispatch(setPage(0))
          dispatch(setIsNextPage(false))
          dispatch(setProfileStudentNextList(false))
        }
        dispatch(setClassList(data?.data.objects))
      },
    },
  })

  const { mutate: updateStudent, isLoading } = useMutationStudentUpdate({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("studentRetrieve")
        queryClient.invalidateQueries("studentsSearch")
        queryClient.invalidateQueries("classesStudents")
        showSnackbar({
          title: t("studentProfile.updateSucceeded"),
          variant: "success",
        })
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    methods.setValue("first_name", userInfo?.first_name as string)
    methods.setValue("last_name", userInfo?.last_name as string)
    methods.setValue("login", userInfo?.login as string)
  }, [userInfo])

  const onSubmit = async (data: IStudentProfile) => {
    checkInputFields(data, updateStudent, params, val, userInfo)
    setTimeout(() => methods.reset({}, { keepValues: true }), 0)
  }

  return (
    <Box>
      <Typography variant="subtitle1" color="mockup.neutral10" mb={3}>
        {t("studentProfile.accountDetails")}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              rowSpacing={mobile ? 4 : 0}
              columnSpacing={1}
            >
              <TextFieldAccountInformation
                isLoading={isLoadingRetrieveStudent}
                name="first_name"
                label={t("studentProfile.firstName")}
              />
              <TextFieldAccountInformation
                isLoading={isLoadingRetrieveStudent}
                name="last_name"
                label={t("studentProfile.lastName")}
              />
            </Grid>
            <Grid
              container
              display="flex"
              flexDirection="row"
              columnSpacing={1}
              rowSpacing={mobile ? 4 : 0}
              mt={mobile ? 0 : 3}
              mb={3}
            >
              <TextFieldAccountInformation
                isLoading={isLoadingRetrieveStudent}
                name="login"
                label={t("studentProfile.userName")}
              />
              <TextFieldAccountInformation
                isLoading={isLoadingRetrieveStudent}
                name="password"
                label={t("studentProfile.password")}
                placeholder="******"
                isPassword
              />
            </Grid>
            {!state && (
              <ArrowButton
                boxProps={{ width: "100%" }}
                onClickLeft={prevStudent}
                onClickRight={nextStudent}
                onDisabledLeft={isDisplayArrow.left || isLoadingRetrieveStudent}
                onDisabledRight={
                  isDisplayArrow.right || isLoadingRetrieveStudent
                }
              />
            )}
          </Grid>
          <Button disabled={isLoading} sx={{ mb: 4, mt: "40px" }} type="submit">
            {isLoading && (
              <span>
                <CircularProgress
                  size="16px"
                  color="inherit"
                  sx={{ mr: "5px" }}
                />
              </span>
            )}
            {t("studentProfile.saveChanges")}
          </Button>
        </form>
      </FormProvider>
    </Box>
  )
}

export default AccountInformation
