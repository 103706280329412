import React, { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as EditionBtn } from "@common_assets/svg/edition-button.svg"
import { ReactComponent as Infinity } from "@common_assets/svg/infinity.svg"
import {
  Box,
  Divider,
  Fab,
  Stack,
  Typography,
  useTheme,
  AvatarGroup,
  styled,
  Avatar,
  Tooltip,
} from "@mui/material"
import {
  useMutationDeleteJobs,
  useMutationPatchCreateJobs,
} from "api/reactQuery/mutations/jobs"
import {
  useMutationFireStudent,
  useMutationHiringStudent,
  useMutationRejectStudent,
} from "api/reactQuery/mutations/students"
import { SingleStudent } from "api/reactQuery/mutations/students.types"
import { useQueryGetJobsDetails } from "api/reactQuery/queries/jobs"
import {
  IGetJobsResponse,
  IGetPendingApplicationStudents,
  IGetResponseJobsDetails,
} from "api/reactQuery/queries/jobs.types"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import EditJob from "components/module/editJob"
import Application from "components/module/editJob/application"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import mixpanel from "mixpanel-browser"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { IObjectStudentDetailed } from "ts/interfaces/Student"
import { getErrorMessageFromTab } from "utils/api"
import { ItemsTypes } from "utils/itemsTypes"

import { ICheckedPerson, IStudentsToChecked } from "../Jobs"
import { initialStateDetailsJob } from "../utily"

interface IProps {
  jobsOffer: IGetJobsResponse
  students: IObjectStudentDetailed[]
  setRichEditorResponsibilities: Dispatch<React.SetStateAction<string>>
  setRichEditorQualifications: Dispatch<React.SetStateAction<string>>
  richEditorQualifications: string
  richEditorResponsibilities: string
  checkedPerson: ICheckedPerson[]
  setStudentsToChecked: Dispatch<React.SetStateAction<IStudentsToChecked[]>>
  allOfClasses: IGetJobsResponse[]
  setCheckedPerson: Dispatch<React.SetStateAction<ICheckedPerson[]>>
  handleChangeInView: (value: boolean) => void
  isRefetching: boolean
}

const AvatarStyle = styled(AvatarGroup)({
  "& .MuiAvatar-root": { width: "36px", height: "36px" },
  "& .MuiAvatar-root:last-child": {
    marginLeft: "-10px",
  },
})

interface IItem {
  id: number
  first_name: string
  last_name: string
}

const SingleGrid: FC<IProps> = ({
  setRichEditorResponsibilities,
  richEditorResponsibilities,
  richEditorQualifications,
  setRichEditorQualifications,
  jobsOffer,
  students,
  allOfClasses,
  setStudentsToChecked,
  setCheckedPerson,
  handleChangeInView,
  isRefetching,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const params = useParams()
  const [classesId, setClassesId] = useState<number[] | []>([])
  const [isPendingApplication, setIsPendingApplication] = useState(false)

  const filterOnlyId = (data: IGetJobsResponse[]) => {
    const idsArr = data.map((el) => el.id)
    setClassesId(idsArr)
  }

  useEffect(() => {
    filterOnlyId(allOfClasses)
  }, [allOfClasses])

  const findIdClass = (id: number) => {
    const updatedId = classesId.find((el) => el === id)
    if (updatedId) setId(updatedId)
  }

  const [state, setState] = useState<IGetResponseJobsDetails>(
    initialStateDetailsJob
  )

  const [users, setUsers] = useState<IItem[] | []>([])

  const [id, setId] = useState<number | null>(null)
  const [employeesTab, setEmployeesTab] = useState(false)
  const [tab, setTab] = useState(employeesTab ? "employees" : "general")
  const [singleUser, setSingleUser] = useState<IItem | null>(null)

  const [studentPendingApplication, setStudentPendingApplication] = useState<
    IGetPendingApplicationStudents | undefined
  >(undefined)

  const {
    isOpen: isDialogOpenJob,
    handleOpen: handleOpenDialogDrop,
    handleClose: handleCloseDialogDrop,
  } = useDialog()

  const {
    isOpen: isDialogEditJob,
    handleOpen: handleOpenDialogEdit,
    handleClose: handleCloseDialogEdit,
  } = useDialog()

  const {
    isOpen: isDialogRemoveJob,
    handleOpen: handleOpenRemoveJob,
    handleClose: handleCloseRemoveJob,
  } = useDialog()

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemsTypes.STUDENT,
    drop: (item: IItem[]) => {
      handleOpenDialogDrop()
      setUsers(item)
      if (item.length === 1) {
        item.filter((el) =>
          setSingleUser({
            id: el.id,
            first_name: el.first_name,
            last_name: el.last_name,
          })
        )
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }))

  const addStudent = (item: IItem[]) => {
    const arrId: SingleStudent[] = []

    item.filter((el) => arrId.push({ student_id: el.id }))

    hiringStudent({
      classId: Number(params.classId),
      jobId: jobsOffer.id,
      data: arrId,
    })
  }

  const { mutate: fireStudent } = useMutationFireStudent({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "student has been fired successfully",
          variant: "success",
        })
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        handleCloseApplication()
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: hiringStudent, isLoading: isLoadingHiringStudent } =
    useMutationHiringStudent({
      options: {
        onSuccess: (_, variables) => {
          if (!!window.Intercom) {
            window.Intercom("trackEvent", "add to job by drag and drop", {
              classId: variables.classId,
              amount: variables.data.length,
              jobTitle: jobsOffer.title,
            })
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "add_job_by_drag_and_drop",
            amount: variables.data.length,
            classId: variables.classId,
            jobTitle: jobsOffer.title,
          })
          queryClient.invalidateQueries(["jobsDetails"])
          queryClient.invalidateQueries(["jobs"])
          queryClient.invalidateQueries(["studentsClass"])
          if (users.length === 1) {
            showSnackbar({
              title: t("jobs.hasBeenSuccessfullyHired", {
                title: jobsOffer.title,
                firstName: singleUser?.first_name,
                lastName: singleUser?.last_name,
              }),
            })
          } else {
            showSnackbar({
              title: t("jobs.haveBeenSuccessfullyHired", {
                count: users.length,
                title: jobsOffer.title,
              }),
            })
          }
          handleCloseDialogDrop()
          handleCloseApplication()
          setId(null)
          setStudentsToChecked((prev) =>
            prev.map((el) => ({ ...el, checked: false }))
          )
          setCheckedPerson([])
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
          handleCloseDialogDrop()
        },
      },
    })

  const { mutate: rejectStudent } = useMutationRejectStudent({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobsDetails"])
        queryClient.invalidateQueries(["jobs"])
        queryClient.invalidateQueries(["studentsClass"])
        if (isDialogOpenRemovePendingApplication) {
          showSnackbar({
            title: t("jobs.jobApplicationRemoveSuccessfully"),
          })
          handleCloseRemovePendingApplication()
          handleCloseApplication()
        }
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { data: getJobDetails, isLoading: isLoadingJobDetails } =
    useQueryGetJobsDetails({
      classId: Number(params.classId),
      jobId: Number(id),
      options: {
        cacheTime: 0,
        enabled: !!id,
        onSuccess: (data) => {
          setState(data?.data)
          setRichEditorResponsibilities(data?.data.responsibilities)
          setRichEditorQualifications(data?.data.qualifications)
        },
      },
    })

  const { mutate: editJobsDetails, isLoading: isLoadingEditJobsDetails } =
    useMutationPatchCreateJobs({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries("jobs")
          showSnackbar({
            title: t("jobs.editCreateJob"),
            variant: "success",
          })
          setRichEditorResponsibilities("")
          setRichEditorQualifications("")
          setId(null)
          handleCloseDialogEdit()
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  const {
    mutate: removeJobs,
    isSuccess: successDeleteJobs,
    isLoading: isLoadingRemoveJobs,
  } = useMutationDeleteJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("jobs")
        queryClient.invalidateQueries(["studentsClass"])
        setRichEditorResponsibilities("")
        setRichEditorQualifications("")
        showSnackbar({
          title: t("jobs.removeCreateJob"),
          variant: "success",
        })
      },
    },
  })

  const {
    isOpen: isDialogOpenApplication,
    handleOpen: handleOpenApplication,
    handleClose: handleCloseApplication,
  } = useDialog()

  const {
    isOpen: isDialogOpenRemovePendingApplication,
    handleOpen: handleOpenRemovePendingApplication,
    handleClose: handleCloseRemovePendingApplication,
  } = useDialog()

  return (
    <Stack
      mr={1}
      mb={1}
      minWidth="260px"
      height="245px"
      ref={drop}
      flex="1"
      sx={{
        borderRadius: 1,
        border: isOver
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.mockup.neutral90}`,
      }}
    >
      <Box
        py="20px"
        px={2}
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        bgcolor={theme.palette.mockup.neutral100}
        border={`2px solid ${theme.palette.mockup.primary95}`}
        borderRadius={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Tooltip
            title={jobsOffer.title.length > 40 ? jobsOffer.title : ""}
            placement="bottom"
          >
            <Typography
              sx={{
                width: "200px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: theme.palette.mockup.neutral0,
              }}
              variant="subtitle1"
            >
              {jobsOffer.title}
            </Typography>
          </Tooltip>
          <Fab
            size="small"
            onClick={() => {
              findIdClass(jobsOffer.id)
              handleOpenDialogEdit()
            }}
          >
            <EditionBtn />
          </Fab>
          <Dialog
            titleText={t("jobs.editJob")}
            open={isDialogEditJob}
            isLoading={isLoadingEditJobsDetails}
            contentWrapperProps={{ px: "24px" }}
            onClose={() => {
              setRichEditorResponsibilities("")
              setRichEditorQualifications("")
              setId(null)
              setEmployeesTab(false)
              handleCloseDialogEdit()
            }}
            desktopMaxWidth="1040px"
            tabletMaxWidth="534px"
            actionAcceptText={"Save"}
            onDeleteClick={handleOpenRemoveJob}
            customActionButtonsSlot={tab !== "general"}
            actionAcceptButtonProps={{
              type: "submit",
              form: "editJob",
              disabled: isLoadingEditJobsDetails,
            }}
            onActionButtonClick={() => {
              setEmployeesTab(false)
            }}
          >
            {isLoadingJobDetails ? (
              <StudentSkeleton height="700px" />
            ) : (
              <EditJob
                isRefetching={isRefetching}
                handleChangeInView={handleChangeInView}
                editJobsDetails={editJobsDetails}
                jobsState={state}
                richEditorResponsibilities={richEditorResponsibilities}
                setRichEditorResponsibilities={setRichEditorResponsibilities}
                setRichEditorQualifications={setRichEditorQualifications}
                richEditorQualifications={richEditorQualifications}
                setJobsState={setState}
                students={students}
                id="editJob"
                getJobDetails={getJobDetails?.data}
                tab={tab}
                setTab={setTab}
                classesId={classesId}
                setId={setId}
                currentId={id}
              />
            )}
          </Dialog>
          <Dialog
            titleText={t("jobs.areYouSureYouWantToDeleteJob")}
            open={isDialogRemoveJob}
            actionAcceptButtonProps={{ disabled: isLoadingRemoveJobs }}
            isLoading={isLoadingRemoveJobs}
            onClose={handleCloseRemoveJob}
            actionAcceptText={t("jobs.delete")}
            onActionButtonClick={() => {
              removeJobs({
                classId: Number(params.classId),
                jobId: jobsOffer.id,
              })
              if (successDeleteJobs) {
                handleCloseRemoveJob()
              }
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          alignItems="center"
        >
          {jobsOffer.positions > 0 ? (
            <Typography
              variant="body3"
              color="mockup.neutral10"
              fontWeight="500"
              sx={{ pr: "3px" }}
            >
              {Number(jobsOffer.positions) - Number(jobsOffer.employees.length)}
            </Typography>
          ) : (
            <Infinity />
          )}
          <Typography variant="body3" color="mockup.neutral10" fontWeight="500">
            {t("jobs.openPositions")}
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1 }} />
          <Coin amount={jobsOffer.salary} />
        </Box>
        <Divider sx={{ width: "100%", my: 2 }} />
        <Box>
          <Typography variant="body3" color="primary.main">
            {t("jobs.employeesTitle").toUpperCase()}
          </Typography>
          <Box display="flex" position="relative" sx={{ minHeight: "36px" }}>
            <AvatarStyle max={7}>
              {jobsOffer.employees.map((el) => (
                <Tooltip key={el.id} title={`${el.first_name} ${el.last_name}`}>
                  <Box
                    display="flex"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setId(jobsOffer.id)
                      if (el.job_application_id === null) {
                        setEmployeesTab(true)
                        handleOpenDialogEdit()
                      } else {
                        setStudentPendingApplication(el)
                        setIsPendingApplication(false)
                        handleOpenApplication()
                      }
                    }}
                  >
                    {el.avatar_url ? (
                      <Avatar
                        src={el.avatar_url}
                        alt="avatar"
                        style={{
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Box>
                </Tooltip>
              ))}
            </AvatarStyle>
          </Box>
          <Dialog
            actionAcceptButtonProps={{ disabled: isLoadingHiringStudent }}
            titleText={
              users?.length === 1
                ? t("jobs.areYouSureYouWantToHireSingleStudentAsAJob", {
                    firstname: singleUser?.first_name,
                    lastname: singleUser?.last_name,
                    jobsTitle: jobsOffer.title,
                  })
                : t("jobs.areYouSureYouWantToHireStudentsAsAJob", {
                    count: users?.length,
                    jobsTitle: jobsOffer.title,
                  })
            }
            open={isDialogOpenJob}
            onClose={() => {
              handleCloseDialogDrop()
              mixpanel.track("hire_students", {
                action: "cancel",
              })
            }}
            desktopMaxWidth="534px"
            tabletMaxWidth="534px"
            actionAcceptText={"Hire"}
            onActionButtonClick={() => addStudent(users)}
          >
            <Stack>
              {users?.length > 1 &&
                users.map((user, index) => (
                  <Typography
                    key={index}
                    color="mockup.neutral10"
                    variant="body2"
                  >
                    {user.first_name} {user.last_name}
                  </Typography>
                ))}
            </Stack>
          </Dialog>
        </Box>
        {jobsOffer.pending_applications_count !== 0 && (
          <Box>
            <Typography variant="body3" color="primary.main">
              {t("jobs.pendingApplicationGrid").toUpperCase()}
            </Typography>
            <Box display="flex">
              <AvatarStyle max={7}>
                {jobsOffer.pending_applications_students.map((el) => (
                  <Tooltip
                    key={el.id}
                    title={`${el.first_name} ${el.last_name}`}
                  >
                    <Box
                      key={el.id}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsPendingApplication(true)
                        setId(jobsOffer.id)
                        setStudentPendingApplication(el)
                        handleOpenApplication()
                      }}
                    >
                      {el.avatar_url ? (
                        <Avatar
                          src={el.avatar_url}
                          alt="avatar"
                          style={{
                            width: "36px",
                            height: "36px",
                          }}
                        />
                      ) : (
                        <Avatar />
                      )}
                    </Box>
                  </Tooltip>
                ))}
              </AvatarStyle>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        titleText={t("jobs.jobApplication")}
        open={isDialogOpenApplication}
        onClose={handleCloseApplication}
        onDeleteClick={
          isPendingApplication ? handleOpenRemovePendingApplication : undefined
        }
        desktopMaxWidth="1040px"
        tabletMaxWidth="534px"
        actionAcceptText={
          isPendingApplication ? t("jobs.hireStudent") : t("jobs.removeStudent")
        }
        onActionButtonClick={() => {
          if (studentPendingApplication && getJobDetails) {
            isPendingApplication
              ? hiringStudent({
                  data: [{ student_id: studentPendingApplication.id }],
                  classId: Number(params.classId),
                  jobId: getJobDetails.data.id,
                })
              : fireStudent({
                  data: [{ student_id: studentPendingApplication.id }],
                  classId: Number(params.classId),
                  jobId: getJobDetails.data.id,
                })
          }
        }}
      >
        {getJobDetails && (
          <Application
            jobState={getJobDetails.data}
            studentId={studentPendingApplication}
          />
        )}
      </Dialog>
      <Dialog
        open={isDialogOpenRemovePendingApplication}
        actionAcceptText={t("jobs.delete")}
        titleText={t("jobs.areYouSureYouWantToDeleteJobApplication")}
        onClose={handleCloseRemovePendingApplication}
        desktopMaxWidth="534px"
        tabletMaxWidth="344px"
        onActionButtonClick={() => {
          if (studentPendingApplication && getJobDetails) {
            rejectStudent({
              data: [{ student_id: studentPendingApplication.id }],
              classId: Number(params.classId),
              jobId: getJobDetails.data.id,
            })
          }
        }}
      />
    </Stack>
  )
}

export default SingleGrid
