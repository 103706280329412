import { FC } from "react"

import { ReactComponent as SvgResearch } from "@common_assets/svg/research.svg"
import { Box } from "@mui/material"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import { useTranslation } from "react-i18next"

interface IProps {
  search: string
}

const NoMatchesForSearch: FC<IProps> = ({ search }) => {
  const { t } = useTranslation()

  return (
    <Box py={{ mobile: "16px", desktop: "64px" }}>
      <NoElementsMessage
        title={t("setup.noMatchesFor", { match: search })}
        Icon={<SvgResearch />}
      />
    </Box>
  )
}

export default NoMatchesForSearch
