import { UseFormReturn } from "react-hook-form"
import { requiredStringSchema, stringSchema } from "utils/yup"
import * as yup from "yup"

import { IResponseNewClass } from "./AddNewClass.types"

export const defaultValues = {
  name: "",
  description: "",
  grade: "",
  icon: "",
  paycheck_automatic: false,
  paycheck_interval: "",
  expense_automatic: false,
  expense_interval: "",
  savings_account_option: false,
  interest_granting_interval: "",
  saving_interest_rate: "",
  round_interest_to_whole_number: false,
  copy_settings: false,
  copy_from_id: "",
  interest_option: false,
}

export const schemaStructure = yup.object({
  name: yup.string().required().min(3).max(255),
  description: stringSchema,
  grade: requiredStringSchema,
  icon: requiredStringSchema,
  paycheck_automatic: yup.boolean(),
  paycheck_interval: stringSchema.when("paycheck_automatic", {
    is: true,
    then: requiredStringSchema,
  }),
  expense_automatic: yup.boolean(),
  expense_interval: stringSchema.when("expense_automatic", {
    is: true,
    then: requiredStringSchema,
  }),
  savings_account_option: yup.boolean(),
  interest_option: yup.boolean(),
  saving_interest_rate: stringSchema.when("interest_option", {
    is: true,
    then: yup
      .string()
      .required("Interest rate is required")
      .test(
        "is-numeric",
        "Interest must be a number",
        //@ts-ignore
        (value) => !isNaN(value) && value?.trim() !== ""
      )
      .test(
        "is-in-range",
        "Interest rate must be a number between 0 and 100",
        (value) => {
          const number = parseFloat(value as string)
          return number >= 0 && number <= 100
        }
      ),
  }),
  round_interest_to_whole_number: yup.boolean(),
  interest_granting_interval: stringSchema.when("interest_option", {
    is: true,
    then: requiredStringSchema,
  }),
  copy_settings: yup.boolean(),
  copy_from_id: stringSchema.when("copy_settings", {
    is: true,
    then: requiredStringSchema,
  }),
})

export const clearError = (
  state: IResponseNewClass,
  methods: UseFormReturn<IResponseNewClass, object>
) => {
  if (state.icon !== null) {
    methods.clearErrors("icon")
  }
  if (!state.paycheck_automatic) {
    methods.clearErrors("paycheck_interval")
  }
  if (!state.expense_automatic) {
    methods.clearErrors("expense_interval")
  }

  if (!state.interest_option) {
    methods.clearErrors("saving_interest_rate")
    methods.clearErrors("interest_granting_interval")
  }

  if (!!state.saving_interest_rate) {
    methods.clearErrors("saving_interest_rate")
  }

  if (!state.copy_settings) {
    methods.clearErrors("copy_from_id")
  }
}

export const setterValue = (
  state: IResponseNewClass,
  methods: UseFormReturn<IResponseNewClass, object>,
  isApplySettings: boolean
) => {
  methods.setValue("icon", state.icon !== null ? state.icon : "")
  methods.setValue("name", state.name)
  methods.setValue("description", state.description)
  methods.setValue("grade", state.grade)
  methods.setValue("paycheck_automatic", state.paycheck_automatic)
  methods.setValue("expense_automatic", state.expense_automatic)
  methods.setValue("savings_account_option", state.savings_account_option)
  methods.setValue(
    "interest_granting_interval",
    state.interest_granting_interval !== null
      ? state.interest_granting_interval
      : ""
  )
  methods.setValue("saving_interest_rate", state.saving_interest_rate)
  methods.setValue(
    "round_interest_to_whole_number",
    state.round_interest_to_whole_number
  )
  methods.setValue(
    "paycheck_interval",
    state.paycheck_interval !== null ? state.paycheck_interval : ""
  )
  methods.setValue(
    "expense_interval",
    state.expense_interval !== null ? state.expense_interval : ""
  )
  methods.setValue("copy_settings", isApplySettings)
}
