import React, { FC } from "react"

import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import {
  useMutationPostStoreClerkTransactionsAccept,
  useMutationPostStoreClerkTransactionsReject,
} from "api/reactQuery/mutations/storeTransactions"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { Trans, useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { ITransactionItemClass } from "ts/interfaces/Transaction"
import { getErrorMessageFromTab } from "utils/api"

interface IProps {
  selectedItems: number[]
  clearSelectedItems: () => void
  sortedData: ITransactionItemClass[]
}

const BatchActionButtons: FC<IProps> = ({
  selectedItems,
  clearSelectedItems,
  sortedData,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const isTablet = useIsBreakpointUp("tablet")
  const classId = useClassId()
  const { showSnackbar } = useCustomSnackbar()

  const { mutate: postTransactionsAccept, isLoading: isLoadingAccept } =
    useMutationPostStoreClerkTransactionsAccept({
      id: Number(classId),
      options: {
        onSuccess: (_, variables) => {
          if (!!window.Intercom) {
            window.Intercom("trackEvent", "accepted transaction", {
              classId: classId,
              numberAcceptedTransaction: variables.transaction_ids.length,
            })
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "acceptedTransaction",
            classId: classId,
            numberAcceptedTransaction: variables.transaction_ids.length,
          })
          setTimeout(() => {
            queryClient.invalidateQueries([
              "classesStoreClerkTransactions",
              Number(classId),
            ])
            showSnackbar({
              title: t("store.transactionsAccepted", {
                count: selectedItems.length,
              }),
            })

            clearSelectedItems()
          }, 500)
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  const { mutate: postTransactionsReject, isLoading: isLoadingReject } =
    useMutationPostStoreClerkTransactionsReject({
      id: Number(classId),
      options: {
        onSuccess: () => {
          setTimeout(() => {
            queryClient.invalidateQueries([
              "classesStoreClerkTransactions",
              Number(classId),
            ])
            showSnackbar({
              title: t("store.transactionsRejected", {
                count: selectedItems.length,
              }),
            })
            clearSelectedItems()
          }, 500)
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  return (
    <>
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        justifyContent="space-between"
        alignItems={{ mobile: "start", tablet: "center" }}
        p="8px 16px"
        pb={{ mobile: "0px", tablet: "8px" }}
        bgcolor="mockup.primary95"
        borderBottom={(theme) => `1px solid ${theme.palette.mockup.primary80}`}
      >
        <Stack columnGap={1}>
          <Typography variant="subtitle1" color="mockup.neutral10">
            <Trans i18nKey="store">
              {selectedItems.length === 500
                ? t("store.maxOf500selectedTransactionsReached")
                : t("store.transactionsSelected", {
                    count: selectedItems.length,
                  })}
            </Trans>
          </Typography>
          {selectedItems.length === 500 && (
            <Typography color="mockup.neutral20">
              {t("store.remainingTransactions", {
                count: sortedData?.length - selectedItems.length,
              })}
            </Typography>
          )}
        </Stack>
        <Stack
          width={{ mobile: "100%", tablet: "max-content" }}
          sx={{ overflowX: "auto" }}
          className="invisible-scrollbar"
        >
          <Stack
            direction="row"
            gap="8px"
            width="max-content"
            mx={{ mobile: "-16px", tablet: "0px" }}
          >
            <Button
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                if (selectedItems.length > 500) {
                  showSnackbar({
                    title: t("store.youCanSendUpToTransactions"),
                    variant: "error",
                  })
                  return
                }
                postTransactionsReject({
                  transaction_ids: selectedItems,
                })
              }}
            >
              {isLoadingReject && (
                <CircularProgress size="16px" sx={{ mr: "8px" }} />
              )}
              {t("reject")}
            </Button>
            <Button
              variant={isTablet ? "outlined" : "text"}
              onClick={() => {
                if (selectedItems.length > 500) {
                  showSnackbar({
                    title: t("store.youCanSendUpToTransactions"),
                    variant: "error",
                  })
                  return
                }
                postTransactionsAccept({
                  transaction_ids: selectedItems,
                })
              }}
            >
              {isLoadingAccept && (
                <CircularProgress size="16px" sx={{ mr: "8px" }} />
              )}
              {t("accept")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default BatchActionButtons
