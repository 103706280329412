import React from "react"

interface ICustomTab {
  children: React.ReactNode
  index: number
  value: number
}

const CustomTab = ({ children, value, index }: ICustomTab) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  )
}
export default CustomTab
