import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  ICoteachersArgs,
  ICoteachersPossibleArgs,
  IResponseCoteachers,
  IResponseCoteachersPossible,
} from "./coteachers.types"

export const useQueryCoteachers = (args: ICoteachersArgs) =>
  useQuery<AxiosResponse<IResponseCoteachers[]>, AxiosError>(
    ["coteachers", args.classId],
    async () =>
      axiosInstance(
        Endpoint.SCHOOL_CLASSES_ID_COTEACHERS.replace("ID", `${args.classId}`)
      ),
    args?.options
  )

export const useQueryCoTeachersPossible = (args: ICoteachersPossibleArgs) =>
  useQuery<AxiosResponse<IResponseCoteachersPossible[]>, AxiosError>(
    ["possibleCoteachers"],
    async () =>
      axiosInstance(
        Endpoint.SCHOOL_CLASSES_ID_COTEACHERS_POSSIBLE.replace(
          "ID",
          `${args.classId}`
        )
      ),
    args.options
  )
