import React, { FC, useState } from "react"

import { useMutationResetPassword } from "api/reactQuery/mutations/password"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"

import { passwordSchema } from "../../../utils/yup"
import Layout from "../layout"
import First from "./pages/first"
import Second from "./pages/second"

const ChangePassword: FC = () => {
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()

  const [linkToResetPassword, setLinkToResetPaassword] = useState(false)
  const [state, setState] = useState({
    password: "",
    token: "",
  })
  const [page, setPage] = useState(0)
  const [error, setError] = useState("")

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      token: window.location.search.slice(7),
    }))
  }, [])

  React.useEffect(() => {
    if (!!error) {
      passwordSchema
        .validate(state.password)
        .then(() => setError(""))
        .catch((err) => setError(err.message))
    }
  }, [state.password])

  const { mutateAsync, isLoading } = useMutationResetPassword({
    options: {
      onSuccess: () => {
        setPage(1)
      },
      onError: () => {
        showSnackbar({
          title: t(
            "noAuthFlow.yourTokenWasExpiredOrIsInvalidPleaseResetPasswordOnceAgain"
          ),
          variant: "error",
        })
        setLinkToResetPaassword(true)
      },
    },
  })

  const handleClick = async () => {
    try {
      await passwordSchema.validate(state.password)
      await mutateAsync(state)
    } catch (err) {
      if (err === "string") setError(err)
      if (err instanceof Error) setError(err.message)
    }
  }

  return (
    <Layout>
      {page === 0 && (
        <First
          error={error}
          handleClick={handleClick}
          setState={setState}
          state={state}
          linkToResetPassword={linkToResetPassword}
          isLoading={isLoading}
        />
      )}
      {page === 1 && <Second />}
    </Layout>
  )
}

export default ChangePassword
