import React, { useEffect, useMemo, useState } from "react"

import { ReactComponent as IconImage } from "@common_assets/svg/image-outlined.svg"
import {
  Avatar,
  Button,
  Checkbox,
  Stack,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material"
import { useQueryClassesStoreClerkItems } from "api/reactQuery/queries/classesStoreItems"
import ActionButtons from "components/common/button/actionButtons"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import { Cell, HeadText, Row } from "components/common/table/basicTable"
import BasicTable from "components/common/table/basicTable/BasicTable"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { IClassStoreClerkItems } from "ts/interfaces/Class"
import { OrderByProps } from "ts/types/TableLabelOrders"

import SearchbarForm from "../../../form/searchbarForm"
import DialogAddItem from "../../allItemsStoreTeacher/common/viewCommonElementsContainer/dialogAddItem/DialogAddItem"
import DialogAddSuggestedItems from "../../allItemsStoreTeacher/common/viewCommonElementsContainer/dialogAddSuggestedItems/DialogAddSuggestedItems"
import NoItemsYet from "../../allItemsStoreTeacher/common/viewCommonElementsContainer/noItemsYet"
import { AllItemSortingTypes } from "./AllItem.types"
import TopBatchActionButtons from "./topBatchActionButtons"

const AllItem = () => {
  const classId = useClassId()
  const theme = useTheme()
  const { t } = useTranslation()
  const [orderBy, setOrderBy] = useState<OrderByProps>("asc")
  const [sortedUsers, setSortedUsers] = useState<IClassStoreClerkItems[]>([])
  const [markedStudents, setMarkedStudents] = useState<IClassStoreClerkItems[]>(
    []
  )
  const [sorting, setSorting] = useState<AllItemSortingTypes>(null)
  const [searchOption, setSearchOption] = useState({
    search: "",
  })

  const { data, isLoading, isError } = useQueryClassesStoreClerkItems({
    classId: Number(classId),
  })

  useEffect(() => {
    setSortedUsers(data?.data ?? [])
  }, [data])

  const filteredUsers = useMemo(() => {
    return sortedUsers.filter((item) =>
      item.name.toLowerCase().includes(searchOption.search.toLowerCase())
    )
  }, [sortedUsers, searchOption.search])

  const handleSort = (name: keyof IClassStoreClerkItems) => {
    const isCurrentColumn = sorting === name
    const newOrderBy = isCurrentColumn
      ? orderBy === "asc"
        ? "desc"
        : "asc"
      : "asc"
    setOrderBy(newOrderBy)
    const updateSortedUser = [...(sortedUsers as IClassStoreClerkItems[])].sort(
      (a, b) => {
        if (name === "price" || name === "quantity_in_stock") {
          if (a[name] !== null && b[name] !== null) {
            if (a[name] === 0 && b[name] > 0)
              return newOrderBy === "asc" ? -1 : 1
            if (b[name] === 0 && a[name] > 0)
              return newOrderBy === "asc" ? 1 : -1
            return newOrderBy === "asc" ? a[name] - b[name] : b[name] - a[name]
          }
          if (a[name] === null) return 1
          if (b[name] === null) return -1
          return 0
        } else {
          if (a !== undefined && b !== undefined && name === "name") {
            return newOrderBy === "asc"
              ? a[name].localeCompare(b[name])
              : b[name].localeCompare(a[name])
          }
          if (a === undefined) return 1
          if (b === undefined) return -1
          return 0
        }
      }
    )
    setSortedUsers(updateSortedUser)
  }

  const handleChecked = (user: IClassStoreClerkItems) => {
    if (markedStudents.find((findStudent) => findStudent.id === user.id)) {
      const excludedStudent = markedStudents.filter(
        (student) => student.id !== user.id
      )
      setMarkedStudents(excludedStudent)
      return
    }

    setMarkedStudents((prev) => [...prev, user])
  }

  const handleMainCheckbox = () => {
    if (markedStudents.length === sortedUsers?.length) {
      setMarkedStudents([])
    } else {
      const allStudentsId = sortedUsers
      if (allStudentsId) setMarkedStudents(allStudentsId)
    }
  }

  const {
    isOpen: isOpenAddItem,
    handleOpen: handleOpenAddItem,
    handleClose: handleCloseAddItem,
  } = useDialog()

  const {
    isOpen: isOpenAddSuggestedItems,
    handleOpen: handleOpenAddSuggestedItems,
    handleClose: handleCloseAddSuggestedItems,
  } = useDialog()

  return (
    <Stack>
      <Stack
        flexDirection="row"
        columnGap={1}
        sx={{ backgroundColor: theme.palette.common.white, p: 2 }}
      >
        <SearchbarForm onSubmit={(data) => setSearchOption(data)} />
        <Button
          onClick={handleOpenAddItem}
          sx={{
            width: { mobile: "100%", tablet: "150px" },
            paddingX: { mobile: "12px", tablet: "8px" },
          }}
        >
          Add Item
        </Button>
      </Stack>
      <DialogAddItem isOpen={isOpenAddItem} onClose={handleCloseAddItem} />
      <BasicTable
        topSlot={
          <TopBatchActionButtons
            selectedItems={markedStudents}
            clearSelectedItems={() => setMarkedStudents([])}
          />
        }
        head={
          <Row>
            <Cell>
              <Checkbox
                indeterminate={
                  !!markedStudents.length &&
                  markedStudents.length !== sortedUsers?.length
                }
                checked={
                  !!markedStudents.length &&
                  markedStudents.length === sortedUsers?.length
                }
                onChange={handleMainCheckbox}
              />
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <TableSortLabel
                active={sorting === "name"}
                direction={orderBy}
                onClick={() => {
                  handleSort("name")
                  setSorting("name")
                }}
              >
                <HeadText>{t("store.item")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <TableSortLabel
                active={sorting === "quantity_in_stock"}
                direction={orderBy}
                onClick={() => {
                  handleSort("quantity_in_stock")
                  setSorting("quantity_in_stock")
                }}
              >
                <HeadText>{t("store.quantity")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell width="90px">
              <TableSortLabel
                active={sorting === "price"}
                direction={orderBy}
                onClick={() => {
                  handleSort("price")
                  setSorting("price")
                }}
              >
                <HeadText>{t("store.value")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell width="50px"></Cell>
          </Row>
        }
        body={filteredUsers?.map((user) => {
          return (
            <Row key={user.id}>
              <Cell>
                <Checkbox
                  onChange={() => handleChecked(user)}
                  checked={!!markedStudents.find((el) => user.id === el.id)}
                />
              </Cell>
              <Cell
                py="8px"
                gap="8px"
                sx={{ opacity: user.out_of_stock ? 0.5 : 1 }}
              >
                {user.icon_url ? (
                  <img
                    alt={user.name}
                    src={user.icon_url}
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <Avatar sx={{ height: "32px", width: "32px" }}>
                    <IconImage />
                  </Avatar>
                )}
                <Typography>{user.name}</Typography>
              </Cell>
              <Cell>
                <Typography>{user.quantity_in_stock}</Typography>
              </Cell>
              <Cell>
                <Coin amount={user.price} />
              </Cell>
              <Cell>
                <ActionButtons item={user} />
              </Cell>
            </Row>
          )
        })}
        bottomSlot={
          <>
            {isLoading && <StudentSkeleton height="300px" />}
            {isError && <ErrorText />}
            {!isLoading &&
              !!searchOption.search.length &&
              filteredUsers?.length === 0 && (
                <NoMatchesForSearch search={searchOption.search} />
              )}
            {!isLoading &&
              filteredUsers?.length === 0 &&
              !searchOption.search.length && (
                <NoItemsYet
                  onAddClick={() => {
                    handleOpenAddItem()
                  }}
                  onAddSuggestedClick={() => {
                    handleOpenAddSuggestedItems()
                  }}
                />
              )}
            <DialogAddSuggestedItems
              isOpen={isOpenAddSuggestedItems}
              onClose={handleCloseAddSuggestedItems}
            />
          </>
        }
      />
    </Stack>
  )
}
export default AllItem
