import React from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check-curved.svg"
import { Box, DialogProps, Stack, Typography, useTheme } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import TextField from "components/form/common/field/textField"
import { useFormContext } from "react-hook-form"

import { shopDefaultAvatars } from "../../../allItemsStoreTeacher/AllItemsStoreTeacher.config"

interface CreateGoalsDialogProps extends Omit<DialogProps, "open"> {
  isOpen: boolean
  onClose: () => void
  indexChecked: number | null
  onActionButton?: () => void
  onIndexChecked: (arg: null | number) => void
  isLoading: boolean
}

const CreateGoalsDialog = ({
  isOpen,
  onClose,
  indexChecked,
  onActionButton,
  onIndexChecked,
  isLoading,
  ...dialogProps
}: CreateGoalsDialogProps) => {
  const theme = useTheme()

  const methods = useFormContext()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      titleText="Create a goal"
      onIconClose
      actionAcceptButtonProps={{ type: "submit", disabled: isLoading }}
      onActionButtonClick={onActionButton}
      {...dialogProps}
    >
      <TextField name="title" label="Name" />
      <TextField name="description" label="Description (Optional)" />
      <TextField name="total_amount" label="Goal amount" />
      <Stack>
        <Typography variant="body2">Choose an icon</Typography>
        <Box mt={2} gap={2} display="flex" flexWrap="wrap">
          {shopDefaultAvatars.map((avatar, index) => (
            <Box
              position="relative"
              width="44px"
              height="44px"
              key={avatar}
              sx={{
                border:
                  index === indexChecked
                    ? `2px solid ${theme.palette.common.black}`
                    : "1px solid unset",
                p: 1,
                borderRadius: 2,
              }}
              onClick={() => {
                if (index === indexChecked) {
                  onIndexChecked(null)
                  methods.setValue("icon_url", "")
                } else {
                  onIndexChecked(index)
                  methods.setValue("icon_url", avatar)
                }
              }}
            >
              {index === indexChecked && (
                <IconCheck
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                  }}
                />
              )}
              <img width="100%" height="100%" src={avatar} />
            </Box>
          ))}
        </Box>
      </Stack>
    </Dialog>
  )
}
export default CreateGoalsDialog
