type State = {
  enable_math_in_whole_class: boolean
  disable_math_in_whole_class: boolean
  fk_class_id: number
  student_ids: number[]
}

export type Action =
  | { type: "enableMath"; isEnabled: boolean }
  | { type: "disableMath"; isDisabled: boolean }
  | { type: "enableCheckedStudents"; studentArr: number[] }
  | { type: "classID"; classID: number }

export interface InitialStateMathOptionsProps {
  enable_math_in_whole_class: boolean
  disable_math_in_whole_class: boolean
  fk_class_id: number
  student_ids: number[]
}

export const initialStateMathOptions: InitialStateMathOptionsProps = {
  enable_math_in_whole_class: false,
  disable_math_in_whole_class: false,
  fk_class_id: 0,
  student_ids: [0],
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "enableMath":
      return {
        ...state,
        enable_math_in_whole_class: action.isEnabled,
      }
    case "disableMath": {
      return {
        ...state,
        disable_math_in_whole_class: !state.disable_math_in_whole_class,
      }
    }
    case "enableCheckedStudents": {
      return {
        ...state,
        student_ids: action.studentArr,
      }
    }
    case "classID": {
      return {
        ...state,
        fk_class_id: action.classID,
      }
    }
  }
}
