import React, { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import { useMutationGoalsCreate } from "api/reactQuery/mutations/goals"
import Coin from "components/common/icon/coin"
import DynamicIcon from "components/common/icon/dynamicIcon"
import IconWrapper from "components/common/icon/iconWrapper"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"

import CreateGoalsDialog from "../../../common/createGoalsDialog"
import {
  defaultValues,
  validationSchema,
} from "../../../common/createGoalsDialog/CreateGoalsDialog.utility"

interface CheckingBaseProps {
  amount: number
  title: string
  rateInterest?: number
  intervalInterest?: string
  iconName?: string
  onClick: (e: number) => void
  id: number
  isActiveNumber: number
  isInterestOption?: boolean
}

interface GoalProps {
  isGoal?: true
  goalCount: number
}

interface NoGoalProps {
  isGoal: false
  goalCount?: never
}

type CheckingProps = CheckingBaseProps & (GoalProps | NoGoalProps)

const Checking = ({
  isActiveNumber,
  amount,
  title,
  iconName = "wallet",
  rateInterest,
  intervalInterest,
  isGoal = true,
  goalCount,
  onClick,
  id,
  isInterestOption,
}: CheckingProps) => {
  const theme = useTheme()
  const [indexChecked, setIndexChecked] = useState<number | null>(null)
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()

  const firstChart = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const queryClient = useQueryClient()

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { mutate, isLoading } = useMutationGoalsCreate({
    savings_account_id: id,
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "New goal has been created successfully",
        })
        handleClose()
        methods.reset()
        setIndexChecked(null)
        queryClient.invalidateQueries("studentGoals")
        queryClient.invalidateQueries("studentSavings")
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const handleSubmitGoals = () => {
    methods.handleSubmit((data) => {
      mutate({ ...data, total_amount: Number(data.total_amount) })
    })()
  }

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Stack
      onClick={() => {
        if (!isOpen) onClick(id)
      }}
      justifyContent="space-between"
      sx={{
        backgroundColor:
          id === isActiveNumber
            ? theme.palette.mockup.primary90
            : theme.palette.mockup.primary99,
        border: `1px solid ${
          id === isActiveNumber
            ? theme.palette.primary.main
            : theme.palette.mockup.primary95
        }`,
        borderRadius: 2,
        minHeight: "144px",
        p: 2,
        cursor: "pointer",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        fontSize="20px"
        alignItems="center"
      >
        <Box>
          <Typography fontWeight="500" fontSize="20px">
            {title}
          </Typography>
          {isInterestOption && intervalInterest && (
            <Box display="flex" paddingTop={1}>
              <Typography variant="body3">
                {t("myActivity.interest")}
              </Typography>
              <Box display="flex">
                <Typography variant="body3">{`: ${rateInterest}%/`}</Typography>
                <Typography variant="body3">{intervalInterest}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <IconWrapper
          width="48px"
          height="48px"
          sx={{
            borderRadius: "50%",
            backgroundColor: theme.palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DynamicIcon name={iconName} variant="solid" />
        </IconWrapper>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="end">
        <Coin
          amount={amount}
          valueTextProps={{ fontSize: "28px" }}
          iconSize="20px"
        />
        {isGoal && (
          <>
            {!goalCount ? (
              <Button
                style={{
                  padding: 0,
                  alignSelf: "end",
                  fontSize: "14px",
                  textTransform: "none",
                  color: "primary.main",
                }}
                variant="text"
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpen()
                }}
              >
                {firstChart("set new goal")}
              </Button>
            ) : (
              <Typography color="mockup.neutral10">{`${goalCount} Goal${
                goalCount > 1 ? "s" : ""
              }`}</Typography>
            )}
          </>
        )}
      </Box>
      <form onKeyDown={(e) => handleKeyDown(e, handleSubmitGoals, isLoading)}>
        <FormProvider {...methods}>
          <CreateGoalsDialog
            isOpen={isOpen}
            isLoading={isLoading}
            onClose={() => {
              handleClose()
              methods.reset()
              setIndexChecked(null)
            }}
            indexChecked={indexChecked}
            onIndexChecked={(arg) => setIndexChecked(arg)}
            onActionButton={() => {
              methods.handleSubmit((data) => {
                mutate({ ...data, total_amount: Number(data.total_amount) })
              })()
            }}
          />
        </FormProvider>
      </form>
    </Stack>
  )
}
export default Checking
