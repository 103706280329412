import { Dispatch, FC } from "react"

import { ReactComponent as IconClock } from "@common_assets/svg/clock.svg"
import { ReactComponent as Trash } from "@common_assets/svg/trash.svg"
import { Fab, Tooltip, Typography } from "@mui/material"
import {
  IStudentAllTransactions,
  IStudentTransactionTeacherSide,
} from "api/reactQuery/queries/transaction.types"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { Cell, Row } from "components/common/table/basicTable"
import TransactionType from "components/common/transactionType"
import { format } from "date-fns"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useParams } from "react-router"

import DialogTransactions from "../dialogTransactions"

interface ITableProps {
  transaction: IStudentAllTransactions
  setDataTransactions: Dispatch<
    React.SetStateAction<IStudentTransactionTeacherSide[]>
  >
  isScroll: boolean
  setIsScroll: React.Dispatch<React.SetStateAction<boolean>>
}

const TableBodyRow: FC<ITableProps> = ({
  transaction,
  setDataTransactions,
  setIsScroll,
  isScroll,
}) => {
  const params = useParams()
  const isDesktop = useIsBreakpointUp("desktop")
  const allTransaction = transaction as IStudentAllTransactions

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Row>
      <Cell maxWidth="64px" px="8px" position="relative">
        {allTransaction.booked_at === null && (
          <IconWrapper
            height="19px"
            width="19px"
            sx={{
              position: "absolute",
              top: "1px",
              right: "10px",
              backgroundColor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconClock />
          </IconWrapper>
        )}
        <TransactionType transaction={transaction} />
      </Cell>
      <Cell>
        <Typography variant="body2" color="mockup.neutral10">
          {transaction.class.name ? transaction.class.name : "-"}
        </Typography>
      </Cell>
      <Cell>
        <Typography
          variant="body2"
          color="mockup.neutral10"
          textAlign="center"
          fontWeight="500"
        >
          {transaction.teacher === null ? (
            <span>-</span>
          ) : (
            transaction.teacher?.last_name
          )}
        </Typography>
      </Cell>
      <Cell>
        <Tooltip
          onMouseEnter={() => setIsScroll(false)}
          title={transaction.title}
          PopperProps={{
            sx: { display: isScroll ? "none" : "block" },
          }}
        >
          <Typography
            variant="body2"
            color="mockup.neutral10"
            fontWeight="500"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100px",
            }}
          >
            {transaction.title}
          </Typography>
        </Tooltip>
      </Cell>
      <Cell
        sx={{
          maxWidth: isDesktop ? "90px" : "120px",
        }}
      >
        <Tooltip
          onMouseEnter={() => setIsScroll(false)}
          title={format(new Date(transaction.created_at), "MMM dd, yyyy")}
          PopperProps={{
            sx: { display: isScroll ? "none" : "block" },
          }}
        >
          <Typography variant="body2" color="mockup.neutral10" fontWeight="500">
            {format(new Date(transaction.created_at), "MMM dd, yyyy").slice(
              0,
              6
            )}
          </Typography>
        </Tooltip>
      </Cell>
      <Cell
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {transaction.amount < 0 ? (
          <Coin amount={`${transaction.amount}`.slice(1)} sign="-" />
        ) : (
          <Typography>{0}</Typography>
        )}
      </Cell>
      <Cell sx={{ display: "flex", justifyContent: "flex-end" }}>
        {transaction.amount > 0 ? (
          <Coin amount={`${transaction.amount}`} sign="+" />
        ) : (
          <Typography>{0}</Typography>
        )}
      </Cell>
      <Cell sx={{ display: "flex", justifyContent: "center" }}>
        {allTransaction.booked_at === null &&
        allTransaction.balance === null ? (
          "-"
        ) : (
          <Coin amount={`${transaction.balance}`} />
        )}
      </Cell>
      <Cell>
        <Fab onClick={handleOpen} size="small">
          <Trash />
        </Fab>
      </Cell>
      <DialogTransactions
        setDataTransactions={setDataTransactions}
        isOpen={isOpen}
        onClose={handleClose}
        transaction={transaction}
        params={params}
      />
    </Row>
  )
}

export default TableBodyRow
