import { FC, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useMutationPostDashboardTransactions } from "api/reactQuery/mutations/dashboardTransactions"
import Dialog from "components/common/dialog/dialog"
import Transactions from "components/form/common/formPart/transactions"
import {
  getCombinedTransactions,
  getIsNoTransactionAdded,
} from "components/form/common/formPart/transactions/Transactions.utils"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { selectSound } from "store/utility/utility.selectors"
import {
  ISalaryTransactionApiCreate,
  ITransactionApiCreate,
} from "ts/interfaces/Transaction"
import { getErrorMessageFromTab } from "utils/api"
import { handleKeyDown } from "utils/keydown"
import { checkTransaction } from "utils/sound/sound"

import SelectStudent from "./selectStudent"
import {
  sendTransactionFormDefaultValues,
  sendTransactionFormSchema,
} from "./SendTransactionForm.config"
import { ISendTransactionFormState } from "./SendTransactionForm.types"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const TransactionForm: FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const [transactionType, setTransactionType] = useState<
    (ITransactionApiCreate | ISalaryTransactionApiCreate)[]
  >([])
  const soundChecked = useAppSelector(selectSound)

  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const methods = useForm<ISendTransactionFormState>({
    defaultValues: sendTransactionFormDefaultValues,
    resolver: yupResolver(sendTransactionFormSchema),
  })

  const classId = useWatch({
    name: "studentClass",
    control: methods.control,
    exact: true,
  })

  const onDialogClose = () => {
    methods.reset()
    onClose()
  }

  useEffect(() => {
    checkTransaction(transactionType, soundChecked)
  }, [transactionType])

  const { mutate, isLoading } = useMutationPostDashboardTransactions({
    options: {
      onSuccess: (_, variables) => {
        setTransactionType(variables.transactions)

        const bonuses = variables.transactions.filter(
          (transaction) => transaction.type === "bonus"
        )
        const fines = variables.transactions.filter(
          (transaction) => transaction.type === "fine"
        )
        const expenses = variables.transactions.filter(
          (transaction) => transaction.type === "expense"
        )
        const paychecks = variables.transactions.filter(
          (transaction) => transaction.type === "salary"
        )
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "transactions",
          bonuses: bonuses.length,
          fines: fines.length,
          expenses: expenses.length,
          paychecks: paychecks.length,
        })

        if (!!window.Intercom) {
          window.Intercom("trackEvent", "amount of transactions", {
            bonuses: bonuses.length,
            fines: fines.length,
            expenses: expenses.length,
            paychecks: paychecks.length,
          })
        }

        queryClient.invalidateQueries(["studentOverview"])
        showSnackbar({ title: t("transaction.transactionHasBeenSent") })
        onDialogClose()
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const handleActionButtonClick = () => {
    const data = methods.getValues()

    if (getIsNoTransactionAdded(data.transactions)) {
      showSnackbar({
        title: t("transaction.completeAtLeastOneOfDisplayTransactions"),
        variant: "info",
      })
      return
    }

    const allTransactions = getCombinedTransactions(data.transactions)

    if (allTransactions.length > 20) {
      showSnackbar({
        title: t("transaction.selectUpTo20Tiles"),
        variant: "info",
      })
      return
    }

    methods.handleSubmit((data) => onSubmit(data, allTransactions))()
  }

  const onSubmit = (
    data: ISendTransactionFormState,
    allTransactions: (ITransactionApiCreate | ISalaryTransactionApiCreate)[]
  ) => {
    mutate({
      student_id: Number(data.student?.id),
      student_class_id:
        Number(data.studentClass) > 0 ? Number(data.studentClass) : null,
      transactions: allTransactions,
    })
    setTransactionType([])
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onDialogClose()
        mixpanel.track("send_quick_transaction", {
          action: "cancel",
        })
      }}
      titleText={t("transaction.sendTransaction")}
      desktopMaxWidth="1040px"
      tabletMaxWidth="720px"
      isLoading={isLoading}
      onActionButtonClick={handleActionButtonClick}
      actionAcceptButtonProps={{ disabled: isLoading }}
    >
      <form
        onKeyDown={(e) => handleKeyDown(e, handleActionButtonClick, isLoading)}
      >
        <FormProvider {...methods}>
          <SelectStudent />
          <Transactions
            studentId={methods.getValues("student.id")}
            classId={Number(classId)}
            formRoot="transactions"
          />
        </FormProvider>
      </form>
    </Dialog>
  )
}

export default TransactionForm
