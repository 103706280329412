export enum ISuggestedJobsTypes {
  TITLE = "title",
  QUALIFICATIONS = "qualifications",
  RESPONSIBILITIES = "responsibilities",
  SALARY = "salary",
}

export interface IUpdateToggleItem {
  id: number
  text: string
  type: ISuggestedJobsTypes
}
