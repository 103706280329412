import React, { useEffect, useState } from "react"

import { ReactComponent as IconExport } from "@common_assets/svg/export.svg"
import { ReactComponent as IconEyeClosed } from "@common_assets/svg/eye-closed.svg"
import { ReactComponent as IconEyeOpened } from "@common_assets/svg/eye-open.svg"
import { ReactComponent as IconTrashed } from "@common_assets/svg/trash.svg"
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  SelectProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import IconWrapper from "components/common/icon/iconWrapper"
import { useIsBreakpointUp } from "hooks/breakpoint"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { payCheck_interval } from "utils/paycheckInterval"

import { PopRowContent } from "./Content.style"
import { PopoverBox, SelectPropsCustomize } from "./Content.utility"

interface ContentTypes {
  isFetchingExportClass: boolean
  onExportData: () => void
  onJobsOpen: () => void
  handleOpenDialog: () => void
  isOpenApplication?: boolean
  paycheck: {
    isActive?: boolean
    interval?: string
  }
  getAutopayValues: (isActive: boolean, value: string) => void
  onSubmitAutopay: () => void
}

const Content = ({
  isFetchingExportClass,
  onExportData,
  onJobsOpen,
  handleOpenDialog,
  isOpenApplication,
  paycheck,
  getAutopayValues,
  onSubmitAutopay,
}: ContentTypes) => {
  const [paycheckInterval, setPaycheckInterval] = useState(paycheck.interval)
  const [paycheckActive, setPaycheckActive] = useState<boolean>(
    paycheck.isActive ?? false
  )
  const { t } = useTranslation()
  const theme = useTheme()
  const isTablet = useIsBreakpointUp("tablet")

  useEffect(() => {
    if (paycheckInterval) {
      getAutopayValues(paycheckActive, paycheckInterval)
    }
  }, [paycheckActive, paycheckInterval])

  return (
    <ul style={{ width: "250px" }}>
      <PopRowContent
        sx={[isFetchingExportClass ? { opacity: 0.3 } : { opacity: 1 }]}
        onClick={onExportData}
      >
        <li>
          <PopoverBox>
            <IconWrapper>
              <IconExport height="100%" width="100%" />
            </IconWrapper>
            <Typography variant="body2" fontWeight="500">
              {t("jobs.exportData")}
            </Typography>
          </PopoverBox>
        </li>
      </PopRowContent>
      <PopRowContent onClick={onJobsOpen}>
        <li>
          <PopoverBox>
            {isOpenApplication ? (
              <>
                <IconWrapper>
                  <IconEyeClosed />
                </IconWrapper>
                <Typography variant="body2" fontWeight="500">
                  {t("jobs.closeJobBoard")}
                </Typography>
              </>
            ) : (
              <>
                <IconWrapper>
                  <IconEyeOpened />
                </IconWrapper>
                <Typography variant="body2" fontWeight="500">
                  {t("jobs.openJobBoard")}
                </Typography>
              </>
            )}
          </PopoverBox>
        </li>
      </PopRowContent>
      <PopRowContent onClick={handleOpenDialog}>
        <li>
          <PopoverBox>
            <IconWrapper>
              <IconTrashed />
            </IconWrapper>
            <Typography variant="body2" fontWeight="500">
              {t("jobs.clearAllApplications")}
            </Typography>
          </PopoverBox>
        </li>
      </PopRowContent>
      <Divider />
      <Typography variant="body3" color="mockup.neutral50A" pl={1}>
        {t("jobs.autoPay")}
      </Typography>
      <PopRowContent>
        <li>
          <FormControlLabel
            sx={{ ml: 0 }}
            control={
              <Checkbox
                checked={paycheckActive}
                onChange={(e) => {
                  setPaycheckActive(e.target.checked)
                  mixpanel.track("autopay_state", {
                    action: "autopay_checkbox",
                  })
                }}
                sx={{
                  ml: 0,
                  mr: 2,
                }}
              />
            }
            label={
              <Typography variant="body2" fontWeight="500">
                {t("jobs.autoPay")}
              </Typography>
            }
          />
        </li>
      </PopRowContent>
      <PopRowContent
        sx={{
          ":hover": {
            backgroundColor: theme.palette.common.white,
          },
        }}
      >
        <li>
          <TextField
            variant="standard"
            select
            onChange={(e) => {
              mixpanel.track("autopay_state", {
                action: "paycheck_interval",
                interval: paycheckInterval,
              })
              setPaycheckInterval(e.target.value)
            }}
            disabled={!paycheckActive}
            value={paycheckInterval}
            SelectProps={SelectPropsCustomize(isTablet) as SelectProps}
            sx={{
              "& .MuiSelect-icon": {
                transform: "translateY(-50%) rotate(-90deg)",
                top: "50%",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                backgroundColor: theme.palette.common.white,
                fontWeight: 500,
              },
            }}
          >
            {payCheck_interval.map((pay) => (
              <MenuItem key={pay.id} value={pay.value} sx={{ fontWeight: 500 }}>
                {pay.label}
              </MenuItem>
            ))}
          </TextField>
        </li>
      </PopRowContent>
      <Button
        onClick={onSubmitAutopay}
        disabled={
          paycheckActive === paycheck.isActive &&
          paycheckInterval === paycheck.interval
        }
        variant="text"
        fullWidth
        sx={{
          backgroundColor: theme.palette.mockup.primary95,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          py: "12px",
        }}
      >
        {t("defaultTranslations.confirm")}
      </Button>
    </ul>
  )
}
export default Content
