import React, { useEffect, useState } from "react"

import { ReactComponent as ArrowBack } from "@common_assets/svg/arrowBack.svg"
import { ReactComponent as IconFilters } from "@common_assets/svg/filters.svg"
import { ReactComponent as ThreeDots } from "@common_assets/svg/threeDots.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  styled,
} from "@mui/material"
import {
  useMutationEditStudentJobs,
  useMutationRemoveStudent,
  useMutationUpdateStudentClasses,
} from "api/reactQuery/mutations/students"
import {
  useQueryExportJobs,
  useQueryExportTransactions,
} from "api/reactQuery/queries/export"
import { useQueryStudentsClasses } from "api/reactQuery/queries/students"
import { IResponseStudentClasses } from "api/reactQuery/queries/students.types"
import { useQueryStudentRetrieve } from "api/reactQuery/queries/studentsSearch"
import Dialog from "components/common/dialog/dialog"
import DialogUpgradeAccount from "components/common/dialogUpgradeAccount/DialogUpgradeAccount"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import FileField from "components/form/common/field/fileField"
import Accounts from "components/module/studentProfile/accounts/Accounts"
import {
  Transactions,
  AccountInformation,
} from "components/module/studentProfile/index"
import { useBlobData } from "hooks/blobData"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { selectClassname } from "store/utility/utility.selectors"
import { PopRow, PopOverStyle } from "styles/common/poprow"
import { UserSchoolPlan } from "ts/enums/User"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"
import { hiddenExport } from "utils/export"

import IconWrapper from "../../common/icon/iconWrapper"
import Goals from "./goals"
import { ManageStudentClasses, ManageStudentJobs } from "./manageStudent"
import { validateSchema } from "./studentProfile.config"
import { LocationState } from "./StudentProfile.types"
const TabStyle = styled(Tab)({
  paddingTop: "10px",
})

const StudentProfile = () => {
  const location = useLocation()
  const state = location.state as LocationState
  const params = useParams()
  const userInfo = useAppSelector(selectUser)
  const { studentId } = params
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const selectClassName = useAppSelector(selectClassname)

  const theme = useTheme()
  const mobile = useMediaQuery("(max-width:600px)")
  const { t } = useTranslation()

  const TabsStyle = styled(Tabs)({
    "&.MuiTabs-root .Mui-disabled": {
      opacity: mobile ? "0.3" : "0",
    },
  })

  const [errorInfo, setErrorInfo] = useState<string | null>(null)
  const [hover, setHover] = useState(false)
  const [classes, setClasses] = useState<IResponseStudentClasses[]>([])
  const [checked, setChecked] = useState<number[]>([])
  const [checkedJobs, setCheckedJobs] = useState<number[]>([])
  const [selectedClass, setSelectedClass] = useState("")
  const navigate = useNavigate()
  const [val, setVal] = useState<string | null>(null)
  const [fullName, setFullname] = useState("")
  const [isExportData, setIsExportData] = useState({
    jobs: false,
    transactions: false,
  })

  const methods = useForm({
    resolver: yupResolver(validateSchema),
  })

  const historyNavigation = window.history.state.idx

  const setClassId = () => {
    return classes.find((el) => el.name === selectClassName)?.id
  }

  const { isLoading, data, isSuccess } = useQueryStudentRetrieve({
    params: Number(studentId),
    options: {
      cacheTime: 0,
      onSuccess: (data) => {
        setFullname(`${data?.data.first_name} ${data?.data.last_name}`)
      },
      onError: (error) => {
        setErrorInfo(t(getErrorMessage(error)))
      },
    },
  })

  const { payIt, isLoadingPayments } = useCustomPayment()

  const {
    refetch: refetchExportTransactions,
    isFetching: isFetchingExportTransactions,
  } = useQueryExportTransactions({
    studentId: Number(studentId),
    options: {
      enabled: isExportData.transactions,
      onSuccess: (response) => {
        setIsExportData((prev) => ({ ...prev, transactions: false }))
        useBlobData({
          response: response.data,
          classname: fullName,
          exportData: t("studentProfile.transactions"),
        })
        showSnackbar({
          title: t("studentProfile.dataHasBeenSuccessfullyExported"),
        })
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { refetch: refetchExportJobs, isFetching: isFetchingExportJobs } =
    useQueryExportJobs({
      studentId: Number(studentId),
      options: {
        enabled: isExportData.jobs,
        onSuccess: (response) => {
          setIsExportData((prev) => ({ ...prev, jobs: false }))
          useBlobData({
            response: response.data,
            classname: fullName,
            exportData: t("studentProfile.jobs"),
          })
          showSnackbar({
            title: t("studentProfile.dataHasBeenSuccessfullyExported"),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  useEffect(() => {
    methods.setValue("avatar_url", data?.data.avatar_url)
    setVal(methods.getValues("avatar_url"))
  }, [isSuccess])

  useQueryStudentsClasses({
    studentId: Number(studentId),
    options: {
      enabled: !!studentId,
      cacheTime: 0,
      onSuccess: (data) => {
        setClasses(data?.data)
      },
    },
  })

  const { mutate, isLoading: isLoadingUpdateStudentClass } =
    useMutationUpdateStudentClasses({
      options: {
        onSuccess: (_, variable) => {
          if (variable.classes.length === 0) {
            showSnackbar({
              title: t("studentProfile.studentIsntAssignedToAnyClass", {
                firstName: data?.data.first_name,
                lastName: data?.data.last_name,
              }),
              variant: "info",
            })
          }
          queryClient.invalidateQueries("students")
          queryClient.invalidateQueries("classesStudents")
          showSnackbar({
            title: t("studentProfile.updateSucceeded"),
            variant: "success",
          })
          setChecked([])
          handleCloseDialogManageStudentClasses()
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessage(error),
            variant: "error",
          })
        },
      },
    })

  const { mutate: editStudentJobs } = useMutationEditStudentJobs({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("possibleJobs")
        setCheckedJobs([])
        showSnackbar({
          title: t("jobs.editJobsSuccessfully"),
        })
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          showSnackbar({
            title: t("jobs.notAvailablePositionsInJob"),
            variant: "error",
          })
          return
        }
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { mutate: removeStudentProfile } = useMutationRemoveStudent({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("studentProfile.studentProfileIsWasDeleted"),
        })
        navigate(RoutePath.HOMEPAGE)
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
        })
      },
    },
  })

  const {
    isOpen: isDialogOpenManageStudentClasses,
    handleOpen: handleOpenDialogManageStudentClasses,
    handleClose: handleCloseDialogManageStudentClasses,
  } = useDialog()

  const {
    isOpen: isDialogOpenManageStudentJobs,
    handleOpen: handleOpenDialogManageStudentJobs,
    handleClose: handleCloseDialogManageStudentJobs,
  } = useDialog()

  const {
    isOpen: isDialogOpenDeleteStudentProfile,
    handleOpen: handleOpenDialogDeleteStudentProfile,
    handleClose: handleCloseDialogDeleteStudentProfile,
  } = useDialog()

  const {
    isOpen: isDialogOpenUpgradeAccount,
    handleOpen: handleOpenDialogUpgradeAccount,
    handleClose: handleCloseDialogUpgradeAccount,
  } = useDialog()

  const [tab, setTab] = useState("personal_information")
  const { popoverAnchorEl, handleClick, handleClose } =
    usePopover<HTMLButtonElement>()

  const {
    handleClick: filterClick,
    handleClose: filterClose,
    popoverAnchorEl: filterPopover,
  } = usePopover<HTMLButtonElement>()

  const {
    popoverAnchorEl: popoverAnchorElExport,
    handleClick: handleClickExport,
    handleClose: handleCloseExport,
  } = usePopover<HTMLButtonElement>()

  const handleChange = (event: React.SyntheticEvent, newVal: string) => {
    setTab(newVal)
  }

  return (
    <Stack
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid
        container
        flexDirection="column"
        justifyContent="stretch"
        alignItems="start"
        sx={{
          maxWidth: "920px",
          backgroundColor: theme.palette.mockup.neutral100,
          px: { mobile: 1, tablet: 3 },
        }}
      >
        <Grid item display="flex" alignItems="center" sx={{ mt: 4, pl: 0 }}>
          <Button
            sx={{ pl: 0 }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              if (historyNavigation === 0 || state?.name === "searchBar") {
                navigate("/")
                return
              } else {
                navigate(RoutePath.CLASS.replace(":classId", `${setClassId()}`))
              }
            }}
            variant="text"
          >
            <ArrowBack
              style={{
                color: theme.palette.mockup.navy,
                transition: ".3s",
                opacity: hover ? "0.5" : "1",
                marginRight: "16px",
              }}
            />
            {historyNavigation === 0 || state?.name === "searchBar"
              ? t("jobs.backToDashboard")
              : t("jobs.backToClass")}
          </Button>
        </Grid>
        {errorInfo && (
          <Typography variant="h3" color="mockup.error50">
            {errorInfo}
          </Typography>
        )}
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          sx={{ pl: 0, pr: 2, pt: 1, pb: 3 }}
        >
          {isLoading ? (
            <StudentSkeleton height="50px" />
          ) : (
            <Typography variant="h3" color="mockup.neutral10">
              {isSuccess && data?.data.first_name}{" "}
              {isSuccess && data?.data.last_name}
            </Typography>
          )}
          <Box display="flex" columnGap="10px">
            {tab === "transactions" && (
              <Fab sx={{ flexShrink: 0 }} onClick={filterClick}>
                <IconWrapper height="32px" width="32px" color="primary.main">
                  <IconFilters />
                </IconWrapper>
              </Fab>
            )}
            <Fab
              onClick={(event) => {
                handleClick(event)
                mixpanel.track("student_profile_options")
              }}
              disabled={isLoading}
            >
              {isFetchingExportJobs || isFetchingExportTransactions ? (
                <CircularProgress />
              ) : (
                <ThreeDots style={{ margin: "auto" }} />
              )}
            </Fab>

            <PopOverStyle
              open={!!popoverAnchorEl}
              onClose={handleClose}
              anchorEl={popoverAnchorEl}
              marginThreshold={40}
              anchorOrigin={{
                vertical: 60,
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ul>
                <PopRow
                  sx={{ width: "100%" }}
                  onClick={() => {
                    handleOpenDialogManageStudentClasses()
                    mixpanel.track("student_classes_management")
                  }}
                >
                  <li>{t("studentProfile.manageStudentClasses")}</li>
                </PopRow>
                <Dialog
                  titleText={t("studentProfile.manageStudentClasses")}
                  open={isDialogOpenManageStudentClasses}
                  actionAcceptButtonProps={{
                    disabled: isLoadingUpdateStudentClass,
                  }}
                  onClose={() => {
                    setChecked([])
                    handleCloseDialogManageStudentClasses()
                    mixpanel.track("student_classes_management", {
                      action: "cancel",
                    })
                  }}
                  actionAcceptText={t("studentProfile.save")}
                  desktopMaxWidth="534px"
                  tabletMaxWidth="534px"
                  onActionButtonClick={() => {
                    const checkedArr: number[] = []

                    if (
                      checked.length === checkedArr.length &&
                      checked.every((el) => checkedArr.includes(el))
                    ) {
                      showSnackbar({
                        title: t("studentProfile.nothingChange"),
                        variant: "info",
                      })
                      return
                    }

                    classes.forEach((el) => {
                      if (el.is_in_class) {
                        return checkedArr.push(el.id)
                      }
                    })

                    mutate({
                      studentId: Number(studentId),
                      classes: checked,
                    })
                  }}
                >
                  <ManageStudentClasses
                    classes={classes}
                    setChecked={setChecked}
                    checked={checked}
                  />
                </Dialog>
                <PopRow onClick={handleOpenDialogManageStudentJobs}>
                  <li>{t("studentProfile.manageStudentJobs")}</li>
                </PopRow>
                <Dialog
                  titleText={t("studentProfile.manageStudentJobs")}
                  open={isDialogOpenManageStudentJobs}
                  onClose={handleCloseDialogManageStudentJobs}
                  actionAcceptText={t("studentProfile.save")}
                  desktopMaxWidth="534px"
                  tabletMaxWidth="534px"
                  onActionButtonClick={() => {
                    editStudentJobs({
                      studentId: Number(studentId),
                      classId: Number(selectedClass),
                      data: checkedJobs,
                    })
                  }}
                >
                  <ManageStudentJobs
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    studentId={studentId as string}
                    checkedJobs={checkedJobs}
                    setCheckedJobs={setCheckedJobs}
                  />
                </Dialog>
                {hiddenExport(userInfo) && (
                  <PopRow
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      mixpanel.track("reports_export", {
                        event_name: "student_profile_export_data",
                      })
                      userInfo?.user.school_plan === UserSchoolPlan.FREE ||
                      userInfo?.user.school_plan === UserSchoolPlan.DEMO
                        ? handleOpenDialogUpgradeAccount()
                        : handleClickExport(e)
                    }}
                  >
                    <li>{t("studentProfile.exportData")}</li>
                  </PopRow>
                )}
                <DialogUpgradeAccount
                  open={isDialogOpenUpgradeAccount}
                  onClose={() => {
                    handleCloseDialogUpgradeAccount()
                    mixpanel.track("reports_export", {
                      event_name: "student_profile_export_data",
                      action: "cancel",
                    })
                  }}
                  onActionButtonClick={() => {
                    payIt(null)
                    mixpanel.track("reports_export", {
                      distinct_id: `${userInfo?.user.id}`,
                      method: "student_profile",
                      action: "submit",
                    })
                  }}
                  isLoading={isLoadingPayments}
                  isExport
                />
                <PopOverStyle
                  open={!!popoverAnchorElExport}
                  onClose={handleCloseExport}
                  anchorEl={popoverAnchorElExport}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                >
                  <PopRow
                    style={{
                      pointerEvents:
                        isFetchingExportJobs || isFetchingExportTransactions
                          ? "none"
                          : "all",
                      opacity:
                        isFetchingExportJobs || isFetchingExportTransactions
                          ? "0.7"
                          : "1",
                    }}
                    onClick={() => {
                      refetchExportTransactions()
                      setIsExportData((prev) => ({
                        ...prev,
                        transactions: true,
                      }))
                    }}
                  >
                    {t("studentProfile.studentTransactions")}
                  </PopRow>
                  <PopRow
                    style={{
                      pointerEvents:
                        isFetchingExportJobs || isFetchingExportTransactions
                          ? "none"
                          : "all",
                      opacity:
                        isFetchingExportJobs || isFetchingExportTransactions
                          ? "0.7"
                          : "1",
                    }}
                    onClick={() => {
                      refetchExportJobs()
                      setIsExportData((prev) => ({ ...prev, jobs: true }))
                    }}
                  >
                    {t("studentProfile.studentJobs")}
                  </PopRow>
                </PopOverStyle>
                <PopRow onClick={handleOpenDialogDeleteStudentProfile}>
                  <li>{t("studentProfile.deleteProfile")}</li>
                </PopRow>
                <Dialog
                  titleText={t(
                    "studentProfile.areYouSureYouWantToDeleteProfile",
                    {
                      firstName: data?.data.first_name,
                      lastName: data?.data.last_name,
                    }
                  )}
                  open={isDialogOpenDeleteStudentProfile}
                  onClose={handleCloseDialogDeleteStudentProfile}
                  actionAcceptText={t("jobs.delete")}
                  onActionButtonClick={() => {
                    removeStudentProfile({
                      studentId: Number(params.studentId),
                    })
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="700"
                    color="mockup.error50"
                  >
                    {t("studentProfile.thisActionCannotBeUndone").toUpperCase()}
                  </Typography>
                </Dialog>
              </ul>
            </PopOverStyle>
          </Box>
        </Grid>
        <Grid item width="100%">
          <Box>
            <TabsStyle
              onChange={handleChange}
              value={tab}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <TabStyle
                value="personal_information"
                label={t("studentProfile.personalInformation")}
              />
              <TabStyle
                value="transactions"
                label={t("studentProfile.transactions")}
              />
              <TabStyle value="accounts" label={t("studentProfile.accounts")} />
              <TabStyle value="goals" label={t("studentProfile.goals")} />
            </TabsStyle>
          </Box>
          {isLoading ? (
            <StudentSkeleton height="150px" />
          ) : (
            <>
              {tab === "personal_information" && (
                <>
                  <Grid item>
                    <Typography
                      display="block"
                      variant="body3"
                      color="mockup.neutral40"
                      pb="12px"
                      pt="24px"
                    >
                      Picture
                    </Typography>
                  </Grid>
                  <form
                    onSubmit={methods.handleSubmit((data) =>
                      setVal(data.avatar_url)
                    )}
                  >
                    <FormProvider {...methods}>
                      <Grid
                        item
                        display="flex"
                        alignItems={mobile ? "flex-start" : "center"}
                        flexDirection={mobile ? "column" : "row"}
                        rowGap={1}
                      >
                        <FileField
                          fileType="image/png, image/jpeg"
                          name="avatar_url"
                          onImageAdd={(e) => setVal(e)}
                          onImageRemove={() => setVal(null)}
                        />
                      </Grid>
                    </FormProvider>
                  </form>
                  <Divider
                    style={{
                      width: "100%",
                      margin: "24px 0",
                      borderColor: theme.palette.mockup.neutral90,
                    }}
                  />
                </>
              )}
            </>
          )}
          {tab === "personal_information" && (
            <AccountInformation
              setClassId={setClassId}
              val={val}
              userInfo={data?.data}
              isLoadingRetrieveStudent={isLoading}
              state={state}
            />
          )}
          {tab === "transactions" && (
            <Transactions
              filterClose={filterClose}
              filterPopover={filterPopover}
            />
          )}
          {tab === "accounts" && <Accounts />}
          {tab === "goals" && <Goals />}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default StudentProfile
