import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IDisplayModeState } from "./displayMode.types"

const initialState: IDisplayModeState = {
  displayMode: "list",
  displaySavings:
    localStorage.getItem("switchState") === null
      ? false
      : JSON.parse(localStorage.getItem("switchState") as string),
}

export const displayModeSlice = createSlice({
  name: "displayMode",
  initialState,
  reducers: {
    setDisplayMode: (
      state,
      { payload }: PayloadAction<IDisplayModeState["displayMode"]>
    ) => {
      state.displayMode = payload
    },
    setDisplaySavings: (
      state,
      { payload }: PayloadAction<IDisplayModeState["displaySavings"]>
    ) => {
      state.displaySavings = payload
    },
  },
})

export const { setDisplayMode, setDisplaySavings } = displayModeSlice.actions

export default displayModeSlice.reducer
