import { Dispatch, FC } from "react"

import { Checkbox } from "@mui/material"
import { IResponseGetStudentPossibleJob } from "api/reactQuery/queries/students.types"
import Coin from "components/common/icon/coin"
import { Cell, Row } from "components/common/table/basicTable"

interface IProps {
  checkedJobs: number[]
  setCheckedJobs: Dispatch<React.SetStateAction<number[]>>
  allJobs?: IResponseGetStudentPossibleJob[]
}

const TableRow: FC<IProps> = ({ allJobs, checkedJobs, setCheckedJobs }) => {
  const handleMarkedJobs = (jobInfo: IResponseGetStudentPossibleJob) => {
    const currentPosition = checkedJobs.findIndex((el) => el === jobInfo.id)
    const newChecked = [...checkedJobs]

    if (currentPosition === -1) {
      newChecked.push(jobInfo.id)
      jobInfo.is_hired = true
    } else {
      newChecked.splice(currentPosition, 1)
      jobInfo.is_hired = false
    }
    setCheckedJobs(newChecked)
  }

  return (
    <>
      {allJobs?.map((jobInfo) => (
        <Row key={jobInfo.title}>
          <Cell>
            <Checkbox
              style={{ margin: 0 }}
              checked={jobInfo.is_hired}
              onChange={() => handleMarkedJobs(jobInfo)}
            />
          </Cell>
          <Cell
            sx={{
              color: "mockup.neutral10",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {jobInfo.title}
          </Cell>
          <Cell
            sx={{
              color: "mockup.neutral10",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {jobInfo.employees_count}/
            {jobInfo.positions === null ? "999" : jobInfo.positions}
          </Cell>
          <Cell>
            <Coin amount={`${jobInfo.salary}`} />
          </Cell>
        </Row>
      ))}
    </>
  )
}

export default TableRow
