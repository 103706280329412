import { Params } from "react-router"
import { IRetrieveStudent, IStudentProfile } from "ts/interfaces/Student"
import { passwordStudentSchema, requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  login: "",
  first_name: "",
  last_name: "",
  password: "",
  avatar_url: "",
}

export const accountSchema = yup.object({
  login: requiredStringSchema.min(3),
  first_name: yup.string().max(255).required().min(2),
  last_name: yup.string().max(255).required().min(2),
  password: yup
    .string()
    .notRequired()
    .test("password", "At least 6 characters", function (val) {
      if (!!val) {
        return passwordStudentSchema.isValid(val)
      }
      return true
    }),
})

export const checkInputFields = (
  data: IStudentProfile,
  cb: (arg: { data: IStudentProfile; params: number }) => void,
  params: Readonly<Params<string>>,
  val: string | null,
  userInfo?: IRetrieveStudent
) => {
  if (
    data.password === "" &&
    data.login === userInfo?.login &&
    userInfo?.avatar_url === val
  ) {
    cb({
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
      },
      params: Number(params.studentId),
    })
    return
  }

  if (data.password === "" && data.login === userInfo?.login) {
    cb({
      data: {
        avatar_url: val === null || val === undefined ? null : val,
        first_name: data.first_name,
        last_name: data.last_name,
      },
      params: Number(params.studentId),
    })
    return
  }

  if (data.password === "" && userInfo?.avatar_url === val) {
    cb({
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        login: data.login as string,
      },
      params: Number(params.studentId),
    })
    return
  }

  if (userInfo?.avatar_url === val) {
    cb({
      data: {
        login: data.login as string,
        first_name: data.first_name,
        password: data.password,
        last_name: data.last_name,
      },
      params: Number(params.studentId),
    })
    return
  }

  if (data.login === userInfo?.login) {
    cb({
      data: {
        avatar_url: val === null || val === undefined ? null : val,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
      },
      params: Number(params.studentId),
    })
    return
  }

  if (data.password === "") {
    cb({
      data: {
        avatar_url: val === null || val === undefined ? null : val,
        login: data.login as string,
        first_name: data.first_name,
        last_name: data.last_name,
      },
      params: Number(params.studentId),
    })
    return
  }

  cb({
    data: {
      avatar_url: val === null || val === undefined ? null : val,
      login: data.login as string,
      first_name: data.first_name,

      password: data.password,
      last_name: data.last_name,
    },
    params: Number(params.studentId),
  })
}
