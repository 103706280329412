import React from "react"

import { ReactComponent as IconClock } from "@common_assets/svg/clock.svg"
import { ReactComponent as Trash } from "@common_assets/svg/trash.svg"
import { Checkbox, Fab, Stack, Typography } from "@mui/material"
import { ITeacherTransactions } from "api/reactQuery/queries/transaction.types"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { Cell, Row } from "components/common/table/basicTable"
import TransactionType from "components/common/transactionType"
import { format } from "date-fns"

interface BodyAllTransactionsProps {
  data: ITeacherTransactions
  onClick: () => void
  getChecked: boolean
  onChange: () => void
}

const BodyAllTransactions = ({
  data,
  onClick,
  getChecked,
  onChange,
}: BodyAllTransactionsProps) => {
  const isPendingTransaction = data?.is_booked

  const getPersonOfTransaction = (data: ITeacherTransactions) => {
    if (data.teacher === null && data.helper === null) {
      return <span>-</span>
    }

    if (data.teacher) {
      return data.teacher?.last_name
    }

    if (data.helper) {
      return `Banker - ${data.helper?.last_name}`
    }
  }

  return (
    <Row>
      <Cell>
        <Checkbox checked={getChecked} onChange={onChange} />
      </Cell>
      <Cell position="relative">
        {!isPendingTransaction && (
          <IconWrapper
            height="19px"
            width="19px"
            sx={{
              position: "absolute",
              top: "1px",
              right: "10px",
              backgroundColor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconClock />
          </IconWrapper>
        )}
        <TransactionType transaction={data} />
      </Cell>
      <Cell>
        <Stack>
          <Typography variant="body2" color="mockup.neutral10">
            {data.class_name}
          </Typography>
          <Typography
            variant="body2"
            color="mockup.neutral10"
            textAlign="center"
            fontWeight="500"
          >
            {getPersonOfTransaction(data)}
          </Typography>
        </Stack>
      </Cell>
      <Cell>
        <Typography fontWeight="500" variant="body2" color="mockup.neutral10">
          {data.student.first_name} {data.student.last_name}
        </Typography>
      </Cell>
      <Cell>
        <Typography fontWeight="500" variant="body2" color="mockup.neutral10">
          {data.title}
        </Typography>
      </Cell>
      <Cell>
        <Typography variant="body2" color="mockup.neutral10" fontWeight="500">
          {format(new Date(data.created_at), "MMM dd, yyyy")}
        </Typography>
      </Cell>
      <Cell>
        {data.amount > 0 ? (
          <Coin amount={`${data.amount}`} sign="+" />
        ) : (
          <Coin amount={`${data.amount}`.slice(1)} sign="-" />
        )}
      </Cell>
      <Cell>
        <Fab size="small" disabled={getChecked}>
          <Trash onClick={onClick} />
        </Fab>
      </Cell>
    </Row>
  )
}
export default BodyAllTransactions
