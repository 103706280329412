import { useQueryClassesById } from "api/reactQuery/queries/classes"
import { useClassId } from "hooks/navigation"
import { useAppDispatch } from "hooks/store"
import { useNavigate } from "react-router"
import { RoutePath } from "routes/Route.types"
import { setClassname } from "store/utility/utility.slice"
import { setLocalStorageClassname } from "utils/classname"

import SubNavigation from "../subNavigation"

const SubNavigationTeacher = () => {
  const navigate = useNavigate()
  const classId = useClassId()
  const dispatch = useAppDispatch()

  const { data, isLoading, isError } = useQueryClassesById({
    id: Number(classId),
    options: {
      enabled: !!classId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 0,
      onSuccess: (data) => {
        setLocalStorageClassname(data?.data.name)
        dispatch(setClassname(data?.data.name))
      },
      onError: (data) => {
        if (data.response?.status === 404) {
          navigate(RoutePath.PAGE404_NOT_FOUND)
        }
      },
    },
  })

  return (
    <SubNavigation
      routeName={data?.data.name}
      iconName={data?.data.icon}
      isLoading={isLoading}
      isError={isError}
    />
  )
}

export default SubNavigationTeacher
