import { FC, useState } from "react"

import { ReactComponent as IconShield } from "@common_assets/svg/shield-cross-solid.svg"
import { Box, ThemeProvider, Typography } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import reactQueryClient from "api/reactQuery"
import { AxiosError } from "axios"
import { SnackbarProvider } from "notistack"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import store from "store"
import theme from "themes"

import "i18n"
import axiosInstance from "./api"
import { useQueryUserMe } from "./api/reactQuery/queries/userMe"
import Dialog from "./components/common/dialog/dialog"
import IconWrapper from "./components/common/icon/iconWrapper"
import DowntimeError from "./components/module/downtimeError"
import { useDialog } from "./hooks/dialog"
export interface ErrorResponse {
  error: string
}

const AppBoundaryError = ({ children }: { children: React.ReactNode }) => {
  const [errorText, setErrorText] = useState("")
  const [axiosErrorObject, setAxiosErrorObject] =
    useState<AxiosError<ErrorResponse> | null>(null)
  const { isOpen, handleOpen, handleClose } = useDialog()
  const {
    isOpen: isOpenServerError,
    handleOpen: handleOpenServerError,
    handleClose: handleCloseServerError,
  } = useDialog()

  const { isSuccess } = useQueryUserMe({
    options: {
      enabled: isOpenServerError,
      refetchInterval: 10 * 1000,
    },
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      handleClose()
      if (isSuccess && isOpenServerError) {
        handleCloseServerError()
        window.location.reload()
      }
      setErrorText("")
      return response
    },
    (error) => {
      if (error.response && error.response.status === 429) {
        setErrorText(error.response.data.error)
        handleOpen()
      }

      if (
        (error.response &&
          error.response.status >= 500 &&
          error.response.status < 600) ||
        (error.message &&
          (error.message.includes("CORS") ||
            error.message.includes("Network Error")))
      ) {
        setAxiosErrorObject(error)
        handleOpenServerError()
      }

      return Promise.reject(error)
    }
  )

  return (
    <>
      <Dialog
        open={isOpen}
        titleText={errorText}
        titleBeforeSlot={
          <IconWrapper height="48px" width="48px">
            <IconShield height="100%" width="100%" />
          </IconWrapper>
        }
        customActionButtonsSlot
      >
        <Box>
          <Typography variant="subtitle1">
            Relax and calm down, make some tea and return to the application in
            about 1 minute
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        onIconClose={false}
        fullScreen
        open={isOpenServerError}
        customActionButtonsSlot
      >
        <DowntimeError axiosErrorObject={axiosErrorObject} />
      </Dialog>
      {children}
    </>
  )
}

const AppProviders: FC = ({ children }) => {
  const isTouchDevice = () => ("ontouchstart" in window ? true : false)
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

  return (
    <Provider store={store}>
      <QueryClientProvider client={reactQueryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={theme}>
            <DndProvider backend={backendForDND}>
              <SnackbarProvider autoHideDuration={3000}>
                <CssBaseline />
                <AppBoundaryError>
                  <Router>{children}</Router>
                </AppBoundaryError>
              </SnackbarProvider>
            </DndProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default AppProviders
