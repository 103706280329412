import { RoutePath } from "routes/Route.types"

export const storeRoutes = [
  {
    path: RoutePath.CLASS_STORE,
    tabName: "storeTransactions",
    labelKey: "store.storeTransactions",
    activeOnPaths: [RoutePath.CLASS_STORE],
  },
  {
    path: RoutePath.CLASS_STORE_ALL_ITEMS,
    tabName: "allItems",
    labelKey: "store.allItems",
    activeOnPaths: [RoutePath.CLASS_STORE_ALL_ITEMS],
  },
]
