import React, { FC } from "react"

import { ReactComponent as Check } from "@common_assets/svg/check.svg"
import { ReactComponent as Cross } from "@common_assets/svg/cross.svg"
import { ReactComponent as Document } from "@common_assets/svg/document-outlined.svg"
import {
  Avatar,
  Box,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { IGetPendingApplicationStudents } from "api/reactQuery/queries/jobs.types"
import { useTranslation } from "react-i18next"

import ToolTipDots from "../textField/toolTipDots"

interface IProps {
  onApply?: (id: number) => void
  onCancel?: (id: number) => void
  onApplication?: (id: number) => void
  studentList: IGetPendingApplicationStudents[]
}

const StudentList: FC<IProps> = ({
  onApply,
  onCancel,
  onApplication,
  studentList,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isToolTip = useMediaQuery("(max-width:550px)")

  return (
    <List>
      {studentList.map((student) => (
        <ListItem
          sx={{
            paddingLeft: "0",
            height: "56px",
            "&:nth-of-type(2n)": {
              background: theme.palette.mockup.primary99,
              borderRadius: 2,
            },
          }}
          key={student.id}
          secondaryAction={
            <Box>
              {student.job_application_id !== null && onApplication && (
                <Tooltip title={t("jobs.viewApplication")} placement="bottom">
                  <Fab
                    size="small"
                    sx={{ mr: "13px" }}
                    onClick={() => onApplication(student.id)}
                  >
                    <Document />
                  </Fab>
                </Tooltip>
              )}
              {onApply && (
                <Tooltip title={t("jobs.acceptStudent")}>
                  <Fab
                    size="small"
                    sx={{ mr: "13px" }}
                    onClick={() => onApply(student.id)}
                  >
                    <Check />
                  </Fab>
                </Tooltip>
              )}
              {onCancel && (
                <Tooltip title={t("jobs.removeStudent")}>
                  <Fab size="small" onClick={() => onCancel(student.id)}>
                    <Cross />
                  </Fab>
                </Tooltip>
              )}
            </Box>
          }
        >
          <ListItemAvatar>
            <Avatar
              src={student.avatar_url}
              alt="avatar"
              style={{
                width: "36px",
                height: "36px",
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <ToolTipDots
                placement="bottom-start"
                title={
                  isToolTip ? `${student.first_name} ${student.last_name}` : ""
                }
                width="100%"
                customProps={{
                  maxWidth: "100%",
                  paddingRight: student.job_application_id ? "100px" : "20px",
                }}
              >
                {student.first_name} {student.last_name}
              </ToolTipDots>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default StudentList
