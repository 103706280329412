import React from "react"

import { Button, Typography } from "@mui/material"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { setAuthData } from "store/auth/auth.slice"

const Second = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h2" sx={{ textAlign: "left" }}>
        {t("noAuthFlow.enterEmailToResetYourPassword")}
      </Typography>
      <Typography
        color="mockup.neutral70"
        fontWeight="400"
        lineHeight="22px"
        fontFamily="Montserrat, sans-serif"
        sx={{ mt: 2, mb: 4, textAlign: "left" }}
      >
        {t(
          "noAuthFlow.checkYourEmailAndUseTheResetLinkToCreateANewPasswordPasswordResetEmailsCouldTakeUpToAnHourToAppearInYourInbox"
        )}
      </Typography>
      <Button
        fullWidth
        onClick={() => {
          navigate(RoutePath.HOMEPAGE)
          dispatch(setAuthData(null))
        }}
      >
        {t("noAuthFlow.backToHomePage")}
      </Button>
    </>
  )
}

export default Second
