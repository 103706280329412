import { FC } from "react"

import { Box, Button, CheckboxProps, Stack } from "@mui/material"
import TransactionActionButtons from "components/common/button/transactionActionButtons"
import CheckableItem from "components/common/listing/checkableItem"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ITransactionItemClass } from "ts/interfaces/Transaction"

interface IProps {
  transactions: ITransactionItemClass[]
  getListItemCheckboxProps: (el: ITransactionItemClass) => CheckboxProps
  countsStat: {
    setCount: React.Dispatch<React.SetStateAction<number>>
    count: number
  }
  sortedDataLength: number
}

const Listing: FC<IProps> = ({
  transactions,
  getListItemCheckboxProps,
  countsStat,
  sortedDataLength,
}) => {
  const { count, setCount } = countsStat
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const classId = useClassId()
  const next10Row = () => {
    setCount((prev) => prev + 10)
  }

  return (
    <Stack>
      <TransitionGroup component={null}>
        {transactions.map((transaction) => (
          <CSSTransition
            key={transaction.id}
            classNames="animation-fade"
            timeout={700}
            unmountOnExit
          >
            <CheckableItem
              topText={`${transaction.first_name} ${transaction.last_name}`}
              bottomTextTitle={t("store.item")}
              bottomText={transaction.title}
              value={transaction.value}
              actionButtonsSlot={
                <TransactionActionButtons
                  id={transaction.id}
                  onSuccessCallback={() => {
                    queryClient.invalidateQueries([
                      "classesStoreTransactions",
                      Number(classId),
                    ])
                  }}
                />
              }
              checkboxProps={getListItemCheckboxProps(transaction)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      <>
        {sortedDataLength > count && (
          <Box textAlign="center">
            <Button variant="text" onClick={next10Row}>
              {t("store.loadMore")}
            </Button>
          </Box>
        )}
      </>
    </Stack>
  )
}

export default Listing
