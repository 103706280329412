import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IAddNewClassArgs,
  IDeleteClassArgs,
  IEditClassArgs,
  IPostAddNewClass,
  IPostDeleteClass,
  IPostEditClass,
  IPostMathClass,
  IPostMathClassArgs,
  IResponseAddNewClass,
  IResponseEditClass,
} from "./classes.type"

export const useMutationAddNewClass = (arg?: IAddNewClassArgs) =>
  useMutation<
    AxiosResponse<IResponseAddNewClass>,
    AxiosError,
    IPostAddNewClass
  >(async (data) => {
    return await axiosInstance.post(Endpoint.CLASSES, data)
  }, arg?.options)

export const useMutationEditClass = (arg?: IEditClassArgs) =>
  useMutation<AxiosResponse<IResponseEditClass>, AxiosError, IPostEditClass>(
    async ({ classId, data }) => {
      return await axiosInstance.patch(`${Endpoint.CLASSES}/${classId}`, data)
    },
    arg?.options
  )

export const useMutationDeleteClass = (args?: IDeleteClassArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostDeleteClass>(
    async ({ classId }) => {
      return await axiosInstance.delete(`${Endpoint.CLASSES}/${classId}`)
    },
    args?.options
  )

export const useMutationMathClass = (args?: IPostMathClassArgs) =>
  useMutation<AxiosResponse<string>, AxiosError, IPostMathClass>(
    async (data) => {
      return await axiosInstance.post(
        `${Endpoint.STUDENTS_MATH_MANAGEMENT}`,
        data
      )
    },
    args?.options
  )
