import { FC, memo } from "react"

import { ReactComponent as IconImage } from "@common_assets/svg/image-outlined.svg"
import { Checkbox, CheckboxProps, Typography, Avatar } from "@mui/material"
import ActionButtons from "components/common/button/actionButtons"
import Coin from "components/common/icon/coin"
import { Cell, Row } from "components/common/table/basicTable"
import { userIsFreeDemoSchoolPlan } from "hooks/schoolPlans"
import { IClassStoreItem } from "ts/interfaces/Class"

interface IProps {
  item: IClassStoreItem
  getCheckboxProps: (el: IClassStoreItem) => CheckboxProps
}

const TableRow: FC<IProps> = ({ item, getCheckboxProps }) => {
  return (
    <Row>
      <Cell px="8px">
        <Checkbox {...getCheckboxProps(item)} sx={{ margin: "auto" }} />
      </Cell>
      <Cell py="8px" gap="8px" sx={{ opacity: item.out_of_stock ? 0.5 : 1 }}>
        {item.icon_url ? (
          <img src={item.icon_url} alt={item.name} width="32px" height="32px" />
        ) : (
          <Avatar sx={{ height: "32px", width: "32px" }}>
            <IconImage />
          </Avatar>
        )}
        <Typography variant="body2" fontWeight={500} color="mockup.neutral10">
          {item.name}
        </Typography>
      </Cell>
      {!userIsFreeDemoSchoolPlan() && (
        <Cell sx={{ opacity: item.out_of_stock ? 0.5 : 1 }}>
          <Typography width="100%" textAlign="center">
            {item.quantity_in_stock === null ? "-" : item.quantity_in_stock}
          </Typography>
        </Cell>
      )}
      <Cell sx={{ opacity: item.out_of_stock ? 0.5 : 1 }}>
        <Coin amount={`${item.price}`} />
      </Cell>
      <Cell>
        <ActionButtons item={item} />
      </Cell>
    </Row>
  )
}

export default memo(TableRow)
