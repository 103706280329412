import React, { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { useMutationGoogleLogin } from "api/reactQuery/mutations/googleLogin"
import { useMutationPostTeacher } from "api/reactQuery/mutations/teacher"
import { useQueryTeacherRegistrationData } from "api/reactQuery/queries/teacherRegistrationData"
import { IResponseTeacherRegistrationData } from "api/reactQuery/queries/teacherRegistrationData.types"
import RegisterButtons from "components/common/button/registerButtons"
import GoogleButton from "components/common/googleButton"
import RegisterInformation from "components/common/registerInformation"
import RegisterInput from "components/common/textField/registerInput"
import { useGoogle } from "hooks/google"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectRegistrationData } from "store/auth/auth.selectors"
import {
  setActivePageSignUp,
  setAuthData,
  setTeacherRegistrationData,
} from "store/auth/auth.slice"
import { UserRole } from "ts/enums/User"
import { getErrorMessage } from "utils/api"

import CheckboxField from "./checkboxField"
import { defaultValues, validationSchema } from "./SignAsTeacher.config"
import { ITeacherState } from "./SignAsTeacher.types"

const SignAsTeacher = () => {
  const dispatch = useAppDispatch()
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const teacherRegisterData = useAppSelector(selectRegistrationData)

  const [checked, setChecked] = useState(false)
  const [error, setError] = useState("")
  const [, setSearchParams] = useSearchParams()

  const methods = useForm<ITeacherState>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setSearchParams({ token: localStorage.getItem("token") ?? "" })
    }
  }, [])

  useQueryTeacherRegistrationData({
    token: window.location.search.slice(7),
    options: {
      enabled: !!window.location.search,
      onSuccess: (data) => {
        dispatch(
          setTeacherRegistrationData(
            data?.data as IResponseTeacherRegistrationData
          )
        )
      },
    },
  })

  useEffect(() => {
    methods.setValue(
      "email",
      !!teacherRegisterData ? teacherRegisterData.email : ""
    )
  }, [teacherRegisterData])

  useEffect(() => {
    if (checked) {
      setError("")
    }
  }, [checked])

  const { mutate: createTeacher, isLoading: isLoadingCreateTeacher } =
    useMutationPostTeacher({
      options: {
        onSuccess: (data) => {
          showSnackbar({
            title: t("noAuthFlow.emailAndPasswordCreated"),
          })
          localStorage.removeItem("token")
          dispatch(
            setAuthData({
              token: data.data.access_token,
              finalized: !data.data.finalization_required,
              role: UserRole.TEACHER,
              is_email_confirmed: data.data.is_email_confirmed,
            })
          )
          {
            data.data.finalization_required
              ? dispatch(setActivePageSignUp(2))
              : navigate(RoutePath.HOMEPAGE)
          }
        },
        onError: (err) => {
          showSnackbar({
            title: t(getErrorMessage(err)),
            variant: "error",
          })
        },
      },
    })

  const onSubmit = async (data: { email: string; password: string }) => {
    createTeacher({
      email: data.email,
      password: data.password,
    })
  }

  const { mutate } = useMutationGoogleLogin({
    option: {
      onSuccess: (data) => {
        dispatch(
          setAuthData({
            token: data.data.access_token,
            role: UserRole.TEACHER,
            finalized: !data.data.finalization_required,
            is_email_confirmed: true,
          })
        )
        showSnackbar({
          title: t(
            data.data.finalization_required
              ? "noAuthFlow.emailAndPasswordCreated"
              : "noAuthFlow.loginSuccessful"
          ),
        })
        if (data.data.finalization_required) {
          dispatch(setActivePageSignUp(2))
        } else {
          navigate(RoutePath.HOMEPAGE)
        }
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessage(error)),
          variant: "error",
        })
      },
    },
  })

  const { refButton } = useGoogle({ mutate })

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Box textAlign="left">
          <Typography
            variant="body1"
            color="primary.main"
            fontSize="24px"
            lineHeight="24px"
            fontWeight="600"
          >
            {t("noAuthFlow.teacher")}
          </Typography>
          <Typography
            variant="h2"
            color="mockup.neutral50"
            sx={{ pt: 1, pb: 4 }}
          >
            {t("noAuthFlow.signup")}
          </Typography>
        </Box>
        <GoogleButton
          text={t("noAuthFlow.signUpGoogle")}
          onClick={() => !checked && setError(t("validations.checkTerms"))}
          checked={checked}
          isSignUp
          ref={refButton}
        />
        <Typography variant="subtitle1" color="mockup.grey80" py={2}>
          {t("defaultTranslations.or")}
        </Typography>
        <Box>
          <RegisterInput name="email" type="email" isSignUp />
          <CheckboxField
            name="terms"
            error={error}
            onClick={() => setChecked(!checked)}
          />
          <RegisterButtons
            isLoading={isLoadingCreateTeacher}
            submitButton={t("noAuthFlow.signup")}
            onClick={() =>
              (window.location.href = "https://www.classbank.com/")
            }
          />
          <RegisterInformation isSignInOrUp />
        </Box>
      </FormProvider>
    </form>
  )
}

export default SignAsTeacher
