import { FC, useState } from "react"

import { Stack } from "@mui/material"
import { useQueryClassesTransactionItems } from "api/reactQuery/queries/classesTransactionItems"
import BasicItem from "components/common/listing/basicItem"
import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import { useDialog } from "hooks/dialog"
import { useSorting } from "hooks/listing"
import { useFilterItems } from "hooks/listing/filtering"
import { useClassId } from "hooks/navigation"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { PrimaryTile } from "styles/common/tile"
import { ITransactionItem } from "ts/interfaces/Transaction"
import { TransactionTypeShort } from "ts/types/Transaction"

import ActionButtons from "../common/actionButtons"
import DataFetchingStatus from "../common/dataFetchingStatus"
import DialogAddItem from "../common/dialogAddItem"
import DialogAddSuggested from "../common/dialogAddSuggested"
import DialogDeleteItem from "../common/dialogDeleteItem"
import DialogEditItem from "../common/dialogEditItem"
import SearchbarAndAddButton from "../common/searchbarAndAddButton"
import { getSetupClassTranslation } from "../SetupClassTeacher.utils"
import { getOptions } from "./Listing.utils"

interface IProps {
  variant: TransactionTypeShort
}

const Listing: FC<IProps> = ({ variant }) => {
  const { t } = useTranslation()
  const tKey = getSetupClassTranslation(variant)
  const classId = useClassId()

  const { data, isLoading, isError } = useQueryClassesTransactionItems({
    classId: Number(classId),
    variant,
    options: {
      enabled: typeof Number(classId) === "number",
    },
  })

  const { filteredData, searchFilter, setSearchFilter } = useFilterItems({
    data: data ? data.data : [],
    getFilterBy: (el) => el.title,
  })

  const { sortedData, sortingState, setSortingState } = useSorting({
    data: filteredData,
  })

  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete,
  } = useDialog()

  const {
    isOpen: isOpenEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit,
  } = useDialog()

  const {
    isOpen: isOpenAdd,
    handleOpen: handleOpenAdd,
    handleClose: handleCloseAdd,
  } = useDialog()

  const {
    isOpen: isOpenAddSuggested,
    handleOpen: handleOpenAddSuggested,
    handleClose: handleCloseAddSuggested,
  } = useDialog()

  const [selectedItem, setSelectedItem] = useState<ITransactionItem | null>(
    null
  )

  return (
    <>
      <PrimaryTile sx={{ borderRadius: "0 8px 8px 8px" }}>
        <SearchbarAndAddButton
          variant={variant}
          onAddItemClick={() => {
            handleOpenAdd()
            mixpanel.track("add_class_settings", {
              action: `add_${variant}_searchbar`,
            })
          }}
          onSearchFormSubmit={(data) => {
            setSearchFilter(data.search)
          }}
        />
        <ListingSortingHeader
          sortingText={
            sortingState.orderBy === "title"
              ? t(tKey.items)
              : sortingState.orderBy === "amount"
              ? t("setup.value")
              : t("setup.none")
          }
          sortingActive={!!sortingState.orderBy}
          sortingDirection={sortingState.order}
          onSubmit={({ sort }) => {
            setSortingState(JSON.parse(sort) as typeof sortingState)
          }}
          options={getOptions(variant)}
        />
        <Stack>
          <TransitionGroup component={null}>
            {data &&
              !isLoading && [
                ...sortedData.map((el) => (
                  <CSSTransition
                    key={el.id}
                    classNames="animation-fade"
                    timeout={700}
                    unmountOnExit
                  >
                    <BasicItem
                      topTextTitle={t("setup.item")}
                      topText={el.title}
                      actionButtonsSlot={
                        <ActionButtons
                          variant={variant}
                          handleOpenDelete={() => {
                            setSelectedItem(el)
                            handleOpenDelete()
                          }}
                          handleOpenEdit={() => {
                            setSelectedItem(el)
                            handleOpenEdit()
                          }}
                        />
                      }
                      value={el.amount}
                    />
                  </CSSTransition>
                )),
              ]}
          </TransitionGroup>
          <DataFetchingStatus
            variant={variant}
            isLoading={isLoading}
            isError={isError}
            data={data?.data}
            sortedAndFilteredData={sortedData}
            searchFilter={searchFilter}
            onAddItemButtonClick={handleOpenAdd}
            onAddSuggestedClick={handleOpenAddSuggested}
          />
        </Stack>
      </PrimaryTile>
      <DialogDeleteItem
        item={selectedItem}
        variant={variant}
        isOpen={isOpenDelete}
        handleClose={(reason) => {
          handleCloseDelete()
          if (!isOpenEdit || reason === "itemDeleted") setSelectedItem(null)
        }}
      />
      <DialogEditItem
        item={selectedItem}
        variant={variant}
        isOpen={isOpenEdit}
        handleClose={() => {
          handleCloseEdit()
          setSelectedItem(null)
        }}
        handleOpenDelete={handleOpenDelete}
      />
      <DialogAddItem
        variant={variant}
        isOpened={isOpenAdd}
        onClose={handleCloseAdd}
      />
      <DialogAddSuggested
        variant={variant}
        isOpened={isOpenAddSuggested}
        onClose={() => handleCloseAddSuggested()}
      />
    </>
  )
}

export default Listing
