import { ThemeOptions } from "@mui/material"

const muiRadio: ThemeOptions["components"] = {
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        margin: "0 8px",
        color: theme.palette.mockup.primary90,
        ":hover": {
          transitionDuration: "300ms",
          boxShadow: `0 0 0 1px ${theme.palette.primary.light} `,
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.mockup.primary80,
        },
        "& .MuiSvgIcon-root": {
          ":last-child": {
            color: theme.palette.primary.main,
          },
        },
        "&.MuiRadio-root.Mui-checked": {
          color: theme.palette.mockup.primary90,
        },
        "&.MuiRadio-root.Mui-disabled": {
          color: theme.palette.mockup.primary90,
        },
        "&.MuiRadio-root.Mui-disabled.Mui-checked": {
          opacity: 0.5,
        },
      }),
    },
  },
}

export default muiRadio
