import { Box } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"

const HelpLink = () => {
  const { t } = useTranslation()
  return (
    <Box sx={{ fontSize: "14px" }}>
      <Trans
        i18nKey={t("defaultTranslations.findMoreHere")}
        style={{ fontSize: "14px" }}
        components={{
          Link: (
            <a
              href={process.env.REACT_APP_SUGGESTED_SETTINGS}
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
      />
    </Box>
  )
}

export default HelpLink
