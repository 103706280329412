import { useEffect, useState } from "react"

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material"
import { useMutationTeacherManagementInvite } from "api/reactQuery/mutations/teacherManagement"
import { useQueryInvitationsProPlan } from "api/reactQuery/queries/invitations"
import { useQueryTeachersManagement } from "api/reactQuery/queries/teachersManagement"
import { IResponseTeachersManagement } from "api/reactQuery/queries/teachersManagement.types"
import ErrorText from "components/common/error/errorText"
import StudentSkeleton from "components/common/skeleton/studentProfile"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { Trans, useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"
import { getErrorMessageFromTab } from "utils/api"
import { getLocalStorageClassname } from "utils/classname"

import GridViewInviteCoteachers from "../gridViewInviteCoTeachers"
import GridViewInviteProPlan from "../gridViewInviteProPlan"

const InviteCoteachers = () => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  useState<IResponseTeachersManagement | null>(null)
  const [teacherAddress, setTeacherAddress] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const params = useParams()
  const userInfo = useAppSelector(selectUser)

  useEffect(() => {
    if (teacherAddress) setError(null)
  }, [teacherAddress])

  const { data, isError, isLoading } = useQueryTeachersManagement({
    options: {
      enabled: user?.user.school_plan === UserSchoolPlan.SCHOOL,
      cacheTime: 0,
    },
  })

  const {
    data: dataPro,
    isLoading: isLoadingPro,
    isError: isErrorPro,
  } = useQueryInvitationsProPlan({
    options: {
      enabled: user?.user.school_plan === UserSchoolPlan.PRO,
      cacheTime: 0,
    },
  })

  const { mutate, isLoading: isLoadingInvite } =
    useMutationTeacherManagementInvite({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries("teachersManagement")
          queryClient.invalidateQueries("invitationsProPlan")
          setTeacherAddress([])
          if (teacherAddress.length > 1) {
            showSnackbar({
              title: t("coteachers.invitationsHaveBeenSent", {
                count: teacherAddress.length,
              }),
            })
          } else {
            showSnackbar({
              title: t("coteachers.invitationHasBeenSent"),
            })
          }
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessageFromTab(error),
            variant: "error",
          })
        },
      },
    })

  if (isLoading || isLoadingPro) {
    return <StudentSkeleton height="200px" />
  }

  if (isError || isErrorPro) {
    return <ErrorText />
  }

  return (
    <Stack>
      <Typography
        variant="body1"
        fontWeight="500"
        color="mockup.neutral10"
        sx={{ mb: 3 }}
      >
        {user?.user.school_plan === UserSchoolPlan.SCHOOL &&
          t(
            "coteachers.enterTheAddressesOfTheTeachersYouWantToInviteToYourSchool"
          )}
        {user?.user.school_plan === UserSchoolPlan.PRO && (
          <Trans
            i18nKey={t(
              "coteachers.enterTheAddressesOfTheTeachersYouWantToInviteAsCoteachers",
              {
                classname: getLocalStorageClassname() || "current class",
              }
            )}
          />
        )}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        height="58px"
        columnGap={1}
        mb={6}
      >
        <TextField
          value={teacherAddress}
          onChange={(e) => setTeacherAddress(e.target.value.split(","))}
          fullWidth
          error={!!error}
          helperText={error}
          label={t("coteachers.emailAddresses")}
          InputLabelProps={{ shrink: true }}
          placeholder={t("coteachers.separateAddressesWithCommas")}
        />
        <Button
          sx={{ whiteSpace: "nowrap" }}
          disabled={isLoadingInvite}
          onClick={() => {
            if (!teacherAddress.length || teacherAddress.includes("")) {
              setError(t("validations.fieldIsRequired"))
              return
            }
            if (userInfo?.user.school_plan === UserSchoolPlan.PRO) {
              mutate({
                emails: teacherAddress,
                class_id: Number(params.classId),
              })
            } else {
              mutate({
                emails: teacherAddress,
              })
            }
          }}
        >
          {t("coteachers.sendInvite")}
        </Button>
      </Box>
      <Grid container gap={2}>
        {data?.data.map((teacher, index) => (
          <GridViewInviteCoteachers key={index} teacher={teacher} />
        ))}
        {dataPro?.data.map((teacher) => (
          <GridViewInviteProPlan key={teacher.id} teacher={teacher} />
        ))}
      </Grid>
    </Stack>
  )
}

export default InviteCoteachers
