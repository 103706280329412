import { setLocale } from "yup"

setLocale({
  mixed: {
    required: "validations.fieldIsRequired",
  },
  string: {
    min: ({ min }) => ({
      key: "validations.enterMinChars",
      values: { count: min },
    }),
    max: ({ max }) => ({
      key: "validations.enterMaxChars",
      values: { count: max },
    }),
    email: "validations.enterValidEmail",
  },
  number: {
    min: ({ min }) => ({
      key: "validations.minNumberIs",
      values: { count: min },
    }),
    max: ({ max }) => ({
      key: "validations.maxNumberIs",
      values: { count: max },
    }),
    integer: "validations.enterInteger",
    negative: "validations.enterNegativeNumber",
    positive: "validations.enterPositiveNumber",
  },
})
