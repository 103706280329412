import React from "react"

import { Box, Divider, Stack, Typography, useTheme } from "@mui/material"
import Coin from "components/common/icon/coin"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"
import { isStudent } from "utils/roleCheck"

interface SavingAccountBalanceProps {
  checkingAmount: number
  savingsAmount: number
  totalAmount: number
}

const SavingAccountBalance = ({
  checkingAmount,
  savingsAmount,
  totalAmount,
}: SavingAccountBalanceProps) => {
  const userInfo = useAppSelector(selectUser)
  const hasSavingAccount =
    userInfo && isStudent(userInfo.user) && userInfo.user.has_savings_accounts

  const theme = useTheme()

  return (
    <Stack flexDirection="row" alignItems="center">
      {hasSavingAccount ? (
        <>
          <Box>
            <Typography
              variant="body3"
              fontWeight={500}
              color="mockup.neutral30"
            >
              Checking
            </Typography>
            <Coin amount={checkingAmount} />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 2,
              height: "34px",
              width: "1px",
              my: "auto",
              backgroundColor: theme.palette.mockup.primary95,
            }}
          />
          <Box>
            <Typography
              variant="body3"
              fontWeight={500}
              color="mockup.neutral30"
            >
              Savings
            </Typography>
            <Coin amount={savingsAmount} />
          </Box>
        </>
      ) : (
        <Box>
          <Typography variant="body3" fontWeight={500} color="mockup.neutral30">
            Total Balance
          </Typography>
          <Coin amount={totalAmount} />
        </Box>
      )}
    </Stack>
  )
}
export default SavingAccountBalance
