import React from "react"

import { ReactComponent as IconArrow } from "@common_assets/svg/arrowBack.svg"
import { Box, BoxProps, Fab } from "@mui/material"

interface IProps {
  onClickLeft: () => void
  onClickRight: () => void
  onDisabledLeft: boolean
  onDisabledRight: boolean
  boxProps?: BoxProps
}

const ArrowButton = ({
  onClickLeft,
  onClickRight,
  onDisabledLeft,
  onDisabledRight,
  boxProps,
}: IProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <Fab disabled={onDisabledLeft} onClick={onClickLeft}>
        <IconArrow />
      </Fab>
      <Fab disabled={onDisabledRight} onClick={onClickRight}>
        <IconArrow style={{ transform: "rotate(180deg)" }} />
      </Fab>
    </Box>
  )
}

export default ArrowButton
