export const jobs = {
  youDoNotHaveAnyStudentsYet: "You don't have any students yet",
  youDoNotHaveAnyJobsYet: "You don't have any jobs yet",
  instructionJobs:
    "First configure your positions and requirements so that students can apply for them",
  addStudents: "Add students",
  addSuggestedJobs: "Add suggested jobs",
  atleastOneCheckbox: "Please select at least one checkbox",
  addJob: "Add job",
  editJob: "Edit job",
  clear: "Clear",
  close: "Close",
  closed: "Closed",
  open: "Open",
  resetHiredStudents: "Reset hired students",
  whenTheJobBardIsOpen:
    "When the Job board is open students can apply for the job. When the job board is closed, students cannot view jobs or apply.",
  clearAllApplications: "Clear all applications",
  closeJobBoard: "Close job board",
  openJobBoard: "Open job board",
  jobBoard: "Job board",
  jobBoardOpen: "Job board open",
  jobBoardClosed: "Job board closed",
  jobTitle: "Job title",
  responsibilities: "Responsibilities",
  qualifications: "Qualifications",
  salary: "Salary",
  general: "General",
  addSelectedJobs: "Add {{count}} selected jobs",
  positions: "Positions",
  pendingApplication: "Pending Application({{count}})",
  pendingApplicationGrid: "Pending Application",
  employees: "Employees({{curr}}/{{slot}})",
  employeesTitle: "Employees",
  showStudentsWithoutJob: "Show students without a job",
  openPositions: "open positions",
  succeedCreateJob: "Jobs has been created successful",
  errorCreateJob: "Error - {{error}}",
  removeCreateJob: "Job has been removed successful",
  editCreateJob: "Job has been edited successful",
  jobAlready: "Job with given title already exists",
  viewApplication: "View application",
  removeStudent: "Remove student",
  hireStudent: "Hire student",
  rejectStudent: "Reject student",
  acceptStudent: "Accept student",
  fireStudent: "Fire student",
  accept: "Accept",
  whatExperienceOfQualifications:
    "What experiences or qualifications make you the best candidate?",
  whyDoYouWantThisJob: "Why do you want this job? (200 word maximum)",
  studentResume: "Student's resume",
  jobApplication: "Job application",
  resumeUpload: "Resume Upload",
  areYouSureYouWantToDeleteJob: "Are you sure you want to delete job?",
  areYouSureYouWantToDeleteJobApplication:
    "Are you sure you want to delete job application?",
  thisActionCannotBeUndone: "This action cannot be undone.",
  delete: "Delete",
  checkConnectionStatus: "Check connection status",
  everyoneHasJob: "Everyone has a job",
  hasBeenSuccessfullyHired:
    "{{firstName}} {{lastName}} has been successfully hired to {{title}}",
  haveBeenSuccessfullyHired:
    "{{count}} students have been successfully hired to {{title}}",
  allOfStudentsHaveBeenSuccessfullyHiredAsTitle:
    "All of {{count}} students have been successfully hired to {{title}}",
  removedSuccessfully: "Removed successfully",
  rejectSuccessfully: "Reject successfully",
  jobApplicationRemoveSuccessfully: "Job application remove successfully",
  hireSuccessfully: "Hire succeed",
  studentAlreadyHired: "Student is already hired",
  studentHasNoJob: "Student has no job",
  editJobsSuccessfully: "Edit jobs successfully",
  notAvailablePositionsInJob: "Not available positions in job",
  backToClass: "Back to class",
  backToDashboard: "Back to dashboard",
  invalidPasswordAvatarFormat: "Invalid Password or Avatar format",
  invalidPassword: "Invalid password",
  allApplicationsHaveBeenRemoved: "All applications have been removed",
  searchStudent: "Search student",
  areYouSureYouWantToClearAllApplications:
    "Are you sure you want to clear all applications",
  nobodyEmployed: "Nobody employed",
  notAttached: "Not attached",
  exportStudentsClass: "Export students class",
  dataHasBeenSuccessfullyExported: "Data has been successfully exported",
  classJobs: "Class jobs",
  classJobsWithStudent: "Class jobs with Students",
  exportData: "Export Data",
  jobDetails: "Job details",
  studentAssignedJobs: "Student assigned jobs",
  thisWillClearAllHiredStudentsAndPendingApplicationsFromYourJobBoard:
    "This will clear all hired students and pending applications from your job board.",
  areYouSureYouWantToHireStudentTo:
    "Are you sure you want to hire {{count}} students to {{jobname}}",
  selectAll: "Select all",
  areYouSureYouWantToHireStudentsAsAJob:
    "Are you sure you want to hire {{count}} students as a {{jobsTitle}}?",
  areYouSureYouWantToHireSingleStudentAsAJob:
    "Are you sure you want to hire {{firstname}} {{lastname}} as a {{jobsTitle}}?",
  pleaseUnlockTheCheckboxToBeAbleToEdit:
    "Please unlock the checkbox to be able to edit",
  hireMultipleStudentsAtOnce: "Hire multiple students at once",
  dragFromHereToHire: "Drag from here to hire",
  pleaseFillTheSalary: "Please fill the salary",
  descriptionUpgradeTeacherExport:
    "Export Data is a feature included in the 'Pro Subscription' and 'School Subscription'. Learn more about our <Link>paid plans here</Link>. Upgrade to pro or school to unlock this feature.",
  clickToFillOut: "Click to fill out",
  areYouSureYouWantToExit: "Are you sure you want to exit?",
  noChangesWillBeSaved: "No changes will be saved.",
  clickOnTheTextToEditTheSuggestedSettings:
    "*Click on the text to edit the suggested settings.",
  selectedItemsShouldHaveTitleOfAtLeast3Characters:
    "Selected items should have title of at least 3 characters",
  amountOfSelectedItemsHaveToBePositiveNumber:
    "Amount of selected items have to be a positive number",
  allStudents: "Select all",
  selected: "Selected",
  autoPay: "Autopay",
  on: "ON",
  off: "OFF",
}
