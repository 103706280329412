import { Button, Stack, styled } from "@mui/material"
import { TransactionType } from "ts/types/Transaction"

import { colorSchemeMap } from "./Batch.config"

interface BatchActionContainer {
  isBetweenTablet: boolean
}

export const TransactionButton = styled(Button)<{
  scheme: TransactionType
}>(({ theme, scheme }) => ({
  padding: "8px 16px",
  color: colorSchemeMap[scheme].color,

  [theme.breakpoints.up("tablet")]: {
    padding: "14px 22px",
    background: colorSchemeMap[scheme].background,
    borderColor: colorSchemeMap[scheme].borderColor,
  },
}))

export const BatchActionsContainer = styled(Stack)<BatchActionContainer>(
  ({ theme, isBetweenTablet }) => ({
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    padding: isBetweenTablet ? "8px" : "8px 16px",
    paddingBottom: "0px",
    borderBottom: `1px solid ${theme.palette.mockup.primary80}`,
    gap: 0,
    background: theme.palette.mockup.primary95,
    position: "sticky",
    zIndex: "1100",

    [theme.breakpoints.up("mobile")]: {
      top: "96px",
    },

    [theme.breakpoints.up("tablet")]: {
      paddingBottom: "8px",
      gap: "16px",
      top: "64px",
    },

    [theme.breakpoints.up("desktop")]: {
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
      top: "68px",
    },
  })
)
