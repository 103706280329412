import { Box, styled } from "@mui/material"

export const PopRowContent = styled(Box)(({ theme }) => ({
  listStyleType: "none",
  width: "100%",
  fontWeight: "500",
  padding: "12px 16px",
  fontSize: "14px",
  cursor: "pointer",
  color: `${theme.palette.mockup.neutral10}`,
  ":hover": {
    backgroundColor: theme.palette.mockup.primary95,
  },
}))
