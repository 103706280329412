import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IClass, IClassShort } from "ts/interfaces/Class"

import { IGetClassesArgs, IGetClassesByIdArgs } from "./classes.types"

export const useQueryClasses = (args?: IGetClassesArgs) =>
  useQuery<AxiosResponse<IClassShort[]>, AxiosError>(
    ["classes"],
    async () => axiosInstance(Endpoint.CLASSES),
    {
      ...args?.options,
    }
  )

export const useQueryClassesById = (args: IGetClassesByIdArgs) =>
  useQuery<AxiosResponse<IClass>, AxiosError>(
    ["classes", args.id],
    async () => axiosInstance(`${Endpoint.CLASSES}/${args.id}`),
    {
      ...args?.options,
    }
  )
