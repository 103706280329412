import { Dispatch, FC, useState } from "react"

import { ReactComponent as Coin } from "@brand_assets/svg/coin.svg"
import { ReactComponent as Infinite } from "@common_assets/svg/infinity.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Divider, MenuItem, Stack, Typography } from "@mui/material"
import { IPostStudentJobApplications } from "api/reactQuery/mutations/students.types"
import { IGetStudentPossibleJobsInClass } from "api/reactQuery/queries/studentJobs.types"
import TextField from "components/form/common/field/textField"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"

import UploadFile from "../uploadFile"
import {
  defaultValues,
  validateSchema,
  choiceMap,
} from "./ApplicationModule.config"

const ApplicationModule: FC<{
  state: IGetStudentPossibleJobsInClass[]
  setIsLoading: Dispatch<React.SetStateAction<boolean>>
  id: string
  postJobApplication: (arg: IPostStudentJobApplications) => void
}> = ({ state, id, setIsLoading, postJobApplication }) => {
  const { t } = useTranslation()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [link, setLink] = useState("")
  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const onSubmit = (data: IPostStudentJobApplications) => {
    if (link === "") {
      postJobApplication({
        choice: data.choice,
        qualifications: data.qualifications,
        job_id: state[0].id,
        motivation: data.motivation,
      } as IPostStudentJobApplications)
    } else {
      postJobApplication({
        choice: data.choice,
        qualifications: data.qualifications,
        job_id: state[0].id,
        motivation: data.motivation,
        resume_file: link,
      } as IPostStudentJobApplications)
    }
  }

  return (
    <>
      <Divider style={{ width: "100%" }} />
      <Stack>
        <Box>
          <Typography variant="subtitle1" color="mockup.grey70" mb={2}>
            {state[0].title}
          </Typography>
          <Box display="flex" width="100%">
            <Coin style={{ marginRight: "5px" }} />
            <Typography variant="body2" color="mockup.neutral10">
              {state[0].salary}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{ height: "20px", mx: "10px" }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                color="mockup.neutral10"
                sx={{
                  pr: "5px",
                  fontWeight: "500",
                  lineHeight: state[0].open_for_all ? "12px" : "18px",
                }}
              >
                {state[0].open_for_all ? <Infinite /> : state[0].positions}
              </Typography>
              <Typography
                variant="body2"
                color="mockup.neutral10"
                fontWeight="500"
              >
                {t("studentJobs.openPositions")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider style={{ width: "100%", margin: "24px 0" }} />
        <form onSubmit={methods.handleSubmit(onSubmit)} id={id}>
          <FormProvider {...methods}>
            <TextField
              name="choice"
              select
              label={t("studentJobs.rankYourChoice")}
            >
              {choiceMap.map((choice, index) => (
                <MenuItem value={choice.label} key={index}>
                  {choice.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ my: 6 }}
              name="qualifications"
              multiline
              rows={4}
              label={t(
                "studentJobs.whatExperiencesOrQualificationsMakeYouTheBestCandidate"
              )}
            />
            <TextField
              name="motivation"
              multiline
              rows={4}
              label={t("studentJobs.whyDoYouWantThisJob")}
              sx={{ mb: 6 }}
            />
            <UploadFile
              setIsLoading={setIsLoading}
              name="resume_file"
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setLink={setLink}
            />
          </FormProvider>
        </form>
      </Stack>
    </>
  )
}

export default ApplicationModule
