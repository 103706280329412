import monster from "@common_assets/image/png/404.png"
import { ReactComponent as Logo } from "@common_assets/svg/logo.svg"
import { Stack, Box, Typography, Button, styled } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { LocationState } from "ts/interfaces/Route"

const MonsterImage = styled(Box)({
  backgroundImage: `url(${monster})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  width: "100%",
})
const Page404 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsBreakpointUp("tablet")
  const isDesktop = useIsBreakpointUp("desktop")
  const location = useLocation()

  const from = (location.state as LocationState)?.from?.pathname

  return (
    <Stack
      alignItems="flex-start"
      sx={
        isDesktop
          ? { p: "46px 142px 166px" }
          : isMobile
          ? { pt: "46px", px: "24px" }
          : { pt: "46px", px: "16px" }
      }
    >
      <Box mb={isDesktop ? "96px" : isMobile ? "130px" : "37.5px"}>
        <Logo />
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={isMobile ? "row" : "column-reverse"}
      >
        <Box mt={!isMobile ? "44px" : ""} flexBasis="50%">
          <Typography
            fontSize={isDesktop ? "64px" : isMobile ? "44px" : "28px"}
            lineHeight={isDesktop ? "80px" : isMobile ? "53px" : "36px"}
            color="mockup.darkBlue"
            maxWidth="566px"
          >
            {t("validations.ooopsWeCantFindThatPage")}
          </Typography>
          <Typography mt={2} mb={3} variant="body1" color="mockup.darkBlue">
            {t("validations.thisPageIsMissingOrYouAssembledTheLinkIncorrectly")}
          </Typography>
          <Button
            onClick={() => {
              if (from === RoutePath.LOGIN) {
                window.location.replace(RoutePath.HOMEPAGE)
              } else {
                navigate(RoutePath.HOMEPAGE, { replace: true })
              }
            }}
            fullWidth={!isMobile}
          >
            {t("validations.goBack")}
          </Button>
        </Box>
        <Box
          flexBasis="50%"
          mr={isMobile ? "25px" : 0}
          width={isMobile ? "" : "100%"}
          height={isMobile ? "" : "50%"}
        >
          <MonsterImage
            sx={
              isDesktop
                ? {
                    width: "100%",
                    minWidth: "400px",
                    height: "487px",
                  }
                : isMobile
                ? {
                    width: "100%",
                    minWidth: "243px",
                    height: "299px",
                  }
                : {
                    width: "100%",
                    minWidth: "200px",
                    height: "241px",
                  }
            }
          />
        </Box>
      </Box>
    </Stack>
  )
}

export default Page404
