import { FC } from "react"

import { Box, Typography } from "@mui/material"
import { format } from "date-fns"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"

import StudentImages from "./studentImages"
import TeacherImages from "./teacherImages"

interface IProps {
  variant?: "student" | "teacher"
}

const WelcomeBack: FC<IProps> = ({ children, variant = "teacher" }) => {
  const isTablet = useIsBreakpointUp("tablet")
  const { t } = useTranslation()

  const currentDate = new Date()

  const getBoxMinHeight = () => {
    if (isTablet) return "250px"
    if (variant === "teacher") return "144px"
    return "200px"
  }

  return (
    <Box
      bgcolor={variant === "student" ? "mockup.additional90" : ""}
      paddingY={variant === "student" ? 4 : 0}
      borderRadius={2}
      width="100%"
      minHeight={getBoxMinHeight()}
      position={variant === "teacher" ? "relative" : "static"}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        position={variant === "teacher" ? "absolute" : "static"}
        height="100%"
        width="100%"
        top="0"
        left="0"
      >
        {variant === "teacher" && <TeacherImages />}
      </Box>
      <Box
        px="32px"
        position={variant === "teacher" ? "absolute" : "static"}
        height="100%"
        width="100%"
        zIndex={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box p={variant === "teacher" ? 2 : 0}>
          <Typography variant="h1" color="mockup.neutral10" mb="4px">
            {t("dashboard.welcome")}!
          </Typography>
          {variant === "teacher" && (
            <Typography
              variant="subtitle2"
              fontWeight={500}
              color="mockup.neutral20"
            >
              {t("dashboard.todayIs")} {format(currentDate, "EEEE")},
              {format(currentDate, " MMMM dd, yyyy")}
            </Typography>
          )}
          {children}
        </Box>
      </Box>
      <Box position="relative">
        {variant === "student" && <StudentImages />}
      </Box>
    </Box>
  )
}

export default WelcomeBack
