import { FormEvent } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Stack } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import TextField from "components/form/common/field/textField"
import mixpanel from "mixpanel-browser"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { TransactionTypeShort } from "ts/types/Transaction"

import {
  defaultValues,
  validationSchema,
  validationSchemaForFine,
} from "./TransactionSetupDialogForm.config"
import { IFormState } from "./TransactionSetupDialogForm.types"

interface IProps<T> {
  open: boolean
  onClose: () => void
  onSubmit: (data: IFormState) => Promise<T>
  isLoading: boolean
  title: string
  titleFieldLabel: string
  initialState?: IFormState
  handleDeleteClick?: () => void
  variant?: TransactionTypeShort
}

const TransactionSetupDialogForm = <T,>({
  open,
  onClose,
  onSubmit,
  isLoading,
  title,
  titleFieldLabel,
  initialState,
  handleDeleteClick,
  variant,
}: IProps<T>) => {
  const { t } = useTranslation()

  const methods = useForm<IFormState>({
    defaultValues: initialState || defaultValues,
    resolver: yupResolver(
      variant === "fine" ? validationSchemaForFine : validationSchema
    ),
  })

  return (
    <Dialog
      titleText={title}
      open={open}
      onClose={() => {
        methods.reset(initialState || defaultValues)
        onClose()
        mixpanel.track("add_class_settings", {
          name: variant,
          action: `cancel_${variant}`,
        })
      }}
      actionAcceptText={t("save")}
      isLoading={isLoading}
      onDeleteClick={handleDeleteClick}
      actionAcceptButtonProps={{
        type: "submit",
        form: title,
        disabled: isLoading,
      }}
    >
      <Stack
        component="form"
        id={title}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          methods.handleSubmit(async (data) => {
            try {
              await onSubmit(data)
              methods.reset(initialState || defaultValues)
              onClose()
            } catch {}
          })()
        }}
      >
        <FormProvider {...methods}>
          <Stack gap="32px">
            <TextField name="title" label={titleFieldLabel} />
            <TextField
              name="amount"
              inputMode="decimal"
              label={t("setup.value")}
            />
          </Stack>
        </FormProvider>
      </Stack>
    </Dialog>
  )
}

export default TransactionSetupDialogForm
