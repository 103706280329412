import { Dispatch, FC, useEffect, useState } from "react"

import { Checkbox, Typography } from "@mui/material"
import { IResponseGetStudentPossibleJob } from "api/reactQuery/queries/students.types"
import { Cell, Row } from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"

interface IProps {
  checkedJobs: number[]
  allJobs?: IResponseGetStudentPossibleJob[]
  setCheckedJobs: Dispatch<React.SetStateAction<number[]>>
}

const TableHead: FC<IProps> = ({ checkedJobs, allJobs, setCheckedJobs }) => {
  const { t } = useTranslation()
  const [checkedAll, setCheckedAll] = useState(false)
  const [possibleJobs, setPossibleJobs] = useState<number[]>([])

  useEffect(() => {
    setPossibleJobs(allJobs ? allJobs.map((el) => el.id) : [])
  }, [allJobs])

  const toggleAll = () => {
    setCheckedAll(!checkedAll)
    if (checkedAll) {
      setCheckedJobs(possibleJobs)
      allJobs?.forEach((el) => (el.is_hired = true))
    } else {
      allJobs?.forEach((el) => (el.is_hired = false))
      setCheckedJobs([])
    }
  }

  return (
    <Row>
      <Cell minWidth="60px">
        <Checkbox
          style={{ margin: 0 }}
          onChange={toggleAll}
          checked={
            checkedJobs.length === possibleJobs.length &&
            checkedJobs.length !== 0
          }
          indeterminate={
            checkedJobs.length !== possibleJobs.length &&
            checkedJobs.length !== 0
          }
        />
      </Cell>
      <Cell minWidth="250px" tableCellProps={{ width: "100%" }}>
        <Typography variant="body3" color="primary.main">
          {t("studentProfile.job").toUpperCase()}
        </Typography>
      </Cell>
      <Cell maxWidth="75px" sx={{ padding: "0 5px" }}>
        <Typography variant="body3" color="primary.main">
          {t("studentProfile.positions").toUpperCase()}
        </Typography>
      </Cell>
      <Cell maxWidth="75px">
        <Typography variant="body3" color="primary.main">
          {t("studentProfile.salary").toUpperCase()}
        </Typography>
      </Cell>
    </Row>
  )
}

export default TableHead
