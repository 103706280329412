import { requiredStringSchema } from "utils/yup"
import * as yup from "yup"

export const defaultValues = {
  username: "",
  password: "",
}

export const validateSchema = yup.object({
  username: requiredStringSchema,
  password: requiredStringSchema,
})
