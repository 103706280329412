import { useEffect, useState } from "react"

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { selectUser } from "store/auth/auth.selectors"
import { Subscription_Status } from "ts/enums/User"
import { IUserTeacher } from "ts/interfaces/User"
import { clearAuthDataLocalStorage } from "utils/authData"

const PaymentManage = () => {
  const userInfo = useAppSelector(selectUser)
  const userT = userInfo?.user as IUserTeacher
  const { t } = useTranslation()
  const { isOpen, handleOpen } = useDialog()
  const location = useLocation()
  const [isGoingToStripe, setIsGoingToStripe] = useState(false)

  const logout = () => {
    clearAuthDataLocalStorage()
    window.location.href = "/"
  }

  useEffect(() => {
    if (userT?.subscription_status === Subscription_Status.UNPAID) {
      handleOpen()
    }
  }, [userT?.subscription_status, location.pathname])

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onIconClose={false}
      desktopMaxWidth="534px"
      customActionButtonsSlot={
        <Box
          gap="10px"
          display="flex"
          justifyContent="flex-end"
          m="16px 32px 16px 0 "
        >
          <Button onClick={logout} variant="outlined">
            {t("dashboard.logout")}
          </Button>

          <Button
            disabled={isGoingToStripe}
            variant="contained"
            onClick={() => {
              setIsGoingToStripe(!isGoingToStripe)
              window.location.href =
                "https://billing.stripe.com/p/login/fZecPKg3y6SpdYA8ww"
            }}
          >
            <span>{isGoingToStripe && <CircularProgress size="16px" />}</span>
            {t("dashboard.manageYourPayment")}
          </Button>
        </Box>
      }
    >
      <Stack textAlign="center">
        <Typography variant="h3" color="mockup.darkBlue" mb={3}>
          {t("dashboard.manageYourPaymentInformation")}
        </Typography>
        <Typography variant="body1" fontWeight="500" color="mockup.neutral10">
          {t("dashboard.weWereUnableToProcessYourLastPayment")}
        </Typography>
      </Stack>
    </Dialog>
  )
}

export default PaymentManage
