import React, { useEffect, useState } from "react"

import { ReactComponent as IconDollar } from "@common_assets/svg/dolar.svg"
import { ReactComponent as SvgPhoto } from "@common_assets/svg/photo.svg"
import {
  Checkbox,
  Stack,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useInfiniteBankerClass } from "api/reactQuery/queries/banker"
import FabIconButton from "components/common/button/fabIconButton"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import NoElementsMessage from "components/common/listing/noElementsMessage"
import NoMatchesForSearch from "components/common/listing/noMatchesForSearch"
import BasicTable, {
  Cell,
  HeadText,
  Row,
} from "components/common/table/basicTable"
import SearchbarForm from "components/form/searchbarForm"
import { useDialog } from "hooks/dialog"
import { useClassId } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { useQueryClient } from "react-query"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import { OrderType } from "../Banker.types"
import BatchActionsButtonsBanker from "../batchActionButtons/BatchActionsButtonsBanker"
import DialogSendTransaction from "../dialogSendTransaction"

const TableView = () => {
  const theme = useTheme()
  const classId = useClassId()
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<OrderType>("asc")

  const [isToggleAll, setIsToggleAll] = useState(false)
  const [studentNumbers, setStudentNumbers] = useState(0)
  const [excludeIds, setExcludeIds] = useState<number[]>([])
  const [markedStudentsId, setMarkedStudentsId] = useState<number[]>([])
  const [singleStudent, setSingleStudent] = useState<number[]>([])
  const [searchOption, setSearchOption] = useState({
    search: "",
  })

  const { ref, inView } = useInView({
    threshold: 0.5,
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  const { data, fetchNextPage, hasNextPage, isError, isLoading } =
    useInfiniteBankerClass({
      classId: Number(classId),
      filter: searchOption.search.length < 2 ? undefined : searchOption.search,
      limit: 20,
      skip: 0,
      sorting: sorting,
      sort_by: "first_name",
    })

  useEffect(() => {
    const initialState = data?.pages.flatMap(
      (item) => item.data.all_objects_count
    )

    if (initialState && !!initialState.length) {
      const [ids] = initialState
      setStudentNumbers(ids)
    }
  }, [data])

  useEffect(() => {
    const newState = data?.pages.flatMap((item) => item.data.objects)
    if (newState && isToggleAll) {
      setMarkedStudentsId((prev) => {
        const newIds = newState
          .filter(
            (item) =>
              !prev.some((exist) => exist === item.id) &&
              !excludeIds.includes(item.id)
          )
          .map((item) => item.id)

        return [...prev, ...newIds]
      })
    }
  }, [data])

  const handleSort = () => {
    const isAsc = sorting === "asc"
    setSorting(isAsc ? "desc" : "asc")
    queryClient.removeQueries(["bankerClass"])
  }

  const handleChecked = (id: number) => {
    const alreadyStudent = markedStudentsId.find((markedId) => markedId === id)
    if (alreadyStudent) {
      const excludedStudent = markedStudentsId.filter(
        (studentId) => studentId !== id
      )
      setMarkedStudentsId(excludedStudent)

      if (isToggleAll) {
        setExcludeIds((prev) => [...prev, alreadyStudent])
      }
      return
    }
    if (isToggleAll) {
      setExcludeIds((prev) => {
        const d = prev.find((item) => item === id)
        if (d) {
          return prev.filter((item) => item !== d)
        } else {
          return prev
        }
      })
    }
    setMarkedStudentsId((prev) => [...prev, id])
  }

  const handleMainCheckbox = () => {
    if (!!searchOption.search.length) return

    setIsToggleAll(!isToggleAll)

    if (isToggleAll) {
      setMarkedStudentsId([])
      setExcludeIds([])
    } else {
      const studentList =
        data?.pages.flatMap((user) => user.data.objects.map(({ id }) => id)) ??
        []
      setMarkedStudentsId(studentList)
    }
  }

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Stack>
      <Stack
        flexDirection="row"
        columnGap={1}
        sx={{ backgroundColor: theme.palette.common.white, p: 2 }}
      >
        <SearchbarForm onSubmit={(data) => setSearchOption(data)} />
      </Stack>
      <BasicTable
        topSlot={
          <BatchActionsButtonsBanker
            markedStudentId={markedStudentsId}
            isToggleAll={isToggleAll}
            studentNumbers={studentNumbers}
            excludeIds={excludeIds}
            onSuccessPostClassesTransactions={() => {
              setExcludeIds([])
              setIsToggleAll(false)
              setMarkedStudentsId([])
              queryClient.removeQueries(["bankerClass"])
            }}
          />
        }
        head={
          <Row>
            <Cell>
              <Checkbox
                style={{ opacity: !!searchOption.search.length ? 0.4 : 1 }}
                indeterminate={
                  (isToggleAll && !!excludeIds.length) ||
                  (!isToggleAll &&
                    markedStudentsId.length !== studentNumbers &&
                    !!markedStudentsId.length)
                }
                checked={isToggleAll && !excludeIds.length}
                onChange={handleMainCheckbox}
              />
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <TableSortLabel active direction={sorting} onClick={handleSort}>
                <HeadText>{t("students.student")}</HeadText>
              </TableSortLabel>
            </Cell>
            <Cell tableCellProps={{ width: "50%" }} minWidth="100px">
              <HeadText>{t("students.job")}</HeadText>
            </Cell>
            <Cell width="90px">
              <HeadText>{t("students.balance")}</HeadText>
            </Cell>
            <Cell width="50px"></Cell>
          </Row>
        }
        body={
          <TransitionGroup component={null}>
            {data?.pages?.flatMap((item) => {
              return item.data.objects.map((user) => (
                <CSSTransition
                  key={user.id}
                  timeout={700}
                  classNames="animation-fade"
                >
                  <Row>
                    <Cell>
                      <Checkbox
                        onChange={() => handleChecked(user.id)}
                        checked={markedStudentsId.some((el) => user.id === el)}
                      />
                    </Cell>
                    <Cell>
                      {user.first_name} {user.last_name}
                    </Cell>
                    <Cell>
                      <Tooltip
                        title={user.jobs.length > 2 ? user.jobs.join(", ") : ""}
                      >
                        <Typography>
                          {user.jobs.length <= 2
                            ? user.jobs.join(", ")
                            : `${user.jobs.slice(0, 2).join(", ")},...`}
                        </Typography>
                      </Tooltip>
                    </Cell>
                    <Cell>
                      <Coin amount={user.balance} />
                    </Cell>
                    <Cell>
                      <FabIconButton
                        tooltipText={t("students.sendTransaction")}
                        onClick={() => {
                          setSingleStudent([user.id])
                          handleOpen()
                        }}
                        Icon={<IconDollar />}
                      />
                    </Cell>
                  </Row>
                </CSSTransition>
              ))
            })}
          </TransitionGroup>
        }
        bottomSlot={
          <>
            {hasNextPage && <div ref={ref} />}

            {isError && <ErrorText />}
            {!isLoading &&
              !!searchOption.search.length &&
              data?.pages.flatMap((item) => item.data.objects).length === 0 && (
                <NoMatchesForSearch search={searchOption.search} />
              )}
            {!isLoading &&
              data?.pages.flatMap((item) => item.data.objects).length === 0 &&
              !searchOption.search.length && (
                <NoElementsMessage
                  boxStyle={{ py: { mobile: "16px", tablet: "62px" } }}
                  Icon={<SvgPhoto />}
                  title={t("students.youDoNotHaveAnyStudentsYet")}
                  description={t("students.addStudentsManuallyOrHaveStudents")}
                />
              )}
          </>
        }
      />
      <DialogSendTransaction
        handleClose={handleClose}
        isOpen={isOpen}
        markedStudentId={singleStudent}
        isToggleAll={isToggleAll}
        excludedStudentId={excludeIds}
        onSuccessPostClassesTransactions={() => {
          setExcludeIds([])
          setMarkedStudentsId([])
          handleClose()
        }}
      />
    </Stack>
  )
}
export default TableView
