import { FC } from "react"

import { ReactComponent as IconTrash } from "@common_assets/svg/trash.svg"
import { Fab } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"

interface IProps {
  onClick: () => void
}

const FabDeleteButton: FC<IProps> = ({ onClick }) => {
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Fab
      size="small"
      sx={{
        flexShrink: 0,
        alignSelf: isTablet ? "center" : "end",
      }}
      onClick={onClick}
    >
      <IconTrash />
    </Fab>
  )
}

export default FabDeleteButton
