import { ThemeOptions } from "@mui/material"

const muiCheckBox: ThemeOptions["components"] = {
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        margin: "0 8px",
        width: "18px",
        height: "18px",
        borderRadius: 0,
        color: theme.palette.mockup.primary90,
        ":hover": {
          transitionDuration: "300ms",
          color: `${theme.palette.mockup.primary80}`,
          border: "2px solid transition",
          padding: 0,
          boxShadow: `0 0 0 3px ${theme.palette.mockup.primary90}`,
        },
        "&.Mui-disabled": {
          color: theme.palette.mockup.primary90,
          "&.Mui-checked": {
            color: theme.palette.mockup.primary80,
          },
        },
        "&.Mui-checked:hover": {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
}
export default muiCheckBox
