import { QueryClient } from "react-query"

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 20 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})

export default reactQueryClient
