import { ChangeEvent, FC } from "react"

import { TextField as TextFieldMui, TextFieldProps } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { getFieldError } from "../field.utils"

type Props = TextFieldProps & {
  name: string
  customOnChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: unknown[]) => void
  ) => void
}

const TextField: FC<Props> = ({
  name,
  customOnChange,
  error,
  helperText,
  ...props
}) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState: { error: fieldError },
      }) => {
        const { isError, errorMessage } = getFieldError(fieldError, t)

        return (
          <TextFieldMui
            {...props}
            name={name}
            onBlur={onBlur}
            onChange={
              customOnChange ? (e) => customOnChange(e, onChange) : onChange
            }
            value={value}
            inputRef={ref}
            error={error || isError}
            helperText={helperText || errorMessage}
          />
        )
      }}
    />
  )
}

export default TextField
