import { AxiosError, AxiosResponse } from "axios"
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query"

import axiosInstance from "../../api"
import { Endpoint } from "../../enpoints.types"
import {
  StudentHelpersTypes,
  StudentInfiniteHelpersArgs,
} from "./studentHelpers.types"

export const useInfiniteStudentHelpers = (
  args?: StudentInfiniteHelpersArgs
): UseInfiniteQueryResult<AxiosResponse<StudentHelpersTypes>, AxiosError> =>
  useInfiniteQuery<AxiosResponse<StudentHelpersTypes>, AxiosError>(
    ["infiniteStudentHelpers", args?.classId, args?.filter, args?.sorting],
    async ({ pageParam = 0 }) =>
      axiosInstance(
        Endpoint.CLASSES_ID_STUDENT_HELPERS.replace("ID", `${args?.classId}`),
        {
          params: {
            filter: args?.filter,
            skip: pageParam,
            limit: args?.limit,
            sort_by: args?.sort_by,
            sorting: args?.sorting,
          },
        }
      ),
    {
      ...args?.options,
      getNextPageParam: (lastPage, allPages) => {
        const loadedItemCount = allPages.reduce(
          (acc, page) => acc + page.data.objects.length,
          0
        )
        const totalItems = lastPage.data.all_objects_count

        if (loadedItemCount < totalItems) {
          return loadedItemCount
        }
        return undefined
      },
    }
  )
