import React from "react"

import { ReactComponent as IconQuestion } from "@common_assets/svg/questionmark-outlined.svg"
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import Coin from "../../../../common/icon/coin"
import { useTransaction } from "../context"

const InterestColumn = () => {
  const theme = useTheme()
  const dataContext = useTransaction()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery("(max-width: 1400px)")

  if (dataContext?.isLoading) return <Skeleton width="100%" height="200px" />

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.mockup.primary95}`,
        px: 2,
        pt: 2,
        pb: isDesktop ? 2 : 0,
        flex: "1 1 100%",
        maxWidth: isDesktop ? "100%" : "232px",
      }}
    >
      <Stack height="100%" direction={isDesktop ? "row" : "column"}>
        <Box flex="1 1 0">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="500">
              {`% ${t("myActivity.interest")}`}
            </Typography>
            <Tooltip
              title={t(
                "myActivity.interestEarnedOnSavingsIsTheInterestRateMultiplied"
              )}
            >
              <IconQuestion style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </Box>
          <Typography
            fontSize="28px"
            my={1}
            mb={2}
            sx={{
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {dataContext?.data?.interest_rate}
          </Typography>
          {!isDesktop && <Divider />}
        </Box>{" "}
        {isDesktop && (
          <Divider orientation="vertical" sx={{ height: "100%", mx: "10px" }} />
        )}
        <Box flex="1 1 0">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="500">
              Total Interest Earned
            </Typography>
            <Tooltip
              title={t(
                "myActivity.thisIsAllOfTheMoneyYouEarnedJustByHavingMoney"
              )}
            >
              <IconQuestion style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </Box>
          <Coin
            amount={dataContext?.data?.total_interest_earned ?? 0}
            valueTextProps={{ fontSize: "28px" }}
            style={{
              marginTop: "8px",
              marginBottom: "16px",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          />
          {!isDesktop && <Divider />}
        </Box>{" "}
        {isDesktop && (
          <Divider orientation="vertical" sx={{ height: "100%", mx: "10px" }} />
        )}
        <Box flex="1 1 0">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="500">
              Projected Monthly Interest
            </Typography>
            <Tooltip
              title={t(
                "myActivity.thisIsAllOfTheMoneyYouEarnedJustByHoldingMoney"
              )}
            >
              <IconQuestion style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </Box>
          <Coin
            amount={dataContext?.data?.projected_monthly_interest ?? 0}
            valueTextProps={{ fontSize: "28px" }}
            style={{
              marginTop: "8px",
              marginBottom: "16px",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          />
          {!isDesktop && <Divider />}
        </Box>
      </Stack>
    </Box>
  )
}
export default InterestColumn
