import { FC } from "react"

import { Stack, StackProps } from "@mui/material"

interface IProps {
  wrapperProps?: StackProps
}

const FabGroup: FC<IProps> = ({ wrapperProps, children }) => {
  return (
    <Stack direction="row" gap="12px" {...wrapperProps}>
      {children}
    </Stack>
  )
}

export default FabGroup
