import { AxiosResponse } from "axios"
import { useQueryClient } from "react-query"
import { UserSchoolPlan } from "ts/enums/User"
import { IUserMe } from "ts/interfaces/User"
import { isTeacher } from "utils/roleCheck"

export const useIsFreeSchoolPlan = () => {
  const queryClient = useQueryClient()
  const meData = queryClient.getQueryData<AxiosResponse<IUserMe>>("userMe")

  if (!meData) return true

  if (!isTeacher(meData.data.user)) return true

  return [UserSchoolPlan.FREE].includes(meData.data.user.school_plan)
}

export const userIsFreeDemoSchoolPlan = () => {
  const queryClient = useQueryClient()
  const meData = queryClient.getQueryData<AxiosResponse<IUserMe>>("userMe")

  if (!meData) return true

  if (!isTeacher(meData.data.user)) return true

  return [UserSchoolPlan.FREE, UserSchoolPlan.DEMO].includes(
    meData.data.user.school_plan
  )
}
