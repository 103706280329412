import { ThemeOptions } from "@mui/material"

const muiAvatar: ThemeOptions["components"] = {
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: "44px",
        width: "44px",
        backgroundColor: theme.palette.primary.main,
      }),
    },
  },
}

export default muiAvatar
