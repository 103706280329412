import React, { FC, useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { useMutationSelfUpdate } from "api/reactQuery/mutations/teacher"
import { HREFLINK } from "components/common/link/hreflink"
import FileField from "components/form/common/field/fileField"
import TextField from "components/form/common/field/textField"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useCustomPayment } from "hooks/payment"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import { useForm, FormProvider } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { UserSchoolPlan } from "ts/enums/User"
import { getErrorMessageFromTab } from "utils/api"

import { defaultValues, validateSchema } from "./General.config"
import { IFormState } from "./General.types"
import StripeButtons from "./stripeButtons"

interface IProps {
  id?: string
  userInfo?: IFormState
  handleClose?: () => void
}

const General: FC<IProps> = ({ id, userInfo, handleClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()
  const isTablet = useIsBreakpointUp("tablet")
  const isDesktop = useIsBreakpointUp("desktop")
  const user = useAppSelector(selectUser)
  const navigate = useNavigate()

  const methods = useForm<IFormState>({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const { mutate } = useMutationSelfUpdate({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("userMe")
        showSnackbar({
          title: t("teacherSettings.updateSucceed"),
        })
        handleClose && handleClose()
        if (location.pathname === RoutePath.SETTINGS) {
          navigate(RoutePath.HOMEPAGE)
        }
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { payIt, isLoadingPayments } = useCustomPayment()

  const onSubmit = (data: IFormState) => {
    if (
      userInfo?.avatar_url === data.avatar_url &&
      userInfo?.first_name === data.first_name &&
      userInfo?.last_name === data.last_name
    ) {
      showSnackbar({
        title: t("teacherSettings.nothingChange"),
        variant: "info",
      })
      handleClose && handleClose()
      if (location.pathname === RoutePath.SETTINGS) {
        navigate(RoutePath.HOMEPAGE)
      }
      return
    }
    mutate({
      first_name: data.first_name,
      last_name: data.last_name,
      avatar_url: data.avatar_url,
    })
  }

  useEffect(() => {
    if (userInfo) {
      methods.setValue("first_name", userInfo.first_name)
      methods.setValue("last_name", userInfo.last_name)
      methods.setValue("avatar_url", userInfo.avatar_url)
      methods.setValue("email", userInfo.email)
      methods.setValue("school_name", userInfo.school_name)
    }
  }, [])

  return (
    <form id={id} onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Stack>
          <Typography
            display="block"
            variant="body3"
            color="mockup.neutral40"
            pb="12px"
            pt="24px"
          >
            Picture
          </Typography>
          <FileField fileType="image/png, image/jpeg" name="avatar_url" />
          <Box sx={{ py: 3 }}>
            <Typography
              sx={{ pb: 3 }}
              variant="subtitle1"
              color={theme.palette.mockup.neutral10}
            >
              {t("teacherSettings.accountDetails")}
            </Typography>
            <Grid container columnSpacing={2} mb={7}>
              <Grid item desktop={6} mobile={12}>
                <TextField
                  name="first_name"
                  type="text"
                  label={t("teacherSettings.firstName")}
                  sx={{ mt: isTablet ? 3 : 0 }}
                />
              </Grid>
              <Grid item desktop={6} mobile={12}>
                <TextField
                  name="last_name"
                  type="text"
                  label={t("teacherSettings.lastName")}
                  sx={{ mt: 3 }}
                />
              </Grid>
              <Grid item desktop={6} mobile={12}>
                <TextField
                  disabled
                  name="email"
                  label={t("teacherSettings.email")}
                  sx={{ mt: 4 }}
                />
              </Grid>
              <Grid item desktop={6} mobile={12}>
                <TextField
                  label={t("teacherSettings.school")}
                  name="school_name"
                  disabled
                  type="text"
                  sx={{ mt: 4 }}
                />
              </Grid>
            </Grid>
            {(user?.user.school_plan === UserSchoolPlan.PRO ||
              user?.user.school_plan === UserSchoolPlan.FREE) &&
              process.env.REACT_APP_TYPE === "classequity" && (
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: theme.palette.mockup.primary99,
                    width: isDesktop ? "426px" : "100%",
                    maxWidth: "100%",
                    borderRadius: "16px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    pb={2}
                    color="mockup.neutral10"
                  >
                    {user?.user.school_plan === UserSchoolPlan.PRO
                      ? t("teacherSettings.manageStripeProfile")
                      : t("teacherSettings.upgradePlan")}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    color="mockup.neutral10"
                    mb={4}
                  >
                    {user?.user.school_plan === UserSchoolPlan.PRO ? (
                      t("teacherSettings.goToYourSubscriptionProfile")
                    ) : (
                      <Trans
                        i18nKey="teacherSettings.bestForTeachersWhoWorkInTeamLearnMoreAboutOurPlan"
                        components={{
                          Link: HREFLINK,
                        }}
                      />
                    )}
                  </Typography>
                  <StripeButtons
                    school_plan={user?.user.school_plan}
                    isLoadingPayments={isLoadingPayments}
                    payIt={payIt}
                  />
                </Box>
              )}
          </Box>
        </Stack>
      </FormProvider>
    </form>
  )
}

export default General
