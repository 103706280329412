import React, { FC } from "react"

import { ReactComponent as Logo } from "@brand_assets/svg/logo.svg"
import { Box, Grid } from "@mui/material"
import BackgroundImageLeft from "components/common/backgroundImageLeft"
import { useIsBreakpointUp } from "hooks/breakpoint"

const Layout: FC = ({ children }) => {
  const desktopView = useIsBreakpointUp("desktop")

  return (
    <Grid
      container
      minHeight="100vh"
      width="100%"
      justifyContent={{ desktop: "space-between", mobile: "stretch" }}
    >
      {desktopView && <BackgroundImageLeft />}
      <Grid
        item
        width={{ desktop: "", mobile: "100%" }}
        px={{ desktop: 0, mobile: "10px" }}
        justifyContent={{ desktop: "center", mobile: "stretch" }}
        flexDirection={{ desktop: "row", mobile: "column" }}
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flex: "1 0 auto",
        }}
      >
        <Box maxWidth="100%" width={{ desktop: "422px", mobile: "536px" }}>
          {!desktopView && (
            <Box sx={{ textAlign: "left", my: 5, width: "115px" }}>
              <Logo />
            </Box>
          )}
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Layout
