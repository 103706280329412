import { RootState } from "../store.types"

export const selectClassname = (state: RootState) => state.utility.classname
export const selectSound = (state: RootState) => state.utility.sound
export const selectNewStudents = (state: RootState) => state.utility.newStudents
export const selectToken = (state: RootState) => state.utility.token
export const selectExistingStudent = (state: RootState) =>
  state.utility.existingStudents
export const selectClassList = (state: RootState) => state.utility.classList
export const selectPage = (state: RootState) => state.utility.page
export const selectIsNextPage = (state: RootState) => state.utility.isNextPage
export const selectIsNextPageOnStudent = (state: RootState) =>
  state.utility.isNextPageOnStudent
export const selectParamsClassStudentList = (state: RootState) =>
  state.utility.classListParams
export const selectStudentsNumber = (state: RootState) =>
  state.utility.studentsNumber
export const selectTaskData = (state: RootState) => state.utility.taskData
export const selectTaskId = (state: RootState) => state.utility.taskId
